import { ColorName, useTheme } from '@emotion/react';
import { fadeColor } from 'design-system/theme';
import { useEffect, useRef } from 'react';
import { Animated } from 'react-native';

export interface GlowProps {
  color: ColorName;
  minOpacity?: number;
  maxOpacity?: number;
  duration?: number;
  disabled?: boolean;
}

type UseGlowHook = (props: GlowProps) => Animated.AnimatedInterpolation<number>;

/**
 * Use a glow animation for use on an Animated.View as any color prop, this creates a glow effect on
 * the component whose style it's applied to
 */
export const useGlow: UseGlowHook = ({
  color = 'divider',
  disabled = false,
  minOpacity = 0.7,
  maxOpacity = 1,
  duration = 3000,
}) => {
  const theme = useTheme();
  const themeColor = theme.color[color];

  const minColor = fadeColor(themeColor, minOpacity);
  const maxColor = fadeColor(themeColor, maxOpacity);

  const animation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (disabled) {
      return () => undefined;
    }

    const loop = Animated.loop(
      Animated.sequence([
        Animated.timing(animation, {
          toValue: 1,
          duration,
          useNativeDriver: true,
        }),
        Animated.timing(animation, {
          toValue: 0,
          duration,
          useNativeDriver: true,
        }),
      ])
    );

    loop.start();

    return () => loop.stop();
  }, [disabled, duration, animation, maxColor, minColor]);

  const animatedColor = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [minColor, maxColor],
  });

  return animatedColor;
};
