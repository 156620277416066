import { useIsSmallerThan } from 'hooks/useResponsive';
import { useToggle } from 'providers/kbar';
import React from 'react';
import { strings } from 'strings';

import { Tab } from './Tab';

export const SearchButton: React.FC = () => {
  const toggleKBar = useToggle();

  const isSmall = useIsSmallerThan('lg');

  if (isSmall) {
    return null;
  }

  return (
    <Tab isActive={false} onPress={toggleKBar} text={strings.ButtonTextSearch} icon="search" />
  );
};
