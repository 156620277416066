import { EncounterService } from '@quromedical/fhir-common';
import { MeasurementName } from '@quromedical/models';
import { mapDisplaysToOptions } from 'core/forms';
import {
  getMonitoringConfiguration,
  getVitalsForMonitoringConfiguration,
  MonitoringConfiguration,
  monitoringConfigurationDisplay,
} from 'core/vitals';
import { ComboBoxSingle } from 'design-system';
import { useCallback, useEffect, useMemo } from 'react';
import { strings } from 'strings';

interface VitalsFilterProps {
  serviceType?: EncounterService;
  dayOfService?: number;
  onChange: (measurements: MeasurementName[]) => void;
  possibleMeasures?: MeasurementName[];
}

export const VitalsFilter: React.FC<VitalsFilterProps> = ({
  dayOfService,
  serviceType,
  onChange,
  possibleMeasures = [],
}) => {
  const initialMonitoringConfiguration: MonitoringConfiguration = useMemo(
    () =>
      serviceType
        ? getMonitoringConfiguration(serviceType, dayOfService)
        : MonitoringConfiguration.HighIntensity,
    [dayOfService, serviceType]
  );

  const handleChange = useCallback(
    (selection: MonitoringConfiguration) => {
      const measurementConfiguration = getVitalsForMonitoringConfiguration(selection);
      const hasPossibleMeasures = possibleMeasures.length > 0;

      if (hasPossibleMeasures) {
        // filter based on possibleMeasures so we can ensure the desired return ordering
        const filtered = possibleMeasures.filter((m) => measurementConfiguration.includes(m));
        onChange(filtered);
      } else {
        onChange(measurementConfiguration);
      }
    },
    [onChange, possibleMeasures]
  );

  useEffect(() => {
    // broadcast the initial value on mount
    handleChange(initialMonitoringConfiguration);
  }, [handleChange, initialMonitoringConfiguration]);

  const options = mapDisplaysToOptions<MonitoringConfiguration>(monitoringConfigurationDisplay);

  return (
    <ComboBoxSingle<MonitoringConfiguration>
      // necessary since the initial value may change after initial mount once the admission is
      // loaded and if this changes the resolved configuration
      key={initialMonitoringConfiguration}
      label={strings.VitalsFilterFormLabel}
      onChange={handleChange}
      value={initialMonitoringConfiguration}
      fetcher={options}
    />
  );
};
