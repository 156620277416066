import { Practitioner } from '@quromedical/models';
import { useLinkTo } from '@react-navigation/native';
import { Row } from 'components/base';
import {
  Button,
  Visible,
  Text,
  Snackbar,
  Alert,
  SkeletonProvider,
  Skeleton,
  Spacer,
  MARGIN,
} from 'design-system';
import { logger } from 'helpers';
import { useFiniteState } from 'hooks/useFiniteState';
import { useObjectState } from 'hooks/useObjectState';
import { useRevalidation } from 'hooks/useRevalidation';
import { getAddressPartialFetcher, googlePlaceFetcher } from 'integration';
import { PractitionerApi } from 'integration/aggregate';
import React, { useCallback } from 'react';
import { View } from 'react-native';
import { PersonContactFormCard, PersonGeneralFormCard } from 'screens/person';
import { strings } from 'strings';

import { PractitionerCreateInitialGroupForm } from './PractitionerCreateInitialGroupForm';
import { PractitionerQualificationForm } from './PractitionerQualificationForm';

const api = new PractitionerApi();

type FormState =
  | 'interactive'
  | 'submitting'
  | 'validation-error'
  | 'submission-error'
  | 'loading'
  | 'complete';

export const PractitionerCreateForm: React.FC = () => {
  const navigate = useLinkTo();
  const revalidate = useRevalidation('Practitioner');

  const formState = useFiniteState<FormState>('interactive');
  const formData = useObjectState<Partial<Practitioner.CreateRequest>>({});
  const showErrors = formState.is('validation-error');

  const handleSubmit = useCallback(async () => {
    const defined = formData.getDefined(['contact', 'general', 'qualification', 'initialGroup']);

    if (!defined) {
      formState.set('validation-error');
      return;
    }
    formState.set('loading');

    try {
      const result = await api.createPractitioner(defined);

      formState.set('complete');
      navigate({
        screen: 'Admin',
        params: {
          screen: 'Practitioner',
          params: {
            screen: 'View',
            params: {
              id: result.id,
            },
          },
        },
      });
    } catch (err) {
      logger.error(err);
      formState.set('submission-error');
    } finally {
      revalidate().catch(logger.error);
    }
  }, [formData, formState, navigate, revalidate]);

  return (
    <>
      <Snackbar isOpen={formState.is('submission-error')} onClose={formState.next('interactive')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={formState.next('interactive')}
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
          actionIcon="close"
        />
      </Snackbar>
      <SkeletonProvider loading={formState.is('loading')}>
        <Skeleton>
          <PersonGeneralFormCard
            onValidChange={formData.set('general')}
            showErrors={showErrors}
            minimalData={true}
          />
        </Skeleton>
        <Spacer height={MARGIN.s} />
        <Skeleton>
          <PersonContactFormCard
            onValidChange={formData.set('contact')}
            showErrors={showErrors}
            addressFetcher={googlePlaceFetcher}
            getAddressPartial={getAddressPartialFetcher}
          />
        </Skeleton>
        <Spacer height={MARGIN.s} />
        <Skeleton>
          <PractitionerQualificationForm
            onValidChange={formData.set('qualification')}
            showErrors={showErrors}
          />
        </Skeleton>
        <Spacer height={MARGIN.s} />
        <Skeleton>
          <PractitionerCreateInitialGroupForm
            onValidChange={formData.set('initialGroup')}
            showErrors={showErrors}
          />
        </Skeleton>
        <Spacer height={MARGIN.s} />
        <Skeleton>
          <Row alignItems="flex-end" justifyContent="space-between">
            <Visible if={formState.is('submission-error')}>
              <View>
                <Text>{strings.LabelPractitionerError}</Text>
              </View>
            </Visible>
          </Row>
          <Row justifyContent="flex-end">
            <Button onPress={handleSubmit} text={strings.ButtonTextSubmitPractitioner} />
          </Row>
        </Skeleton>
      </SkeletonProvider>
    </>
  );
};
