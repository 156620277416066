import { DeviceCode, DeviceUseStatementCode, getReferenceId } from '@quromedical/fhir-common';
import { DeviceAssociation } from '@quromedical/models';
import { DisplayView } from 'components/fhir';
import { ConfirmationButton } from 'components/input';
import { ColumnType } from 'components/table';
import { getDurationInDays, getPlanPeriodDisplay } from 'core/display';
import {
  getMonitoringConfiguration,
  MonitoringConfiguration,
  monitoringConfigurationDisplay,
} from 'core/vitals';
import { Text } from 'design-system';
import { dateFormatter } from 'helpers';
import { useRevalidation } from 'hooks/useRevalidation';
import { DeviceUseStatementApi } from 'integration';
import compact from 'lodash.compact';
import React, { useCallback } from 'react';
import { DeviceConfigure } from 'screens/device';
import { strings } from 'strings';

import { TableDeviceUseStatement } from './mapping';

const api = new DeviceUseStatementApi();

type HandlerFactory = (id?: string) => () => Promise<void>;

const terminateHandler: HandlerFactory = (id?: string) => async () => {
  if (!id) {
    return;
  }
  await api.terminateDeviceUseStatement(id);
};

const cancelHandler: HandlerFactory = (id?: string) => async () => {
  if (!id) {
    return;
  }
  await api.cancelDeviceUseStatement(id);
};

const fallbackHandler: HandlerFactory = () => () => Promise.resolve();

type ActionType = 'terminate' | 'cancel' | 'none';
type ValidActionType = Exclude<ActionType, 'none'>;

const actionFactoryMap: Record<ActionType, HandlerFactory> = {
  terminate: terminateHandler,
  cancel: cancelHandler,
  none: fallbackHandler,
};

const deviceUseStatementActionMap: Record<DeviceUseStatementCode, ActionType> = {
  [DeviceUseStatementCode.relay]: 'terminate',
  [DeviceUseStatementCode.patch]: 'cancel',
  [DeviceUseStatementCode.glucose]: 'terminate',
  [DeviceUseStatementCode.bloodPressure]: 'terminate',
};

const confirmationDialogTitleMap: Record<ValidActionType, string> = {
  cancel: strings.DeviceUseStatementCancelTitle,
  terminate: strings.DeviceUseStatementTerminateTitle,
};

const confirmationDialogMessageMap: Record<ValidActionType, string> = {
  cancel: strings.DeviceUseStatementCancelMessage,
  terminate: strings.DeviceUseStatementTerminateMessage,
};

const buttonTextMap: Record<ValidActionType, string> = {
  cancel: strings.DeviceUseStatementCancelButton,
  terminate: strings.DeviceUseStatementTerminateButton,
};

interface ActionCellRow {
  id: string;
  code?: DeviceUseStatementCode;
  isActive?: boolean;
}

// not ideal, but minimizes the number of changes needed - the existing component is quite tightly
// coupled to where it's used at the moment
interface ActionCellProps {
  row: {
    original: ActionCellRow;
  };
}

const ActionCell: React.FC<ActionCellProps> = ({ row }) => {
  const { code, isActive, id } = row.original;

  // temporary key, will need to be updated once the new endpoint and actions are configured
  const revalidate = useRevalidation('DeviceUseStatement');

  const actionType = deviceUseStatementActionMap[code as DeviceUseStatementCode] || 'none';
  const factory = actionFactoryMap[actionType];

  const onSubmit = useCallback(async () => {
    const handler = factory(id);

    await handler();
    await revalidate();
  }, [id, factory, revalidate]);

  if (!isActive || actionType === 'none') {
    return null;
  }

  const title = confirmationDialogTitleMap[actionType];
  const message = confirmationDialogMessageMap[actionType];
  const buttonText = buttonTextMap[actionType];

  return (
    <ConfirmationButton
      status="status-critical"
      title={title}
      message={message}
      onSubmit={onSubmit}
      buttonText={buttonText}
    />
  );
};

const baseColumn: ColumnType<TableDeviceUseStatement>[] = [
  {
    header: strings.LabelDevice,
    Cell: ({ value }) => <DisplayView reference={value} />,
    accessor: 'device',
  } as ColumnType<TableDeviceUseStatement, 'device'>,
  {
    header: strings.LabelStart,
    Cell: ({ value }) => <Text>{value?.toLocaleDateString()}</Text>,
    accessor: 'start',
  } as ColumnType<TableDeviceUseStatement, 'start'>,
  {
    header: strings.LabelEnd,
    Cell: ({ value }) => <Text>{value?.toLocaleDateString() || 'Ongoing'}</Text>,
    accessor: 'end',
  } as ColumnType<TableDeviceUseStatement, 'end'>,
  {
    header: strings.DeviceTableTypeSubheading,
    Cell: ({ value }) => <Text>{value}</Text>,
    accessor: 'type',
  } as ColumnType<TableDeviceUseStatement, 'type'>,
  {
    header: strings.DeviceTableStatusSubheading,
    Cell: ({ value }) => <Text>{value}</Text>,
    accessor: 'status',
  } as ColumnType<TableDeviceUseStatement, 'status'>,
];

const updateAction: ColumnType<TableDeviceUseStatement, 'id'> = {
  header: strings.LabelAction,
  Cell: ActionCell,
  accessor: 'id',
};

export const deviceUseStatementToDeviceCodeMap: Record<DeviceUseStatementCode, DeviceCode> = {
  [DeviceUseStatementCode.bloodPressure]: DeviceCode.bloodPressure,
  [DeviceUseStatementCode.glucose]: DeviceCode.glucose,
  [DeviceUseStatementCode.patch]: DeviceCode.patch,
  [DeviceUseStatementCode.relay]: DeviceCode.relay,
};

const calibrateAction: ColumnType<TableDeviceUseStatement, 'code'> = {
  header: strings.TableColumnCalibrate,
  accessor: 'code',
  Cell: ({ row }) => {
    const id = getReferenceId(row.original.device);

    if (!id) {
      return null;
    }

    const isActive = row.original.status === 'active';

    if (!isActive) {
      return null;
    }

    const deviceUseStatementCode = row.original.code;
    const deviceCode =
      deviceUseStatementCode && deviceUseStatementToDeviceCodeMap[deviceUseStatementCode];

    return <DeviceConfigure deviceId={id} deviceCode={deviceCode} />;
  },
};

export const patientDeviceUseStatementColumns = (
  canUpdate: boolean,
  canCalibrate: boolean
): ColumnType<TableDeviceUseStatement>[] =>
  compact([...baseColumn, canCalibrate && calibrateAction, canUpdate && updateAction]);

export const deviceDeviceUseStatementColumns: ColumnType<TableDeviceUseStatement>[] = [
  {
    header: strings.TitlePatient,
    Cell: ({ value }) => <DisplayView reference={value} />,
    accessor: 'subject',
  } as ColumnType<TableDeviceUseStatement, 'subject'>,
  {
    header: strings.LabelStart,
    Cell: ({ value }) => <Text>{value?.toLocaleDateString()}</Text>,
    accessor: 'start',
  } as ColumnType<TableDeviceUseStatement, 'start'>,
  {
    header: strings.LabelEnd,
    Cell: ({ value }) => <Text>{value?.toLocaleDateString() || 'Ongoing'}</Text>,
    accessor: 'end',
  } as ColumnType<TableDeviceUseStatement, 'end'>,
  {
    header: strings.DeviceTableTypeSubheading,
    Cell: ({ value }) => <Text>{value}</Text>,
    accessor: 'type',
  } as ColumnType<TableDeviceUseStatement, 'type'>,
  {
    header: strings.DeviceTableStatusSubheading,
    Cell: ({ value }) => <Text>{value}</Text>,
    accessor: 'status',
  } as ColumnType<TableDeviceUseStatement, 'status'>,
  {
    header: strings.LabelAction,
    Cell: ActionCell,
    accessor: 'id',
  } as ColumnType<TableDeviceUseStatement, 'id'>,
];

export type TableAssociation = DeviceAssociation.GetResponse & {
  start?: string;
  end?: string;
  monitoringConfiguration?: MonitoringConfiguration;
};

export const deviceAssociationColumns: ColumnType<TableAssociation>[] = [
  {
    header: strings.TitlePatient,
    Cell: ({ value }) => <DisplayView reference={value} />,
    accessor: 'patient',
  } as ColumnType<TableAssociation, 'patient'>,
  {
    header: strings.LabelDevice,
    Cell: ({ value }) => <DisplayView reference={value} />,
    accessor: 'device',
  } as ColumnType<TableAssociation, 'device'>,
  {
    header: strings.LabelStart,
    accessor: 'start',
    Cell: ({ value }) => (value ? <Text>{dateFormatter(new Date(value))}</Text> : null),
  } as ColumnType<TableAssociation, 'start'>,
  {
    header: strings.LabelEnd,
    accessor: 'end',
    Cell: ({ value }) => (value ? <Text>{dateFormatter(new Date(value))}</Text> : null),
  } as ColumnType<TableAssociation, 'end'>,
  {
    header: strings.DeviceAssociationActiveAdmissionServiceLabel,
    Cell: ({ value }) => <Text>{getPlanPeriodDisplay(value?.service, value?.period)}</Text>,
    accessor: 'inProgressAdmission',
  } as ColumnType<TableAssociation, 'inProgressAdmission'>,
  {
    header: strings.MonitoringConfigurationLabel,
    Cell: ({ value }) => (value ? <Text>{monitoringConfigurationDisplay[value]}</Text> : null),
    accessor: 'monitoringConfiguration',
  } as ColumnType<TableAssociation, 'monitoringConfiguration'>,
  {
    header: strings.LabelAction,
    Cell: ({ row }) => (
      <ActionCell
        row={{
          original: {
            ...row.original,
            isActive: row.original.status === 'active',
          },
        }}
      />
    ),
    accessor: 'id',
  } as ColumnType<TableAssociation, 'id'>,
];

export const mapResponseToTableAssociation = (
  association: DeviceAssociation.GetResponse
): TableAssociation => {
  const { service, period } = association.inProgressAdmission || {};

  const baseData: TableAssociation = {
    // this is the period for the device use statement, not admission
    ...(association.period || {}),
    ...association,
  };

  const hasServiceDetails = service && period;

  if (!hasServiceDetails) {
    return baseData;
  }

  const monitoringConfiguration = getMonitoringConfiguration(
    service,
    getDurationInDays(period?.start)
  );

  return {
    ...baseData,
    monitoringConfiguration,
  };
};

export const getTableDataFromResponse =
  (monitoringConfiguration?: MonitoringConfiguration) =>
  (response: DeviceAssociation.GetAllResponse) => {
    const result = response.associations.map(mapResponseToTableAssociation);

    if (!monitoringConfiguration) {
      return result;
    }

    return result.filter((res) => res.monitoringConfiguration === monitoringConfiguration);
  };
