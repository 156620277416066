import { Media, Patient } from '@quromedical/models';
import { useLinkTo } from '@react-navigation/native';
import { Row } from 'components/base';
import {
  AdmissionClinicalData,
  AdmissionClinicalForm,
  AdmissionNotesData,
  AdmissionNotesForm,
} from 'core/admission';
// TODO: importing these from the correct location is creating an import error, probably a cycle
// eslint-disable-next-line no-restricted-imports
import {
  AdmissionAuthorizationData,
  AdmissionAuthorizationForm,
} from 'core/admission/AdmissionAuthorizationForm';
import { CareTeamForm } from 'core/patient';
import { PersonNameAndIdDisplay } from 'core/person';
import { Alert, Button, Section, Skeleton, SkeletonProvider, Snackbar } from 'design-system';
import { logger } from 'helpers';
import { useFiniteState } from 'hooks/useFiniteState';
import { useObjectState } from 'hooks/useObjectState';
import { useRevalidation } from 'hooks/useRevalidation';
import {
  icd10CodeFetcher,
  practitionerFetcher,
  teamOrDepartmentOrganizationFetcher,
} from 'integration';
import { MediaApi, PatientApi, PatientEndpoint } from 'integration/aggregate';
import { usePatientId } from 'providers/PatientIdContext';
import React, { useCallback } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { strings } from 'strings';
import useSWR from 'swr';

const api = new PatientApi();

interface FormData {
  note: AdmissionNotesData;
  authorization: AdmissionAuthorizationData;
  clinical: AdmissionClinicalData;
  careTeam: Patient.GeneralPractitionerUpdateRequest;
}

type FormState =
  | 'interactive'
  | 'submitting'
  | 'validation-error'
  | 'submission-error'
  | 'loading'
  | 'complete';

interface ApiData {
  patient: Patient.GetPatientResponse;
  coverage?: Patient.PatientCoverageResponse;
}

const mediaApi = new MediaApi();

export const AdmissionCreateScreen: React.FC = () => {
  const id = usePatientId();
  const linkTo = useLinkTo();

  const swrKey = api.getPatientSWRKey(id, PatientEndpoint.Base, PatientEndpoint.Coverage);
  const apiData = useSWR(swrKey, async (): Promise<ApiData> => {
    // TODO: make a single endpoint for fetching just the necessary data for this page
    const patient = await api.getPatient(id);
    if (!patient.coverage) {
      return {
        patient,
      };
    }

    const coverage = await api.getCoverage(id);
    return {
      patient,
      coverage,
    };
  });

  const revalidate = useRevalidation(id);

  const formState = useFiniteState<FormState>('interactive');
  const formData = useObjectState<Partial<FormData>>({});

  const onSubmit = useCallback(async () => {
    const defined = apiData.data?.coverage
      ? formData.getDefined(['authorization', 'clinical', 'note', 'careTeam'])
      : // if we don't have a coverage then we don't expect this in the resulting data
        formData.getDefined(['clinical', 'note', 'careTeam']);

    if (!defined) {
      formState.set('validation-error');
      return;
    }

    formState.set('loading');
    try {
      await api.createAdmission(id, {
        class: defined.note.class,
        service: defined.note.service,
        periodStart: defined.note.periodStart,
        icd10Codes: defined.clinical.icd10Codes,
        practitioners: defined.careTeam,
        medicalAidAuthorization: defined.authorization?.medicalAidAuthorization,
        oxygen: defined.clinical.oxygen,
        attachments: defined.note.attachments,
      });
      await revalidate().catch(logger.error);
      linkTo({
        screen: 'Patient',
        params: {
          screen: 'View',
          params: {
            id,
            screen: 'Profile',
          },
        },
      });
      formState.set('complete');
    } catch (err) {
      logger.error(err);
      formState.set('submission-error');
    }
  }, [apiData.data?.coverage, formData, formState, id, revalidate, linkTo]);

  const icd10Codes = apiData.data?.patient.inProgressAdmission?.icd10Codes?.map(
    (code) => code.code
  );

  const createUploadUrl = useCallback(
    (body: Media.CreateUploadUrlRequest): Promise<Media.CreateUploadUrlResponse> =>
      mediaApi.createUploadUrl(id, body),
    [id]
  );

  if (apiData.error) {
    return (
      <Snackbar isOpen={!!apiData.error} onClose={revalidate}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={revalidate}
          actionIcon="refresh"
          title={strings.AlertError}
          body={strings.ErrorFetchingPatient}
        />
      </Snackbar>
    );
  }

  const showErrors = formState.is('validation-error');

  return (
    <>
      <Snackbar isOpen={formState.is('submission-error')} onClose={formState.next('interactive')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={formState.next('interactive')}
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
          actionIcon="close"
        />
      </Snackbar>
      <SkeletonProvider loading={apiData.isValidating || formState.is('loading')}>
        <ScrollView>
          <Section hasTopMargin>
            <Skeleton>
              <PersonNameAndIdDisplay
                title={strings.CardLabelPatientDetails}
                general={apiData.data?.patient?.general}
              />
            </Skeleton>
          </Section>
          <Section unsetZIndex>
            <Skeleton>
              <AdmissionNotesForm
                onValidChange={formData.set('note')}
                showErrors={showErrors}
                createUploadUrl={createUploadUrl}
              />
            </Skeleton>
          </Section>
          <Section isVisible={!!apiData.data?.coverage}>
            <Skeleton>
              <AdmissionAuthorizationForm
                schemeName={apiData.data?.coverage?.coverage?.medicalAid?.schemeName}
                planName={apiData.data?.coverage?.coverage?.medicalAid?.plan}
                onValidChange={formData.set('authorization')}
                showErrors={showErrors}
              />
            </Skeleton>
          </Section>
          <Section unsetZIndex>
            <Skeleton>
              <AdmissionClinicalForm
                icd10CodeFetcher={icd10CodeFetcher}
                // TODO: cleanup here. need to get ICD10Codes from previous admission
                icd10Codes={icd10Codes}
                onValidChange={formData.set('clinical')}
                showErrors={showErrors}
              />
            </Skeleton>
          </Section>
          <Section unsetZIndex>
            <Skeleton>
              <CareTeamForm
                // TODO: get practitioners from previous admission
                teamOrDepartmentOrganizationFetcher={teamOrDepartmentOrganizationFetcher}
                practitionerFetcher={practitionerFetcher}
                onValidChange={formData.set('careTeam')}
                showErrors={showErrors}
                // TODO: set the default practitioners here, needs to be defined as a `CreateReference`
                // and create a mapping from a `CreateReference` to a `SelectOption` (search: mapRefTo)
              />
            </Skeleton>
          </Section>
          <Section unsetZIndex hasBottomMargin>
            <Skeleton>
              <Row justifyContent="flex-end" unsetZIndex>
                <Button
                  disabled={apiData.isValidating}
                  onPress={onSubmit}
                  text={strings.ButtonTextSubmit}
                />
              </Row>
            </Skeleton>
          </Section>
        </ScrollView>
      </SkeletonProvider>
    </>
  );
};
