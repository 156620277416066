import styled from '@emotion/native';
import {
  createNavigatorFactory,
  DefaultNavigatorOptions,
  ParamListBase,
  CommonActions,
  TabActionHelpers,
  TabNavigationState,
  TabRouter,
  TabRouterOptions,
  useNavigationBuilder,
} from '@react-navigation/native';
import { Col, Row } from 'components/base';
import { Visible, IconName } from 'design-system/base';
import { BORDER_WIDTH, PADDING } from 'design-system/theme';
import { LinkTo } from 'navigation';
import * as React from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useBottomNavigatorContext } from './BottomNavigatorContext';
import { Tab } from './Tab';
import { TopBar } from './TopBar';

// Props accepted by the view
type TabNavigationConfig = {
  name?: string;
  initials?: string;
};

// Supported screen options
type TabNavigationOptions = {
  icon: IconName;
  link?: LinkTo;
  isHidden?: boolean;
};

// Map of event name and the type of data (in event.data)
//
// canPreventDefault: true adds the defaultPrevented property to the
// emitted events.
type TabNavigationEventMap = {
  tabPress: {
    data: { isAlreadyFocused: boolean };
    canPreventDefault: true;
  };
};

// The props accepted by the component is a combination of 3 things
type Props = DefaultNavigatorOptions<
  ParamListBase,
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap
> &
  TabRouterOptions &
  TabNavigationConfig;

const Screen = styled.View(({ theme }) => {
  const { height } = useWindowDimensions();
  const { top } = useSafeAreaInsets();

  const iosScreenHeight = height - top;
  const screenHeight = Platform.OS === 'ios' ? iosScreenHeight : height;

  return {
    height: screenHeight,
    backgroundColor: theme.color.base,
  };
});

const BottomBar = styled(Row)(({ theme }) => () => {
  const { bottom } = useSafeAreaInsets();

  const bottomBarHeight = Platform.OS === 'ios' ? bottom : 0;

  return {
    paddingBottom: bottomBarHeight,
    backgroundColor: theme.color['base-grey'],
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingHorizontal: PADDING.s,
    maxWidth: '100%',
    borderStyle: 'solid',
    borderWidth: 0,
    borderColor: theme.color.divider,
    borderTopWidth: BORDER_WIDTH[1],
  };
});

const Page = styled(Col)({
  flex: 1,
});

/**
 * Web-style Top Navigator implemented as per the
 * [React-Navigation Documentation Sample](https://reactnavigation.org/docs/custom-navigators/)
 */
export const BottomNavigator = ({
  initialRouteName,
  children,
  screenOptions,
  name,
  initials,
}: Props): JSX.Element => {
  const { state, navigation, descriptors, NavigationContent } = useNavigationBuilder<
    TabNavigationState<ParamListBase>,
    TabRouterOptions,
    TabActionHelpers<ParamListBase>,
    TabNavigationOptions,
    TabNavigationEventMap
  >(TabRouter, {
    children,
    screenOptions,
    initialRouteName,
  });

  const { isNavigationVisible } = useBottomNavigatorContext();

  const navigateToProfile = React.useCallback(() => {
    navigation.navigate('Profile');
  }, [navigation]);

  return (
    <NavigationContent>
      <Screen>
        <Page unsetZIndex>
          <Visible if={isNavigationVisible}>
            <TopBar name={name} initials={initials} onProfilePress={navigateToProfile} />
          </Visible>
          {state.routes.map((route, index) => {
            const isVisible = index === state.index;

            if (!isVisible) {
              return null;
            }

            return descriptors[route.key].render();
          })}
        </Page>

        <Visible if={isNavigationVisible}>
          <BottomBar unsetZIndex>
            {state.routes.map((route, index) => {
              const { icon, link, isHidden } = descriptors[route.key].options;

              if (isHidden) {
                return null;
              }

              const isActive = state.index === index;

              const isAlreadyFocused = route.key === state.routes[state.index].key;

              const onPress = () => {
                if (link && typeof link !== 'string') {
                  navigation.navigate(link.screen, link.params);
                  return;
                }

                const event = navigation.emit({
                  type: 'tabPress',
                  target: route.key,
                  canPreventDefault: true,
                  data: {
                    isAlreadyFocused,
                  },
                });

                if (!event.defaultPrevented) {
                  navigation.dispatch({
                    ...CommonActions.navigate({ name: route.name, merge: true }),
                    target: state.key,
                  });
                }
              };

              return <Tab key={route.key} onPress={onPress} icon={icon} isActive={!!isActive} />;
            })}
          </BottomBar>
        </Visible>
      </Screen>
    </NavigationContent>
  );
};

export const createBottomNavigator = createNavigatorFactory<
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap,
  typeof BottomNavigator
>(BottomNavigator);
