import { HumanNamePrefix } from '@quromedical/fhir-common';
import { MedicalAid, Person } from '@quromedical/models';
import { Field, InputConfig, SelectOption } from 'components/types';
import { Text } from 'design-system';
import { strings } from 'strings';

/*
 * Dates come from backend as a string
 */
export type PatientSelection = {
  id: string;
  idNumber: string;
  birthDate: string;
  gender: Person.Gender;
  prefix: HumanNamePrefix;
  dependantCode: string;
  isMainMember: boolean;
  status?: string;
  start?: string;
  end?: string;
};

const getDisplay = ({ general }: Partial<MedicalAid.MedicalAidPerson>): string =>
  `${general?.givenName || ''} ${general?.familyName || ''}`;

export const findMemberByDependantCode = (
  results: MedicalAid.MedicalAidValidationResponse,
  dependantCode?: string
): Partial<MedicalAid.MedicalAidPerson> | undefined =>
  results.find((r) => r.medicalAidMembership?.dependantCode === dependantCode);

export const findMemberByIdNumber = (
  results: MedicalAid.MedicalAidValidationResponse,
  idNumber?: string
): Partial<MedicalAid.MedicalAidPerson> | undefined =>
  results.find((r) => r.general?.identifierValue === idNumber);

export const createPatientSelectFields = (
  results: MedicalAid.MedicalAidValidationResponse,
  selected: Partial<MedicalAid.MedicalAidPerson>,
  canEditIdNumber: boolean
): Field<PatientSelection>[] => {
  const patientOptions = results
    .map<SelectOption>((p) => ({
      display: getDisplay(p),
      value: p.medicalAidMembership?.dependantCode,
    }))
    .filter((o) => !!o.value);

  const editIdField: InputConfig<Partial<PatientSelection>> = {
    key: 'idNumber',
    type: 'text-box',
    label: strings.LabelIDNumber,
  };

  const idNumber = selected?.general?.identifierValue;

  const displayIdField: InputConfig<Partial<PatientSelection>> = {
    key: 'idNumber',
    label: strings.LabelIDNumber,
    type: 'custom',
    background: false,
    Display: () => <Text>{idNumber || ''}</Text>,
  };

  const idField = canEditIdNumber ? editIdField : displayIdField;

  const mainMemberText = selected?.medicalAidMembership?.isMainMember
    ? strings.FormLabelYes
    : strings.FormLabelNo;

  const fields: Field<Partial<PatientSelection>>[] = [
    {
      subfields: [
        {
          key: 'id',
          type: 'combobox-single',
          label: strings.CardLabelNameAndSurname,
          fetcher: patientOptions,
        },
      ],
    },
    {
      subfields: [
        {
          key: 'status',
          type: 'custom',
          label: strings.FormLabelMedicalAidStatus,
          background: false,
          Display: () => <Text>{selected?.medicalAidMembership?.status}</Text>,
        },
        {
          key: 'start',
          label: strings.FormLabelMedicalAidStart,
          background: false,
          type: 'custom',
          Display: () => <Text>{selected?.medicalAidMembership?.start}</Text>,
        },
      ],
    },
    {
      subfields: [
        idField,
        {
          key: 'birthDate',
          type: 'custom',
          label: strings.DateOfBirth,
          background: false,
          Display: () => <Text>{selected?.general?.birthDate || ''}</Text>,
        },
      ],
    },
    {
      subfields: [
        {
          key: 'prefix',
          type: 'custom',
          label: strings.LabelTitle,
          background: false,
          Display: () => <Text>{selected?.general?.prefixName || ''}</Text>,
        },
        {
          key: 'gender',
          type: 'custom',
          label: strings.LabelSex,
          background: false,
          Display: () => <Text>{selected?.general?.gender || ''}</Text>,
        },
      ],
    },
    {
      subfields: [
        {
          key: 'dependantCode',
          type: 'custom',
          background: false,
          label: strings.CardLabelDependantCode,
          Display: () => <Text>{selected?.medicalAidMembership?.dependantCode}</Text>,
        },
        {
          key: 'isMainMember',
          type: 'custom',
          background: false,
          label: strings.FormLabelIsMainMember,
          Display: () => <Text>{mainMemberText}</Text>,
        },
      ],
    },
  ];

  return fields;
};

export type NextOfKinSelection = {
  dependentCode: string;
};

export const createNextOfKinSelectFields = (
  options: Partial<MedicalAid.MedicalAidPerson>[]
): Field<NextOfKinSelection>[] => {
  const personOptions = options
    .map<SelectOption>((p) => ({
      display: getDisplay(p),
      value: p.medicalAidMembership?.dependantCode,
    }))
    .filter((o) => !!o.value);

  const otherOption: SelectOption = {
    display: strings.RadioOptionNextOfKinOther,
    value: undefined,
  };

  const fields: Field<Partial<NextOfKinSelection>>[] = [
    {
      subfields: [
        {
          key: 'dependentCode',
          type: 'radio-group',
          label: strings.CardLabelNameAndSurname,
          options: [...personOptions, otherOption],
        },
      ],
    },
  ];

  return fields;
};
