import { GroupSecurity } from '@quromedical/fhir-common';
import { Admission } from '@quromedical/models';
import { useLinkTo } from '@react-navigation/native';
import { ConfirmationButton } from 'components/input';
import { AdmissionCard, AdmissionInlineUpdateCard } from 'core/admission';
import { WithGroup } from 'core/auth';
import { Button, Section, Skeleton, SkeletonProvider, Visible } from 'design-system';
import { noOpAsync } from 'helpers';
import { logger } from 'helpers/logger';
import { useUserSession } from 'hooks';
import { useRevalidation } from 'hooks/useRevalidation';
import { PatientApi, PatientEndpoint } from 'integration/aggregate';
import React, { useCallback } from 'react';
import { strings } from 'strings';
import useSWR from 'swr';

interface PatientAdmissionsTabProps {
  patientId: string;
  hasCoverage: boolean;
  hasActiveAdmission: boolean;
}

const patientApi = new PatientApi();

const Placeholder: React.FC = () => (
  <Section>
    <Skeleton>
      <AdmissionCard downloadFile={noOpAsync} />
    </Skeleton>
  </Section>
);

/**
 * Admissions are only editable if they are `in-progress`
 */
const isEditableAdmission = (admission: Admission.GetResponse) =>
  admission.status === 'in-progress';

export const PatientAdmissionsTab: React.FC<PatientAdmissionsTabProps> = ({
  patientId,
  hasCoverage,
  hasActiveAdmission,
}) => {
  const linkTo = useLinkTo();
  const session = useUserSession();
  const canViewMedia = session.hasAny([GroupSecurity.MediaRead]);

  const onCreatePress = useCallback(() => {
    linkTo({
      screen: 'Patient',
      params: {
        screen: 'View',
        params: {
          id: patientId,
          screen: 'Admit',
        },
      },
    });
  }, [linkTo, patientId]);

  const fetcher = () => patientApi.getAdmissions(patientId);
  const swrKey = patientApi.getPatientSWRKey(patientId, PatientEndpoint.Admit);
  const { data, isValidating } = useSWR(swrKey, fetcher);
  const revalidate = useRevalidation(patientId);

  const canEditEncounter = session.hasAny([GroupSecurity.EncounterUpdate]);

  const admissions = data?.admissions || [];

  const discharge = useCallback(async () => {
    await patientApi.dischargePatient(patientId);
    revalidate().catch(logger.error);
  }, [patientId, revalidate]);

  return (
    <SkeletonProvider loading={isValidating}>
      <Section isVisible={!hasActiveAdmission}>
        <Button text={strings.AdmissionButtonTextCreate} onPress={onCreatePress} />
      </Section>

      <WithGroup groups={[GroupSecurity.EncounterUpdate]}>
        <Section isVisible={hasActiveAdmission}>
          <ConfirmationButton
            onSubmit={discharge}
            buttonText={strings.PatientDischargeDialogButtonText}
            title={strings.PatientDischargeDialogTitle}
            message={strings.PatientDischargeDialogMessage}
            status="status-critical"
          />
        </Section>
      </WithGroup>

      <Visible if={!data}>
        <Placeholder />
      </Visible>

      {admissions.map((admission, index) => {
        const isLast = index === admissions.length - 1;

        const canEdit = canEditEncounter && isEditableAdmission(admission);

        return (
          <Section key={admission.id} hasBottomMargin={isLast}>
            <Skeleton>
              <AdmissionInlineUpdateCard
                patientId={patientId}
                hasMedicalAidAuthorization={hasCoverage}
                revalidate={revalidate}
                data={admission}
                canEdit={canEdit}
                canViewMedia={canViewMedia}
              />
            </Skeleton>
          </Section>
        );
      })}
    </SkeletonProvider>
  );
};
