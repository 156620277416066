import { CreateReference } from '@quromedical/fhir-common';
import { FormCard, RowConfig } from 'design-system';
import React from 'react';
import { strings } from 'strings';

const createRows = (groups: CreateReference[] = []): RowConfig[] => {
  const label = groups.length ? strings.GroupsFormLabel : strings.NoGroupsAssigned;

  const rows: RowConfig[] = [
    {
      icon: 'people-outline',
      fields: [
        {
          type: 'list',
          label,
          display: groups.map((group) => group.display || strings.DisplayNotFound),
        },
      ],
    },
  ];

  return rows;
};

interface PractitionerGroupMembershipCardProps {
  data?: CreateReference[];
}

export const PractitionerGroupMembershipCard: React.FC<PractitionerGroupMembershipCardProps> = ({
  data = [],
}) => {
  const groupRows = createRows(data);

  const hasMemberships = data.length > 0;
  const cardColor = hasMemberships ? 'base-grey' : 'status-warning';

  return (
    <FormCard title={strings.GroupsFormLabel} showIcons rows={groupRows} cardColor={cardColor} />
  );
};
