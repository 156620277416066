import React from 'react';

interface VisibilityProps {
  /**
   * Check the truthiness of any value, defined to eliminate the need for boolean casting by the
   * consumer
   */
  isVisible?: unknown;
}

/**
 * HOC that adds an `isVisible` prop that stops a component from rendering if
 * `isVisible===false`
 * @param WrappedComponent component to be selectively hidden
 * @returns null if `isVisible` is false
 */
export function withVisibility<P>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentType<P & VisibilityProps> {
  const VisibilityControlled: React.ComponentType<P & VisibilityProps> = (props) => {
    if (props.isVisible === false) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };

  return VisibilityControlled;
}
