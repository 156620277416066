import { ScreenSize, SCREEN_SIZE, Size } from 'design-system';
import { useWindowDimensions } from 'react-native';

type ScreenMap<T> = Partial<Record<Size, T>>;

/**
 * Get the value from `map` based on the current user's screen size
 * @param map value available at each respective component level
 * @param fallback fallback value if none are found matching the greater-than comparison strategy
 * @returns
 *
 * @example
 * // will default to valueMd for screens sized smaller than `sm` in this case since all other sizes
 * // resolve to a value given the below usage:
 *
 * const value = useResponsive({
 *     sm: valueSm // screens larger than SCREEN_SIZE.sm,
 *     md: valueMd // screens larger than SCREEN_SIZE.md,
 *     xl: valueXl // screens larger than SCREEN_SIZE.xl,
 *   },
 *   valueMd
 * )
 */
export const useResponsive = <T>(map: ScreenMap<T>, fallback: T): T => {
  const { width } = useWindowDimensions();

  const screenSizes = [
    ScreenSize['5xl'],
    ScreenSize['4xl'],
    ScreenSize['3xl'],
    ScreenSize.xxl,
    ScreenSize.xl,
    ScreenSize.lg,
    ScreenSize.md,
    ScreenSize.sm,
  ];

  for (let index = 0; index < screenSizes.length; index++) {
    const key = screenSizes[index];
    const val = map[key];

    if (width > SCREEN_SIZE[key] && val) {
      return val;
    }
  }

  return map.sm || fallback;
};

/**
 * Used to check if the size of the screen is smaller than a specific value
 *
 * @example
 * const isSmallerThanSm = useIsSmallerThan('sm')
 */
export const useIsSmallerThan = (size: Size): boolean => {
  const { width } = useWindowDimensions();

  return width < SCREEN_SIZE[size];
};

/**
 * Used to check if the size of the screen is greater than or equal to a specific value
 *
 * @example
 * const useIsBiggerThan = useIsBiggerThan('sm')
 */
export const useIsBiggerThan = (size: Size): boolean => !useIsSmallerThan(size);
