import { Field } from 'components/types';
import { ampathTestFetcher } from 'integration';
import compact from 'lodash.compact';
import { strings } from 'strings';

import { FormData, TestType } from './types';

export const createFields = (testType: TestType): Field<FormData>[] => {
  const specialOrder = testType === 'special';
  const fields: (Field<FormData> | undefined)[] = [
    {
      subfields: [
        {
          type: 'radio-group',
          key: 'testType',
          label: strings.AmpathRequestTestTypeLabel,
          options: [
            {
              display: strings.AmpathRequestTestTypeNormalOption,
              value: 'normal',
            },
            {
              display: strings.AmpathRequestTestTypeSpecialOption,
              value: 'special',
            },
          ],
        },

        {
          key: 'sampleDateTime',
          type: 'masked-text-box',
          label: strings.AmpathRequestSampleDateTimeLabel,
          maskType: 'datetime',
        },
      ],
    },
    {
      subfields: [
        {
          key: 'testCodes',
          type: 'combobox-multiple',
          searchable: true,
          label: specialOrder
            ? strings.AmpathRequestTestsSpecialLabel
            : strings.AmpathRequestTestsNormalLabel,
          returnFullOption: false,
          fetcher: ampathTestFetcher({ specialOrder }),
        },
      ],
    },

    {
      subfields: [
        {
          key: 'comment',
          type: 'text-box',
          label: strings.AmpathRequestCommentLabel,
          multiline: true,
        },
      ],
    },
  ];

  return compact(fields);
};

export const initialValues: Partial<FormData> = { testType: 'normal' };
