import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Col, FlexProps, Row } from 'components/base';
import { Text, Visible } from 'design-system/base';
import { BORDER_RADIUS, BORDER_WIDTH, MARGIN } from 'design-system/theme';
import React from 'react';
import { AccessibilityRole, AccessibilityValue } from 'react-native';

interface WrapperProps {
  error?: string;
  label?: string;
  open?: boolean;
  focused?: boolean;
  border?: boolean;
  background?: boolean;
  insetLabel?: boolean;
  hideErrorSection?: boolean;
  accessibilityRole?: AccessibilityRole;
  accessibilityValue?: AccessibilityValue;
  rightAlign?: boolean;
}

interface FieldRowProps {
  error: boolean;
  open: boolean;
  focused: boolean;
  background: boolean;
  border: boolean;
}

interface BaseProps {
  open: boolean;
  focused?: boolean;
}

const Base = styled.View<BaseProps>(({ open }) => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: open ? 1 : undefined,
  overflow: 'visible',
  width: '100%',
}));

const getBorderColor = (focused: boolean, error: boolean): ColorName => {
  if (focused) {
    return 'text-default';
  }

  if (error) {
    return 'status-critical';
  }

  return 'divider';
};

const FieldRow = styled(Col)<FieldRowProps>(
  ({ theme, error, open, focused, border, background }) => {
    const borderColor = getBorderColor(focused, error);
    const borderWidth = border ? BORDER_WIDTH[2] : 0;
    const backgroundColor = background ? theme.color.base : undefined;

    return {
      position: 'relative',
      backgroundColor,
      borderStyle: 'solid',
      borderBottomWidth: borderWidth,
      borderRadius: BORDER_RADIUS[4],
      borderBottomColor: theme.color[borderColor],
      zIndex: open ? 1 : 0,
    };
  }
);

interface LabelRowProps {
  insetLabel: boolean;
}

const LabelRow = styled(Row)<LabelRowProps & FlexProps>(({ insetLabel }) => ({
  marginTop: MARGIN['2xs'],
  marginLeft: insetLabel ? MARGIN.xs : 0,
}));

const ErrorRow = styled(Row)({
  marginTop: MARGIN['2xs'],
});

export const Wrapper: React.FC<React.PropsWithChildren<WrapperProps>> = ({
  children,
  label,
  error,
  open = false,
  focused = false,
  border = true,
  background = true,
  insetLabel = true,
  accessibilityRole,
  accessibilityValue,
  hideErrorSection = false,
  rightAlign = false,
}) => {
  const rowJustification = rightAlign ? 'flex-end' : undefined;

  return (
    <Base
      open={open}
      accessibilityRole={accessibilityRole}
      accessibilityLabel={label}
      accessibilityValue={accessibilityValue}
    >
      <FieldRow
        error={!!error}
        open={open}
        focused={focused}
        border={border}
        background={background}
      >
        <Visible if={label}>
          <LabelRow justifyContent={rowJustification} insetLabel={insetLabel}>
            <Text variant="caption">{label || ''}</Text>
          </LabelRow>
        </Visible>
        <Row justifyContent={rowJustification}>{children}</Row>
      </FieldRow>
      <Visible if={!hideErrorSection}>
        <ErrorRow>
          <Text variant="caption" color="status-critical">
            {error || ''}
          </Text>
        </ErrorRow>
      </Visible>
    </Base>
  );
};
