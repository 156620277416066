import styled from '@emotion/native';
import { useNavigation } from '@react-navigation/native';
import { Col } from 'components/base';
import { PADDING, ToggleButton } from 'design-system';
import React, { useCallback } from 'react';
import { strings } from 'strings';

type LinkType = 'MedicalAid' | 'Private';

interface ToggleProps {
  active: LinkType;
}

const ToggleWrapper = styled(Col)(({ theme }) => ({
  backgroundColor: theme.color['base-grey'],
  justifyContent: 'center',
  alignItems: 'center',
  paddingVertical: PADDING.s,
}));

export const NavigationToggle: React.FC<ToggleProps> = ({ active }) => {
  const navigation = useNavigation();

  const onChange = useCallback(
    (value: LinkType) => {
      if (value === active) {
        return;
      }

      navigation.navigate('Admin', {
        screen: 'Onboard',
        params: {
          screen: value,
        },
      });
    },
    [active, navigation]
  );

  return (
    <ToggleWrapper>
      <Col alignItems="center" justifyContent="center">
        <ToggleButton<LinkType>
          onChange={onChange}
          options={[
            { value: 'MedicalAid', display: strings.FormLabelMedicalAid },
            { value: 'Private', display: strings.FormLabelPrivate },
          ]}
          value={active}
        />
      </Col>
    </ToggleWrapper>
  );
};
