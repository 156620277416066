import { ColorName } from '@emotion/react';
import { EncounterService } from '@quromedical/fhir-common';
import {
  BaseTimeSeriesRecord,
  MeasurementName,
  PostureCalibration,
  PostureReading,
} from '@quromedical/models';
import { OxygenSaturationScale, Scales } from '@quromedical/news';
import { hoursToMilliseconds, minutesToMilliseconds } from 'date-fns';
import { GradientPoint, IconName } from 'design-system';
import {
  decimalFormatter,
  integerFormatter,
  optionalDecimalFormatter,
  optionalIntegerFormatter,
} from 'helpers';
import { strings } from 'strings';

import { createGradient, createSafeScale, SafeScale } from './scales';
import { getECGSplineProps, getPerfusionIndexSplineProps } from './secondary-plots';
import { SplinePropGetter } from './types';

export const minimalMeasures: MeasurementName[] = [
  MeasurementName.CombinedArterialBloodPressure,
  MeasurementName.Glucose,
];

export const largeMeasures: MeasurementName[] = [
  MeasurementName.HeartRate,
  MeasurementName.RespiratoryRate,
  MeasurementName.CoreTemperature,
  MeasurementName.OxygenSaturation,
  MeasurementName.Posture,
];

export const trackingMeasures: MeasurementName[] = [
  MeasurementName.CombinedArterialBloodPressure,
  MeasurementName.Glucose,
  MeasurementName.Weight,
];

/**
 * Measures that represent only vital signs. Measures that are visible when creating and viewing a note and other general iterative cases
 */
export const vitalMeasures: MeasurementName[] = [
  MeasurementName.HeartRate,
  MeasurementName.RespiratoryRate,
  MeasurementName.CoreTemperature,
  MeasurementName.OxygenSaturation,
  MeasurementName.Posture,
  MeasurementName.CombinedArterialBloodPressure,
  MeasurementName.SystolicArterialBloodPressure,
  MeasurementName.DiastolicArterialBloodPressure,
  MeasurementName.PerfusionIndex,
  MeasurementName.Glucose,
];

export const colors: Partial<Record<MeasurementName, ColorName>> = {
  [MeasurementName.OxygenSaturation]: 'measure-oxygen-saturation',
  [MeasurementName.PerfusionIndex]: 'measure-oxygen-saturation',
  [MeasurementName.CoreTemperature]: 'measure-core-temperature',
  [MeasurementName.HeartRate]: 'measure-heart-rate',
  [MeasurementName.RespiratoryRate]: 'measure-respiratory-rate',
  [MeasurementName.ECG]: 'measure-ecg',
  [MeasurementName.CombinedArterialBloodPressure]: 'measure-blood-pressure',
  [MeasurementName.MeanArterialBloodPressure]: 'measure-blood-pressure-map',
  [MeasurementName.SystolicArterialBloodPressure]: 'measure-blood-pressure-systolic',
  [MeasurementName.DiastolicArterialBloodPressure]: 'measure-blood-pressure-diastolic',
  [MeasurementName.Glucose]: 'measure-glucose',
  [MeasurementName.Posture]: 'measure-posture',
  [MeasurementName.Weight]: 'measure-weight',
};

export const scales: Partial<Record<MeasurementName, SafeScale>> = {
  [MeasurementName.OxygenSaturation]: createSafeScale(Scales.spo2Scale1),
  [MeasurementName.CoreTemperature]: createSafeScale(Scales.temperature, 1),
  [MeasurementName.HeartRate]: createSafeScale(Scales.pulseRate),
  [MeasurementName.RespiratoryRate]: createSafeScale(Scales.respirationRate),
  [MeasurementName.SystolicArterialBloodPressure]: createSafeScale(Scales.systolicBloodPressure),
};

export const oxygenSaturationScales: Partial<Record<OxygenSaturationScale, SafeScale>> = {
  [OxygenSaturationScale.Scale1]: createSafeScale(Scales.spo2Scale1),
  [OxygenSaturationScale.Scale2Oxygen]: createSafeScale(Scales.spo2Scale2Oxygen),
  [OxygenSaturationScale.Scale2Air]: createSafeScale(Scales.spo2Scale2Air),
};

export const staleDurations: Partial<Record<MeasurementName, number>> = {
  [MeasurementName.HeartRate]: minutesToMilliseconds(5),
  [MeasurementName.Posture]: minutesToMilliseconds(5),
  [MeasurementName.RespiratoryRate]: minutesToMilliseconds(5),
  [MeasurementName.CoreTemperature]: minutesToMilliseconds(5),
  [MeasurementName.OxygenSaturation]: hoursToMilliseconds(4),
  [MeasurementName.Steps]: hoursToMilliseconds(4),
  [MeasurementName.Glucose]: hoursToMilliseconds(6),
  [MeasurementName.CombinedArterialBloodPressure]: hoursToMilliseconds(4),
  [MeasurementName.SystolicArterialBloodPressure]: hoursToMilliseconds(4),
  [MeasurementName.DiastolicArterialBloodPressure]: hoursToMilliseconds(4),
  [MeasurementName.MeanArterialBloodPressure]: hoursToMilliseconds(4),
};

export const domains: Partial<Record<MeasurementName, [number, number]>> = {
  [MeasurementName.OxygenSaturation]: [80, 100],
  [MeasurementName.PerfusionIndex]: [0, 20],
  [MeasurementName.CoreTemperature]: [34, 40],
  [MeasurementName.HeartRate]: [35, 160],
  [MeasurementName.RespiratoryRate]: [0, 40],
  // exclude not found/unknown posture readings
  [MeasurementName.Posture]: [1, 6],
  [MeasurementName.CombinedArterialBloodPressure]: [0, 300],
};

export const retainDefaultYDomain: Partial<Record<MeasurementName, boolean>> = {
  [MeasurementName.Posture]: true,
};

export const units: Partial<Record<MeasurementName, string>> = {
  [MeasurementName.OxygenSaturation]: '%',
  [MeasurementName.CoreTemperature]: '°C',
  [MeasurementName.HeartRate]: '/min',
  [MeasurementName.RespiratoryRate]: '/min',
  [MeasurementName.Steps]: 'steps',
  [MeasurementName.Posture]: '',
  [MeasurementName.Battery]: '%',
  [MeasurementName.Glucose]: 'mmol/L',
  [MeasurementName.SystolicArterialBloodPressure]: 'mm Hg',
  [MeasurementName.DiastolicArterialBloodPressure]: 'mm Hg',
  [MeasurementName.MeanArterialBloodPressure]: 'mm Hg',
  [MeasurementName.CombinedArterialBloodPressure]: 'mm Hg',
  [MeasurementName.Weight]: 'kg',
};

export const codes: Partial<Record<MeasurementName, string>> = {
  [MeasurementName.OxygenSaturation]: 'SpO2',
  [MeasurementName.PerfusionIndex]: 'PI',
  [MeasurementName.CoreTemperature]: 'Temp',
  [MeasurementName.HeartRate]: 'HR',
  [MeasurementName.RespiratoryRate]: 'RR',
  [MeasurementName.ECG]: 'ECG',
  [MeasurementName.Glucose]: 'GLU',
  [MeasurementName.MeanArterialBloodPressure]: 'MAP',
  [MeasurementName.CombinedArterialBloodPressure]: 'BP',
  [MeasurementName.Posture]: 'POS',
  // weight would be too long to display in the code section of the graphs
  [MeasurementName.Weight]: 'WT',
};

export const icons: Partial<Record<MeasurementName, IconName>> = {
  [MeasurementName.OxygenSaturation]: 'oxygen-saturation',
  [MeasurementName.CoreTemperature]: 'temperature',
  [MeasurementName.HeartRate]: 'heart',
  [MeasurementName.RespiratoryRate]: 'lungs',
  [MeasurementName.Steps]: 'activity',
  [MeasurementName.Posture]: 'laying-down',
  [MeasurementName.Battery]: 'battery-std',
  [MeasurementName.Weight]: 'scale-bathroom',
  [MeasurementName.CombinedArterialBloodPressure]: 'blood-pressure',
  [MeasurementName.Glucose]: 'glucose-measure',
  [MeasurementName.ECG]: 'heart-cardiogram',
};

export const titles: Partial<Record<MeasurementName, string>> = {
  [MeasurementName.OxygenSaturation]: strings.VitalTitleOxygenSaturation,
  [MeasurementName.PerfusionIndex]: strings.VitalTitlePerfusionIndex,
  [MeasurementName.CoreTemperature]: strings.VitalTitleCoreTemperature,
  [MeasurementName.HeartRate]: strings.VitalTitleHeartRate,
  [MeasurementName.RespiratoryRate]: strings.VitalTitleRespiratoryRate,
  [MeasurementName.Posture]: strings.VitalTitlePosture,
  [MeasurementName.Steps]: strings.VitalTitleSteps,
  [MeasurementName.ECG]: strings.VitalTitleECG,
  [MeasurementName.Glucose]: strings.VitalTitleGlucose,
  [MeasurementName.SystolicArterialBloodPressure]: strings.VitalTitleSystolicArterialBloodPressure,
  [MeasurementName.DiastolicArterialBloodPressure]:
    strings.VitalTitleDiastolicArterialBloodPressure,
  [MeasurementName.MeanArterialBloodPressure]: strings.VitalTitleMeanArterialBloodPressure,
  [MeasurementName.CombinedArterialBloodPressure]: strings.VitalTitleCombinedArterialBloodPressure,
  [MeasurementName.Weight]: strings.VitalTitleWeight,
  [MeasurementName.PostureCalibration]: strings.VitalTitlePostureCalibration,
};

export const gradientStops: Partial<Record<MeasurementName, GradientPoint[]>> = {
  [MeasurementName.OxygenSaturation]: createGradient(Scales.spo2Scale1Bounds),
  [MeasurementName.CoreTemperature]: createGradient(Scales.temperatureBounds),
  [MeasurementName.HeartRate]: createGradient(Scales.pulseRateBounds),
  [MeasurementName.RespiratoryRate]: createGradient(Scales.respirationRateBounds),
};

type RequiredFormatter = (value?: number) => string;
type OptionalFormatter = (value?: number) => string | undefined;

const postureDisplays: Partial<Record<PostureReading, string>> = {
  [PostureReading.LayingDown]: strings.PostureLayingDown,
  [PostureReading.LeaningBack]: strings.PostureLeaningBack,
  [PostureReading.NotFound]: strings.PostureNotFound,
  [PostureReading.Running]: strings.PostureRunning,
  [PostureReading.Sitting]: strings.PostureSitting,
  [PostureReading.Standing]: strings.PostureStanding,
  [PostureReading.Unknown]: strings.PostureUnknown,
  [PostureReading.Walking]: strings.PostureWalking,
};

const shortPostureDisplays: Partial<Record<PostureReading, string>> = {
  ...postureDisplays,
  [PostureReading.LayingDown]: strings.PostureLayingDownShort,
  [PostureReading.LeaningBack]: strings.PostureLeaningBackShort,
};

const postureCalibrationDisplays: Partial<Record<PostureCalibration, string>> = {
  [PostureCalibration.LayingSupine]: strings.PostureCalibrationLayingSupine,
  [PostureCalibration.Upright]: strings.PostureCalibrationUpright,
};

type Filter = (record: BaseTimeSeriesRecord) => boolean;

/**
 * Returns a false if the record value is either `NotFound` or `Unknown`
 */
const postureFilter: Filter = (record) => record.value > 0;

export const measureFilter: Partial<Record<MeasurementName, Filter>> = {
  [MeasurementName.Posture]: postureFilter,
};

const postureFormatter = (value?: number) => postureDisplays[value as PostureReading];
const requiredPostureFormatter = (value?: number) => postureFormatter(value) || '';

const postureCalibrationFormatter = (value?: number) =>
  postureCalibrationDisplays[value as PostureCalibration];

const requiredPostureCalibrationFormatter = (value?: number) =>
  postureCalibrationFormatter(value) || '';

const shortPostureFormatter = (value?: number) =>
  shortPostureDisplays[value as PostureReading] || '';

/**
 * Some measurements can't be represented as number -> string, e.g. Combined blood pressure is a
 * string, this is a placeholder until the end-to-end graphing components can be updated to handle
 * it appropriately
 */
const unFormatted = (value?: string | number) => value?.toString() || '';

/**
 * Format values to a string, returning undefined if the value can't be converted
 */
export const optionalValueFormatters: Record<MeasurementName, OptionalFormatter> = {
  [MeasurementName.OxygenSaturation]: optionalDecimalFormatter,
  [MeasurementName.PerfusionIndex]: optionalDecimalFormatter,
  [MeasurementName.CoreTemperature]: optionalDecimalFormatter,
  [MeasurementName.HeartRate]: optionalIntegerFormatter,
  [MeasurementName.RespiratoryRate]: optionalIntegerFormatter,
  [MeasurementName.Battery]: optionalIntegerFormatter,
  [MeasurementName.SkinTemperature]: optionalDecimalFormatter,
  [MeasurementName.Steps]: optionalIntegerFormatter,
  [MeasurementName.RRI]: optionalIntegerFormatter,
  [MeasurementName.Posture]: postureFormatter,
  [MeasurementName.ECG]: optionalIntegerFormatter,
  [MeasurementName.Glucose]: optionalDecimalFormatter,
  [MeasurementName.SystolicArterialBloodPressure]: optionalIntegerFormatter,
  [MeasurementName.DiastolicArterialBloodPressure]: optionalIntegerFormatter,
  [MeasurementName.MeanArterialBloodPressure]: optionalIntegerFormatter,
  [MeasurementName.CombinedArterialBloodPressure]: unFormatted,
  [MeasurementName.Weight]: decimalFormatter,
  [MeasurementName.PostureCalibration]: postureCalibrationFormatter,
};

/**
 * Format value strings to a value or undefined. Ensure that we don't return an empty string
 */
export const valueFormatters: Record<MeasurementName, RequiredFormatter> = {
  [MeasurementName.OxygenSaturation]: decimalFormatter,
  [MeasurementName.PerfusionIndex]: decimalFormatter,
  [MeasurementName.CoreTemperature]: decimalFormatter,
  [MeasurementName.HeartRate]: integerFormatter,
  [MeasurementName.RespiratoryRate]: integerFormatter,
  [MeasurementName.Battery]: integerFormatter,
  [MeasurementName.SkinTemperature]: decimalFormatter,
  [MeasurementName.Steps]: integerFormatter,
  [MeasurementName.RRI]: integerFormatter,
  [MeasurementName.Posture]: requiredPostureFormatter,
  [MeasurementName.ECG]: integerFormatter,
  [MeasurementName.Glucose]: decimalFormatter,
  [MeasurementName.SystolicArterialBloodPressure]: integerFormatter,
  [MeasurementName.DiastolicArterialBloodPressure]: integerFormatter,
  [MeasurementName.MeanArterialBloodPressure]: integerFormatter,
  [MeasurementName.CombinedArterialBloodPressure]: unFormatted,
  [MeasurementName.Weight]: decimalFormatter,
  [MeasurementName.PostureCalibration]: requiredPostureCalibrationFormatter,
};

export const shortValueFormatters: Partial<Record<MeasurementName, RequiredFormatter>> = {
  ...valueFormatters,
  [MeasurementName.Posture]: shortPostureFormatter,
};

/**
 * Associations of which primary graph is associated with a secondary meta graph
 */
export const secondaryGraphMeasure: Partial<Record<MeasurementName, MeasurementName>> = {
  [MeasurementName.HeartRate]: MeasurementName.ECG,
  [MeasurementName.OxygenSaturation]: MeasurementName.PerfusionIndex,
};

export const splinePropGetters: Partial<Record<MeasurementName, SplinePropGetter>> = {
  [MeasurementName.ECG]: getECGSplineProps,
  [MeasurementName.PerfusionIndex]: getPerfusionIndexSplineProps,
};

interface BaseSplineProps {
  showActions: boolean;
  showSlider: boolean;
  durationLarge?: number;
  durationSmall?: number;
}

export const baseSplineProps: Partial<Record<MeasurementName, BaseSplineProps>> = {
  [MeasurementName.ECG]: {
    showActions: true,
    showSlider: true,
    durationLarge: 6000,
    durationSmall: 3000,
  },
  [MeasurementName.PerfusionIndex]: {
    showActions: false,
    showSlider: false,
  },
};

export enum MonitoringConfiguration {
  HighIntensity = 'high',
  MediumIntensity = 'medium',
  LowIntensity = 'low',
}

export const monitoringConfigurationDisplay: Record<MonitoringConfiguration, string> = {
  [MonitoringConfiguration.HighIntensity]: strings.MonitoringConfigurationHighIntensity,
  [MonitoringConfiguration.MediumIntensity]: strings.MonitoringConfigurationMediumIntensity,
  [MonitoringConfiguration.LowIntensity]: strings.MonitoringConfigurationLowIntensity,
};

type MonitoringPlanItem = {
  /**
   * if not specified will be defaulted to -Infinity
   */
  dayFrom?: number;
  /**
   * if not specified will be defaulted to +Infinity
   */
  dayTo?: number;
  monitoringConfiguration: MonitoringConfiguration;
};

type MonitoringPlan = MonitoringPlanItem[];

/**
 * Mapping of encounter services to monitoring plans, like care plans for monitoring configurations
 */
const monitoringPlanMap: Partial<Record<EncounterService, MonitoringPlan>> = {
  [EncounterService.LACE]: [
    {
      dayFrom: 0,
      dayTo: 7,
      monitoringConfiguration: MonitoringConfiguration.HighIntensity,
    },
    {
      dayFrom: 8,
      dayTo: 15,
      monitoringConfiguration: MonitoringConfiguration.MediumIntensity,
    },
    {
      dayFrom: 16,
      monitoringConfiguration: MonitoringConfiguration.LowIntensity,
    },
  ],
};

const isDayInPlan = (plan: MonitoringPlanItem, dayOfService: number): boolean => {
  const { dayFrom = -Infinity, dayTo = Infinity } = plan;

  return dayOfService >= dayFrom && dayOfService <= dayTo;
};

/**
 * Get the monitoring configuration for a given plan and day combination
 */
export const getMonitoringConfiguration = (
  encounterService: EncounterService,
  dayOfService?: number
): MonitoringConfiguration => {
  const monitoringPlan = monitoringPlanMap[encounterService];
  if (!monitoringPlan) {
    return MonitoringConfiguration.HighIntensity;
  }

  if (typeof dayOfService === 'undefined') {
    return MonitoringConfiguration.HighIntensity;
  }

  const planItem = monitoringPlan.find((plan) => isDayInPlan(plan, dayOfService));

  return planItem?.monitoringConfiguration || MonitoringConfiguration.HighIntensity;
};

const passiveMonitoringMeasurements: MeasurementName[] = [
  MeasurementName.CombinedArterialBloodPressure,
  MeasurementName.Glucose,
  MeasurementName.Weight,
  MeasurementName.OxygenSaturation,
  MeasurementName.HeartRate,
];
/**
 * An undefined value indicates that all vitals should be included
 */
const monitoringConfigurationVitalsMap: Record<
  MonitoringConfiguration,
  MeasurementName[] | undefined
> = {
  [MonitoringConfiguration.HighIntensity]: undefined,
  [MonitoringConfiguration.MediumIntensity]: passiveMonitoringMeasurements,
  [MonitoringConfiguration.LowIntensity]: passiveMonitoringMeasurements,
};

const allMeasures = Object.values(MeasurementName);

export const getVitalsForMonitoringConfiguration = (
  monitoringConfiguration: MonitoringConfiguration = MonitoringConfiguration.HighIntensity
): MeasurementName[] => {
  const filteredMeasures = monitoringConfigurationVitalsMap[monitoringConfiguration];

  return filteredMeasures || allMeasures;
};
