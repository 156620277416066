import { isValid, differenceInDays } from 'date-fns';
import { maskedInputFormatter } from 'design-system/components';
import humanizeDuration, { Unit } from 'humanize-duration';

export const getDisplayDate = (date?: string) =>
  date ? maskedInputFormatter.date(new Date(date)) : undefined;

const parseDate = (isoDate?: string | number): Date | undefined => {
  if (typeof isoDate === 'number') {
    return new Date(isoDate);
  }

  if (!isoDate) {
    return undefined;
  }

  const parsed = new Date(isoDate);

  if (!isValid(parsed)) {
    return undefined;
  }

  return parsed;
};

const getRelativeAge = (isoDate?: string, relative = new Date()): number | undefined => {
  const parsed = parseDate(isoDate);

  if (!parsed) {
    return undefined;
  }

  const age = relative.getTime() - parsed.getTime();

  return age;
};

/**
 * Get the display for a duration of time in the specified unit, rounded
 */
export const getSingleDurationDisplay = (unit: Unit, isoDate?: string): string | undefined => {
  const age = getRelativeAge(isoDate);

  if (!age) {
    return undefined;
  }

  return humanizeDuration(age, {
    largest: 1,
    round: true,
    units: [unit],
  });
};

export const getDurationInDays = (
  isoDate?: string | number,
  relativeDate: string | number = Date.now()
): number | undefined => {
  const parsed = parseDate(isoDate);
  const relative = parseDate(relativeDate);

  const bothValid = parsed && relative;

  if (!bothValid) {
    return undefined;
  }

  return differenceInDays(relative, parsed);
};
