import styled from '@emotion/native';
import { Flex } from 'components/base';
import { Icon, Visible, Text, BASE_SIZE, MARGIN, IconName } from 'design-system';
import React, { ElementType, PropsWithChildren, ReactElement } from 'react';
import { ViewProps } from 'react-native';
import { HeaderGroup } from 'react-table';

import { PositionProps } from './PositionProps';

interface DataTableHeaderProps<Data extends object> {
  headerGroups: HeaderGroup<Data>[];
  columnConfig?: HeaderColumnConfigProps<Data>;
  icon?: IconName;
  Header: ElementType;
  Row: ElementType<PositionProps>;
  Cell: ElementType<PositionProps>;
}

interface CellWrapperProps {
  flex?: number;
}

const CellWrapper = styled.View<CellWrapperProps>(({ flex = 1 }) => ({
  flex,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
}));

const IconWrapper = styled(Flex)(() => ({
  marginRight: MARGIN['2xs'],
}));

export interface HeaderColumnConfig {
  flex?: number;
  icon?: IconName;
}

export type HeaderColumnConfigProps<Data extends object> = Partial<
  Record<keyof Data, HeaderColumnConfig>
>;

export const DataTableHeader = <Data extends object>({
  headerGroups,
  columnConfig = {},
  Header,
  Row,
  Cell,
}: PropsWithChildren<DataTableHeaderProps<Data>>): ReactElement => (
  <Header>
    {headerGroups.map((headerGroup, headerGroupIndex) => (
      <Row {...headerGroup.getHeaderGroupProps()} index={headerGroupIndex}>
        {headerGroup.headers.map((cell) => {
          const isSortable = cell.isSortedDesc === undefined && !cell.disableSortBy;
          const iconName = cell.isSortedDesc ? 'sort-amount-desc' : 'sort-amount-asc';
          const key = cell.id as keyof Data;

          const config = columnConfig[key] || {};
          const fallbackIcon = config?.icon || 'sort';

          return (
            <CellWrapper
              {...(cell.getHeaderProps(cell.getSortByToggleProps()) as ViewProps)}
              flex={config?.flex}
            >
              <IconWrapper isVisible={isSortable || cell.isSorted}>
                <Visible if={isSortable}>
                  <Icon name={fallbackIcon} size={BASE_SIZE[16]} />
                </Visible>
                <Visible if={cell.isSorted}>
                  <Icon name={iconName} size={BASE_SIZE[12]} />
                </Visible>
              </IconWrapper>
              <Cell>
                {/* We not using the "Header" key word as it is a reserved keywords used by react
                  table so we have opted to use "header" without the capital letter */}
                <Text variant="body-strong">{cell.render('header')}</Text>
              </Cell>
            </CellWrapper>
          );
        })}
      </Row>
    ))}
  </Header>
);
