import { useCallback, useState } from 'react';

type Key = string | number;

interface Item<TKey extends Key, TValue> {
  key: TKey;
  value: TValue;
}

interface UseListResult<TKey extends Key, TValue> {
  add: (key: TKey, value: TValue) => void;
  remove: (key: TKey) => void;
  update: (key: TKey, newValue: TValue) => void;
  items: Item<TKey, TValue>[];
}

/**
 * Ensure list items have unique keys if required, this is not handled by this hook
 * @param initial
 * @returns
 */

export const useList = <TKey extends Key, TValue>(
  initial: Item<TKey, TValue>[] = []
): UseListResult<TKey, TValue> => {
  const [items, setItems] = useState(initial);

  const add = useCallback(
    (key: TKey, value: TValue) => {
      setItems([...items, { key, value }]);
    },
    [items]
  );

  const remove = useCallback(
    (key: TKey) => {
      setItems(items.filter((item) => item.key !== key));
    },
    [items]
  );

  const update = useCallback(
    (key: TKey, newValue: TValue) => {
      setItems(items.map((item) => (item.key === key ? { key, value: newValue } : item)));
    },
    [items]
  );

  return { items, add, remove, update };
};
