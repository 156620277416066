import styled from '@emotion/native';
import { Logo as BrandLogo } from 'design-system/brand';
import { BASE_SIZE, MARGIN } from 'design-system/theme';
import React from 'react';

const Wrapper = styled.View({
  height: BASE_SIZE[48],
  marginRight: MARGIN.m,
});

export const Logo: React.FC = () => (
  <Wrapper>
    <BrandLogo size={BASE_SIZE[42]} />
  </Wrapper>
);
