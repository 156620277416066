import { ConfirmationButton } from 'components/input';
import { logger } from 'helpers';
import { useRevalidation } from 'hooks/useRevalidation';
import { PractitionerApi } from 'integration/aggregate';
import React, { useCallback } from 'react';
import { strings } from 'strings';

interface PractitionerActiveToggleButtonProps {
  id: string;
  isActive: boolean;
  canEdit: boolean;
}

const api = new PractitionerApi();

export const PractitionerActiveToggleButton: React.FC<PractitionerActiveToggleButtonProps> = ({
  id,
  isActive,
  canEdit,
}) => {
  const revalidate = useRevalidation(id);

  const activate = useCallback(async () => {
    await api.activate(id);
    revalidate().catch(logger.error);
  }, [id, revalidate]);

  const deactivate = useCallback(async () => {
    await api.deactivate(id);
    revalidate().catch(logger.error);
  }, [id, revalidate]);

  if (!canEdit) {
    return null;
  }

  if (isActive) {
    return (
      <ConfirmationButton
        onSubmit={deactivate}
        buttonText={strings.PractitionerDeactivateDialogButtonText}
        title={strings.PractitionerDeactivateDialogTitle}
        message={strings.PractitionerDeactivateDialogMessage}
        status="status-critical"
      />
    );
  }

  return (
    <ConfirmationButton
      onSubmit={activate}
      buttonText={strings.PractitionerActivateDialogButtonText}
      title={strings.PractitionerActivateDialogTitle}
      message={strings.PractitionerActivateDialogMessage}
      status="status-warning"
    />
  );
};
