import { Col, Row } from 'components/base';
import { Button, FormCard, RowConfig, Text } from 'design-system';
import { Section } from 'design-system/layout';
import { useUserSession } from 'hooks';
import { useLogout } from 'hooks/useLogout';
import React from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { PersonContactForm, PersonGeneralForm } from 'screens/person';
import { strings } from 'strings';

import { PreferenceCard } from './cards';

const createRows = (groupNames: string[] = []): RowConfig[] => [
  {
    icon: 'people-outline',
    fields: [
      {
        type: 'list',
        label: strings.GroupsFormLabel,
        display: groupNames,
      },
    ],
  },
];

export const UserProfileScreen: React.FC = () => {
  const logout = useLogout();

  const session = useUserSession();
  const profile = session.getProfile();

  const groupNames = profile?.groups.map((group) => group.display || strings.ResourceNameMissing);
  const groupRows = createRows(groupNames);

  return (
    <ScrollView>
      <Section>
        <Row justifyContent="space-between">
          <Col>
            <Text variant="page-heading">{strings.UserProfileCardTitle}</Text>
          </Col>
          <Col>
            <Button text={strings.ButtonTextSignOut} onPress={logout} />
          </Col>
        </Row>
      </Section>

      <Section>
        <PreferenceCard />
      </Section>

      {profile ? (
        <>
          <Section>
            <PersonGeneralForm canEdit={false} data={profile.practitioner.general} />
          </Section>
          <Section>
            <PersonContactForm
              canEdit={false}
              data={profile.practitioner.contact}
              hasEmergencyAccess={false}
            />
          </Section>
        </>
      ) : null}

      <Section hasBottomMargin>
        <FormCard title={strings.GroupsFormLabel} showIcons rows={groupRows} />
      </Section>
    </ScrollView>
  );
};
