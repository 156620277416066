import { logger } from 'helpers';
import { useCallback, useEffect, useState } from 'react';
import { AnySchema } from 'yup';

import { useAsyncStorageData } from './useAsyncStorageData';

type UseCachedStateResult<TState> = readonly [
  state: TState,
  setState: (value: TState) => void,
  restoredKey: number
];

export const useCachedState = <TState>(
  key: string,
  initial: TState,
  restoreSchema: AnySchema
): UseCachedStateResult<TState> => {
  const [state, setState] = useState(initial);
  const [restoredKey, setRestoredKey] = useState(0);
  const { setData, getData } = useAsyncStorageData<TState>(key, initial);

  const isStateReady = !!restoredKey;

  useEffect(() => {
    if (isStateReady) {
      return;
    }

    const exec = async () => {
      const restored = await getData();
      const isValid = await restoreSchema.isValid(restored);

      if (!isValid) {
        setData(initial).catch(logger.error);
      } else {
        setState(restored);
      }

      setRestoredKey(Date.now());
    };

    exec().catch(logger.error);
  }, [getData, initial, isStateReady, restoreSchema, restoredKey, setData]);

  const setValue = useCallback(
    (value: TState) => {
      if (!isStateReady) {
        return;
      }

      setState(value);
      setData(value).catch(logger.error);
    },
    [isStateReady, setData]
  );

  return [state, setValue, restoredKey];
};
