// converted using SVGR with React-Native and Typescript Options
// https://react-svgr.com/playground/?native=true&typescript=true
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { Icon } from './internal';

export const LayingDown: Icon = ({ color = '#000000', size = 24 }) => (
  <Svg width={size} height={size} fill="none" viewBox="0 0 24 24">
    <Path
      d="M21.337 12.568H3.054V6.027C3.054 5.469 2.608 5 2.027 5 1.469 5 1 5.446 1 6.027v11.028c0 .558.446 1.027 1.027 1.027.558 0 1.027-.447 1.027-1.027v-2.456h17.122v2.456c0 .558.447 1.027 1.027 1.027.559 0 1.027-.447 1.027-1.027v-3.594a.895.895 0 0 0-.893-.893Z"
      fill={color}
    />
    <Path
      d="M10.22 11.63h4.465c.334 0 .647-.134.848-.38l2.165-2.41 2.411 2.277a1.14 1.14 0 0 0 1.63-.045 1.14 1.14 0 0 0-.045-1.63l-3.281-3.125a1.092 1.092 0 0 0-.849-.312c-.29.022-.58.156-.803.402L15.265 8.08h-4.978c-.179 0-.804.022-1.295.513a1.739 1.739 0 0 0-.491 1.228c0 1.094.893 1.741 1.719 1.808ZM7.965 9.599a1.897 1.897 0 1 1-3.795 0 1.897 1.897 0 0 1 3.795 0Z"
      fill={color}
    />
  </Svg>
);
