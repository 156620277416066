import { GroupCode, GroupCodeDescription, GroupDescription } from '@quromedical/fhir-common';
import { Row } from 'components/base';
import { DisplayView } from 'components/fhir';
import { ConfirmationButton } from 'components/input';
import { ColumnType } from 'components/table';
import { Text } from 'design-system';
import { logger } from 'helpers';
import { useRevalidation } from 'hooks/useRevalidation';
import { GroupApi } from 'integration/GroupApi';
import React, { useCallback } from 'react';
import { CellProps } from 'react-table';
import { strings } from 'strings';

import { TableGroup } from '../config';

const api = new GroupApi();

export const columnsLarge: ColumnType<TableGroup>[] = [
  {
    header: strings.LabelActive,
    accessor: 'active',
    Cell: ({ value }) => <Text variant="body-strong">{value ? 'Active' : 'Inactive'}</Text>,
  } as ColumnType<TableGroup, 'active'>,
  {
    header: strings.DeviceTableTypeSubheading,
    accessor: 'type',
    Cell: ({ value }) => <Text>{GroupCodeDescription[value as GroupCode] || value}</Text>,
  } as ColumnType<TableGroup, 'type'>,
  {
    header: strings.DeviceTableNameSubheading,
    accessor: 'name',
  } as ColumnType<TableGroup, 'name'>,
  {
    header: strings.LabelPermissions,
    Cell: ({ value = [] }) =>
      value.map((permission, index) => (
        <Row key={index}>
          <Text>{GroupDescription[permission] || ''}</Text>
        </Row>
      )),
    accessor: 'permissions',
  } as ColumnType<TableGroup, 'permissions'>,
  {
    header: strings.LabelOrganization,
    Cell: ({ value }) => <DisplayView reference={value} />,
    accessor: 'organization',
  } as ColumnType<TableGroup, 'organization'>,
];

const createActionCell = (groupId: string) => {
  const AddCell: React.FC<CellProps<fhir4.GroupMember>> = ({ row }) => {
    const member = row.original;

    // list data is still using the old FHIR endpoint
    const revalidate = useRevalidation(groupId);

    const onSubmit = useCallback(async () => {
      const updateMember: fhir4.GroupMember = {
        ...member,
        inactive: false,
      };

      await api.updateGroupMembers(groupId, [updateMember]);
      await revalidate().catch(logger.error);
    }, [member, revalidate]);

    return (
      <ConfirmationButton
        status="status-warning"
        title="Add Member"
        message="Are you sure you want to re-add this member to the group?"
        onSubmit={onSubmit}
        buttonText="Re-add"
      />
    );
  };

  const RemoveCell: React.FC<CellProps<fhir4.GroupMember>> = ({ row }) => {
    const member = row.original;
    // list data is still using the old FHIR endpoint
    const revalidate = useRevalidation(groupId);

    const onSubmit = useCallback(async () => {
      const updateMember: fhir4.GroupMember = {
        ...member,
        inactive: true,
      };

      await api.updateGroupMembers(groupId, [updateMember]);
      await revalidate().catch(logger.error);
    }, [member, revalidate]);

    return (
      <ConfirmationButton
        status="status-critical"
        title="Remove Member"
        message="Are you sure you want to remove this member from the group?"
        onSubmit={onSubmit}
        buttonText="Remove"
      />
    );
  };

  const ActionCell: ColumnType<fhir4.GroupMember, 'id'>['Cell'] = (props) => {
    const member = props.row.original;

    if (member.inactive) {
      return <AddCell {...props} />;
    }

    return <RemoveCell {...props} />;
  };

  return ActionCell;
};

export const memberColumns = (groupId: string): ColumnType<fhir4.GroupMember>[] => [
  {
    accessor: 'entity',
    header: strings.LabelEntity,
    Cell: ({ value }) => <DisplayView reference={value} />,
  } as ColumnType<fhir4.GroupMember, 'entity'>,
  {
    accessor: 'inactive',
    header: strings.FormLabelActive,
    Cell: ({ value }) => <Text>{value ? 'Inactive' : 'Active'}</Text>,
  } as ColumnType<fhir4.GroupMember, 'inactive'>,
  {
    accessor: 'id',
    header: strings.FormLabelActive,
    Cell: createActionCell(groupId),
  } as ColumnType<fhir4.GroupMember, 'id'>,
];
