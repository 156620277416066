import { Bundle } from '@quromedical/fhir-common';

import { FhirBaseApi } from './FhirBaseApi';

export interface Params {
  count?: number;
  pageToken?: string;
  subject?: string;
  operator?: string;
  device?: string;
}

export class MediaApi extends FhirBaseApi<fhir4.Media> {
  constructor() {
    super('api/fhir/media');
  }

  getMedias = async (params: Params): Promise<Bundle<fhir4.Media>> => this.getAll(params);

  getMedia = async (id: string): Promise<fhir4.Media> => this.get(id);

  createMedia = async (res: fhir4.Media): Promise<fhir4.Media> => this.post(res);
}
