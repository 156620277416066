import { Link } from '@react-navigation/native';
import { Row } from 'components/base';
import { Icon, Text, Visible } from 'design-system';
import { LinkTo } from 'navigation';
import React from 'react';

interface Link {
  to: LinkTo;
  text: string;
}
interface BreadcrumbProps {
  links: Link[];
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ links }) => (
  <Row alignItems="center">
    {links.map((link, i) => (
      <React.Fragment key={link.text}>
        <Visible if={i}>
          <Icon name="chevron-right" />
        </Visible>
        <Link to={link.to}>
          <Text>{link.text}</Text>
        </Link>
      </React.Fragment>
    ))}
  </Row>
);
