import styled from '@emotion/native';
import { Row, withVisibility } from 'components/base';
import { MARGIN } from 'design-system';
import React, { ComponentType } from 'react';
import { View } from 'react-native';

interface BaseProps {
  Left?: ComponentType;
  Right: ComponentType;
}

const Wrapper = styled.View({
  marginTop: MARGIN.s,
});

const Base: React.FC<BaseProps> = ({ Left, Right }) => (
  <Wrapper>
    <Row justifyContent="space-between" alignItems="center" flexWrap="wrap">
      <View>{Left ? <Left /> : null}</View>
      {Right ? (
        <View>
          <Right />
        </View>
      ) : null}
    </Row>
  </Wrapper>
);

export const Footer = withVisibility<BaseProps>(Base);
