import { BaseTimeSeriesRecord, MeasurementName } from '@quromedical/models';
import { Domain } from 'components/graph';
import { MILLISECONDS } from 'constants/time';
import { getExpandedDomain, VitalCardSplineProps } from 'design-system';
import maxBy from 'lodash.maxby';
import range from 'lodash.range';

import { colors, domains } from './records';
import { SplinePropGetter } from './types';

/**
 * General implementation Guidelines
 *
 * - We need the following yTicks: `-1500, -1000, -500, 0, 500, 1000, 1500` always
 * - According to that, we should show aspectRatio * 6 blocks in the time direction
 * - Each time block is `0.2s`
 * - This means we have `aspectRatio * 0.2s = duration` of data in view at any time
 *
 *
 * > Note: Currently we read ECG at `125/s` which means `125 * duration` data points in view
 */

// constants based on the way we display ECG data
const ecgYBlockCount = 6;
const ecgYAllTicks = [-1500, -1000, -500, 0, 500, 1000, 1500];
const ecgYCenterTick = [0];
const ecgYDomain: Domain = [-1500, 1500];

const ecgXSubBlockDurationMs = 0.2 * MILLISECONDS.second;
const ecgXBlockDurationMs = MILLISECONDS.second;

interface YTicks {
  yTicks: number[];
  ySubTicks: number[];
}

const getYTicks = (showYValues: boolean): YTicks => {
  const yTicks = showYValues ? ecgYAllTicks : ecgYCenterTick;
  const ySubTicks = showYValues ? ecgYCenterTick : ecgYAllTicks;
  return { yTicks, ySubTicks };
};

/**
 * Get the time duration of the X-Axis for a given aspect ratio
 * @returns the duration in ms
 */
const getXDuration = (aspectRatio: number): number => {
  const xBlockCount = ecgYBlockCount * aspectRatio;
  const xDuration = ecgXSubBlockDurationMs * xBlockCount;
  return xDuration;
};

/**
 * Basic config for an ECG Graph. Assumes relevant defaults based on an aspect ratio. Does not return
 * any valid data
 */
const getDefaultConfig = (aspectRatio: number, showYValues = false): VitalCardSplineProps => {
  const xDuration = getXDuration(aspectRatio);

  // if we don't have data, assume some defaults so we can have a grid to display
  const xEnd = new Date().getTime();
  const xStart = xEnd - xDuration;

  // the range function is not inclusive of the end value
  const xSubTicks = range(xStart, xEnd + ecgXSubBlockDurationMs, ecgXSubBlockDurationMs);
  const xTicks = range(xStart, xEnd + ecgXSubBlockDurationMs, ecgXBlockDurationMs);
  const xDomain: Domain = [xStart, xEnd];

  const { yTicks, ySubTicks } = getYTicks(showYValues);

  return {
    aspectRatio,
    xDomain,
    yDomain: ecgYDomain,
    xTicks,
    yTicks,
    xSubTicks,
    ySubTicks,
  };
};

export const getECGSplineProps: SplinePropGetter = ({
  aspectRatio,
  baseData = [],
  endTs,
  showYValues = false,
}) => {
  const hasData = baseData.length > 2;

  const xDuration = getXDuration(aspectRatio);

  if (!hasData) {
    return getDefaultConfig(aspectRatio, showYValues);
  }

  const latestMetric = maxBy(baseData, (d) => d.ts) as BaseTimeSeriesRecord;

  const xEnd = endTs || latestMetric.ts;
  const xStart = xEnd - xDuration;

  // the range function is not inclusive of the end value
  const xSubTicks = range(xStart, xEnd + ecgXSubBlockDurationMs, ecgXSubBlockDurationMs);
  const xTicks = range(xStart, xEnd + ecgXSubBlockDurationMs, ecgXBlockDurationMs);

  const xDomain: Domain = [xStart, xEnd];

  // remove unnecessary data from graph
  const data = baseData
    .filter((d) => d.ts >= xStart && d.ts <= xEnd)
    .map((d) => ({ x: d.ts, y: d.value }));

  const { yTicks, ySubTicks } = getYTicks(showYValues);

  return {
    data,
    aspectRatio,
    xDomain,
    yDomain: ecgYDomain,
    xTicks,
    yTicks,
    xSubTicks,
    ySubTicks,
  };
};

const isInDomain =
  (domain?: Domain) =>
  (datum: BaseTimeSeriesRecord): boolean => {
    if (!domain) {
      return false;
    }

    return datum.ts >= domain[0] && datum.ts <= domain[1];
  };

export const getPerfusionIndexSplineProps: SplinePropGetter = ({
  aspectRatio,
  baseData = [],
  baseTimeFrame,
  deviceFilter: activeDevice,
}) => {
  const data = baseData
    .filter((d) => {
      if (!activeDevice) {
        return true;
      }

      return d.sensorId === activeDevice;
    })
    .filter(isInDomain(baseTimeFrame))
    .map((d) => ({
      x: d.ts,
      y: d.value,
    }));

  const measureDomain = domains[MeasurementName.PerfusionIndex];
  const yDomain =
    measureDomain &&
    getExpandedDomain(
      measureDomain,
      data.map((d) => d.y)
    );

  const color = colors[MeasurementName.PerfusionIndex];

  return {
    data,
    color,
    yDomain,
    yTicks: 5,
    aspectRatio,
    overflow: 'hidden',
    xDomain: baseTimeFrame,
  };
};
