import { ColorName } from '@emotion/react';
import { Row, Col } from 'components/base';
import { IconName, Icon, Visible, Card, Text, Spacer } from 'design-system/base';
import { BASE_SIZE } from 'design-system/theme';
import React from 'react';

import { Measurement } from './Measure';
import { StatusGrid } from './StatusGrid';

interface StatusCardProps<T> {
  title?: string;
  statusColor?: ColorName;
  statusIcon?: IconName;
  icon?: IconName;
  measurements?: Measurement<T>[];
  onSelect?: (key: T) => void;
}

export const StatusCard = <T extends string>({
  title = '',
  statusColor = 'text-default',
  icon,
  statusIcon,
  measurements = [],
  onSelect,
}: StatusCardProps<T>) => (
  <Card>
    <Row justifyContent="space-between" alignItems="center">
      <Row alignItems="center">
        <Visible if={statusIcon}>
          <Icon size={BASE_SIZE[16]} name={statusIcon} color={statusColor} />
          <Spacer width={BASE_SIZE[8]} />
        </Visible>
        <Text variant="body">{title}</Text>
      </Row>
      <Col isVisible={icon}>
        <Icon size={BASE_SIZE[16]} name={icon} />
      </Col>
    </Row>
    <Spacer height={BASE_SIZE[8]} />
    <StatusGrid<T> onSelect={onSelect} measurements={measurements} />
  </Card>
);
