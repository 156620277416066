import { ColorName } from '@emotion/react';
import { Row } from 'components/base';
import { BASE_SIZE, MARGIN } from 'design-system/theme';
import React from 'react';

import { Spacer, Visible } from '../generic';
import { Icon, IconName } from '../icon';
import { Text } from '../text';
import {
  BaseButton,
  BorderRadiusProps,
  getTextColor,
  ShapeVariant,
  SizeVariant,
  TypeVariant,
} from './BaseButton';

type IconSize = 'default' | 'large' | 'extra-large';

export interface ButtonProps extends BorderRadiusProps {
  onPress: () => void;

  variant?: TypeVariant;
  shape?: ShapeVariant;
  size?: SizeVariant;
  text?: string;
  icon?: IconName;
  disabled?: boolean;
  textColor?: ColorName;
  iconSize?: IconSize;
  iconPosition?: 'left' | 'right';
}

const iconSizes: Record<IconSize, number> = {
  default: BASE_SIZE[20],
  large: BASE_SIZE[24],
  'extra-large': BASE_SIZE[32],
};

export const Button: React.FC<ButtonProps> = ({
  variant = 'contained',
  shape = 'default',
  size = 'default',
  disabled = false,
  text = '',
  iconPosition = 'left',
  icon,
  unsetBottomLeftRadius,
  unsetBottomRightRadius,
  unsetTopLeftRadius,
  unsetTopRightRadius,
  iconSize = 'default',
  textColor = getTextColor(variant, disabled),
  onPress,
}) => {
  const hasRightIcon = icon && iconPosition === 'right';
  const hasLeftIcon = icon && iconPosition === 'left';
  const fullWidth = size === 'full-width';

  const resolvedIconSize = iconSizes[iconSize];

  return (
    <Row alignItems="flex-start" fullWidth={fullWidth}>
      <BaseButton
        onPress={onPress}
        disabled={disabled}
        variant={variant}
        shape={shape}
        size={size}
        unsetBottomLeftRadius={unsetBottomLeftRadius}
        unsetBottomRightRadius={unsetBottomRightRadius}
        unsetTopLeftRadius={unsetTopLeftRadius}
        unsetTopRightRadius={unsetTopRightRadius}
      >
        <Visible if={hasLeftIcon}>
          <Icon name={icon as IconName} color={textColor} size={resolvedIconSize} />
          <Visible if={text}>
            <Spacer width={MARGIN['2xs']} />
          </Visible>
        </Visible>
        <Text selectable={false} variant="button" color={textColor}>
          {text}
        </Text>
        <Visible if={hasRightIcon}>
          <Visible if={text}>
            <Spacer width={MARGIN['2xs']} />
          </Visible>
          <Icon name={icon as IconName} color={textColor} size={resolvedIconSize} />
        </Visible>
      </BaseButton>
    </Row>
  );
};
