import { Notes } from '@quromedical/models';
import { usePaginatedData, getNextTokenFromLink } from 'hooks/usePaginatedData';
import { useRevalidation } from 'hooks/useRevalidation';
import { NotesApi } from 'integration/aggregate';
import { useAsyncDebounce } from 'react-table';
import useSWR from 'swr';

const api = new NotesApi();

const getNotesFromData = (data: Notes.NoteBundle) => data.notes;
const getPinsFromData = (data?: Notes.NoteBundle) => data?.pins;
const getNextTokenFromData = (data: Notes.NoteBundle) => getNextTokenFromLink(data.link);

interface UseAllNotesHookResult {
  revalidate: () => Promise<void>;
  loadMore: () => void;
  canLoadMore: boolean;
  data?: Notes.Note[];
  pins?: Notes.Note[];
  error: unknown;
  isLoading: boolean;
}

export const useAllNotes = (id: string, search: string): UseAllNotesHookResult => {
  const trimmedSearch = search.trim();

  const fetcher = (pageToken?: string) =>
    api.getNotes(id, {
      pageToken,
      count: 20,
      search: trimmedSearch || undefined,
    });

  const debouncedFetcher = useAsyncDebounce(fetcher, 500);

  const key = `${id}-note-list-${trimmedSearch}`;

  const { error, hasNext, isLoading, loadNext, data } = usePaginatedData<
    Notes.NoteBundle,
    Notes.Note
  >(key, debouncedFetcher, getNotesFromData, getNextTokenFromData);

  const revalidate = useRevalidation(key);

  // the data shouldn't be re-fetched since it's the same key between the hooks
  const { data: pinsBundle } = useSWR(key, () => fetcher());
  const pins = getPinsFromData(pinsBundle);

  return {
    isLoading,
    data,
    pins,
    error,
    revalidate,
    canLoadMore: hasNext,
    loadMore: loadNext,
  };
};
