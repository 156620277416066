export enum ScreenSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
  /**
   * **Reserved Use**: Clinical monitoring and similar cases
   */
  '3xl' = '3xl',
  /**
   * **Reserved Use**: Clinical monitoring and similar cases
   */
  '4xl' = '4xl',
  /**
   * **Reserved Use**: Clinical monitoring and similar cases
   */
  '5xl' = '5xl',
}

export const SCREEN_SIZE: Record<ScreenSize, number> = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1400,
  '3xl': 1600,
  '4xl': 2000,
  '5xl': 2400,
};
