import { CrudForm, SubmissionHandler } from 'components/form';
import { Field } from 'components/types';
import { FormCard } from 'design-system';
import React, { useCallback } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

export interface SearchResult {
  display: string;
  idNumber?: string;
  dependantCode: string;
}

interface MedicalAidUpdatePatientSelectionProps {
  idNumber: string;
  searchResults?: SearchResult[];
  onSubmit: (dependantCode: string) => void;
}

interface FormData {
  dependantCode: string;
}

const schema = yup.object<ObjectType<FormData>>({
  dependantCode: yup.string().required(),
});

export const MedicalAidUpdatePatientSelection: React.FC<MedicalAidUpdatePatientSelectionProps> = ({
  idNumber,
  searchResults = [],
  onSubmit,
}) => {
  const foundPatient = searchResults.find((result) => result.idNumber === idNumber);

  const field: Field<FormData> = {
    subfields: [
      {
        key: 'dependantCode',
        type: 'radio-group',
        label: strings.CardLabelDependantName,
        options: searchResults.map((result) => ({
          display: `${result.dependantCode} - ${result.display}`,
          value: result.dependantCode,
        })),
        isDisabled: !!foundPatient,
        vertical: true,
      },
    ],
  };

  const handleSubmit = useCallback<SubmissionHandler<FormData>>(
    (data: FormData) => {
      onSubmit(data.dependantCode);
      return {};
    },
    [onSubmit]
  );

  if (!searchResults.length) {
    return (
      <FormCard
        title={strings.MedicalAidPatientUpdateSearchError}
        cardColor="status-warning"
        rows={[
          {
            fields: [
              {
                type: 'text',
                label: strings.MedicalAidPatientSelectionEmpty,
              },
            ],
          },
        ]}
      />
    );
  }

  return (
    <CrudForm<FormData>
      title={strings.TitleSelectPatient}
      initialValues={{
        dependantCode: foundPatient?.dependantCode,
      }}
      fields={[field]}
      validationSchema={schema}
      onSubmit={handleSubmit}
    />
  );
};
