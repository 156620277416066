import { DeviceCode } from '@quromedical/fhir-common';
import { DeviceAssociation } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { Field, SelectOption } from 'components/types';
import { deviceCodeFilterOptions } from 'core/device';
import { createMixedFetcher } from 'core/forms';
import { Fetcher } from 'design-system';
import compact from 'lodash.compact';
import { useCallback, useMemo, useState } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

type FetcherFactory = (type?: DeviceCode) => Fetcher<string, undefined>;

interface CreateDeviceAssociationFormProps {
  fetcherFactory: FetcherFactory;
  onSubmit: (association: DeviceAssociation.PatientDeviceAssociationCreateRequest) => void;
}

interface FormData {
  /**
   * Device type is used for filtering the options available in the dropdown for devices
   */
  deviceType: DeviceCode;
  device: SelectOption<undefined, string>;
}

const formSchema = yup.object<ObjectType<FormData>>({
  // don't need to validate the object since it comes from the dropdown
  device: yup.object().required(),
});

const createFields = (
  state: Partial<FormData>,
  fetcherFactory: FetcherFactory
): Field<FormData>[] => {
  // create a fetcher which appends the existing selection so that it isn't dropped if the search is
  // updated or filtered by a differently selected code
  const fetcherWithSelection = createMixedFetcher(
    compact([state.device]),
    fetcherFactory(state.deviceType)
  );

  return [
    {
      subfields: [
        {
          key: 'deviceType',
          type: 'combobox-single',
          fetcher: deviceCodeFilterOptions,
          label: strings.LabelDeviceType,
          placeholder: strings.SortFilterAll,
        },
        {
          key: 'device',
          type: 'combobox-single',
          label: strings.LabelDevice,
          fetcher: fetcherWithSelection,
          returnFullOption: true,
          searchable: true,
        },
      ],
    },
  ];
};

export const CreateDeviceAssociationForm: React.FC<CreateDeviceAssociationFormProps> = ({
  fetcherFactory,
  onSubmit,
}) => {
  const [state, setState] = useState<Partial<FormData>>({});

  const fields = useMemo(() => createFields(state, fetcherFactory), [fetcherFactory, state]);

  const handleSubmit = useCallback(
    async (result: FormData) => {
      try {
        await onSubmit({
          device: result.device.value,
        });
        return {};
      } catch (error) {
        return { error };
      }
    },
    [onSubmit]
  );

  return (
    <CrudForm
      hasCard={false}
      key={state.deviceType}
      initialValues={state}
      onChangeDebounceTime={0}
      fields={fields}
      onChange={setState}
      onSubmit={handleSubmit}
      validationSchema={formSchema}
      cardProps={{ unsetZIndex: true }}
    />
  );
};
