export const BASE_SIZE = {
  1: 1,
  2: 2,
  4: 4,
  6: 6,
  8: 8,
  12: 12,
  16: 16,
  20: 20,
  22: 22,
  24: 24,
  32: 32,
  36: 36,
  40: 40,
  42: 42,
  48: 48,
  56: 56,
  64: 64,
  96: 96,
  148: 148,
  200: 200,
  576: 576,
};

export const SPACING = {
  none: 0,
  '3xs': BASE_SIZE['4'],
  '2xs': BASE_SIZE['8'],
  xs: BASE_SIZE['12'],
  s: BASE_SIZE['16'],
  m: BASE_SIZE['24'],
  l: BASE_SIZE['32'],
  xl: BASE_SIZE['40'],
  '2xl': BASE_SIZE['48'],
  '3xl': BASE_SIZE['56'],
};

export const PADDING = SPACING;

export const MARGIN = SPACING;

export type Spacing = keyof typeof SPACING;

export const BORDER_WIDTH = {
  1: 1,
  2: 2,
  4: 4,
};

export type BorderWidth = keyof typeof BORDER_WIDTH;

export const BORDER_RADIUS = {
  4: 4,
  full: 99999,
};
