import { Image } from './Image';
import { PDF } from './PDF';
import { Renderer as RendererType } from './types';

export { DownloadButton } from './DownloadButton';

export { RendererProps } from './types';

export type FileType = 'image' | 'pdf';

export const Renderer: Record<FileType, RendererType> = {
  image: Image,
  pdf: PDF,
};
