import { Vitals, MeasurementName } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import last from 'lodash.last';

const measures = Object.values(MeasurementName);

export enum VitalsEndpoint {
  Tracking = 'tracking',
}

const emptyResult: Vitals.AggregateResult = {
  latest: {
    type: 'success',
    data: {},
  },
  vitals: {
    type: 'success',
    data: {},
  },
};

export const flattenVitalResult = (
  aggregate: Vitals.AggregateResult
): Vitals.VitalsResultMeasures => {
  const { vitals } = aggregate;

  if (vitals.type === 'error') {
    return {};
  }

  return vitals.data;
};

/**
 * Merge vitals from multiple pages
 *
 * @param pages should be in time order from oldest to newest
 * @returns
 */
const mergeVitalResults = (pages: Vitals.AggregateResult[]): Vitals.AggregateResult => {
  const lastPage = last(pages);

  if (!lastPage) {
    return emptyResult;
  }

  const flatVitals = pages.map(flattenVitalResult);

  const data = measures.reduce<Vitals.VitalsResultMeasures>((prev, key) => {
    const parts = flatVitals.map((vitals) => vitals[key] || []).flat();

    return {
      ...prev,
      [key]: parts,
    };
  }, {});

  return {
    vitals: {
      type: 'success',
      data,
    },
    latest: lastPage.latest,
  };
};

export type VitalsAggregateMergedResult = Omit<Vitals.AggregateResult, 'next'>;

export class VitalsApi {
  private readonly client: BaseApi;
  private readonly patientBasePath = 'api/aggregate/patient';
  private readonly vitalsBasePath = 'vitals';

  constructor() {
    this.client = new BaseApi();
  }

  private getPath(patientId: string, ...parts: (MeasurementName | VitalsEndpoint)[]): string {
    return [this.patientBasePath, patientId, this.vitalsBasePath, ...parts].join('/');
  }

  public async getVitals(
    patientId: string,
    params: Vitals.QueryParams
  ): Promise<VitalsAggregateMergedResult> {
    const path = this.getPath(patientId);

    let hasNext = true;
    const results: Vitals.AggregateResult[] = [];
    let paginationParams: Vitals.QueryParams = params;

    while (hasNext) {
      // eslint-disable-next-line no-await-in-loop
      const response = await this.client.get<Vitals.AggregateResult>(path, {
        params: paginationParams,
      });

      const result = response.data;
      results.push(result);

      const nextParams = result.next;
      hasNext = !!nextParams;
      if (nextParams) {
        paginationParams = nextParams;
      }
    }

    const merged = mergeVitalResults(results);

    return merged;
  }

  public async getTracking(
    patientId: string,
    params: Vitals.QueryParams
  ): Promise<VitalsAggregateMergedResult> {
    const path = this.getPath(patientId, VitalsEndpoint.Tracking);

    let hasNext = true;
    const results: Vitals.AggregateResult[] = [];
    let paginationParams: Vitals.QueryParams = params;

    while (hasNext) {
      // eslint-disable-next-line no-await-in-loop
      const response = await this.client.get<Vitals.AggregateResult>(path, {
        params: paginationParams,
      });

      const result = response.data;
      results.push(result);

      const nextParams = result.next;
      hasNext = !!nextParams;
      if (nextParams) {
        paginationParams = nextParams;
      }
    }

    const merged = mergeVitalResults(results);

    return merged;
  }

  public async getVitalsByMeasurement(
    patientId: string,
    measurementName: MeasurementName,
    params: Vitals.QueryParams
  ): Promise<Vitals.AggregateMeasureResult> {
    const path = this.getPath(patientId, measurementName);
    const result = await this.client.get<Vitals.AggregateMeasureResult>(path, {
      params,
    });

    return result.data;
  }
}
