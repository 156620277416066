import { UserContext, UserSession } from 'providers/UserContext';
import { useContext } from 'react';

/**
 * Access the logged in user's session
 * @returns the logged in Session info
 */
export const useUserSession = (): UserSession => {
  const context = useContext(UserContext);
  return context;
};
