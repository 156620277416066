import { GroupSecurity } from '@quromedical/fhir-common';
import { Medication } from '@quromedical/models';
import { WithGroup } from 'core/auth';
import { includedRouteCodes } from 'core/medication';
import { Alert, Section } from 'design-system';
import { useRevalidation } from 'hooks/useRevalidation';
import { medicationFetcher } from 'integration';
import { MedicationApi } from 'integration/aggregate';
import React, { useCallback } from 'react';
import { CreateMedicationCard, MedicationTable } from 'screens/medication';
import { strings } from 'strings';

interface PatientMedicationsTabProps {
  patientId: string;
  admissionId?: string;
}
const api = new MedicationApi();

const assignableMedicationFetcher = medicationFetcher(includedRouteCodes);

export const PatientMedicationsTab: React.FC<PatientMedicationsTabProps> = ({
  patientId,
  admissionId,
}) => {
  const revalidate = useRevalidation(patientId);

  const onSubmit = useCallback(
    async (medicationUsage: Medication.CreateMedicationUsage[]) => {
      if (!admissionId) {
        return;
      }
      await api.createMedication(patientId, admissionId, { medicationUsage });
      revalidate();
    },
    [patientId, admissionId, revalidate]
  );

  return (
    <>
      <WithGroup groups={[GroupSecurity.MedicationStatementCreate]}>
        <Section unsetZIndex>
          <CreateMedicationCard
            onSubmit={onSubmit}
            medicationFetcher={assignableMedicationFetcher}
            title={strings.FormTitleCreateMedicationStatement}
          />
        </Section>
      </WithGroup>

      <WithGroup groups={[GroupSecurity.MedicationStatementRead]}>
        {admissionId ? (
          <Section>
            <MedicationTable patientId={patientId} admissionId={admissionId} />
          </Section>
        ) : (
          <Section isVisible={!admissionId}>
            <Alert
              title={strings.ErrorCardTitle}
              body={strings.AlertMissingAdmissionID}
              backgroundColor="status-warning"
            />
          </Section>
        )}
      </WithGroup>
    </>
  );
};
