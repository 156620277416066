import { ChargeItemDefinition } from '@quromedical/models';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Field } from 'components/types';
import { logger } from 'helpers';
import { useFiniteState } from 'hooks/useFiniteState';
import { useList } from 'hooks/useList';
import React, { useCallback, useMemo } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

import { AdministratorFetcher, ChargeLine, ChargeLineForm, SchemeFetcher } from './ChargeLineForm';

export interface ChargeItemDefinition {
  title: string;
  description: string;
  chargeLines: ChargeLine[];
}

export interface InitialChargeItemDefinition
  extends Omit<Partial<ChargeItemDefinition>, 'chargeLines'> {
  chargeLines: Partial<ChargeLine>[];
}

export interface ChargeItemDefinitionFormProps {
  initial?: InitialChargeItemDefinition;
  administratorFetcher: AdministratorFetcher;
  schemeFetcher: SchemeFetcher;
  onSubmit: (data: ChargeItemDefinition) => Promise<void>;
  onCancel?: () => void;
  hideCard?: boolean;
}

interface ListDataValue {
  isValid: boolean;
  hasSubmitted: boolean;
  data: Partial<ChargeLine>;
}

interface ListData {
  key: number | string;
  value: ListDataValue;
}

const createEmptyItem = (): ListData => ({
  key: Math.random(),
  value: {
    hasSubmitted: false,
    isValid: false,
    data: {
      amountFactor: ChargeItemDefinition.MONEY_FACTOR,
    },
  },
});

type ManagedOutsideOfForm = undefined;

interface FormChargeItemDefinition extends Omit<ChargeItemDefinition, 'chargeLines'> {
  chargeLines: ManagedOutsideOfForm;
}

/**
 * Assumes that the input data is valid
 */
const getInitialChargeLines = (chargeLines: Partial<ChargeLine>[] = []): ListData[] => {
  if (!chargeLines.length) {
    return [createEmptyItem()];
  }

  return chargeLines.map((line) => ({
    key: `${line.type}-${line.identifierCode}}`,
    value: {
      data: {
        ...line,
        amount: line.amount,
        amountFactor: line.amountFactor,
        currency: line.currency,
        chargeCode: line.chargeCode,
      },
      hasSubmitted: true,
      isValid: true,
    },
  }));
};

const fields: Field<FormChargeItemDefinition>[] = [
  {
    subfields: [
      {
        key: 'title',
        type: 'text-box',
        label: strings.ChargeItemDefinitionFormLabelName,
      },
      {
        key: 'description',
        type: 'text-box',
        label: strings.ChargeItemDefinitionFormLabelDescription,
      },
    ],
  },
];

type State = 'editing' | 'submitting' | 'item-error' | 'submission-error';

type ValidListDataValue = ListDataValue & { data: ChargeLine };

const areItemsValid = (items: Partial<ListDataValue>[]): items is ValidListDataValue[] =>
  items.every((item) => item.isValid);

const schema = yup.object<ObjectType<FormChargeItemDefinition>>({
  description: yup.string().required(),
  title: yup.string().required(),
});

export const ChargeItemDefinitionForm: React.FC<ChargeItemDefinitionFormProps> = ({
  initial,
  administratorFetcher,
  schemeFetcher,
  onSubmit,
  onCancel,
  hideCard = false,
}) => {
  const state = useFiniteState<State>('editing');

  const initialLines = useMemo(() => getInitialChargeLines(initial?.chargeLines), [initial]);
  const lines = useList(initialLines);

  const items = lines.items.map((i) => i.value);
  const itemsValid = areItemsValid(items);

  const handleSubmit = useCallback<SubmissionHandler<FormChargeItemDefinition>>(
    async ({ description, title }: FormChargeItemDefinition) => {
      if (!itemsValid) {
        state.set('item-error');
        return {};
      }

      state.set('submitting');
      try {
        await onSubmit({
          title,
          description,
          chargeLines: items.map((i) => i.data),
        });
        return {};
      } catch (err) {
        logger.error(err);
        state.set('submission-error');
        return { error: err };
      }
    },
    [items, itemsValid, onSubmit, state]
  );

  const hasError = state.is('submission-error') || state.is('item-error');
  const errorMessage = state.is('submission-error')
    ? strings.ChargeItemDefinitionFormSubmissionError
    : strings.ChargeItemDefinitionFormItemsError;

  const error = hasError ? errorMessage : undefined;

  const title = initial
    ? strings.ChargeItemDefinitionFormCreateTitle
    : strings.ChargeItemDefinitionFormEditTitle;

  return (
    <CrudForm<FormChargeItemDefinition>
      hasCard={!hideCard}
      title={hideCard ? undefined : title}
      fields={fields}
      initialValues={{ ...initial, chargeLines: undefined }}
      validationSchema={schema}
      error={error}
      showErrors={hasError}
      onSubmit={handleSubmit}
      secondaryButtonText={strings.ButtonTextCancel}
      onSecondarySubmit={onCancel}
      showSecondarySubmitButton={!!onCancel}
    >
      {lines.items.map((item, index) => {
        const isLast = index === lines.items.length - 1;
        const canRemove = lines.items.length > 1;

        return (
          <ChargeLineForm
            key={item.key}
            administratorFetcher={administratorFetcher}
            schemeFetcher={schemeFetcher}
            onChange={(data, isValid) => {
              state.set('editing');
              lines.update(item.key, { ...item.value, data, isValid });
            }}
            onRemove={canRemove ? () => lines.remove(item.key) : undefined}
            onAdd={
              isLast
                ? () => {
                    const emptyItem = createEmptyItem();
                    lines.update(item.key, { ...item.value, hasSubmitted: true });
                    lines.add(emptyItem.key, emptyItem.value);
                  }
                : undefined
            }
            value={item.value.data}
            showErrors={item.value.hasSubmitted || hasError}
          />
        );
      })}
    </CrudForm>
  );
};
