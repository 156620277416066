import { Platform } from 'react-native';

interface PlatformConfig {
  /**
   * OS of the current device
   */
  os: typeof Platform['OS'];
  isWeb: boolean;
  /**
   * `Native` in this context refers to any non-web platform
   */
  isNative: boolean;
  isAndroid: boolean;
  isIos: boolean;
}

/**
 * Get the current platform information from the react-native `Platform` static
 */
export const getPlatformConfig = (): PlatformConfig => {
  const os = Platform.OS;

  const isWeb = os === 'web';
  const isNative = !isWeb;

  const isAndroid = os === 'android';
  const isIos = os === 'ios';

  return {
    os,
    isWeb,
    isNative,
    isAndroid,
    isIos,
  };
};
