import { Person } from '@quromedical/models';
import { IdentifierCodeDisplay } from 'core/display';
import { FormCard } from 'design-system';
import React from 'react';
import { strings } from 'strings';

import { getDisplayName } from './mapping';

interface PersonNameAndIdDisplayProps {
  general?: Partial<Person.General>;
  title?: string;
}

export const PersonNameAndIdDisplay: React.FC<PersonNameAndIdDisplayProps> = ({
  general = {},
  title,
}) => {
  const { identifierCode, identifierValue } = general;

  const name = getDisplayName(general);

  const identifierLabel = identifierCode
    ? IdentifierCodeDisplay[identifierCode]
    : strings.LabelIdentification;

  return (
    <FormCard
      title={title}
      rows={[
        {
          fields: [
            {
              type: 'text',
              label: strings.LabelNameAndSurname,
              display: name,
            },
            {
              type: 'text',
              label: identifierLabel,
              display: identifierValue,
            },
          ],
        },
      ]}
    />
  );
};
