import { Theme, useTheme } from '@emotion/react';
import { ClinicalImpressionAssessmentValueCode } from '@quromedical/fhir-common';
import { Notes } from '@quromedical/models';
import { StopProps } from 'components/graph';
import * as Graph from 'components/graph';
import { TimelineCard, TimelinePoint } from 'design-system/components';
import { useResponsive } from 'hooks/useResponsive';
import React from 'react';
import { strings } from 'strings';

import { codeAssessment } from './record';

interface NoteSummaryGraphProps {
  notes: Notes.Note[];
}

const isValidPoint = (point: Partial<TimelinePoint>): point is TimelinePoint => {
  const hasY = typeof point.y !== 'undefined';
  const hasData = hasY && point.x && point.text;

  return !!hasData;
};

enum AssessmentCodeValue {
  Deteriorating = -1,
  Stable = 0,
  Improving = 1,
}

const margin: Graph.Margin = {
  ...Graph.defaultMargin,
  // need some space on the left so that the text doesn't overlap
  left: 84,
};

const assessmentCodeValueMap: Record<ClinicalImpressionAssessmentValueCode, AssessmentCodeValue> = {
  [ClinicalImpressionAssessmentValueCode.Deteriorating]: AssessmentCodeValue.Deteriorating,
  [ClinicalImpressionAssessmentValueCode.Stable]: AssessmentCodeValue.Stable,
  [ClinicalImpressionAssessmentValueCode.Improving]: AssessmentCodeValue.Improving,
};

const assessmentCodeValueDisplayMap: Record<AssessmentCodeValue, string> = {
  [AssessmentCodeValue.Deteriorating]:
    codeAssessment[ClinicalImpressionAssessmentValueCode.Deteriorating],
  [AssessmentCodeValue.Stable]: codeAssessment[ClinicalImpressionAssessmentValueCode.Stable],
  [AssessmentCodeValue.Improving]: codeAssessment[ClinicalImpressionAssessmentValueCode.Improving],
};

const createStops = (theme: Theme): StopProps[] => [
  {
    offsetVal: 1,
    color: theme.color['status-success'],
  },
  {
    offsetVal: 0,
    color: theme.color['accent-blue'],
  },
  {
    offsetVal: -1,
    color: theme.color['status-critical'],
  },
];

const assessmentCodeFormatter = (value: number): string =>
  assessmentCodeValueDisplayMap[value as AssessmentCodeValue] || '';

export const NoteSummaryGraph: React.FC<NoteSummaryGraphProps> = ({ notes }) => {
  const theme = useTheme();
  const aspectRatio = useResponsive({ sm: 3, lg: 4, xl: 5, xxl: 6 }, 3);

  const points: TimelinePoint[] = notes
    .map<Partial<TimelinePoint>>((item) => {
      const { note } = item;
      const x = item.dateCreated ? new Date(item.dateCreated).getTime() : undefined;

      const y = note.assessmentCode ? assessmentCodeValueMap[note.assessmentCode] : undefined;

      return {
        y,
        x,
        text: note.subjective,
      };
    })
    .filter(isValidPoint);

  const stops = createStops(theme);

  return (
    <TimelineCard
      alwaysExpanded
      showScatter
      margin={margin}
      blockColor="accent-blue"
      icon="sticky-note-2"
      title={strings.NoteHistoryTitle}
      valueFormatter={assessmentCodeFormatter}
      yTicks={[-1, 0, 1]}
      yDomain={[-1, 1]}
      timeFormat="date"
      points={points}
      aspectRatio={aspectRatio}
      color="accent-blue"
      stops={stops}
    />
  );
};
