import { Row } from 'components/base';
import { SkeletonProvider, Spacer } from 'design-system/base';
import { useTheme, Spacing, SPACING } from 'design-system/theme';
import React, { ReactElement } from 'react';
import { FlatListProps, ActivityIndicator, FlatList } from 'react-native';

export type ItemComponent<TItem> = FlatListProps<TItem>['renderItem'];

interface ListProps<TItem> {
  data: Array<TItem>;
  loading?: boolean;
  spacing?: Spacing;
  Item: ItemComponent<TItem>;
  ListHeaderComponent?: React.ComponentType;
  ListEmptyComponent?: React.ComponentType;
  LoadingPlaceholder?: React.ComponentType;
  ListFooterComponent?: React.ComponentType;
  keyExtractor: (item: TItem, index: number) => string;
  onEndReached?: () => void;
  onRefresh?: () => void;
}

const Loader: React.FC = () => {
  const theme = useTheme();
  return (
    <Row justifyContent="center">
      <ActivityIndicator size="large" color={theme.color.primary} />
    </Row>
  );
};

export const List = <TItem extends {}>({
  data,
  loading = false,
  spacing,
  keyExtractor,
  onEndReached,
  onRefresh,
  Item,
  ListHeaderComponent,
  ListEmptyComponent,
  ListFooterComponent,
}: ListProps<TItem>): ReactElement => {
  const Footer = loading ? Loader : ListFooterComponent;

  const height = spacing && SPACING[spacing];
  const Separator = () => <Spacer height={height} />;

  return (
    <SkeletonProvider loading={loading}>
      <FlatList
        ItemSeparatorComponent={Separator}
        ListHeaderComponent={ListHeaderComponent}
        onRefresh={onRefresh}
        onEndReached={onEndReached}
        data={data}
        keyExtractor={keyExtractor}
        renderItem={Item}
        ListEmptyComponent={ListEmptyComponent}
        ListFooterComponent={Footer}
      />
    </SkeletonProvider>
  );
};
