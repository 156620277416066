import styled from '@emotion/native';
import { Row } from 'components/base';
import { Text } from 'design-system';
import { MARGIN, PADDING } from 'design-system/theme';
import React from 'react';

interface ShortcutProps {
  shortcut?: string[];
}

const ShortcutKey = styled(Text)(({ theme }) => ({
  paddingHorizontal: PADDING['3xs'],
  backgroundColor: theme.color['primary-20'],
  marginLeft: MARGIN['3xs'],
}));

export const Shortcut: React.FC<ShortcutProps> = ({ shortcut = [] }) => (
  <Row>
    {shortcut.map((key) => (
      <ShortcutKey key={key} variant="caption">
        {key}
      </ShortcutKey>
    ))}
  </Row>
);
