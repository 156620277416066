import { useCallback, useState } from 'react';
import { SortingRule } from 'react-table';

import { OnTableStateChangedHandler } from './DataTable';

interface TableState<TData extends {}, TSortValue> {
  onChange: OnTableStateChangedHandler<TData>;
  sort?: TSortValue;
}

type SortDirection = 'desc' | 'asc';

type PartialRecord<TKey extends string | number | symbol, TValue> = Partial<Record<TKey, TValue>>;

export type SortConfig<TData extends {}, TSortValue = string> = PartialRecord<
  keyof TData,
  PartialRecord<SortDirection, TSortValue>
>;

interface Config<TData extends {}, TSortValue> {
  sort: SortConfig<TData, TSortValue>;
}

const resolveSort = <TData extends {}, TSortValue>(
  sortState: SortingRule<TData>[] | undefined,
  config?: SortConfig<TData, TSortValue>
): TSortValue | undefined => {
  const sortDirection: SortDirection = sortState?.[0]?.desc ? 'desc' : 'asc';
  const sortKey = sortState?.[0]?.id as keyof TData;
  const sort = config?.[sortKey]?.[sortDirection];
  return sort;
};

/**
 * A hook for declaratively handling table change states and resolving sort values
 */
export const useTableState = <TData extends {}, TSortValue = string>(
  config: Config<TData, TSortValue>
): TableState<TData, TSortValue> => {
  const [sortState, setSortState] = useState<SortingRule<TData>[]>();

  const onChange = useCallback<OnTableStateChangedHandler<TData>>(({ sortBy }) => {
    setSortState(sortBy);
  }, []);

  const sort = resolveSort<TData, TSortValue>(sortState, config.sort);

  return {
    onChange,
    sort,
  };
};
