import { FormCard } from 'design-system';
import React from 'react';
import { strings } from 'strings';

export const MDMError: React.FC = () => (
  <FormCard
    cardColor="status-critical"
    title={strings.TinyMDMErrorTitle}
    rows={[
      {
        fields: [
          {
            type: 'text',
            label: strings.TinyMDMErrorText,
          },
        ],
      },
    ]}
  />
);
