import { getCreateReferenceId, GroupSecurity } from '@quromedical/fhir-common';
import { ConfirmationButton } from 'components/input';
import { ClaimTemplate as FormData, ClaimTemplateCard, InitialClaimTemplate } from 'core/claim';
import { Section, Skeleton, SkeletonProvider } from 'design-system';
import { useRevalidation } from 'hooks/useRevalidation';
import { useUserSession } from 'hooks/useUserSession';
import { chargeItemFetcher } from 'integration';
import { ClaimApi } from 'integration/aggregate';
import { ClaimStackScreenProps } from 'navigation';
import React, { useCallback } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { NotFoundScreen } from 'screens/NotFoundScreen';
import { strings } from 'strings';
import useSWR from 'swr';

const api = new ClaimApi();

export const ClaimScreen: React.FC<ClaimStackScreenProps<'View'>> = ({ route }) => {
  const { id } = route.params;

  const session = useUserSession();

  const fetcher = useCallback(() => api.getOne(id), [id]);
  const swrKey = api.getSWRKey(id);

  const { data, isValidating, error } = useSWR(swrKey, fetcher);

  const canEdit = session.hasAny([GroupSecurity.ClaimUpdate]) && data?.status === 'draft';

  const revalidate = useRevalidation(api.getSWRKey());

  const onSubmit = useCallback(async () => {
    await api.submit(id, {});
    await revalidate();
  }, [id, revalidate]);

  const onEdit = useCallback(
    async (result: FormData) => {
      await api.update(id, {
        chargeLines: result.claimLines.map((line) => ({
          chargeLine: line.chargeItemIdentifier,
          quantity: line.quantity,
        })),
      });
      await revalidate();
    },
    [id, revalidate]
  );

  if (error) {
    return <NotFoundScreen />;
  }

  const loading = isValidating || !data;

  const initial: InitialClaimTemplate | undefined = data
    ? {
        type: data.type,
        status: data.status,
        invoiceNumber: data.invoiceNumber,
        claimLines:
          data.chargeLines?.map((line) => ({
            chargeItemDisplay: line.chargeItem.display || line.chargeLine.code,
            chargeItemIdentifier: getCreateReferenceId(line.chargeItem),
            quantity: line.quantity,
            unitPrice: line.chargeLine.amount,
          })) || [],
      }
    : undefined;

  const title = data?.patient?.display || strings.DisplayNotFound;

  const isDraft = data?.status === 'draft';

  return (
    <ScrollView>
      <SkeletonProvider loading={loading}>
        <Section unsetZIndex>
          <Skeleton>
            <ClaimTemplateCard
              title={title}
              chargeItemFetcher={chargeItemFetcher}
              canEdit={canEdit}
              initial={initial}
              onSubmit={onEdit}
              showPrice
            />
          </Skeleton>
        </Section>
        <Section isVisible={isDraft}>
          <ConfirmationButton
            buttonText={strings.ButtonTextSubmit}
            message={strings.ClaimSubmitMessage}
            title={strings.ClaimSubmitTitle}
            onSubmit={onSubmit}
            status="accent-green"
          />
        </Section>
      </SkeletonProvider>
    </ScrollView>
  );
};
