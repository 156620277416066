import { FileResult } from 'components/types';
import { DocumentResult } from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import compact from 'lodash.compact';
import { Platform } from 'react-native';

type DocumentResultsSuccess = DocumentResult & { type: 'success' };

export const mapItemToFileResult = (document: DocumentResultsSuccess): FileResult => {
  const { name, uri, lastModified, size } = document;

  const [typePart, base64Part] = uri.split(';');

  const type = typePart.replace('data:', '');
  const base64 = base64Part.replace('base64,', '');
  const random = Math.random();
  const key = `${name}-${random}`;

  return {
    name,
    uri,
    lastModified,
    base64,
    type,
    key,
    size,
  };
};

const toBase64 = (file: File): Promise<string | undefined> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || undefined);
    reader.onerror = (error) => reject(error);
  });

export const mapFileListToArray = (fileList?: FileList): File[] => {
  const files: File[] = [];

  if (!fileList) {
    return [];
  }

  for (let i = 0; i < fileList.length; i++) {
    const item = fileList.item(i);

    if (item) {
      files.push(item);
    }
  }

  return files;
};

export const readWebFile = async (file: File): Promise<FileResult | undefined> => {
  const uri = await toBase64(file);

  if (!uri) {
    return undefined;
  }

  const result = mapItemToFileResult({
    name: file.name,
    type: 'success',
    file,
    mimeType: file.type,
    lastModified: file.lastModified,
    size: file.size,
    uri,
  });

  return result;
};

const readNativeFile = async (document: DocumentResultsSuccess): Promise<FileResult> => {
  const { uri } = document;

  const mimeType = document.mimeType as string;

  const base64 = await FileSystem.readAsStringAsync(uri, {
    encoding: FileSystem.EncodingType.Base64,
  });

  const uriBase64 = `data:${mimeType};base64,${base64}`;

  const result = mapItemToFileResult({
    ...document,
    uri: uriBase64,
  });

  return result;
};

/**
 * The web and native implementation differs and that native only allows
 * for the selection of a single file while web can do many file at once
 */
export const readFiles = async (document?: DocumentResultsSuccess): Promise<FileResult[]> => {
  if (!document) {
    return [];
  }
  const fileList = document?.output || undefined;

  const isNative = Platform.OS !== 'web';

  /**
   * In native the `output` and `file` are always
   * `undefined` so we have to use the expo FileSystem API instead to manually read the file
   */
  if (isNative) {
    const nativeFile = await readNativeFile(document);
    return [nativeFile];
  }

  const items = mapFileListToArray(fileList);

  const fileReadPromises = items.filter((f) => !!f).map(readWebFile);

  const files = await Promise.all(fileReadPromises);

  return compact(files);
};
