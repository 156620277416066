import { LoadingCard, MessageCard } from 'components/card';
import { parseError } from 'helpers/error';
import React from 'react';

export type FormPlaceHolderStep = 'loading' | 'error' | 'success';
interface FormPlaceHolderProps {
  onSubmit?: () => void;
  step: FormPlaceHolderStep;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  errorMessage?: string;
  successMessage?: string;
}

export const FormPlaceHolder: React.FC<FormPlaceHolderProps> = ({
  onSubmit,
  step,
  error,
  errorMessage = 'An Error ocurred',
  successMessage,
}) => {
  switch (step) {
    case 'loading':
      return <LoadingCard />;

    case 'success':
      return (
        <MessageCard
          additionalInformation={successMessage}
          buttonText="Back"
          message="Success"
          onSubmit={onSubmit}
        />
      );

    case 'error':
      const additionalInformation = parseError(error);
      return (
        <MessageCard
          additionalInformation={additionalInformation}
          buttonText="Reset"
          message={errorMessage}
          onSubmit={onSubmit}
        />
      );
  }
};
