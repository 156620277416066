import styled from '@emotion/native';
import {
  ClinicalImpressionCode,
  ClinicalImpressionLocationCode,
  getReferenceId,
} from '@quromedical/fhir-common';
import { MeasurementName, Vitals } from '@quromedical/models';
import { Col, Row } from 'components/base';
import { Field, SelectOption } from 'components/types';
import { getStatusMeasurements } from 'core/vitals';
import { Button, FileKeyProgressMap, PADDING, Visible } from 'design-system';
import { StatusGrid } from 'design-system/components';
import { Reference } from 'fhir/r4';
import compact from 'lodash.compact';
import React from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

import { codeOptions, locationOptions, assessmentOptions, planOptions } from './mapping';
import { FormData } from './types';

const StatusRow = styled(Row)({
  paddingLeft: PADDING['3xs'],
});

interface StatusDisplayProps {
  latest: Vitals.LatestResultMeasures;
  onAdd: () => void;
  showAdd: boolean;
}

export const StatusDisplay: React.FC<StatusDisplayProps> = ({
  onAdd: handleAdd,
  showAdd = false,
  latest,
}) => {
  const measurements = getStatusMeasurements({
    latest,
    additionalMeasures: [MeasurementName.Posture],
  });

  return (
    <Row alignItems="center">
      <StatusRow flexGrow={1}>
        <StatusGrid lg={4} xl={6} sm={2} measurements={measurements} />
      </StatusRow>
      <Visible if={showAdd}>
        <Col flexGrow={0} flexShrink={0}>
          <Button variant="text" icon="add" onPress={handleAdd} />
        </Col>
      </Visible>
    </Row>
  );
};

export const createFields = (
  latest: Vitals.LatestResultMeasures,
  vitalsLoading: boolean,
  showObjectiveNote: boolean,
  showAssessmentNote: boolean,
  showPlanNote: boolean,
  handleObjectiveAdd: () => void,
  handleAssessmentAdd: () => void,
  handlePlanAdd: () => void,
  generalPractitioner: Reference[] = [],
  progress: FileKeyProgressMap,
  uploadInProgress = false
): Field<FormData>[] => {
  const practitionerOptions: SelectOption[] = generalPractitioner.map((practitioner) => ({
    display: practitioner.display || strings.PlaceholderPractitionerDisplayMissing,
    value: getReferenceId(practitioner) as string,
  }));
  const fields: (Field<FormData> | undefined)[] = [
    {
      subfields: [
        {
          key: 'locationCode',
          type: 'toggle-button',
          label: strings.SOAPNoteLocationDescription,
          options: locationOptions,
        },
      ],
    },
    {
      subfields: [
        {
          key: 'code',
          type: 'combobox-single',
          label: strings.SOAPNoteCodeDescription,
          fetcher: codeOptions,
        },
        {
          key: 'practitionersToNotify',
          type: 'combobox-multiple',
          label: strings.FormLabelPractitionersToNotify,
          returnFullOption: false,
          fetcher: practitionerOptions,
        },
      ],
    },
    {
      icon: 'text-s',
      subfields: [
        {
          key: 'subjective',
          type: 'text-box',
          label: '',
          placeholder: strings.SOAPNoteSubjectiveCommentsPlaceholder,
          multiline: true,
        },
      ],
    },
    {
      icon: 'text-o',
      subfields: [
        {
          key: 'vitals',
          type: 'custom',
          label: '',
          paddingHorizontal: false,
          Display: () => (
            <StatusDisplay
              latest={latest}
              onAdd={handleObjectiveAdd}
              showAdd={!showObjectiveNote}
            />
          ),
        },
      ],
    },
    showObjectiveNote
      ? {
          subfields: [
            {
              key: 'objective',
              type: 'text-box',
              label: '',
              placeholder: strings.SOAPNoteObjectiveCommentsPlaceholder,
              multiline: true,
            },
          ],
        }
      : undefined,
    {
      icon: 'text-a',
      subfields: [
        {
          key: 'assessmentCode',
          type: 'radio-group',
          label: '',
          options: assessmentOptions,
          action: !showAssessmentNote
            ? {
                icon: 'add',
                onClick: handleAssessmentAdd,
              }
            : undefined,
        },
      ],
    },
    showAssessmentNote
      ? {
          subfields: [
            {
              key: 'assessment',
              type: 'text-box',
              label: '',
              placeholder: strings.SOAPNoteAssessmentCommentsPlaceholder,
              multiline: true,
            },
          ],
        }
      : undefined,
    {
      icon: 'text-p',
      subfields: [
        {
          key: 'planCode',
          type: 'radio-group',
          label: '',
          options: planOptions,
          action: !showPlanNote
            ? {
                icon: 'add',
                onClick: handlePlanAdd,
              }
            : undefined,
        },
      ],
    },
    showPlanNote
      ? {
          subfields: [
            {
              key: 'plan',
              type: 'text-box',
              label: '',
              placeholder: strings.SOAPNotePlanCommentsPlaceholder,
              multiline: true,
            },
          ],
        }
      : undefined,
    {
      subfields: [
        {
          key: 'media',
          type: 'file-picker-dialog',
          label: strings.FormLabelAttachments,
          buttonText: strings.ButtonTextAddAttachment,
          progress,
          uploadInProgress,
        },
      ],
    },
  ];

  return compact(fields);
};

export const initialValues: Partial<FormData> = {
  media: [],
  practitionersToNotify: [],
  code: ClinicalImpressionCode.Routine,
  locationCode: ClinicalImpressionLocationCode.Virtual,
};

export const initialValuesRestoreSchema = yup.object<ObjectType<FormData>>({
  media: yup.array(yup.object()).required(),
  practitionersToNotify: yup.array(yup.string()),
  code: yup.string().equals(Object.values(ClinicalImpressionCode)).required(),
  locationCode: yup.string().equals(Object.values(ClinicalImpressionLocationCode)).required(),
});
