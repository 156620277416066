import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Row } from 'components/base';
import { Spacer, Text } from 'design-system/base';
import { BORDER_WIDTH, MARGIN, PADDING } from 'design-system/theme';
import { Pressable } from 'react-native';

import { NotificationDot } from '../notification-dot';
import { getNodeColor } from './internal';
import { BaseItemPillProps, Node } from './types';

export type GroupItemPillProps = BaseItemPillProps<Node>;

interface WrapperProps {
  color: ColorName;
  selected?: boolean;
}

const Wrapper = styled(Row)<WrapperProps>(({ selected = false, color, theme }) => ({
  alignItems: 'center',
  marginRight: MARGIN.s,
  paddingTop: PADDING['2xs'],
  borderColor: theme.color[color],
  borderStyle: 'solid',
  borderWidth: 0,
  borderBottomWidth: selected ? BORDER_WIDTH[2] : undefined,
}));

export const GroupItemPill: React.FC<GroupItemPillProps> = ({ node, onSelect, onUnSelect }) => {
  const color = getNodeColor(node);
  const stateColor = node.selected ? color : undefined;

  return (
    <Pressable onPress={node.selected ? onUnSelect : onSelect}>
      <Wrapper color={color}>
        <NotificationDot color={color} border={!node.selected} />
        <Spacer width={MARGIN['2xs']} />
        <Text selectable={false} color={stateColor} variant="body-strong">
          {node.display}
        </Text>
      </Wrapper>
    </Pressable>
  );
};
