import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { PrimitiveValueType } from 'components/types';
import { pickVariant, VariantConfig, Text, Icon, IconName } from 'design-system/base';
import { BASE_SIZE, BORDER_RADIUS, BORDER_WIDTH, MARGIN, PADDING } from 'design-system/theme';
import React, { ReactElement, useCallback } from 'react';
import { ViewStyle } from 'react-native';

export type Variant = 'bordered' | 'default';
type SelectedVariant = 'selected' | 'unselected';

const itemWrapperVariants: VariantConfig<Variant, ViewStyle, ItemPressableProps> = (theme) => ({
  default: {},
  bordered: {
    paddingVertical: PADDING['3xs'],
    paddingHorizontal: PADDING['2xs'],
    borderColor: theme.color['text-default'],
    borderWidth: BORDER_WIDTH[2],
    borderRadius: BORDER_RADIUS[4],
  },
});

const selectedWrapperVariants: VariantConfig<SelectedVariant, ViewStyle, ItemPressableProps> = (
  theme
) => ({
  unselected: {},
  selected: {
    borderColor: theme.color.primary,
  },
});

interface ItemPressableProps {
  variant: Variant;
  selected: SelectedVariant;
  fullWidth?: boolean;
  hasMarginRight?: boolean;
  hasMarginBottom?: boolean;
}

const ItemPressable = styled.Pressable<ItemPressableProps>(
  ({ hasMarginRight, hasMarginBottom, fullWidth }) => ({
    width: fullWidth ? '100%' : undefined,
    flexDirection: 'row',
    marginRight: hasMarginRight ? MARGIN.s : undefined,
    marginBottom: hasMarginBottom ? MARGIN.s : undefined,
    alignItems: 'flex-start',
  }),
  (props) => pickVariant(itemWrapperVariants, props.variant, props),
  (props) => pickVariant(selectedWrapperVariants, props.selected, props)
);

const TextWrapper = styled.View({
  marginLeft: MARGIN['2xs'],
});

interface ItemProps<TValue extends PrimitiveValueType> {
  value: TValue;
  display: string;
  selected: boolean;
  isDisabled?: boolean;
  vertical?: boolean;
  variant?: Variant;
  onPress: (value: TValue) => void;
}

export const Item = <TValue extends PrimitiveValueType>({
  selected,
  value,
  display,
  isDisabled,
  onPress,
  vertical = false,
  variant = 'default',
}: ItemProps<TValue>): ReactElement => {
  const handlePress = useCallback(() => onPress(value), [value, onPress]);

  const iconName: IconName = selected ? 'radio-button-on' : 'radio-button-off';
  const disabledColor: ColorName = isDisabled ? 'text-disabled' : 'text-default';
  const hasMarginRight = !vertical;
  const hasMarginBottom = !!vertical;

  const iconColor: ColorName = selected ? 'primary' : disabledColor;

  const selectedVariant = selected ? 'selected' : 'unselected';

  return (
    <ItemPressable
      fullWidth={vertical}
      onPress={handlePress}
      accessibilityRole="radio"
      accessibilityLabel={display}
      disabled={isDisabled}
      variant={variant}
      selected={selectedVariant}
      hasMarginRight={hasMarginRight}
      hasMarginBottom={hasMarginBottom}
    >
      <Icon name={iconName} size={BASE_SIZE[20]} color={iconColor} />
      <TextWrapper>
        <Text variant="body" color={disabledColor}>
          {display}
        </Text>
      </TextWrapper>
    </ItemPressable>
  );
};
