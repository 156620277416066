import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Col, Row } from 'components/base';
import { Spacer, Text, Icon, IconName } from 'design-system/base';
import { BASE_SIZE, BORDER_RADIUS, MARGIN, PADDING } from 'design-system/theme';
import { dateTimeWithoutYearFormatter } from 'helpers';
import React from 'react';
import { TouchableOpacity } from 'react-native';

interface NotificationProps {
  title: string;
  body: string;
  time?: number;
  color: ColorName;
  icon: IconName;
  onPress: () => void;
}

const Wrapper = styled(Col)(({ theme }) => ({
  backgroundColor: theme.color['base-grey'],
  boarderRadius: BORDER_RADIUS[4],
  padding: PADDING.xs,
}));

const iconSize = BASE_SIZE[16];
const iconSpacing = MARGIN.xs;

const Body = styled(Col)({
  marginTop: MARGIN['3xs'],
  marginLeft: iconSpacing + iconSize,
});

export const Notification: React.FC<NotificationProps> = ({
  body,
  color,
  icon,
  time,
  title,
  onPress,
}) => {
  const timeDisplay = time ? dateTimeWithoutYearFormatter(time) : '';

  return (
    <TouchableOpacity onPress={onPress}>
      <Wrapper>
        <Row alignItems="center" justifyContent="space-between">
          <Row alignItems="center">
            <Icon name={icon} color={color} size={iconSize} />
            <Spacer width={iconSpacing} />
            <Text variant="caption-small">{title}</Text>
          </Row>
          <Text color="text-subdued" variant="caption-small">
            {timeDisplay}
          </Text>
        </Row>
        <Body>
          <Text variant="caption">{body}</Text>
        </Body>
      </Wrapper>
    </TouchableOpacity>
  );
};
