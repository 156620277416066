import styled from '@emotion/native';
import { Row } from 'components/base';
import { Icon } from 'design-system/base';
import { BASE_SIZE, MARGIN } from 'design-system/theme';
import { useToggleSelection } from 'hooks/useToggleSelection';
import React, { useCallback } from 'react';

import { Filter, FilterProps } from './Filter';

export type FilterItem<TIdentifier extends string | number> = Omit<
  FilterProps<TIdentifier>,
  'onClick' | 'selected'
>;

export interface FilterSectionProps<TIdentifier extends string | number> {
  filters: FilterItem<TIdentifier>[];
  initialSelection?: TIdentifier;
  onSelect?: (identifier?: TIdentifier) => void;
}

const ItemWrapper = styled.TouchableOpacity({
  marginRight: MARGIN['2xs'],
});

export const FilterSection = <TIdentifier extends string | number>({
  filters,
  initialSelection,
  onSelect,
}: FilterSectionProps<TIdentifier>) => {
  const [selected, onChange] = useToggleSelection<TIdentifier>(initialSelection, onSelect);

  const handleIconClick = useCallback(() => onChange(undefined), [onChange]);

  return (
    <Row alignItems="center" flexWrap="wrap">
      <ItemWrapper onPress={handleIconClick}>
        <Icon size={BASE_SIZE[16]} name="filter" />
      </ItemWrapper>
      {filters.map((filter) => (
        <ItemWrapper key={filter.identifier}>
          <Filter {...filter} onClick={onChange} selected={selected === filter.identifier} />
        </ItemWrapper>
      ))}
    </Row>
  );
};
