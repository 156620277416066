import {
  DeviceUseStatementList,
  DeviceUseStatementSearchForm,
  initialSearch,
  Search,
} from 'core/device-use-statement';
import { Section } from 'design-system';
import React, { useState } from 'react';
import { ScrollView } from 'react-native';

export const DeviceAssociationScreen: React.FC = () => {
  const [search, setSearch] = useState<Search>(initialSearch);

  return (
    <ScrollView>
      <Section unsetZIndex>
        <DeviceUseStatementSearchForm onSearchChange={setSearch} />
      </Section>
      <Section>
        <DeviceUseStatementList
          status={search.status}
          code={search.code}
          device={search.device}
          monitoringConfiguration={search.monitoringConfiguration}
        />
      </Section>
    </ScrollView>
  );
};
