import styled from '@emotion/native';
import { fadeColor, PADDING, useTheme } from 'design-system/theme';
import { LinearGradient } from 'expo-linear-gradient';

const Wrapper = styled(LinearGradient)({
  paddingVertical: PADDING['3xs'],
  paddingHorizontal: PADDING['2xs'],
});

export const TitleWrapper: React.FC = ({ children }) => {
  const theme = useTheme();

  const startColor = fadeColor(theme.color['text-default'], 0.2);

  return (
    <Wrapper
      colors={[startColor, theme.color.transparent]}
      start={{ x: 0, y: 0.5 }}
      end={{ x: 1, y: 0.5 }}
    >
      {children}
    </Wrapper>
  );
};
