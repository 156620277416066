import { Medication } from '@quromedical/models';

import { MedicationRepeat, RepeatDescription } from './types';

export const mapMedicationRepeatToRepeatName = (
  repeat?: Partial<Medication.Repeat>
): MedicationRepeat | undefined => {
  if (!repeat) {
    return undefined;
  }
  const descriptions = Object.keys(RepeatDescription) as MedicationRepeat[];

  const foundDescription = descriptions.find((key) => {
    const value = RepeatDescription[key];
    const isSame =
      value.frequency === repeat.frequency &&
      value.period === repeat.period &&
      value.periodUnit === repeat.periodUnit;

    return isSame;
  });

  return foundDescription;
};
