import * as Graph from 'components/graph';
import { useMemo, useState } from 'react';

import { TimelinePoint } from './TimelineCard';

interface UseTooltipPropsResult {
  onHover?: (datum?: Graph.Datum) => void;
  tooltipText?: string;
  closest?: TimelinePoint;
}

export const useTooltipProps = (data: TimelinePoint[]): UseTooltipPropsResult => {
  const [hover, onHover] = useState<Graph.Datum>();

  const getClosest = useMemo(() => Graph.getClosestDatum(data), [data]);
  const closest = useMemo(() => hover && getClosest(hover), [getClosest, hover]);

  return {
    onHover,
    tooltipText: closest?.text,
    closest,
  };
};
