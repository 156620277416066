import { FhirBaseApi } from './FhirBaseApi';

interface ValueSetQueryParams {
  search?: string;
}
export class ValueSetApi extends FhirBaseApi<fhir4.ValueSet, ValueSetQueryParams> {
  constructor() {
    super('api/fhir/value-set');
  }

  getIcd10Codes = async (search?: string): Promise<fhir4.ValueSet> =>
    this.get('icd', {
      search,
    });
}
