import { CountryCode, HumanNamePrefix } from '@quromedical/fhir-common';
import { Person, Validations } from '@quromedical/models';
import { Field, SelectOption } from 'components/types';
import { Fetcher, RadioOption } from 'design-system';
import compact from 'lodash.compact';
import { strings } from 'strings';

export const titleFetcher = async (): Promise<SelectOption<undefined, HumanNamePrefix>[]> => [
  {
    display: strings.TitleMr,
    value: HumanNamePrefix.mr,
  },
  {
    display: strings.TitleMrs,
    value: HumanNamePrefix.mrs,
  },
  {
    display: strings.TitleMiss,
    value: HumanNamePrefix.miss,
  },
  {
    display: strings.TitleDr,
    value: HumanNamePrefix.dr,
  },
  {
    display: strings.TitleSr,
    value: HumanNamePrefix.sr,
  },
  {
    display: strings.TitleProf,
    value: HumanNamePrefix.prof,
  },
];

type Gender = fhir4.Patient['gender'];

type DefinedGender = Exclude<Gender, undefined>;

export type ContactWithOutCoordinates = Omit<
  Person.ContactWithAddressEmergencyAccess,
  'addressCoordinates'
>;

export const genderDisplay: Record<DefinedGender, string> = {
  female: strings.GenderFemale,
  male: strings.GenderMale,
  other: strings.GenderOther,
  unknown: strings.GenderUnknown,
};

export const genderOptions: RadioOption<Gender>[] = [
  {
    display: strings.GenderMale,
    value: 'male',
  },
  {
    display: strings.GenderFemale,
    value: 'female',
  },
  {
    display: strings.GenderOther,
    value: 'other',
  },
  {
    display: strings.GenderUnknown,
    value: 'unknown',
  },
];

interface WithPrefix {
  prefixName?: HumanNamePrefix;
}

export const prefixRow: Field<WithPrefix> = {
  subfields: [
    {
      type: 'combobox-single',
      key: 'prefixName',
      label: strings.LabelTitle,
      fetcher: titleFetcher,
    },
  ],
  icon: 'person-outline',
};

interface WithName {
  givenName?: string;
  familyName?: string;
}

export const nameRow: Field<WithName> = {
  subfields: [
    {
      type: 'text-box',
      key: 'givenName',
      label: strings.LabelName,
    },
    {
      type: 'text-box',
      key: 'familyName',
      label: strings.LabelSurname,
    },
  ],
};

interface WithGender {
  gender?: Gender;
}

export const genderRow: Field<WithGender> = {
  subfields: [
    {
      type: 'radio-group',
      key: 'gender',
      label: strings.Gender,
      options: genderOptions,
    },
  ],
  icon: 'gender-male-female',
};

interface WithPhone {
  telecomPhoneNumbers?: string[];
}

export const createPhoneRow = (
  canShowPrimaryError: boolean,
  canShowSecondaryError: boolean
): Field<WithPhone> => ({
  subfields: [
    {
      type: 'text-box',
      // this is used so formkik can populate an array for us
      key: 'telecomPhoneNumbers.0' as keyof WithPhone,
      label: strings.Phone,
      errorMessage: canShowPrimaryError && strings.PhoneNumberLineError,
    },
    {
      type: 'text-box',
      // this is used so formkik can populate an array for us
      key: 'telecomPhoneNumbers.1' as keyof WithPhone,
      label: strings.Phone2,
      placeholder: strings.FormPlaceholderPhoneAlternative,
      errorMessage: canShowSecondaryError && strings.PhoneNumberLineError,
    },
  ],
  icon: 'phone-iphone',
});

export const phoneRow = createPhoneRow(false, false);

interface WithEmail {
  telecomEmail?: string;
}

export const emailRow: Field<WithEmail> = {
  subfields: [
    {
      type: 'text-box',
      key: 'telecomEmail',
      label: strings.Email,
    },
  ],
  icon: 'email',
};

export interface WithAddress {
  addressLines?: string[];
  addressCity?: string;
  addressPostalCode?: string;
  addressCountry?: string;
  addressPlaceId?: string;
}

interface WithEmergencyAccess {
  addressEmergencyAccess?: string;
}

export const countryFetcher: SelectOption[] = [
  {
    display: strings.SouthAfrica,
    value: CountryCode.SouthAfrica,
  },
  {
    display: strings.Namibia,
    value: CountryCode.Namibia,
  },
];

/**
 * if the address fetcher is not provided the form will only allow for manual address input
 */
export const createAddressRows = (addressSearchFetcher?: Fetcher<string>): Field<WithAddress>[] =>
  compact([
    addressSearchFetcher && {
      subfields: [
        {
          type: 'combobox-single',
          key: 'addressPlaceId',
          label: 'Search Addresses',
          searchable: true,
          fetcher: addressSearchFetcher,
        },
      ],
      icon: 'pin-drop',
    },
    {
      subfields: [
        {
          type: 'text-box',
          key: 'addressLines.0' as keyof WithAddress,
          label: strings.LabelAddressLine1,
          errorMessage: strings.AddressLine1Error,
        },
      ],
    },
    {
      subfields: [
        {
          type: 'text-box',
          key: 'addressLines.1' as keyof WithAddress,
          label: strings.LabelAddressLine2,
          placeholder: strings.FormPlaceholderAddressLine2,
        },
      ],
    },
    {
      subfields: [
        {
          type: 'text-box',
          key: 'addressCity',
          label: strings.LabelCity,
        },
      ],
    },
    {
      subfields: [
        {
          type: 'text-box',
          key: 'addressPostalCode',
          label: strings.LabelPostalCode,
        },
      ],
    },
    {
      subfields: [
        {
          type: 'combobox-single',
          key: 'addressCountry',
          fetcher: countryFetcher,
          label: strings.LabelCountry,
        },
      ],
    },
  ]);

const emergencyAccessField: Field<WithEmergencyAccess> = {
  subfields: [
    {
      type: 'text-box',
      key: 'addressEmergencyAccess',
      label: strings.LabelInCaseOfEmergency,
      multiline: true,
    },
  ],
  icon: 'error-outline',
};

export const idTypeFetcher = async (): Promise<SelectOption[]> => [
  {
    display: strings.LabelSAIDNumber,
    value: Person.IdentifierCode.saIdNumber,
  },
  {
    display: strings.LabelNamibiaIDNumber,
    value: Person.IdentifierCode.namibiaIdNumber,
  },
  {
    display: strings.LabelPassportNumber,
    value: Person.IdentifierCode.passportNumber,
  },
];

interface WithId {
  identifierCode?: Person.IdentifierCode;
  identifierValue?: string;
}

export const idRow: Field<WithId> = {
  subfields: [
    {
      type: 'combobox-single',
      label: strings.LabelIdentifierType,
      key: 'identifierCode',
      fetcher: idTypeFetcher,
    },
    {
      type: 'text-box',
      key: 'identifierValue',
      label: strings.LabelIDNumber,
    },
  ],
};

interface WithDateOfBirth {
  birthDate?: string;
}

export const birthDate: Field<WithDateOfBirth> = {
  subfields: [
    {
      maskType: 'date',
      type: 'masked-text-box',
      key: 'birthDate',
      label: strings.DateOfBirth,
    },
  ],
  icon: 'cake',
};

export const personGeneralFields: Field<Partial<Person.General>>[] = [
  prefixRow,
  nameRow,
  idRow,
  genderRow,
  birthDate,
];

export const minimalPersonGeneralFields: Field<Partial<Person.General>>[] = [
  prefixRow,
  nameRow,
  genderRow,
];

export const createPersonContactFields = (
  isPrimaryValid: boolean,
  isSecondaryValid: boolean,
  addressFetcher?: Fetcher<string>,
  hasEmergencyAccess = false
): Field<ContactWithOutCoordinates>[] => {
  const phone = createPhoneRow(!isPrimaryValid, !isSecondaryValid);
  const addressEmergencyAccess = hasEmergencyAccess ? emergencyAccessField : undefined;
  const addressRows = createAddressRows(addressFetcher);

  return compact<Field<ContactWithOutCoordinates>>([
    phone,
    emailRow,
    addressEmergencyAccess,
    ...addressRows,
  ]);
};

/**
 * Get the resolved contact form fields based on the current form state
 */
export const resolveFormContactFields = (
  formState: Partial<Person.Contact>,
  hasEmergencyAccess?: boolean,
  addressFetcher?: Fetcher<string>
): Field<ContactWithOutCoordinates>[] => {
  const isPrimaryValid = Validations.phoneSchema.isValidSync(formState.telecomPhoneNumbers?.[0]);

  // the secondary number may be empty or match the required format
  const isSecondaryEmpty = !formState.telecomPhoneNumbers?.[1];
  const isSecondaryValid =
    isSecondaryEmpty || Validations.phoneSchema.isValidSync(formState.telecomPhoneNumbers?.[1]);

  return createPersonContactFields(
    isPrimaryValid,
    isSecondaryValid,
    addressFetcher,
    hasEmergencyAccess
  );
};
