import React, { useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';
import { Line } from 'react-native-svg';

import { usePlotDataContext } from './PlotDataProvider';
import { ExternalComponentProps } from './types';

const AnimatedLine = Animated.createAnimatedComponent(Line);

interface XTrackerProps {
  xVal?: number;
  opacity?: number;
  stroke?: string;
  strokeWidth?: number;
  strokeDasharray?: number | number[];
  duration?: number;
  onEnd?: () => void;
}

type ExternalProps = ExternalComponentProps & XTrackerProps;

export const XTracker: React.FC<ExternalProps> = (props) => {
  const {
    xVal,
    xDomain,
    xScale,
    baseHeight,
    margin,
    opacity = 1,
    strokeWidth = 1,
    stroke = 'lightgrey',
    strokeDasharray,
    duration,
    onEnd,
  } = usePlotDataContext(props);

  const start = xScale(xVal || xDomain[0]);
  const end = xScale(xDomain[1]);

  const x = useRef(new Animated.Value(start)).current;

  useEffect(() => {
    x.setValue(start);

    const animation = Animated.timing(x, {
      useNativeDriver: true,
      toValue: end,
      duration,
      easing: Easing.linear,
    });

    animation.start(({ finished }) => {
      if (finished && onEnd) {
        onEnd();
      }
    });
  }, [duration, start, end, onEnd, x]);

  return (
    <AnimatedLine
      y1={margin.top}
      y2={baseHeight - margin.bottom}
      x1={x}
      x2={x}
      opacity={opacity}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeDasharray={strokeDasharray}
    />
  );
};
