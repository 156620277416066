import styled from '@emotion/native';
import { Col, Row } from 'components/base';
import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';

import { useSkeletonContext } from './SkeletonProvider';

interface SkeletonProps {
  /**
   * Whether to show the loader as a full-width component - defaults to true
   */
  fullWidth?: boolean;
}

const Wrapper = styled(Animated.View)(({ theme }) => ({
  backgroundColor: theme.color['text-disabled'],
  overflow: 'hidden',
  flex: 1,
}));

const ChildWrapper = styled.View(() => ({
  opacity: 0,
}));

/**
 * Wrapper component for Skeleton implementing a fade in and out using `react-native.Animated`
 */
const AnimatedWrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const max = 0.5;
  const min = 0.2;
  const duration = 1500;

  const fadeAnim = useRef(new Animated.Value(min)).current;

  useEffect(() => {
    const loop = Animated.loop(
      Animated.sequence([
        Animated.timing(fadeAnim, {
          toValue: max,
          duration,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnim, {
          toValue: min,
          duration,
          useNativeDriver: true,
        }),
      ])
    );

    loop.start();

    return () => loop.stop();
  }, [fadeAnim]);

  return (
    <Wrapper
      style={{
        opacity: fadeAnim,
      }}
    >
      <ChildWrapper>{children}</ChildWrapper>
    </Wrapper>
  );
};

/**
 * Wraps a component with automatic skeleton loading functionality. Requires the `SkeletonProvider`
 * to be set at the top level with the loading state. Components within the loader need to be able
 * to display content if their props are not in a ready-state to ensure that the loader has a layout
 * to work with
 *
 * Note that any child elements should be disabled (if interactable) during loading
 * @example
 * <SkeletonProvider loading={loading}>
 *   <View>
 *     <Skeleton>
 *       <Text variant="heading">Loading Placeholder</Text>
 *     </Skeleton>
 *   </View>
 * </SkeletonProvider>
 */
export const Skeleton: React.FC<React.PropsWithChildren<SkeletonProps>> = ({
  children,
  fullWidth = true,
}) => {
  const { loading = false } = useSkeletonContext();

  if (!loading) {
    return <>{children}</>;
  }

  if (fullWidth) {
    return <AnimatedWrapper>{children}</AnimatedWrapper>;
  }

  return (
    <Row>
      <Col>
        <AnimatedWrapper>{children}</AnimatedWrapper>
      </Col>
      <Col />
    </Row>
  );
};
