import { GroupSecurity } from '@quromedical/fhir-common';
import { WithGroup } from 'core/auth';
import { getDataFromResponse, serviceRequestColumns } from 'core/service-request';
import { PaginatedTable } from 'core/table';
import { Section, Visible } from 'design-system';
import { useResponsive } from 'hooks/useResponsive';
import { ServiceRequestApi } from 'integration';
import { PatientApi, PatientEndpoint } from 'integration/aggregate';
import React from 'react';
import useSWR from 'swr';

import { CoverageStatusCard, ServiceRequestForm } from '../form';

const serviceRequestApi = new ServiceRequestApi();
const patientApi = new PatientApi();

interface Props {
  patientId: string;
}

export const ServiceRequestTab: React.FC<Props> = ({ patientId }) => {
  const showHeader = useResponsive({ md: true }, false);
  const isStriped = useResponsive({ md: true }, false);

  const patientFetcher = () => patientApi.getPatient(patientId);
  const key = patientApi.getPatientSWRKey(patientId, PatientEndpoint.Base);

  const { data } = useSWR(key, patientFetcher);

  const hasActiveAdmission = !!data?.inProgressAdmission;
  const hasCoverage = !!data?.coverage;

  const authorizationCode = data?.inProgressAdmission?.medicalAidAuthorization?.code;
  const hasAuthorization = authorizationCode ? authorizationCode !== '' : false;

  const serviceType = data?.inProgressAdmission?.service;
  const isServiceTypeTcp = serviceType ? serviceType === 'encounter.service.lace' : false;

  /**
   *  We allow the user to create a service request if they have an active admission,
   * coverageand authorization, or if they have an active admission and coverage and the service type is TCP.
   */
  const displayForm =
    (hasActiveAdmission && hasCoverage && hasAuthorization) ||
    (hasActiveAdmission && hasCoverage && isServiceTypeTcp);

  const fetcher = (pageToken?: string) =>
    serviceRequestApi.getServiceRequests({
      subject: patientId,
      pageToken,
    });

  const cacheKey = JSON.stringify({
    subject: patientId,
  });

  return (
    <>
      <WithGroup groups={[GroupSecurity.ServiceRequestCreate]}>
        <Visible if={displayForm}>
          <Section>
            <ServiceRequestForm patientId={patientId} />
          </Section>
        </Visible>
        <Visible if={!displayForm}>
          <Section>
            <CoverageStatusCard
              hasActiveAdmission={hasActiveAdmission}
              hasCoverage={hasCoverage}
              hasAuthorization={hasAuthorization}
            />
          </Section>
        </Visible>
      </WithGroup>

      <Section>
        <PaginatedTable
          cacheKey={`ServiceRequest${cacheKey}`}
          fetcher={fetcher}
          columns={serviceRequestColumns}
          showHeader={showHeader}
          isStriped={isStriped}
          getDataFromResponse={getDataFromResponse}
        />
      </Section>
    </>
  );
};
