import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { OnboardMedicalAidScreen, OnboardPrivateScreen } from 'screens/patient';

import { OnboardStackParamList, AdminDrawerParamList } from './types';

const Stack = createNativeStackNavigator<OnboardStackParamList>();

export const PatientOnboardStackNavigator: React.FC<
  NativeStackScreenProps<AdminDrawerParamList, 'Onboard'>
> = () => (
  <Stack.Navigator
    initialRouteName="MedicalAid"
    screenOptions={{
      headerShown: false,
    }}
  >
    <Stack.Screen
      name="MedicalAid"
      component={OnboardMedicalAidScreen}
      options={{
        title: 'Patients',
      }}
    />
    <Stack.Screen
      name="Private"
      component={OnboardPrivateScreen}
      options={{
        title: 'Patient',
      }}
    />
  </Stack.Navigator>
);
