import { ObjectType } from 'validation';
import * as yup from 'yup';

import { FormData, TestType } from './types';

export const isTestTypeSpecial = (code: TestType): boolean => code === 'special';

export const schema = yup
  .object<ObjectType<FormData>>({
    testType: yup.string().required(),
    testCodes: yup.array().when('testType', {
      is: isTestTypeSpecial,
      then: yup.array().required().min(1).max(1),
      otherwise: yup.array().required().min(1),
    }),
    sampleDateTime: yup.date().required(),
    comment: yup.string().optional(),
  })
  .defined();
