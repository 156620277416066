import { IconName } from 'design-system/base';
import React from 'react';

import { FormCard } from '../form-card';

interface MessageCardProps {
  title?: string;
  message?: string;
  icon?: IconName;
}

export const MessageCard: React.FC<MessageCardProps> = ({ title = '', message = '', icon }) => (
  <FormCard
    title={title}
    rows={[
      {
        icon,
        fields: [
          {
            type: 'text',
            label: message,
          },
        ],
      },
    ]}
  />
);
