import { VideoTile } from 'design-system';
import { usePeer } from 'providers/video';
import React from 'react';

import { Peer } from './Peer';

interface RemotePeerProps {
  peerId?: string;
}

export const RemotePeer: React.FC<RemotePeerProps> = ({ peerId = undefined }) => {
  const peer = usePeer(peerId);

  if (!peer) {
    return null;
  }

  return (
    <VideoTile
      displayName={peer.name}
      isMicEnabled={peer.isMicEnabled}
      isVideoEnabled={peer.isVideoEnabled}
      isMinimized={false}
      showMuteIcon
      signalLevel={peer.signalLevel}
      volume={peer.volume}
    >
      <Peer peer={peer} />
    </VideoTile>
  );
};
