import { MouseEvent, useCallback } from 'react';
import { Platform } from 'react-native';
import { useAsyncDebounce } from 'react-table';

/**
 * Only supports web, hover as a concept does not exist on native
 */
interface PlatformHandler {
  onMouseMove?: (event: MouseEvent) => void;
}

type Scaler = (v: number) => number;

/**
 * Will not have any effect on mobile since there isn't any concept of hovering
 */
export const usePlatformHoverHandler = (
  xScaler: Scaler,
  yScaler: Scaler,
  moveHandler?: (position: { x: number; y: number }) => void,
  debounce = 100
): PlatformHandler => {
  const webCallback = useCallback(
    (e: MouseEvent) => {
      const { offsetX, offsetY } = e.nativeEvent;
      moveHandler?.({ x: xScaler(offsetX), y: yScaler(offsetY) });
    },
    [moveHandler, xScaler, yScaler]
  );

  const webCallbackDebounced = useAsyncDebounce(webCallback, debounce);

  const isWeb = Platform.OS === 'web';
  const canHandle = isWeb && moveHandler;

  if (!canHandle) {
    return {};
  }

  return {
    onMouseMove: webCallbackDebounced,
  };
};
