import { ascending, range } from 'd3-array';
import { interpolateRgb } from 'd3-interpolate';
import { scaleLinear } from 'd3-scale';
import React from 'react';
import { Defs, LinearGradient as Base, Stop } from 'react-native-svg';

import { usePlotDataContext } from './PlotDataProvider';
import { Domain, ExternalComponentProps } from './types';

export interface StopProps {
  color: string;
  opacity?: number;
  offsetVal: number;
}

interface PlotLineProps {
  id: string;
  stops: StopProps[];
  direction?: 'vertical' | 'horizontal';
  relativeDomain?: Domain;
}

type ExternalProps = ExternalComponentProps & PlotLineProps;

export const LinearGradient: React.FC<ExternalProps> = (props) => {
  const {
    id,
    direction = 'vertical',
    yDomain,
    xDomain,
    relativeDomain,
    stops,
  } = usePlotDataContext(props);

  const isVertical = direction === 'vertical';

  const domain = isVertical ? yDomain : xDomain;

  const gradientScale = scaleLinear()
    .domain(relativeDomain || domain)
    .range([0, 1]);

  // set x and y values if direction is vertical. SVG defaults to horizontal
  const y1 = isVertical ? 1 : undefined;
  const y2 = isVertical ? 0 : undefined;
  const x1 = isVertical ? 0 : undefined;
  const x2 = isVertical ? 0 : undefined;

  const scaledStops = stops
    .sort((a, b) => ascending(a.offsetVal, b.offsetVal))
    .map((stop) => {
      const offsetScaled = gradientScale(stop.offsetVal);

      return {
        ...stop,
        offsetScaled,
      };
    });

  const colorDomain = scaledStops.map((stop) => stop.offsetScaled);
  const colorRange = scaledStops.map((stop) => stop.color);

  // create a color scale so we can interpolate without worrying about the given domain values
  const colorScale = scaleLinear<string, number>()
    .domain(colorDomain)
    .range(colorRange)
    .interpolate(interpolateRgb);

  // create a new gradient which spans the entire range generated from the given stops
  const step = 1 / 20;
  const gradientStops = range(0, 1 + step, step).map((offset) => ({
    offset,
    color: colorScale(offset),
  }));

  return (
    <Defs>
      <Base id={id} y1={y1} x1={x1} y2={y2} x2={x2}>
        {gradientStops.map((stop) => (
          <Stop key={stop.offset} offset={stop.offset} stopColor={stop.color} />
        ))}
      </Base>
    </Defs>
  );
};
