import { GroupSecurity } from '@quromedical/fhir-common';
import { MeasurementName, Vitals } from '@quromedical/models';
import {
  metricValueGetters,
  secondaryDataGetters,
  primaryDataGetters,
  getLegends,
} from 'core/vitals';
import { MARGIN, SkeletonProvider, Spacer } from 'design-system';
import { Grid } from 'design-system/layout';
import { useUserSession } from 'hooks';
import { useResponsive } from 'hooks/useResponsive';
import { useRevalidation } from 'hooks/useRevalidation';
import { VitalsApi } from 'integration/aggregate';
import compact from 'lodash.compact';
import { usePatientId } from 'providers/PatientIdContext';
import { getBoundaryTimes, Times } from 'providers/PatientVitalsProvider';
import React, { useCallback, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import useSWR from 'swr';

import { VitalsGridItem } from './VitalsGridItem';

interface VitalsTrackingGridProps {
  scrollToTop?: () => void;
  id: string;
  times: Required<Times>;
  measurements: MeasurementName[];
}

const api = new VitalsApi();

interface TrackingGridItemProps {
  measureName: MeasurementName;
  latest: Vitals.LatestResultMeasures;
  vitals: Vitals.VitalsResultMeasures;
  patientId: string;
  initialWidth: number;
  timeFrame: [number, number];
  toggleExpanded: (measureName: MeasurementName) => void;
  expanded: boolean;
  canViewFilter?: boolean;
  revalidate?: () => void;
}

const TrackingGridItem: React.FC<TrackingGridItemProps> = ({
  initialWidth,
  measureName,
  latest,
  patientId,
  timeFrame,
  toggleExpanded,
  vitals,
  expanded,
  canViewFilter,
  revalidate,
}) => {
  const summary = metricValueGetters[measureName]?.(latest);
  const primaryData = primaryDataGetters[measureName]?.(vitals);
  const resolvedPrimaryData = primaryData?.data || vitals[measureName];
  const secondaryData = secondaryDataGetters[measureName]?.(vitals) || [];
  const graphCodes = compact([
    primaryData?.measureName || measureName,
    ...secondaryData.map((d) => d.measureName),
  ]);
  const legend = getLegends(graphCodes);
  const primaryGraphMeasureName = primaryData?.measureName || measureName;

  return (
    <VitalsGridItem
      revalidate={revalidate}
      patientId={patientId}
      key={measureName}
      measureName={measureName}
      primaryGraphMeasureName={primaryGraphMeasureName}
      vitals={resolvedPrimaryData}
      latest={latest[measureName]}
      initialWidth={initialWidth}
      timeFrame={timeFrame}
      isExpanded={expanded}
      onExpandToggled={toggleExpanded}
      summary={summary}
      secondaryData={secondaryData}
      legend={legend}
      canViewFilter={canViewFilter}
      showSecondaryData
    />
  );
};

export const VitalsTrackingGrid: React.FC<VitalsTrackingGridProps> = ({
  scrollToTop,
  times,
  id,
  measurements,
}) => {
  const session = useUserSession();

  // only users who are allowed to manage device use statements should care about filters
  const canViewFilter = session.hasAny([
    GroupSecurity.DeviceUseStatementCreate,
    GroupSecurity.DeviceUseStatementAdmin,
  ]);

  const { width } = useWindowDimensions();
  const [expanded, setExpanded] = useState<MeasurementName | undefined>();

  const resolvedTimes = getBoundaryTimes(times);

  const toggleExpanded = useCallback(
    (measureName: MeasurementName) => {
      if (measureName === expanded) {
        setExpanded(undefined);
      } else {
        setExpanded(measureName);
      }

      if (scrollToTop) {
        scrollToTop();
      }
    },
    [expanded, scrollToTop]
  );

  const columns = useResponsive({ md: 2 }, 1);
  const initialWidth = width / columns;

  const patientId = usePatientId();

  const filteredMeasures = measurements.filter((key) => key !== expanded);

  const fetcher = useCallback(() => api.getTracking(id, resolvedTimes), [id, resolvedTimes]);
  const swrKey = `vitals-tracking/${times.start.getTime()}/${times.duration}`;

  const { isValidating, data } = useSWR(swrKey, fetcher);
  const revalidate = useRevalidation(swrKey);

  const vitals = data?.vitals.type === 'success' ? data.vitals.data : {};
  const latest = data?.latest.type === 'success' ? data.latest.data : {};

  const timeFrame: [number, number] = [resolvedTimes.start, resolvedTimes.end];

  return (
    <SkeletonProvider loading={isValidating}>
      {expanded ? (
        <>
          <TrackingGridItem
            key={expanded}
            measureName={expanded}
            latest={latest}
            vitals={vitals}
            patientId={patientId}
            initialWidth={initialWidth}
            timeFrame={timeFrame}
            toggleExpanded={toggleExpanded}
            canViewFilter={canViewFilter}
            revalidate={revalidate}
            expanded
          />
          <Spacer height={MARGIN.m} />
        </>
      ) : null}

      <Grid sm={1} md={2} gap="m">
        {filteredMeasures.map((measureName) => (
          <TrackingGridItem
            key={measureName}
            measureName={measureName}
            latest={latest}
            vitals={vitals}
            patientId={patientId}
            initialWidth={initialWidth}
            timeFrame={timeFrame}
            toggleExpanded={toggleExpanded}
            expanded={false}
            canViewFilter={canViewFilter}
            revalidate={revalidate}
          />
        ))}
      </Grid>
    </SkeletonProvider>
  );
};
