import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface Context {
  isNavigationVisible: boolean;
  hideNavigation: () => void;
  showNavigation: () => void;
}

const initialProps: Context = {
  isNavigationVisible: true,
  showNavigation: () => undefined,
  hideNavigation: () => undefined,
};

const BottomNavigatorContext = createContext<Context>(initialProps);

interface ProviderProps {
  isVisibleInitially?: boolean;
}

export const useBottomNavigatorContext = (): Context => useContext(BottomNavigatorContext);

/**
 * Required so that child components can dynamically chose to make the navigation invisible or not
 */
export const BottomNavigatorProvider: React.FC<ProviderProps> = ({
  children,
  isVisibleInitially = true,
}) => {
  const [isNavigationVisible, setNavigationVisible] = useState(isVisibleInitially);

  const hideNavigation = useCallback(() => {
    setNavigationVisible(false);
  }, []);

  const showNavigation = useCallback(() => {
    setNavigationVisible(true);
  }, []);

  return (
    <BottomNavigatorContext.Provider
      value={{
        isNavigationVisible,
        hideNavigation,
        showNavigation,
      }}
    >
      {children}
    </BottomNavigatorContext.Provider>
  );
};

/**
 * When called will ensure that the `topBarHidden` is true for the lifecycle of the component and
 * will unhide when the component dismounts
 */
export const useBottomNavigatorHidden = (): void => {
  const { hideNavigation, showNavigation } = useContext(BottomNavigatorContext);

  useEffect(() => {
    hideNavigation();

    return () => showNavigation();
  }, [hideNavigation, showNavigation]);
};
