import { Person } from '@quromedical/models';
import { IdentifierCodeDisplay } from 'core/display';
import { getDisplayName } from 'core/person';
import { maskedInputFormatter, RowConfig } from 'design-system';
import compact from 'lodash.compact';
import { strings } from 'strings';

export const getMinimalPersonalDetailsRows = (
  general: Partial<Person.General> = {}
): RowConfig[] => {
  const displayName = getDisplayName(general);

  return [
    {
      icon: 'person-outline',
      fields: [
        {
          type: 'text',
          label: strings.LabelNameAndSurname,
          display: displayName,
        },
      ],
    },
    {
      icon: 'gender-male-female',
      fields: [
        {
          type: 'text',
          label: strings.Gender,
          display: general.gender,
        },
      ],
    },
  ];
};

export const getFullPersonalDetailsRows = (general: Partial<Person.General> = {}): RowConfig[] => {
  const date = general.birthDate ? maskedInputFormatter.date(new Date(general.birthDate)) : '';

  const minimalPersonalDetails = getMinimalPersonalDetailsRows(general);
  const extendedPersonalDetails: RowConfig[] = [
    {
      icon: 'cake',
      fields: [
        {
          type: 'text',
          label: strings.DateOfBirth,
          display: date,
        },
      ],
    },
    {
      fields: [
        {
          type: 'text',
          label: strings.LabelIDNumber,
          display: general.identifierValue,
        },
        {
          type: 'text',
          label: strings.LabelIdentifierType,
          display: general.identifierCode && IdentifierCodeDisplay[general.identifierCode],
        },
      ],
    },
  ];

  return [...minimalPersonalDetails, ...extendedPersonalDetails];
};

export const getContactDetailsRows = (
  contact: Partial<Person.ContactWithAddressEmergencyAccess> = {},
  hasEmergencyAccess = false
): RowConfig[] =>
  compact([
    {
      icon: 'phone-iphone',
      fields: [
        {
          type: 'text',
          label: strings.Phone,
          display: contact.telecomPhoneNumbers?.[0],
        },
        {
          type: 'text',
          label: strings.Phone2,
          display: contact.telecomPhoneNumbers?.[1],
        },
      ],
    },
    {
      icon: 'email-outline',
      fields: [
        {
          type: 'text',
          label: strings.Email,
          display: contact.telecomEmail,
        },
      ],
    },
    {
      icon: 'pin-drop',
      fields: [
        {
          type: 'list',
          label: strings.LabelAddress,
          display: contact.addressLines,
        },
      ],
    },
    {
      fields: [
        {
          type: 'text',
          label: strings.LabelPostalCode,
          display: contact.addressPostalCode,
        },
        {
          type: 'text',
          label: strings.LabelCity,
          display: contact.addressCity,
        },
      ],
    },
    {
      fields: [
        {
          type: 'text',
          label: strings.LabelCountry,
          display: contact.addressCountry,
        },
      ],
    },
    hasEmergencyAccess && {
      icon: 'error-outline',
      fields: [
        {
          type: 'text',
          label: strings.LabelInCaseOfEmergency,
          display: contact.addressEmergencyAccess,
        },
      ],
    },
  ]);

export const getContactDetailsWithoutAddressRows = (
  contact: Partial<Person.ContactWithAddressEmergencyAccess> = {},
  hasEmergencyAccess = false
): RowConfig[] =>
  compact([
    {
      icon: 'phone-iphone',
      fields: [
        {
          type: 'text',
          label: strings.Phone,
          display: contact.telecomPhoneNumbers?.[0],
        },
        {
          type: 'text',
          label: strings.Phone2,
          display: contact.telecomPhoneNumbers?.[1],
        },
      ],
    },
    {
      icon: 'email-outline',
      fields: [
        {
          type: 'text',
          label: strings.Email,
          display: contact.telecomEmail,
        },
      ],
    },
    hasEmergencyAccess && {
      icon: 'error-outline',
      fields: [
        {
          type: 'text',
          label: strings.LabelInCaseOfEmergency,
          display: contact.addressEmergencyAccess,
        },
      ],
    },
  ]);

export const getAddressDetailsRows = (
  contact: Partial<Person.ContactWithAddressEmergencyAccess> = {}
): RowConfig[] =>
  compact([
    {
      icon: 'pin-drop',
      fields: [
        {
          type: 'list',
          label: strings.LabelAddress,
          display: contact.addressLines,
        },
      ],
    },
    {
      fields: [
        {
          type: 'text',
          label: strings.LabelPostalCode,
          display: contact.addressPostalCode,
        },
        {
          type: 'text',
          label: strings.LabelCity,
          display: contact.addressCity,
        },
      ],
    },
    {
      fields: [
        {
          type: 'text',
          label: strings.LabelCountry,
          display: contact.addressCountry,
        },
      ],
    },
  ]);
