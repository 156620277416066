import styled from '@emotion/native';
import { Col } from 'components/base';
import { IconName } from 'design-system/base';
import { Absolute } from 'design-system/layout';
import { useLayoutDimensions } from 'hooks/useLayoutDimensions';
import { useResponsive } from 'hooks/useResponsive';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { Header } from './Header';

export interface CallWrapperProps {
  title: string;
  count?: number;
  isPrimaryButton?: boolean;
  buttonIcon: IconName;
  onButtonPress: () => void;
}
interface PaddedScrollViewProps {
  width: number;
}

const Wrapper = styled(Col)(({ theme }) => {
  const { height, width } = useWindowDimensions();
  return {
    backgroundColor: theme.color['base-grey'],
    height,
    width,
  };
});

const ContentWrapper = styled(Col)<PaddedScrollViewProps>(({ width: baseWidth }) => {
  const dimensions = useWindowDimensions();
  const screenFactor = 0.9;
  const maxWidth = dimensions.width * screenFactor;
  const width = baseWidth * screenFactor;

  return {
    marginHorizontal: 'auto',
    width,
    maxWidth,
  };
});

export const CallWrapper: React.FC<CallWrapperProps> = ({
  children,
  count = 1,
  isPrimaryButton = false,
  buttonIcon,
  title,
  onButtonPress,
}) => {
  const buttonVariant = isPrimaryButton ? 'contained' : 'outlined';

  const { width, height } = useWindowDimensions();
  const { layout, onLayout } = useLayoutDimensions({
    initialHeight: height,
    initialWidth: width,
  });
  const safeHeight = layout.height * 0.8;
  const minDimension = layout.width < safeHeight ? layout.width : safeHeight;
  const baseCols = useResponsive({ sm: 1, md: 2, lg: 3, xl: 4 }, 1);
  const cols = baseCols > count ? count : baseCols;
  const contentWidth = minDimension * cols;

  return (
    <Absolute mode="full-screen" name="call-wrapper">
      <Wrapper flex={1}>
        <ScrollView onLayout={onLayout}>
          <Col flex={1}>
            <Header
              title={title}
              onPress={onButtonPress}
              buttonIcon={buttonIcon}
              buttonVariant={buttonVariant}
            />
            <Col flex={1}>
              <ContentWrapper width={contentWidth}>{children}</ContentWrapper>
            </Col>
          </Col>
        </ScrollView>
      </Wrapper>
    </Absolute>
  );
};
