import { GroupSecurity } from '@quromedical/fhir-common';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useFhirDisplay } from 'components/fhir';
import { getPlatformConfig } from 'helpers';
import { DrawerType } from 'hooks/useDrawerType';
import { useFiniteState } from 'hooks/useFiniteState';
import { useUserSession } from 'hooks/useUserSession';
import { DrawerActionsProvider } from 'providers/DrawerActionsContext';
import { useKBarPatientActions } from 'providers/kbar';
import { PatientIdProvider } from 'providers/PatientIdContext';
import { PatientVitalsProvider } from 'providers/PatientVitalsProvider';
import React from 'react';
import { MediaCreateScreen, MediaListScreen } from 'screens/media';
import {
  PatientNotesScreen,
  ServiceRequestsScreen,
  PatientVitalsScreen,
  PatientProfileScreen,
  CreateMeetingScreen,
  PatientSummaryScreen,
  AdmissionCreateScreen,
  PatientClinicalScreen,
} from 'screens/patient';
import { strings } from 'strings';

import { DrawerNavigatorContent } from './DrawerNavigatorContent';
import { hiddenDrawerItemStyle, useCommonDrawerProps } from './drawerStyles';
import { PatientDrawerParamList, PatientStackParamList } from './types';

const Drawer = createDrawerNavigator<PatientDrawerParamList>();

export const PatientDrawerNavigator: React.FC<
  NativeStackScreenProps<PatientStackParamList, 'View'>
> = ({ route, navigation }) => {
  const { id } = route.params;

  const screenOptions = useCommonDrawerProps();
  const drawerType = useFiniteState<DrawerType>(screenOptions.drawerType);

  // Make available for users with the new navigator feature preview
  const session = useUserSession();
  const hasVideoConsult = session.hasAny([GroupSecurity.VideoConsultCreate]);

  const patientNameDisplay = useFhirDisplay('Patient', id);

  const { isNative } = getPlatformConfig();

  navigation.setOptions({
    title: patientNameDisplay || 'Patient',
  });

  // always hide on native, show on web depending on the feature preview permissions style
  const hasVideoItem = isNative && hasVideoConsult;
  const videoDrawerItemStyle = hasVideoItem ? hiddenDrawerItemStyle : undefined;

  const hasClinicalPermission = session.hasAny([GroupSecurity.MedicationStatementRead]);
  const clinicalDrawerItemStyle = hasClinicalPermission ? undefined : hiddenDrawerItemStyle;

  useKBarPatientActions(id, patientNameDisplay || strings.DisplayLoading);

  return (
    <PatientIdProvider id={id}>
      <PatientVitalsProvider id={id}>
        <DrawerActionsProvider
          onHide={drawerType.next('back')}
          onShow={drawerType.next(screenOptions.drawerType)}
        >
          <Drawer.Navigator
            initialRouteName="Profile"
            detachInactiveScreens={true}
            screenOptions={{ ...screenOptions, drawerType: drawerType.value }}
            drawerContent={DrawerNavigatorContent}
          >
            <Drawer.Screen
              name="Profile"
              component={PatientProfileScreen}
              options={{ title: 'Profile' }}
            />
            <Drawer.Screen
              name="Vitals"
              component={PatientVitalsScreen}
              options={{ title: 'Vitals' }}
            />
            <Drawer.Screen
              name="Clinical"
              component={PatientClinicalScreen}
              options={{
                title: 'Clinical',
                drawerItemStyle: clinicalDrawerItemStyle,
              }}
            />
            <Drawer.Screen
              name="Notes"
              component={PatientNotesScreen}
              options={{ title: 'Notes' }}
            />
            <Drawer.Screen
              name="PrintSummary"
              component={PatientSummaryScreen}
              options={{
                title: 'Print Summary',
                drawerItemStyle: hiddenDrawerItemStyle,
              }}
            />
            <Drawer.Screen
              name="ServiceRequests"
              component={ServiceRequestsScreen}
              options={{ title: strings.PathologyDrawerTitle }}
            />
            <Drawer.Screen name="Medias" component={MediaListScreen} options={{ title: 'Media' }} />
            <Drawer.Screen
              name="CreateMedia"
              component={MediaCreateScreen}
              options={{
                title: 'Create Media',
                drawerItemStyle: hiddenDrawerItemStyle,
              }}
            />
            <Drawer.Screen
              name="VideoConsult"
              component={CreateMeetingScreen}
              options={{ title: strings.VideoConsult, drawerItemStyle: videoDrawerItemStyle }}
            />
            <Drawer.Screen
              name="Admit"
              component={AdmissionCreateScreen}
              options={{
                title: strings.AdmissionDrawerTitle,
                drawerItemStyle: hiddenDrawerItemStyle,
              }}
            />
          </Drawer.Navigator>
        </DrawerActionsProvider>
      </PatientVitalsProvider>
    </PatientIdProvider>
  );
};
