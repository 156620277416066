import { Media } from '@quromedical/models';
import { FormPlaceHolder, FormPlaceHolderStep } from 'components/form';
import { MediaForm } from 'core/media';
import { PatientHeaderSection } from 'core/patient';
import { useRevalidation } from 'hooks/useRevalidation';
import { MediaApi } from 'integration/aggregate';
import { PatientDrawerScreenProps } from 'navigation';
import { usePatientId } from 'providers/PatientIdContext';
import React, { useCallback, useState } from 'react';
import { ScrollView } from 'react-native';

type FormStep = 'form' | FormPlaceHolderStep;

const api = new MediaApi();

const errorMessage = 'Could not create Media';
const successMessage = 'Successfully created Media';

export const MediaCreateScreen: React.FC<PatientDrawerScreenProps<'CreateMedia'>> = () => {
  const [step, setStep] = useState<FormStep>('form');
  const [formError, setFormError] = useState<any>();

  const patientId = usePatientId();
  const revalidate = useRevalidation(patientId);

  const onSubmit = useCallback(
    async (formData: Media.CreateRequest) => {
      setStep('loading');
      try {
        await api.createMedia(patientId, formData);
        setStep('success');
        revalidate();
      } catch (err) {
        setFormError(err);
        setStep('error');
      }
    },
    [patientId, revalidate]
  );

  const handleResetForm = useCallback(() => setStep('form'), []);

  switch (step) {
    case 'loading':
    case 'error':
    case 'success':
      return (
        <FormPlaceHolder
          error={formError}
          step={step}
          errorMessage={errorMessage}
          successMessage={successMessage}
          onSubmit={handleResetForm}
        />
      );

    default:
      return (
        <ScrollView>
          <PatientHeaderSection id={patientId} />
          <MediaForm patientId={patientId} onSubmit={onSubmit} />
        </ScrollView>
      );
  }
};
