import styled from '@emotion/native';
import { GroupSecurity } from '@quromedical/fhir-common';
import { Row, Col } from 'components/base';
import { WithGroup } from 'core/auth';
import {
  MARGIN,
  Button,
  Card,
  Header,
  Spacer,
  Divider,
  PADDING,
  Skeleton,
  BASE_SIZE,
  Text,
} from 'design-system';
import { useMeeting } from 'providers/video';
import React from 'react';
import { strings } from 'strings';

import { CopyInviteButton, InviteButton } from './invite';
import { CreateMeetingCardComponent } from './types';

const ControlWrapper = styled(Row)(() => ({
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

const BodyWrapper = styled.View({
  marginTop: MARGIN.m,
  paddingHorizontal: PADDING.m,
  paddingBottom: PADDING.s,
});

export const CreateMeetingCard: CreateMeetingCardComponent = ({
  patientId,
  room,
  handleJoinWaitingRoom,
}) => {
  const { meetingState } = useMeeting();
  return (
    <Card paddingHorizontal={0} paddingVertical={0} border={true}>
      <Header title={strings.StartConsultHeader} blockColor={'accent-purple'} icon="video-call" />
      <BodyWrapper>
        <WithGroup groups={[GroupSecurity.VideoConsultInvite]}>
          <Skeleton>
            <Button
              disabled={meetingState.type !== 'out-of-call'}
              text={strings.StartConsult}
              onPress={handleJoinWaitingRoom}
            />
          </Skeleton>
          <Spacer height={MARGIN.xs} />

          <Divider orientation="horizontal" />

          <Spacer height={MARGIN.xs} />

          <ControlWrapper>
            <Skeleton>
              {room ? (
                <Col>
                  <Row>
                    <Text variant="subheading">{strings.SendInviteHeader}</Text>
                  </Row>
                  <Spacer height={MARGIN.s} />
                  <Row>
                    <InviteButton
                      buttonText={strings.ButtonTextInviteViaSms}
                      roomId={room.roomId}
                      patientId={patientId}
                      type="sms"
                      icon="send-to-mobile"
                    />
                    <Spacer width={MARGIN['2xs']} />
                    <InviteButton
                      buttonText={strings.ButtonTextInviteViaEmail}
                      roomId={room.roomId}
                      patientId={patientId}
                      type="email"
                      icon="email"
                    />
                    <Spacer width={MARGIN['2xs']} />
                    <InviteButton
                      buttonText={strings.ButtonTextInviteViaWhatsapp}
                      roomId={room.roomId}
                      patientId={patientId}
                      type="whatsapp"
                      icon="whatsapp"
                    />
                    <Spacer width={MARGIN['2xs']} />
                    <CopyInviteButton meetingLink={room.joinRoomUrl} />
                  </Row>
                </Col>
              ) : (
                <Spacer height={BASE_SIZE[42]} />
              )}
            </Skeleton>
          </ControlWrapper>
        </WithGroup>
      </BodyWrapper>
    </Card>
  );
};
