// converted using SVGR with React-Native and Typescript Options
// https://react-svgr.com/playground/?native=true&typescript=true
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { Icon } from './internal';

export const Activity: Icon = ({ color = '#000000', size = 24 }) => (
  <Svg width={size} height={size} fill="none" viewBox="0 0 24 24">
    <Path
      d="m9.486 15.844 1.81 1.81-1.564 4.226a1.178 1.178 0 0 1-1.498.693 1.168 1.168 0 0 1-.693-1.498l1.945-5.231Z"
      fill={color}
    />
    <Path
      d="m6.11 11.217 1.073-2.056c.29-.537.715-.984 1.23-1.297 2.48-1.476 2.794-1.453 3.107-1.43l1.677.111c.514.022.872.157 2.593 2.683.067.09.157.156.29.179l2.057.313c.56.089.94.603.85 1.162-.09.559-.604.94-1.163.85l-2.056-.313c-.671-.112-1.275-.47-1.655-1.029-.09-.134-.156-.246-.246-.357l-1.319 4.18 1.834 1.833c.268.269.492.626.626.984l1.408 4.114a1.147 1.147 0 0 1-.715 1.475 1.133 1.133 0 0 1-.38.067c-.492 0-.94-.313-1.118-.782l-1.409-4.114c-.022-.045-.045-.067-.067-.112l-3.264-3.286a1.884 1.884 0 0 1-.514-1.744l.715-3.174c-.067.044-.134.089-.223.134-.201.112-.358.29-.47.492l-1.073 2.056a1.026 1.026 0 0 1-.894.537c-.157 0-.313-.045-.47-.112a1.027 1.027 0 0 1-.424-1.364ZM12.947 1.428a2.258 2.258 0 1 1-.489 4.488 2.258 2.258 0 0 1 .489-4.488Z"
      fill={color}
    />
  </Svg>
);
