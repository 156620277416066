// converted using SVGR with React-Native and Typescript Options
// https://react-svgr.com/playground/?native=true&typescript=true
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { Icon } from './internal';

export const OxygenSaturation: Icon = ({ color = '#000000', size = 24 }) => (
  <Svg width={size} height={size} fill="none" viewBox="0 0 24 24">
    <Path
      d="M11.76 20.43v-.916a3.214 3.214 0 0 1-3.21-3.21h-.916a4.133 4.133 0 0 0 4.127 4.127ZM13.054 14.189 10.81 8.576l-2.189 3.94H2.896v.918h6.266l1.513-2.724 2.199 5.496 1.733-2.772h6.325v-.917H14.1l-1.046 1.672Z"
      fill={color}
      stroke={color}
      strokeWidth={0.5}
    />
    <Path
      d="M15.955 14.045c.29.787.473 1.61.544 2.446a4.739 4.739 0 0 1-9.477 0c.07-.836.254-1.659.544-2.446h-.954a9.394 9.394 0 0 0-.507 2.446 5.656 5.656 0 0 0 11.31 0 9.394 9.394 0 0 0-.507-2.446h-.953ZM11.76 4.088c1.046 2.173 2.428 5.218 3.43 7.817h.976c-1.504-3.974-3.849-8.774-3.993-9.067L11.761 2l-.413.838c-.144.293-2.488 5.093-3.993 9.067h.976c1.002-2.599 2.384-5.644 3.43-7.817Z"
      fill={color}
      stroke={color}
      strokeWidth={0.5}
    />
  </Svg>
);
