import { DiagnosticReportCode } from '@quromedical/fhir-common';
import { Field } from 'components/types';
import { patientFetcher } from 'integration';
import { Params as DiagnosticReportParams } from 'integration/DiagnosticReportApi';

export const initialSearch: Partial<DiagnosticReportParams> = {
  code: DiagnosticReportCode.ampath,
};

export const searchFields: Field<DiagnosticReportParams>[] = [
  {
    subfields: [
      {
        type: 'combobox-single',
        searchable: true,
        key: 'subject',
        label: 'Patient',
        fetcher: patientFetcher,
      },
      {
        type: 'combobox-multiple',
        searchable: true,
        key: 'status',
        label: 'Status',
        fetcher: [
          {
            value: 'final',
            display: 'Final',
          },
          {
            value: 'preliminary',
            display: 'Preliminary',
          },
          {
            value: 'unknown',
            display: 'Unknown',
          },
        ],
      },
      {
        type: 'text-box',
        key: 'identifier',
        label: 'Order Number',
        placeholder: 'Order number of request',
      },
      {
        type: 'combobox-single',
        key: 'code',
        label: 'Provider',
        fetcher: [
          {
            value: DiagnosticReportCode.ampath,
            display: 'Ampath',
          },
        ],
      },
    ],
  },
];
