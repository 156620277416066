import { GroupSecurity } from '@quromedical/fhir-common';
import { Medication } from '@quromedical/models';
import { WithGroup } from 'core/auth';
import { includedRouteCodes } from 'core/medication';
import { Alert, Section } from 'design-system';
import { useRevalidation } from 'hooks/useRevalidation';
import { medicationFetcher, organizationFetcher } from 'integration';
import { MedicationRequestApi } from 'integration/aggregate';
import React, { useCallback } from 'react';
import { CreateMedicationRequestCard, MedicationRequestTable } from 'screens/medication';
import { strings } from 'strings';

interface PatientMedicationRequestTabProps {
  patientId: string;
  admissionId?: string;
}

const api = new MedicationRequestApi();

const assignableMedicationFetcher = medicationFetcher(includedRouteCodes);

export const PatientMedicationRequestTab: React.FC<PatientMedicationRequestTabProps> = ({
  patientId,
  admissionId,
}) => {
  const revalidate = useRevalidation(patientId);

  const onSubmit = useCallback(
    async (data: Medication.CreateMedicationRequest) => {
      if (!admissionId) {
        return;
      }
      await api.createMedicationRequest(patientId, admissionId, data);
      revalidate();
    },
    [patientId, admissionId, revalidate]
  );

  return (
    <>
      <WithGroup groups={[GroupSecurity.MedicationRequestCreate]}>
        <Section unsetZIndex isVisible={!!admissionId}>
          <CreateMedicationRequestCard
            onSubmit={onSubmit}
            medicationFetcher={assignableMedicationFetcher}
            organizationFetcher={organizationFetcher}
            title={strings.FormTitleCreateMedicationRequest}
          />
        </Section>
      </WithGroup>
      <WithGroup groups={[GroupSecurity.MedicationRequestRead]}>
        {admissionId ? (
          <Section>
            <MedicationRequestTable patientId={patientId} admissionId={admissionId} />
          </Section>
        ) : (
          <Section isVisible={!admissionId}>
            <Alert
              title={strings.ErrorCardTitle}
              body={strings.AlertMissingAdmissionID}
              backgroundColor="status-warning"
            />
          </Section>
        )}
      </WithGroup>
    </>
  );
};
