import { Button, ButtonProps } from 'design-system/base';
import React, { ReactElement, useCallback, useState } from 'react';

import { Alert, AlertProps } from '../alert';
import { Snackbar } from '../snackbar';

interface AlertButtonProps<TResult, TError = unknown> extends ButtonProps {
  /**
   * On Press should not catch any errors, since the component handler needs to know if there was an
   * error to display the appropriate alert
   *
   * If call is successful, will call `onSuccess` with the result, if not will call `onError` with
   * the error, use this if you need to do any additional handling based on the result of the call
   */
  onPress: (() => TResult) | (() => Promise<TResult>);
  onError?: (error: TError) => void;
  onSuccess?: (result: TResult) => void;
  error?: AlertProps;
  success?: AlertProps;
}

type State = 'initial' | 'error' | 'success' | 'loading';

export const AlertButton = <TResult, TError = unknown>({
  error,
  success,
  onPress,
  onSuccess,
  onError,
  ...rest
}: AlertButtonProps<TResult, TError>): ReactElement => {
  const [state, setState] = useState<State>('initial');

  const dismiss = useCallback(() => {
    setState('initial');
  }, []);

  const handlePress = useCallback(async () => {
    setState('loading');
    try {
      const result = await onPress();
      setState('success');
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (err) {
      setState('error');
      if (onError) {
        onError(err as TError);
      }
    }
  }, [onError, onPress, onSuccess]);

  const showError = state === 'error';
  const showSuccess = state === 'success';
  const isLoading = state === 'loading';

  return (
    <>
      <Button disabled={isLoading} {...rest} onPress={handlePress} />
      <Snackbar isOpen={showError} onClose={dismiss}>
        <Alert
          onAction={dismiss}
          actionIcon="close"
          backgroundColor="status-critical"
          textColor="white"
          {...error}
        />
      </Snackbar>
      <Snackbar isOpen={showSuccess} onClose={dismiss}>
        <Alert
          onAction={dismiss}
          actionIcon="close"
          backgroundColor="status-success"
          textColor="white"
          {...success}
        />
      </Snackbar>
    </>
  );
};
