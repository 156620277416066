import { Field, SelectOption } from 'components/types';
import { ToggleOption } from 'design-system';
import { strings } from 'strings';

export enum Sort {
  FamilyAsc = 'family',
  FamilyDesc = '-family',
  GivenAsc = 'given',
  GivenDesc = '-given',
  ActiveFirst = '-active',
  ActiveLast = 'active',
}

export const initialSearch: SearchParams = {
  active: true,
  name: '',
  sort: Sort.FamilyAsc,
};

const activeFilterOptions = async (): Promise<SelectOption<never, SearchParams['active']>[]> => [
  {
    display: strings.SortFilterAll,
    value: 'all',
  },
  {
    display: strings.SortFilterActive,
    value: true,
  },
  {
    display: strings.SortFilterDischarged,
    value: false,
  },
];

export const activeOptions: ToggleOption<boolean | 'all' | undefined>[] = [
  {
    display: strings.SortFilterActive,
    value: true,
  },
  {
    display: strings.SortFilterDischarged,
    value: false,
  },
  {
    display: strings.SortFilterAll,
    value: 'all',
  },
];

export interface SearchParams {
  sort?: Sort;
  active?: boolean | 'all';
  name?: string;
}

export const searchFields: Field<SearchParams>[] = [
  {
    subfields: [
      {
        key: 'active',
        label: strings.LabelStatus,
        type: 'combobox-single',
        fetcher: activeFilterOptions,
      },
    ],
  },
];
