import { MedicalAid, Patient } from '@quromedical/models';
import { getDisplayName } from 'core/person';
import {
  Alert,
  FormCard,
  Section,
  Skeleton,
  SkeletonProvider,
  Snackbar,
  Visible,
} from 'design-system';
import { logger } from 'helpers';
import { useBoolean } from 'hooks/useBoolean';
import { useFiniteState } from 'hooks/useFiniteState';
import { CoverageApi, organizationSchemeCodeFetcher } from 'integration';
import { PatientApi } from 'integration/aggregate';
import React, { useCallback, useState } from 'react';
import {
  MedicalAidUpdateForm,
  MedicalAidUpdateFormSearchResult,
  MedicalAidUpdatePatientSelection,
  MedicalAidUpdatePatientSelectionSearchResult,
} from 'screens/patient';
import { strings } from 'strings';

interface PatientMedicalAidUpdateTabProps {
  patient: Patient.GetPatientResponse;
  patientId: string;
  revalidate: () => void;
}

const IDNumberErrorCard: React.FC = () => (
  <FormCard
    cardColor="status-warning"
    rows={[
      {
        fields: [
          {
            type: 'text',
            label: strings.ErrorCardTitle,
            display: strings.ErrorTextPatientIdNumberRequired,
          },
        ],
      },
    ]}
  />
);

const isFullResult = (
  result: Partial<MedicalAidUpdatePatientSelectionSearchResult>
): result is MedicalAidUpdatePatientSelectionSearchResult =>
  Boolean(result.dependantCode && result.display);

const patientApi = new PatientApi();

const coverageApi = new CoverageApi();
const onSearch = (req: MedicalAid.MedicalAidValidationRequest) => coverageApi.familyCheck(req);

type State = 'form' | 'validation-error' | 'submission-error' | 'loading';

export const PatientMedicalAidUpdateTab: React.FC<PatientMedicalAidUpdateTabProps> = ({
  patient,
  patientId,
  revalidate,
}) => {
  const state = useFiniteState<State>('form');
  const alertOpen = useBoolean(false);

  const idNumber = patient.general.identifierValue;

  const [search, setSearch] = useState<MedicalAidUpdateFormSearchResult | undefined>();

  const onSubmit = useCallback(
    async (dependantCode: string) => {
      const foundPatient = search?.result.find(
        (p) => p.medicalAidMembership?.dependantCode === dependantCode
      );

      const schemeCode = foundPatient?.medicalAidMembership?.medicalAid?.schemeCode;
      const subscriberId = foundPatient?.medicalAidMembership?.medicalAid?.membershipNumber;

      if (!(schemeCode && subscriberId)) {
        state.set('validation-error');
        alertOpen.setTrue();
        return;
      }

      try {
        await patientApi.updateCoverage(patientId, {
          coverage: {
            dependantCode,
            schemeCode,
            subscriberId,
          },
        });

        revalidate();
      } catch (err) {
        state.set('submission-error');
        alertOpen.setTrue();
        logger.error(err);
      }
    },
    [alertOpen, patientId, revalidate, search?.result, state]
  );

  const closeAlert = useCallback(() => {
    alertOpen.setFalse();
    state.set('form');
  }, [alertOpen, state]);

  if (!idNumber) {
    return (
      <Section>
        <IDNumberErrorCard />
      </Section>
    );
  }

  const searchResults = search?.result
    // filter out inactive members in the list
    ?.filter((res) => res.medicalAidMembership?.isActive)
    .map<Partial<MedicalAidUpdatePatientSelectionSearchResult>>((res) => ({
      display: getDisplayName(res.general),
      dependantCode: res.medicalAidMembership?.dependantCode,
      idNumber: res.general?.identifierValue,
    }))
    .filter(isFullResult);

  const errorMessage = state.is('submission-error')
    ? strings.MedicalAidUpdateSubmissionError
    : strings.MedicalAidUpdateValidationError;

  const isErrorState = state.is('submission-error') || state.is('validation-error');

  return (
    <SkeletonProvider loading={state.is('loading')}>
      <Section unsetZIndex>
        <Skeleton>
          <MedicalAidUpdateForm
            schemeFetcher={organizationSchemeCodeFetcher}
            onSearch={onSearch}
            onResult={setSearch}
            onError={state.next('submission-error')}
            idNumber={idNumber}
          />
        </Skeleton>
      </Section>

      <Section isVisible={!!searchResults} unsetZIndex hasBottomMargin>
        <MedicalAidUpdatePatientSelection
          idNumber={idNumber}
          searchResults={searchResults}
          onSubmit={onSubmit}
        />
      </Section>

      <Visible if={isErrorState}>
        <Snackbar isOpen={alertOpen.value} onClose={closeAlert}>
          <Alert
            actionIcon="close"
            backgroundColor="status-critical"
            onAction={closeAlert}
            title={strings.AlertError}
            body={errorMessage}
          />
        </Snackbar>
      </Visible>
    </SkeletonProvider>
  );
};
