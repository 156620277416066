import styled from '@emotion/native';
import { Col, Row } from 'components/base';
import { MILLISECONDS } from 'constants/time';
import { Text, Icon } from 'design-system/base';
import { BASE_SIZE, MARGIN } from 'design-system/theme';
import { timeFormatter } from 'helpers';
import humanizeDuration from 'humanize-duration';
import React from 'react';
import { strings } from 'strings';

export interface SummaryProps {
  title: string;
  unit?: string;
  value?: string;
  isLarge?: boolean;
  time?: number;
  showTime?: boolean;
  fallbackValue?: string;
  fallbackTime?: string;
}

const UnitWrapper = styled.View({
  marginLeft: MARGIN['3xs'],
});

const TimeTextWrapper = styled.View({
  marginLeft: MARGIN['3xs'],
});

export const Summary: React.FC<SummaryProps> = ({
  value,
  title,
  unit,
  isLarge,
  time,
  showTime = false,
  fallbackValue = '--',
  fallbackTime = '--',
}) => {
  const valueVariant = isLarge ? 'page-heading' : 'body-strong';

  const isGreaterThanDay = time && time > MILLISECONDS.day;

  const humanizedTime =
    time &&
    humanizeDuration(Date.now() - time, {
      round: true,
      largest: 1,
    });

  const timeDisplay =
    humanizedTime && isGreaterThanDay
      ? `${humanizedTime} ${strings.DurationSuffixAgo}`
      : timeFormatter(time);

  return (
    <Col>
      <Text variant="caption">{title}</Text>

      <Row alignItems="baseline">
        <Text variant={valueVariant}>{value || fallbackValue}</Text>
        <UnitWrapper>
          <Text variant="caption">{unit}</Text>
        </UnitWrapper>
      </Row>

      <Row isVisible={showTime} alignItems="center">
        <Icon name="access-time" size={BASE_SIZE[12]} />
        <TimeTextWrapper>
          <Text>{timeDisplay || fallbackTime}</Text>
        </TimeTextWrapper>
      </Row>
    </Col>
  );
};
