import { useTheme } from '@emotion/react';
import { DrawerNavigationOptions } from '@react-navigation/drawer';
import { BASE_SIZE, FONT_FAMILY, FONT_SIZE } from 'design-system';
import { DrawerType, useDrawerType } from 'hooks/useDrawerType';
import { TextStyle, ViewStyle } from 'react-native';

const drawerLabelStyle: TextStyle = {
  fontFamily: FONT_FAMILY.regular,
  fontSize: FONT_SIZE.body,
};

const headerTitleStyle: TextStyle = {
  fontFamily: FONT_FAMILY.regular,
  fontSize: FONT_SIZE.heading,
};

export const hiddenDrawerItemStyle: ViewStyle = {
  display: 'none',
};

interface DrawerTypeProps {
  drawerType: DrawerType;
}

export const useCommonDrawerProps = (): DrawerNavigationOptions & DrawerTypeProps => {
  const theme = useTheme();
  const drawerType = useDrawerType();

  const backgroundColor = theme.color.base;

  const headerShown = drawerType !== 'permanent';

  return {
    drawerType,
    drawerLabelStyle: {
      ...drawerLabelStyle,
      color: theme.color['text-subdued'],
    },
    headerTitleStyle: {
      ...headerTitleStyle,
      color: theme.color['text-subdued'],
    },
    headerShown,
    headerTintColor: theme.color['text-default'],
    headerStyle: {
      backgroundColor,
      height: BASE_SIZE[48],
      borderBottomWidth: 0,
    },
    drawerStyle: {
      backgroundColor,
    },
    drawerItemStyle: {
      backgroundColor,
    },
  };
};
