import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { ChargeItemListScreen, ChargeItemScreen } from 'screens/charge-item';
import { strings } from 'strings';

import { GroupStackParamList } from './types';

const Stack = createNativeStackNavigator<GroupStackParamList>();

export const ChargeItemNavigator: React.FC = () => (
  <Stack.Navigator initialRouteName="List">
    <Stack.Screen
      name="List"
      component={ChargeItemListScreen}
      options={{
        title: strings.ChargeItemDefinitionListTitle,
        headerShown: false,
      }}
    />
    <Stack.Screen
      name="View"
      component={ChargeItemScreen}
      options={{ title: strings.ChargeItemDefinitionViewTitle }}
    />
  </Stack.Navigator>
);
