import axios from 'axios';

/**
 * We are taking an error object and returning the error message as a string if the error can't be parsed it will not return a message
 * @param err
 * @returns a string message
 */
export const parseError = (err?: unknown): string | undefined => {
  if (!err) {
    return undefined;
  }

  const isAxiosError = axios.isAxiosError(err);
  if (isAxiosError) {
    const responseData = err.response?.data;
    return responseData?.message?.toString() || responseData?.toString();
  }

  const error = err as Error;
  if (error.message) {
    return error?.message?.toString();
  }

  // Gets around issue of error being `unknown`
  return `${err}`;
};
