import { Col } from 'components/base';
import React from 'react';
import { ScrollView } from 'react-native';

import { SectionProps } from './types';

export const Main: React.FC<SectionProps> = ({ flex = 3, hidden = false, children }) => {
  if (hidden) {
    return null;
  }

  return (
    <Col flex={flex}>
      <ScrollView>{children}</ScrollView>
    </Col>
  );
};
