import styled from '@emotion/native';
import { ColorName, useTheme } from '@emotion/react';
import { Row } from 'components/base';
import * as Base from 'components/graph';
import { extent } from 'd3-array';
import { Visible, Text } from 'design-system/base';
import { fadeColor, PADDING } from 'design-system/theme';
import { integerFormatter } from 'helpers';
import React from 'react';

import { SplineActionProps, SplineActions } from './SplineActions';

export type Ticks = number | number[];
export type Domain = [number, number];

export interface SplineProps extends SplineActionProps {
  title?: string;
  color?: ColorName;
  gridColor?: ColorName;
  data?: Base.Datum[];
  xTicks?: Ticks;
  yTicks?: Ticks;
  xSubTicks?: Ticks;
  ySubTicks?: Ticks;
  aspectRatio?: number;
  xDomain?: Domain;
  yDomain?: Domain;
  showYValues?: boolean;
  showXValues?: boolean;
  yFormatter?: (value?: number) => string;
  xFormatter?: (value?: number) => string;
  initialWidth?: number;
  showGrid?: boolean;
  strokeWidth?: number;
  gridStrokeWidth?: number;
  overflow?: 'hidden' | 'visible';
  trackerColor?: ColorName;
  trackerDuration?: number;
  trackerValue?: number;
  onTrackerAnimationEnd?: () => void;
}

const TitleWrapper = styled(Row)({
  paddingVertical: PADDING.xs,
});

export const Spline: React.FC<SplineProps> = ({
  data = [],
  aspectRatio = 5 / 1,
  showGrid = false,
  xTicks = [],
  xSubTicks = [],
  yTicks = [],
  ySubTicks = [],
  showXValues = false,
  showYValues = false,
  xDomain = extent(data, (d) => d.x) as Domain,
  yDomain = extent(data, (d) => d.y) as Domain,
  color = 'text-default',
  gridColor = color,
  xFormatter = integerFormatter,
  yFormatter = integerFormatter,
  initialWidth,
  strokeWidth = 4,
  gridStrokeWidth = strokeWidth / 2,
  overflow = 'hidden',
  trackerColor = 'text-default',
  trackerDuration = 5000,
  trackerValue,
  isPlaying,
  next,
  onPlay,
  onStop,
  previous,
  onTrackerAnimationEnd,
  title,
}) => {
  const theme = useTheme();
  const textColor = theme.color['text-default'];

  const resolvedColor = theme.color[color];
  const resolvedGridColor = theme.color[gridColor];
  const resolvedTrackerColor = theme.color[trackerColor];

  const fadedGridColor = fadeColor(resolvedGridColor, 0.5);

  const plotMargin: Base.Margin = {
    right: showXValues ? 14 : 0,
    left: showXValues ? 36 : 0,
    top: showYValues ? 4 : 0,
    bottom: showYValues ? 20 : 0,
  };

  return (
    <>
      <Row alignItems="center" justifyContent="space-between">
        <Visible if={title}>
          <TitleWrapper>
            <Text variant="subheading">{title}</Text>
          </TitleWrapper>
        </Visible>

        <SplineActions
          isPlaying={isPlaying}
          next={next}
          onPlay={onPlay}
          onStop={onStop}
          previous={previous}
        />
      </Row>
      <Row flex={1}>
        <Base.LinearPlot
          baseWidth={initialWidth}
          xDomain={xDomain}
          yDomain={yDomain}
          margin={plotMargin}
          flex={1}
          data={data}
          aspectRatio={aspectRatio}
          overflow={overflow}
        >
          <Visible if={showGrid}>
            <Base.Grid
              stroke={fadedGridColor}
              grid
              xTicks={xSubTicks}
              yTicks={ySubTicks}
              strokeWidth={gridStrokeWidth}
            />
            <Base.Grid
              stroke={fadedGridColor}
              grid
              xTicks={xTicks}
              yTicks={yTicks}
              strokeWidth={gridStrokeWidth}
            />
          </Visible>

          <Visible if={isPlaying}>
            <Base.XTracker
              xVal={trackerValue}
              duration={trackerDuration}
              onEnd={onTrackerAnimationEnd}
              strokeWidth={strokeWidth}
              stroke={resolvedTrackerColor}
            />
          </Visible>

          <Base.PlotLine stroke={resolvedColor} strokeWidth={strokeWidth} />

          <Visible if={showXValues}>
            <Base.XAxis color={textColor} ticks={xTicks} formatter={xFormatter} />
          </Visible>

          <Visible if={showYValues}>
            <Base.YAxis color={textColor} ticks={yTicks} formatter={yFormatter} />
          </Visible>
        </Base.LinearPlot>
      </Row>
    </>
  );
};
