// import { AnalyticsBrowser } from '@segment/analytics-next';
// import { env } from 'config/env';
import { Analytics, SetUser, TrackEvent } from './types';

// const segment = AnalyticsBrowser.load({ writeKey: env.segmentWriteKey });

const track: TrackEvent = () => {
  // segment.track(name, data);
};

const setUser: SetUser = () => {
  // segment.identify(username);
};

export const analytics: Analytics = {
  setUser,
  track,
};
