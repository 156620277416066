import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Row } from 'components/base';
import { Spacer, Visible, Text, Icon, IconName } from 'design-system/base';
import { BASE_SIZE, BORDER_WIDTH, MARGIN, PADDING } from 'design-system/theme';
import React from 'react';

export interface HeaderMetric {
  name?: string;
  icon?: IconName;
  iconColor?: ColorName;
  value?: string | number;
  valueIcon?: IconName;
  valueUnit?: string;
  valueIconColor?: ColorName;
}

export interface HeaderProps {
  title?: string;
  icon?: IconName;
  metrics?: HeaderMetric[];
}
const IconWrapper = styled(Row)(({ theme }) => ({
  paddingTop: PADDING.xs,
  paddingHorizontal: PADDING['3xs'],
  paddingBottom: PADDING['3xs'],
  backgroundColor: theme.color['accent-green'],
  marginRight: MARGIN['2xs'],
}));

const TextWrapper = styled(Row)({
  paddingTop: PADDING.xs,
  alignItems: 'center',
});

const Wrapper = styled(Row)(({ theme }) => ({
  paddingHorizontal: PADDING.xs,
  paddingBottom: PADDING['2xs'],
  borderWidth: 0,
  borderBottomWidth: BORDER_WIDTH[1],
  borderBottomColor: theme.color.divider,
}));

export const Header: React.FC<HeaderProps> = ({ title = '', icon, metrics = [] }) => (
  <Wrapper alignItems="center" justifyContent="space-between">
    <Row>
      <IconWrapper isVisible={!!icon}>
        <Icon name="person-outline" size={BASE_SIZE[12]} color="text-on-color" />
      </IconWrapper>
      <TextWrapper>
        <Text variant="dashboard-title">{title}</Text>
      </TextWrapper>
    </Row>
    <TextWrapper>
      {metrics.map((metric, i) => (
        <Row key={`${metric.name}-${metric.value}-${i}`} alignItems="center">
          <Visible if={metric.icon}>
            <Icon name={metric.icon} size={BASE_SIZE[16]} color={metric.iconColor} />
          </Visible>
          <Visible if={metric.name}>
            <Text variant="dashboard-title">{metric.name}</Text>
          </Visible>
          <Visible if={metric.name && metric.value}>
            <Spacer width={BASE_SIZE[4]} />
          </Visible>
          <Visible if={metric.valueIcon}>
            <Icon color={metric.valueIconColor} name={metric.valueIcon} size={BASE_SIZE[16]} />
          </Visible>
          <Visible if={metric.value || metric.valueUnit}>
            <Text variant="dashboard-title">
              {metric.value}
              {metric.valueUnit}
            </Text>
          </Visible>
        </Row>
      ))}
    </TextWrapper>
  </Wrapper>
);
