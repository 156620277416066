import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Row } from 'components/base';
import { BASE_SIZE, BORDER_RADIUS, BORDER_WIDTH } from 'design-system/theme';
import React from 'react';
import { ViewStyle } from 'react-native';

import { pickVariant, VariantConfig } from '../generic';

/**
 * Props used for the purpose of individually controlling radii, e.g. for button groups
 */
export interface BorderRadiusProps {
  unsetTopLeftRadius?: boolean;
  unsetTopRightRadius?: boolean;
  unsetBottomLeftRadius?: boolean;
  unsetBottomRightRadius?: boolean;
}

interface BaseProps extends BorderRadiusProps {
  onPress: () => void;

  variant?: TypeVariant;
  shape?: ShapeVariant;
  size?: SizeVariant;
  disabled?: boolean;
}

export type TypeVariant = 'contained' | 'outlined' | 'flat' | 'flat-alt' | 'text';
export type ShapeVariant = 'round' | 'default';
export type SizeVariant = 'large' | 'full-width' | 'default';

type PressedVariant = 'pressed' | 'none';
type DisabledVariant = 'disabled' | 'enabled';

const shapeVariants: Record<ShapeVariant, ViewStyle> = {
  default: {
    borderRadius: BORDER_RADIUS[4],
  },

  round: {
    borderRadius: BORDER_RADIUS.full,
    // this is specific to override existing padding
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
};

const wrapperVariants: VariantConfig<TypeVariant, ViewStyle> = (theme) => ({
  // set borders for non-bordered variants to ensure size consistency
  contained: {
    backgroundColor: theme.color.primary,
    borderColor: theme.color.primary,
    borderStyle: 'solid',
    borderWidth: BORDER_WIDTH[1],
  },
  outlined: {
    borderColor: theme.color.primary,
    borderStyle: 'solid',
    borderWidth: BORDER_WIDTH[1],
  },
  flat: {
    backgroundColor: theme.color['base-grey'],
    borderColor: theme.color['base-grey'],
    borderStyle: 'solid',
    borderWidth: BORDER_WIDTH[1],
  },
  'flat-alt': {
    backgroundColor: theme.color.divider,
    borderColor: theme.color.divider,
    borderStyle: 'solid',
    borderWidth: BORDER_WIDTH[1],
  },
  text: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
});

const wrapperSizeVariants: Record<SizeVariant, ViewStyle> = {
  large: {
    height: BASE_SIZE[42],
    minWidth: BASE_SIZE[42],
    paddingHorizontal: BASE_SIZE[22],
  },
  'full-width': {
    height: BASE_SIZE[42],
    minWidth: BASE_SIZE[42],
  },
  default: {
    height: BASE_SIZE[32],
    minWidth: BASE_SIZE[32],
    paddingHorizontal: BASE_SIZE[16],
  },
};

const pressedVariants: VariantConfig<PressedVariant, ViewStyle, WrapperProps> = (theme, props) => {
  const isContained = props.variant === 'contained';
  const backgroundColor = isContained ? theme.color['primary-hover'] : theme.color['primary-10'];

  return {
    pressed: {
      backgroundColor,
    },
    none: {},
  };
};

const disabledVariants: VariantConfig<DisabledVariant, ViewStyle, WrapperProps> = (
  theme,
  props
) => {
  const isDisabled = props.disabled === 'disabled';
  const hasVisibleBorder = isDisabled && props.variant === 'outlined';
  const hasVisibleBackground = isDisabled && props.variant !== 'text';

  const borderColor = hasVisibleBorder ? theme.color['text-disabled'] : theme.color['base-grey'];
  const backgroundColor = hasVisibleBackground ? theme.color['base-grey'] : 'transparent';

  return {
    disabled: {
      backgroundColor,
      borderColor,
    },
    enabled: {},
  };
};

interface WrapperProps {
  variant: TypeVariant;
  size: SizeVariant;
  pressed: PressedVariant;
  disabled: DisabledVariant;
  shape: ShapeVariant;
}

const Wrapper = styled.View<WrapperProps & BorderRadiusProps>(
  ({ unsetBottomLeftRadius, unsetBottomRightRadius, unsetTopLeftRadius, unsetTopRightRadius }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: unsetTopRightRadius ? 0 : undefined,
    borderTopLeftRadius: unsetTopLeftRadius ? 0 : undefined,
    borderBottomRightRadius: unsetBottomRightRadius ? 0 : undefined,
    borderBottomLeftRadius: unsetBottomLeftRadius ? 0 : undefined,
  }),
  (props) => pickVariant(wrapperVariants, props.variant, props),
  (props) => pickVariant(wrapperSizeVariants, props.size, props),
  (props) => pickVariant(shapeVariants, props.shape, props),
  (props) => pickVariant(disabledVariants, props.disabled, props),
  (props) => pickVariant(pressedVariants, props.pressed, props)
);

interface StyledPressableProps {
  fullWidth?: boolean;
}

const StyledPressable = styled.Pressable<StyledPressableProps>(({ fullWidth }) => ({
  width: fullWidth ? '100%' : undefined,
}));

const enabledTextColorMap: Record<TypeVariant, ColorName> = {
  contained: 'white',
  flat: 'text-default',
  'flat-alt': 'text-default',
  outlined: 'primary',
  text: 'text-default',
};

const disabledTextColorMap: Record<TypeVariant, ColorName> = {
  contained: 'text-disabled',
  flat: 'text-disabled',
  'flat-alt': 'text-disabled',
  outlined: 'text-disabled',
  text: 'text-disabled',
};

export const getTextColor = (variant: TypeVariant, disabled: boolean): ColorName => {
  if (disabled) {
    return disabledTextColorMap[variant];
  }

  return enabledTextColorMap[variant];
};

export const BaseButton: React.FC<BaseProps> = ({
  variant = 'contained',
  shape = 'default',
  size = 'default',
  disabled = false,
  children,
  unsetBottomLeftRadius,
  unsetBottomRightRadius,
  unsetTopLeftRadius,
  unsetTopRightRadius,
  onPress,
}) => {
  const disabledVariant: DisabledVariant = disabled ? 'disabled' : 'enabled';
  const fullWidth = size === 'full-width';

  return (
    <Row alignItems="flex-start" fullWidth={fullWidth}>
      <StyledPressable onPress={onPress} disabled={disabled} fullWidth={fullWidth}>
        {({ pressed }) => {
          const pressedVariant: PressedVariant = pressed ? 'pressed' : 'none';

          return (
            <Wrapper
              variant={variant}
              shape={shape}
              size={size}
              pressed={pressedVariant}
              disabled={disabledVariant}
              unsetBottomRightRadius={unsetBottomRightRadius}
              unsetBottomLeftRadius={unsetBottomLeftRadius}
              unsetTopLeftRadius={unsetTopLeftRadius}
              unsetTopRightRadius={unsetTopRightRadius}
            >
              {children}
            </Wrapper>
          );
        }}
      </StyledPressable>
    </Row>
  );
};
