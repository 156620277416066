import { FormCard, RowConfig } from 'design-system';
import { compact } from 'lodash';
import React from 'react';
import { strings } from 'strings';

interface CoverageStatusCardProps {
  hasActiveAdmission: boolean;
  hasCoverage: boolean;
  hasAuthorization: boolean;
}

export const CoverageStatusCard: React.FC<CoverageStatusCardProps> = ({
  hasActiveAdmission,
  hasCoverage,
  hasAuthorization,
}) => {
  const admissionWarning = hasActiveAdmission ? undefined : strings.WarningNoActiveAdmission;
  const coverageWarning = hasCoverage ? undefined : strings.WarningNoMedicalAidDetails;
  const authorizationWarning = hasAuthorization ? undefined : strings.WarningNoAuthorization;
  const warnings = compact([admissionWarning, coverageWarning, authorizationWarning]);

  const messageRow: RowConfig[] = [
    {
      icon: 'warning',
      fields: [
        {
          type: 'list',
          label: strings.PathologyWarningCardLabel,
          display: warnings,
        },
      ],
    },
  ];

  return (
    <FormCard
      cardColor="status-warning"
      showIcons={true}
      title={strings.LabelStatus}
      rows={messageRow}
    />
  );
};
