import { Validations } from '@quromedical/models';
import { getRelayStatusIssues } from 'core/device';
import { Field, RowConfig } from 'design-system';
import { Notification, RelayStatusField, RelayStatus as Status } from 'integration/RelayStatusApi';
import compact from 'lodash.compact';
import { strings } from 'strings';

const sortNotifications = (notificationA: Notification, notificationB: Notification) =>
  notificationB.timestamp - notificationA.timestamp;

const getMBSStatus = (field?: RelayStatusField<string>): string => {
  const mbsStatus = field?.value;
  if (!mbsStatus) {
    return '';
  }

  const isMonitoring = /monitoring/i.test(mbsStatus);

  return isMonitoring
    ? `Patch is connected, last MBS status: ${mbsStatus}`
    : `Patch is not connected, restart device. Last MBS status: ${mbsStatus}`;
};

export const createIssueRows = (data: Status = {}): RowConfig[] | undefined => {
  const issues = getRelayStatusIssues(data);

  if (!issues?.hasAny) {
    return undefined;
  }

  const relayIssues = compact([
    issues?.lowBattery && strings.RelayWarningLowBattery,
    issues?.lowStorage && strings.RelayWarningLowStorage,
    issues?.noBluetooth && strings.RelayWarningNoBluetoothDevices,
    issues?.poorNetwork && strings.RelayWarningBadNetwork,
  ]);

  const relayField: Field = relayIssues?.length
    ? {
        type: 'list',
        label: strings.RelayIssuesTitle,
        display: relayIssues,
      }
    : {
        type: 'text',
        label: strings.RelayIssuesTitle,
        display: strings.RelayIssueTextNoIssues,
      };

  const issueFields: RowConfig[] = [
    {
      icon: 'warning',
      fields: [
        {
          type: 'text',
          label: strings.LabelMBSAppStatus,
          display: getMBSStatus(data.mbsAppStatus),
        },
        relayField,
      ],
    },
  ];
  return issueFields;
};

export const createNotificationRows = (data: Status = {}): RowConfig[] => {
  const { activeNotifications } = data;

  const sortedNotifications = activeNotifications?.value.sort(sortNotifications) || [];

  return sortedNotifications.map<RowConfig>((n) => {
    const time = new Date(n.timestamp).toLocaleString();
    const label = `${time}: ${n.title}`;

    return {
      fields: [
        {
          type: 'text',
          label,
          display: n.content,
        },
      ],
    };
  });
};

export const createRelayStatusRows = (data: Status = {}): RowConfig[] => {
  const networkText = ['Very poor', 'Poor', 'Moderate', 'Good', 'Very good'];
  const val = (field?: RelayStatusField<number | string | string[]>) => field?.value.toString();

  const isPatchConnected = (field?: RelayStatusField<string[]>) => {
    const patch = field?.value.find((value) => Validations.patchIdRegex.test(value));
    return patch;
  };

  const { timestamp, uptimeMillis, ...rest } = data;
  const relayId = val(rest.relayId);
  const bluetoothConnections = val(rest.bluetoothGattConnectedDevices) || 'None';

  const mbsAppStatus = isPatchConnected(rest.bluetoothGattConnectedDevices)
    ? val(rest.mbsAppStatus)
    : getMBSStatus(rest.mbsAppStatus);

  const networkQuality = `${networkText[rest.cellularNetworkLevel?.value || 0]} (Level ${
    rest.cellularNetworkLevel?.value || ''
  }, ${rest.cellularNetworkType?.value || ''})`;
  const availableStorage = val(rest.availableStorageMB);
  const relayAppVersion = val(rest.relayAppVersion);
  const phoneNumber = val(rest.phoneNumber);
  const mbsAppVersion = val(rest.mbsAppVersion);

  const hasChargingData = !!rest.batteryIsCharging;
  const isBatteryCharging = rest.batteryIsCharging?.value === true ? 'Charging' : 'Not Charging';
  const chargingMessage = hasChargingData ? isBatteryCharging : 'Unknown';

  const batteryPercentage = `${val(rest.batteryPercent) || ''}% (${chargingMessage})`;

  const updatedDate = timestamp ? new Date(timestamp).toLocaleString() : '';
  const rebootDate =
    timestamp && uptimeMillis?.value
      ? new Date(timestamp - uptimeMillis.value).toLocaleString()
      : '';

  return [
    {
      fields: [
        {
          type: 'text',
          label: strings.LabelLastUpdated,
          display: updatedDate,
        },
        {
          type: 'text',
          label: strings.LabelPhoneBattery,
          display: batteryPercentage,
        },
      ],
    },
    {
      fields: [
        {
          type: 'text',
          label: strings.LabelAvailableStorage,
          display: availableStorage,
        },
        {
          type: 'text',
          label: strings.LabelRelayID,
          display: relayId,
        },
      ],
    },
    {
      fields: [
        {
          type: 'text',
          label: strings.LabelBluetoothConnections,
          display: bluetoothConnections,
        },
        {
          type: 'text',
          label: strings.LabelPhoneNumber,
          display: phoneNumber,
        },
      ],
    },
    {
      fields: [
        {
          type: 'text',
          label: strings.LabelMBSAppStatus,
          display: mbsAppStatus,
        },
        {
          type: 'text',
          label: strings.LabelRelayAppVersion,
          display: relayAppVersion,
        },
      ],
    },
    {
      fields: [
        {
          type: 'text',
          label: strings.LabelMBSAppVersion,
          display: mbsAppVersion,
        },
        {
          type: 'text',
          label: strings.LabelNetworkQuality,
          display: networkQuality,
        },
      ],
    },
    {
      fields: [
        {
          type: 'text',
          label: strings.LabelLastReboot,
          display: rebootDate,
        },
      ],
    },
  ];
};
