import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { BORDER_WIDTH, SPACING, BorderWidth, Spacing } from 'design-system/theme';
import { View } from 'react-native';

interface DividerProps {
  orientation: 'horizontal' | 'vertical';
  color?: ColorName;
  width?: BorderWidth;
  spacing?: Spacing;
}

export const Divider = styled(View)<DividerProps>(
  ({ width: thickness = 1, color = 'divider', orientation, theme, spacing = 'none' }) => {
    const borderWidth = BORDER_WIDTH[thickness];
    const marginWidth = SPACING[spacing];
    const backgroundColor = theme.color[color];

    if (orientation === 'vertical') {
      return {
        width: borderWidth,
        marginLeft: marginWidth,
        marginRight: marginWidth,
        backgroundColor,
        alignSelf: 'stretch',
      };
    }

    return {
      height: borderWidth,
      marginBottom: marginWidth,
      marginTop: marginWidth,
      backgroundColor,
      alignSelf: 'stretch',
    };
  }
);
