import { Claim, WithId } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { LinkButton } from 'components/input';
import { ColumnType } from 'components/table';
import { Field, SelectOption } from 'components/types';
import { claimTypeDisplay } from 'core/display';
import { PaginatedTable } from 'core/table';
import { Section, Text } from 'design-system';
import { ClaimApi } from 'integration/aggregate';
import { GroupStackScreenProps } from 'navigation';
import React, { useCallback, useState } from 'react';
import { ScrollView } from 'react-native';
import { strings } from 'strings';

const api = new ClaimApi();

const columns: ColumnType<WithId<Claim.Claim>>[] = [
  {
    accessor: 'patient',
    header: strings.LabelNameAndSurname,
    Cell: ({ value }) => <Text>{value.display || strings.DisplayNotFound}</Text>,
  } as ColumnType<Claim.Claim, 'patient'>,
  {
    accessor: 'type',
    header: strings.AdmissionServiceLabel,
    Cell: ({ value }) => <Text>{claimTypeDisplay[value] || value}</Text>,
  } as ColumnType<WithId<Claim.Claim>, 'type'>,
  {
    accessor: 'status',
    header: strings.LabelStatus,
  },
  {
    accessor: 'id',
    header: '',
    Cell: ({ value }) => (
      <LinkButton
        text={strings.ClaimViewTitle}
        to={{
          screen: 'Admin',
          params: {
            screen: 'Claim',
            params: {
              screen: 'View',
              params: {
                id: value,
              },
            },
          },
        }}
      />
    ),
  } as ColumnType<Claim.GetResponse, 'id'>,
];

type SearchParams = Omit<Claim.GetAllParams, 'pageToken' | 'count'>;

const statuses: Claim.ClaimStatus[] = ['active', 'draft', 'cancelled', 'entered-in-error'];

const statusOptions: SelectOption<never, Claim.ClaimStatus>[] = statuses.map((status) => ({
  value: status,
  display: status,
}));

const searchFields: Field<SearchParams>[] = [
  {
    subfields: [
      {
        label: strings.LabelStatus,
        key: 'status',
        type: 'combobox-single',
        fetcher: statusOptions,
      },
    ],
  },
];

const getDataFromResponse = (res: Claim.GetAllResponse) => res.claims;

export const ClaimListScreen: React.FC<GroupStackScreenProps<'List'>> = () => {
  const [search, setSearch] = useState<SearchParams>({});

  const fetcher = useCallback(
    (pageToken?: string, count?: number) => api.getAll({ ...search, pageToken, count }),
    [search]
  );
  const baseSWRKey = `claim-list-${search.status}`;

  return (
    <ScrollView>
      <Section unsetZIndex>
        <CrudForm fields={searchFields} initialValues={{}} onChange={setSearch} />
      </Section>
      <Section>
        <PaginatedTable
          cacheKey={baseSWRKey}
          fetcher={fetcher}
          getDataFromResponse={getDataFromResponse}
          columns={columns}
          canChangeCount
        />
      </Section>
    </ScrollView>
  );
};
