import { ScaleLinear } from 'd3-scale';

/**
 * Resolve a dimension by applying relevant scaling and offsetting for numeric values - for string
 * values (e.g. "50%") the offset will not be applied
 */
export const resolveScaledValue = (
  scale: ScaleLinear<number, number>,
  value: string | number,
  offset: number
): string | number => {
  if (typeof value !== 'number') {
    return value;
  }

  return scale(value) + offset;
};
