import React from 'react';
import { Line } from 'react-native-svg';

import { usePlotDataContext } from './PlotDataProvider';
import { ExternalComponentProps } from './types';

interface XLineProps {
  xVal: number;
  opacity?: number;
  stroke?: string;
  strokeWidth?: number;
  strokeDasharray?: number | number[];
}

type ExternalProps = ExternalComponentProps & XLineProps;

export const XLine: React.FC<ExternalProps> = (props) => {
  const {
    xVal,
    xScale,
    baseHeight,
    margin,
    opacity = 1,
    strokeWidth = 1,
    stroke = 'lightgrey',
    strokeDasharray,
  } = usePlotDataContext(props);

  const x = xScale(xVal);

  return (
    <Line
      y1={margin.top}
      y2={baseHeight - margin.bottom}
      x1={x}
      x2={x}
      opacity={opacity}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeDasharray={strokeDasharray}
    />
  );
};
