import styled from '@emotion/native';
import { Row, Col } from 'components/base';
import { Button, Visible, Icon, Spacer, Text } from 'design-system/base';
import { MARGIN } from 'design-system/theme';
import React from 'react';

import { ConnectionQuality } from './ConnectionQuality';
import { VideoCol } from './VideoCol';

const ControlButtonWrapper = styled(Row)({
  position: 'absolute',
  bottom: MARGIN['2xs'],
});

const MinimizeButtonWrapper = styled(Row)({
  position: 'absolute',
  top: MARGIN['2xs'],
  right: MARGIN['2xs'],
});

const MuteIconWrapper = styled(Row)({
  position: 'absolute',
  right: MARGIN['2xs'],
  top: MARGIN['2xs'],
});

const ConnectionQualityWrapper = styled(Row)({
  position: 'absolute',
  left: MARGIN['2xs'],
  top: MARGIN['2xs'],
});

export interface VideoTileProps {
  displayName?: string;
  isMicEnabled: boolean;
  localAudioControl?: boolean;
  isVideoEnabled: boolean;
  localVideoControl?: boolean;
  isMinimized: boolean;
  signalLevel?: number;
  volume?: number;
  showMuteIcon?: boolean;
  showLeaveButton?: boolean;
  leaveMeeting?: () => void;
  toggleMinimized?: () => void;
  toggleVideoEnabled?: () => void;
  toggleMicEnabled?: () => void;
}

export const VideoTile: React.FC<VideoTileProps> = ({
  children,
  displayName = '',
  isMinimized,
  localAudioControl,
  isMicEnabled,
  isVideoEnabled,
  localVideoControl,
  signalLevel,
  volume,
  showMuteIcon = false,
  showLeaveButton = false,
  toggleVideoEnabled,
  toggleMicEnabled,
  toggleMinimized,
  leaveMeeting,
}) => {
  const videoButtonIcon = localVideoControl ? 'videocam' : 'videocam-off';
  const videoButtonVariant = localVideoControl ? 'contained' : 'outlined';

  const micButtonIcon = localAudioControl ? 'mic' : 'mic-off';
  const micButtonVariant = localAudioControl ? 'contained' : 'outlined';

  const muteButtonIcon = isMicEnabled ? 'mic' : 'mic-off';
  const minimizedButtonIcon = isMinimized ? 'expand' : 'collapse';

  return (
    <Row justifyContent="center">
      <Col flex={1}>
        <Row justifyContent="center" flex={1}>
          <VideoCol volume={volume} isVideoEnabled={isVideoEnabled} displayName={displayName}>
            {children}
          </VideoCol>
          <MinimizeButtonWrapper>
            {toggleMinimized ? (
              <Button
                icon={minimizedButtonIcon}
                variant="contained"
                shape="round"
                onPress={toggleMinimized}
              />
            ) : null}
          </MinimizeButtonWrapper>

          <ControlButtonWrapper>
            {toggleVideoEnabled ? (
              <>
                <Button
                  icon={videoButtonIcon}
                  variant={videoButtonVariant}
                  shape="round"
                  onPress={toggleVideoEnabled}
                />

                <Spacer width={MARGIN.xs} />
              </>
            ) : null}

            {toggleMicEnabled ? (
              <>
                <Button
                  icon={micButtonIcon}
                  variant={micButtonVariant}
                  shape="round"
                  onPress={toggleMicEnabled}
                />
                <Spacer width={MARGIN.xs} />
              </>
            ) : null}
            <Visible if={showLeaveButton}>
              {leaveMeeting ? (
                <Button icon="call-end" variant="outlined" shape="round" onPress={leaveMeeting} />
              ) : null}
            </Visible>
          </ControlButtonWrapper>

          <MuteIconWrapper isVisible={showMuteIcon}>
            <Icon name={muteButtonIcon} color="primary" />
          </MuteIconWrapper>

          <ConnectionQualityWrapper>
            <ConnectionQuality signalLevel={signalLevel} />
          </ConnectionQualityWrapper>
        </Row>

        <Spacer height={MARGIN.s} />

        <Visible if={!isMinimized}>
          <Row justifyContent="center">
            <Text variant="body" color="text-default">
              {displayName}
            </Text>
          </Row>
        </Visible>
      </Col>
    </Row>
  );
};
