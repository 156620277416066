import { MedicalAid, Person } from '@quromedical/models';
import { Button, Section } from 'design-system';
import { FormikErrors } from 'formik';
import { getAddressPartialFetcher, googlePlaceFetcher } from 'integration';
import React, { useState, useCallback } from 'react';
import { JointFields } from 'screens/patient';
import { strings } from 'strings';

import { NextOfKinForm } from '../forms';
import { NextOfKinSelectionCard } from './NextOfKinSelectionCard';

export type PatientStepData = {
  results: MedicalAid.MedicalAidValidationResponse;
  patient: MedicalAid.MedicalAidPerson;
  onSubmit: (nextOfKin: JointFields) => void;
};

/**
 * Get options that are allowed as next-of-kin
 */
const getNextOfKinOptions = (
  results: MedicalAid.MedicalAidValidationResponse,
  patient: Partial<MedicalAid.MedicalAidPerson>
): Partial<MedicalAid.MedicalAidPerson>[] =>
  results
    // only members that are eligible for next of kin
    .filter((result) => result.medicalAidMembership?.isEligibleAsNextOfKin)
    // remove the input patient from the list
    .filter(
      (result) =>
        result.medicalAidMembership?.dependantCode !== patient.medicalAidMembership?.dependantCode
    );

export const NextOfKinStep: React.FC<PatientStepData> = ({ results, onSubmit, patient }) => {
  const [submitted, setSubmitted] = useState(false);
  const [nextOfKinError, setNextOfKinError] = useState(false);
  const [selected, setSelected] = useState<Partial<MedicalAid.MedicalAidPerson>>();
  const [nextOfKin, setNexOfKin] = useState<Partial<JointFields>>();

  const options = getNextOfKinOptions(results, patient);

  const onSelectionChange = useCallback((result?: Partial<MedicalAid.MedicalAidPerson>) => {
    setSelected(result);
  }, []);

  const onNextOfKinChange = useCallback(
    (data: Partial<JointFields>, err: FormikErrors<Partial<Person.Contact>>) => {
      const hasErrors = !!Object.keys(err).length;

      setNexOfKin(data);
      setNextOfKinError(hasErrors);
    },
    []
  );

  const showErrors = submitted && nextOfKinError;

  const handleSubmit = useCallback(() => {
    const merged: Partial<JointFields> = {
      ...nextOfKin,
    };
    setSubmitted(true);
    if (!nextOfKinError) {
      onSubmit(merged as JointFields);
    }
  }, [nextOfKin, nextOfKinError, onSubmit]);

  const initialFormData = {
    ...(selected?.contact || {}),
    ...(selected?.general || {}),
  };

  return (
    <>
      <Section>
        <NextOfKinSelectionCard options={options} onSelect={onSelectionChange} />
      </Section>

      <Section>
        <NextOfKinForm
          key={initialFormData.identifierCode}
          initial={initialFormData}
          showErrors={showErrors}
          onChange={onNextOfKinChange}
          addressFetcher={googlePlaceFetcher}
          getAddressPartial={getAddressPartialFetcher}
        />
      </Section>

      <Section alignItems="flex-end" hasBottomMargin>
        <Button onPress={handleSubmit} text={strings.ButtonTextSubmit} />
      </Section>
    </>
  );
};
