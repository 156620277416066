import { FONT_FAMILY, FONT_SIZE } from 'design-system';
import React from 'react';
import { G, Text } from 'react-native-svg';

import { usePlotDataContext } from './PlotDataProvider';
import { ExternalComponentProps } from './types';

interface YAxisProps {
  ticks?: number | number[];
  color?: string;
  formatter?: (y: number) => string;
}

type ExternalProps = ExternalComponentProps & YAxisProps;

const defaultFormatter = (n: number) => n.toString();

export const YAxis: React.FC<ExternalProps> = (props) => {
  const {
    ticks = 10,
    yScale,
    formatter = defaultFormatter,
    color = 'lightgrey',
  } = usePlotDataContext(props);

  const tickMarks = typeof ticks === 'number' ? yScale.ticks(ticks) : ticks;

  return (
    <G>
      {tickMarks.map((t) => (
        <G key={t.toString()}>
          <Text
            fontFamily={FONT_FAMILY.regular}
            fontSize={FONT_SIZE.captionSmall}
            pointerEvents="none"
            alignmentBaseline="central"
            fill={color}
            textAnchor="start"
            y={yScale(t)}
            x={0}
          >
            {formatter(t)}
          </Text>
        </G>
      ))}
    </G>
  );
};
