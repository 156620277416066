import styled from '@emotion/native';
import { Col, Row } from 'components/base';
import { FileResult } from 'components/types';
import { Divider, Spacer, Text, Button } from 'design-system/base';
import { MARGIN } from 'design-system/theme';
import { useIsSmallerThan } from 'hooks/useResponsive';
import React, { useCallback, useState } from 'react';
import { ScrollView } from 'react-native';
import { strings } from 'strings';

import { Dialog } from '../dialog';
import { FileRow } from '../input';
import { FileUploadControl } from './file-upload-control';
import { FileUploadItem, Progress } from './file-upload-item';

export type FileKeyProgressMap = Record<string, Progress | undefined>;

export interface FilePickerDialogProps {
  progress?: FileKeyProgressMap;
  value?: FileResult[];
  isDisabled?: boolean;
  label?: string;
  buttonText?: string;
  uploadInProgress?: boolean;
  onChange: (value: FileResult[]) => void;
  errors?: string;
}
interface SizeProps {
  isSmall: boolean;
}

const ChangeDirectionViewWrapper = styled.View<SizeProps>(({ isSmall }) => ({
  flexDirection: isSmall ? 'column' : 'row',
}));

const Footer = styled(Row)(() => ({
  justifyContent: 'flex-end',
  alignSelf: 'flex-end',
}));

const FileUploadItemWrapper = styled(Col)<SizeProps>(({ isSmall }) => ({
  aspectRatio: isSmall ? 2 : 1,
}));

const ErrorRow = styled(Row)({
  marginTop: MARGIN['2xs'],
});

const progressPlaceholder: Progress = {
  status: 'loading',
  value: 0,
  total: 0,
};

export const FilePickerDialog: React.FC<FilePickerDialogProps> = ({
  value = [],
  onChange,
  buttonText = 'Add attachment',
  isDisabled,
  progress = {},
  uploadInProgress = false,
  errors,
}) => {
  const isSmall = useIsSmallerThan('sm');

  const [showFileDialog, setShowFileDialog] = useState(false);

  const onshowFileDialog = useCallback(() => {
    setShowFileDialog(true);
    onChange(value);
  }, [onChange, value]);

  const onCloseDialog = useCallback(() => {
    setShowFileDialog(false);
    onChange(value);
  }, [onChange, value]);

  const handleDelete = useCallback(
    (key: string) => {
      const filteredValue = value.filter((file) => file.key !== key);

      onChange(filteredValue);
    },
    [value, onChange]
  );

  const handleCancel = useCallback(() => {
    onChange([]);
    setShowFileDialog(false);
  }, [onChange]);

  const handleTitleChange = useCallback(
    (index: number) => (title: string) => {
      const currentFile = value[index];
      const updatedFile = {
        ...currentFile,
        name: title,
      };

      const newValue = [...value];
      newValue[index] = updatedFile;

      onChange(newValue);
    },
    [onChange, value]
  );

  const onSelect = (files: FileResult[]) => {
    const result = [...value, ...files];

    onChange(result);
  };

  // this is set conditionally to ensure that the native layout is correct
  const horizontalFillFlex = isSmall ? undefined : 1;

  return (
    <>
      <Dialog isOpen={showFileDialog} title={strings.FileUploadingLabelText}>
        <ChangeDirectionViewWrapper isSmall={isSmall}>
          <Col flex={horizontalFillFlex}>
            <FileUploadControl onSelect={onSelect} isPlacedVertically={isSmall} />
          </Col>

          <Spacer width={MARGIN.xs} />

          <Col flex={horizontalFillFlex}>
            <FileUploadItemWrapper isSmall={isSmall}>
              <ScrollView>
                {value.map((file) => {
                  const { key } = file;

                  const fileProgress = progress[key] || progressPlaceholder;

                  return (
                    <Col key={key}>
                      <FileUploadItem
                        onCancel={() => handleDelete(key)}
                        progress={fileProgress}
                        title={file.name}
                      />
                      <Divider orientation="horizontal" />
                    </Col>
                  );
                })}
              </ScrollView>
            </FileUploadItemWrapper>
          </Col>
        </ChangeDirectionViewWrapper>
        <Col>
          <Spacer height={MARGIN.xs} />
          <Footer fullWidth={true}>
            <Button
              textColor="primary"
              text={strings.ButtonTextCancel}
              variant="text"
              onPress={handleCancel}
            />
            <Spacer width={MARGIN.xs} />
            <Button text="Done" onPress={onCloseDialog} disabled={uploadInProgress} />
          </Footer>
        </Col>
      </Dialog>

      <Row flex={1}>
        <Col flex={1}>
          <Row flex={1}>
            <Col flex={1}>
              {value.map((file, index) => (
                <Row key={file.key} fullWidth flex={1}>
                  <FileRow
                    title={file.name}
                    onDelete={() => handleDelete(file.key)}
                    onTitleChange={handleTitleChange(index)}
                  />
                </Row>
              ))}
            </Col>
          </Row>

          <Row>
            <Button
              onPress={onshowFileDialog}
              disabled={isDisabled}
              text={buttonText}
              variant="flat-alt"
              icon="attach-file"
            />
          </Row>
          <ErrorRow isVisible={!!errors}>
            <Text variant="caption" color="status-critical">
              {errors}
            </Text>
          </ErrorRow>
        </Col>
      </Row>
    </>
  );
};
