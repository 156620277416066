import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import {
  BASE_SIZE,
  BORDER_RADIUS,
  BORDER_WIDTH,
  FONT_FAMILY,
  FONT_SIZE,
  lightenColor,
  MARGIN,
  PADDING,
} from 'design-system/theme';

interface PillInputProps {
  textColor: ColorName;
  backgroundColor: ColorName;
}

export const PillInput = styled.TextInput<PillInputProps>(
  ({ theme, textColor, backgroundColor }) => ({
    width: BASE_SIZE[200],
    color: theme.color[textColor],
    backgroundColor: lightenColor(theme.color[backgroundColor], 0.5),
    borderColor: lightenColor(theme.color[backgroundColor], -0.3),
    borderStyle: 'solid',
    borderWidth: BORDER_WIDTH[1],
    padding: PADDING['3xs'],
    fontFamily: FONT_FAMILY.regular,
    fontSize: FONT_SIZE.caption,
    borderRadius: BORDER_RADIUS[4],
    marginTop: MARGIN['3xs'],
    marginBottom: MARGIN['3xs'],
  })
);
