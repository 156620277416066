import { MedicalAid } from '@quromedical/models';

import { FhirBaseApi } from './FhirBaseApi';

export interface Params {
  count?: number;
  pageToken?: string;
  status?: string;
  patient?: string;
  subscriber?: string;
  payor?: string;
}

export class CoverageApi extends FhirBaseApi<fhir4.Coverage> {
  constructor() {
    super('api/fhir/coverage');
  }

  familyCheck = async (
    request: MedicalAid.MedicalAidValidationRequest
  ): Promise<MedicalAid.MedicalAidValidationResponse> => {
    const path = this.getPath('family-check');
    const result = await this.client.post<MedicalAid.MedicalAidValidationResponse>(path, request);

    return result.data;
  };
}
