import { Row } from 'components/base';
import { Button, Spacer } from 'design-system/base';
import { MARGIN } from 'design-system/theme';
import { useMeeting } from 'providers/video';
import React, { useCallback } from 'react';
import { strings } from 'strings';

import { CallWrapper } from '../call-wrapper';

interface WaitingRoomProps {
  title?: string;
  leaveMeeting: () => void;
}

export const WaitingRoom: React.FC<WaitingRoomProps> = ({ children, leaveMeeting, title = '' }) => {
  const meeting = useMeeting();

  const handleJoinMeeting = useCallback(() => {
    meeting.joinMeeting();
  }, [meeting]);

  return (
    <CallWrapper title={title} onButtonPress={leaveMeeting} buttonIcon="call-end">
      {children}
      <Spacer height={MARGIN.xs} />
      <Row justifyContent="center">
        <Button
          onPress={handleJoinMeeting}
          icon="phone-enabled"
          iconPosition="right"
          text={strings.ButtonTextJoinMeeting}
        />
      </Row>
    </CallWrapper>
  );
};
