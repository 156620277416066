import { Vitals, Websocket, BaseTimeSeriesRecord } from '@quromedical/models';
import { limit } from '@quromedical/utils';
import { env } from 'config/env';
import { isHighQuality, removeStaleMeasurementsForVitalsPayload } from 'core/vitals';
import { descending, sort } from 'd3-array';
import { hoursToMilliseconds } from 'date-fns';
import { logger } from 'helpers';
import {
  useActiveAssignedAdmissions,
  useActiveAssignedAdmissionVitals,
} from 'hooks/useActiveAssignedAdmissions';
import { useAsyncStorageData } from 'hooks/useAsyncStorageData';
import { useUserSession } from 'hooks/useUserSession';
import { SendMessage, useWebsocket } from 'hooks/useWebsocket';
import React, { useContext, useEffect, useState, createContext, useCallback } from 'react';

import { mergeLatestMeasuresByTime } from './helpers';

interface Cache {
  vitals: Vitals.LiveDataPayload;
  alerts: PatientAlerts;
  updated: number;
}

const LiveDataContext = createContext<Cache>({
  vitals: {},
  alerts: {},
  updated: 0,
});

const mergeVitalsData = (
  oldData: Vitals.LiveDataPayload = {},
  newData: Vitals.LiveDataPayload = {}
) => {
  const patientIds = [...Object.keys(oldData), ...Object.keys(newData)];

  return patientIds.reduce((acc, patientId) => {
    const latest = mergeLatestMeasuresByTime(
      oldData[patientId]?.latest,
      newData[patientId]?.latest
    );

    // fully overwrite ECG data if we have any new data
    const newEcg: BaseTimeSeriesRecord[] = newData[patientId]?.ecg || [];
    const oldEcg: BaseTimeSeriesRecord[] = oldData[patientId]?.ecg || [];

    const ecg = sort([...newEcg, ...oldEcg], (a, b) => descending(a.ts, b.ts)).slice(0, 1250);

    return {
      ...acc,
      [patientId]: {
        latest,
        ecg,
      },
    } as Vitals.LiveDataPayload;
  }, {} as Vitals.LiveDataPayload);
};

const mergeAlertData = (
  message: Websocket.PatientAlerts,
  oldData: PatientAlerts = {}
): PatientAlerts => {
  const patientIds = [...Object.keys(oldData), ...Object.keys(message)];

  return patientIds.reduce<PatientAlerts>((acc, patientId) => {
    const oldAlerts = oldData[patientId] || [];
    const newAlerts = message[patientId] || [];

    const mergedAlerts = sort([...oldAlerts, ...newAlerts], (a, b) => descending(a.ts, b.ts))
      // limit to 5 alerts per patient
      .filter(limit(5));

    return {
      ...acc,
      [patientId]: mergedAlerts,
    };
  }, {});
};

const stripECGData = (data: Vitals.LiveDataPayload = {}): Vitals.LiveDataPayload => {
  const keys = Object.keys(data);

  const strippedData = keys.reduce<Vitals.LiveDataPayload>((acc, key) => {
    const datum = data[key];

    return {
      ...acc,
      [key]: {
        ...datum,
        ecg: [],
      },
    };
  }, {});

  return strippedData;
};

type PatientAlerts = Record<string, Websocket.Alert[]>;

interface ConnectionData {
  vitals?: Vitals.LiveDataPayload;
  alerts?: PatientAlerts;
}

const useLiveDataConnection = (onUpdate: (data: ConnectionData) => void) => {
  const initialVitals = useActiveAssignedAdmissionVitals();

  const [cache, setCache] = useState<ConnectionData>({});

  const session = useUserSession();

  const [hasPopulatedCache, setHasPopulatedCache] = useState(false);
  const [hasPopulatedInitial, setHasPopulatedInitial] = useState(false);

  const { getData, setData } = useAsyncStorageData<ConnectionData>(
    'connection-live-data-cache',
    {}
  );

  const handleUpdate = useCallback(
    async (data: ConnectionData) => {
      setCache(data);
      onUpdate(data);
      await setData({
        ...data,
        vitals: stripECGData(data.vitals),
      });
    },
    [onUpdate, setData]
  );

  const onMessageHandler = useCallback(
    async (message: Websocket.LiveData) => {
      if (message.type === 'live-stream') {
        try {
          const mergedVitals = mergeVitalsData(cache.vitals, message.data);

          await handleUpdate({ ...cache, vitals: mergedVitals });
        } catch (err) {
          logger.error(err);
        }
      } else if (message.type === 'alert') {
        try {
          const mergedAlerts = mergeAlertData(message.data, cache.alerts);
          await handleUpdate({ ...cache, alerts: mergedAlerts });
        } catch (err) {
          logger.error(err);
        }
      }
    },
    [cache, handleUpdate]
  );

  const onOpenHandler = useCallback(
    async (sendMessage: SendMessage<Websocket.SubscriptionParams>) => {
      const authToken = await session.getAuthToken();
      if (!authToken) {
        return;
      }

      sendMessage({
        action: 'subscribe',
        authToken,
      });
    },
    [session]
  );

  useWebsocket(env.vitalsWebsocketUrl, onOpenHandler, onMessageHandler);

  useEffect(() => {
    const shouldPopulateCache = !hasPopulatedCache;

    const hasInitialValues = Object.keys(initialVitals).length;
    const shouldPopulateInitial = hasInitialValues && !hasPopulatedInitial;

    const exec = async () => {
      if (shouldPopulateCache) {
        const data = await getData();
        // only populate the cache on an initial mount
        setHasPopulatedCache(true);

        const vitals = mergeVitalsData(cache.vitals, data.vitals);
        await handleUpdate({ vitals });
      }

      if (shouldPopulateInitial) {
        setHasPopulatedInitial(true);

        const vitals = mergeVitalsData(cache.vitals, initialVitals);
        await handleUpdate({ vitals });
      }
    };

    exec().catch(logger.error);
  }, [cache, getData, handleUpdate, hasPopulatedCache, hasPopulatedInitial, initialVitals]);
};

interface WithUpdated {
  updated: number;
  vitals: Vitals.LiveDataPayload;
  alerts: PatientAlerts;
}

export const LiveDataProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<WithUpdated>({
    updated: Date.now(),
    vitals: {},
    alerts: {},
  });

  const handleUpdateVitals = useCallback((value: ConnectionData) => {
    setData((old) => ({
      ...old,
      updated: Date.now(),
      vitals: value.vitals || {},
      alerts: value.alerts || {},
    }));
  }, []);

  useLiveDataConnection(handleUpdateVitals);

  return <LiveDataContext.Provider value={data}>{children}</LiveDataContext.Provider>;
};

const maxAlertAge = hoursToMilliseconds(72);

/**
 * Checks if an alert is older than the stale amount, for now this is 3 days
 */
const isAlertStale = (alert: Websocket.Alert): boolean => {
  const age = Date.now() - alert.ts;

  return age > maxAlertAge;
};

/**
 * Inverse of `isAlertStale`
 */
const isAlertRecent = (alert: Websocket.Alert) => !isAlertStale(alert);

const removeStaleAlertsForPayload = (alerts?: PatientAlerts): PatientAlerts => {
  if (!alerts) {
    return {};
  }

  return Object.entries(alerts).reduce(
    (accumulator, [patientId, patientAlerts = []]) => ({
      ...accumulator,
      [patientId]: patientAlerts.filter(isAlertRecent),
    }),
    {}
  );
};

/**
 * Use the aggregated live data for all patients
 *
 * @param includeStale if true then expired readings will not be filtered out
 * @returns
 */
export const useLiveData = (includeStale = false): Cache => {
  const context = useContext(LiveDataContext);

  if (includeStale) {
    return context;
  }

  const vitals = removeStaleMeasurementsForVitalsPayload(context.vitals);
  const alerts = removeStaleAlertsForPayload(context.alerts);

  return {
    updated: context.updated,
    alerts,
    vitals,
  };
};

export const usePatientLiveData = (
  patientId: string,
  includeStale = false
): Vitals.LiveDataMeasures => {
  const { vitals: data } = useLiveData(includeStale);

  return data[patientId] || {};
};

export const usePatientAlerts = (patientId: string): Websocket.Alert[] => {
  const { alerts } = useLiveData();
  const { data: admissions = [] } = useActiveAssignedAdmissions();

  const patientAlerts = alerts[patientId] || [];
  const admissionAlerts = admissions
    .filter((a) => a.subject?.id === patientId)
    .map((a) => a.alerts || [])
    .flat();

  const mergedAlerts = [...patientAlerts, ...admissionAlerts]
    .filter(isAlertStale)
    .filter(isHighQuality);

  return mergedAlerts;
};
