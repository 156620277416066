import styled from '@emotion/native';
import { Row } from 'components/base';
import { Text } from 'design-system/base';
import { BASE_SIZE, BORDER_RADIUS, PADDING } from 'design-system/theme';
import { getInitials } from 'helpers/name';
import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';

export interface VideoColProps {
  displayName?: string;
  volume?: number;
  isVideoEnabled: boolean;
}
const VideoPlaceHolder = styled(Row)({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

const InitialWrapper = styled.View(({ theme }) => ({
  backgroundColor: theme.color['accent-purple'],
  padding: PADDING.xl,
  borderRadius: BORDER_RADIUS.full,
  aspectRatio: 1,
  alignItems: 'center',
  justifyContent: 'center',
}));

const InitialText = styled(Text)({
  fontSize: BASE_SIZE[64],
});

const VideoColWrapper = styled(Animated.View)(({ theme }) => ({
  flex: 1,
  aspectRatio: 1,
  backgroundColor: theme.color.divider,
  borderRadius: 8,
  overflow: 'hidden',
  shadowColor: theme.color.white,
}));

export const VideoCol: React.FC<VideoColProps> = ({
  displayName,
  volume = 0,
  isVideoEnabled,
  children,
}) => {
  const borderAnim = useRef(new Animated.Value(volume)).current;

  const initials = getInitials(displayName);

  useEffect(() => {
    Animated.spring(borderAnim, {
      toValue: volume / 3,
      useNativeDriver: true,
    }).start();
  }, [borderAnim, volume]);

  return (
    <VideoColWrapper
      style={{
        shadowRadius: borderAnim,
      }}
    >
      {isVideoEnabled ? (
        children
      ) : (
        <VideoPlaceHolder>
          <InitialWrapper>
            <InitialText variant="page-heading">{initials}</InitialText>
          </InitialWrapper>
        </VideoPlaceHolder>
      )}
    </VideoColWrapper>
  );
};
