import { noOp } from 'helpers';
import React, { createContext, useContext } from 'react';

interface Context {
  hideDrawer: () => void;
  showDrawer: () => void;
}

const DrawerActionsContext = createContext<Context>({
  hideDrawer: noOp,
  showDrawer: noOp,
});

interface DrawerActionsProviderProps {
  onHide: () => void;
  onShow: () => void;
}

export const DrawerActionsProvider: React.FC<DrawerActionsProviderProps> = ({
  children,
  onHide,
  onShow,
}) => (
  <DrawerActionsContext.Provider value={{ showDrawer: onShow, hideDrawer: onHide }}>
    {children}
  </DrawerActionsContext.Provider>
);

export const useDrawerActions = (): Context => useContext(DrawerActionsContext);
