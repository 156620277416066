import styled from '@emotion/native';
import { ColorName, useTheme } from '@emotion/react';
import { Row } from 'components/base';
import { Text } from 'design-system/base';
import { BASE_SIZE, BORDER_RADIUS, BORDER_WIDTH, MARGIN } from 'design-system/theme';
import React, { ReactElement, useCallback } from 'react';
import { TouchableOpacity } from 'react-native';

export interface LegendProps<TIdentifier> {
  identifier: TIdentifier;
  color?: ColorName;
  label?: string;
  selected?: boolean;
  onClick?: (identifier: TIdentifier) => void;
}

interface ColorProps {
  color: ColorName;
  selected: boolean;
}

export const Color = styled.View<ColorProps>(({ color, selected }) => {
  const theme = useTheme();
  const resolvedColor = theme.color[color];
  const backgroundColor = selected ? resolvedColor : undefined;

  return {
    backgroundColor,
    height: BASE_SIZE[16],
    width: BASE_SIZE[16],
    marginRight: MARGIN['3xs'],
    borderRadius: BORDER_RADIUS.full,
    borderColor: resolvedColor,
    borderWidth: BORDER_WIDTH[2],
    borderStyle: 'solid',
  };
});

const LegendWrapper = styled(Row)({
  marginRight: MARGIN.xs,
  marginBottom: MARGIN['2xs'],
  alignItems: 'center',
});

export const Legend = <TCode extends string>({
  identifier,
  color,
  label = '',
  selected = false,
  onClick,
}: LegendProps<TCode>): ReactElement => {
  const handlePress = useCallback(() => onClick?.(identifier), [identifier, onClick]);

  return (
    <TouchableOpacity disabled={!onClick} onPress={handlePress}>
      <LegendWrapper>
        {color ? <Color color={color} selected={selected} /> : null}
        <Text variant="caption">{label}</Text>
      </LegendWrapper>
    </TouchableOpacity>
  );
};
