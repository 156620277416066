import { Logistics, GoogleDistanceMatrix } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import compact from 'lodash.compact';

export enum LogisticsEndpoint {
  Base = '',
  Book = 'book',
  Distance = 'distance',
  Quote = 'quote',
}

export class LogisticsApi {
  private readonly client: BaseApi;
  private readonly logisticsRoot = 'api/aggregate/logistics';
  constructor() {
    this.client = new BaseApi();
  }

  /**
   * Get an SWR Key associated with the `getPatient` function.
   */
  public getPatientLogisticsSWRKey = (
    patientId: string,
    ...endpoint: LogisticsEndpoint[]
  ): string => this.createPath(endpoint.join('_')) + patientId;

  private createPath(endpoint: string) {
    return compact([this.logisticsRoot, endpoint]).join('/');
  }

  public async getDistance(
    params: GoogleDistanceMatrix.GetDistanceParams
  ): Promise<GoogleDistanceMatrix.GetDistanceResponse> {
    const path = this.createPath(LogisticsEndpoint.Distance);

    const result = await this.client.get<GoogleDistanceMatrix.GetDistanceResponse>(path, {
      params,
    });

    return result.data;
  }

  public async getQuote(quoteRequest: Logistics.QuoteRequest): Promise<Logistics.QuoteResponse> {
    const path = this.createPath(LogisticsEndpoint.Quote);

    const result = await this.client.post<Logistics.QuoteResponse>(path, quoteRequest);

    return result.data;
  }

  public async createBooking(
    bookingRequest: Logistics.BookingRequest
  ): Promise<Logistics.BookingResponse> {
    const path = this.createPath(LogisticsEndpoint.Book);

    const result = await this.client.post<Logistics.BookingResponse>(path, bookingRequest);

    return result.data;
  }

  public async getBookings(params: Logistics.GetParameters): Promise<Logistics.Booking[]> {
    const path = this.createPath(LogisticsEndpoint.Book);

    const result = await this.client.get<Logistics.Booking[]>(path, {
      params,
    });

    return result.data;
  }
}
