import {
  ClinicalImpressionAssessmentValueCode,
  ClinicalImpressionCode,
  ClinicalImpressionLocationCode,
  ClinicalImpressionPlanValueCode,
} from '@quromedical/fhir-common';
import { strings } from 'strings';

export const codeDisplay: Record<ClinicalImpressionCode, string> = {
  [ClinicalImpressionCode.ClinicalUpdate]: strings.SOAPNoteCodeClinicalUpdate,
  [ClinicalImpressionCode.Routine]: strings.SOAPNoteCodeRoutine,
  [ClinicalImpressionCode.Onboarding]: strings.SOAPNoteCodeOnboarding,
  [ClinicalImpressionCode.ClinicalCallout]: strings.SOAPNoteCodeClinicalCallout,
  [ClinicalImpressionCode.Discharge]: strings.SOAPNoteCodeDischarge,
  [ClinicalImpressionCode.BloodResults]: strings.SOAPNoteCodeBloodResults,
  [ClinicalImpressionCode.Physiotherapy]: strings.SOAPNoteCodePhysiotherapy,
};

export const codeLocation: Record<ClinicalImpressionLocationCode, string> = {
  [ClinicalImpressionLocationCode.InPerson]: strings.SOAPNoteLocationCodeInPerson,
  [ClinicalImpressionLocationCode.Virtual]: strings.SOAPNoteLocationCodeVirtual,
};

export const codeAssessment: Record<ClinicalImpressionAssessmentValueCode, string> = {
  [ClinicalImpressionAssessmentValueCode.Deteriorating]: strings.SOAPNoteAssessmentDeteriorating,
  [ClinicalImpressionAssessmentValueCode.Improving]: strings.SOAPNoteAssessmentImproving,
  [ClinicalImpressionAssessmentValueCode.Stable]: strings.SOAPNoteAssessmentStable,
};

export const codePlan: Record<ClinicalImpressionPlanValueCode, string> = {
  [ClinicalImpressionPlanValueCode.Amend]: strings.SOAPNotePlanAmend,
  [ClinicalImpressionPlanValueCode.Continue]: strings.SOAPNotePlanContinue,
  [ClinicalImpressionPlanValueCode.Discharge]: strings.SOAPNotePlanDischarge,
  [ClinicalImpressionPlanValueCode.Escalate]: strings.SOAPNotePlanEscalate,
};
