import { Notes } from '@quromedical/models';
import { NoteCard } from 'core/notes';
import { MARGIN, Skeleton, SkeletonProvider, Spacer, Text, Visible } from 'design-system';
import React from 'react';
// all these components should be moved into core
import { downloadAttachment } from 'screens/patient';
import { strings } from 'strings';

interface NotesSectionProps {
  notes?: Notes.Note[];
  loading?: boolean;
}

const placeholder = '_____________________';

const Loading: React.FC = () => (
  <SkeletonProvider loading>
    {new Array(3).fill(0).map((_, i) => (
      <>
        <Spacer height={MARGIN.s} />
        <Skeleton>
          <NoteCard
            key={i}
            onAttachmentPress={downloadAttachment}
            note={{
              id: '',
              media: [],
              note: {
                subjective: placeholder,
                assessment: placeholder,
                objective: placeholder,
                plan: placeholder,
              },
              practitionersToNotify: [],
              vitals: {},
              pinned: false,
            }}
            canPin={false}
          />
        </Skeleton>
      </>
    ))}
  </SkeletonProvider>
);

export const NotesSection: React.FC<NotesSectionProps> = ({ notes, loading = false }) => {
  if (loading) {
    return <Loading />;
  }

  if (!notes?.length) {
    return <Text variant="page-heading">{strings.PatientPrintSummaryPlaceholderNoNotes}</Text>;
  }

  return (
    <>
      {notes?.map((note, index) => (
        <>
          <Visible if={index}>
            <Spacer height={MARGIN.s} />
          </Visible>
          <NoteCard onAttachmentPress={downloadAttachment} note={note} canPin={false} />
        </>
      ))}
    </>
  );
};
