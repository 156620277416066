import {
  ClinicalImpressionAssessmentValueCode,
  ClinicalImpressionPlanValueCode,
} from '@quromedical/fhir-common';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

import { codes, locationCodes, planCodes, assessmentCodes } from './mapping';
import { FormData } from './types';

export const isAssessmentNoteRequired = (code?: ClinicalImpressionAssessmentValueCode): boolean =>
  code !== ClinicalImpressionAssessmentValueCode.Stable;

export const isPlanNoteRequired = (code?: ClinicalImpressionPlanValueCode): boolean =>
  code !== ClinicalImpressionPlanValueCode.Continue;

export const formSchema = yup.object<ObjectType<FormData>>({
  code: yup.string().equals(codes),
  locationCode: yup
    .string()
    .equals(locationCodes)
    .required(strings.SOAPNoteLocationRequiredErrorMessage),
  planCode: yup.string().equals(planCodes).required(strings.SOAPNotePlanRequiredErrorMessage),
  assessmentCode: yup
    .string()
    .equals(assessmentCodes)
    .required(strings.SOAPNoteAssessmentRequiredErrorMessage),
  assessment: yup.string().when('assessmentCode', {
    is: isAssessmentNoteRequired,
    then: yup.string().required(strings.SOAPNoteAssessmentRequiredErrorMessage),
    otherwise: yup.string().notRequired(),
  }),
  plan: yup.string().when('planCode', {
    is: isPlanNoteRequired,
    then: yup.string().required(strings.SOAPNotePlanRequiredErrorMessage),
    otherwise: yup.string().notRequired(),
  }),
  objective: yup.string().notRequired(),
  subjective: yup.string().required(strings.SOAPNoteSubjectiveRequiredErrorMessage),
  media: yup.array().max(5),
});
