import { ColorName } from '@emotion/react';
import { Text } from 'design-system/base';
import React, { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';

export interface FilterProps<TIdentifier extends string | number> {
  identifier: TIdentifier;
  title: string;
  selected: boolean;
  onClick: (identifier: TIdentifier) => void;
}

export const Filter = <TIdentifier extends string | number>({
  identifier,
  onClick,
  title = '',
  selected = false,
}: FilterProps<TIdentifier>) => {
  const handleClick = useCallback(() => onClick(identifier), [identifier, onClick]);
  const color: ColorName | undefined = selected ? 'primary' : undefined;

  return (
    <TouchableOpacity onPress={handleClick}>
      <Text variant="caption" color={color}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};
