import { GroupSecurity } from '@quromedical/fhir-common';
import { Group } from '@quromedical/models';
import { GroupMembers, GroupFormCard, AddMemberCard } from 'core/group';
import { Alert, Section, Skeleton, SkeletonProvider, Snackbar } from 'design-system';
import { useUserSession } from 'hooks';
import { useRevalidation } from 'hooks/useRevalidation';
import { organizationFetcher } from 'integration';
import { GroupApi } from 'integration/aggregate';
import { GroupStackScreenProps } from 'navigation';
import React, { useCallback } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { NotFoundScreen } from 'screens/NotFoundScreen';
import { strings } from 'strings';
import useSWR from 'swr';

const api = new GroupApi();

export const GroupScreen: React.FC<GroupStackScreenProps<'View'>> = ({ route }) => {
  const session = useUserSession();

  const canEdit = session.hasAny([GroupSecurity.GroupUpdate]);

  const { id } = route.params;
  const fetcher = useCallback(() => api.getGroup(id), [id]);
  const swrKey = api.getGroupSWRKey(id);
  const { data = {}, isValidating, error } = useSWR(swrKey, fetcher);
  const revalidate = useRevalidation(swrKey);

  const onSubmit = useCallback(
    async (formData: Group.CreateRequest) => {
      await api.updateGroup(id, formData);
    },
    [id]
  );

  if (!id) {
    return <NotFoundScreen />;
  }

  return (
    <SkeletonProvider loading={isValidating}>
      <ScrollView>
        <Section unsetZIndex>
          <Skeleton>
            <GroupFormCard
              canEdit={canEdit}
              data={data}
              onSubmit={onSubmit}
              revalidate={revalidate}
              organizationFetcher={organizationFetcher}
            />
          </Skeleton>
        </Section>

        <Section unsetZIndex>
          <Skeleton>
            <AddMemberCard id={id} />
          </Skeleton>
        </Section>
        <Section unsetZIndex>
          <Skeleton>
            <GroupMembers id={id} />
          </Skeleton>
        </Section>

        <Snackbar onClose={revalidate} isOpen={error}>
          <Alert
            backgroundColor="status-critical"
            textColor="white"
            onAction={revalidate}
            actionIcon="close"
            title={strings.ErrorCardTitle}
            body={strings.GenericErrorMessage}
          />
        </Snackbar>
      </ScrollView>
    </SkeletonProvider>
  );
};
