import { Admission, Media } from '@quromedical/models';
import { handleDownload } from 'core/media';
import { Alert, Skeleton, SkeletonProvider, Snackbar, Visible } from 'design-system';
import { logger } from 'helpers';
import { useFiniteState } from 'hooks/useFiniteState';
import { icd10CodeFetcher } from 'integration';
import { MediaApi, PatientApi } from 'integration/aggregate';
import React, { useCallback } from 'react';
import { strings } from 'strings';

import { AdmissionCard } from './AdmissionCard';
import { AdmissionUpdateForm } from './AdmissionUpdateForm';

interface AdmissionInlineUpdateCardProps {
  patientId: string;
  hasMedicalAidAuthorization: boolean;
  canEdit: boolean;
  canViewMedia: boolean;
  data: Admission.GetResponseWithVitals;
  revalidate: () => void;
}

const api = new PatientApi();
const mediaApi = new MediaApi();

type FormState = 'initial' | 'editing' | 'submitting' | 'error';

export const AdmissionInlineUpdateCard: React.FC<AdmissionInlineUpdateCardProps> = ({
  patientId,
  data,
  revalidate,
  canEdit,
  hasMedicalAidAuthorization,
  canViewMedia,
}) => {
  const state = useFiniteState<FormState>('initial');

  const handleSubmit = useCallback(
    async (result: Admission.UpdateRequest) => {
      state.set('submitting');
      try {
        await api.updateAdmission(patientId, data.id, result);

        state.set('initial');
        revalidate();
      } catch (err) {
        state.set('error');
      }
    },
    [data, patientId, revalidate, state]
  );

  const downloadFile = useCallback(
    async (id: string) => {
      try {
        const { url } = await mediaApi.createDownloadUrl(patientId, id);
        await handleDownload(url);
      } catch (err) {
        logger.error(err);
      }
    },
    [patientId]
  );

  const createUploadUrl = useCallback(
    (body: Media.CreateUploadUrlRequest): Promise<Media.CreateUploadUrlResponse> =>
      mediaApi.createUploadUrl(patientId, body),
    [patientId]
  );

  return (
    <>
      <SkeletonProvider loading={state.is('submitting')}>
        <Skeleton>
          <Visible if={state.isNot('editing')}>
            <AdmissionCard
              downloadFile={downloadFile}
              data={data}
              canEdit={canEdit}
              canViewMedia={canViewMedia}
              onEditPress={state.next('editing')}
            />
          </Visible>

          <Visible if={state.is('editing')}>
            <AdmissionUpdateForm
              icd10CodeFetcher={icd10CodeFetcher}
              data={data}
              hasMedicalAidAuthorization={hasMedicalAidAuthorization}
              handleCancel={state.next('initial')}
              handleSubmit={handleSubmit}
              createUploadUrl={createUploadUrl}
            />
          </Visible>
        </Skeleton>
      </SkeletonProvider>

      <Snackbar onClose={state.next('editing')} isOpen={state.is('error')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={state.next('editing')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
        />
      </Snackbar>
    </>
  );
};
