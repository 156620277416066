import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { ClaimTemplateListScreen, ClaimTemplateScreen } from 'screens/claim-template';
import { strings } from 'strings';

import { GroupStackParamList } from './types';

const Stack = createNativeStackNavigator<GroupStackParamList>();

export const ClaimTemplateNavigator: React.FC = () => (
  <Stack.Navigator initialRouteName="List">
    <Stack.Screen
      name="List"
      component={ClaimTemplateListScreen}
      options={{
        title: strings.ClaimTemplateListTitle,
        headerShown: false,
      }}
    />
    <Stack.Screen
      name="View"
      component={ClaimTemplateScreen}
      options={{ title: strings.ClaimTemplateViewTitle }}
    />
  </Stack.Navigator>
);
