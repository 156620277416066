import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';

import { env } from './env';

const environment = `${env.stage}-${Platform.OS}`;

const webConfig: Sentry.SentryExpoNativeOptions = {
  dsn: env.sentryDSN,
  release: env.releaseId,
  environment,
  enableInExpoDevelopment: true,
};

const nativeConfig: Sentry.SentryExpoNativeOptions = {
  dsn: env.sentryDSN,
  environment,
};

export const sentryConfig = Platform.OS === 'web' ? webConfig : nativeConfig;
