import { Row, Col } from 'components/base';
import { Text, Spacer, IconName, Icon, Card, Button } from 'design-system/base';
import { MARGIN, PADDING } from 'design-system/theme';
import React from 'react';

import { LineLoader, LineLoaderStatus } from './line-loader';

type Display = (value: number, total: number) => string;

const getPercentage = (value: number, total: number): number => {
  if (total <= 0) {
    return 0;
  }

  const fraction = value / total;
  const result = Math.ceil(fraction * 100);

  return result;
};

const percentDisplay: Display = (value, total) => {
  const percent = getPercentage(value, total);

  return `${percent}%`;
};

export interface Progress {
  value: number;
  total: number;
  status: LineLoaderStatus;
}

interface FileUploadItemProps {
  title?: string;
  progress?: Progress;
  icon?: IconName;
  display?: Display;
  onCancel: () => void;
}

export const FileUploadItem: React.FC<FileUploadItemProps> = ({
  title = '',
  progress,
  display = percentDisplay,
  onCancel,
  icon = 'attachment',
}) => (
  <Card paddingHorizontal={0} paddingVertical={PADDING['3xs']} color="base">
    <Row justifyContent="space-between" fullWidth={true}>
      <Col justifyContent="center" fullWidth={true} flex={1}>
        <Row alignItems="center">
          <Icon name={icon} />
          <Spacer width={MARGIN.s} />
          <Text numberOfLines={1} ellipsizeMode="head" variant="body">
            {title}
          </Text>
        </Row>
      </Col>
      <Col justifyContent="flex-end">
        <Button
          textColor="text-disabled"
          variant="flat"
          shape="round"
          icon="highlight-remove"
          onPress={onCancel}
        />
      </Col>
    </Row>
    <Spacer height={MARGIN['2xs']} />
    {progress ? (
      <LineLoader
        total={progress.total}
        value={progress.value}
        status={progress.status}
        display={display}
      />
    ) : undefined}
  </Card>
);
