import { FONT_FAMILY, FONT_SIZE } from 'design-system';
import React from 'react';
import { AlignmentBaseline, Text as BaseText, TextAnchor } from 'react-native-svg';

import { resolveScaledValue } from './layout';
import { usePlotDataContext } from './PlotDataProvider';
import { ExternalComponentProps } from './types';

interface TextProps {
  x: string | number;
  y: string | number;
  /**
   * A pixel value offset - will only be applied if the `x` value is a number
   */
  xOffset?: number;
  /**
   * A pixel value offset - will only be applied if the `y` value is a number
   */
  yOffset?: number;
  text?: string;
  color?: string;
  fontSize?: number;
  fontFamily?: string;
  alignmentBaseline?: AlignmentBaseline;
  textAnchor?: TextAnchor;
}

type ExternalProps = ExternalComponentProps & TextProps;

export const Text: React.FC<ExternalProps> = (props) => {
  const {
    text = '',
    color = 'lightgrey',
    fontSize = FONT_SIZE.body,
    fontFamily = FONT_FAMILY.regular,
    alignmentBaseline = 'middle',
    textAnchor = 'middle',
    x,
    y,
    xScale,
    yScale,
    xOffset = 0,
    yOffset = 0,
  } = usePlotDataContext(props);

  const resolvedX = resolveScaledValue(xScale, x, xOffset);
  const resolvedY = resolveScaledValue(yScale, y, yOffset);

  return (
    <BaseText
      fontFamily={fontFamily}
      fontSize={fontSize}
      fill={color}
      textAnchor={textAnchor}
      alignmentBaseline={alignmentBaseline}
      x={resolvedX}
      y={resolvedY}
      pointerEvents="none"
    >
      {text}
    </BaseText>
  );
};
