import styled from '@emotion/native';
import { Skeleton, Card } from 'design-system/base';
import { MARGIN, PADDING } from 'design-system/theme';
import React from 'react';

import { Header, HeaderProps } from '../header';
import { Summary, SummaryProps } from './Summary';
import { SummaryWrapper } from './SummaryWrapper';

export interface VitalCardMinimalProps extends HeaderProps {
  /**
   * Using an `undefined` value in a row will lead to spacing. This is useful for aligning to the
   * layout of other components that may have more elements
   */
  values?: SummaryProps[];
}

const BodyWrapper = styled.View({
  marginTop: MARGIN.m,
  paddingHorizontal: PADDING.m,
  paddingBottom: PADDING.s,
});

export const VitalCardMinimal: React.FC<VitalCardMinimalProps> = ({ values = [], ...header }) => (
  <Card paddingHorizontal={0} paddingVertical={0}>
    <Header {...header} />
    <BodyWrapper>
      <Skeleton>
        <SummaryWrapper>
          {values.map((value) => (
            <Summary key={value.title} fallbackValue="--" {...value} />
          ))}
        </SummaryWrapper>
      </Skeleton>
    </BodyWrapper>
  </Card>
);
