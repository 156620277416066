import { useCallback } from 'react';
import useSWR, { SWRResponse, SWRConfiguration } from 'swr';

import { useRevalidation as useRevalidationBase } from './useRevalidation';

type ResourceType = fhir4.FhirResource['resourceType'];

type KeyType = ResourceType | [ResourceType, string];

interface FhirDataResult<TResult, TError> extends SWRResponse<TResult, TError> {
  /**
   * Function can be called to force SWR to revalidate the cache which should trigger an update for
   * any components displaying the data from the hook
   */
  revalidate: () => void;
}

/**
 * Creates a SWR cache key in a way that allows us to consistently invalidate if required
 * @param key type of FHIR resource being referenced
 * @param params params used for the fetcher function
 * @returns the cache key to be used
 */
const createCacheKey = <TParams>(key: KeyType, params?: TParams): string => {
  const isKeyArray = Array.isArray(key);

  if (!params) {
    if (isKeyArray) {
      return (key as string[]).join('/');
    }

    return key as string;
  }

  const strParams = JSON.stringify(params);

  if (isKeyArray) {
    return `${(key as string[]).join('/')}/${strParams}`;
  }

  return `${key as string}/${strParams}`;
};

/**
 * Makes use of `useSWR` but wraps the implementation with an additional key for the ResourceType.
 * This helps ensure that the SWR caching mechanism works correctly if fetchers for different
 * resources have the same params (e.g. get a patient vs get notes for a patient both use patientId)
 * @param key type of resource being fetched, should align with TResult
 * @param params params to be passed to the fetcher
 * @param fetcher to be used for fetching patients, use an arrow function to prevent scoping issues
 * @example
 * // outside your component, creates a wrapper for the fetcher
 * const fetcher = (patientId: string) => api.getPatient(patientId);
 *
 * // inside the component
 * const { data, error } = useFhirData('Patient', patientId, fetcher);
 */
export const useFhirData = <TParams, TResult>(
  key: KeyType,
  params: TParams,
  fetcher: (p: TParams) => Promise<TResult>,
  swrOptions?: SWRConfiguration
): FhirDataResult<TResult, object> => {
  const keyedFetcher = () => fetcher(params);
  const cacheKey = createCacheKey(key, params);

  const swr = useSWR(cacheKey, keyedFetcher, swrOptions);

  const revalidate = useCallback(() => swr.mutate(cacheKey, true), [cacheKey, swr]);

  return { ...swr, revalidate };
};

/**
 * Makes use of SWR revalidation for FHIR data
 *
 * @deprecated new components should use `hooks/useRevalidation` and not this hook
 *
 * @param key prefix of SWR key that will be invalidated
 * @returns `revalidate` function to be called to mark SWR data as invalid
 * @example
 * // create a revalidator
 * const revalidate = useRevalidation('Patient')
 *
 * // later
 * const handleStuff = async () => {
 *  // a form submission or something that will cause current SWR cache to be stale
 *  await doStuff()
 *
 *  // trigger cache revalidation
 *  revalidate()
 * }
 */
export const useRevalidation = (key: KeyType): (() => Promise<void>) => {
  const cacheKey = createCacheKey(key);
  const revalidate = useRevalidationBase(cacheKey);
  return revalidate;
};
