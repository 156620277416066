import styled from '@emotion/native';
import { Validations } from '@quromedical/models';
import { Row } from 'components/base';
import { Button, MARGIN, Spacer, TextBox, BORDER_WIDTH, Visible } from 'design-system';
import { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { strings } from 'strings';

import { AuthBottomLinks } from './AuthBottomLinks';
import { AuthWrapper } from './AuthWrapper';
import { AuthenticationStepProps } from './types';

const Wrapper = styled.View(({ theme }) => {
  const backgroundColor = theme.color.base;
  const borderColor = theme.color.primary;

  return {
    backgroundColor,
    borderStyle: 'solid',
    borderBottomWidth: BORDER_WIDTH[4],
    borderColor,
    flex: 1,
  };
});

export const AuthUsername: React.FC<AuthenticationStepProps> = (props) => {
  const { authState, onNext } = props;
  const { username: value } = authState;
  const [username, setUsername] = useState(value);
  const [error, setError] = useState<string>();
  const [isValid, setValidity] = useState(false);

  const onSubmit = useCallback(() => {
    setError(undefined);
    if (!isValid) {
      setError(strings.AuthUsernameError);
      return;
    }

    onNext({ username });
  }, [username, onNext, isValid]);

  useEffect(() => {
    const isUsernameValid = Validations.usernameSchema.required().isValidSync(username);
    setValidity(isUsernameValid);
  }, [username]);

  const isWeb = Platform.OS === 'web';

  return (
    <Wrapper>
      <AuthWrapper
        heading={strings.AuthUsernameHeading}
        subheading={strings.AuthUsernameSubheading}
      >
        <Row>
          <TextBox
            onChange={setUsername}
            onSubmitEditing={onSubmit}
            label={strings.AuthUsernameLabel}
            errors={error}
            value={username}
          />
        </Row>
        <Spacer height={MARGIN.m} />
        <Row>
          <Button
            onPress={onSubmit}
            text={strings.AuthLoginText}
            size="full-width"
            icon="arrow-forward"
            iconPosition="right"
          />
        </Row>
      </AuthWrapper>
      <Visible if={isWeb}>
        <AuthBottomLinks />
      </Visible>
    </Wrapper>
  );
};
