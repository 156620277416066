import { Observation } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import compact from 'lodash.compact';

export enum ObservationEndpoint {
  Vitals = 'vitals',
}

interface PathParams {
  patientId: string;
  endpoint?: ObservationEndpoint;
}

export class ObservationApi {
  private readonly client: BaseApi;
  private readonly patientRoute = 'api/aggregate/patient';
  private readonly observationRoute = 'observation';

  constructor() {
    this.client = new BaseApi();
  }

  private createPath({ patientId, endpoint }: PathParams) {
    const base = [this.patientRoute, patientId, this.observationRoute].join('/');

    return compact([base, endpoint]).join('/');
  }

  public async createObservationVital(
    patientId: string,
    params: Observation.CreateVitalsRequest
  ): Promise<Observation.CreateVitalsResponse> {
    const path = this.createPath({ patientId, endpoint: ObservationEndpoint.Vitals });
    const result = await this.client.post<Observation.CreateVitalsResponse>(path, params);

    return result.data;
  }
}
