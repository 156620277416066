import { getNumericFallback } from 'helpers';
import React from 'react';
import { Line } from 'react-native-svg';

import { usePlotDataContext } from './PlotDataProvider';
import { ExternalComponentProps } from './types';

interface YLineProps {
  yVal: number;
  xStart?: number;
  xEnd?: number;
  opacity?: number;
  stroke?: string;
  strokeWidth?: number;
  strokeDasharray?: number | number[];
}

type ExternalProps = ExternalComponentProps & YLineProps;

export const YLine: React.FC<ExternalProps> = (props) => {
  const {
    yVal,
    yScale,
    opacity = 1,
    strokeWidth = 1,
    stroke = 'lightgrey',
    strokeDasharray,
    xScale,
    xDomain,
    xStart,
    xEnd,
  } = usePlotDataContext(props);

  const y = yScale(yVal);

  const x1 = xScale(getNumericFallback(xDomain[0], xStart));
  const x2 = xScale(getNumericFallback(xDomain[1], xEnd));

  return (
    <Line
      x1={x1}
      x2={x2}
      y1={y}
      y2={y}
      opacity={opacity}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeDasharray={strokeDasharray}
    />
  );
};
