import React, { useState, useCallback, useMemo } from 'react';

import { Tabs } from './Tabs';
import { AllowedChildren, TabGroupProps, TabState, TabWithIndex } from './types';

const getTabState = (children: AllowedChildren, activeIndex: number): TabState => {
  let activeChild: TabGroupProps['children'] | null = null;

  const tabs: TabWithIndex[] = [];
  React.Children.forEach(children, (child, index) => {
    const isActive = activeIndex === index;

    if (isActive) {
      activeChild = child;
    }

    if (!React.isValidElement(child)) {
      return null;
    }

    tabs.push({
      ...child.props,
      index,
    });
  });

  return { activeChild, tabs };
};

export const Group: React.FC<TabGroupProps> = ({ children, onTabChange, tabWidth }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const { activeChild, tabs } = useMemo(
    () => getTabState(children, activeIndex),
    [activeIndex, children]
  );
  const setActiveTab = useCallback(
    (tab: TabWithIndex) => {
      setActiveIndex(tab.index);
      if (onTabChange) {
        onTabChange(tab);
      }
    },
    [onTabChange]
  );

  return (
    <>
      <Tabs tabWidth={tabWidth} tabs={tabs} onTabPress={setActiveTab} activeIndex={activeIndex} />
      {activeChild}
    </>
  );
};
