import { useCallback, useState } from 'react';
import { LayoutChangeEvent, LayoutRectangle, useWindowDimensions } from 'react-native';

interface Layout {
  height: number;
  width: number;
}

interface LayoutHookResult {
  layout: Layout;
  onLayout: (e: LayoutChangeEvent) => void;
}

interface Input {
  initialHeight?: number;
  initialWidth?: number;
  /**
   * Max number of times to recalculate layout
   * (prevents infinite look for self-modifying components)
   */
  maxCount?: number;
}

/**
 * To be used when we want to pass information about a component's height and width but
 * it's only available after the component has been rendered. Using `maxCount` helps mitigate
 * a potential infinite loop
 *
 * > Note, changing the inner component size will cause this to change, should not be used for
 * > components that are modifying their own inner dimensions, but rather for the case where we
 * > are modifying some specific data e.g. an SVG ViewBox based on the component's size
 *
 * @example
 * const { layout, onLayout } = useLayoutDimensions(initialWidth);
 *
 * return <View onLayout={onLayout}>
 *   <SomeComponent width={layout.width} height={layout.height} />
 * </View>
 */
export const useLayoutDimensions = ({
  initialHeight: baseHeight,
  initialWidth: baseWidth,
  maxCount = 2,
}: Input): LayoutHookResult => {
  const windowDimensions = useWindowDimensions();
  const [count, setCount] = useState(0);
  const [layout, setLayout] = useState<LayoutRectangle>();

  const onLayout = useCallback(
    (e: LayoutChangeEvent) => {
      if (count > maxCount) return;

      setCount(count + 1);
      setLayout(e.nativeEvent.layout);
    },
    [count, maxCount]
  );

  return {
    onLayout,
    layout: {
      height: layout?.height || baseHeight || windowDimensions.height,
      width: layout?.width || baseWidth || windowDimensions.width,
    },
  };
};
