import { GroupSecurity } from '@quromedical/fhir-common';
import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItemList,
} from '@react-navigation/drawer';
import { Col } from 'components/base';
import { WithGroup } from 'core/auth';
import { SidebarAlerts } from 'core/vitals';
import React from 'react';
import { View } from 'react-native';

export const DrawerNavigatorContent: React.FC<DrawerContentComponentProps> = (props) => (
  <DrawerContentScrollView>
    <DrawerItemList {...props} />
    <WithGroup groups={[GroupSecurity.PatientRead]}>
      <WithGroup groups={[GroupSecurity.AlertEWS]}>
        <Col flex={1} justifyContent="flex-start">
          <View>
            <SidebarAlerts />
          </View>
        </Col>
      </WithGroup>
    </WithGroup>
  </DrawerContentScrollView>
);
