import { FhirBaseApi } from './FhirBaseApi';

export interface Params {
  count?: number;
  pageToken?: string;
  code?: string;
}
export class GroupApi extends FhirBaseApi<fhir4.Group> {
  constructor() {
    super('api/fhir/group');
  }

  getGroup = async (id: string): Promise<fhir4.Group> => this.get(id);

  updateGroupMembers = async (id: string, member: fhir4.GroupMember[]): Promise<void> => {
    const path = this.getPath(id, 'member');
    await this.client.put(path, member);
  };
}
