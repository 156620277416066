import { getReferenceId } from '@quromedical/fhir-common';
import { Admission, Vitals } from '@quromedical/models';
import { mergeObjects } from 'helpers';
import { getField, getNextTokenFromLink, usePaginatedData } from 'hooks/usePaginatedData';
import { AdmissionApi } from 'integration/aggregate';
import { useCallback } from 'react';

const api = new AdmissionApi();

const getNextToken = (data: Admission.GetAllResponse): string | undefined =>
  getNextTokenFromLink(data.link);

/**
 * Will get the active assignments as well as the vitals shadow for the patient
 */
export const useActiveAssignedAdmissions = (count = 150) => {
  const admissionFetcher = useCallback(
    (pageToken?: string) =>
      api.getAdmissions({ pageToken, count, status: 'in-progress', includeVitals: true }),
    [count]
  );

  return usePaginatedData<Admission.GetAllResponse, Admission.GetResponseWithVitals>(
    'active-assigned-admissions',
    admissionFetcher,
    getField('admissions'),
    getNextToken
  );
};

const mapAdmissionToVitalsPartial = ({
  vitals,
  subject,
}: Admission.GetResponseWithVitals): Vitals.LiveDataPayload => {
  const patientId = getReferenceId(subject);
  const hasVitals = patientId && vitals;

  if (!hasVitals) {
    return {};
  }

  return {
    [patientId]: vitals,
  };
};

const getVitalsFromAdmissions = (
  admissions: Admission.GetResponseWithVitals[] = []
): Vitals.LiveDataPayload => {
  const vitalPartials = admissions.map<Vitals.LiveDataPayload>(mapAdmissionToVitalsPartial);

  return mergeObjects(vitalPartials, {});
};

/**
 * Use the latest vitals shadow for all active assigned patient
 */
export const useActiveAssignedAdmissionVitals = (admissionCount?: number) => {
  const { data } = useActiveAssignedAdmissions(admissionCount);

  return getVitalsFromAdmissions(data);
};
