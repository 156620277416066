import { RequiredCoding } from '@quromedical/fhir-common';
import { Patient, Validations } from '@quromedical/models';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Field as FormField, SelectOption } from 'components/types';
import { getDisplay } from 'core/display';
import { Alert, FormCard, Skeleton, SkeletonProvider, Snackbar, Visible } from 'design-system';
import { useFiniteState } from 'hooks/useFiniteState';
import { icd10CodeFetcher } from 'integration';
import { PatientApi } from 'integration/aggregate';
import React, { useCallback } from 'react';
import { strings } from 'strings';

interface ICD10CardProps {
  patientId: string;
  admissionICD10Codes?: RequiredCoding[];
  canEdit: boolean;
  revalidate: () => void;
}

const api = new PatientApi();

interface InitialFormValues {
  initialValues: Patient.AdmissionICD10CodeUpdateRequest;
  initialCodes?: SelectOption[];
}

/**
 * get the initial form values for the admission ICD10 Codes
 *
 * note that the initial selections ensure that we can view values that may not yet be in the option
 * list since the options need to be fetched from the backend
 */
const getInitialFormValues = (icd10Codes: RequiredCoding[]): InitialFormValues => {
  const initialCodes: SelectOption[] | undefined = icd10Codes.map((code) => ({
    value: code.code,
    display: getDisplay(code, 'code: display'),
  }));

  const initialValues: Patient.AdmissionICD10CodeUpdateRequest = {
    admissionICD10Codes: icd10Codes.map((code) => code.code),
  };

  return { initialCodes, initialValues };
};

interface FormProps {
  admissionICD10Codes: RequiredCoding<string>[];
  handleSubmit: (data: Patient.AdmissionICD10CodeUpdateRequest) => Promise<void>;
  handleCancel: () => void;
}

const Form: React.FC<FormProps> = ({ admissionICD10Codes, handleCancel, handleSubmit }) => {
  const { initialValues, initialCodes } = getInitialFormValues(admissionICD10Codes);

  const fields: FormField<Patient.AdmissionICD10CodeUpdateRequest>[] = [
    {
      subfields: [
        {
          key: 'admissionICD10Codes',
          label: strings.FormLabelICD10Codes,
          type: 'combobox-multiple',
          searchable: true,
          fetcher: icd10CodeFetcher,
          initialSelection: initialCodes,
        },
      ],
    },
  ];
  const onSubmit = useCallback<SubmissionHandler<Patient.AdmissionICD10CodeUpdateRequest>>(
    async (data) => {
      try {
        await handleSubmit?.(data);
        return {};
      } catch (error) {
        return { error };
      }
    },
    [handleSubmit]
  );
  return (
    <CrudForm
      cardProps={{ unsetZIndex: true }}
      title={strings.CardTitleAdmissionDetails}
      validationSchema={Validations.admissionICD10CodeUpdateRequestSchema}
      fields={fields}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSecondarySubmit={handleCancel}
      showSecondarySubmitButton
      showSubmitButton
    />
  );
};

interface CardProps {
  canEdit: boolean;
  onEditPress: () => void;
  admissionICD10Codes: RequiredCoding<string>[];
}

const Card: React.FC<CardProps> = ({ admissionICD10Codes, canEdit, onEditPress }) => (
  <FormCard
    isButtonVisible={canEdit}
    onButtonPress={onEditPress}
    buttonText={strings.ButtonTextEdit}
    buttonIcon="edit"
    showIcons
    title={strings.CardTitleAdmissionDetails}
    rows={[
      {
        icon: 'list',
        fields: [
          {
            type: 'list',
            label: strings.FormLabelICD10Codes,
            display: admissionICD10Codes.map((code) => getDisplay(code, 'code: display')),
          },
        ],
      },
    ]}
  />
);

type FormState = 'initial' | 'editing' | 'submitting' | 'error';

export const ICD10Card: React.FC<ICD10CardProps> = ({
  patientId,
  admissionICD10Codes = [],
  revalidate,
  canEdit,
}) => {
  const state = useFiniteState<FormState>('initial');

  const handleSubmit = useCallback(
    async (data: Patient.AdmissionICD10CodeUpdateRequest) => {
      state.set('submitting');
      try {
        await api.updateICD10Codes(patientId, data);
        state.set('initial');
        revalidate();
      } catch (err) {
        state.set('error');
      }
    },
    [patientId, revalidate, state]
  );

  return (
    <>
      <SkeletonProvider loading={state.is('submitting')}>
        <Skeleton>
          <Visible if={state.isNot('editing')}>
            <Card
              admissionICD10Codes={admissionICD10Codes}
              canEdit={canEdit}
              onEditPress={state.next('editing')}
            />
          </Visible>

          <Visible if={state.is('editing')}>
            <Form
              admissionICD10Codes={admissionICD10Codes}
              handleCancel={state.next('initial')}
              handleSubmit={handleSubmit}
            />
          </Visible>
        </Skeleton>
      </SkeletonProvider>

      <Snackbar onClose={state.next('editing')} isOpen={state.is('error')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={state.next('editing')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
        />
      </Snackbar>
    </>
  );
};
