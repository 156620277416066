import { RequiredCoding } from '@quromedical/fhir-common';
import { SelectOption } from 'components/types';

type CodeDisplayFormat = 'code' | 'display | code' | 'code: display';

type CodeDisplay = {
  code: string;
  display?: string;
};

type CodeFormatter = (codeDisplay: CodeDisplay) => string;

const formatters: Record<CodeDisplayFormat, CodeFormatter> = {
  code: ({ code }) => code,
  'display | code': ({ display, code }) => display || code,
  'code: display': ({ code, display = '' }) => `${code}: ${display}`,
};

const getCodeDisplayData = (coding: RequiredCoding): CodeDisplay => {
  const { code, display } = coding;

  return {
    code,
    display,
  };
};

export const getDisplay = (coding: RequiredCoding, format: CodeDisplayFormat): string => {
  const codeDisplay = getCodeDisplayData(coding);
  const formatter = formatters[format];

  return formatter(codeDisplay);
};

/**
 *
 * @param coding
 * @param format
 * @returns
 */
export const mapCodingToSelectOption = (
  format: CodeDisplayFormat,
  coding?: RequiredCoding
): SelectOption | undefined => {
  if (!coding) {
    return undefined;
  }

  return {
    display: getDisplay(coding, format),
    value: coding.code,
  };
};
