import { useCallback, useState } from 'react';

type OnChange<TSelection> = (selected?: TSelection) => void;

type UseToggleSelectionResult<TSelection> = [
  selection: TSelection | undefined,
  onChange: OnChange<TSelection>
];

/**
 * When an item is selected, the selection is updated, however, if the same item is selected again,
 * the selection is cleared.
 *
 * @param initialSelection the initially selected item
 * @param onChange called when an item is selected/deselected
 *
 * @example
 * const [selected, onSelect] = useToggleSelection();
 * // do stuff
 * <Button isSelected={selected === 'foo'} onClick={() => onSelect('foo')}>Select foo</Button>
 */
export const useToggleSelection = <TSelection extends string | number = string>(
  initialSelection?: TSelection,
  onChange?: OnChange<TSelection>
): UseToggleSelectionResult<TSelection> => {
  const [selected, setSelected] = useState<TSelection | undefined>(initialSelection);

  const onSelect = useCallback(
    (item?: TSelection) => {
      const next = item === selected ? undefined : item;

      setSelected(next);
      onChange?.(next);
    },
    [onChange, selected]
  );

  return [selected, onSelect];
};
