import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { OrganizationListScreen, OrganizationScreen } from 'screens/organization';

import { OrganizationStackParamList } from './types';

const Stack = createNativeStackNavigator<OrganizationStackParamList>();

export const OrganizationNavigator: React.FC = () => (
  <Stack.Navigator initialRouteName="List">
    <Stack.Screen
      name="List"
      component={OrganizationListScreen}
      options={{
        title: 'Organizations',
        headerShown: false,
      }}
    />
    <Stack.Screen name="View" component={OrganizationScreen} options={{ title: 'Organization' }} />
  </Stack.Navigator>
);
