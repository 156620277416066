import styled from '@emotion/native';
import { FlexAlignType, Platform } from 'react-native';

import { withVisibility } from './withVisibility';

export interface FlexProps {
  index?: number;
  alignItems?: FlexAlignType;
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  flexWrap?: 'wrap' | 'nowrap';
  flex?: number;
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: number;
  fullWidth?: boolean;
  fullHeight?: boolean;
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  /**
   * To set the CSS as `z-index: unset`, used to override the default react-native behavior.
   *
   * Only works on web.
   */
  unsetZIndex?: boolean;
}

/**
 * `zIndex: unset` will cause an error on native but is sometimes required for web since
 * react-native sets the `zIndex: 0` by default which breaks the stacking context in certain cases
 * @returns either "unset" as string (but cast as a number for compatibility) or undefined
 */
export const resolveZIndex = (unsetZIndex?: boolean): number | undefined => {
  const isWeb = Platform.OS === 'web';
  const shouldUnset = unsetZIndex && isWeb;

  const zIndex = (shouldUnset ? 'unset' : undefined) as number | undefined;

  return zIndex;
};

const FlexBase = styled.View<FlexProps>(
  ({
    alignItems,
    justifyContent,
    flex,
    flexGrow,
    flexShrink,
    flexBasis,
    flexWrap,
    fullWidth,
    fullHeight,
    flexDirection,
    unsetZIndex,
  }) => {
    const width = fullWidth ? '100%' : undefined;
    const height = fullHeight ? '100%' : undefined;

    const zIndex = resolveZIndex(unsetZIndex);

    return {
      zIndex,
      flex,
      alignItems,
      justifyContent,
      flexWrap,
      width,
      height,
      flexDirection,
      flexGrow,
      flexShrink,
      flexBasis,
    };
  }
);

export const Flex = withVisibility<FlexProps>(FlexBase);

const RowBase = styled(FlexBase)<FlexProps>({
  flexDirection: 'row',
});

export const Row = withVisibility<FlexProps>(RowBase);

const ColBase = styled(FlexBase)<FlexProps>({
  flexDirection: 'column',
});

export const Col = withVisibility<FlexProps>(ColBase);
