import { MaterialIcons } from '@expo/vector-icons';

import { Activity } from './Activity';
import { BloodPressure } from './BloodPressure';
import { Collapse } from './Collapse';
import { EmailOutline } from './EmailOutline';
import { Expand } from './Expand';
import { Filter } from './Filter';
import { FilterOutline } from './FilterOutline';
import { GenderMaleFemale } from './GenderMaleFemale';
import { GlucoseMeasure } from './GlucoseMeasure';
import { Heart } from './Heart';
import { HeartCardiogram } from './HeartCardiogram';
import { Insights } from './Insights';
import { Icon } from './internal';
import { LayingDown } from './LayingDown';
import { Lungs } from './Lungs';
import { OxygenSaturation } from './OxygenSaturation';
import { Patch } from './Patch';
import { PatchAdd } from './PatchAdd';
import { Quro } from './Quro';
import { ScaleBathroom } from './ScaleBathroom';
import { SortAmountAsc } from './SortAmountAsc';
import { SortAmountDesc } from './SortAmountDesc';
import { Stethoscope } from './Stethoscope';
import { Temperature } from './Temperature';
import { Whatsapp } from './Whatsapp';

export { SignalStrength, BarValue } from './SignalStrength';
export { TextIcon } from './TextIcon';

const textPrefix = 'text-';

type Letter =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z';

type TextIconName = `${typeof textPrefix}${Letter}`;

export type CustomIconName =
  | 'patch'
  | 'patch-add'
  | 'stethoscope'
  | 'quro'
  | 'filter-outline'
  | 'filter'
  | 'gender-male-female'
  | 'email-outline'
  | 'sort-amount-desc'
  | 'sort-amount-asc'
  | 'laying-down'
  | 'lungs'
  | 'oxygen-saturation'
  | 'temperature'
  | 'activity'
  | 'blood-pressure'
  | 'heart'
  | 'expand'
  | 'collapse'
  | 'whatsapp'
  | 'insights'
  | 'scale-bathroom'
  | 'glucose-measure'
  | 'heart-cardiogram';

export type MaterialIconNames = React.ComponentProps<typeof MaterialIcons>['name'];

export type IconName = MaterialIconNames | CustomIconName | TextIconName;

const iconMap: Record<CustomIconName, Icon> = {
  patch: Patch,
  stethoscope: Stethoscope,
  quro: Quro,
  filter: Filter,
  activity: Activity,
  lungs: Lungs,
  temperature: Temperature,
  'gender-male-female': GenderMaleFemale,
  'email-outline': EmailOutline,
  'filter-outline': FilterOutline,
  'sort-amount-asc': SortAmountAsc,
  'sort-amount-desc': SortAmountDesc,
  'laying-down': LayingDown,
  'oxygen-saturation': OxygenSaturation,
  'patch-add': PatchAdd,
  'blood-pressure': BloodPressure,
  heart: Heart,
  expand: Expand,
  collapse: Collapse,
  whatsapp: Whatsapp,
  insights: Insights,
  'scale-bathroom': ScaleBathroom,
  'glucose-measure': GlucoseMeasure,
  'heart-cardiogram': HeartCardiogram,
};

/**
 * get a custom icon if it exists in the icon map
 * @param name the name of the icon
 * @returns the icon component if found, otherwise `undefined`
 */
export const getCustomIcon = (name: string): Icon | undefined => {
  const foundIcon = iconMap[name as CustomIconName];

  // since we're casting `name` above, we can have the case where the icon is not found
  return foundIcon as Icon | undefined;
};

/**
 * get the name of the Text icon if the icon is a valid Text icon
 */
export const getTextIconName = (name: string): string | undefined => {
  const isTextIcon = name.startsWith(textPrefix);

  if (!isTextIcon) {
    return undefined;
  }

  return name.replace(textPrefix, '');
};
