import { DeviceUseStatementCode, DeviceUseStatementStatus } from '@quromedical/fhir-common';
import { DeviceAssociation } from '@quromedical/models';
import { SortConfig } from 'components/table';
import { Field, SelectOption } from 'components/types';
import { createMixedFetcher, mapDisplaysToOptionsWithStart } from 'core/forms';
import { MonitoringConfiguration, monitoringConfigurationDisplay } from 'core/vitals';
import { deviceByTypeFetcher } from 'integration';
import { strings } from 'strings';

import { deviceUseStatementToDeviceCodeMap, TableAssociation } from './columns';
import { deviceUseStatementCodeDisplayMap } from './mapping';

export const sortConfig: SortConfig<TableAssociation> = {
  status: {
    asc: 'status',
    desc: '-status',
  },
  start: {
    asc: 'date',
    desc: '-date',
  },
  end: {
    asc: 'date',
    desc: '-date',
  },
  code: {
    asc: 'code',
    desc: '-code',
  },
  device: {
    asc: 'device',
    desc: '-device',
  },
};

interface ClientSideFilterParams {
  monitoringConfiguration?: MonitoringConfiguration;
}

export type Search = ClientSideFilterParams &
  Pick<DeviceAssociation.GetAllParams, 'code' | 'status' | 'device'>;

export const initialSearch: Search = {
  status: 'active',
};

const deviceUseStatementStatus: Record<DeviceUseStatementStatus, string> = {
  active: strings.DeviceUseStatementStatusActive,
  completed: strings.DeviceUseStatementStatusCompleted,
  'entered-in-error': strings.DeviceUseStatementStatusEnteredInError,
  intended: strings.DeviceUseStatementStatusIntended,
  stopped: strings.DeviceUseStatementStatusStopped,
  'on-hold': strings.DeviceUseStatementStatusOnHold,
};

const filterAll: SelectOption = {
  display: strings.SortFilterAll,
  value: undefined,
};

export const searchFields = (code?: DeviceUseStatementCode): Field<Search>[] => {
  const deviceCode = code ? deviceUseStatementToDeviceCodeMap[code] : undefined;

  return [
    {
      subfields: [
        {
          key: 'device',
          label: strings.LabelDevice,
          type: 'combobox-single',
          fetcher: createMixedFetcher(
            [
              {
                display: strings.SortFilterAll,
                value: undefined,
              },
            ],
            deviceByTypeFetcher(deviceCode)
          ),
          searchable: true,
          placeholder: filterAll.display,
        },
        {
          key: 'code',
          label: strings.LabelDeviceType,
          type: 'combobox-single',
          fetcher: mapDisplaysToOptionsWithStart(filterAll, deviceUseStatementCodeDisplayMap),
          placeholder: filterAll.display,
        },
        {
          key: 'status',
          label: strings.LabelStatus,
          type: 'combobox-single',
          fetcher: mapDisplaysToOptionsWithStart(filterAll, deviceUseStatementStatus),
          placeholder: filterAll.display,
        },
        {
          key: 'monitoringConfiguration',
          label: strings.MonitoringConfigurationLabel,
          type: 'combobox-single',
          fetcher: mapDisplaysToOptionsWithStart(filterAll, monitoringConfigurationDisplay),
          placeholder: filterAll.display,
        },
      ],
    },
  ];
};
