import { ClaimTypeCode } from '@quromedical/fhir-common';
import { ChargeItemDefinition, Claim, ClaimTemplate } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { Field } from 'components/types';
import { claimTypeDisplay } from 'core/display';
import { mapDisplaysToOptions } from 'core/forms';
import { useFiniteState } from 'hooks/useFiniteState';
import { useList } from 'hooks/useList';
import React, { useCallback, useMemo } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

import { ClaimLine, ClaimLineForm, ChargeItemFetcher } from './ClaimLineForm';

export interface ClaimTemplate {
  type: ClaimTypeCode;
  claimLines: ClaimLine[];
}

export interface ClaimLineColumn extends ClaimLine {
  unitPrice?: ChargeItemDefinition.Money;
}

export interface InitialClaimTemplate extends Omit<Partial<ClaimTemplate>, 'claimLines'> {
  claimLines: Partial<ClaimLineColumn>[];
  status?: Claim.ClaimStatus;
  invoiceNumber?: string;
}

export interface ClaimTemplateFormProps {
  initial?: InitialClaimTemplate;
  chargeItemFetcher: ChargeItemFetcher;
  onSubmit: (data: ClaimTemplate) => Promise<void>;
  onCancel?: () => void;
  hideCard?: boolean;
  title: string;
}

interface ListDataValue {
  isValid: boolean;
  hasSubmitted: boolean;
  data: Partial<ClaimLine>;
}

interface ListData {
  key: number | string;
  value: ListDataValue;
}

const createEmptyItem = (): ListData => ({
  key: Math.random(),
  value: {
    hasSubmitted: false,
    isValid: false,
    data: {
      quantity: 1,
    },
  },
});

type ManagedOutsideOfForm = undefined;

interface FormClaimTemplate extends Omit<ClaimTemplate, 'claimLines'> {
  claimLines: ManagedOutsideOfForm;
}

/**
 * Assumes that the input data is valid
 */
const getInitialClaimLines = (claimLines: Partial<ClaimLine>[] = []): ListData[] => {
  if (!claimLines.length) {
    return [createEmptyItem()];
  }

  return claimLines.map((line) => ({
    key: line.chargeItemIdentifier || '',
    value: {
      data: line,
      hasSubmitted: true,
      isValid: true,
    },
  }));
};

const editTypeFields: Field<FormClaimTemplate>[] = [
  {
    subfields: [
      {
        key: 'type',
        type: 'combobox-single',
        label: strings.ClaimTemplateFormLabelService,
        fetcher: mapDisplaysToOptions(claimTypeDisplay),
      },
    ],
  },
];

const viewTypeFields = (claimType: ClaimTypeCode): Field<FormClaimTemplate>[] => [
  {
    subfields: [
      {
        type: 'text-display',
        key: 'type',
        label: strings.ClaimTemplateFormLabelService,
        text: claimTypeDisplay[claimType],
      },
    ],
  },
];

type State = 'editing' | 'submitting' | 'item-error' | 'submission-error';

type ValidListDataValue = ListDataValue & { data: ClaimLine };

const areItemsValid = (items: Partial<ListDataValue>[]): items is ValidListDataValue[] =>
  items.every((item) => item.isValid);

const schema = yup.object<ObjectType<FormClaimTemplate>>({
  type: yup.string().required().equals(Object.values(ClaimTypeCode)),
});

export const ClaimTemplateForm: React.FC<ClaimTemplateFormProps> = ({
  initial,
  chargeItemFetcher,
  onSubmit,
  onCancel,
  hideCard = false,
  title,
}) => {
  const state = useFiniteState<State>('editing');

  const initialLines = useMemo(() => getInitialClaimLines(initial?.claimLines), [initial]);
  const lines = useList(initialLines);

  const items = lines.items.map((i) => i.value);
  const itemsValid = areItemsValid(items);

  const handleSubmit = useCallback(
    async ({ type }: FormClaimTemplate) => {
      if (!itemsValid) {
        state.set('item-error');
        return {};
      }

      state.set('submitting');
      try {
        await onSubmit({
          type,
          claimLines: items.map((i) => i.data),
        });
        return {};
      } catch (err) {
        state.set('submission-error');
        return { error: err };
      }
    },
    [items, itemsValid, onSubmit, state]
  );

  const hasError = state.is('submission-error') || state.is('item-error');
  const errorMessage = state.is('submission-error')
    ? strings.ClaimTemplateFormSubmissionError
    : strings.ClaimTemplateFormItemsError;

  const error = hasError ? errorMessage : undefined;
  const fields = initial?.type ? viewTypeFields(initial.type) : editTypeFields;

  return (
    <CrudForm<FormClaimTemplate>
      hasCard={!hideCard}
      title={hideCard ? undefined : title}
      fields={fields}
      initialValues={{ ...initial, claimLines: undefined }}
      validationSchema={schema}
      error={error}
      showErrors={hasError}
      onSubmit={handleSubmit}
      secondaryButtonText={strings.ButtonTextCancel}
      onSecondarySubmit={onCancel}
      showSecondarySubmitButton={!!onCancel}
    >
      {lines.items.map((item, index) => {
        const isLast = index === lines.items.length - 1;
        const canRemove = lines.items.length > 1;

        return (
          <ClaimLineForm
            key={item.key}
            chargeItemFetcher={chargeItemFetcher}
            onChange={(data, isValid) => {
              state.set('editing');

              lines.update(item.key, { ...item.value, data, isValid });
            }}
            onRemove={canRemove ? () => lines.remove(item.key) : undefined}
            onAdd={
              isLast
                ? () => {
                    const emptyItem = createEmptyItem();
                    lines.update(item.key, { ...item.value, hasSubmitted: true });
                    lines.add(emptyItem.key, emptyItem.value);
                  }
                : undefined
            }
            value={item.value.data}
            showErrors={item.value.hasSubmitted || hasError}
          />
        );
      })}
    </CrudForm>
  );
};
