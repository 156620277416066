import { GroupSecurity } from '@quromedical/fhir-common';
import {
  ChargeItemDefinition as FormData,
  ChargeItemDefinitionCard,
  InitialChargeItemDefinition,
  mapFormDataToChargeItemRequest,
} from 'core/claim';
import { Section, Skeleton, SkeletonProvider } from 'design-system';
import { useRevalidation } from 'hooks/useRevalidation';
import { useUserSession } from 'hooks/useUserSession';
import { administratorCodeFetcher, organizationSchemeCodeFetcher } from 'integration';
import { ChargeItemApi } from 'integration/aggregate';
import { ChargeItemStackScreenProps } from 'navigation';
import React, { useCallback } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { NotFoundScreen } from 'screens/NotFoundScreen';
import useSWR from 'swr';

const api = new ChargeItemApi();

export const ChargeItemScreen: React.FC<ChargeItemStackScreenProps<'View'>> = ({ route }) => {
  const { id } = route.params;

  const session = useUserSession();
  const canEdit = session.hasAny([GroupSecurity.ChargeItemDefinitionUpdate]);

  const fetcher = useCallback(() => api.getChargeItem(id), [id]);
  const swrKey = api.getSWRKey(id);

  const { data, isValidating, error } = useSWR(swrKey, fetcher);

  const revalidate = useRevalidation(api.getSWRKey());

  const handleSubmit = useCallback(
    async (data: FormData) => {
      const requestData = mapFormDataToChargeItemRequest(data);
      await api.updateChargeItem(id, requestData);
      await revalidate();
    },
    [id, revalidate]
  );

  if (error) {
    return <NotFoundScreen />;
  }

  const loading = isValidating || !data;

  const initial: InitialChargeItemDefinition | undefined = data
    ? {
        title: data.title,
        description: data.description,
        chargeLines: data.chargeLines.map((line) => {
          const identifier =
            line.applicability?.schemeCode || line.applicability?.administratorCode;

          return {
            amount: line.amount?.value,
            amountFactor: line.amount?.factor,
            currency: line.amount?.currency,
            chargeCode: line.code,
            type: line.applicability?.schemeCode ? 'scheme' : 'administrator',
            identifierCode: identifier,
            identifierDisplay: identifier,
          };
        }),
      }
    : undefined;

  return (
    <ScrollView>
      <SkeletonProvider loading={loading}>
        <Section unsetZIndex>
          <Skeleton>
            <ChargeItemDefinitionCard
              administratorFetcher={administratorCodeFetcher}
              canEdit={canEdit}
              schemeFetcher={organizationSchemeCodeFetcher}
              initial={initial}
              onSubmit={handleSubmit}
            />
          </Skeleton>
        </Section>
      </SkeletonProvider>
    </ScrollView>
  );
};
