import { ColumnType, DataTable } from 'components/table';
import { FormCard, Skeleton, SectionSpacer, Text, RowConfig } from 'design-system';
import { getMoneyDisplay } from 'helpers/money';
import { useFiniteState } from 'hooks/useFiniteState';
import compact from 'lodash.compact';
import { useCallback } from 'react';
import { strings } from 'strings';

import { ClaimLine } from './ClaimLineForm';
import {
  ClaimLineColumn,
  ClaimTemplateForm,
  ClaimTemplateFormProps,
  InitialClaimTemplate,
} from './ClaimTemplateForm';

interface ClaimTemplateCardProps extends ClaimTemplateFormProps {
  title: string;
  canEdit: boolean;
  showPrice?: boolean;
}

type CardState = 'initial' | 'form';

interface ClaimLineCalculatedColumn extends ClaimLineColumn {
  /**
   * Derived from the `quantity` and `unitPrice` if defined
   */
  totalPrice?: never;
}

const tableColumns = (showPrice = false): ColumnType<ClaimLineCalculatedColumn>[] => {
  const baseCols: ColumnType<ClaimLineCalculatedColumn>[] = [
    {
      accessor: 'chargeItemDisplay',
      header: strings.ClaimLineChargeItemLabel,
    } as ColumnType<ClaimLine, 'chargeItemDisplay'>,
    {
      accessor: 'quantity',
      header: strings.ClaimLineQuantityLabel,
    } as ColumnType<ClaimLine, 'quantity'>,
  ];

  if (!showPrice) {
    return baseCols;
  }

  return [
    ...baseCols,
    {
      accessor: 'unitPrice',
      header: strings.ChargeLineUnitPrice,
      Cell: ({ value }) => <Text>{value ? getMoneyDisplay(value, 1) : ''}</Text>,
    } as ColumnType<ClaimLineColumn, 'unitPrice'>,
    {
      accessor: 'totalPrice',
      header: strings.ChargeLineTotalPrice,
      Cell: ({ row }) => (
        <Text>
          {row.original.unitPrice
            ? getMoneyDisplay(row.original.unitPrice, row.original.quantity)
            : ''}
        </Text>
      ),
    },
  ];
};

const formRows = (initial?: InitialClaimTemplate): RowConfig[] => [
  {
    fields: compact([
      initial?.status
        ? {
            type: 'text',
            label: strings.LabelStatus,
            display: initial.status,
          }
        : undefined,
      initial?.invoiceNumber
        ? {
            type: 'text',
            label: strings.LabelInvoiceNumber,
            display: initial.invoiceNumber,
          }
        : undefined,
    ]),
  },
];

export const ClaimTemplateCard: React.FC<ClaimTemplateCardProps> = ({
  title,
  canEdit,
  initial,
  onSubmit,
  showPrice,
  ...rest
}) => {
  const state = useFiniteState<CardState>('initial');

  const handleSubmit = useCallback<ClaimTemplateCardProps['onSubmit']>(
    async (data) => {
      await onSubmit(data);
      state.set('initial');
    },
    [onSubmit, state]
  );

  if (state.is('form')) {
    return (
      <ClaimTemplateForm
        {...rest}
        title={title}
        onSubmit={handleSubmit}
        initial={initial}
        onCancel={state.next('initial')}
      />
    );
  }

  const columns = tableColumns(showPrice);
  const tableData: Partial<ClaimLineCalculatedColumn>[] = initial?.claimLines || [];

  const rows = formRows(initial);

  return (
    <>
      <Skeleton>
        <FormCard
          title={title}
          rows={rows}
          onButtonPress={state.next('form')}
          buttonText={strings.ButtonTextEdit}
          buttonIcon="edit"
          isButtonVisible={canEdit}
        />
      </Skeleton>

      <SectionSpacer />

      <Skeleton>
        <DataTable columns={columns} data={tableData} />
      </Skeleton>
    </>
  );
};
