import { Visible } from 'design-system';
import React, { ElementType, PropsWithChildren, ReactElement, useEffect } from 'react';
import {
  useTable,
  useSortBy,
  Column,
  Renderer,
  CellProps,
  TableState,
  FilterValue,
  useGlobalFilter,
  useAsyncDebounce,
  Row as RowType,
} from 'react-table';
import { Waypoint } from 'react-waypoint';

import * as T from '../Table';
import { BodyColumnConfig, DataTableBody } from './Body';
import { DataTableHeader, HeaderColumnConfig } from './Header';
import { PositionProps } from './PositionProps';

/**
 * Definition of a column configuration. Note that `Index` is the same as `Value`.
 * `Index` determines the type of the `value` prop passed to a `Cell` Renderer
 */
export interface ColumnType<
  Data extends object,
  Value extends keyof Data = keyof Data,
  Index extends keyof Data = Value
> {
  /**
   * This aligns to the internal react-table type system. We need to find a way to correctly extend
   * that datatype. Setting this to `false` will make a column sortable
   */
  disableSortBy?: boolean;
  accessor: Value;
  header: string;
  Cell?: Renderer<CellProps<Data, Data[Index]>>;
}

type OnEndReachedHandler = () => void;

type TableStateChange<Data extends object> = Partial<TableState<Data> & { rows: RowType<Data>[] }>;

export type OnTableStateChangedHandler<Data extends object> = (
  state: TableStateChange<Data>
) => void;

const noOp = () => undefined;

export interface DataTableProps<Data extends object> {
  columns: ColumnType<Data>[];
  columnConfig?: ColumnConfig<Data>;
  data: Data[];
  showLoading?: boolean;
  showHeader?: boolean;
  defaultColumn?: Partial<Column<Data>>;
  /**
   * Updating this trigger the table to update its filter state
   */
  filter?: FilterValue;
  /**
   * Will be called when the end of the table is scrolled into view. Ideally this
   * is when you should load more data and update the `data` prop
   */
  onEndReached?: OnEndReachedHandler;
  /**
   * Called via the `useEffect` hook whenever the `state` of the table instance
   * is changed
   */
  onTableStateChanged?: OnTableStateChangedHandler<Data>;
  Table?: ElementType;
  Header?: ElementType;
  HeaderRow?: ElementType<PositionProps>;
  HeaderCell?: ElementType<PositionProps>;
  Body?: ElementType;
  Row?: ElementType<PositionProps>;
  Cell?: ElementType<PositionProps>;
  LoadingSpinner?: ElementType;
  NoResultsPlaceholder?: ElementType;
  onRowPress?: (data: Data) => void;
  useCards?: boolean;
  debounceTime?: number;
}

export type ColumnConfig<Data extends object> = Partial<
  Record<keyof Data, HeaderColumnConfig & BodyColumnConfig>
>;

/**
 * A base layout for a table, implementing `react-table` while providing a stylable
 * interface for all rendered components as well as event-based handling for loading
 * and updating data and responding to changes of the table's inner sort or
 * filter state
 */
export const DataTable = <Data extends Object>({
  columns,
  data,
  showLoading = false,
  showHeader = true,
  defaultColumn,
  filter,
  onEndReached,
  onTableStateChanged = noOp,
  Table = T.Table,
  Header = T.Header,
  HeaderRow = T.HeaderRow,
  HeaderCell = T.HeaderCell,
  Body = T.Body,
  Row = T.FullPaddedRowWithBackgroundRow,
  Cell = T.Cell,
  LoadingSpinner,
  NoResultsPlaceholder,
  onRowPress,
  useCards,
  debounceTime = 300,
  columnConfig = {},
}: PropsWithChildren<DataTableProps<Data>>): ReactElement => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      data,
      columns,
      defaultColumn,
      manualSortBy: true,
      manualFilters: false,
      manualGlobalFilter: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      autoResetRowState: false,
      // we can't do a multi-sort like this on the backend
      maxMultiSortColCount: 1,
    },
    useGlobalFilter,
    useSortBy
  );

  const hasRows = rows.length > 0;
  const showNoResultsPlaceholder = !showLoading && !hasRows;

  const onTableStateChangedDebounced = useAsyncDebounce(onTableStateChanged, debounceTime);

  const setGlobalFilterDebounced = useAsyncDebounce(setGlobalFilter, debounceTime);

  useEffect(() => {
    onTableStateChangedDebounced({ sortBy, globalFilter, rows });
  }, [sortBy, globalFilter, onTableStateChangedDebounced, rows]);

  useEffect(() => {
    setGlobalFilterDebounced(filter);
  }, [filter, setGlobalFilterDebounced]);

  const shouldShowHeader = showHeader && !useCards;

  return (
    <Table {...getTableProps()}>
      <Visible if={shouldShowHeader}>
        <DataTableHeader<Data>
          headerGroups={headerGroups}
          Header={Header}
          Row={HeaderRow}
          Cell={HeaderCell}
          columnConfig={columnConfig}
        />
      </Visible>

      <DataTableBody<Data>
        rows={rows}
        getTableBodyProps={getTableBodyProps}
        prepareRow={prepareRow}
        Body={Body}
        Row={Row}
        Cell={Cell}
        isLoading={showLoading}
        onRowPress={onRowPress}
        useCards={useCards}
        columnConfig={columnConfig}
      />

      {showLoading && LoadingSpinner ? <LoadingSpinner /> : null}
      {onEndReached ? <Waypoint key={Date.now()} onEnter={onEndReached} /> : null}
      {showNoResultsPlaceholder && NoResultsPlaceholder ? <NoResultsPlaceholder /> : null}
    </Table>
  );
};
