import React from 'react';
import { Circle, G } from 'react-native-svg';

import { StopProps } from './LinearGradient';
import { usePlotDataContext } from './PlotDataProvider';
import { createGradientScale } from './resolveColor';
import { Datum, ExternalComponentProps } from './types';

interface PlotScatterProps {
  fill?: string;
  stops?: StopProps[];
  direction?: 'vertical' | 'horizontal';
  radius?: number;
  onPress?: (point: Datum) => void;
}

type ExternalProps = ExternalComponentProps & PlotScatterProps;

export const PlotScatter: React.FC<ExternalProps> = (props) => {
  const {
    xScale,
    yScale,
    fill,
    data,
    radius = 1,
    stops = [],
    direction = 'vertical',
    onPress,
  } = usePlotDataContext(props);

  const scaledPositions = data.map((d) => ({ x: xScale(d.x), y: yScale(d.y) }));

  const gradientScale = createGradientScale(direction, yScale, xScale, stops);

  return (
    <G>
      {scaledPositions.map((pos) => {
        const value = direction === 'vertical' ? pos.y : pos.x;
        const resolvedFill = stops.length ? gradientScale(value) : fill;

        const handlePress = () => onPress?.(pos);

        return (
          <Circle
            key={pos.x}
            cx={pos.x}
            cy={pos.y}
            r={radius}
            fill={resolvedFill || fill}
            onPress={handlePress}
          />
        );
      })}
    </G>
  );
};
