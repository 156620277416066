import { GroupSecurity } from '@quromedical/fhir-common';
import { Title } from 'components/card';
import { WithGroup } from 'core/auth';
import { RelayStatus, StatusCard } from 'core/device';
import { DeviceDeviceUseStatementList } from 'core/device-use-statement';
import { Alert, Section, Skeleton, SkeletonProvider, Snackbar, Text, Visible } from 'design-system';
import { useRevalidation } from 'hooks/useRevalidation';
import { DeviceApi } from 'integration/aggregate';
import { DeviceStackScreenProps } from 'navigation';
import React, { useCallback } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { strings } from 'strings';
import useSWR from 'swr';

import { DeviceCard } from './DeviceCard';
import { DeviceConfigure } from './DeviceConfigure';
import { DeviceMetricsList } from './DeviceMetricsList';

const api = new DeviceApi();

export const DeviceScreen: React.FC<DeviceStackScreenProps<'View'>> = ({ route }) => {
  const { id } = route.params;

  const fetcher = useCallback(() => api.getDevice(id), [id]);

  const swrKey = api.getDeviceSWRKey(id);
  const revalidate = useRevalidation(id);

  const { data, isValidating, error } = useSWR(swrKey, fetcher);

  return (
    <ScrollView>
      <SkeletonProvider loading={isValidating}>
        <Visible if={error}>
          <Snackbar onClose={revalidate} isOpen={!!error}>
            <Alert
              backgroundColor="status-critical"
              textColor="white"
              onAction={revalidate}
              actionIcon="close"
              title={strings.ErrorCardTitle}
              body={strings.GenericErrorMessage}
            />
          </Snackbar>
        </Visible>
        <Alert />
        <Section unsetZIndex>
          <Skeleton>
            <DeviceCard id={id} device={data} revalidate={revalidate} />
          </Skeleton>
        </Section>
        <WithGroup groups={[GroupSecurity.DeviceConfigure]}>
          <Section unsetZIndex>
            <DeviceConfigure inline deviceId={id} deviceCode={data?.type} revalidate={revalidate} />
          </Section>
        </WithGroup>
        <Section>
          <Skeleton>
            <RelayStatus deviceId={id} device={data} />
          </Skeleton>
        </Section>
        <Section>
          <StatusCard deviceId={id} />
        </Section>
        <Section hasBottomMargin>
          <Skeleton>
            <Title title="Associated Patients" />
            <DeviceDeviceUseStatementList deviceId={id} />
          </Skeleton>
        </Section>
        <WithGroup groups={[GroupSecurity.DeviceMetricRead]}>
          <Section>
            <Text>{strings.DeviceMetricListTitle}</Text>
          </Section>
          <Section>
            <DeviceMetricsList deviceId={id} />
          </Section>
        </WithGroup>
      </SkeletonProvider>
    </ScrollView>
  );
};
