import styled from '@emotion/native';
import { Col, Row } from 'components/base';
import { Visible, Text, Icon, IconName } from 'design-system/base';
import { BASE_SIZE, BORDER_WIDTH, MARGIN, PADDING } from 'design-system/theme';
import React from 'react';

import { SplineProps, Spline } from '../spline';
import { BackgroundWrapper } from './BackgroundWrapper';
import { MeasureBlock } from './MeasureBlock';
import { TitleWrapper } from './TitleWrapper';
import type { Measure } from './types';

export interface GraphProps extends SplineProps {
  icon?: IconName;
  title?: string;
  note?: string;
  summary?: Measure;
}

const GraphWrapper = styled(Row)({
  marginTop: MARGIN['2xs'],
});

const IconWrapper = styled(Col)({
  marginRight: MARGIN['3xs'],
});

const Wrapper = styled(Row)(({ theme }) => ({
  paddingBottom: PADDING['2xs'],
  borderWidth: 0,
  borderBottomWidth: BORDER_WIDTH[1],
  borderBottomColor: theme.color.divider,
}));

export const Graph: React.FC<GraphProps> = ({ icon, note, summary, title = '', ...graph }) => (
  <BackgroundWrapper backgroundColor={summary?.backgroundColor}>
    <Wrapper flex={1}>
      <Col flex={3}>
        <Visible if={!!title}>
          <TitleWrapper>
            <Row alignItems="center" justifyContent="space-between">
              <Row alignItems="center">
                <Visible if={icon}>
                  <IconWrapper>
                    <Icon name={icon} size={BASE_SIZE[12]} />
                  </IconWrapper>
                </Visible>
                <Text variant="dashboard-title">{title}</Text>
              </Row>
              <Col>
                <Text variant="dashboard-subtitle">{note}</Text>
              </Col>
            </Row>
          </TitleWrapper>
        </Visible>
        <GraphWrapper flex={1}>
          <Spline {...graph} />
        </GraphWrapper>
      </Col>
      <Col isVisible={!!summary} flex={1}>
        <MeasureBlock
          {...summary}
          valueVariant="dashboard-value-large"
          backgroundColor="transparent"
        />
      </Col>
    </Wrapper>
  </BackgroundWrapper>
);
