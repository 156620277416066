import styled from '@emotion/native';
import { Vitals } from '@quromedical/models';
import { Row } from 'components/base';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Card, PADDING, Text, ToggleCard } from 'design-system';
import { useCachedState } from 'hooks/useCachedState';
import { useFileUpload } from 'hooks/useFileUpload';
import { MediaApi, NotesApi } from 'integration/aggregate';
import React, { useCallback, useEffect, useState } from 'react';
import { strings } from 'strings';
import * as yup from 'yup';

import { createFields, initialValues, initialValuesRestoreSchema } from './fields';
import { mapFormToNote } from './mapping';
import { formSchema, isAssessmentNoteRequired, isPlanNoteRequired } from './schema';
import { FormData } from './types';

const api = new NotesApi();
const mediaApi = new MediaApi();

interface NoteFormProps {
  id: string;
  generalPractitioners?: fhir4.Reference[];
  latest: Vitals.LatestResultMeasures;
  vitalsLoading: boolean;
  onRefresh: () => void;
}

const FormWrapper = styled.View({
  paddingHorizontal: PADDING.m,
  paddingBottom: PADDING.s,
});

const requiredBoolean = yup.boolean().required();

export const NoteForm: React.FC<NoteFormProps> = ({
  id,
  latest,
  vitalsLoading,
  onRefresh,
  generalPractitioners,
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [showSuccess, setSuccess] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  // we need to keep track of the last submission as a key to refresh the form after submission
  const [lastSubmitted, setLastSubmitted] = useState(Date.now());

  const [formState, setFormState] = useCachedState(
    `${id}.NoteForm.formState`,
    initialValues,
    initialValuesRestoreSchema
  );
  const [showObjectiveNote, setObjectiveNote] = useCachedState(
    `${id}.NoteForm.showObjectiveNote`,
    false,
    requiredBoolean
  );
  const [showAssessmentNote, hasAssessmentNote] = useCachedState(
    `${id}.NoteForm.showAssessmentNote`,
    false,
    requiredBoolean
  );
  const [showPlanNote, hasPlanNote] = useCachedState(
    `${id}.NoteForm.showPlanNote`,
    false,
    requiredBoolean
  );

  const upload = useFileUpload((data) => mediaApi.createUploadUrl(id, data));

  const onChange = useCallback(
    (state: Partial<FormData>) => {
      setFormState(state);
      upload.onFilesChange(state);
    },
    [setFormState, upload]
  );

  const handleObjectiveAdd = useCallback(() => {
    setObjectiveNote(true);
  }, [setObjectiveNote]);

  const handleAssessmentAdd = useCallback(() => {
    hasAssessmentNote(true);
  }, [hasAssessmentNote]);

  const handlePlanAdd = useCallback(() => {
    hasPlanNote(true);
  }, [hasPlanNote]);

  const handleReset = useCallback(() => {
    setFormState(initialValues);
    setObjectiveNote(false);
    hasAssessmentNote(false);
    hasPlanNote(false);
    setError(undefined);
    setSubmitting(false);
    setLastSubmitted(Date.now());
  }, [hasAssessmentNote, hasPlanNote, setFormState, setObjectiveNote]);

  const handleSubmit = useCallback<SubmissionHandler<FormData>>(
    async (data: FormData) => {
      const note = mapFormToNote(data, latest, upload.media);

      try {
        setSubmitting(true);
        await api.createNote(id, note);
        handleReset();
        setSuccess(true);
        setError(undefined);
        onRefresh();
        return {};
      } catch (error) {
        setError(strings.SOAPNoteSubmissionErrorMessage);
        setSuccess(false);
        return { error };
      }

      setSubmitting(false);
    },
    [latest, id, handleReset, onRefresh, upload]
  );

  useEffect(() => {
    const shouldShowAssessmentField =
      formState.assessmentCode && isAssessmentNoteRequired(formState.assessmentCode);

    const shouldShowPlanField = formState.planCode && isPlanNoteRequired(formState.planCode);

    if (shouldShowAssessmentField) {
      hasAssessmentNote(true);
    } else if (!formState.assessment) {
      hasAssessmentNote(false);
    }

    if (shouldShowPlanField) {
      hasPlanNote(true);
    } else if (!formState.plan) {
      hasPlanNote(false);
    }

    // if the form state is changed we want to hide any messages sure on the screen
    setSuccess(false);
    setError(undefined);
  }, [formState, hasAssessmentNote, hasPlanNote]);

  const fields = createFields(
    latest,
    vitalsLoading,
    showObjectiveNote,
    showAssessmentNote,
    showPlanNote,
    handleObjectiveAdd,
    handleAssessmentAdd,
    handlePlanAdd,
    generalPractitioners,
    upload.progress,
    !upload.uploadsComplete
  );

  return (
    <Card paddingHorizontal={0} paddingVertical={0}>
      <ToggleCard
        title={strings.SOAPNoteCreateTitle}
        iconText="SOAP"
        blockColor="accent-green"
        textCollapse={strings.SOAPNoteButtonTextHide}
        textExpand={strings.SOAPNoteButtonTextCreateNote}
        contentWrapperProps={{ paddingHorizontal: 0, paddingVertical: 0 }}
      >
        <FormWrapper>
          <CrudForm
            key={lastSubmitted}
            disabled={isSubmitting}
            hasCard={false}
            fields={fields}
            initialValues={formState}
            onChangeDebounceTime={0}
            onChange={onChange}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
            includeIcons
          />
          <Row>
            {error ? <Text color="status-critical">{error}</Text> : null}
            {showSuccess ? (
              <Text color="status-success">{strings.SOAPNoteSubmissionSuccessMessage}</Text>
            ) : null}
          </Row>
        </FormWrapper>
      </ToggleCard>
    </Card>
  );
};
