import { Field, SelectOption } from 'components/types';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

export type Mode = 'custom' | 'latest';

export type Duration = '5m' | '15m' | '1h' | '6h' | '24h' | '1w' | '1m' | '3m' | '6m' | '1y';

const defaultDurations: Duration[] = ['5m', '15m', '1h', '6h', '24h'];

export interface LatestForm {
  duration: Duration;
}

export const latestFormSchema = yup.object<ObjectType<LatestForm>>({
  duration: yup.string().required(),
});

export interface CustomForm {
  from: string;
  duration: Duration;
}

export const customFormSchema = yup.object<ObjectType<CustomForm>>({
  duration: yup.string().required(),
  from: yup.string().required(strings.DurationErrorInvalidDate),
});

const durationOptions: SelectOption<never, Duration>[] = [
  {
    display: '5m',
    value: '5m',
  },
  {
    display: '15m',
    value: '15m',
  },
  {
    display: '1h',
    value: '1h',
  },
  {
    display: '6h',
    value: '6h',
  },
  {
    display: '24h',
    value: '24h',
  },
  {
    display: '1w',
    value: '1w',
  },
  {
    display: '1m',
    value: '1m',
  },
  {
    display: '3m',
    value: '3m',
  },
  {
    display: '6m',
    value: '6m',
  },
  {
    display: '1y',
    value: '1y',
  },
];

export const modeOptions: SelectOption<never, Mode>[] = [
  {
    display: strings.DurationLabelLatest,
    value: 'latest',
  },
  {
    display: strings.DurationLabelCustom,
    value: 'custom',
  },
];

const selectOptions = (options: Duration[]) =>
  durationOptions.filter((option) => options.includes(option.value));

export const getCustomFields = (options: Duration[] = defaultDurations): Field<CustomForm>[] => [
  {
    subfields: [
      {
        key: 'from',
        type: 'masked-text-box',
        maskType: 'datetime',
        label: strings.DurationLabelFrom,
      },
    ],
  },
  {
    subfields: [
      {
        key: 'duration',
        type: 'combobox-single',
        fetcher: selectOptions(options),
        label: strings.DurationLabelDuration,
      },
    ],
  },
];

export const getLatestFields = (options: Duration[] = defaultDurations): Field<LatestForm>[] => [
  {
    subfields: [
      {
        key: 'duration',
        type: 'combobox-single',
        fetcher: selectOptions(options),
        label: strings.DurationLabelDuration,
      },
    ],
  },
];
