import { Button } from 'design-system';
import React from 'react';

import { Body } from './Body';
import { Wrapper } from './Card';
import { Footer } from './Footer';
import { Section } from './Section';

interface MessageCardProps {
  message: string;
  additionalInformation?: string;
  onSubmit?: () => void;
  buttonText?: string;
}

export const MessageCard: React.FC<MessageCardProps> = ({
  message,
  additionalInformation,
  onSubmit,
  buttonText,
}) => (
  <Wrapper>
    <Body>
      <Section title={message} content={additionalInformation} />
    </Body>

    {onSubmit && buttonText ? (
      <Footer Right={() => <Button onPress={onSubmit} text={buttonText} />} />
    ) : undefined}
  </Wrapper>
);
