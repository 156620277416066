import { MeasurementName, Observation } from '@quromedical/models';
import { VitalObservationCreateForm } from 'core/observation';
import {
  getNEWS2Score,
  getStatusMeasurements,
  getOxygenSaturationScale,
  getNewsStatusCardMeasurement,
  getPlanStatusCardMeasurement,
  StatusMeasure,
  isMeasurementName,
} from 'core/vitals';
import { SkeletonProvider, StatusCard } from 'design-system';
import { useBoolean } from 'hooks/useBoolean';
import { useFiniteState } from 'hooks/useFiniteState';
import { useMergedLatestVitals } from 'hooks/useMergedLatestVitals';
import { usePatientActiveAdmission } from 'hooks/usePatientActiveAdmission';
import { ObservationApi } from 'integration/aggregate';
import compact from 'lodash.compact';
import { usePatientVitals } from 'providers/PatientVitalsProvider';
import React from 'react';
import { strings } from 'strings';

interface PatientVitalsSummaryCardProps {
  id: string;
}

type SelectedMeasure = MeasurementName | 'none';

export const getMeasurementKey = (key: StatusMeasure): SelectedMeasure => {
  const isMeasure = isMeasurementName(key);
  if (isMeasure) {
    return key;
  }

  return 'none';
};

const observationApi = new ObservationApi();

/**
 * Will display patient vitals data based on the  PatientVitalsProvider context
 */
export const PatientVitalsSummaryCard: React.FC<PatientVitalsSummaryCardProps> = ({ id }) => {
  const { isValidatingPatient, isValidatingVitals, onRefresh } = usePatientVitals();
  const isLoading = isValidatingPatient || isValidatingVitals;

  const admission = usePatientActiveAdmission(id);
  const latest = useMergedLatestVitals(id);

  const oxygenSaturationScale = getOxygenSaturationScale(admission?.oxygen);
  const newsScore = getNEWS2Score(latest, oxygenSaturationScale);

  const measurements = getStatusMeasurements({ latest, oxygenSaturationScale });

  const newsMeasure = getNewsStatusCardMeasurement(newsScore);

  const planMeasure = getPlanStatusCardMeasurement(admission);

  const combinedMeasures = compact([newsMeasure, ...measurements, planMeasure]);

  const selected = useFiniteState<SelectedMeasure>('none');
  const dialogOpen = useBoolean(false);

  const onSelect = (key: StatusMeasure) => {
    const resolved = getMeasurementKey(key);
    selected.set(resolved);
    dialogOpen.setTrue();
  };

  const onSubmit = async (measures: Observation.ObservationVitalMeasurement[]) => {
    await observationApi.createObservationVital(id, { measures });
    onRefresh();
    dialogOpen.setFalse();
  };
  return (
    <>
      <SkeletonProvider loading={isLoading}>
        <StatusCard
          onSelect={onSelect}
          title={strings.TitleVitalLatest}
          measurements={combinedMeasures}
        />
      </SkeletonProvider>
      {selected.value !== 'none' ? (
        <VitalObservationCreateForm
          measure={selected.value}
          isOpen={dialogOpen.value}
          onSubmit={onSubmit}
          onClose={dialogOpen.setFalse}
        />
      ) : null}
    </>
  );
};
