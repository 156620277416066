import styled from '@emotion/native';
import { Row } from 'components/base';
import { BASE_SIZE, MARGIN } from 'design-system';
import { Button, IconName, Visible } from 'design-system/base';
import { Section } from 'design-system/layout';
import { useIsBiggerThan } from 'hooks/useResponsive';
import { usePatientVitals } from 'providers/PatientVitalsProvider';
import React from 'react';

import { PatientBreadcrumb } from './PatientBreadcrumb';
import { PatientVitalsSummaryCard } from './PatientVitalsSummaryCard';

interface ActionProps {
  icon: IconName;
  onPress: () => void;
}

interface PatientHeaderSectionProps {
  id: string;
  action?: ActionProps;
}

const ReducedSection = styled(Section)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  // setting a height so that the bar size is consistent with or without a button
  height: BASE_SIZE[32],
  // individually set so as to override the `marginVertical` from the section
  marginTop: MARGIN['2xs'],
  marginBottom: MARGIN.xs,
});

export const PatientHeaderSection: React.FC<PatientHeaderSectionProps> = ({ action, id }) => {
  const isBig = useIsBiggerThan('lg');
  const patients = usePatientVitals();

  const patient = patients.patient?.general;

  return (
    <>
      <Visible if={isBig}>
        <ReducedSection>
          <PatientBreadcrumb id={id} patient={patient} />
          {action ? (
            <Row>
              <Button onPress={action.onPress} icon={action.icon} variant="flat" />
            </Row>
          ) : null}
        </ReducedSection>
      </Visible>

      <Section hasTopMargin={false}>
        <PatientVitalsSummaryCard id={id} />
      </Section>
    </>
  );
};
