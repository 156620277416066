import { ChargeItemDefinition } from '@quromedical/models';
import { LinkButton } from 'components/input';
import { ColumnType } from 'components/table';
import {
  ChargeItemDefinition as FormData,
  ChargeItemDefinitionForm,
  mapFormDataToChargeItemRequest,
} from 'core/claim';
import { PaginatedTable } from 'core/table';
import { Section, ToggleCard } from 'design-system';
import { useRevalidation } from 'hooks/useRevalidation';
import { administratorCodeFetcher, organizationSchemeCodeFetcher } from 'integration';
import { ChargeItemApi } from 'integration/aggregate';
import { GroupStackScreenProps } from 'navigation';
import React, { useCallback } from 'react';
import { ScrollView } from 'react-native';
import { strings } from 'strings';

const api = new ChargeItemApi();

const columns: ColumnType<ChargeItemDefinition.GetResponse>[] = [
  {
    accessor: 'title',
    header: strings.ChargeItemDefinitionFormLabelName,
  },
  {
    accessor: 'description',
    header: strings.ChargeItemDefinitionFormLabelDescription,
  },
  {
    accessor: 'id',
    header: '',
    Cell: ({ value }) => (
      <LinkButton
        text={strings.ChargeItemDefinitionViewTitle}
        to={{
          screen: 'Admin',
          params: {
            screen: 'ChargeItem',
            params: {
              screen: 'View',
              params: {
                id: value,
              },
            },
          },
        }}
      />
    ),
  } as ColumnType<ChargeItemDefinition.GetResponse, 'id'>,
];

export const ChargeItemListScreen: React.FC<GroupStackScreenProps<'List'>> = () => {
  const baseSWRKey = 'charge-item-list';

  const revalidate = useRevalidation(baseSWRKey);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      const chargeItem = mapFormDataToChargeItemRequest(data);

      await api.createChargeItem(chargeItem);

      await revalidate();
    },
    [revalidate]
  );

  return (
    <ScrollView>
      <Section unsetZIndex>
        <ToggleCard
          title={strings.ChargeItemDefinitionFormCreateTitle}
          icon="attach-money"
          textExpand={strings.LabelExpand}
          textCollapse={strings.LabelCollapse}
        >
          <ChargeItemDefinitionForm
            hideCard
            schemeFetcher={organizationSchemeCodeFetcher}
            administratorFetcher={administratorCodeFetcher}
            onSubmit={handleSubmit}
          />
        </ToggleCard>
      </Section>
      <Section>
        <PaginatedTable
          cacheKey={baseSWRKey}
          fetcher={(pageToken?: string, count?: number) =>
            api.getChargeItems({
              count,
              pageToken,
            })
          }
          columns={columns}
          getDataFromResponse={(res) => res.chargeItems}
          canChangeCount
        />
      </Section>
    </ScrollView>
  );
};
