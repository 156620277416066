import { ColorName } from '@emotion/react';
import { DeepPartial, MeasurementName, Notes } from '@quromedical/models';
import { getNotePlanDisplay } from 'core/display';
import { getStatusMeasurements } from 'core/vitals';
import {
  CommentDisplay,
  Note,
  NoteAction,
  NoteCard as Base,
  TagConfig,
  NestedComment,
  Measurement,
} from 'design-system/components';
import compact from 'lodash.compact';
import React from 'react';

import { codeAssessment, codeDisplay, codeLocation, codePlan } from './record';

export { codeDisplay, codeLocation, codeAssessment, codePlan } from './record';

interface NoteCardProps {
  note: Notes.Note;
  onAttachmentPress: (id: string) => Promise<void>;
  onPin?: () => void;
  canPin: boolean;
}

type DeepPartialStructuredData = DeepPartial<Notes.StructuredNoteNode>;

const flattenComments = (node: DeepPartialStructuredData): Partial<Notes.StructuredNoteItem>[] => {
  if (!node) {
    return [];
  }

  if (node.type === 'comment') {
    return [node];
  }

  if (node.type === 'group') {
    if (!node.children?.length) {
      return [];
    }

    return compact(node.children).map(flattenComments).flat();
  }

  return [];
};

const mapNoteItemToComment = (
  note: Partial<Notes.StructuredNoteItem>
): Partial<NestedComment.CommentWithPath> => ({
  ...note,
  color: note.color as ColorName,
});

const displayStructuredContent = (content?: Notes.PartialStructuredContent): Note[] | undefined => {
  const notes = content?.data;

  if (!notes?.length) {
    return undefined;
  }

  return notes.map((note) => {
    const comments = flattenComments(note).map(mapNoteItemToComment);

    return {
      title: note.display,
      body: note.type === 'group' ? <CommentDisplay comments={[comments]} /> : undefined,
    };
  });
};

const isFullMeasure = (measure: Partial<Measurement>): measure is Measurement =>
  measure.value !== undefined;

export const NoteCard: React.FC<NoteCardProps> = ({ note, onAttachmentPress, onPin, canPin }) => {
  const createdBy = note.assessor?.display;
  const date = note.dateCreated ? new Date(note.dateCreated) : undefined;

  const statusMeasurements = getStatusMeasurements({
    latest: note.vitals,
    additionalMeasures: [MeasurementName.Posture],
  }).filter((v) => v.hasValue);

  const measurements = statusMeasurements
    .map<Partial<Measurement>>((measure) => ({
      value: measure.value,
      name: measure.name,
      unit: measure.unit,
    }))
    .filter(isFullMeasure);

  const attachments = note.media.map((attachment) => ({
    name: attachment.title || '',
    onDownloadPress: () => onAttachmentPress(attachment.id),
  }));

  const code: TagConfig | undefined = note.code && {
    color: 'accent-blue',
    text: codeDisplay[note.code],
  };

  const location: TagConfig | undefined = note.locationCode && {
    color: 'accent-green',
    text: codeLocation[note.locationCode],
  };

  const serviceDisplay = getNotePlanDisplay(
    note.dateCreated,
    note.admission?.service,
    note.admission?.period
  );
  const service: TagConfig | undefined = serviceDisplay
    ? {
        color: 'accent-purple',
        text: serviceDisplay,
      }
    : undefined;

  const tags: TagConfig[] = compact([code, location, service]);

  const notes: Note[] = compact([
    note.note.subjective
      ? {
          body: note.note.subjective,
        }
      : undefined,
    {
      body: note.note.objective,
      measurements,
    },
    {
      title: note.note.assessmentCode && codeAssessment[note.note.assessmentCode],
      body: note.note.assessment,
    },
    {
      title: note.note.planCode && codePlan[note.note.planCode],
      body: note.note.plan,
    },
  ]);

  const structuredNote = displayStructuredContent(note.structuredContent);

  const resolvedNote: Note[] = structuredNote
    ? [
        {
          measurements,
        },
        ...structuredNote,
      ]
    : notes;

  const action: NoteAction | undefined =
    canPin && onPin
      ? {
          icon: note.pinned ? 'star' : 'star-outline',
          color: note.pinned ? 'accent-yellow' : undefined,
          onPress: onPin,
        }
      : undefined;

  return (
    <Base
      createdBy={createdBy}
      date={date}
      tags={tags}
      attachments={attachments}
      notes={resolvedNote}
      action={action}
    />
  );
};
