import styled from '@emotion/native';
import { Row } from 'components/base';
import { Text } from 'design-system/base';
import { PADDING } from 'design-system/theme';
import React from 'react';

const Wrapper = styled(Row)({
  height: 0,
  overflow: 'visible',
  opacity: 0.7,
});

const TextWrapper = styled(Row)({
  paddingVertical: PADDING['2xs'],
  paddingHorizontal: PADDING.xs,
});

interface PlaceholderProps {
  placeholder: string;
  value: string;
}

const extractRemainder = (placeholder: string, value: string): string =>
  placeholder.split('').slice(value.length).join('');

export const Placeholder: React.FC<PlaceholderProps> = ({ value, placeholder }) => {
  const remainder = extractRemainder(placeholder, value);

  return (
    <Wrapper>
      <TextWrapper>
        <Text color="transparent">{value}</Text>
        <Text color="text-disabled">{remainder}</Text>
      </TextWrapper>
    </Wrapper>
  );
};
