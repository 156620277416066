import { ColorName } from '@emotion/react';
import * as Graph from 'components/graph';
import { FONT_FAMILY, FONT_SIZE, useTheme } from 'design-system/theme';
import React from 'react';

export interface StatusPlotProps {
  layoutWidth: number;
  bounds?: StatusBarBound[];
  domain?: Graph.Domain;
  value?: number;
  boundPreference?: BoundPreference;
  trackColor?: ColorName;
  strokeWidth?: number;
  activeStrokeWidth?: number;
  valueText?: string;
}

export interface StatusBarBound {
  start: number;
  end: number;
  color: ColorName;
}

const margin = {
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
};

type BoundPreference = 'both' | 'start' | 'end';
type BoundChecker = (bound: StatusBarBound, value: number) => boolean;

const boundChecker: Record<BoundPreference, BoundChecker> = {
  both: (bound: StatusBarBound, value: number) => value >= bound.start && value <= bound.end,
  end: (bound: StatusBarBound, value: number) => value >= bound.start && value < bound.end,
  start: (bound: StatusBarBound, value: number) => value > bound.start && value <= bound.end,
};

const isPastMidpoint = (domain: Graph.Domain, value?: number) => {
  if (typeof value === 'undefined') {
    return false;
  }

  return value > Graph.mean(domain);
};

export const StatusPlot: React.FC<StatusPlotProps> = ({
  layoutWidth,
  bounds = [],
  domain = [0, 1],
  trackColor = 'divider',
  value,
  boundPreference: boundCondition = 'both',
  valueText: text,
}) => {
  const theme = useTheme();

  const isInBound = boundChecker[boundCondition];
  const hasValue = typeof value !== 'undefined';

  const baseHeight = 28;
  const aspectRatio = layoutWidth / baseHeight;

  const strokeWidth = 2;
  const activeStrokeWidth = 6;

  const strokeTop = baseHeight - 4;

  const activeStrokeDiff = activeStrokeWidth - strokeWidth;

  // flip text if we're close to the end so that we don't run off the SVG
  // domain is always defined so the mean should be as well
  const pastMidpoint = isPastMidpoint(domain, value);

  const textOffsetAmount = 4;
  const textOffset = pastMidpoint ? -textOffsetAmount : textOffsetAmount;

  const textAnchor = pastMidpoint ? 'end' : 'start';

  return (
    <Graph.LinearPlot
      flex={1}
      baseWidth={layoutWidth}
      baseHeight={baseHeight}
      aspectRatio={aspectRatio}
      yDomain={[0, baseHeight]}
      xDomain={domain}
      margin={margin}
    >
      <Graph.YLine yVal={strokeTop} stroke={theme.color[trackColor]} strokeWidth={strokeWidth} />

      {bounds.map((bound) => {
        const inBound = hasValue && isInBound(bound, value);
        const resolvedStrokeWidth = inBound ? activeStrokeWidth : strokeWidth;
        const resolvedYVal = inBound ? strokeTop - activeStrokeDiff / 2 : strokeTop;

        const resolvedColor = theme.color[bound.color];
        const resolvedOpacity = inBound ? 1 : 0.5;

        const key = `${bound.color}-${bound.start}-${bound.end}`;

        return (
          <Graph.YLine
            key={key}
            yVal={resolvedYVal}
            xStart={bound.start}
            xEnd={bound.end}
            strokeWidth={resolvedStrokeWidth}
            stroke={resolvedColor}
            opacity={resolvedOpacity}
          />
        );
      })}

      {typeof value !== 'undefined' ? (
        <>
          <Graph.XLine
            xVal={value}
            strokeWidth={strokeWidth}
            stroke={theme.color['text-default']}
          />
          <Graph.Text
            text={text}
            x={value}
            y={4}
            fontSize={FONT_SIZE.captionSmall}
            fontFamily={FONT_FAMILY.regular}
            xOffset={textOffset}
            textAnchor={textAnchor}
            color={theme.color['text-default']}
            alignmentBaseline="bottom"
          />
        </>
      ) : null}
    </Graph.LinearPlot>
  );
};
