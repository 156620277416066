import { CountryCode } from '@quromedical/fhir-common';
import { Person, Validations } from '@quromedical/models';
import { Field } from 'components/types';
import { prefixRow, nameRow, genderRow, createPersonContactFields } from 'core/forms';
import { Fetcher } from 'design-system';

/**
 * Stub type for merging person data into a single object
 */
export type JointFields = Person.General & Omit<Person.Contact, 'addressCoordinates'>;

/**
 * Get the resolved contact form fields based on the current form state
 */
export const resolveFormNextOfKinFields = (
  formState: Partial<Person.Contact>,
  addressFetcher?: Fetcher<string>
): Field<JointFields>[] => {
  const isPrimaryValid = Validations.phoneSchema.isValidSync(formState.telecomPhoneNumbers?.[0]);

  // the secondary number may be empty or match the required format
  const isSecondaryEmpty = !formState.telecomPhoneNumbers?.[1];
  const isSecondaryValid =
    isSecondaryEmpty || Validations.phoneSchema.isValidSync(formState.telecomPhoneNumbers?.[1]);

  const contactDetailsRows = createPersonContactFields(
    isPrimaryValid,
    isSecondaryValid,
    addressFetcher
  );
  return [prefixRow, nameRow, genderRow, ...contactDetailsRows];
};

export const initialNextOfKin: Partial<JointFields> = {
  telecomPhoneNumbers: ['', ''],
  addressLines: ['', ''],
  addressCountry: CountryCode.SouthAfrica,
};
