import { Row, Col } from 'components/base';
import {
  Spacer,
  Text,
  ExternalLinkButton,
  Grid,
  AppleAppStoreLink,
  GooglePlayStoreLink,
  BASE_SIZE,
  Section,
} from 'design-system';
import { strings } from 'strings';

export const AuthBottomLinks: React.FC = () => (
  <Col justifyContent="center" alignItems="center">
    <Section>
      <Text variant="page-heading">{strings.TitleNewToQuroMedical}</Text>
    </Section>
    <Text variant="caption">{strings.TitleRegisterNow}</Text>
    <Section>
      <Grid gap="s" sm={2}>
        <ExternalLinkButton
          icon="person-outline"
          titlePrefix={strings.CardTitleIAmNew}
          title={strings.TitlePatient}
          buttonText={strings.ButtonTextLearnMore}
          link="https://quromedical.co.za/services/for-patients"
        />
        <ExternalLinkButton
          icon="stethoscope"
          titlePrefix={strings.CardTitleIAmNew}
          title={strings.TitlePractitioner}
          buttonText={strings.ButtonTextRegister}
          link="https://quromedical.co.za/services/for-doctors"
        />
      </Grid>
    </Section>
    <Section justifyContent="center" alignItems="center">
      <Text variant="caption">{strings.TitleDownloadOurApp}</Text>
      <Spacer height={BASE_SIZE[16]} />
      <Row>
        <AppleAppStoreLink />
        <Spacer width={BASE_SIZE[16]} />
        <GooglePlayStoreLink />
      </Row>
    </Section>
  </Col>
);
