import styled from '@emotion/native';
import { Col } from 'components/base';
import { Skeleton, SkeletonProvider, Button } from 'design-system/base';
import { logger } from 'helpers';
import { handleDownload } from 'helpers/download';
import { useBoolean } from 'hooks/useBoolean';
import React, { useCallback } from 'react';
import { strings } from 'strings';

import { Dialog } from '../dialog';
import { DownloadButton, Renderer } from './renderer';
import { MediaPreviewProps } from './types';
import { useDownloadUrl } from './useDownloadUrl';

const Placeholder = styled.View({
  width: '100%',
  height: 200,
});

export const MediaPreview: React.FC<MediaPreviewProps> = ({ id, getUrl, type, title }) => {
  const open = useBoolean(false);

  // media link should only be fetched if the dialog is open
  const { loading, url } = useDownloadUrl(id, getUrl, open.value);

  const onDownloadPress = useCallback(async () => {
    if (!url) {
      return;
    }

    try {
      await handleDownload(url);
    } catch (error) {
      // this is unlikely to happen, but logging to handle potential native edge cases
      logger.error(error);
    }
  }, [url]);

  if (!type) {
    return <DownloadButton getUrl={getUrl} />;
  }

  const FileRenderer = Renderer[type];

  return (
    <>
      <Dialog
        borderColor="accent-green"
        maxSize="xxl"
        isOpen={open.value}
        onRequestClose={open.setFalse}
        secondary={{
          text: strings.ButtonTextClose,
          onPress: open.setFalse,
        }}
        primary={{
          disabled: loading,
          icon: 'file-download',
          onPress: onDownloadPress,
          text: strings.MediaDownloadButtonText,
        }}
        title={title}
      >
        <SkeletonProvider loading={loading}>
          <Col alignItems="center">
            {url ? (
              <FileRenderer title={title} url={url} />
            ) : (
              <Skeleton>
                <Placeholder />
              </Skeleton>
            )}
          </Col>
        </SkeletonProvider>
      </Dialog>

      <Button onPress={open.setTrue} text={strings.MediaViewButtonText} icon="attach-file" />
    </>
  );
};
