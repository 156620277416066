import { Person } from '@quromedical/models';
import {
  getAddressDetailsRows,
  getContactDetailsWithoutAddressRows,
  getFullPersonalDetailsRows,
  getMinimalPersonalDetailsRows,
} from 'core/forms';
import { FormCard } from 'design-system';
import { strings } from 'strings';

interface ContactCardProps {
  data?: Partial<Person.ContactWithAddressEmergencyAccess>;
  canEdit: boolean;
  hasEmergencyAccess: boolean;
  onEditPress: () => void;
}
interface GeneralCardProps {
  data?: Partial<Person.General>;
  canEdit: boolean;
  minimalData: boolean;
  onEditPress: () => void;
}

export const ContactCard: React.FC<ContactCardProps> = ({
  data,
  canEdit,
  onEditPress,
  hasEmergencyAccess = false,
}) => {
  const rows = getContactDetailsWithoutAddressRows(data, hasEmergencyAccess);

  return (
    <FormCard
      isButtonVisible={canEdit}
      onButtonPress={onEditPress}
      buttonText={strings.ButtonTextEdit}
      buttonIcon="edit"
      showIcons
      title={strings.FormLabelPersonContactDetails}
      rows={rows}
    />
  );
};

export const GeneralCard: React.FC<GeneralCardProps> = ({
  data,
  canEdit,
  minimalData = false,
  onEditPress,
}) => {
  const rows = minimalData ? getMinimalPersonalDetailsRows(data) : getFullPersonalDetailsRows(data);

  return (
    <FormCard
      isButtonVisible={canEdit}
      onButtonPress={onEditPress}
      buttonText={strings.ButtonTextEdit}
      buttonIcon="edit"
      showIcons
      title={strings.FormLabelPersonDetails}
      rows={rows}
    />
  );
};

interface AddressCardProps {
  data?: Partial<Person.Contact>;
  isButtonDisabled: boolean;
  onMapsPress?: () => void;
}
export const AddressCard: React.FC<AddressCardProps> = ({
  data,
  isButtonDisabled,
  onMapsPress,
}) => {
  const rows = getAddressDetailsRows(data);
  const isButtonTextDisabled = isButtonDisabled
    ? strings.ButtonTextNoMapLink
    : strings.ButtonTextOpenMaps;
  return (
    <FormCard
      buttonVariant="contained"
      onButtonPress={onMapsPress}
      buttonText={isButtonTextDisabled}
      title={strings.TitleAddressDetails}
      buttonIcon="directions"
      isDisabled={isButtonDisabled}
      showIcons
      rows={rows}
    />
  );
};
