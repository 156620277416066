import { Organization } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import { compact } from 'lodash';

export enum OrganizationEndpoint {
  Base = '',
  GeneralOrganization = 'general',
  ContactOrganization = 'contact',
  Insurance = 'insurance',
}
export class OrganizationApi {
  private readonly client: BaseApi;
  private readonly root = 'api/aggregate/organization';

  constructor() {
    this.client = new BaseApi();
  }

  private createBasePath(endpoint: OrganizationEndpoint): string {
    return compact([this.root, endpoint]).join('/');
  }

  private createIdPath(organizationId?: string, endpoint?: string) {
    if (!organizationId) {
      return this.root;
    }
    const basePath = `${this.root}/${organizationId}`;

    return compact([basePath, endpoint]).join('/');
  }

  public getOrganizationSWRKey = (practitionerId: string, endpoint: OrganizationEndpoint): string =>
    this.createIdPath(practitionerId, endpoint);

  public async getOrganization(id: string): Promise<Organization.GetResponse> {
    const path = this.createIdPath(id, OrganizationEndpoint.Base);
    const result = await this.client.get<Organization.GetResponse>(path);

    return result.data;
  }

  public async getOrganizations(
    params: Organization.GetAllParams
  ): Promise<Organization.GetAllResponse> {
    const result = await this.client.get<Organization.GetAllResponse>(this.root, { params });

    return result.data;
  }

  public async createOrganization(
    organization: Organization.CreateRequest
  ): Promise<Organization.CreateResponse> {
    const result = await this.client.post<Organization.CreateResponse>(this.root, organization);

    return result.data;
  }

  public async updateOrganization(
    id: string,
    organization: Organization.UpdateRequest
  ): Promise<Organization.UpdateResponse> {
    const result = await this.client.put<Organization.UpdateResponse>(
      this.createIdPath(id),
      organization
    );

    return result.data;
  }

  public async updateOrganizationContact(
    practitionerId: string,
    body: Partial<Organization.ContactUpdateRequest>
  ): Promise<Organization.ContactUpdateResponse> {
    const path = this.createIdPath(practitionerId, OrganizationEndpoint.ContactOrganization);
    const result = await this.client.put<Organization.ContactUpdateResponse>(path, body);

    return result.data;
  }

  public async updateOrganizationGeneral(
    practitionerId: string,
    body: Organization.General
  ): Promise<Organization.UpdateResponse> {
    const path = this.createIdPath(practitionerId, OrganizationEndpoint.GeneralOrganization);
    const result = await this.client.put<Organization.UpdateResponse>(path, body);

    return result.data;
  }

  public async getInsuranceOrganizations(
    params?: Organization.GetAllInsuranceOrganizationParams
  ): Promise<Organization.GetAllInsuranceOrganizationsResponse> {
    const path = this.createBasePath(OrganizationEndpoint.Insurance);
    const result = await this.client.get(path, {
      params,
    });

    return result.data;
  }
}
