import { Vitals } from '@quromedical/models';
import { mergeLatestMeasuresByTime, usePatientLiveData } from 'integration/live-vitals';
import { usePatientVitals } from 'providers/PatientVitalsProvider';

/**
 * Utility hook that merges websocket and latest vitals by time, useful in order to get a summary
 * view of vitals data for a patient that always reflects the latest state of the data at an
 * application level
 *
 * Uses `usePatientLiveData` and `usePatientVitals` to resolve the resulting data
 */
export const useMergedLatestVitals = (
  patientId: string,
  includeStale = false
): Partial<Record<Vitals.MeasurementName, Vitals.BaseTimeSeriesRecord>> => {
  const { latestFiltered } = usePatientVitals();
  const { latest: latestWebsocketVitals } = usePatientLiveData(patientId, includeStale);

  return mergeLatestMeasuresByTime<Vitals.BaseTimeSeriesRecord>(
    latestFiltered,
    latestWebsocketVitals
  );
};
