import { GroupSecurity } from '@quromedical/fhir-common';
import { PaginatedTable } from 'core/table';
import { getDataFromResponseCompat } from 'hooks/useFhirDataInfinite';
import { useUserSession } from 'hooks/useUserSession';
import { DeviceUseStatementApi } from 'integration';
import React, { useCallback } from 'react';

import { patientDeviceUseStatementColumns } from './columns';
import { mapFhirToTable } from './mapping';

const deviceUseStatementApi = new DeviceUseStatementApi();

interface PatientDeviceUseStatementListProps {
  patientId: string;
}

export const PatientDeviceUseStatementList: React.FC<PatientDeviceUseStatementListProps> = ({
  patientId,
}) => {
  const deviceUseStatementFetcher = useCallback(
    (pageToken?: string) =>
      deviceUseStatementApi.getDeviceUseStatements({
        subject: patientId,
        pageToken,
      }),
    [patientId]
  );

  const session = useUserSession();
  const canUpdate = session.hasAny([GroupSecurity.DeviceUseStatementUpdate]);
  const canCalibrate = session.hasAny([GroupSecurity.DeviceConfigure]);
  const columns = patientDeviceUseStatementColumns(canUpdate, canCalibrate);

  return (
    <PaginatedTable
      cacheKey={`DeviceUseStatement${patientId}`}
      fetcher={deviceUseStatementFetcher}
      columns={columns}
      getDataFromResponse={getDataFromResponseCompat(mapFhirToTable)}
    />
  );
};
