import { Row } from 'components/base';
import { useToggleSelection } from 'hooks/useToggleSelection';
import React, { ReactElement } from 'react';

import { Legend, LegendProps } from './Legend';

export type FooterLegendProps<TCode extends string> = Omit<
  LegendProps<TCode>,
  'onClick' | 'selected'
>;

export interface LegendSectionProps<TCode extends string> {
  legend?: FooterLegendProps<TCode>[];
  initialSelection?: TCode;
  onSelect?: (code?: TCode) => void;
}

export const LegendSection = <TLegendCode extends string>({
  legend = [],
  initialSelection,
  onSelect,
}: LegendSectionProps<TLegendCode>): ReactElement => {
  const [selectedCode, onLegendClick] = useToggleSelection<TLegendCode>(initialSelection, onSelect);

  const noSelection = selectedCode === undefined;

  return (
    <Row alignItems="center" flexWrap="wrap">
      {legend.map((props) => {
        const selected = noSelection || props.identifier === selectedCode;
        return (
          <Legend<TLegendCode>
            key={props.identifier}
            {...props}
            onClick={onLegendClick}
            selected={selected}
          />
        );
      })}
    </Row>
  );
};
