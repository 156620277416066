import { DeliveryMethod } from '@quromedical/auth';
import { useAuthentication } from 'hooks';
import { useCallback, useState } from 'react';
import { strings } from 'strings';

import { AuthWrapper } from './AuthWrapper';
import { DeliveryOptions } from './DeliveryOptions';
import { AuthenticationStepProps } from './types';

export const AuthDeliveryMethod: React.FC<AuthenticationStepProps> = (props) => {
  const { onNext, authState } = props;
  const { mode: value, username } = authState;
  const [mode, setMode] = useState<DeliveryMethod>(value);
  const { startAuthentication, isLoading, error } = useAuthentication();

  const onSubmit = useCallback(() => {
    if (!mode) {
      return;
    }

    startAuthentication({
      data: {
        mode,
        username,
      },
      onComplete: (details) => onNext({ mode, display: details.username, field: details.field }),
    });
  }, [mode, startAuthentication, onNext, username]);

  return (
    <AuthWrapper
      heading={strings.AuthDeliveryMethodHeading}
      subheading={strings.AuthDeliveryMethodSubheading}
      error={error}
    >
      <DeliveryOptions onChange={setMode} onSubmit={onSubmit} value={value} isLoading={isLoading} />
    </AuthWrapper>
  );
};
