import { AlertButton } from 'design-system';
import { setStringAsync } from 'expo-clipboard';
import React from 'react';
import { strings } from 'strings';

interface CopyInviteButtonProps {
  meetingLink: string;
}

export const CopyInviteButton: React.FC<CopyInviteButtonProps> = ({ meetingLink }) => {
  const onPress = async () => {
    await setStringAsync(meetingLink);
  };

  return (
    <AlertButton
      variant="flat-alt"
      icon="content-copy"
      text={strings.ButtonTextCopyConsultLink}
      success={{
        title: strings.AlertSuccess,
        body: strings.AlertCopySuccess,
      }}
      error={{
        title: strings.AlertError,
        body: strings.AlertCopyError,
      }}
      onPress={onPress}
    />
  );
};
