import { DeviceUseStatementCode } from '@quromedical/fhir-common';
import { DeviceUseStatement } from 'fhir/r4';
import orderBy from 'lodash.orderby';
import { strings } from 'strings';

export const deviceUseStatementCodeDisplayMap: Record<DeviceUseStatementCode, string> = {
  [DeviceUseStatementCode.patch]: strings.DisplayDeviceTypePatch,
  [DeviceUseStatementCode.relay]: strings.DisplayDeviceTypeRelay,
  [DeviceUseStatementCode.glucose]: strings.DisplayDeviceTypeGlucose,
  [DeviceUseStatementCode.bloodPressure]: strings.DisplayDeviceTypeBloodPressure,
};

export const orderDeviceUseStatementsByTimeStart = (
  deviceUseStatements: DeviceUseStatement[]
): DeviceUseStatement[] =>
  orderBy(
    deviceUseStatements,
    [(dus) => new Date(dus.timingPeriod?.start || '').getTime()],
    'desc'
  );

function getDeviceUseStatementCode(deviceUseStatement: fhir4.DeviceUseStatement) {
  const { reasonCode } = deviceUseStatement;
  const code = reasonCode && reasonCode[0];

  return code?.coding && code.coding?.length > 0
    ? (code.coding[0].code as DeviceUseStatementCode)
    : undefined;
}

export const getDeviceUseStatementTypeDisplayName = (
  type?: DeviceUseStatementCode
): string | undefined => {
  if (!type) {
    return undefined;
  }
  return deviceUseStatementCodeDisplayMap[type];
};

export interface TableDeviceUseStatement {
  id?: string;
  start?: Date;
  end?: Date;
  type?: string;
  code?: DeviceUseStatementCode;
  device: fhir4.Reference;
  subject: fhir4.Reference;
  isActive: boolean;
  status: fhir4.DeviceUseStatement['status'];
}

export const mapFhirToTable = (
  fhir: fhir4.DeviceUseStatement
): Partial<TableDeviceUseStatement> => {
  const { id, timingPeriod, device, subject, status } = fhir;

  const start = timingPeriod?.start ? new Date(timingPeriod?.start) : undefined;
  const end = timingPeriod?.end ? new Date(timingPeriod?.end) : undefined;

  const deviceUseStatementCode = getDeviceUseStatementCode(fhir);
  const isActive = status !== 'completed' && status !== 'stopped';

  return {
    id,
    start,
    end,
    device,
    subject,
    isActive,
    status,
    code: deviceUseStatementCode,
    type: getDeviceUseStatementTypeDisplayName(deviceUseStatementCode),
  };
};
