import { createContext, useContext } from 'react';

import { BaseComponentProps } from './types';

const Context = createContext<Partial<BaseComponentProps>>({});

interface PlotDataProviderProps {
  value: BaseComponentProps;
}

export const PlotDataProvider: React.FC<PlotDataProviderProps> = ({ value, children }) => (
  <Context.Provider value={value}>{children}</Context.Provider>
);

// outside of the plot context this data will not necessarily be defined as expected
export const usePlotDataContext = <TInternalProps extends {}>(props: TInternalProps) => {
  const baseData = useContext(Context) as BaseComponentProps;

  return {
    ...baseData,
    ...props,
  };
};
