import { getReferenceId } from '@quromedical/fhir-common';
import { Group } from '@quromedical/models';
import { SelectOption } from 'components/types';
import { Alert, Skeleton, SkeletonProvider, Snackbar, Visible } from 'design-system';
import { logger } from 'helpers';
import { useFiniteState } from 'hooks/useFiniteState';
import React, { useCallback } from 'react';
import { strings } from 'strings';

import { Card } from '../cards';
import { Form } from './Form';

interface GroupFormCardProps {
  canEdit: boolean;
  data: Partial<Group.Group>;
  revalidate: () => void;
  onSubmit: (group: Group.CreateRequest) => Promise<void>;
  organizationFetcher: (query?: string) => Promise<SelectOption[]>;
}

type FormState = 'initial' | 'editing' | 'submitting' | 'error';

const mapGroupToFormGroup = (group: Partial<Group.Group> = {}): Partial<Group.CreateRequest> => ({
  ...group,
  organization: getReferenceId(group.organization),
});

export const GroupFormCard: React.FC<GroupFormCardProps> = ({
  data,
  revalidate,
  canEdit,
  onSubmit,
  organizationFetcher,
}) => {
  const state = useFiniteState<FormState>('initial');

  const handleSubmit = useCallback(
    async (result: Group.CreateRequest) => {
      state.set('submitting');
      try {
        await onSubmit(result);
        state.set('initial');
        revalidate();
        return {};
      } catch (error) {
        state.set('error');
        logger.error(error);
        return { error };
      }
    },
    [revalidate, onSubmit, state]
  );
  const createData = mapGroupToFormGroup(data);

  return (
    <>
      <SkeletonProvider loading={state.is('submitting')}>
        <Skeleton>
          <Visible if={state.isNot('editing')}>
            <Card data={data} canEdit={canEdit} onEditPress={state.next('editing')} />
          </Visible>

          <Visible if={state.is('editing')}>
            <Form
              data={createData}
              handleCancel={state.next('initial')}
              handleSubmit={handleSubmit}
              showSecondarySubmitButton
              organizationFetcher={organizationFetcher}
              initialOrganization={data.organization}
              title={strings.CardTitleGroupEdit}
            />
          </Visible>
        </Skeleton>
      </SkeletonProvider>

      <Snackbar onClose={state.next('editing')} isOpen={state.is('error')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={state.next('editing')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
        />
      </Snackbar>
    </>
  );
};
