import { Organization } from '@quromedical/models';
import { useLinkTo } from '@react-navigation/native';
import { Row } from 'components/base';
import { Alert, Button, Section, Skeleton, SkeletonProvider, Snackbar } from 'design-system';
import { logger } from 'helpers';
import { useFiniteState } from 'hooks/useFiniteState';
import { useObjectState } from 'hooks/useObjectState';
import { useRevalidation } from 'hooks/useRevalidation';
import { getAddressPartialFetcher, googlePlaceFetcher, schemeCodeFetcher } from 'integration';
import { OrganizationApi } from 'integration/aggregate';
import React, { useCallback } from 'react';
import { PersonContactFormCard } from 'screens/person';
import { strings } from 'strings';

import { OrganizationGeneralFormCard } from './OrganizationFormCard';

const api = new OrganizationApi();

type FormState =
  | 'interactive'
  | 'submitting'
  | 'validation-error'
  | 'submission-error'
  | 'loading'
  | 'complete';

export const OrganizationCreateCard: React.FC = () => {
  const navigate = useLinkTo();
  const revalidate = useRevalidation('Group');

  const formState = useFiniteState<FormState>('interactive');
  const formData = useObjectState<Partial<Organization.CreateRequest>>({});
  const showErrors = formState.is('validation-error');

  const handleSubmit = useCallback(async () => {
    const defined = formData.getDefined(['contact', 'general']);

    if (!defined) {
      formState.set('validation-error');
      return;
    }
    formState.set('loading');

    try {
      const result = await api.createOrganization(defined);

      formState.set('complete');
      navigate({
        screen: 'Admin',
        params: {
          screen: 'Organization',
          params: {
            screen: 'View',
            params: {
              id: result.id,
            },
          },
        },
      });
    } catch (err) {
      logger.error(err);
      formState.set('submission-error');
    } finally {
      revalidate().catch(logger.error);
    }
  }, [formData, formState, navigate, revalidate]);

  return (
    <>
      <SkeletonProvider loading={formState.is('loading')}>
        <Section unsetZIndex>
          <Skeleton>
            <OrganizationGeneralFormCard
              schemeFetcher={schemeCodeFetcher}
              onValidChange={formData.set('general')}
              showErrors={showErrors}
            />
          </Skeleton>
        </Section>

        <Section>
          <Skeleton>
            <PersonContactFormCard
              onValidChange={formData.set('contact')}
              showErrors={showErrors}
              addressFetcher={googlePlaceFetcher}
              getAddressPartial={getAddressPartialFetcher}
            />
          </Skeleton>
        </Section>
        <Section hasBottomMargin>
          <Row justifyContent="flex-end">
            <Button onPress={handleSubmit} text={strings.FormTitleCreateOrganization} />
          </Row>
        </Section>
      </SkeletonProvider>

      <Snackbar isOpen={formState.is('submission-error')} onClose={formState.next('interactive')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={formState.next('interactive')}
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
          actionIcon="close"
        />
      </Snackbar>
    </>
  );
};
