import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Col, Row } from 'components/base';
import {
  Text,
  Icon,
  IconName,
  Skeleton,
  SkeletonProvider,
  useSkeletonContext,
  Button,
  ShapeVariant,
  ButtonTypeVariant,
  Card,
} from 'design-system/base';
import { BASE_SIZE, MARGIN } from 'design-system/theme';
import { useIsSmallerThan } from 'hooks/useResponsive';
import React, { ElementType } from 'react';

type FieldType = 'text' | 'list' | 'custom';

interface BaseField<TFieldType extends FieldType> {
  type: TFieldType;
}

interface TextField extends BaseField<'text'> {
  label: string;
  display?: string;
  fallback?: string;
}

interface ListField extends BaseField<'list'> {
  label: string;
  display?: string[];
}

interface CustomField extends BaseField<'custom'> {
  label?: string;
  Display: ElementType;
}

export type Field = TextField | ListField | CustomField;

export interface RowConfig {
  icon?: IconName;
  fields: Field[];
}

interface FormCardProps {
  title?: string;
  showIcons?: boolean;
  rows: RowConfig[];

  cardColor?: ColorName;
  borderColor?: ColorName;
  paddingHorizontal?: number;
  paddingVertical?: number;

  buttonText?: string;
  buttonIcon?: IconName;
  buttonVariant?: ButtonTypeVariant;
  buttonDisabled?: boolean;
  buttonShape?: ShapeVariant;
  isDisabled?: boolean;
  onButtonPress?: () => void;
  /**
   * `true` by default if `onButtonPress` is provided
   */
  isButtonVisible?: boolean;
}

interface FieldRowProps {
  showIcons?: boolean;
  icon?: IconName;
  fields: Field[];
}

const IconWrapper = styled(Col)({
  marginRight: MARGIN.s,
  marginTop: MARGIN['2xs'],
  height: BASE_SIZE[24],
  width: BASE_SIZE[24],
});

const FieldWrapper = styled(Col)({
  marginTop: MARGIN['2xs'],
});

const textPlaceholder = '_____';

const TextComponent: React.FC<Field> = (props) => {
  const { loading } = useSkeletonContext();

  if (props.type !== 'text') {
    return null;
  }

  const { display, label, fallback = '' } = props;
  const showLoading = loading && !display;
  const resolvedText = showLoading ? textPlaceholder : display;

  return (
    // creating an internal skeleton provider based on an external one so that we can show fields
    // that are ready before their data is necessarily available
    <SkeletonProvider loading={showLoading}>
      <Row>
        <Text variant="caption">{label}</Text>
      </Row>
      <Row>
        <Skeleton fullWidth={false}>
          <Text variant="body-strong">{resolvedText || fallback}</Text>
        </Skeleton>
      </Row>
    </SkeletonProvider>
  );
};

const ListComponent: React.FC<Field> = (props) => {
  const { loading } = useSkeletonContext();

  if (props.type !== 'list') {
    return null;
  }

  const { display, label } = props;
  const showLoading = loading && !display?.length;
  const resolvedDisplay = showLoading ? [textPlaceholder] : display;

  return (
    <SkeletonProvider loading={showLoading}>
      <Row>
        <Text variant="caption">{label}</Text>
      </Row>

      <Skeleton fullWidth={false}>
        {resolvedDisplay?.map((value) => (
          <Row key={value}>
            <Text variant="body-strong">{value}</Text>
          </Row>
        ))}
      </Skeleton>
    </SkeletonProvider>
  );
};

const CustomComponent: React.FC<Field> = (props) => {
  if (props.type !== 'custom') {
    return null;
  }

  const { Display, label } = props;
  return (
    <>
      <Row isVisible={!!label}>
        <Text variant="caption">{label}</Text>
      </Row>

      <Row>
        <Display />
      </Row>
    </>
  );
};

const componentMap: Record<FieldType, React.FC<Field>> = {
  custom: CustomComponent,
  text: TextComponent,
  list: ListComponent,
};

const FieldDisplay: React.FC<Field> = (props) => {
  const { type } = props;

  const Element = componentMap[type];

  return <Element {...props} />;
};

const FieldRow: React.FC<FieldRowProps> = ({ fields, icon, showIcons }) => {
  const isSmall = useIsSmallerThan('md');

  const Wrapper = isSmall ? Col : Row;
  const fieldWrapperFlex = !isSmall ? 1 : undefined;

  return (
    <Row>
      <IconWrapper isVisible={!!showIcons}>{icon ? <Icon name={icon} /> : null}</IconWrapper>
      <Wrapper flex={1}>
        {fields.map((field, index) => (
          <FieldWrapper key={index} flex={fieldWrapperFlex}>
            <FieldDisplay {...field} />
          </FieldWrapper>
        ))}
      </Wrapper>
    </Row>
  );
};

export const FormCard: React.FC<FormCardProps> = ({
  rows,
  showIcons,
  paddingHorizontal,
  paddingVertical,
  buttonIcon,
  cardColor,
  borderColor,
  title = '',
  buttonText = '',
  buttonVariant = 'flat',
  buttonDisabled = false,
  buttonShape = 'default',
  onButtonPress,
  isButtonVisible = !!onButtonPress,
  isDisabled,
}) => (
  <Card
    border={!!borderColor}
    borderColor={borderColor}
    color={cardColor}
    paddingHorizontal={paddingHorizontal}
    paddingVertical={paddingVertical}
  >
    <Row isVisible={!!title} alignItems="center" justifyContent="space-between">
      <Text variant="heading">{title}</Text>
      {onButtonPress && isButtonVisible ? (
        <Button
          onPress={onButtonPress}
          text={buttonText}
          icon={buttonIcon}
          shape={buttonShape}
          variant={buttonVariant}
          disabled={isDisabled || buttonDisabled}
        />
      ) : null}
    </Row>
    {rows.map((row, index) => (
      <FieldRow key={index} showIcons={showIcons} icon={row.icon} fields={row.fields} />
    ))}
  </Card>
);
