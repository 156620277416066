import { Media } from '@quromedical/models';
import { CrudForm, SubmissionHandler } from 'components/form';
import { FileResult, Field } from 'components/types';
import { FileKeyProgressMap } from 'design-system';
import { useFileUpload } from 'hooks/useFileUpload';
import { MediaApi } from 'integration/aggregate';
import React, { useCallback } from 'react';

import { schema } from './validation';

interface MediaFormProps {
  onSubmit: (data: Media.CreateRequest) => void;
  patientId: string;
}

const mediaApi = new MediaApi();

export const initialData: FormMedia = {
  media: [],
};

export interface FormMedia {
  media: FileResult[];
}

export const createFields = (
  progress: FileKeyProgressMap,
  uploadInProgress: boolean
): Field<FormMedia>[] => [
  {
    subfields: [
      {
        type: 'file-picker-dialog',
        key: 'media',
        label: 'Files',
        progress,
        uploadInProgress,
      },
    ],
  },
];

export const MediaForm: React.FC<MediaFormProps> = ({ onSubmit, patientId }) => {
  const createUploadUrl = useCallback(
    (body: Media.CreateUploadUrlRequest): Promise<Media.CreateUploadUrlResponse> =>
      mediaApi.createUploadUrl(patientId, body),
    [patientId]
  );

  const { media, onFilesChange, progress, uploadsComplete } = useFileUpload(createUploadUrl);

  const handleSubmit = useCallback<SubmissionHandler<FormMedia>>(() => {
    if (!uploadsComplete) {
      // this shouldn't be possible when fully implemented since the dialog should remain open as
      // long as uploads are still in progress
      return {};
    }

    onSubmit({
      media,
    });
    return {};
  }, [media, onSubmit, uploadsComplete]);

  const fields = createFields(progress, !uploadsComplete);

  return (
    <CrudForm
      onSubmit={handleSubmit}
      fields={fields}
      initialValues={initialData}
      validationSchema={schema}
      onChange={onFilesChange}
      buttonText="Submit"
    />
  );
};
