import { VideoConsult } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';

export class VideoConsultApi {
  private readonly client: BaseApi;
  private readonly patientBasePath = 'api/aggregate/patient';
  private readonly videoConsultPath = 'video-consult';

  constructor() {
    this.client = new BaseApi();
  }

  private createPath(patientId: string, segments: string[] = []): string {
    const parts = [this.patientBasePath, patientId, this.videoConsultPath, ...segments];
    return parts.join('/');
  }

  public async createRoom(patientId: string): Promise<VideoConsult.CreateRoomResponse> {
    const path = this.createPath(patientId);
    const result = await this.client.post<VideoConsult.CreateRoomResponse>(path, {});

    return result.data;
  }

  public async sendInvite(
    patientId: string,
    body: VideoConsult.SendInvitationRequest
  ): Promise<VideoConsult.SendInvitationResponse> {
    const path = this.createPath(patientId, ['invite']);
    const result = await this.client.post<VideoConsult.SendInvitationResponse>(path, body);
    return result.data;
  }
}
