import { getDisplayFromCodableConcepts } from '@quromedical/fhir-common';
import { Practitioner } from '@quromedical/models';
import { FormCard, RowConfig } from 'design-system';
import { strings } from 'strings';

interface QualificationCardProps {
  data?: Partial<Practitioner.QualificationFields>;
  canEdit: boolean;
  onEditPress: () => void;
}

const getPractitionerQualificationRows = (
  practitioner: Partial<Practitioner.QualificationFields> = {}
): RowConfig[] => [
  {
    icon: 'school',
    fields: [
      {
        type: 'text',
        label: strings.Specialty,
        display: practitioner.qualificationCode
          ? getDisplayFromCodableConcepts([practitioner.qualificationCode.code])
          : undefined,
      },
    ],
  },

  {
    fields: [
      {
        type: 'text',
        label: strings.RegistrationNumber,
        display: practitioner.registrationNumber,
      },
    ],
  },
];

export const QualificationCard: React.FC<QualificationCardProps> = ({
  data,
  canEdit,
  onEditPress,
}) => (
  <FormCard
    isButtonVisible={canEdit}
    onButtonPress={onEditPress}
    buttonText={strings.ButtonTextEdit}
    buttonIcon="edit"
    showIcons
    title={strings.FormLabelPractitionerQualificationDetails}
    rows={getPractitionerQualificationRows(data)}
  />
);
