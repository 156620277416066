import React from 'react';
import { TouchableOpacity } from 'react-native';

interface PressableWrapperProps {
  onPress?: () => void;
}

export const PressableWrapper: React.FC<PressableWrapperProps> = ({ children, onPress }) => {
  if (!onPress) {
    return <>{children}</>;
  }

  return <TouchableOpacity onPress={onPress}>{children}</TouchableOpacity>;
};
