import { Domain } from 'components/graph';
import { extent, max, min } from 'd3-array';

// need a custom remove function since compact removes zero values as well
const removeUndefined = (arr: (number | undefined)[]): number[] =>
  arr.filter((val) => val !== undefined) as number[];

export const getExpandedDomain = (base: Domain, data: number[]): Domain | undefined => {
  const dataDomain = extent(data);

  const jointDomains = removeUndefined([...dataDomain, ...base]);

  const maxVal = max(jointDomains) as number;
  const minVal = min(jointDomains) as number;

  return [minVal, maxVal];
};
