import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { Icon } from './internal';

export const Quro: Icon = ({ color = '#000000', size = 24 }) => (
  <Svg width={size} height={size} fill="none" viewBox="0 0 24 24">
    <Path
      d="M21 20.235h-9.293c-1.248 0-2.406-.186-3.474-.559a8.45 8.45 0 0 1-2.752-1.632 7.886 7.886 0 0 1-1.827-2.594C3.218 14.42 3 13.266 3 11.984c0-1.163.218-2.23.654-3.198a7.761 7.761 0 0 1 1.827-2.527 8.149 8.149 0 0 1 2.662-1.655A8.787 8.787 0 0 1 11.39 4c1.143 0 2.218.201 3.226.604A8.096 8.096 0 0 1 17.3 6.259a7.49 7.49 0 0 1 1.804 2.527c.451.969.677 2.035.677 3.197 0 .835-.105 1.573-.316 2.214a6.41 6.41 0 0 1-.79 1.678c-.33.477-.699.88-1.105 1.207a8.824 8.824 0 0 1-1.15.828v.044H21v2.281ZM11.323 6.55c-.767 0-1.48.142-2.143.425a5.045 5.045 0 0 0-1.691 1.14A5.496 5.496 0 0 0 6.383 9.86a5.803 5.803 0 0 0-.406 2.192c0 .805.136 1.557.406 2.258.271.686.647 1.282 1.128 1.79.481.491 1.045.879 1.692 1.162a5.446 5.446 0 0 0 2.165.425c.782 0 1.504-.142 2.166-.425a5.213 5.213 0 0 0 1.737-1.163 5.502 5.502 0 0 0 1.127-1.789c.271-.7.407-1.453.407-2.258 0-.79-.136-1.521-.407-2.192a5.301 5.301 0 0 0-1.127-1.744 4.946 4.946 0 0 0-1.737-1.14c-.662-.284-1.399-.426-2.21-.426Z"
      fill={color}
    />
  </Svg>
);
