import { DeviceAssociation } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import compact from 'lodash.compact';

enum DeviceAssociationEndpoint {
  Base = '',
}

export class DeviceAssociationApi {
  private readonly client: BaseApi;
  private readonly root = 'api/aggregate/device-association';

  constructor() {
    this.client = new BaseApi();
  }

  private createPath(endpoint: DeviceAssociationEndpoint = DeviceAssociationEndpoint.Base) {
    return compact([this.root, endpoint]).join('/');
  }

  public getDeviceAssociationSWRKey = (
    endpoint?: DeviceAssociationEndpoint,
    deviceId = ''
  ): string => `${this.createPath(endpoint)}/${deviceId}`;

  public async getDeviceAssociations(
    params: DeviceAssociation.GetAllParams
  ): Promise<DeviceAssociation.GetAllResponse> {
    const path = this.createPath(DeviceAssociationEndpoint.Base);
    const result = await this.client.get<DeviceAssociation.GetAllResponse>(path, { params });

    return result.data;
  }
}
