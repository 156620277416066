import { MedicalAid } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { FormikErrors } from 'formik';
import React, { useCallback } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

import {
  findMemberByIdNumber,
  createPatientSelectFields,
  findMemberByDependantCode,
  PatientSelection,
} from './fields';

interface PatientSelectionCardProps {
  results: MedicalAid.MedicalAidValidationResponse;
  value?: Partial<MedicalAid.MedicalAidPerson>;
  initialIdNumber?: string;
  showErrors: boolean;
  onChange: (result: Partial<MedicalAid.MedicalAidPerson>, errors: boolean) => void;
}

const schema = yup.object<ObjectType<PatientSelection>>({
  id: yup.string().required(),
  idNumber: yup.string().min(1).required(),
});

export const PatientSelectionCard: React.FC<PatientSelectionCardProps> = ({
  results,
  initialIdNumber,
  onChange,
  value,
  showErrors,
}) => {
  const initialMember = findMemberByIdNumber(results, initialIdNumber);
  const selected = value || initialMember || {};

  const id = selected?.medicalAidMembership?.dependantCode || '';

  const idNumber = findMemberByDependantCode(results, id)?.general?.identifierValue;
  const initial: Partial<PatientSelection> = {
    id,
    idNumber,
  };

  // if we don't initially resolve an ID number then the field should be editable
  const fields = createPatientSelectFields(results, selected, !initial.idNumber);

  const handleChange = useCallback(
    (result: PatientSelection, errors: FormikErrors<PatientSelection>) => {
      const found = findMemberByDependantCode(results, result.id);

      if (!found) {
        return;
      }

      const merged: Partial<MedicalAid.MedicalAidPerson> = {
        ...found,
        general: {
          ...(found.general || {}),
          identifierValue: result.idNumber,
        },
      };

      const hasErrors = !!Object.keys(errors).length;
      onChange(merged, hasErrors);
    },
    [onChange, results]
  );

  return (
    <CrudForm
      key={id}
      title={strings.MedicalAidSearchSelectPatientTitle}
      fields={fields}
      validationSchema={schema}
      initialValues={initial}
      showSubmitButton={false}
      showErrors={showErrors}
      onChange={handleChange}
      onChangeDebounceTime={0}
    />
  );
};
