import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Row } from 'components/base';
import { Visible, Text, IconBlock, IconName, IconText, Button } from 'design-system/base';
import { BORDER_WIDTH, MARGIN } from 'design-system/theme';
import React from 'react';
import { TouchableWithoutFeedback } from 'react-native';

export interface HeaderProps {
  title: string;
  icon?: IconName;
  /**
   * To use a text block instead of an icon
   */
  iconText?: string;
  iconColor?: ColorName;
  blockColor?: ColorName;

  primaryButtonIcon?: IconName;
  primaryButtonText?: string;
  showPrimaryButton?: boolean;
  onPrimaryButtonPress?: () => void;

  secondaryButtonIcon?: IconName;
  secondaryButtonText?: string;
  showSecondaryButton?: boolean;
  onSecondaryButtonPress?: () => void;
}

const Wrapper = styled(Row)(({ theme }) => ({
  flex: 1,
  alignItems: 'center',
  borderStyle: 'solid',
  borderColor: theme.color.divider,
  borderTopWidth: BORDER_WIDTH[1],
}));

const TextWrapper = styled(Row)({
  flex: 1,
  marginLeft: MARGIN.s,
});

export const Header: React.FC<HeaderProps> = ({
  blockColor,
  icon,
  iconText,
  iconColor = 'text-on-color',
  title,
  primaryButtonText,
  primaryButtonIcon = 'arrow-forward',
  showPrimaryButton = false,
  onPrimaryButtonPress,
  secondaryButtonText,
  secondaryButtonIcon,
  showSecondaryButton = false,
  onSecondaryButtonPress,
}) => (
  <TouchableWithoutFeedback onPress={onPrimaryButtonPress}>
    <Wrapper>
      {icon ? <IconBlock name={icon} iconColor={iconColor} blockColor={blockColor} /> : null}
      {iconText && !icon ? (
        <IconText text={iconText} iconColor={iconColor} blockColor={blockColor} />
      ) : null}
      <TextWrapper>
        <Text variant="heading">{title}</Text>
      </TextWrapper>
      <Visible if={showSecondaryButton}>
        {onSecondaryButtonPress ? (
          <Button
            variant="flat"
            onPress={onSecondaryButtonPress}
            iconPosition="right"
            icon={secondaryButtonIcon}
            text={secondaryButtonText}
          />
        ) : null}
      </Visible>
      <Visible if={showPrimaryButton}>
        {onPrimaryButtonPress ? (
          <Button
            variant="flat"
            onPress={onPrimaryButtonPress}
            iconPosition="right"
            icon={primaryButtonIcon}
            text={primaryButtonText}
          />
        ) : null}
      </Visible>
    </Wrapper>
  </TouchableWithoutFeedback>
);
