import { ObjectType } from '@quromedical/fhir-common';
import { CrudForm } from 'components/form';
import { Field, SelectOption } from 'components/types';
import { mapDisplaysToOptions } from 'core/forms';
import { useCallback, useState } from 'react';
import { strings } from 'strings';
import * as yup from 'yup';

import { mapMedicationRepeatToRepeatName } from './internal';
import {
  medicationFrequencyDisplay,
  MedicationRepeat,
  MedicationUsageFormData,
  RepeatDescription,
} from './types';

interface FormProps {
  title?: string;
  medicationFetcher: (query?: string) => Promise<SelectOption[]>;
  onAdd: () => void;
  onChange: (data: Partial<MedicationUsageFormData>, hasErrors: boolean) => void;
  onRemove: () => void;
  canAdd: boolean;
  canRemove: boolean;
  showErrors: boolean;
  initialValues: Partial<MedicationUsageFormData>;
}

const MedicationRepeatCodes = Object.values(MedicationRepeat);

type FormData = {
  medication: SelectOption<any, string>;
  /**
   * represents a repeatData
   */
  repeat: MedicationRepeat;
  noteText?: string;
};

export const schema = yup
  .object<ObjectType<FormData>>({
    medication: yup.object().required(),
    repeat: yup.string().oneOf(MedicationRepeatCodes).required(),
    noteText: yup.string().notRequired(),
  })
  .required();

const createFields = (
  medicationFetcher: (query?: string) => Promise<SelectOption[]>
): Field<FormData>[] => [
  {
    subfields: [
      {
        key: 'medication',
        label: strings.LabelMedication,
        initialSelection: [],
        searchable: true,
        type: 'combobox-single',
        fetcher: medicationFetcher,
        returnFullOption: true,
      },
      {
        key: 'repeat',
        label: strings.LabelMedicationFrequency,
        type: 'combobox-single',
        fetcher: mapDisplaysToOptions(medicationFrequencyDisplay),
      },
      {
        type: 'text-box',
        key: 'noteText',
        label: strings.LabelMedicationNoteText,
      },
    ],
  },
];

const mapInitialValuesToFormData = (
  initialValues: Partial<MedicationUsageFormData>
): Partial<FormData> => ({
  ...initialValues,
  repeat: mapMedicationRepeatToRepeatName({
    frequency: initialValues.frequency,
    period: initialValues.period,
    periodUnit: initialValues.periodUnit,
  }),
});

export const MedicationForm = ({
  title,
  medicationFetcher,
  onAdd,
  onChange,
  onRemove,
  canAdd,
  canRemove,
  showErrors,
  initialValues,
}: FormProps) => {
  const [medicationDisplay, setMedicationDisplay] = useState(title);

  const handleChange = useCallback(
    (data: Partial<FormData>) => {
      const medication = data.medication;
      const repeatData = data.repeat ? RepeatDescription[data.repeat] : {};

      // manually check schema since the internal form does not validate if not touched
      const isValid = schema.isValidSync(data);
      setMedicationDisplay(medication?.display || title);
      onChange(
        {
          medicationCode: medication?.value,
          noteText: data.noteText,
          ...repeatData,
        },
        !isValid
      );
    },
    [onChange, title]
  );

  const initialData = mapInitialValuesToFormData(initialValues);

  const onSubmit = useCallback(() => {
    onAdd();
    return {};
  }, [onAdd]);

  const fields = createFields(medicationFetcher);

  return (
    <CrudForm<FormData>
      title={medicationDisplay}
      validationSchema={schema}
      fields={fields}
      initialValues={initialData}
      hasCard={false}
      buttonText={strings.FormListAddItem}
      onSecondarySubmit={onRemove}
      secondaryButtonText={strings.FormListRemoveItem}
      showSecondarySubmitButton={canRemove}
      onChange={handleChange}
      onSubmit={onSubmit}
      validateOnChange
      validateOnMount={showErrors}
      onChangeDebounceTime={0}
      showErrors={showErrors}
      showSubmitButton={canAdd}
      buttonVariant="outlined"
    />
  );
};
