import styled from '@emotion/native';
import { A } from '@expo/html-elements';
import { Col, Row } from 'components/base';
import { Text, Spacer, Icon, IconName } from 'design-system/base';
import { BASE_SIZE, BORDER_RADIUS, MARGIN, PADDING } from 'design-system/theme';
import React from 'react';

interface ExternalLinkButtonProps {
  icon?: IconName;
  title: string;
  titlePrefix: string;
  buttonText?: string;
  link?: string;
}

const ContentWrapper = styled(A)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.color['base-grey'],
  justifyContent: 'space-between',
  padding: PADDING.s,
  width: BASE_SIZE[148],
  height: BASE_SIZE[148],
  borderRadius: BORDER_RADIUS[4],
}));

export const ExternalLinkButton: React.FC<ExternalLinkButtonProps> = ({
  icon,
  title,
  buttonText,
  link,
  titlePrefix,
}) => (
  <ContentWrapper href={link}>
    <Col fullWidth alignItems="center" justifyContent="center">
      <Icon size={BASE_SIZE[32]} name={icon} color="primary" />
      <Spacer height={MARGIN['3xs']} />
      <Text ellipsizeMode="clip" numberOfLines={1} variant="caption">
        {titlePrefix}
      </Text>
      <Spacer height={MARGIN['3xs']} />
      <Text ellipsizeMode="clip" numberOfLines={1} variant="button">
        {title}
      </Text>
    </Col>
    <Col justifyContent="flex-end" alignItems="center">
      <Row justifyContent="center" alignItems="center">
        <Text color="primary" variant="caption">
          {buttonText}
        </Text>
        <Spacer width={MARGIN['3xs']} />
        <Icon size={BASE_SIZE[16]} name="arrow-forward" color="primary" />
      </Row>
    </Col>
  </ContentWrapper>
);
