import { NavigationAction, useLinkProps } from '@react-navigation/native';
import { Button } from 'design-system/base';
import { LinkTo } from 'navigation';
import React, { ReactElement } from 'react';

interface LinkButtonProps {
  // TODO: update this to use the new linking configuration
  to: LinkTo;
  action?: NavigationAction;
  text: string;
}

export const LinkButton = ({ to, action, text }: LinkButtonProps): ReactElement => {
  const { onPress, ...props } = useLinkProps({ to, action });

  return <Button onPress={onPress} text={text} {...props} />;
};
