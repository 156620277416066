import { EncounterClass, EncounterService, Period, PeriodComplete } from '@quromedical/fhir-common';
import { strings } from 'strings';

import { getDurationInDays } from './date';

export const admissionServiceDisplay: Record<EncounterService, string> = {
  [EncounterService.HospitalAtHome]: strings.AdmissionServiceHospitalAtHome,
  [EncounterService.LACE]: strings.AdmissionServiceLACE,
  [EncounterService.RemotePatientMonitoring]: strings.AdmissionServiceRemotePatientMonitoring,
  [EncounterService.Arthroplasty]: strings.AdmissionServiceArthroplasty,
  [EncounterService.HealingHands]: strings.AdmissionServiceHealingHands,
  [EncounterService.InHospitalMonitoring]: strings.AdmissionServiceInHospitalMonitoring,
  [EncounterService.CareAtHome]: strings.AdmissionServiceCareAtHome,
};

export const admissionServiceShortDisplay: Record<EncounterService, string> = {
  [EncounterService.HospitalAtHome]: strings.AdmissionServiceShortHospitalAtHome,
  [EncounterService.LACE]: strings.AdmissionServiceShortLACE,
  [EncounterService.RemotePatientMonitoring]: strings.AdmissionServiceShortRemotePatientMonitoring,
  [EncounterService.Arthroplasty]: strings.AdmissionServiceShortArthroplasty,
  [EncounterService.HealingHands]: strings.AdmissionServiceShortHealingHands,
  [EncounterService.InHospitalMonitoring]: strings.AdmissionServiceShortInHospitalMonitoring,
  [EncounterService.CareAtHome]: strings.AdmissionServiceShortCareAtHome,
};

export const admissionClassDisplay: Record<EncounterClass, string> = {
  [EncounterClass.Home]: strings.AdmissionClassHome,
  [EncounterClass.Hospital]: strings.AdmissionClassHospital,
};

export const admissionStatusDisplay: Record<fhir4.Encounter['status'], string> = {
  'entered-in-error': strings.EncounterStatusEnteredInError,
  'in-progress': strings.EncounterStatusInProgress,
  arrived: strings.EncounterStatusArrived,
  cancelled: strings.EncounterStatusCancelled,
  finished: strings.EncounterStatusFinished,
  onleave: strings.EncounterStatusOnLeave,
  planned: strings.EncounterStatusPlanned,
  triaged: strings.EncounterStatusTriaged,
  unknown: strings.EncounterStatusUnknown,
};

const hasEnd = (period: Period): period is PeriodComplete => 'end' in period;

/**
 * Note that the period duration days are calculated such that the first day is "Day 1"
 */
export const getPeriodDurationDays = (period?: Period): number | undefined => {
  const isComplete = period && hasEnd(period);
  const days = getDurationInDays(period?.start, isComplete ? period?.end : undefined);

  if (days === undefined) {
    return undefined;
  }

  // since if start === now then the admission is "Day 1"
  const relativeDay = days + 1;

  return relativeDay;
};

export const getPeriodDayDisplay = (period?: Period): string | undefined => {
  const periodDuration = getPeriodDurationDays(period);

  if (typeof periodDuration === 'undefined') {
    return undefined;
  }

  return `${strings.AdmissionDurationPrefixDay} ${periodDuration}`;
};

export const getPlanPeriodDisplay = (service?: EncounterService, period?: Period): string => {
  if (!service) {
    return strings.AdmissionServiceNoneText;
  }

  const serviceDisplay = service && admissionServiceShortDisplay[service];

  const dayDisplay = getPeriodDayDisplay(period);

  if (!dayDisplay) {
    return serviceDisplay;
  }

  return `${serviceDisplay} (${dayDisplay})`;
};

/**
 * Get the plan display for a note to display which date of the plan a note was created.
 *
 * This is done such that the date duration will be calculated based on the period of the
 */
export const getNotePlanDisplay = (
  noteDate?: string,
  admissionService?: EncounterService,
  admissionPeriod?: Period
): string | undefined => {
  const hasAllData = noteDate && admissionService && admissionPeriod?.start;

  if (!hasAllData) {
    return undefined;
  }

  return getPlanPeriodDisplay(admissionService, {
    start: admissionPeriod.start,
    end: noteDate,
  });
};
