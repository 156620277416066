import { logger } from 'helpers';
import { useCallback } from 'react';

import { useAsyncStorage } from './useAsyncStorage';

interface AsyncStorageData<T> {
  getData: () => Promise<T>;
  setData: (data: T) => Promise<void>;
}

/**
 * A higher-level hook build on `useAsyncStorage` with JSON parsing
 */
export const useAsyncStorageData = <T>(key: string, initialValue: T): AsyncStorageData<T> => {
  const { getItem, setItem } = useAsyncStorage(key);

  const getData = useCallback(async () => {
    const storageItem = await getItem();
    if (storageItem) {
      try {
        const data = JSON.parse(storageItem) as T;

        return data;
      } catch (err) {
        logger.error(err);
      }
    }

    return initialValue;
  }, [getItem, initialValue]);

  const setData = useCallback(
    async (data: T) => {
      try {
        await setItem(JSON.stringify(data));
      } catch (err) {
        logger.error(err);
      }
    },
    [setItem]
  );

  return { getData, setData };
};
