import { Practitioner } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import compact from 'lodash.compact';

export enum PractitionerEndpoint {
  Base = '',
  Activate = 'activate',
  Deactivate = 'deactivate',
  GeneralPractitioner = 'general',
  ContactPractitioner = 'contact',
  QualificationPractitioner = 'qualification',
}

export class PractitionerApi {
  private readonly client: BaseApi;
  private readonly listRoot = 'api/aggregate/practitioner';
  private readonly aggregateRoot = 'api/aggregate/practitioner/';

  constructor() {
    this.client = new BaseApi();
  }

  private createPath(practitionerId: string, endpoint?: string) {
    const basePath = `${this.aggregateRoot}${practitionerId}`;

    return compact([basePath, endpoint]).join('/');
  }

  /**
   * Get an SWR Key associated with the `getPractitioner` function
   */
  public getPractitionerSWRKey = (practitionerId: string, endpoint: PractitionerEndpoint): string =>
    this.createPath(practitionerId, endpoint);

  public activate = async (practitionerId: string): Promise<void> => {
    const path = this.createPath(practitionerId, PractitionerEndpoint.Activate);

    await this.client.put<Practitioner.PractitionerActivateResponse>(path);
  };

  public deactivate = async (practitionerId: string): Promise<void> => {
    const path = this.createPath(practitionerId, PractitionerEndpoint.Deactivate);

    await this.client.put<Practitioner.PractitionerDeactivateResponse>(path);
  };

  public async getPractitioner(practitionerId: string): Promise<Practitioner.GetOneResponse> {
    const path = this.createPath(practitionerId, PractitionerEndpoint.Base);

    const result = await this.client.get<Practitioner.GetOneResponse>(path);

    return result.data;
  }

  public async getPractitioners(
    params: Practitioner.GetAllParams
  ): Promise<Practitioner.GetAllResponse> {
    const result = await this.client.get<Practitioner.GetAllResponse>(this.listRoot, { params });

    return result.data;
  }

  public async updatePractitionerContact(
    practitionerId: string,
    body: Partial<Practitioner.ContactUpdateRequest>
  ): Promise<Practitioner.ContactUpdateResponse> {
    const path = this.createPath(practitionerId, PractitionerEndpoint.ContactPractitioner);

    const result = await this.client.put<Practitioner.ContactUpdateResponse>(path, body);
    return result.data;
  }

  public async updatePractitionerGeneral(
    practitionerId: string,
    body: Practitioner.GeneralUpdateRequest
  ): Promise<Practitioner.GeneralUpdateResponse> {
    const path = this.createPath(practitionerId, PractitionerEndpoint.GeneralPractitioner);
    const result = await this.client.put<Practitioner.GeneralUpdateResponse>(path, body);
    return result.data;
  }

  public async createPractitioner(
    practitioner: Practitioner.CreateRequest
  ): Promise<Practitioner.CreateResponse> {
    const result = await this.client.post<Practitioner.CreateResponse>(this.listRoot, practitioner);
    return result.data;
  }

  public async updatePractitionerQualification(
    practitionerId: string,
    body: Practitioner.QualificationUpdateRequest
  ): Promise<Practitioner.QualificationUpdateResponse> {
    const path = this.createPath(practitionerId, PractitionerEndpoint.QualificationPractitioner);
    const result = await this.client.put<Practitioner.GeneralUpdateResponse>(path, body);
    return result.data;
  }
}
