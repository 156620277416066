import React, { createContext, useContext } from 'react';

interface Context {
  loading: boolean;
}

const SkeletonContext = createContext<Context>({ loading: false });

/**
 * Required internally for the `Skeleton` component to access the loading state within its context
 * but may be used by some higher-order components to display contents aware of the wrapped state
 */
export const useSkeletonContext = (): Context => useContext(SkeletonContext);

/**
 * Top-level provider for the `Skeleton` component. See `Skeleton` for usage information
 */
export const SkeletonProvider: React.FC<Context> = ({ loading, children }) => (
  <SkeletonContext.Provider value={{ loading }}>{children}</SkeletonContext.Provider>
);
