import { Row } from 'components/base';
import { Button, Section, Text, Spacer, MARGIN } from 'design-system';
import React from 'react';
import { strings } from 'strings';

interface FooterProps {
  canLoadMore: boolean;
  onPress: () => void;
  hasError?: boolean;
}

export const Footer: React.FC<FooterProps> = ({ onPress, canLoadMore, hasError }) => {
  if (hasError) {
    return (
      <Section hasBottomMargin>
        <Row justifyContent="center">
          <Text variant="body-strong">{strings.NoteListLoadingError}</Text>
        </Row>
        <Spacer height={MARGIN.xs} />
        <Row justifyContent="center">
          <Button text={strings.NoteListReloadButtonText} onPress={onPress} />
        </Row>
      </Section>
    );
  }

  if (!canLoadMore) {
    return (
      <Section hasBottomMargin>
        <Row justifyContent="center">
          <Text variant="body-strong">{strings.ListEndOfEntriesText}</Text>
        </Row>
      </Section>
    );
  }

  return (
    <Section hasBottomMargin>
      <Row justifyContent="center">
        <Button text={strings.ButtonTextLoadMore} onPress={onPress} />
      </Row>
    </Section>
  );
};
