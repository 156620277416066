import { strings } from 'strings';

export const activeDisplay = (active?: boolean): string | undefined => {
  if (active === true) {
    return strings.DisplayActive;
  }
  if (active === false) {
    return strings.DisplayInactive;
  }
  return undefined;
};
