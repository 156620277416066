import styled from '@emotion/native';
import { Row } from 'components/base';
import {
  ColumnConfig,
  ColumnType,
  DataTable,
  FullPaddedRowWithBackgroundRow,
  OnTableStateChangedHandler,
  PositionProps,
  VerticalPaddedRow,
} from 'components/table';
import { Spinner, Text } from 'design-system';
import { PADDING } from 'design-system/theme';
import { BundleLink } from 'fhir/r4';
import { usePaginatedData, getNextTokenFromLink } from 'hooks/usePaginatedData';
import { useIsSmallerThan } from 'hooks/useResponsive';
import React, { ReactElement } from 'react';
import { Column } from 'react-table';

import { TablePaginationControls } from './TablePaginationControls';

type RowType = 'padded-full-background' | 'padded-vertical';

interface WithBundleLink {
  link?: BundleLink[];
}

interface PaginatedTableProps<TData extends {}, TResponse extends WithBundleLink> {
  cacheKey: any;
  columns: ColumnType<TData>[];
  columnConfig?: ColumnConfig<TData>;
  showHeader?: boolean;
  rowType?: RowType;

  /**
   * @deprecated striped tables are no longer supported in design system
   */
  isStriped?: boolean;

  disablePagination?: boolean;
  /**
   * The table count when initially rendered
   */
  initialCount?: number;
  /**
   * If the user is allowed to change the count
   */
  canChangeCount?: boolean;

  fetcher: (pageToken?: string, count?: number) => Promise<TResponse>;
  getDataFromResponse: (response: TResponse) => TData[];
  onTableStateChanged?: OnTableStateChangedHandler<TData>;
  onRowPress?: (data: TData) => void;
}

const defaultColumn: Partial<Column> = {
  Header: Text,
  Cell: (props: { value: string }) => <Text>{props.value}</Text>,
  disableSortBy: true,
};

const MessageWrapper = styled(Row)({
  padding: PADDING.s,
});

const getNextToken = (data: WithBundleLink) => getNextTokenFromLink(data.link);

const rowTypes: Record<RowType, React.ComponentType<PositionProps>> = {
  'padded-full-background': FullPaddedRowWithBackgroundRow,
  'padded-vertical': VerticalPaddedRow,
};

export const PaginatedTable = <TData extends {}, TResponse extends WithBundleLink>({
  cacheKey,
  fetcher,
  columns,
  showHeader,
  rowType = 'padded-full-background',
  getDataFromResponse,
  onTableStateChanged,
  onRowPress,
  initialCount = 20,
  canChangeCount = false,
  disablePagination = false,
  columnConfig,
}: PaginatedTableProps<TData, TResponse>): ReactElement => {
  const isSmall = useIsSmallerThan('sm');

  const {
    hasNext,
    error,
    hasPrev,
    data = [],
    isLoading,
    loadNext,
    setCount,
    loadPrev,
    resetPage,
    page,
  } = usePaginatedData(
    JSON.stringify(cacheKey),
    fetcher,
    getDataFromResponse,
    getNextToken,
    initialCount
  );

  if (error) {
    return (
      <MessageWrapper>
        <Text>Error</Text>
      </MessageWrapper>
    );
  }

  const LoadingSpinner = () => (
    <MessageWrapper alignItems="center" justifyContent="center">
      <Spinner />
    </MessageWrapper>
  );

  // if we don't have a `flatten` function, we assume `TFlat = TResource`
  const tableData = data;
  const ResolvedRow = rowTypes[rowType];

  return (
    <>
      <DataTable<TData>
        Row={ResolvedRow}
        data={tableData}
        columns={columns}
        defaultColumn={defaultColumn as Partial<Column<TData>>}
        showLoading={isLoading}
        LoadingSpinner={LoadingSpinner}
        showHeader={showHeader}
        onTableStateChanged={onTableStateChanged}
        onRowPress={onRowPress}
        useCards={isSmall}
        columnConfig={columnConfig}
      />

      <TablePaginationControls
        initialCount={initialCount}
        hasPages={!disablePagination}
        canChangeCount={canChangeCount}
        currentPage={page}
        canPressNext={hasNext}
        canPressPrevious={hasPrev}
        onNextPress={loadNext}
        onPreviousPress={loadPrev}
        onCountChange={setCount}
        resetPage={resetPage}
      />
    </>
  );
};
