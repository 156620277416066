import {
  PasswordlessAuthZeroClient,
  BaseAuthUser,
  DeliveryMethod,
  PasswordlessAuthZeroClientOptions,
  StorageClient,
  Credentials,
  AuthenticationDetails,
  PromiseOfOptional,
  AuthSession,
  AuthSessionClient,
  Optional,
} from '@quromedical/auth';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Hub } from 'aws-amplify';

import { UnauthenticatedApi } from './UnauthenticatedApi';

const storageClient = AsyncStorage as StorageClient;

const unauthenticatedApi = new UnauthenticatedApi();
export type Session = AuthSession<BaseAuthUser>;
export class AuthApi<T extends BaseAuthUser> extends PasswordlessAuthZeroClient<T> {
  static async currentSession(): PromiseOfOptional<Session> {
    const session = (await AuthApi.instance?.getSession()) as Optional<Session>;

    return session;
  }

  static signOut() {
    return AuthApi.instance?.signOut();
  }

  static async getCurrentUser(): PromiseOfOptional<BaseAuthUser> {
    const user = (await AuthApi.instance?.getUser()) as Optional<BaseAuthUser>;

    return user;
  }

  static getInstance<T extends BaseAuthUser = BaseAuthUser>(
    options: PasswordlessAuthZeroClientOptions
  ): AuthApi<T> {
    if (!AuthApi.instance) {
      AuthApi.instance = new AuthApi(options, storageClient, AuthSessionClient, Hub);
    }

    return AuthApi.instance as AuthApi<T>;
  }

  // eslint-disable-next-line class-methods-use-this
  async startAuthentication(
    mode: DeliveryMethod,
    username: string
  ): Promise<AuthenticationDetails> {
    const { data } = await unauthenticatedApi.post<AuthenticationDetails>(
      'api/auth/passwordless/start',
      {
        mode,
        username,
      }
    );

    return data;
  }

  async completeAuthentication(
    mode: DeliveryMethod,
    username: string,
    code: string
  ): Promise<Credentials> {
    const { data } = await unauthenticatedApi.post<Credentials>('api/auth/passwordless/complete', {
      mode,
      username,
      code,
    });

    await this.initializeSession(data);

    return data;
  }
}
