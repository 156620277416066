import {
  FieldType,
  ValueType,
  FieldData,
  InputConfig,
  ControlType,
  PrimitiveValueType,
} from 'components/types';
import {
  ComboBoxMultiple,
  ComboBoxMultipleProps,
  ComboBoxSingle,
  ComboBoxSingleProps,
  RadioGroup,
  TextBox,
  TextBoxProps,
  NumberBox,
  NumberBoxProps,
  ToggleButton,
  ToggleButtonProps,
  RadioGroupProps,
  CustomProps,
  Custom,
  MultiFileProps,
  MultiFile,
  MaskedTextBoxProps,
  MaskedTextBox,
  FilePickerDialog,
  FilePickerDialogProps,
  TextDisplay,
  TextDisplayProps,
} from 'design-system/components';
import React, { ReactElement } from 'react';

type SelectiveControlProps<TData extends FieldData<TData>> = {
  value: FieldType | ValueType[];
  onChange: (newValue: FieldType | ValueType[]) => void;
  disabled?: boolean;
  config: InputConfig<TData>;
  errors?: string;
};

interface ComponentProps {
  'toggle-button': ToggleButtonProps<PrimitiveValueType>;
  'text-box': TextBoxProps;
  'number-box': NumberBoxProps;
  'radio-group': RadioGroupProps<PrimitiveValueType>;
  'combobox-single': ComboBoxSingleProps<PrimitiveValueType>;
  'combobox-multiple': ComboBoxMultipleProps<PrimitiveValueType>;
  custom: CustomProps;
  'multi-file': MultiFileProps;
  'masked-text-box': MaskedTextBoxProps;
  'file-picker-dialog': FilePickerDialogProps;
  'text-display': TextDisplayProps;
}

const components: Record<ControlType, React.FC<ComponentProps[ControlType]>> = {
  'toggle-button': (props) => (
    <ToggleButton {...(props as ToggleButtonProps<PrimitiveValueType>)} />
  ),
  'text-box': (props) => <TextBox {...(props as TextBoxProps)} />,
  'number-box': (props) => <NumberBox {...(props as NumberBoxProps)} />,
  'radio-group': (props) => <RadioGroup {...(props as RadioGroupProps<PrimitiveValueType>)} />,
  'combobox-single': (props) => (
    <ComboBoxSingle {...(props as ComboBoxSingleProps<PrimitiveValueType>)} />
  ),
  'combobox-multiple': (props) => (
    <ComboBoxMultiple {...(props as ComboBoxMultipleProps<PrimitiveValueType>)} />
  ),
  custom: (props) => <Custom {...(props as CustomProps)} />,
  'multi-file': (props) => <MultiFile {...(props as MultiFileProps)} />,
  'masked-text-box': (props) => <MaskedTextBox {...(props as MaskedTextBoxProps)} />,
  'file-picker-dialog': (props) => <FilePickerDialog {...(props as FilePickerDialogProps)} />,
  'text-display': (props) => <TextDisplay {...(props as TextDisplayProps)} />,
};

export const SelectiveControl = <TData extends FieldData<TData>>(
  props: SelectiveControlProps<TData>
): ReactElement => {
  const Component = components[props.config.type];

  const componentProps: ComponentProps[typeof props.config.type] = {
    // allow the individual component config to override the disabled value if required
    isDisabled: props.disabled,
    ...props.config,
    errors: props.errors,
    onChange: props.onChange,
    value: props.value,
  } as ComponentProps[typeof props.config.type];

  return <Component {...componentProps} />;
};
