import { MedicalAid } from '@quromedical/models';
import { CrudForm } from 'components/form';
import React, { useCallback } from 'react';
import { strings } from 'strings';

import {
  createNextOfKinSelectFields,
  findMemberByDependantCode,
  NextOfKinSelection,
} from './fields';

interface NextOfKinSelectionCardProps {
  options: MedicalAid.MedicalAidValidationResponse;
  /**
   * A result of `other` indicates that the option selected is `other`
   */
  onSelect: (result?: Partial<MedicalAid.MedicalAidPerson>) => void;
}

export const NextOfKinSelectionCard: React.FC<NextOfKinSelectionCardProps> = ({
  options,
  onSelect,
}) => {
  const initial: Partial<NextOfKinSelection> = {
    dependentCode: options[0]?.medicalAidMembership?.dependantCode,
  };

  const fields = createNextOfKinSelectFields(options);

  const handleChange = useCallback(
    ({ dependentCode }: Partial<NextOfKinSelection>) => {
      if (!dependentCode) {
        onSelect(undefined);
      } else {
        const found = findMemberByDependantCode(options, dependentCode);
        onSelect(found);
      }
    },
    [onSelect, options]
  );

  return (
    <CrudForm
      title={strings.FormLabelNextOfKin}
      fields={fields}
      initialValues={initial}
      showSubmitButton={false}
      onChange={handleChange}
      onChangeDebounceTime={0}
    />
  );
};
