import { Util } from '@quromedical/utils';
import * as Graph from 'components/graph';
import { dateTimeWithoutYearWithSecondsFormatter } from 'helpers';
import { useMemo, useState } from 'react';

type Formatter = (value?: number) => string;

const getTooltipText = (
  valueFormatter: (value?: number) => string,
  datum?: Graph.Datum,
  unit = '',
  simple = false
) => {
  if (!datum) {
    return undefined;
  }

  const time = dateTimeWithoutYearWithSecondsFormatter(new Date(datum.x));
  const value = valueFormatter(datum.y);

  const valueDisplay = `${value} ${unit}`;

  if (simple) {
    return valueDisplay;
  }

  return `${valueDisplay} - ${time}`;
};

interface UseTooltipPropsResult {
  onHover?: (datum?: Graph.Datum) => void;
  hover?: Graph.Datum;
  tooltipText?: string;
  closest?: Graph.Datum;
}

interface GetTooltipDataParams {
  hover?: Graph.Datum;
  unit?: string;
  valueFormatter: Formatter;
  simple?: boolean;
  data?: Graph.Datum[];
}

interface TooltipData {
  closest?: Graph.Datum;
  tooltipText?: string;
}

export const getTooltipData: Util.Fn<GetTooltipDataParams, TooltipData> = ({
  valueFormatter,
  hover,
  unit,
  simple = false,
  data = [],
}) => {
  const getClosest = Graph.getClosestDatum(data);
  const closest = hover && getClosest(hover);
  const tooltipText = getTooltipText(valueFormatter, closest, unit, simple);

  return { closest, tooltipText };
};

export const useTooltipProps = (
  showTooltip: boolean,
  data: Graph.Datum[],
  valueFormatter: Formatter,
  unit?: string
): UseTooltipPropsResult => {
  const [hover, onHover] = useState<Graph.Datum>();

  const { closest, tooltipText } = useMemo(
    () => getTooltipData({ data, hover, unit, valueFormatter, simple: false }),
    [data, hover, unit, valueFormatter]
  );

  if (!showTooltip) {
    return {};
  }

  return {
    onHover,
    hover,
    tooltipText,
    closest,
  };
};
