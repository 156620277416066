import { logger } from 'helpers';
import { useCallback } from 'react';
import { useSWRConfig } from 'swr';

type FullConfiguration = ReturnType<typeof useSWRConfig>;

/**
 * Invalidates all keys that contain the `searchKey`
 * @param searchKey key to search for in SWR cache
 * @param config SWR Config
 */
const revalidateRelatedKeys = async (
  searchKey: string,
  config: FullConfiguration
): Promise<void> => {
  const { cache, mutate } = config;

  const keys = [];
  const isMap = cache instanceof Map;

  if (!isMap) {
    return;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const swrKey of cache.keys()) {
    const foundSearch = typeof swrKey === 'string' && swrKey.includes(searchKey);
    if (foundSearch) {
      keys.push(swrKey);
    }
  }

  const revalidateTasks = keys.map((swrKey) => mutate(swrKey, undefined, true));

  // awaiting instead of returning the promise since we want to discard the resulting data
  await Promise.all(revalidateTasks);
};

/**
 * Makes use of SWR revalidation. For FHIR data use the version in 'useFhirData.ts'
 * @param key prefix of SWR key that will be invalidated
 * @returns `revalidate` function to be called to mark SWR data as invalid
 * @example
 * // create a revalidator
 * const revalidate = useRevalidation('Patient')
 *
 * // later
 * const handleStuff = async () => {
 *  // a form submission or something that will cause current SWR cache to be stale
 *  await doStuff()
 *
 *  // trigger cache revalidation
 *  revalidate()
 * }
 */
export const useRevalidation = (key: string): (() => Promise<void>) => {
  const config = useSWRConfig();

  const revalidate = useCallback(async () => {
    try {
      await revalidateRelatedKeys(key, config);
    } catch (err) {
      logger.error(err);
    }
  }, [key, config]);

  return revalidate;
};
