export const strings = {
  AuthOneTimePinButtonText: 'Resend OTP',
  AuthUsernameLabel: 'Email or phone number',
  AuthUsernameError: 'Username should be email or phone number',
  AuthUsernameHeading: 'Welcome back',
  AuthUsernameSubheading: 'Enter your credentials to log in',
  AuthDeliveryMethodHeading: "Please verify it's you",
  AuthDeliveryMethodSubheading: 'Select where we can send your one-time PIN',
  AuthDeliveryMethodButtonText: 'Send One-Time PIN',
  AuthOneTimePinHeading: 'Enter One-time PIN',
  AuthOneTimePinSubheading:
    'A one-time PIN has been sent to the [field] associated with [username]',
  AuthLabelEmail: 'Email',
  AuthLabelSMS: 'SMS',
  AuthLoginText: 'Log in',
  NotLoggedIn: 'Not logged in',
  DateOfBirth: 'Date of birth',
  DateLabel: 'Date',
  Gender: 'Gender',
  CardLabelAge: 'Age',
  RiskUnspecified: 'unspecified',
  TitleMr: 'Mr.',
  TitleMrs: 'Mrs.',
  TitleMiss: 'Miss',
  TitleDr: 'Dr.',
  TitleSr: 'Sr.',
  TitleProf: 'Prof.',
  GivenName: 'First name',
  FamilyName: 'Last name',
  RegistrationNumber: 'Registration number',
  Email: 'Email',
  Phone: 'Primary Phone Number',
  LabelPhone: 'Phone',
  Phone2: 'Alternate Phone Number',
  SecondaryPhone: 'Secondary phone numbers',
  Specialty: 'Specialty',
  SouthAfrica: 'South Africa',
  Namibia: 'Namibia',
  DeviceName: 'Device name',
  DisplayError: 'Error loading display name',
  DisplayLoading: 'Loading',
  VitalSignsError: 'Error loading latest vital signs',
  GeneralPractitioners: 'Practitioners',
  CardTitleCareTeam: 'Care team',
  DeviceUseStatementPeriodError:
    'Time period cannot be greater than 24hrs - please choose a valid time period.',
  DeviceUseStatementCancelTitle: 'Cancel device association',
  DeviceUseStatementTerminateTitle: 'Terminate device association',
  DeviceUseStatementCancelMessage: 'Are you sure you want to cancel this device association?',
  DeviceUseStatementTerminateMessage: 'Are you sure you want to terminate this device association?',
  DeviceUseStatementCancelButton: 'Cancel',
  DeviceUseStatementTerminateButton: 'Terminate',

  DeviceUseStatementStatusEnteredInError: 'Entered In Error',
  DeviceUseStatementStatusActive: 'Active',
  DeviceUseStatementStatusCompleted: 'Completed',
  DeviceUseStatementStatusIntended: 'Intended',
  DeviceUseStatementStatusStopped: 'Stopped',
  DeviceUseStatementStatusOnHold: 'OnHold',

  SOAPNoteCreateTitle: 'New note',
  SOAPNoteTitle: 'Note',
  SOAPNotePreviewTitle: 'Note Preview',
  SOAPNoteAssessmentStable: 'Stable',
  SOAPNoteAssessmentImproving: 'Improving',
  SOAPNoteAssessmentDeteriorating: 'Deteriorating',
  SOAPNotePlanContinue: 'Continue management',
  SOAPNotePlanAmend: 'Amend plan',
  SOAPNotePlanDischarge: 'Discharge',
  SOAPNotePlanEscalate: 'Escalate',
  // TODO: get better text for these fields
  SOAPNoteButtonTextCreateNote: 'New note',
  SOAPNoteButtonTextHide: 'Hide',
  SOAPSubjectiveTitle: 'Subjective',
  SOAPObjectiveTitle: 'Objective',
  SOAPAssessmentTitle: 'Assessment',
  SOAPPlanTitle: 'Plan',
  SOAPNoteSubjectiveCommentsPlaceholder: 'Subjective comments',
  SOAPNoteObjectiveCommentsPlaceholder: 'Objective comments',
  SOAPNoteAssessmentCommentsPlaceholder: 'Assessment comments',
  SOAPNotePlanCommentsPlaceholder: 'Plan Comments',
  SOAPNoteCodeDescription: 'Visit type',
  SOAPNoteLocationDescription: 'Location',
  SOAPNoteCodeRoutine: 'Routine',
  SOAPNoteCodeClinicalUpdate: 'Clinical update',
  SOAPNoteCodeOnboarding: 'Onboarding',
  SOAPNoteCodeClinicalCallout: 'Clinical Callout',
  SOAPNoteCodeWelcome: 'Welcome',
  SOAPNoteCodeDischarge: 'Discharge',
  SOAPNoteCodeBloodResults: 'Blood Results',
  SOAPNoteCodePhysiotherapy: 'Physiotherapy',
  SOAPNoteLocationCodeVirtual: 'Virtual',
  SOAPNoteLocationCodeInPerson: 'In-person',
  SOAPNoteCodeRequiredErrorMessage: 'Note type is required',
  SOAPNoteLocationRequiredErrorMessage: 'Location is required',
  SOAPNoteSubjectiveRequiredErrorMessage: 'Subjective comments are required',
  SOAPNoteObjectiveRequiredErrorMessage: 'Objective comments are required',
  SOAPNoteAssessmentRequiredErrorMessage: 'Assessment comments are required',
  SOAPNoteAllItemsRequiredErrorMessage: 'Please ensure all SOAP note items are completed',
  SOAPNotePlanRequiredErrorMessage: 'Plan comments are required',
  SOAPNoteSubmissionErrorMessage: 'Error submitting note',
  SOAPNoteSubmissionSuccessMessage: 'Note successfully created',
  DeleteButtonText: 'Delete',
  DurationSuffixAgo: 'ago',
  FormLabelAttachments: 'Attachments',
  ButtonTextAddAttachment: 'Add attachment',
  FormLabelPractitionersToNotify: 'Notify practitioners',
  ButtonTextLoadMore: 'Load more',
  ListEndOfEntriesText: 'No more items',
  TextBoxPlaceholderSearch: 'Search',
  PlaceholderPractitionerDisplayMissing: 'Name not provided',
  VideoConsult: 'Video consult',
  JoinMeeting: 'Join Meeting',
  ButtonTextEndConsult: 'End Consult',
  ButtonTextLeaveWaitingRoom: 'Leave Waiting Room',

  MeetingLoadingTitle: 'Loading',
  WaitingRoomTitle: 'Waiting Room',
  Mute: 'Mute',
  Unmute: 'Unmute',
  PatientMedicalAidSearchId: 'SA ID number or medical aid number is required',
  FormLabelPatientDetails: 'Patient details',
  FormLabelPatientContactDetails: 'Patient contact details',
  FormLabelNextOfKin: 'Next of kin details',
  RadioOptionNextOfKinOther: 'Other',
  FormLabelMedicalAid: 'Medical aid',
  FormLabelMedicalAidNumber: 'Medical aid number',
  FormLabelPrivate: 'Private',
  FormLabelScheme: 'Scheme',
  FormLabelIDType: 'ID type',
  FormLabelIsMainMember: 'Is Patient Main Member',
  FormLabelYes: 'Yes',
  FormLabelNo: 'No',
  FormLabelMedicalAidStatus: 'Membership status',
  FormLabelMedicalAidStart: 'Start',
  FormLabelMedicalAidEnd: 'End',
  FormPlaceholderPhoneAlternative: 'Phone alternative (optional)',
  FormPlaceholderAddressLine2: 'Address line 2 (optional)',
  ButtonTextFindPatient: 'Find patient',
  ButtonTextOnboardPatient: 'Onboard patient',
  ButtonTextNext: 'Next',
  LabelPrivatePatientOnboardError: 'Cannot on-board private patient, please try again',
  // TODO: Needs a better naming structure for this strings
  LabelRisk: 'Risk',
  FormTitleSearch: 'Search',
  ButtonTextSearch: 'Search',
  SortFilterAll: 'All',
  SortFilterActive: 'Active',
  SortFilterDischarged: 'Discharged',
  FilterNameAscending: 'Name (A-Z)',
  FilterNameDescending: 'Name (Z-A)',
  FilterSurnameAscending: 'Surname (A-Z)',
  FilterSurnameDescending: 'Surname (Z-A)',
  FilterActive: 'Active',
  TitleFilterBy: 'Filter by',
  FilterInactive: 'Inactive',
  TitleSortBy: 'Sort By',
  LabelStatus: 'Status',
  LabelSort: 'Sort',
  LabelTitle: 'Title',
  LabelName: 'Name',
  LabelSurname: 'Surname',
  LabelIDNumber: 'ID number',
  ButtonTextSignOut: 'Sign Out',
  LabelCity: 'City',
  LabelPostalCode: 'Postal code',
  LabelCountry: 'Country',
  LabelSex: 'Sex',
  LabelStreetAddress: 'Street address',
  LabelAddressLine1: 'Address line 1',
  LabelAddressLine2: 'Address line 2',
  LabelInCaseOfEmergency: 'In case of emergency',
  LabelIdentification: 'Identifier',
  LabelIdentifierType: 'ID Type',
  LabelPassportNumber: 'Passport Number',
  LabelSAIDNumber: 'SA ID Number',
  LabelNamibiaIDNumber: 'Namibia ID Number',
  LabelIDorPassportNumber: 'ID or Passport Number',
  GenderMale: 'Male',
  GenderFemale: 'Female',
  GenderOther: 'Other',
  GenderUnknown: 'Unknown',
  RiskLow: 'Low',
  RiskMedium: 'Medium',
  RiskHigh: 'High',
  LabelAddress: 'Address',
  ButtonTextEdit: 'Edit',
  ButtonTextUpdate: 'Update',
  LabelPersonalDetails: 'Personal details',
  LabelContactDetails: 'Contact details',
  LabelNameAndSurname: 'Name & Surname',
  TitleRelayStatus: 'Relay Status',
  LabelRelayStatus: 'Status',
  LabelRelayID: 'Relay ID',
  LabelLastUpdated: 'Last Updated',
  LabelPhoneBattery: 'PhoneBattery',
  LabelPhoneNumber: 'PhoneNumber',
  LabelAvailableStorage: 'Available Storage (MB)',
  LabelBluetoothConnections: 'Bluetooth Connections',
  LabelMBSAppStatus: 'MBS App Status',
  LabelRelayAppVersion: 'Relay App Version',
  LabelMBSAppVersion: 'MBS App Version',
  LabelNetworkQuality: 'Network Quality',
  LabelLastReboot: 'Last Reboot',
  Now: 'Now',
  StartConsult: 'Join Consultation',
  ButtonTextJoinMeeting: 'Join Meeting',
  StartConsultHeader: 'Start Video Consult',
  SendInviteHeader: 'Send Invite',
  CreateConsult: 'Create instant consult',
  MeetingId: 'Meeting ID',
  ErrorJoiningMeeting: 'Error joining meeting, please try again',
  CardLabelMedicalAidDetails: 'Medical aid details',
  CardLabelPatientDetails: 'Patient details',
  CardLabelDependantCode: 'Dependant code',
  CardLabelDependantName: 'Dependant name',
  CardLabelDependantType: 'Dependant type',
  CardLabelMainMemberDetails: 'Main member details',
  CardLabelEffectiveDateFrom: 'Effective date from',
  CardLabelEffectiveDateTo: 'To',
  CardLabelAdministrator: 'Administrator',
  FormLabelAdministrator: 'Administrator',
  CardLabelScheme: 'Scheme',
  CardLabelPlanAndOption: 'Plan and option',
  CardLabelNameAndSurname: 'Name and Surname',
  CardLabelNextOfKin: 'Next of kin',
  LabelNextOfKin: 'Next of kin',
  ButtonTextRefresh: 'Refresh',
  LabelExpand: 'Expand',
  LabelCollapse: 'Collapse',
  TableColumnCalibrate: 'Calibrate',
  ButtonTextCreatePatient: 'Create Patient',
  PatchCalibrateFormTitle: 'Calibrate Patch',
  PatchCalibrateFormTitleShort: 'Calibrate',
  PatchCalibrateTitleTemperature: 'Temperature',
  PatchCalibrateTitlePosture: 'Posture',
  PostureCalibrationUnknown: 'Unknown',
  PostureCalibrationUpright: 'Upright',
  PostureCalibrationLayingSupine: 'Laying Supine',
  PostureLayingDown: 'Laying Down',
  PostureLayingDownShort: 'Laying',
  PostureLeaningBack: 'Leaning Back',
  PostureLeaningBackShort: 'Leaning',
  PostureNotFound: 'Not Found',
  PostureRunning: 'Running',
  PostureSitting: 'Sitting',
  PostureStanding: 'Standing',
  PostureUnknown: 'Unknown',
  PostureWalking: 'Walking',
  VitalTitleOxygenSaturation: 'Oxygen Saturation',
  VitalTitleCoreTemperature: 'Core Temperature',
  VitalTitleHeartRate: 'Heart Rate',
  VitalTitleRespiratoryRate: 'Respiratory Rate',
  VitalTitlePosture: 'Posture',
  VitalTitleSteps: 'Activity',
  VitalTitleECG: 'ECG',
  VitalTitlePerfusionIndex: 'Perfusion Index',
  VitalTitleGlucose: 'Glucose',
  VitalTitleSystolicArterialBloodPressure: 'Systolic Arterial Pressure',
  VitalTitleDiastolicArterialBloodPressure: 'Diastolic Arterial Pressure',
  VitalTitleMeanArterialBloodPressure: 'Mean Arterial Pressure',
  VitalTitleCombinedArterialBloodPressure: 'Blood Pressure',
  VitalTitleWeight: 'Weight',
  VitalTitlePostureCalibration: 'Posture Calibration',
  LabelDevice: 'Device',
  LabelDevices: 'Devices',
  LabelPatientFile: 'Patient file',
  ErrorCardTitle: 'Error',
  GenericErrorMessage: 'An error occurred',
  NoteListLoadingError: 'Error loading notes',
  NoteListReloadButtonText: 'Reload',
  RelayLoadingErrorText: 'Error loading relay status',
  RelayMBSIssuesTitle: 'MBS Issues',
  RelayIssuesTitle: 'Issues',
  RelayIssueTextNoIssues: 'No Issues',
  RelayWarningBadNetwork: 'Bad mobile network.',
  RelayWarningLowStorage: 'Low storage.',
  RelayWarningLowBattery: 'Low battery & not charging.',
  RelayWarningNoBluetoothDevices: 'No Bluetooth devices connected.',
  LabelNotifications: 'Notifications',
  NoAssignedDevicesErrorText: 'No assigned devices',
  NoAssociatedDevicesFound: 'No Associated Devices Found',
  ErrorFetchingPatient: 'Error fetching patient',
  ErrorFetchingNextOfKin: 'Error fetching next of kin',
  DurationLabelDuration: 'Duration',
  DurationLabelCustom: 'Custom',
  DurationLabelLatest: 'Latest',
  DurationLabelFrom: 'From',
  DurationErrorInvalidDate: 'Please enter a valid date',
  DurationCustomSelectTimeFrame: 'Select time frame',
  ButtonTextSubmit: 'Submit',
  ButtonTextCancel: 'Cancel',
  ButtonTextClose: 'Close',
  ButtonTextCopyConsultLink: 'Copy Link',
  ButtonTextInviteViaEmail: 'Email',
  ButtonTextInviteViaSms: 'SMS',
  ButtonTextInviteViaWhatsapp: 'WhatsApp',
  NoDataFallbackText: 'No data for selected period',
  AlertSuccess: 'Success',
  AlertError: 'Error',
  AlertCopySuccess: 'Your details have been copied',
  AlertCopyError: 'Could not copy invite',
  AlertSendInviteSuccessful: 'Meeting invite sent successfully',
  AlertSendInviteFailed: 'Could not send invite',
  AlertPatientLoadingError: 'Error loading patients',
  AlertMissingAdmissionID: 'Patient does not have an active admission ID',
  ButtonTextRetry: 'Retry',
  ErrorUpdatingPatient: 'Error updating patient information',
  ErrorUpdatingRelatedPerson: 'Error updating related person information',
  AddressLine1Error: 'Address Line 1 is required',
  PhoneNumberLineError: 'Phone number is required with this format. e.g. +27123456789',
  MedicalAidSearchError: 'Error searching for patient',
  MedicalAidError: 'Error loading medical aid details',
  MedicalAidSearchSelectPatientTitle: 'Select patient',
  NextOfKinError: 'Error loading next of kin',
  DragFileToUpload: 'Drag file to upload',
  ChooseFile: 'Choose file',
  OrText: 'or',
  FileUploadingLabelText: 'Uploading',
  CreateDevice: 'Device details',
  DeviceCreateError: 'Error creating a new device',
  DeviceEditError: 'Error editing a device',
  DeviceDetails: 'Device details',
  LabelDeviceType: 'Device type',
  LabelDeviceStatus: 'Device status',
  LabelDeviceSerialNumber: 'Serial number',
  DisplayDeviceTypeRelay: 'Relay',
  DisplayDeviceTypeGlucose: 'Glucose',
  DisplayDeviceTypeBloodPressure: 'Blood pressure',
  DisplayDeviceTypePatch: 'Patch',
  PlaceHolderDeviceType: 'Select a device type',
  PlaceHolderDeviceStatus: 'Select a device status',
  DisplayActive: 'active',
  DisplayInactive: 'inactive',
  DisplayEnteredInError: 'entered in error',
  FormLabelActive: 'Active',
  FormLabelAssignedOrgOrTeams: 'Assigned Team or Departments',
  CardTitleAdmissionDetails: 'Admission Details',
  FormLabelICD10Codes: 'ICD 10 Codes',
  PractitionerDeactivateDialogButtonText: 'Deactivate',
  PractitionerDeactivateDialogTitle: 'Deactivate Practitioner',
  PractitionerDeactivateDialogMessage: 'Are you sure you want to make this practitioner inactive?',
  PractitionerActivateDialogButtonText: 'Activate',
  PractitionerActivateDialogTitle: 'Activate Practitioner',
  PractitionerActivateDialogMessage: 'Are you sure you want to make this practitioner active?',
  PatientDischargeDialogButtonText: 'Discharge',
  PatientDischargeDialogTitle: 'Discharge Patient',
  PatientDischargeDialogMessage: 'Are you sure you want to discharge this patient?',
  PatientReadmitDialogButtonText: 'Readmit',
  PatientReadmitDialogTitle: 'Readmit Patient',
  PatientReadmitDialogMessage: 'Are you sure you want to readmit this patient?',
  AdmissionServiceLabel: 'Quro Medical offering',
  AdmissionServiceOptionAll: 'All Services',
  AdmissionStartLabel: 'Date of admission',
  AdmissionStatusLabel: 'Status',
  AdmissionStatusAdmitted: 'Admitted',
  AdmissionStatusOnboarded: 'Onboarded',
  AdmissionStatusDischarged: 'Discharged',
  AdmissionEndLabel: 'End of admission',
  AdmissionServiceHospitalAtHome: 'Hospital at home',
  AdmissionServiceLACE: 'LACE',
  AdmissionServiceRemotePatientMonitoring: 'Remote patient monitoring',
  AdmissionServiceArthroplasty: 'Arthroplasty',
  AdmissionServiceHealingHands: 'Healing Hands',
  AdmissionServiceInHospitalMonitoring: 'In Hospital Monitoring',
  AdmissionServiceCareAtHome: 'Care At Home',
  AdmissionServiceShortHospitalAtHome: 'HaH',
  AdmissionServiceShortLACE: 'LACE',
  AdmissionServiceShortRemotePatientMonitoring: 'RPM',
  AdmissionServiceShortArthroplasty: 'ARTH',
  AdmissionServiceShortHealingHands: 'HH',
  AdmissionServiceShortInHospitalMonitoring: 'IHM',
  AdmissionServiceShortCareAtHome: 'CaH',
  AdmissionClass: 'Admission type',
  AdmissionClassHome: 'Home Care',
  AdmissionClassHospital: 'In Hospital Care',
  AdmissionAuthorizationDateLabel: 'Date of authorization',
  AdmissionAuthorizationCodeLabel: 'Medical aid authorization code',
  RequiredOxygenFormLabel: 'Supplemental Oxygen',
  RequiredOxygenQuantityFormLabel: 'Oxygen (l/min)',
  AdmissionAuthorizationFormTitle: 'Authorization',
  AdmissionNotesFormTitle: 'Admission notes',
  AdmissionClinicalFormTitle: 'Clinical details',
  AdmissionDrawerTitle: 'Admit',
  AdmissionCardTitle: 'Admission',
  AdmissionButtonTextCreate: 'Create Admission',
  AdmissionServiceFormLabel: 'Service',
  AdmissionServiceNoneText: 'None',
  AdmissionDurationPrefixDay: 'Day',
  EncounterStatusEnteredInError: 'Entered in error',
  EncounterStatusInProgress: 'In progress',
  EncounterStatusArrived: 'Arrived',
  EncounterStatusCancelled: 'Cancelled',
  EncounterStatusFinished: 'Finished',
  EncounterStatusOnLeave: 'On leave',
  EncounterStatusPlanned: 'Planned',
  EncounterStatusTriaged: 'Triaged',
  EncounterStatusUnknown: 'Unknown',
  CardTitleInactiveMedicalAidMembers: 'Inactive medical aid members',
  CardLabelInactiveMembers: 'Members',
  CardLabelInactiveMedicalAidReason: 'Reason',
  FormTitleCreateMedia: 'Create Media',
  FormTitleCreateGroup: 'Create Group',
  MediaFileNameSubheading: 'File Name',
  MediaPractitionerSubheading: 'Practitioner',
  MediaDownloadSubheading: 'Download',
  MediaDownloadButtonText: 'Download file',
  MediaViewButtonText: 'View file',
  GroupsFormLabel: 'Groups',
  LabelGroupName: 'Group Name',
  LabelGroupType: 'Group Type',
  NoGroupsAssigned: 'No groups assigned',
  PlaceHolderGroupName: 'Enter Group Name',
  PlaceHolderGroupType: 'Enter Group Type',
  PlaceHolderSelectOption: 'Select option',
  PlaceHolderAddPermissions: 'Add permissions',
  PlaceHolderSelectOrganization: 'Select organization',
  LabelActive: 'Active',
  DisplayNotFound: 'Display not found',
  LabelPermissions: 'Permissions',
  LabelOrganization: 'Organization',
  CardTitleGroupDetails: 'Group Details',
  CardTitleAddGroupMembers: 'Add Members',
  ButtonTextAddGroupMembers: 'Add members',
  CardTitleGroupEdit: 'Edit Group',
  UserProfileCardTitle: 'User Profile',
  UserProfileLinkText: 'Profile',
  ResourceNameMissing: 'Display name not found',
  FormLabelPreferences: 'Preferences',
  FormLabelTheme: 'Theme',
  FormOptionThemeDark: 'Dark',
  FormOptionThemeLight: 'Light',
  FormOptionThemeSystem: 'System',
  FormOptionThemeContrastDark: 'High Contrast',
  PreferenceUpdateErrorMessage: 'Error updating user preferences',
  ToggleDisplayAdmissions: 'Admissions',
  ToggleDisplayPatients: 'Patients',
  CardTitleOrganizationDetails: 'Organization details',
  CardTitleOrganizationEdit: 'Edit Organization',
  LabelOrganizationType: 'Type',
  LabelOrganizationSchemeCode: 'Scheme code',
  FormLabelMembers: 'Members',
  FormTitleCreateOrganization: 'Create Organization',
  CardTitlePractitionerDetails: 'Practitioner details',
  CardTitlePractitionerEdit: 'Edit Practitioner',
  LabelPractitionerError: 'Cannot Create a Practitioner, please try again',
  FormLabelPersonDetails: 'Details',
  FormLabelPersonContactDetails: 'Contact details',
  FormLabelPractitionerQualificationDetails: 'Qualification',
  ButtonTextSubmitPractitioner: 'Create Practitioner',
  FormTitlePractitionerInitialGroup: 'Initial group',
  FormLabelPractitionerGroup: 'Group',
  QualificationCode: 'Qualification code',
  CardTitleIAmNew: 'I am a new',
  ToggleCardCollapseButtonTextHide: 'Hide',
  PractitionerToggleCardTitle: 'Create Practitioner',
  PractitionerToggleCardExpandButtonText: 'Create Practitioner',
  PractitionerToggleCardCollapseButtonText: 'Hide',
  OrganizationToggleCardTitle: 'Create Organization',
  OrganizationToggleCardExpandButtonText: 'Create Organization',
  OrganizationToggleCardCollapseButtonText: 'Hide',
  GroupToggleCardTitle: 'Create Group',
  GroupToggleCardExpandButtonText: 'Create Group',
  GroupToggleCardCollapseButtonText: 'Hide',
  DeviceToggleCardTitle: 'Create Device',
  DeviceToggleCardExpandButtonText: 'Create Device',
  DeviceToggleCardCollapseButtonText: 'Hide',
  TitleCreateDeviceAssociation: 'Create Device Association',
  LabelCreateDeviceAssociation: 'Create Association',
  DeviceAssociationActiveAdmissionServiceLabel: 'Active Admission',
  TitlePatient: 'Patient',
  TitlePractitioner: 'Practitioner',
  ButtonTextRegister: 'Register',
  ButtonTextLearnMore: 'Learn more',
  TitleRegisterNow: 'Register now',
  TitleNewToQuroMedical: 'New to Quro Medical?',
  TitleDownloadOurApp: 'Download our app',
  LabelVitalLatest: 'Latest',
  TitleVitalLatest: 'Latest Vitals',
  EWSTitle: 'EWS',
  EWSTitleLong: 'Early Warning',
  NEWS2ScoreTitle: 'NEWS2',
  NEWS2ScoreTitleLong: 'NEWS2 Score',
  NEWS2ScoreLabelLowRisk: 'Low Risk',
  NEWS2ScoreLabelLowMediumRisk: 'Low-medium Risk',
  NEWS2ScoreLabelMediumRisk: 'Medium Risk',
  NEWS2ScoreLabelHighRisk: 'High Risk',
  NEWS2ScoreLabelShortLowRisk: 'Low',
  NEWS2ScoreLabelShortLowMediumRisk: 'Low-medium',
  NEWS2ScoreLabelShortMediumRisk: 'Medium',
  NEWS2ScoreLabelShortHighRisk: 'High',
  NEWS2ScoreLabelUnknownRisk: 'Unknown',
  MedicationsCardTitle: 'Medication',
  MedicationsRequestCardTitle: 'Pharmacy',
  LabelMedication: 'Medication',
  LabelMedicationNappiCode: 'Medication Nappi Code',
  MedicationPlaceholder: 'Medication Search',
  LabelMedicationFrequency: 'Frequency',
  LabelMedicationDuration: 'Duration',
  MedicationRepeatPlaceholder: 'eg. 3 times',
  LabelMedicationPeriodUnit: 'Period Unit',
  MedicationPeriodUnitPlaceholder: 'eg. Daily',
  LabelMedicationPeriod: 'Period',
  MedicationPeriodPlaceholder: 'eg. Daily',
  LabelMedicationNoteText: 'Instructions',
  LabelMedicationRefill: 'Refills',
  LabelMedicationGenerics: 'Generics',
  LabelRecipient: 'Send To',
  LabelRecipientPicker: 'Select where to send the script',

  MedicationNoteTextPlaceholder: 'take with meals',
  FormTitleCreateMedicationStatement: 'Create Medication Statement',
  FormTitleCreateMedicationRequest: 'Create Medication Request',
  LabelMedicationName: 'Medication',
  LabelMedicationRoute: 'Medication Route',
  CardTitleMedicationDetails: 'Medication Details',
  LabelMedicationStrength: 'Medication Strength',
  LabelMedicationVolume: 'Volume',
  LabelMedicationDosage: 'Medication Dosage',
  LabelMedicationStatus: 'Medication Status',
  LabelMedicationPrescriber: 'Created by',
  NEWS2OxygenSaturationLabelScale1: 'Scale 1 (air)',
  NEWS2OxygenSaturationLabelScale2Oxygen: 'Scale 2 (oxygen)',
  NEWS2OxygenSaturationLabelScale2Air: 'Scale 2 (air)',
  PatientPrintSummaryPlaceholderNoNotes: 'No notes',
  PatientPrintTitleNoVitals: 'No vitals',
  NoteHistoryTitle: 'History',
  MedicationRequestRefillOnce: 'Once',
  MedicationRequestRefillTwice: 'Two times',
  MedicationRequestRefillThreeTimes: 'Three times',
  MedicationFrequencyDaily: 'One/day',
  MedicationFrequencyBiDaily: 'Two/day',
  MedicationFrequencyThreeTimesADay: 'Three/day',
  MedicationFrequency4Hourly: 'Four hourly',
  MedicationFrequency6Hourly: 'Six hourly',
  MedicationFrequencyNightOnly: 'Night only',
  MedicationFrequencyMorningOnly: 'Morning only',
  MedicationFrequencyAsNeeded: 'As needed',
  MedicationPrescriptionLength7Days: '7 days',
  MedicationPrescriptionLength14Days: '14 days',
  MedicationPrescriptionLength1Month: '1 month',
  MedicationNameSubheading: 'Medication',
  MedicationNappiCodeSubheading: 'NappiCode',
  MedicationDosageSubheading: 'Dosage',
  MedicationRepeatSubheading: 'Repeat',
  MedicationStrengthSubheading: 'Strength',
  MedicationVolumeSubheading: 'Volume',
  MedicationRouteSubheading: 'Route',
  MedicationScheduleSubheading: 'Schedule',
  MedicationActionsSubheading: 'Actions',
  MedicationRequestHeading: 'Medication Requests',
  MedicationConfirmationButtonTitle: 'Mark as Error',
  MedicationConfirmationButtonText: 'Mark as Error',
  MedicationConfirmationButtonMessage:
    'Are you sure you want mark this medication as entered in error?',
  TitleUrgentClinicalReview: 'Urgent Clinical Review',
  TitleUrgentClinicalReviewCritical: 'Urgent Clinical Review (High)',
  TitleRealtimeAlert: 'Realtime Alerts',
  ValueNotAvailable: 'N/A',
  PathologyButtonTextCreateRequest: 'New request',
  PathologyButtonTextHide: 'Hide',
  PathologyOrderNumberSubheading: 'Order Number',
  PathologyRequestStatusSubheading: 'Request Status',
  PathologyRequestDateSubheading: 'Request Date',
  PathologyTestsSubheading: 'Tests',
  PathologyNotesSubheading: 'Notes',
  PathologyCreateCardTitle: 'Create Ampath Pathology Request',
  PathologyReportDateSubheading: 'Report Date',
  PathologyReportStatusSubheading: 'Report Status',
  PathologyDownloadButtonTitle: 'Download Report',
  PathologyDrawerTitle: 'Pathology',
  WarningNoMedicalAidDetails:
    'Patient does not have Medical Aid details required for pathology requests',
  WarningNoActiveAdmission: 'Patient does not have an active admission',
  WarningNoAuthorization: 'Patient does not have an authorization number',
  PathologyWarningCardLabel: 'Pathology requests unavailable',

  ButtonTextOpenMaps: 'Open Maps',
  ButtonTextNoMapLink: 'No Map Link',
  TitleAddressDetails: 'Address Details',
  PathologyRequestsTabTitle: 'Requests',
  PathologyReportsTabTitle: 'Reports',

  VitalsActiveTabTitle: 'Active Vitals',
  VitalsTrackingTabTitle: 'Vital Tracking',

  // KBar strings
  KBarHomeTitle: 'Home',
  KBarHomeSubtitle: 'Go to the home screen',

  KBarDashboardTitle: 'Dashboard',
  KBarDashboardSubtitle: 'Go to dashboard',

  KBarPatientTitle: 'Patients',
  KBarPatientSubtitle: 'Go to Patient List',

  KBarAdmissionTitle: 'Admissions',
  KBarAdmissionSubtitle: 'Go to Admissions List',

  KBarActiveAdmissionsTitle: 'Active Patients',
  KBarActiveAdmissionsSubtitle: 'View Patients with Active Admissions',

  KBarAdminOnboardPrivateTitle: 'Onboard Private',
  KBarAdminOnboardPrivateSubtitle: 'Onboard Patient without Medical Aid Details',
  KBarAdminOnboardMedicalAidTitle: 'Onboard Medical Aid',
  KBarAdminOnboardMedicalAidSubtitle: 'Onboard Patient with Medical Aid',

  KBarActiveAdmissionActionSubtitlePrefix: 'For ',

  KBarActiveAdmissionActionProfileTitle: 'Profile',
  KBarActiveAdmissionActionVitalsTitle: 'Vitals',
  KBarActiveAdmissionActionClinicalTitle: 'Clinical',
  KBarActiveAdmissionActionNotesTitle: 'Notes',
  KBarActiveAdmissionActionPathologyTitle: 'Pathology',
  KBarActiveAdmissionActionMediaTitle: 'Media',
  KBarActiveAdmissionActionVideoConsultTitle: 'Video Consult',

  KBarCurrentPatientActionTitleSubtitle: 'Current Patient',

  ActionListSubtitleTime: 'Time',

  // alerts
  AlertTitleECGNonSinusRhythm: 'Non-sinus rhythm',
  AlertRowTitleTime: 'Time',

  LabelSearchAddresses: 'Search Addresses',

  DeviceTableNameSubheading: 'Name',
  DeviceTableTypeSubheading: 'Type',
  DeviceTableStatusSubheading: 'Status',

  MyPatients: 'My Patients',

  VitalObservationFormTitlePrefix: 'Add',
  VitalObservationFormTitleSuffix: 'Measurement',
  VitalObservationFormLabelMeasure: 'Measurement',
  VitalObservationFormLabelDateTime: 'Date and Time',
  VitalObservationFormLabelValue: 'Value',
  VitalObservationFormLabelUnit: 'Unit',

  VitalObservationDeviceName: 'Manually Added',

  VitalsFilterFormLabel: 'Monitoring Level',

  MonitoringConfigurationLabel: 'Monitoring Intensity',
  MonitoringConfigurationHighIntensity: 'High Intensity',
  MonitoringConfigurationMediumIntensity: 'Medium Intensity',
  MonitoringConfigurationLowIntensity: 'Low Intensity',
  ReferPatient: 'Refer patient',

  TinyMDMGeoPositionLabel: 'Latest Geolocation Position',
  TinyMDMErrorTitle: 'Error',
  TinyMDMErrorText: 'Could not load the TinyMDM status',
  TinyMDMRebootButton: 'Reboot device',
  TinyMDMStatusTitle: 'TinyMDM Status',
  TinyMDMRebootErrorTitle: 'Reboot error',
  TinyMDMRebootErrorText: 'Could not reboot the device remotely',

  LabelGivenName: 'Given Name',
  LabelFamilyName: 'Family Name',
  LabelAction: 'Action',
  LabelStart: 'Start',
  LabelEnd: 'End',
  LabelSubject: 'Subject',
  LabelEntity: 'Entity',

  PaginationControlsLabelPage: 'Page',
  DeviceMetricListTitle: 'Calibration History',

  NestedFormTextRequired: 'Text is required',

  LogisticsCollectionCardTitle: 'Collection details',
  LogisticsFromLabel: 'From',
  LogisticsPatientNameLabel: 'Patient name',
  LogisticsOrganizationNameLabel: 'Organization name',
  LogisticsToLabel: 'To',
  LogisticsDistanceLabel: 'Distance',
  LogisticsCollectionKitLabel: 'Collection Kit',
  LogisticsDistanceCalculationError: 'Error when calculating the distance',
  LogisticsDistanceSubmittingError: 'Error when requesting the quote',
  LogisticsQuotationError: 'Error when getting the quotes from the logistics provider',
  LogisticsQuoteSubmittingError: 'Error when booking the collection',
  LogisticsBookingsLoadingError: 'Error loading collections',
  LogisticsGetQuoteButton: 'Get quotes',
  LogisticsBPMonitoringKitLabel: 'BP monitoring kit',
  LogisticsConcentratorLabel: 'Concentrator',
  LogisticsCylinder10LLabel: '10 liter oxygen Cylinder',
  LogisticsCylinder3LLabel: '3 liter oxygen Cylinder',
  LogisticsMonitoringKitLabel: 'Monitoring kit',
  LogisticsVehicleTypeLabel: 'Vehicle type',
  LogisticsServiceTypeLabel: 'Service type',
  LogisticsTransportModeLabel: 'Transport mode',
  LogisticsBookingStatus: 'Status',
  LogisticsProviderName: 'Provider name',
  LogisticsServiceDescriptionLabel: 'Service description',
  LogisticsPriceLabel: 'Price',
  LogisticsRandLabel: 'R',
  LogisticsQuoteLabel: 'quote',
  LogisticsQuoteSearchError: 'Error searching for quotes',
  LogisticsBookCollection: 'Book collection',
  LogisticsBookingConfirmationMessage: 'Are you sure you want to create a booking for ',

  // alert form
  AlertQualityHigh: 'High',
  AlertQualityMedium: 'Medium',
  AlertQualityLow: 'Low',

  AlertTypeLabel: 'Type',
  AlertTimeLabel: 'Time',
  AlertQualityLabel: 'Data quality',
  AlertClassificationLabel: 'Clinical conditions',
  AlertDetailsLabel: 'General comments',
  AlertCommentFormTitle: 'Add alert comment',

  TitleSelectPatient: 'Select patient',
  ErrorTextPatientIdNumberRequired: 'Patient ID Number is Required',
  MedicalAidUpdateSubmissionError: 'Error updating medical aid details',
  MedicalAidUpdateValidationError: 'Main member details are not valid',
  MedicalAidPatientSelectionEmpty: 'No members found',
  MedicalAidPatientUpdateSearchFormTitle: 'Update medical aid details',
  MedicalAidPatientUpdateSearchError: 'No results found',

  ChargeLineAppliesOptionLabel: 'Applies to',
  ChargeLineAppliesOptionScheme: 'Scheme',
  ChargeLineAppliesOptionAdministrator: 'Administrator',
  ChargeLineAppliesSchemeOrAdministrator: 'Scheme or administrator',
  ChargeLineItemAmountLabel: 'Amount (cents)',
  ChargeLineItemCodeLabel: 'Charge Code',

  ChargeLineUnitPrice: 'Unit Price',
  ChargeLineTotalPrice: 'Total Price',

  FormListAddItem: 'Add Item',
  FormListRemoveItem: 'Remove Item',

  ChargeLineFormTitle: 'Add Item',
  ChargeItemDefinitionFormLabelName: 'Name',
  ChargeItemDefinitionFormLabelDescription: 'Description',
  ChargeItemDefinitionFormLabelChargeLines: 'Claim Line by Scheme or Administrator',

  ChargeItemDefinitionFormCreateTitle: 'Create Charge Line',
  ChargeItemDefinitionFormEditTitle: 'Edit Charge Line',
  ChargeItemDefinitionFormSubmissionError: 'Error submitting form',
  ChargeItemDefinitionFormItemsError: 'Charge lines not valid',

  ChargeItemDefinitionViewTitle: 'View item',
  ChargeItemDefinitionListTitle: 'Charge Items',

  ClaimLineQuantityLabel: 'Quantity',
  ClaimLineChargeItemLabel: 'Charge item',
  ClaimLineFormTitle: 'Add Item',

  ClaimTemplateFormSubmissionError: 'Error submitting form',
  ClaimTemplateFormItemsError: 'Items not valid',

  ClaimTemplateFormCreateTitle: 'Create claim template',
  ClaimTemplateFormEditTitle: 'Update claim template',

  ClaimTemplateFormLabelService: 'Service type',

  ClaimTemplateViewTitle: 'View item',
  ClaimTemplateListTitle: 'Claim templates',

  ClaimViewTitle: 'View item',
  ClaimListTitle: 'Claims',
  ClaimEditTitle: 'Edit claim',

  LabelInvoiceNumber: 'Invoice number',

  ClaimSubmitMessage: "Submit claim to the patient's medical aid",
  ClaimSubmitTitle: 'Submit claim',

  AmpathRequestTestTypeLabel: 'Test Type',
  AmpathRequestTestTypeNormalOption: 'Normal',
  AmpathRequestTestTypeSpecialOption: 'Special Order',
  AmpathRequestTestsNormalLabel: 'Ordered Tests',
  AmpathRequestTestsSpecialLabel: 'Special Test',
  AmpathRequestSampleDateTimeLabel: 'Sample Date and Time',
  AmpathRequestCommentLabel: 'Notes',
};

export type LocaleStringKey = keyof typeof strings;
