import { getCreateReferenceId, Period, PeriodComplete } from '@quromedical/fhir-common';
import { Admission } from '@quromedical/models';
import {
  admissionClassDisplay,
  admissionServiceDisplay,
  admissionStatusDisplay,
  getDisplay,
  getDisplayDate,
  getQuantityDisplay,
} from 'core/display';
import { FileCard, FormCard, Grid, RowConfig } from 'design-system';
import compact from 'lodash.compact';
import React from 'react';
import { strings } from 'strings';

import { statusColor } from './internal';

interface AdmissionCardProps {
  data?: Admission.GetResponseWithVitals;
  canEdit?: boolean;
  canViewMedia?: boolean;
  onEditPress?: () => void;
  downloadFile: DownloadFile;
}

type DownloadFile = (mediaId: string) => Promise<void>;

const periodIsComplete = (period?: Period): period is PeriodComplete => {
  if (!period) {
    return false;
  }

  const periodEnd = (period as PeriodComplete).end;

  return !!periodEnd;
};

const createRows = (
  downloadAttachment: DownloadFile,
  admission: Partial<Admission.GetResponseWithVitals> = {},
  canViewMedia = false
): RowConfig[] => {
  const {
    class: cls,
    icd10Codes = [],
    medicalAidAuthorization,
    oxygen,
    period,
    service,
    status,
    media = [],
  } = admission;

  const start = period?.start;
  const end = periodIsComplete(period) ? period.end : undefined;

  return compact([
    {
      fields: [
        {
          type: 'text',
          label: strings.AdmissionStatusLabel,
          display: admissionStatusDisplay[status || 'unknown'],
        },
      ],
    },
    {
      fields: [
        {
          type: 'text',
          label: strings.AdmissionServiceLabel,
          display: service && admissionServiceDisplay[service],
        },
        {
          type: 'text',
          label: strings.AdmissionClass,
          display: cls && admissionClassDisplay[cls],
        },
      ],
    },
    {
      fields: [
        {
          type: 'text',
          label: strings.AdmissionStartLabel,
          display: getDisplayDate(start),
        },
        {
          type: 'text',
          label: strings.AdmissionEndLabel,
          display: getDisplayDate(end),
        },
      ],
    },
    medicalAidAuthorization
      ? {
          fields: [
            {
              type: 'text',
              label: strings.AdmissionAuthorizationDateLabel,
              display: getDisplayDate(medicalAidAuthorization?.date),
            },
            {
              type: 'text',
              label: strings.AdmissionAuthorizationCodeLabel,
              display: medicalAidAuthorization?.code,
            },
          ],
        }
      : undefined,
    {
      fields: compact([
        {
          type: 'list',
          label: strings.FormLabelICD10Codes,
          display: icd10Codes.map((code) => getDisplay(code, 'code: display')),
        },
        oxygen
          ? {
              type: 'text',
              label: strings.RequiredOxygenFormLabel,
              display: getQuantityDisplay(oxygen),
            }
          : undefined,
      ]),
    },
    canViewMedia
      ? {
          fields: [
            {
              type: 'custom',
              Display: () => (
                <Grid sm={1} md={2} lg={3} xl={4} gap="xs">
                  {media.map((m) => (
                    <FileCard
                      name={m.display || strings.DisplayNotFound}
                      onPress={() => downloadAttachment(getCreateReferenceId(m))}
                    />
                  ))}
                </Grid>
              ),
            },
          ],
        }
      : undefined,
  ]);
};

export const AdmissionCard: React.FC<AdmissionCardProps> = ({
  data,
  canEdit,
  canViewMedia,
  onEditPress,
  downloadFile,
}) => {
  const borderColor = statusColor[data?.status || 'unknown'];

  const rows = createRows(downloadFile, data, canViewMedia);
  return (
    <FormCard
      borderColor={borderColor}
      title={strings.AdmissionCardTitle}
      rows={rows}
      onButtonPress={onEditPress}
      buttonText={strings.ButtonTextEdit}
      isButtonVisible={canEdit}
      buttonIcon="edit"
    />
  );
};
