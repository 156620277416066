import { createFhirDateTime, createFhirDate } from '@quromedical/fhir-common';
import { parse, isValid, format, addMinutes } from 'date-fns';

import { MaskType, Reshaper, Parser } from './types';

export const maskFormats: Record<MaskType, string> = {
  date: 'DD/MM/YYYY',
  datetime: 'DD/MM/YYYY hh:mm',
};

const dateFnsDateFormat = 'dd/MM/yyyy';
const dateFnsDateTimeFormat = 'dd/MM/yyyy HH:mm';

const parseMaskedDate: Parser<Date> = (value?: string) => {
  if (!value) {
    return undefined;
  }

  if (value.length < maskFormats.date.length) {
    return undefined;
  }

  // we have to use a new Date because it needs a relative date (but this introduces a zone offset)
  // however the `parse` function will not represent this according to UTC
  const relative = new Date();
  const parsed = parse(value, dateFnsDateFormat, relative);

  if (!isValid(parsed)) {
    return undefined;
  }

  // append timezone offset since the date needs to be UTC relative to avoid funny timezone issues
  const offsetCorrected = addMinutes(parsed, -relative.getTimezoneOffset());

  return offsetCorrected;
};

const parseMaskedDateTime: Parser<Date> = (value?: string) => {
  if (!value) {
    return undefined;
  }

  if (value.length < maskFormats.datetime.length) {
    return undefined;
  }

  const parsed = parse(value, dateFnsDateTimeFormat, new Date());

  if (!isValid(parsed)) {
    return undefined;
  }

  return parsed;
};

const dateFormatter = (value?: Date) => {
  if (!value) {
    return '';
  }

  return format(value, dateFnsDateFormat);
};

const dateTimeFormatter = (value?: Date) => {
  if (!value) {
    return '';
  }

  return format(value, dateFnsDateTimeFormat);
};

const dateInputReshaper: Reshaper = (isoDate: string) => {
  const parsed = new Date(isoDate);

  if (!isValid(parsed)) {
    return undefined;
  }

  return dateFormatter(parsed);
};

const datetimeInputReshaper: Reshaper = (isoDate: string) => {
  const parsed = new Date(isoDate);

  if (!isValid(parsed)) {
    return undefined;
  }

  return dateTimeFormatter(parsed);
};

const dateOutputReshaper: Reshaper = (isoDate: string) => {
  const parsed = parseMaskedDate(isoDate);

  if (!parsed) {
    return undefined;
  }

  const result = createFhirDate(parsed);

  return result;
};

const datetimeOutputReshaper: Reshaper = (isoDate: string) => {
  const parsed = parseMaskedDateTime(isoDate);

  if (!parsed) {
    return undefined;
  }

  return createFhirDateTime(parsed);
};

export const maskedInputFormatter = {
  date: dateFormatter,
  datetime: dateTimeFormatter,
};

export const maskedInputParser = {
  date: parseMaskedDate,
  datetime: parseMaskedDateTime,
};

export const inputReshapers: Record<MaskType, Reshaper> = {
  date: dateInputReshaper,
  datetime: datetimeInputReshaper,
};

export const outputReshapers: Record<MaskType, Reshaper> = {
  date: dateOutputReshaper,
  datetime: datetimeOutputReshaper,
};
