import styled from '@emotion/native';
import { ColorName, useTheme } from '@emotion/react';
import * as Graph from 'components/graph';
import { extent } from 'd3-array';
import { hoursToMilliseconds } from 'date-fns';
import { Visible, IconName, Skeleton, SkeletonProvider, Card } from 'design-system/base';
import { MARGIN } from 'design-system/theme';
import {
  integerFormatter,
  noOp,
  timeWithMillisecondsFormatter,
  timeWithSecondsFormatter,
  dateTimeWithoutYearFormatter,
} from 'helpers';
import { useLayoutDimensions } from 'hooks/useLayoutDimensions';
import { useIsSmallerThan } from 'hooks/useResponsive';
import React, { ReactElement, useCallback, useState } from 'react';
import { useAsyncDebounce } from 'react-table';

import { Header } from '../header';
import { Slider } from '../slider';
import { Spline, SplineProps } from '../spline';
import { BodyWrapper } from './BodyWrapper';
import { FilterItem } from './FilterSection';
import { Footer } from './Footer';
import { FooterLegendProps } from './LegendSection';
import { NoDataFallback } from './NoDataFallback';
import { SecondaryData, SecondaryDisplay } from './SecondaryDisplay';
import { Summary, SummaryProps } from './Summary';
import { SummarySection } from './SummarySection';
import { SummaryWrapper } from './SummaryWrapper';
import { Tooltip } from './Tooltip';
import { GradientPoint } from './types';
import { useTooltipProps } from './useTooltipProps';

export interface VitalCardSliderProps {
  initialValue: number;
  onChange: (value: number) => void;
}

export type VitalCardSplineProps = Omit<SplineProps, 'initialWidth'> & {
  isLoading?: boolean;
};

type TimeFormat = 'HH:MM' | 'dd mmm';

const timeFormatters: Record<TimeFormat, (time?: number) => string> = {
  'HH:MM': timeWithSecondsFormatter,
  'dd mmm': dateTimeWithoutYearFormatter,
};

const twentyFourHoursMs = hoursToMilliseconds(24);

const resolveTimeFormatter = (xDomain?: [number, number]) => {
  if (!xDomain) {
    return timeFormatters['HH:MM'];
  }

  const timeDiff = Math.abs(xDomain[0] - xDomain[1]);
  const timeFormat: TimeFormat = timeDiff > twentyFourHoursMs ? 'dd mmm' : 'HH:MM';

  const timeFormatter = timeFormatters[timeFormat];
  return timeFormatter;
};

interface VitalCardProps<TIdentifier extends string> {
  /**
   * Uniquely identifies the active value for a card, used to determine which metric is active
   */
  identifier: TIdentifier;
  title: string;
  code: string;
  /**
   * Note that if the Icon is not provided then the code will be used as a fallback
   */
  icon?: IconName;
  data?: Graph.Datum[];
  latestValue?: Graph.Datum;
  color: ColorName;
  unit?: string;
  yDomain?: [number, number];
  xDomain?: [number, number];
  showScatter?: boolean;
  /**
   * Component will automatically calculate the SVG width after render, but an initial width
   * estimate is useful in order to minimize the layout shift after the first
   */
  initialWidth: number;
  gradient?: GradientPoint[];
  valueFormatter?: (value?: number) => string;
  timeFormat?: TimeFormat;
  shortValueFormatter?: (value?: number) => string;
  onExpandPress?: () => void;
  isExpanded?: boolean;
  showAddButton?: boolean;
  onAddPress?: () => void;
  spline?: VitalCardSplineProps;
  slider?: VitalCardSliderProps;
  secondaryData?: SecondaryData[];
  showSecondaryData?: boolean;
  debounceTime?: number;
  showTooltip?: boolean;
  hideSummary?: boolean;
  legend?: FooterLegendProps<TIdentifier>[];
  filters?: FilterItem<string>[];
  showFilter?: boolean;
  onFilterChange?: (filter?: string) => void;
  initialFilter?: string;
  /**
   * Override the default summary
   */
  summary?: SummaryProps[];
}

const GraphWrapper = styled.View({
  marginTop: MARGIN.s,
});

const hasSecondaryData = (secondaryData: SecondaryData[]): boolean => {
  const hasData = secondaryData.find((item) => item.data.length > 0);

  return !!hasData;
};

export const VitalCard = <TIdentifier extends string>({
  identifier,
  initialWidth,
  color,
  code,
  icon,
  title,
  unit,
  gradient = [],
  latestValue,
  data = [],
  valueFormatter = integerFormatter,
  shortValueFormatter = valueFormatter,
  xDomain: xDom,
  yDomain: yDom,
  isExpanded = false,
  spline,
  onExpandPress,
  showAddButton = false,
  onAddPress,
  slider,
  debounceTime = 500,
  showScatter = false,
  showTooltip = false,
  hideSummary = false,
  secondaryData = [],
  showSecondaryData = false,
  summary = [],
  legend,
  filters = [],
  initialFilter,
  showFilter,
  onFilterChange,
}: VitalCardProps<TIdentifier>): ReactElement => {
  const theme = useTheme();
  const { layout, onLayout } = useLayoutDimensions({ initialWidth });
  const [sliderValue, setSliderValue] = useState<number>(slider?.initialValue || 0);

  const sliderChangeDebounced = useAsyncDebounce(slider?.onChange || noOp, debounceTime);

  const isSmall = useIsSmallerThan('sm');

  const { closest, tooltipText, onHover, hover } = useTooltipProps(
    showTooltip,
    data,
    valueFormatter,
    unit
  );

  const onSliderChange = useCallback(
    (value: number) => {
      setSliderValue(value);
      sliderChangeDebounced(value);
    },
    [sliderChangeDebounced]
  );

  const ticks = isExpanded && !isSmall ? 10 : 5;
  const aspectRatio = isExpanded ? 3 : 2;
  const expandIcon = isExpanded ? 'collapse' : 'expand';

  const xExtent = extent(data, (d) => d?.x);
  const yExtent = extent(data, (d) => d?.y);

  const xResolvedDomain = Graph.getFullDomain(xExtent);
  const yResolvedDomain = Graph.getFullDomain(yExtent);

  const xDomain = xDom || xResolvedDomain;
  const yDomain = yDom || yResolvedDomain;

  const timeFormatter = resolveTimeFormatter(xDomain);

  // each gradient must have a unique id on the page
  const gradientId = `path-gradient-${code}-${Math.random()}`;

  const baseColor = theme.color[color];

  const hasGradient = gradient?.length > 0;
  const stops = gradient.map<Graph.StopProps>((stop) => ({
    offsetVal: stop.value,
    color: stop.color ? theme.color[stop.color] : baseColor,
  }));

  const showSpline = isExpanded && spline;
  const showSlider = isExpanded && slider;

  const hasData = data?.length > 0 || hasSecondaryData(secondaryData);

  const initialFooterSelection = showSecondaryData ? undefined : identifier;

  const [selectedLegend, setSelectedLegend] = useState<TIdentifier | undefined>(
    initialFooterSelection
  );

  const showAll = showSecondaryData && selectedLegend === undefined;

  const isPrimarySelected = selectedLegend === identifier;
  const showPrimaryGraph = showAll || isPrimarySelected;
  const primaryTooltipVisible = showTooltip && showPrimaryGraph;

  return (
    <Card paddingHorizontal={0} paddingVertical={0} overflow="hidden">
      <Header
        onPrimaryButtonPress={onExpandPress}
        title={title}
        icon={icon}
        iconText={code}
        blockColor={color}
        primaryButtonIcon={expandIcon}
        showPrimaryButton={!!onExpandPress}
        secondaryButtonIcon="add"
        onSecondaryButtonPress={onAddPress}
        showSecondaryButton={showAddButton}
      />

      <BodyWrapper>
        <Skeleton>
          <Visible if={!hideSummary}>
            <Visible if={!summary.length}>
              <SummarySection
                data={data}
                unit={unit}
                latestValue={latestValue}
                xFormatter={valueFormatter}
              />
            </Visible>
            <Visible if={summary.length}>
              <SummaryWrapper>
                {summary.map((value) => (
                  <Summary fallbackValue="--" {...value} />
                ))}
              </SummaryWrapper>
            </Visible>
          </Visible>
        </Skeleton>

        <GraphWrapper onLayout={onLayout}>
          <Skeleton>
            <Graph.LinearPlot
              overflow="visible"
              xDomain={xDomain}
              yDomain={yDomain}
              baseWidth={layout.width}
              aspectRatio={aspectRatio}
              useNiceScaleY
              onHoverDebounce={0}
              onHover={onHover}
              // use selecting as a replacement for hovering on native
              onSelectEnd={onHover}
            >
              <Visible if={hasGradient}>
                <Graph.LinearGradient
                  relativeDomain={yResolvedDomain}
                  id={gradientId}
                  direction="vertical"
                  stops={stops}
                />
              </Visible>

              <Visible if={hasData}>
                <Graph.Grid yTicks={ticks} yGrid stroke={theme.color.divider} />
              </Visible>

              <Visible if={showSlider}>
                <Graph.XLine
                  xVal={sliderValue}
                  stroke={theme.color['text-default']}
                  strokeWidth={2}
                />
              </Visible>

              <Visible if={showSecondaryData}>
                {secondaryData.map((secondary) => {
                  const isSelected = selectedLegend === secondary.code;
                  const isVisible = showAll || isSelected;
                  if (!isVisible) {
                    return null;
                  }

                  return (
                    <SecondaryDisplay
                      {...secondary}
                      hover={hover}
                      showTooltip={showTooltip && isSelected}
                    />
                  );
                })}
              </Visible>

              <Visible if={hasData && showPrimaryGraph}>
                <Graph.PlotLine
                  data={data}
                  stroke={baseColor}
                  strokeWidth={2}
                  gradientId={hasGradient ? gradientId : undefined}
                  stops={stops}
                />
              </Visible>

              <Visible if={showScatter}>
                <Graph.PlotScatter data={data} fill={baseColor} radius={3} stops={stops} />
              </Visible>

              <Visible if={primaryTooltipVisible}>
                <Tooltip
                  closest={closest}
                  baseColor={baseColor}
                  stops={stops}
                  tooltipText={tooltipText}
                />
              </Visible>

              <Visible if={hasData}>
                <Graph.Grid
                  overflow="hidden"
                  xTicks={ticks}
                  yTicks={ticks}
                  axis
                  yFormatter={shortValueFormatter}
                  xFormatter={timeFormatter}
                  color={theme.color['text-default']}
                />
              </Visible>

              <Visible if={!hasData}>
                <NoDataFallback />
              </Visible>
            </Graph.LinearPlot>
          </Skeleton>
        </GraphWrapper>

        <Visible if={showSlider}>
          <Slider
            hasTrack
            hasText
            isVisible={!!showSlider}
            initialWidth={layout.width}
            xDomain={xDomain}
            initialValue={slider?.initialValue}
            onChange={onSliderChange}
            valueFormatter={timeWithSecondsFormatter}
            markerColor="text-default"
          />
        </Visible>

        <Visible if={showSpline}>
          <GraphWrapper>
            <SkeletonProvider loading={!!spline?.isLoading}>
              <Skeleton>
                <Spline
                  showGrid
                  strokeWidth={2}
                  gridStrokeWidth={1}
                  xFormatter={timeWithMillisecondsFormatter}
                  initialWidth={layout.width}
                  gridColor="divider"
                  {...(spline || {})}
                />
              </Skeleton>
            </SkeletonProvider>
          </GraphWrapper>
        </Visible>
      </BodyWrapper>

      <Footer<TIdentifier>
        legend={{
          legend,
          visible: showSecondaryData,
          initialSelection: initialFooterSelection,
          onSelect: setSelectedLegend,
        }}
        filter={{
          filters,
          initialSelection: initialFilter,
          onSelect: onFilterChange,
          visible: showFilter,
        }}
      />
    </Card>
  );
};
