import { DeviceAssociation } from '@quromedical/models';
import {
  PatientDeviceUseStatementList,
  CreateDeviceAssociationForm,
} from 'core/device-use-statement';
import {
  Alert,
  Section,
  Skeleton,
  SkeletonProvider,
  Snackbar,
  ToggleCard,
  Visible,
} from 'design-system';
import { logger } from 'helpers';
import { parseError } from 'helpers/error';
import { useRevalidation } from 'hooks/useFhirData';
import { useFiniteState } from 'hooks/useFiniteState';
import { activeDeviceFetcher } from 'integration';
import { PatientApi } from 'integration/aggregate';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';

import { PatientRelayStatus } from '../cards';

interface PatientDevicesTabProps {
  patientId: string;
  canCreateDeviceUseStatement: boolean;
}

const patientApi = new PatientApi();

type AssociationFormState = 'form' | 'submitting' | 'error';

export const PatientDevicesTab: React.FC<PatientDevicesTabProps> = ({
  patientId,
  canCreateDeviceUseStatement,
}) => {
  const associationFormState = useFiniteState<AssociationFormState>('form');
  const [associationErrorMessage, setAssociationErrorMessage] = useState<string>();

  // using FHIR revalidation since the data displaying this is still from the FHIR form
  const revalidateFhir = useRevalidation('DeviceUseStatement');

  const onCreateAssociation = useCallback(
    async (association: DeviceAssociation.PatientDeviceAssociationCreateRequest) => {
      associationFormState.set('submitting');
      try {
        await patientApi.createDeviceAssociation(patientId, association);
        associationFormState.set('form');
        revalidateFhir().catch(logger.error);
      } catch (err) {
        setAssociationErrorMessage(parseError(err));
        associationFormState.set('error');
      }
    },
    [associationFormState, patientId, revalidateFhir]
  );

  return (
    <>
      <Visible if={canCreateDeviceUseStatement}>
        <SkeletonProvider loading={associationFormState.is('submitting')}>
          <Snackbar
            isOpen={associationFormState.is('error')}
            onClose={associationFormState.next('form')}
          >
            <Alert
              backgroundColor="status-critical"
              textColor="white"
              onAction={associationFormState.next('form')}
              actionIcon="close"
              title={strings.ErrorCardTitle}
              body={associationErrorMessage || strings.GenericErrorMessage}
            />
          </Snackbar>

          <Section unsetZIndex>
            <ToggleCard
              title={strings.TitleCreateDeviceAssociation}
              textExpand={strings.LabelCreateDeviceAssociation}
              textCollapse={strings.LabelCollapse}
              icon="devices"
            >
              <Skeleton>
                <CreateDeviceAssociationForm
                  onSubmit={onCreateAssociation}
                  fetcherFactory={activeDeviceFetcher}
                />
              </Skeleton>
            </ToggleCard>
          </Section>
        </SkeletonProvider>
      </Visible>

      <Section>
        <PatientRelayStatus patientId={patientId} />
      </Section>

      <Section hasBottomMargin>
        <PatientDeviceUseStatementList patientId={patientId} />
      </Section>
    </>
  );
};
