import { Patient, Person } from '@quromedical/models';
import { Row } from 'components/base';
import { Button, Section, Visible, Text } from 'design-system';
import { FormikErrors } from 'formik';
import { logger } from 'helpers';
import { getAddressPartialFetcher, googlePlaceFetcher } from 'integration';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import { JointFields } from 'screens/patient';
import { strings } from 'strings';

import { ContactForm } from './ContactDetailsForm';
import { GeneralForm } from './GeneralForm';
import { NextOfKinForm } from './NextOfKinDetailsForm';

interface PrivateOnboardFormProps {
  onSubmit: (privatePatientOnBoard: Patient.PrivateOnboardRequest) => Promise<void>;
}

interface FormState<TForm> {
  state: TForm;
  errors: FormikErrors<TForm>;
}

export const PrivateOnboardForm: React.FC<PrivateOnboardFormProps> = ({ onSubmit }) => {
  const [showErrors, setShowErrors] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [patientGeneral, setPatientGeneral] = useState<FormState<Partial<Person.General>>>({
    state: {},
    errors: {},
  });

  const [patientContact, setPatientContact] = useState<FormState<Partial<Person.Contact>>>({
    state: {},
    errors: {},
  });

  const [nextOfKin, setNextOfKin] = useState<FormState<Partial<JointFields>>>({
    state: {},
    errors: {},
  });

  const onPatientGeneralChange = useCallback(
    (data: Partial<Person.General>, errors: FormikErrors<Partial<Person.General>>) => {
      setPatientGeneral({ state: data, errors });
    },
    [setPatientGeneral]
  );

  const onPatientContactChange = useCallback(
    (data: Partial<Person.Contact>, errors: FormikErrors<Partial<Person.Contact>>) => {
      setPatientContact({ state: data, errors });
    },
    [setPatientContact]
  );

  const onNextOfKinChange = useCallback(
    (data: Partial<JointFields>, errors: FormikErrors<Partial<JointFields>>) => {
      setNextOfKin({ state: data, errors });
    },
    [setNextOfKin]
  );

  const handleSubmit = async () => {
    const generalErrorCount = Object.values(patientGeneral.errors).length;
    const contactErrorCount = Object.values(patientContact.errors).length;
    const nextOfKinErrorCount = Object.values(nextOfKin.errors).length;

    const hasErrors = generalErrorCount > 0 || contactErrorCount > 0 || nextOfKinErrorCount > 0;

    if (hasErrors) {
      setShowErrors(true);
      setHasError(false);
      return;
    }

    // data in form has been validated so we can cast
    const nextOfKinData = nextOfKin.state as JointFields;

    try {
      // on submit, we should have validated that all these fields are of the full required data
      await onSubmit({
        patient: {
          general: patientGeneral.state as Person.General,
          contact: patientContact.state as Person.Contact,
        },
        nextOfKin: {
          contact: nextOfKinData,
          general: nextOfKinData,
        },
      });
      setShowErrors(false);
    } catch (err) {
      logger.error(err);
      setHasError(true);
    }
  };

  return (
    <>
      <Section>
        <GeneralForm onChange={onPatientGeneralChange} showErrors={showErrors} />
      </Section>
      <Section>
        <ContactForm
          onChange={onPatientContactChange}
          showErrors={showErrors}
          addressFetcher={googlePlaceFetcher}
          getAddressPartial={getAddressPartialFetcher}
        />
      </Section>
      <Section>
        <NextOfKinForm
          onChange={onNextOfKinChange}
          showErrors={showErrors}
          addressFetcher={googlePlaceFetcher}
          getAddressPartial={getAddressPartialFetcher}
        />
      </Section>
      <Section hasBottomMargin>
        <Row alignItems="flex-end" justifyContent="space-between">
          <Visible if={hasError}>
            <View>
              <Text>{strings.LabelPrivatePatientOnboardError}</Text>
            </View>
          </Visible>
        </Row>
        <Row alignItems="flex-end" justifyContent="space-between">
          <Button
            onPress={handleSubmit}
            text={strings.ButtonTextOnboardPatient}
            icon="person-add-alt-1"
            size="large"
          />
        </Row>
      </Section>
    </>
  );
};
