import styled from '@emotion/native';
import { Row, Col } from 'components/base';
import { FONT_FAMILY, MARGIN, SPACING } from 'design-system/theme';
import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import {
  NativeSyntheticEvent,
  NativeTouchEvent,
  TextInput,
  TextInputKeyPressEventData,
  TextInputProps,
} from 'react-native';

import { Wrapper } from '../internal';

interface OneTimePinInputProps {
  size: number;
  onChange(pin: string): void;
  disabled?: boolean;
}

interface TextInputPropsWeb {
  onMouseDown?: TextInputProps['onPressIn'];
}
const Input = styled.TextInput<TextInputPropsWeb>(({ theme }) => ({
  height: SPACING['3xl'],
  color: theme.color['text-default'],
  width: '100%',
  fontFamily: FONT_FAMILY.regular,
  fontSize: 14,
  textAlign: 'center',
}));

type PinRecord = Record<number, string>;
/**
 * Transforms a string to a `PinRecord` equivalent
 *
 * @param otp - The otp value
 *
 * @returns A record mapping the index to the value
 */
const createPinMap = (otp: string): PinRecord => {
  const values = otp.split('');

  return values.reduce(
    (memo, value, idx) => ({
      ...memo,
      [idx]: value,
    }),
    {}
  );
};

const InputWrapper = styled(Col)({
  marginHorizontal: MARGIN['3xs'],
});

export const OneTimePinInput: React.FC<OneTimePinInputProps> = (props) => {
  const { size, onChange: onUpdate, disabled = false } = props;
  const array = useMemo(() => new Array<string>(size).fill(''), [size]);
  const [pin, setPin] = useState<PinRecord>(
    array.reduce(
      (memo, _, idx) => ({
        ...memo,
        [idx]: '',
      }),
      {}
    )
  );

  const refs = useMemo(
    () =>
      array.map(() => {
        const ref = createRef<TextInput>();
        const key = Math.random().toString();
        return {
          ref,
          key,
        };
      }),
    [array]
  );

  const getRef = useCallback(
    (idx: number) => {
      const item = refs[idx];

      return item?.ref;
    },
    [refs]
  );
  const onPaste = useCallback(
    (text: string) => {
      const otp = createPinMap(text);
      setPin(otp);
      const ref = getRef(size);
      ref?.current?.focus();
    },
    [getRef, size]
  );
  const onChange = useCallback(
    (change: string, idx: number) => {
      const text = change.replace(/\s/g, '').substring(0, size);
      const isPaste = text.length === size;
      if (isPaste) {
        onPaste(text);
        return;
      }
      const value = text.split('').pop();

      const ref = getRef(idx + 1);
      if (!value) {
        return;
      }
      setPin({
        ...pin,
        [idx]: value,
      });

      ref?.current?.focus();
    },
    [pin, getRef, size, onPaste]
  );

  useEffect(() => {
    const values = Object.values(pin);
    const value = values.join('');
    onUpdate(value);
  }, [pin, onUpdate]);

  const onKeyPress = useCallback(
    (event: NativeSyntheticEvent<TextInputKeyPressEventData>, idx: number) => {
      const isBackspace = event.nativeEvent.key === 'Backspace';
      if (isBackspace) {
        event.preventDefault();
        setPin({
          ...pin,
          [idx]: '',
        });
        const ref = getRef(idx - 1);
        ref?.current?.focus();
      }
    },
    [getRef, pin]
  );

  const onPressIn = useCallback(
    (event: NativeSyntheticEvent<NativeTouchEvent>, idx: number) => {
      if (idx === 0) {
        return;
      }
      const values = Object.values(pin);
      const index = values.findIndex((value) => !value);
      if (index !== -1) {
        event.preventDefault();
        const ref = getRef(index);
        ref?.current?.focus();
      }
    },
    [pin, getRef]
  );

  return (
    <Row flex={1}>
      {refs.map(({ ref, key }, idx) => (
        <Col flex={1} key={key}>
          <InputWrapper>
            <Wrapper>
              <Input
                ref={ref}
                editable={!disabled}
                value={pin[idx]}
                onChangeText={(text) => onChange(text, idx)}
                onKeyPress={(e) => onKeyPress(e, idx)}
                onPressIn={(e) => onPressIn(e, idx)}
                onMouseDown={(e) => onPressIn(e, idx)}
              />
            </Wrapper>
          </InputWrapper>
        </Col>
      ))}
    </Row>
  );
};
