import { line } from 'd3-shape';
import React from 'react';
import { Path } from 'react-native-svg';

import { StopProps } from './LinearGradient';
import { usePlotDataContext } from './PlotDataProvider';
import { createGradientScale, resolveColor } from './resolveColor';
import { Datum, ExternalComponentProps } from './types';

interface PlotLineProps {
  stroke?: string;
  strokeWidth?: number;
  /**
   * The stops for the gradient need to be provided if it's being used in order to determine the
   * stroke for cases where all values in the line may be the same
   */
  stops?: StopProps[];
  strokeDasharray?: number | number[];
  gradientId?: string;
}

type ExternalProps = ExternalComponentProps & PlotLineProps;

const getVerticalConstant = (data: Datum[]): number | undefined => {
  const first = data[0];
  if (!first) {
    return undefined;
  }

  const allEqual = data.every((d) => d.y === first.y);

  if (!allEqual) {
    return undefined;
  }

  return first.y;
};

export const PlotLine: React.FC<ExternalProps> = (props) => {
  const {
    xScale,
    yScale,
    data,
    stops = [],
    stroke = 'lightgrey',
    strokeWidth = 1,
    strokeDasharray,
    gradientId,
  } = usePlotDataContext(props);

  const lineCommand = line<Datum>()
    .x((d) => xScale(d.x))
    .y((d) => yScale(d.y))(data) as string;

  const yConstant = getVerticalConstant(data);
  const isHorizontal = yConstant !== undefined;

  const hasStops = stops.length;
  const first = data[0];
  const useConstantColor = isHorizontal && hasStops && first;

  const gradientScale = createGradientScale('vertical', yScale, xScale, stops);
  const constantColor = isHorizontal ? gradientScale(yScale(yConstant)) : undefined;

  const resolvedStroke = useConstantColor ? constantColor : resolveColor(stroke, gradientId);

  return (
    <Path
      d={lineCommand}
      strokeDasharray={strokeDasharray}
      strokeWidth={strokeWidth}
      stroke={resolvedStroke}
      fill="none"
    />
  );
};
