import { GroupSecurity } from '@quromedical/fhir-common';
import { Group } from '@quromedical/models';
import { useLinkTo } from '@react-navigation/native';
import { CrudForm } from 'components/form';
import { WithGroup } from 'core/auth';
import { TableGroup, initialSearch, searchFields, CreateGroupCard, columnsLarge } from 'core/group';
import { PaginatedTable } from 'core/table';
import { Section, ToggleCard } from 'design-system';
import { GroupApi } from 'integration/aggregate';
import { GroupStackScreenProps } from 'navigation';
import React, { useCallback, useState } from 'react';
import { ScrollView } from 'react-native';
import { strings } from 'strings';

const api = new GroupApi();

const getDataFromResponse = (res: Group.GetAllResponse): TableGroup[] => res.groups;

export const GroupListScreen: React.FC<GroupStackScreenProps<'List'>> = () => {
  const [search, setSearch] = useState<Group.GetAllParams>(initialSearch);
  const navigate = useLinkTo();

  const fetcher = useCallback(
    (pageToken?: string) =>
      api.getGroups({
        pageToken,
        code: search.code,
      }),
    [search.code]
  );

  const onRowPress = useCallback(
    (row: TableGroup) =>
      navigate({
        screen: 'Admin',
        params: {
          screen: 'Group',
          params: { screen: 'View', params: { id: row.id } },
        },
      }),
    [navigate]
  );

  return (
    <ScrollView>
      <WithGroup groups={[GroupSecurity.GroupCreate]}>
        <Section unsetZIndex>
          <ToggleCard
            icon="people-outline"
            title={strings.GroupToggleCardTitle}
            textExpand={strings.GroupToggleCardExpandButtonText}
            textCollapse={strings.GroupToggleCardCollapseButtonText}
            contentWrapperProps={{ paddingHorizontal: 0, paddingVertical: 0 }}
          >
            <CreateGroupCard />
          </ToggleCard>
        </Section>
      </WithGroup>
      <Section unsetZIndex>
        <CrudForm
          cardProps={{ unsetZIndex: true }}
          fields={searchFields}
          initialValues={initialSearch}
          showSubmitButton={false}
          onChange={setSearch}
        />
      </Section>
      <Section unsetZIndex>
        <PaginatedTable
          cacheKey={`Group${JSON.stringify(search)}`}
          fetcher={fetcher}
          onRowPress={onRowPress}
          columns={columnsLarge}
          getDataFromResponse={getDataFromResponse}
        />
      </Section>
    </ScrollView>
  );
};
