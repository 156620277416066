import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

interface LogoProps {
  size?: number;
}
export const Logo: React.FC<LogoProps> = ({ size = 42 }) => (
  <Svg width={size} height={size} fill="none" viewBox="0 0 42 42">
    <Path fill="#FF595A" d="M0 0h42v42H0z" />
    <Path
      d="M35.089 33.494h-14c-1.88 0-3.623-.281-5.232-.842-1.585-.584-2.967-1.404-4.145-2.46-1.155-1.077-2.072-2.38-2.752-3.907-.657-1.55-.985-3.29-.985-5.221 0-1.752.328-3.358.985-4.817a11.691 11.691 0 0 1 2.752-3.807 12.276 12.276 0 0 1 4.01-2.492c1.54-.607 3.17-.91 4.892-.91 1.722 0 3.341.303 4.859.91a12.194 12.194 0 0 1 4.043 2.492 11.28 11.28 0 0 1 2.718 3.807c.68 1.46 1.02 3.065 1.02 4.817 0 1.257-.159 2.369-.476 3.335a9.654 9.654 0 0 1-1.19 2.526 8.657 8.657 0 0 1-1.664 1.819c-.589.494-1.167.91-1.733 1.246v.068h6.898v3.436ZM20.512 12.878a8.114 8.114 0 0 0-3.228.64 7.6 7.6 0 0 0-2.548 1.718 8.278 8.278 0 0 0-1.665 2.628c-.407 1.01-.611 2.11-.611 3.3a9.36 9.36 0 0 0 .611 3.403 8.287 8.287 0 0 0 1.7 2.695 8.04 8.04 0 0 0 2.547 1.752c.997.426 2.084.64 3.262.64s2.265-.214 3.262-.64a7.852 7.852 0 0 0 2.616-1.752 8.29 8.29 0 0 0 1.7-2.695 9.36 9.36 0 0 0 .61-3.402c0-1.19-.203-2.29-.61-3.301a7.987 7.987 0 0 0-1.7-2.628 7.448 7.448 0 0 0-2.616-1.718c-.997-.427-2.107-.64-3.33-.64Z"
      fill="#fff"
    />
  </Svg>
);
