import { Row } from 'components/base';
import { Section } from 'design-system';
import React from 'react';

import { DashboardLoading, Search } from './cards';

interface DashboardErrorGridProps {
  error?: boolean;
}

export const DashboardErrorGrid: React.FC<DashboardErrorGridProps> = ({ error = false }) => (
  <Section flex={1} fullHeight>
    <Row>
      <Search layout="grid" />
    </Row>
    <Row>
      <DashboardLoading error={error} />
    </Row>
  </Section>
);
