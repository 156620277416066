import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { BASE_SIZE } from 'design-system/theme';
import React from 'react';

import { IconName } from './custom-icons';
import { Icon } from './Icon';

interface IconCardProps {
  name: IconName;
  iconColor?: ColorName;
  blockColor?: ColorName;
}

interface WrapperProps {
  color: ColorName;
}

const Wrapper = styled.View<WrapperProps>(({ theme, color }) => ({
  backgroundColor: theme.color[color],
  height: BASE_SIZE[48],
  width: BASE_SIZE[42],
  alignItems: 'center',
  justifyContent: 'center',
}));

export const IconBlock: React.FC<IconCardProps> = ({
  iconColor = 'text-on-color',
  blockColor = 'text-default',
  name,
}) => (
  <Wrapper color={blockColor}>
    <Icon color={iconColor} name={name} size={BASE_SIZE[24]} />
  </Wrapper>
);
