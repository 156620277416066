import { ColorName, Theme, useTheme } from '@emotion/react';
import { Col, Row } from 'components/base';
import { Text } from 'design-system/base';
import React from 'react';

import { NestedComment } from '../nested-form';

interface CommentDisplayProps {
  comments: Partial<NestedComment.CommentWithPath>[][];
}

const isValidColor = (theme: Theme, color?: string): color is ColorName => {
  const foundColor = theme.color[color as ColorName];

  return !!foundColor;
};

const getValidColorName = (theme: Theme, color?: string): ColorName | undefined => {
  const isColor = isValidColor(theme, color);
  if (isColor) {
    return color;
  }

  return undefined;
};

const End = () => <Text>{'. '}</Text>;
const Dash = () => <Text>{' - '}</Text>;

export const CommentDisplay: React.FC<CommentDisplayProps> = ({ comments }) => {
  const theme = useTheme();

  return (
    <Col>
      {comments.map((section) => (
        <Row>
          {section.map((comment) => {
            if (!comment.text) {
              return (
                <Text>
                  <Text color={getValidColorName(theme, comment.color)}>
                    {comment.display || ''}
                  </Text>
                  <End />
                </Text>
              );
            }

            return (
              <Text>
                <Text color={getValidColorName(theme, comment.color)}>{comment.display || ''}</Text>
                <Dash />
                <Text>{comment.text}</Text>
                <End />
              </Text>
            );
          })}
        </Row>
      ))}
    </Col>
  );
};
