import { ColorName } from '@emotion/react';
import { EncounterService, Period } from '@quromedical/fhir-common';
import { Vitals, MeasurementName, Validations, Websocket, Admission } from '@quromedical/models';
import { OxygenSaturationScale, QuroScoreResult } from '@quromedical/news';
import { Util } from '@quromedical/utils';
import * as Graph from 'components/graph';
import { MILLISECONDS } from 'constants/time';
import { getPlanPeriodDisplay } from 'core/display';
import {
  codes,
  colors,
  icons,
  scales,
  titles,
  units,
  optionalValueFormatters,
  oxygenSaturationScales,
  staleDurations,
  measureFilter,
  gradientStops,
  news2RiskColorMap,
  news2ShortDisplayMap,
} from 'core/vitals';
import { IconName } from 'design-system/base';
import type {
  StatusCardMeasurement,
  SummaryProps,
  VitalSummaryCardHeaderMetric,
  MeasureTagProps,
  FooterLegendProps,
  SecondaryData as BaseSecondaryData,
} from 'design-system/components';
import { mergeObjects } from 'helpers';
import { formatMillisecondsToAgeString } from 'helpers/time';
import compact from 'lodash.compact';
import pickBy from 'lodash.pickby';
import { strings } from 'strings';

import { SafeScale } from './scales';

export const dashboardPrimaryMeasureNames: MeasurementName[] = [
  MeasurementName.RespiratoryRate,
  MeasurementName.CoreTemperature,
  MeasurementName.OxygenSaturation,
  MeasurementName.CombinedArterialBloodPressure,
];

export const dashboardSecondaryMeasureNames: MeasurementName[] = [
  MeasurementName.Steps,
  MeasurementName.Posture,
  MeasurementName.Glucose,
];

/**
 * Measures that are regarded as primary for all patients
 */
export const unconditionalPrimaryMeasures: MeasurementName[] = [
  MeasurementName.HeartRate,
  MeasurementName.RespiratoryRate,
  MeasurementName.CoreTemperature,
  MeasurementName.OxygenSaturation,
];

/**
 * Measures that are regarded as primary for only patients who have them
 */
export const conditionalPrimaryMeasures: MeasurementName[] = [
  MeasurementName.CombinedArterialBloodPressure,
  MeasurementName.Glucose,
];

export const getPatchBatteryProps = (
  latest: Vitals.LatestResultMeasures
): VitalSummaryCardHeaderMetric | undefined => {
  const battery = latest[MeasurementName.Battery];

  if (!battery) {
    return {};
  }

  const isPatchBattery = Validations.patchIdRegex.test(battery?.sensorId || '');

  if (!isPatchBattery) {
    return {};
  }

  return {
    value: battery.value,
    valueUnit: units[MeasurementName.Battery],
    valueIcon: 'battery-std',
  };
};

const relayIssue: VitalSummaryCardHeaderMetric = {
  valueIcon: 'phone-android',
  valueIconColor: 'status-warning',
};

const patchIssue: VitalSummaryCardHeaderMetric = {
  valueIcon: 'patch',
  valueIconColor: 'status-warning',
};

export const getServiceProps = (
  service?: EncounterService,
  period?: Period
): VitalSummaryCardHeaderMetric => ({
  icon: 'quro',
  iconColor: 'primary',
  name: getPlanPeriodDisplay(service, period),
});

export const getIssueProps = (
  canViewIssues: boolean,
  hasRelayIssue?: boolean,
  hasPatchIssue?: boolean
): VitalSummaryCardHeaderMetric[] => {
  if (!canViewIssues) {
    return [];
  }

  return compact([hasRelayIssue && relayIssue, hasPatchIssue && patchIssue]);
};

const fiveMinutesDuration = MILLISECONDS.minute * 5;

export const getNoteDisplay = (ts?: number): string | undefined => {
  if (!ts) {
    return undefined;
  }

  const now = new Date().getTime();
  const age = now - ts;

  const showAge = age > fiveMinutesDuration;

  if (!showAge) {
    return undefined;
  }

  return formatMillisecondsToAgeString(ts);
};

interface AggregatedValue {
  value: string;
  ts?: number;
  hasValue: boolean;
}

type ValueAggregator = (latest: Vitals.LatestResultMeasures) => AggregatedValue;

const fallback = '--';

export const bloodPressureAggregator: ValueAggregator = (latest) => {
  const systolic = latest[MeasurementName.SystolicArterialBloodPressure];
  const diastolic = latest[MeasurementName.DiastolicArterialBloodPressure];

  // we may want to add some logic to get these values and ensure that they're both within some kind
  // of window,at the moment BP device sends them at the same time so it should be fine for now
  const systolicBloodPressure = systolic?.value;
  const diastolicBloodPressure = diastolic?.value;

  const hasValue = systolicBloodPressure || diastolicBloodPressure;

  const value = `${systolicBloodPressure || fallback}/${diastolicBloodPressure || fallback}`;

  // using systolic for the ts since NEWS is also only based on this so it keeps it aligned
  const ts = systolic?.ts;

  return {
    value,
    ts,
    hasValue: !!hasValue,
  };
};

type VitalSummaryMeasureAggregator = (
  defaults: MeasureDisplayProps,
  latest: Vitals.LatestResultMeasures
) => MeasureDisplayProps;

const vitalSummaryCardMeasureAggregators: Partial<
  Record<MeasurementName, VitalSummaryMeasureAggregator>
> = {
  [MeasurementName.CombinedArterialBloodPressure]: (defaults, latest) => {
    // the NEWS score for blood pressure is only based on the SYS value
    const systolicBloodPressure = latest[MeasurementName.SystolicArterialBloodPressure]?.value;

    const scale = scales[MeasurementName.SystolicArterialBloodPressure];
    const newsValue = scale && scale(systolicBloodPressure);
    const showWarning = newsValue === 3;

    const color = showWarning ? 'risk-high' : colors[MeasurementName.CombinedArterialBloodPressure];

    const { value, ts, hasValue } = bloodPressureAggregator(latest);

    return {
      ...defaults,
      color,
      value,
      note: getNoteDisplay(ts),
      hasValue,
    };
  },
};

const getMeasureDefaults = (
  latest: Vitals.LatestResultMeasures,
  measureName: MeasurementName,
  useCodeTitle = false,
  scale: SafeScale | undefined = scales[measureName]
): MeasureDisplayProps => {
  const value = latest[measureName]?.value;

  const newsValue = scale && scale(value);
  const showWarning = newsValue === 3;

  const color = showWarning ? 'risk-high' : colors[measureName];
  const title = useCodeTitle ? codes[measureName] : titles[measureName];
  const valueString = optionalValueFormatters[measureName](value);
  const hasValue = !!valueString;
  const valueDisplay = hasValue ? valueString : fallback;

  return {
    color,
    title,
    unit: units[measureName],
    value: valueDisplay,
    note: getNoteDisplay(latest[measureName]?.ts),
    icon: icons[measureName],
    hasValue,
    measureName,
  };
};

/**
 *
 * @param scale the scale to be used for calculating the measure props if an override is required
 *
 */
export const getMeasureProps = (
  latest: Vitals.LatestResultMeasures,
  measureName: MeasurementName,
  useCodeTitle?: boolean,
  scale?: SafeScale
): MeasureDisplayProps => {
  const defaults = getMeasureDefaults(latest, measureName, useCodeTitle, scale);

  const aggregator = vitalSummaryCardMeasureAggregators[measureName];

  if (!aggregator) {
    return defaults;
  }

  return aggregator(defaults, latest);
};

type ValuesGetter = (latest: Vitals.LatestResultMeasures) => SummaryProps[];

const glucoseValueGetter: ValuesGetter = (latest) => {
  const measure = latest[MeasurementName.Glucose];
  const formatter = optionalValueFormatters[MeasurementName.Glucose];

  return [
    {
      title: strings.LabelVitalLatest,
      showTime: true,
      isLarge: true,
      unit: units[MeasurementName.Glucose],
      time: latest[MeasurementName.Glucose]?.ts,
      value: formatter(measure?.value),
    },
  ];
};

const bloodPressureValueGetter: ValuesGetter = (latest) => {
  const { value, ts } = bloodPressureAggregator(latest);

  const unit = units[MeasurementName.CombinedArterialBloodPressure];
  const map = latest[MeasurementName.MeanArterialBloodPressure];
  const meanFormatter = optionalValueFormatters[MeasurementName.MeanArterialBloodPressure];

  return [
    {
      title: strings.LabelVitalLatest,
      value,
      time: ts,
      unit,
      isLarge: true,
      showTime: true,
    },
    {
      title: strings.VitalTitleMeanArterialBloodPressure,
      value: meanFormatter(map?.value),
      time: map?.ts,
      unit,
      isLarge: true,
      showTime: true,
    },
  ];
};

export const metricValueGetters: Partial<Record<MeasurementName, ValuesGetter>> = {
  [MeasurementName.Glucose]: glucoseValueGetter,
  [MeasurementName.CombinedArterialBloodPressure]: bloodPressureValueGetter,
};

interface MeasureDisplayProps {
  icon?: IconName;
  title?: string;
  color?: ColorName;
  backgroundColor?: ColorName;
  value?: number | string;
  unit?: string;
  note?: string;
  hasValue: boolean;
  measureName: MeasurementName;
}

type MeasureDisplayPropMapper = (name: Vitals.MeasurementName) => MeasureDisplayProps;

export type StatusMeasure = MeasurementName | 'service' | 'news';

/**
 * Define a mapper that can be used to get measure props for a given measurement name
 */
export const getPrimaryMeasurePropMapper =
  (
    latest: Partial<Record<Vitals.MeasurementName, Vitals.LatestResult>>,
    oxygenSaturationScale: OxygenSaturationScale = OxygenSaturationScale.Scale1,
    useCodeTitle = true
  ): MeasureDisplayPropMapper =>
  (name: Vitals.MeasurementName): MeasureDisplayProps => {
    const oxygenScaleOverride = oxygenSaturationScales[oxygenSaturationScale];

    if (name !== MeasurementName.OxygenSaturation) {
      return getMeasureProps(latest, name, useCodeTitle);
    }

    // we only need to override the oxygen scale since it otherwise defaults to scale1
    return getMeasureProps(latest, name, useCodeTitle, oxygenScaleOverride);
  };

/**
 * Check if a measurement stale
 *
 * @param relative time for age to be calculated relative to. Defaults to `now`
 * @returns a boolean indicating if the measurement is stale (`true`) or recent (`false`)
 *
 * @see isMeasurementRecent for the opposite function
 */
export const isMeasurementStale = <TKey extends string = MeasurementName>(
  relative: number,
  value: Vitals.LatestResult,
  key: TKey
) => {
  if (!value) {
    return false;
  }

  const staleDuration = staleDurations[key as MeasurementName];
  const age = relative - value.ts;

  // don't discard data if we don't have a time specified for age
  const isStale = staleDuration && age > staleDuration;

  return isStale;
};

/**
 * Check if a measurement recent
 *
 * @param relative time for age to be calculated relative to. Defaults to `now`
 *
 * @see isMeasurementStale for the base implementation of this
 */
export const isMeasurementRecent = <TKey extends string = MeasurementName>(
  relative: number,
  value: Vitals.LatestResult,
  key: TKey
) => !isMeasurementStale(relative, value, key);

/**
 * Keep only measures that are not stale as defined by the stale duration for each measure
 */
export const removeStaleMeasurements = (
  latest: Vitals.LatestResultMeasures = {},
  relative = Date.now()
): Vitals.LatestResultMeasures => {
  const pickRecent = isMeasurementRecent.bind(undefined, relative);

  return pickBy(latest, pickRecent);
};

export const removeStaleMeasurementsForVitalsPayload = (payload: Vitals.LiveDataPayload) => {
  const patientIds = Object.keys(payload);

  const partials = patientIds.map<Vitals.LiveDataPayload>((patientId) => ({
    [patientId]: {
      ...payload[patientId],
      latest: removeStaleMeasurements(payload[patientId].latest),
    },
  }));

  return mergeObjects<Vitals.LiveDataPayload>(partials, {});
};

export interface StatusMeasurementWithValueIndicator extends StatusCardMeasurement<StatusMeasure> {
  hasValue: boolean;
}

export const getStatusPrimaryPropMapper = (
  latest: Partial<Record<Vitals.MeasurementName, Vitals.LatestResult>>,
  oxygenSaturationScale: OxygenSaturationScale | undefined
) => {
  const measurePropMapper = getPrimaryMeasurePropMapper(latest, oxygenSaturationScale);

  return (measure: Vitals.MeasurementName): StatusMeasurementWithValueIndicator => {
    const props = measurePropMapper(measure);

    // so as to avoid colors being chaotic we only show color risk values
    const resolvedColor = props.color === 'risk-high' ? props.color : undefined;

    return {
      hasValue: props.hasValue,
      statusColor: resolvedColor,
      name: props.title,
      unit: props.unit,
      value: props.value,
      icon: props.icon,
      key: measure,
    };
  };
};

// TODO: change back to 0.8
const alertMinimumSignalQuality = 0.5;

export const isHighQuality = (alert: Websocket.Alert) =>
  (alert.value?.signalQuality || 0) >= alertMinimumSignalQuality;

export const getLatestECGSinusAlert = (
  alerts: Websocket.Alert[] = []
): Websocket.Alert | undefined => {
  const alert = alerts.find((a) => a.type === 'ecg-sinus-rhythm');
  const classification = alert?.value;

  if (!classification) {
    return undefined;
  }

  const quality = classification?.signalQuality || 0;
  const lowQuality = quality < alertMinimumSignalQuality;

  if (lowQuality) {
    return undefined;
  }

  const isRhythmAlert = classification?.isSinusRhythm === false;

  if (!isRhythmAlert) {
    return undefined;
  }

  return alert;
};

export const getEcgTags = (alerts: Websocket.Alert[]): MeasureTagProps[] => {
  const showAlert = getLatestECGSinusAlert(alerts);

  if (!showAlert) {
    return [];
  }

  return [
    {
      title: strings.AlertTitleECGNonSinusRhythm,
      color: 'status-critical',
      icon: 'warning',
      glow: true,
    },
  ];
};

interface GetStatusMeasurementsParams {
  latest?: Vitals.LatestResultMeasures;
  oxygenSaturationScale?: OxygenSaturationScale;
  additionalMeasures?: Vitals.MeasurementName[];
}

export const getStatusMeasurements: Util.Fn<
  GetStatusMeasurementsParams,
  StatusMeasurementWithValueIndicator[]
> = ({ latest = {}, oxygenSaturationScale, additionalMeasures = [] }) => {
  const mapMeasureToStatusProps = getStatusPrimaryPropMapper(latest, oxygenSaturationScale);

  const unconditionalMeasureProps = unconditionalPrimaryMeasures.map(mapMeasureToStatusProps);

  const conditionalMeasureProps = conditionalPrimaryMeasures
    .map(mapMeasureToStatusProps)
    .filter((measure) => measure.name);

  const additionalMeasureProps = additionalMeasures
    .map(mapMeasureToStatusProps)
    .filter((measure) => measure.name);

  return [...unconditionalMeasureProps, ...conditionalMeasureProps, ...additionalMeasureProps];
};

export interface DatumWithDevice extends Graph.Datum {
  deviceName: string;
}

export const mapVitalsToGraphData = (
  measureName: Vitals.MeasurementName,
  vitals: Vitals.BaseTimeSeriesRecord[] = []
): DatumWithDevice[] => {
  const filter = measureFilter[measureName];

  const filtered = filter ? vitals.filter(filter) : vitals;

  const sorted = filtered
    .map<DatumWithDevice>((d) => ({ x: d.ts, y: d.value, deviceName: d.sensorId }))
    // we need to fix this more generally by sorting on the backend as well
    .sort((a, b) => (a.x > b.x ? 1 : -1));

  return sorted;
};

export interface SecondaryData extends BaseSecondaryData {
  measureName: MeasurementName;
  data: DatumWithDevice[];
}

type SecondaryDataHandler = (vitals: Vitals.VitalsResultMeasures) => SecondaryData[] | undefined;

const createMeasureToSecondaryDataMap =
  (vitals: Vitals.VitalsResultMeasures): Util.ArrayMap<MeasurementName, SecondaryData> =>
  (measureName) => ({
    measureName,
    code: codes[measureName] || measureName,
    color: colors[measureName] || 'text-default',
    gradient: gradientStops[measureName],
    unit: units[measureName],
    title: titles[measureName] || measureName,
    data: mapVitalsToGraphData(measureName, vitals[measureName]),
    showTooltip: false,
  });

export const secondarySupplementaryMeasures: Partial<Record<MeasurementName, MeasurementName[]>> = {
  [MeasurementName.CombinedArterialBloodPressure]: [
    MeasurementName.SystolicArterialBloodPressure,
    MeasurementName.DiastolicArterialBloodPressure,
  ],
};

/**
 * Different metrics that make up a single complete measure
 */
export const measureParts: Partial<Record<MeasurementName, MeasurementName[]>> = {
  [MeasurementName.CombinedArterialBloodPressure]: [
    MeasurementName.SystolicArterialBloodPressure,
    MeasurementName.DiastolicArterialBloodPressure,
  ],
};

export const secondaryDataGetters: Partial<Record<MeasurementName, SecondaryDataHandler>> = {
  [MeasurementName.CombinedArterialBloodPressure]: (vitals) => {
    const mapper = createMeasureToSecondaryDataMap(vitals);

    const secondaryMeasures =
      secondarySupplementaryMeasures[MeasurementName.CombinedArterialBloodPressure] || [];

    return secondaryMeasures.map(mapper);
  },
};

interface PrimaryData {
  measureName: MeasurementName;
  data?: Vitals.BaseTimeSeriesRecord[];
}

type PrimaryDataHandler = (vitals: Vitals.VitalsResultMeasures) => PrimaryData;

export const primaryDataGetters: Partial<Record<MeasurementName, PrimaryDataHandler>> = {
  [MeasurementName.CombinedArterialBloodPressure]: (vitals) => ({
    data: vitals[MeasurementName.MeanArterialBloodPressure],
    measureName: MeasurementName.MeanArterialBloodPressure,
  }),
};

export const getLegend = (
  measurementName: MeasurementName
): FooterLegendProps<MeasurementName> => ({
  identifier: measurementName,
  color: colors[measurementName] || 'text-default',
  label: titles[measurementName],
});

export const getLegends = (
  measurementNames: MeasurementName[]
): FooterLegendProps<MeasurementName>[] => measurementNames.map(getLegend);

export const getPlanStatusCardMeasurement = (
  admission?: Admission.GetResponseWithMedia
): StatusMeasurementWithValueIndicator => ({
  name: strings.AdmissionServiceFormLabel,
  value: getPlanPeriodDisplay(admission?.service, admission?.period),
  icon: 'quro',
  hasValue: true,
  key: 'service',
});

export const getNewsStatusCardMeasurement = (
  newsScore?: QuroScoreResult
): StatusMeasurementWithValueIndicator | undefined => {
  if (!newsScore) {
    return undefined;
  }

  const statusColor = news2RiskColorMap[newsScore?.risk];

  return {
    statusColor,
    name: strings.EWSTitle,
    value: newsScore.score,
    unit: news2ShortDisplayMap[newsScore.risk],
    icon: 'warning',
    hasValue: true,
    key: 'news',
  };
};

export const measurementNames = Object.values(MeasurementName);

export const isMeasurementName = (key: string): key is MeasurementName =>
  measurementNames.includes(key as MeasurementName);
