import { ColorName } from '@emotion/react';
import { EncounterClass, EncounterService } from '@quromedical/fhir-common';
import { Field } from 'components/types';
import { strings } from 'strings';

/**
 * Currently these are all Home based, but when we add service types for hospital they will be added
 * here as well
 */
export const serviceClassMapping: Record<EncounterService, EncounterClass> = {
  [EncounterService.HospitalAtHome]: EncounterClass.Home,
  [EncounterService.LACE]: EncounterClass.Home,
  [EncounterService.RemotePatientMonitoring]: EncounterClass.Home,
  [EncounterService.Arthroplasty]: EncounterClass.Home,
  [EncounterService.HealingHands]: EncounterClass.Home,
  [EncounterService.InHospitalMonitoring]: EncounterClass.Hospital,
  [EncounterService.CareAtHome]: EncounterClass.Home,
};

export const statusColor: Partial<Record<fhir4.Encounter['status'], ColorName>> = {
  'entered-in-error': 'status-critical',
  'in-progress': 'status-success',
  finished: 'accent-blue',
};

export interface OxygenFields {
  hasOxygen: boolean;
  oxygen?: number;
}

export const getOxygenValue = (hasOxygen?: boolean, oxygen?: number): number | undefined => {
  if (!hasOxygen) {
    return undefined;
  }

  if (!oxygen) {
    return undefined;
  }

  return +oxygen;
};

export const createOxygenFields = (hasOxygen = false): Field<OxygenFields>[] => [
  {
    subfields: [
      {
        key: 'hasOxygen',
        type: 'radio-group',
        label: strings.RequiredOxygenFormLabel,
        options: [
          {
            display: strings.FormLabelYes,
            value: true,
          },
          {
            display: strings.FormLabelNo,
            value: false,
          },
        ],
      },
      hasOxygen
        ? {
            key: 'oxygen',
            type: 'text-box',
            label: strings.RequiredOxygenQuantityFormLabel,
            keyboardType: 'numeric',
          }
        : {
            key: 'oxygen',
            type: 'custom',
            label: '',
            background: false,
            Display: () => null,
            isDisabled: true,
          },
    ],
  },
];
