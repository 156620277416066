import { Medication } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import { compact } from 'lodash';

export enum MedicationRequestEndpoint {
  Group = 'group',
}

interface PathParams {
  patientId: string;
  admissionId?: string;
  groupIdentifier?: string;
  endpoint?: MedicationRequestEndpoint;
}
export class MedicationRequestApi {
  private readonly client: BaseApi;
  private readonly patientRoute = 'api/aggregate/patient';
  private readonly admissionRoute = 'admission';
  private readonly medicationRequestRoute = 'medication-request';

  constructor() {
    this.client = new BaseApi();
  }

  private createPath({ patientId, admissionId, groupIdentifier, endpoint }: PathParams) {
    const base = [this.patientRoute, patientId].join('/');
    if (!admissionId) {
      return compact([base, this.medicationRequestRoute, endpoint, groupIdentifier]).join('/');
    }
    return [base, this.admissionRoute, admissionId, this.medicationRequestRoute].join('/');
  }

  /**
   * Get an SWR Key associated with the `getMedications` function.
   */
  public getMedicationRequestSWRKey = (
    patientId: string,
    status?: fhir4.MedicationRequest['status']
  ): string => compact([patientId, status]).join('/');

  public async getMedicationRequests(
    patientId: string,
    params: Medication.QueryRequestsParams
  ): Promise<Medication.GetAllRequestsResponse> {
    const path = this.createPath({ patientId });

    const result = await this.client.get<Medication.GetAllRequestsResponse>(path, { params });

    return result.data;
  }

  public async getRequestByIdentifier(
    patientId: string,
    params: Medication.QueryRequestsParams
  ): Promise<Medication.GetGroupedRequestsResponse> {
    const path = this.createPath({
      patientId,
      groupIdentifier: params.groupIdentifier,
      endpoint: MedicationRequestEndpoint.Group,
    });

    const result = await this.client.get<Medication.GetGroupedRequestsResponse>(path, { params });

    return result.data;
  }

  public async createMedicationRequest(
    patientId: string,
    admissionId: string,
    body: Medication.CreateMedicationRequest
  ): Promise<Medication.CreateRequestResponse> {
    const path = this.createPath({ patientId, admissionId });

    const result = await this.client.post<Medication.CreateRequestResponse>(path, body);

    return result.data;
  }
}
