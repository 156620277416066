import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Col } from 'components/base';
import { Icon, IconName } from 'design-system/base';
import { BORDER_WIDTH, MARGIN, PADDING, Spacing } from 'design-system/theme';

import { getFadedColor } from './internal';

interface IndentWrapperProps {
  color?: ColorName;
  icon?: IconName;
  marginTop?: Spacing;
  marginBottom?: Spacing;
  marginLeft?: Spacing;
  hasIcon?: boolean;
}

const Wrapper = styled(Col)<IndentWrapperProps>(
  ({
    theme,
    color = 'text-default',
    marginTop = '2xs',
    marginBottom = 'none',
    marginLeft = 'none',
    hasIcon = false,
  }) => ({
    borderColor: getFadedColor(theme, color),
    borderWidth: 0,
    borderLeftWidth: BORDER_WIDTH[2],
    borderStyle: 'solid',
    marginTop: MARGIN[marginTop],
    marginBottom: MARGIN[marginBottom],
    marginLeft: MARGIN[marginLeft],
    paddingLeft: hasIcon ? PADDING.xl : PADDING.xs,
    alignItems: 'flex-start',
    position: 'relative',
  })
);

const IconCol = styled(Col)({
  position: 'absolute',
  top: MARGIN['3xs'],
  left: MARGIN['2xs'],
});

export const IndentWrapper: React.FC<IndentWrapperProps> = ({ children, icon, ...rest }) => (
  <Wrapper {...rest} hasIcon={!!icon} flexGrow={1}>
    <IconCol isVisible={!!icon}>
      <Icon name={icon} />
    </IconCol>
    <Col flexGrow={1} flexWrap="wrap">
      {children}
    </Col>
  </Wrapper>
);
