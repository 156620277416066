import styled from '@emotion/native';
import { MeasurementName } from '@quromedical/models';
import { Col, Row } from 'components/base';
import { getDurationInDays } from 'core/display';
import { largeMeasures } from 'core/vitals';
import {
  SkeletonProvider,
  Button,
  Section,
  DurationPicker,
  DurationResult,
  Grid,
  PADDING,
} from 'design-system';
import { millisecondsToDuration } from 'design-system/components';
import { usePatientActiveAdmission } from 'hooks/usePatientActiveAdmission';
import { useIsSmallerThan } from 'hooks/useResponsive';
import { usePatientVitals } from 'providers/PatientVitalsProvider';
import React, { useCallback, useState } from 'react';
import { NotFoundScreen } from 'screens/NotFoundScreen';

import { VitalsActiveGrid } from './VitalsActiveGrid';
import { VitalsFilter } from './VitalsFilter';

interface PatientVitalsTabProps {
  id: string;
  scrollToTop: () => void;
}

export const RefreshButtonWrapper = styled.View({
  paddingLeft: PADDING.s,
  paddingBottom: PADDING.s,
});

export const PatientVitalsActiveTab: React.FC<PatientVitalsTabProps> = ({ id, scrollToTop }) => {
  const isSmall = useIsSmallerThan('sm');
  const [filter, setFilter] = useState<MeasurementName[]>([]);

  const activeAdmission = usePatientActiveAdmission(id);

  const dayOfService = getDurationInDays(activeAdmission?.period?.start);
  const serviceType = activeAdmission?.service;

  const { setTimes, isValidatingVitals, onRefresh, times } = usePatientVitals();

  const handleDurationChanged = useCallback(
    (state: DurationResult) => {
      setTimes({
        duration: state.duration,
        start: state.from,
      });
    },
    [setTimes]
  );

  if (!id) {
    return <NotFoundScreen />;
  }

  // on small devices we want the picker separated from the refresh button to fill the row
  const controlRowJustify = isSmall ? 'space-between' : 'flex-end';

  // necessary to ensure the picker reflects the correct state if the times are updated externally
  const durationPickerKey = `${times.duration}-${times.start?.getTime() || ''}`;

  return (
    <>
      <Section unsetZIndex>
        <Grid unsetZIndex sm={1} lg={2}>
          <Col justifyContent="flex-end" unsetZIndex>
            <VitalsFilter
              possibleMeasures={largeMeasures}
              onChange={setFilter}
              dayOfService={dayOfService}
              serviceType={serviceType}
            />
          </Col>

          <Col flex={1} justifyContent="flex-end">
            <Row justifyContent={controlRowJustify} alignItems="flex-end">
              <DurationPicker
                key={durationPickerKey}
                isCondensed={isSmall}
                onChange={handleDurationChanged}
                from={times.start}
                initial={millisecondsToDuration[times.duration]}
              />
              <RefreshButtonWrapper>
                <Button onPress={onRefresh} icon="refresh" variant="contained" shape="round" />
              </RefreshButtonWrapper>
            </Row>
          </Col>
        </Grid>
      </Section>

      <Section>
        <SkeletonProvider loading={isValidatingVitals}>
          <VitalsActiveGrid measurements={filter} scrollToTop={scrollToTop} />
        </SkeletonProvider>
      </Section>
    </>
  );
};
