import { PaginatedTable } from 'core/table';
import { getDataFromResponseCompat } from 'hooks/useFhirDataInfinite';
import { DeviceUseStatementApi } from 'integration';
import React, { useCallback } from 'react';

import { deviceDeviceUseStatementColumns } from './columns';
import { mapFhirToTable } from './mapping';

const deviceUseStatementApi = new DeviceUseStatementApi();

interface DeviceDeviceUseStatementListProps {
  deviceId: string;
}

export const DeviceDeviceUseStatementList: React.FC<DeviceDeviceUseStatementListProps> = ({
  deviceId,
}) => {
  const deviceUseStatementFetcher = useCallback(
    (pageToken?: string) =>
      deviceUseStatementApi.getDeviceUseStatements({
        device: deviceId,
        pageToken,
      }),
    [deviceId]
  );

  return (
    <PaginatedTable
      cacheKey={`DeviceUseStatement${deviceId}`}
      fetcher={deviceUseStatementFetcher}
      columns={deviceDeviceUseStatementColumns}
      getDataFromResponse={getDataFromResponseCompat(mapFhirToTable)}
    />
  );
};
