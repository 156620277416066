import styled from '@emotion/native';
import { Col, Row } from 'components/base';
import { Button } from 'design-system/base';
import { MARGIN } from 'design-system/theme';
import React from 'react';

import { TextBox } from '../text-box';

interface FileCardProps {
  title: string;
  onDelete: () => void;
  onTitleChange: (value: string) => void;
}

const ButtonWrapper = styled(Col)({
  marginTop: MARGIN['3xs'],
});

export const FileRow: React.FC<FileCardProps> = ({ title, onDelete, onTitleChange }) => (
  <Row flex={1}>
    <Col flexGrow={1}>
      <TextBox value={title} onChange={onTitleChange} />
    </Col>
    <ButtonWrapper flexShrink={0}>
      <Button icon="delete" variant="text" onPress={onDelete} />
    </ButtonWrapper>
  </Row>
);
