import { useTheme } from '@emotion/react';
import { BarValue, SignalStrength } from 'design-system/base';
import React from 'react';

interface ConnectionQualityProps {
  signalLevel?: number;
}

/**
 * Defaulting this to 5(excellent wifi strength) because 100ms does not provide connection strength
 * if you are the only person in a meeting/call. returns -1 for `connection undefined`.
 * @param signalLevel or connection strength, provided by 100ms on a scale of -1 to 5.
 * @returns A 3 bar wifi icon indicating connection strength
 */
export const ConnectionQuality: React.FC<ConnectionQualityProps> = ({ signalLevel = 5 }) => {
  const theme = useTheme();

  const bars =
    signalLevel === -1 ? (3 as BarValue) : (Math.floor((signalLevel + 1) / 2) as BarValue);

  const color = theme.color.primary;

  return (
    <SignalStrength
      size={24}
      missingColor={theme.color['text-disabled']}
      color={color}
      bars={bars}
    />
  );
};
