import { Device } from '@quromedical/models';
import { ColumnType } from 'components/table';
import { strings } from 'strings';

import { getDeviceTypeDisplayName } from './config';

export interface DeviceTable {
  id?: string;
  type: string;
  name: string;
  status: fhir4.Device['status'];
}

export const columns: ColumnType<DeviceTable>[] = [
  {
    header: strings.DeviceTableNameSubheading,
    accessor: 'name',
  } as ColumnType<DeviceTable, 'name'>,
  {
    header: strings.DeviceTableTypeSubheading,
    accessor: 'type',
  } as ColumnType<DeviceTable, 'type'>,
  {
    header: strings.DeviceTableStatusSubheading,
    accessor: 'status',
  } as ColumnType<DeviceTable, 'status'>,
];

export const getDataFromResponse = (res: Device.GetAllResponse): Partial<DeviceTable>[] =>
  res.devices.map((device) => ({ ...device, type: getDeviceTypeDisplayName(device.type) }));
