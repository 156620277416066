import { NextOfKin, Person, RelatedPerson } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import compact from 'lodash.compact';

export enum RelatedPersonEndpoint {
  Base = '',
  Contact = 'contact',
  General = 'general',
}

export class RelatedPersonApi {
  private readonly client: BaseApi;
  private readonly root = 'api/aggregate/patient/';
  constructor() {
    this.client = new BaseApi();
  }

  private createPath(patientId: string, endpoint: string, resourceId?: string) {
    const basePath = `${this.root}${patientId}`;

    return compact([basePath, 'next-of-kin', resourceId, endpoint]).join('/');
  }

  /**
   * Get an SWR Key associated with the `getPatient` function.
   */
  public getPatientSWRKey = (patientId: string, ...endpoint: RelatedPersonEndpoint[]): string =>
    this.createPath(patientId, endpoint.join('_'));

  public async updateContactRelatedPerson(
    relatedPersonId: string,
    patientId: string,
    body: Person.Contact
  ): Promise<RelatedPerson.RelatedPerson> {
    const path = this.createPath(patientId, RelatedPersonEndpoint.Contact, relatedPersonId);

    const result = await this.client.put<RelatedPerson.RelatedPerson>(path, body);
    return result.data;
  }

  public async updateGeneralRelatedPerson(
    relatedPersonId: string,
    patientId: string,
    body: Person.General
  ): Promise<RelatedPerson.RelatedPerson> {
    const path = this.createPath(patientId, RelatedPersonEndpoint.General, relatedPersonId);

    const result = await this.client.put<RelatedPerson.RelatedPerson>(path, body);

    return result.data;
  }

  public async getRelatedPerson(id: string): Promise<NextOfKin.GetResponse> {
    const path = this.createPath(id, RelatedPersonEndpoint.Base);

    const result = await this.client.get<NextOfKin.GetResponse>(path);

    return result.data;
  }
}
