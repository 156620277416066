import React from 'react';

import { useDashboardContext } from './DashboardContext';
import { DashboardErrorGrid, DashboardTable, DashboardGrid, Layout } from './sections';

interface DashboardProps {
  layout: Layout;

  /**
   * ItemSize is a single numeric value that hints FlashList about the approximate size of the
   * items before they're rendered. to decide how many items it needs to draw on the screen before
   * initial load and while scrolling. If most of the items are of different sizes, you can think
   * of an average or median value and if most items are of the same size, just use that number.
   * If you're confused between two values, the smaller value is a better choice.
   * Has enough tolerance to work around different devices.
   */
  itemSize?: number;
}

const Dashboard: React.FC<DashboardProps> = ({ layout }) => {
  const { data, isLoading, updated, onLayoutChange, error } = useDashboardContext();
  if (error) {
    return <DashboardErrorGrid error={!!error} />;
  }

  if (layout === 'table') {
    return (
      <DashboardTable
        loading={isLoading}
        data={data}
        updated={updated}
        onLayoutChange={onLayoutChange}
      />
    );
  }

  return (
    <DashboardGrid
      loading={isLoading}
      data={data}
      updated={updated}
      onLayoutChange={onLayoutChange}
    />
  );
};

export const DashboardGridScreen: React.FC = () => <Dashboard layout="grid" />;

export const DashboardListScreen: React.FC = () => <Dashboard layout="table" />;
