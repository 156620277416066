import {
  getReferenceId,
  getResourcesFromBundle,
  DeviceUseStatementCode,
} from '@quromedical/fhir-common';
import { MessageCard } from 'design-system';
import { useFhirData } from 'hooks/useFhirData';
import { DeviceUseStatementApi } from 'integration';
import React from 'react';
import { strings } from 'strings';

import { RelayStatus } from './RelayStatus';

interface PatientRelayStatusProps {
  patientId: string;
}

const deviceApi = new DeviceUseStatementApi();

const fetcher = (patientId: string) =>
  deviceApi.getDeviceUseStatements({
    subject: patientId,
    code: DeviceUseStatementCode.relay,
    status: 'active',
    count: 10,
  });

export const PatientRelayStatus: React.FC<PatientRelayStatusProps> = ({ patientId }) => {
  const { data, error, isValidating } = useFhirData(
    ['DeviceUseStatement', 'registered-relays'],
    patientId,
    fetcher
  );

  if (error) {
    return <MessageCard message={strings.GenericErrorMessage} title={strings.ErrorCardTitle} />;
  }

  if (isValidating) {
    // we don't actually know anything about what data to expect, this shouldn't be a huge issue
    return null;
  }
  if (!data) {
    return (
      <MessageCard message={strings.NoAssignedDevicesErrorText} title={strings.LabelRelayStatus} />
    );
  }
  const resources = getResourcesFromBundle(data);

  if (!resources?.length) {
    return (
      <MessageCard message={strings.NoAssociatedDevicesFound} title={strings.LabelRelayStatus} />
    );
  }

  const statusCards = resources.map((d) => {
    const id = getReferenceId(d.device);

    if (!id) {
      // if the deviceUseStatement doesn't have an ID there's no device associated/nothing to render
      return null;
    }

    return <RelayStatus key={id} deviceId={id} />;
  });

  return <>{statusCards}</>;
};
