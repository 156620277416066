import { EncounterService } from '@quromedical/fhir-common';
import { Admission, Media, Validations } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { Field, FileResult, SelectOption } from 'components/types';
import { admissionServiceDisplay } from 'core/display';
import { FileKeyProgressMap } from 'design-system';
import { CreateUploadUrl, useFileUpload } from 'hooks/useFileUpload';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

import { serviceClassMapping } from './internal';

export type AdmissionNotesData = Pick<
  Admission.CreateRequest,
  'class' | 'service' | 'periodStart' | 'attachments'
>;

// the class is left out of the form because we infer it based on the selected service because we
// have a simple setup right now, though this may become more complex as we add service types
type AdmissionNotesFormData = Pick<Admission.CreateRequest, 'service' | 'periodStart'> & {
  media?: FileResult[];
};

interface AdmissionNotesFormProps {
  showErrors?: boolean;
  /**
   * Returns undefined if the data is incomplete or invalid. This is to simplify error checking and
   * validation at a parent level - no data means no valid data
   */
  onValidChange: (data?: AdmissionNotesData) => void;
  createUploadUrl: CreateUploadUrl;
}

const serviceOptions: SelectOption[] = Object.values(EncounterService).map((service) => ({
  value: service,
  display: admissionServiceDisplay[service],
}));

const schema = yup
  .object<ObjectType<AdmissionNotesFormData>>({
    periodStart: Validations.admissionRequestSchemaFields.periodStart,
    service: Validations.admissionRequestSchemaFields.service,
    media: yup.array().optional(),
  })
  .required();

const createFields = (
  uploadInProgress: boolean,
  progress: FileKeyProgressMap
): Field<AdmissionNotesFormData>[] => [
  {
    icon: 'calendar-today',
    subfields: [
      {
        key: 'periodStart',
        type: 'masked-text-box',
        label: strings.AdmissionStartLabel,
        maskType: 'date',
      },
      {
        key: 'service',
        searchable: true,
        type: 'combobox-single',
        label: strings.AdmissionServiceLabel,
        fetcher: serviceOptions,
      },
    ],
  },
  {
    subfields: [
      {
        key: 'media',
        type: 'file-picker-dialog',
        label: strings.FormLabelAttachments,
        uploadInProgress,
        progress,
      },
    ],
  },
];

const initialValues: Partial<AdmissionNotesFormData> = {
  service: EncounterService.HospitalAtHome,
};

export const AdmissionNotesForm: React.FC<AdmissionNotesFormProps> = ({
  onValidChange,
  showErrors = false,
  createUploadUrl,
}) => {
  const [media, setMedia] = useState<Media.CreateData[]>([]);
  const [form, setForm] = useState<AdmissionNotesData>();

  const { onFilesChange, progress, uploadsComplete } = useFileUpload(
    createUploadUrl,
    (attachments) => {
      setMedia(attachments);
      if (form) {
        onValidChange({
          ...form,
          attachments,
        });
      }
    }
  );

  const onChange = useCallback(
    (data?: Partial<AdmissionNotesFormData>) => {
      if (!data) {
        onValidChange(undefined);
      } else {
        onFilesChange(data);

        const result = data as AdmissionNotesFormData; // casting since data is validated
        const fullData: AdmissionNotesData = {
          ...result,
          class: serviceClassMapping[result.service],
          attachments: media,
        };

        setForm(fullData);
        onValidChange(fullData);
      }
    },
    [media, onFilesChange, onValidChange]
  );

  const fields = createFields(!uploadsComplete, progress);

  return (
    <CrudForm<AdmissionNotesFormData>
      title={strings.AdmissionNotesFormTitle}
      cardProps={{ unsetZIndex: true }}
      includeIcons
      validateOnMount
      initialValues={initialValues}
      fields={fields}
      validationSchema={schema}
      showErrors={showErrors}
      onValidChange={onChange}
      onChangeDebounceTime={0}
      showSubmitButton={false}
    />
  );
};
