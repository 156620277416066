import { Field } from 'components/types';

export interface PractitionerParams {
  sort?: Sort;
  count?: number;
  pageToken?: string;
  name?: string;
  id?: string;
  email?: string;
}

export enum Sort {
  FamilyAsc = 'family',
  FamilyDesc = '-family',
  GivenAsc = 'given',
  GivenDesc = '-given',
  EmailAsc = 'email',
  EmailDesc = '-email',
}

export const initialSearch: PractitionerParams = {
  name: undefined,
  email: undefined,
  sort: Sort.FamilyAsc,
};

export const searchFields: Field<PractitionerParams>[] = [
  {
    subfields: [
      {
        key: 'name',
        label: 'Name',
        type: 'text-box',
        placeholder: 'Type to search',
      },
      {
        key: 'email',
        label: 'Email (exact)',
        type: 'text-box',
        placeholder: 'Type to search',
      },
      {
        key: 'sort',
        label: 'Sort',
        type: 'combobox-single',
        searchable: true,
        fetcher: [
          {
            display: 'None',
            value: undefined,
          },
          {
            display: 'Family Name (A-Z)',
            value: Sort.FamilyAsc,
          },
          {
            display: 'Family Name (Z-A)',
            value: Sort.FamilyDesc,
          },
          {
            display: 'Given Name (A-Z)',
            value: Sort.GivenAsc,
          },
          {
            display: 'Given Name (Z-A)',
            value: Sort.GivenDesc,
          },
          {
            display: 'Email (A-Z)',
            value: Sort.EmailAsc,
          },
          {
            display: 'Email (Z-A)',
            value: Sort.EmailDesc,
          },
        ],
      },
    ],
  },
];
