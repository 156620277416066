import { GroupSecurity } from '@quromedical/fhir-common';
import { Organization } from '@quromedical/models';
import { useLinkTo } from '@react-navigation/native';
import { CrudForm } from 'components/form';
import { WithGroup } from 'core/auth';
import {
  columnsLarge,
  columnsSmall,
  initialSearch,
  OrganizationCreateCard,
  OrganizationTable,
  searchFields,
} from 'core/organization';
import { PaginatedTable } from 'core/table';
import { Section, ToggleCard } from 'design-system';
import { useResponsive } from 'hooks/useResponsive';
import { OrganizationApi } from 'integration/aggregate';
import { OrganizationStackScreenProps } from 'navigation';
import React, { useCallback, useState } from 'react';
import { ScrollView } from 'react-native';
import { strings } from 'strings';

const api = new OrganizationApi();

const getDataFromResponse = (res: Organization.GetAllResponse): OrganizationTable[] =>
  res.organizations.map((p) => ({
    id: p.id,
    name: p.general.name,
    type: p.general.type,
    telecomEmail: p.contact.telecomEmail,
    telecomPhoneNumbers: p.contact.telecomPhoneNumbers?.[0],
    addressLines: p.contact.addressLines?.[1],
    addressCity: p.contact.addressCity,
    addressPostalCode: p.contact.addressPostalCode,
  }));

export const OrganizationListScreen: React.FC<OrganizationStackScreenProps<'List'>> = () => {
  const showHeader = useResponsive({ md: true }, false);
  const isStriped = useResponsive({ md: true }, false);
  const columns = useResponsive({ md: columnsLarge }, columnsSmall);
  const navigate = useLinkTo();

  const [search, setSearch] = useState<Organization.GetAllParams>(initialSearch);

  const fetcher = useCallback(
    (pageToken?: string) =>
      api.getOrganizations({
        address: search.address ? search.address : undefined,
        name: search.name ? search.name : undefined,
        type: search.type,
        pageToken,
      }),
    [search]
  );

  const onRowPress = useCallback(
    (row: OrganizationTable) =>
      navigate({
        screen: 'Admin',
        params: {
          screen: 'Organization',
          params: { screen: 'View', params: { id: row.id } },
        },
      }),
    [navigate]
  );

  return (
    <ScrollView>
      <WithGroup groups={[GroupSecurity.OrganizationCreate]}>
        <Section>
          <ToggleCard
            icon="medical-services"
            title={strings.OrganizationToggleCardTitle}
            textExpand={strings.OrganizationToggleCardExpandButtonText}
            textCollapse={strings.OrganizationToggleCardCollapseButtonText}
            contentWrapperProps={{ paddingHorizontal: 0, paddingVertical: 0 }}
          >
            <OrganizationCreateCard />
          </ToggleCard>
        </Section>
      </WithGroup>
      <Section>
        <CrudForm
          cardProps={{ unsetZIndex: true }}
          fields={searchFields}
          initialValues={initialSearch}
          showSubmitButton={false}
          onChange={setSearch}
        />
      </Section>

      <Section>
        <PaginatedTable
          cacheKey={`Organization${JSON.stringify(search)}`}
          fetcher={fetcher}
          columns={columns}
          getDataFromResponse={getDataFromResponse}
          showHeader={showHeader}
          isStriped={isStriped}
          onRowPress={onRowPress}
        />
      </Section>
    </ScrollView>
  );
};
