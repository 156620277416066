import { FONT_FAMILY, FONT_SIZE } from 'design-system';
import React from 'react';
import { G, Text } from 'react-native-svg';

import { usePlotDataContext } from './PlotDataProvider';
import { ExternalComponentProps } from './types';

interface XAxisProps {
  ticks?: number | number[];
  color?: string;
  formatter?: (x: number) => string;
}

type ExternalProps = ExternalComponentProps & XAxisProps;

const defaultFormatter = (n: number) => n.toString();

export const XAxis: React.FC<ExternalProps> = (props) => {
  const {
    ticks = 10,
    xScale,
    baseHeight,
    formatter = defaultFormatter,
    color = 'lightgrey',
  } = usePlotDataContext(props);

  const tickMarks = typeof ticks === 'number' ? xScale.ticks(ticks) : ticks;

  return (
    <G>
      {tickMarks.map((t) => (
        <G key={t.toString()}>
          <Text
            fontFamily={FONT_FAMILY.regular}
            fontSize={FONT_SIZE.captionSmall}
            fill={color}
            textAnchor="middle"
            x={xScale(t)}
            y={baseHeight}
            pointerEvents="none"
          >
            {formatter(t)}
          </Text>
        </G>
      ))}
    </G>
  );
};
