import { Text, Icon } from 'design-system/base';
import { BASE_SIZE, MARGIN, PADDING } from 'design-system/theme';
import { View } from 'react-native';
import { DropDownPickerProps, ItemType } from 'react-native-dropdown-picker';

export type Step = 'loading' | 'error' | 'data';

export const ArrowDownIconComponent: DropDownPickerProps<unknown>['ArrowDownIconComponent'] = ({
  style,
}) => (
  <View style={style}>
    <Icon name="keyboard-arrow-down" color="text-default" />
  </View>
);

export const ArrowUpIconComponent: DropDownPickerProps<unknown>['ArrowUpIconComponent'] = ({
  style,
}) => (
  <View style={style}>
    <Icon name="keyboard-arrow-up" color="text-default" />
  </View>
);

export const TickIconComponent: DropDownPickerProps<unknown>['TickIconComponent'] = () => (
  <View style={{ marginRight: MARGIN['2xs'] }}>
    <Icon name="check-circle-outline" color="text-default" size={BASE_SIZE[16]} />
  </View>
);

const ListEmptyComponent: DropDownPickerProps<unknown>['ListEmptyComponent'] = () => (
  <View style={{ paddingHorizontal: PADDING.xs, paddingVertical: PADDING['2xs'] }}>
    <Text color="text-default" variant="caption">
      No items found
    </Text>
  </View>
);

const ListEmptyLoadingComponent: DropDownPickerProps<unknown>['ListEmptyComponent'] = () => (
  <View style={{ paddingHorizontal: PADDING.xs, paddingVertical: PADDING['2xs'] }}>
    <Text color="text-default" variant="caption">
      Loading
    </Text>
  </View>
);

const ListEmptyErrorComponent: DropDownPickerProps<unknown>['ListEmptyComponent'] = () => (
  <View style={{ paddingHorizontal: PADDING.xs, paddingVertical: PADDING['2xs'] }}>
    <Text color="text-default" variant="caption">
      Error
    </Text>
  </View>
);

const listEmptyComponents: Record<Step, DropDownPickerProps<unknown>['ListEmptyComponent']> = {
  data: ListEmptyComponent,
  loading: ListEmptyLoadingComponent,
  error: ListEmptyErrorComponent,
};

export const getListEmptyComponent = (
  step: Step
): DropDownPickerProps<unknown>['ListEmptyComponent'] => listEmptyComponents[step];

type Loading = '__loading__';
type Error = '__error__';

export const getStatusItems = (step: Step): ItemType<Loading | Error>[] => {
  if (step === 'loading') {
    return [
      {
        label: 'Loading',
        value: '__loading__',
        disabled: true,
        selectable: false,
      },
    ];
  }

  if (step === 'error') {
    return [
      {
        label: 'Error',
        value: '__error__',
        disabled: true,
        selectable: false,
      },
    ];
  }

  return [];
};
