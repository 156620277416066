import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import { differenceInYears } from 'date-fns';

const timeFormatFn = timeFormat('%H:%M');
const timeFormatWithSecondsFn = timeFormat('%H:%M:%S');
const timeFormatWithMillisecondsFn = timeFormat('%H:%M:%S.%L');
const dateFormatFn = timeFormat('%Y/%m/%d');
const dateWithoutYearTimeWithSecondsFormatFn = timeFormat('%e %b %H:%M:%S');

/**
 * Format time to `HH:MM`
 */
export const timeFormatter = (value?: number | Date): string =>
  value !== undefined ? timeFormatFn(new Date(value)) : '';

/**
 * Format time to `HH:MM:SS`
 */
export const timeWithSecondsFormatter = (value?: number | Date): string =>
  value !== undefined ? timeFormatWithSecondsFn(new Date(value)) : '';

/**
 * Format time to `HH:MM:SS.LL`
 */
export const timeWithMillisecondsFormatter = (value?: number | Date): string =>
  value !== undefined ? timeFormatWithMillisecondsFn(new Date(value)) : '';

/**
 * Format time to `dd mmm HH:MM:SS`
 */
export const dateWithoutYearTimeWithSecondsFormatter = (value?: number | Date): string =>
  value !== undefined ? dateWithoutYearTimeWithSecondsFormatFn(new Date(value)) : '';

const shortMonthDateFormatFn = timeFormat('%e %b %Y');
/**
 * Format date to `dd mmm yyyy` format
 */
export const shortMonthDateFormatter = (value?: number | Date): string =>
  value !== undefined ? shortMonthDateFormatFn(new Date(value)).trim() : '';

const integerFormatFn = format('.0f');
/**
 * Format number as an integer
 */
export const integerFormatter = (value?: number): string =>
  value !== undefined ? integerFormatFn(value) : '';

export const optionalIntegerFormatter = (value?: number): string | undefined =>
  value !== undefined ? integerFormatFn(value) : undefined;

const decimalFormatFn = format('.1f');
/**
 * Format number as a float with 1 decimal
 */
export const decimalFormatter = (value?: number): string =>
  value !== undefined ? decimalFormatFn(value) : '';

export const optionalDecimalFormatter = (value?: number): string | undefined =>
  value !== undefined ? decimalFormatFn(value) : undefined;

/**
 * formats the date in a YYYY/mm/dd format
 * @param date the input date
 * @returns the formatted date as a string
 */
export const dateFormatter = (date: Date | number): string => dateFormatFn(new Date(date));

const dateWithoutYearFormatFn = timeFormat('%e %b');
/**
 * formats the date in a dd mmm format
 * @param date the input date
 * @returns the formatted date as a string
 */
export const dateWithoutYearFormatter = (date?: Date | number): string =>
  date ? dateWithoutYearFormatFn(new Date(date)) : '';

const dateTimeWithoutYearFormatFn = timeFormat('%e %b %H:%M');
/**
 * formats the date in a dd mmm HH:MM format
 * @param date the input date
 * @returns the formatted date as a string
 */
export const dateTimeWithoutYearFormatter = (date?: Date | number): string =>
  date ? dateTimeWithoutYearFormatFn(new Date(date)) : '';

const dateTimeWithoutYearFormatWithSecondsFn = timeFormat('%e %b %H:%M:%S');
/**
 * formats the date in a dd mmm HH:MM:SS format
 * @param date the input date
 * @returns the formatted date as a string
 */
export const dateTimeWithoutYearWithSecondsFormatter = (date?: Date | number): string =>
  date ? dateTimeWithoutYearFormatWithSecondsFn(new Date(date)) : '';

export const getAgeInYears = (fhirDate?: string, to = new Date()): number | undefined => {
  if (!fhirDate) {
    return undefined;
  }
  const date = new Date(fhirDate);
  const age = differenceInYears(to, date);
  return age;
};
