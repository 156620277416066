import { Device, DeviceCommand, MDM } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import { compact } from 'lodash';

enum DeviceEndpoint {
  Base = '',
  CalibratePatch = 'management/calibrate/patch-calibrate',
  MDMStatus = 'management/status',
  RebootRelay = 'management/reboot',
  Metrics = 'metric',
}

export class DeviceApi {
  private readonly client: BaseApi;
  private readonly root = 'api/aggregate/device';

  constructor() {
    this.client = new BaseApi();
  }

  private createPath(deviceId: string, endpoint: DeviceEndpoint) {
    return compact([this.root, deviceId, endpoint]).join('/');
  }

  /**
   * Get an SWR Key associated with the `getPatient` function
   */
  public getDeviceSWRKey = (
    deviceId: string,
    endpoint: DeviceEndpoint = DeviceEndpoint.Base
  ): string => this.createPath(deviceId, endpoint);

  public async getDevices(params: Device.GetAllParams): Promise<Device.GetAllResponse> {
    const result = await this.client.get<Device.GetAllResponse>(this.root, { params });

    return result.data;
  }

  createDevice = async (device: Device.CreateRequest): Promise<Device.CreateResponse> => {
    const result = await this.client.post<Device.CreateResponse>(this.root, device);

    return result.data;
  };

  public getDevice = async (id: string): Promise<Device.GetResponse> => {
    const result = await this.client.get<Device.GetResponse>(
      this.createPath(id, DeviceEndpoint.Base)
    );

    return result.data;
  };

  public updateDevice = async (
    id: string,
    device: Device.UpdateRequest
  ): Promise<Device.UpdateResponse> => {
    const result = await this.client.put<Device.UpdateResponse>(
      this.createPath(id, DeviceEndpoint.Base),
      device
    );

    return result.data;
  };

  public calibratePatch = async (
    id: string,
    request: Device.PatchCalibrateRequest
  ): Promise<Device.PatchCalibrateResponse> => {
    const path = this.createPath(id, DeviceEndpoint.CalibratePatch);

    const result = await this.client.post<Device.PatchCalibrateResponse>(path, request);

    return result.data;
  };

  public rebootDevice = async (id: string): Promise<void> => {
    const path = this.createPath(id, DeviceEndpoint.RebootRelay);
    await this.client.post(path, {});
  };

  public getMDMStatus = async (id: string): Promise<MDM.Status> => {
    const path = this.createPath(id, DeviceEndpoint.MDMStatus);
    const result = await this.client.get<MDM.Status>(path);

    return result.data;
  };

  public getDeviceMetrics = async (
    id: string,
    params?: DeviceCommand.DeviceMetricGetAllParams
  ): Promise<DeviceCommand.DeviceMetricGetAllResponse> => {
    const path = this.createPath(id, DeviceEndpoint.Metrics);

    const result = await this.client.get<DeviceCommand.DeviceMetricGetAllResponse>(path, {
      params,
    });

    return result.data;
  };
}
