import styled from '@emotion/native';
import { Row } from 'components/base';
import { Text, Button, ComboBoxSingle, MARGIN, Visible } from 'design-system';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';

interface TablePaginationControlsProps {
  initialCount: number;
  countOptions?: number[];
  canChangeCount?: boolean;
  hasPages?: boolean;
  canPressNext: boolean;
  canPressPrevious: boolean;
  currentPage: number;
  onPreviousPress: () => void;
  onNextPress: () => void;
  onCountChange: (count: number) => void;
  resetPage: () => void;
}

const Wrapper = styled(Row)({
  marginVertical: MARGIN.m,
});

export const TablePaginationControls: React.FC<TablePaginationControlsProps> = ({
  initialCount,
  countOptions = [5, 10, 20, 50, 100],
  canPressNext,
  canPressPrevious,
  canChangeCount,
  hasPages,
  onPreviousPress,
  onNextPress,
  onCountChange,
  resetPage,
  currentPage,
}) => {
  const [count, setCount] = useState(initialCount);
  const countFetcher = countOptions.map((o) => ({
    display: `${o}`,
    value: o,
  }));

  const handleCountChange = useCallback(
    (value: number) => {
      setCount(value);
      onCountChange(value);
    },
    [onCountChange]
  );

  const isFirstPage = currentPage === 0;

  // add 1 to the page displayed since users wouldn't expect 0-based indexing
  const pageDisplay = `${strings.PaginationControlsLabelPage} ${currentPage + 1}`;

  // to ensure consistent spacing the row must always be rendered, but the contents are optional
  return (
    <Wrapper justifyContent="flex-start" alignItems="center" unsetZIndex>
      <Row flex={1} unsetZIndex>
        <Row isVisible={canChangeCount}>
          <ComboBoxSingle<number>
            value={count}
            fetcher={countFetcher}
            onChange={handleCountChange}
          />
        </Row>
      </Row>

      <Row flex={1} alignItems="center" justifyContent="center">
        <Visible if={hasPages}>
          <Text variant="caption">{pageDisplay}</Text>
        </Visible>
      </Row>

      <Row flex={1} justifyContent="flex-end">
        <Visible if={hasPages}>
          <Button
            shape="round"
            variant="text"
            icon="first-page"
            disabled={isFirstPage}
            onPress={resetPage}
          />
          <Button
            shape="round"
            variant="text"
            icon="chevron-left"
            disabled={!canPressPrevious}
            onPress={onPreviousPress}
          />
          <Button
            shape="round"
            variant="text"
            icon="chevron-right"
            disabled={!canPressNext}
            onPress={onNextPress}
          />
        </Visible>
      </Row>
    </Wrapper>
  );
};
