import { GroupSecurity } from '@quromedical/fhir-common';
import { Device, Validations } from '@quromedical/models';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Alert, FormCard, Skeleton, SkeletonProvider, Snackbar, Visible } from 'design-system';
import { logger } from 'helpers';
import { useUserSession } from 'hooks';
import { useFiniteState } from 'hooks/useFiniteState';
import { DeviceApi } from 'integration/aggregate';
import React, { useCallback } from 'react';
import { strings } from 'strings';

import { deviceFormFields, deviceCardRows } from './internal';

interface DeviceCardProps {
  id: string;
  device?: Device.GetResponse;
  revalidate: () => Promise<void>;
}

type State = 'initial' | 'submitting' | 'error' | 'editing';

const api = new DeviceApi();

export const DeviceCard: React.FC<DeviceCardProps> = ({ id, device, revalidate }) => {
  const state = useFiniteState<State>('initial');

  const rows = deviceCardRows(device);
  const session = useUserSession();
  const canEdit = session.hasAny([GroupSecurity.DeviceUpdate]);

  const onSubmit = useCallback<SubmissionHandler<Device.CreateRequest>>(
    async (formData: Device.CreateRequest) => {
      try {
        state.set('submitting');
        await api.updateDevice(id, formData);

        revalidate().catch(logger.error);
        state.set('initial');
        return {};
      } catch (error) {
        state.set('error');
        logger.error(error);
        return { error };
      }
    },
    [id, revalidate, state]
  );

  const initialValues = device || {};

  return (
    <>
      <SkeletonProvider loading={state.is('submitting')}>
        <Skeleton>
          <Visible if={state.isNot('editing')}>
            <FormCard
              onButtonPress={state.next('editing')}
              isButtonVisible={canEdit}
              buttonText={strings.ButtonTextEdit}
              title={strings.DeviceDetails}
              buttonIcon="edit"
              showIcons
              rows={rows}
            />
          </Visible>

          <Visible if={state.is('editing')}>
            <CrudForm<Device.UpdateRequest>
              cardProps={{ unsetZIndex: true }}
              title={strings.DeviceDetails}
              buttonText={strings.ButtonTextUpdate}
              fields={deviceFormFields}
              initialValues={initialValues}
              includeIcons={true}
              onSubmit={onSubmit}
              onSecondarySubmit={state.next('initial')}
              showSecondarySubmitButton={true}
              validationSchema={Validations.deviceSchema}
            />
          </Visible>
        </Skeleton>
      </SkeletonProvider>

      <Snackbar onClose={state.next('editing')} isOpen={state.is('error')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={state.next('editing')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
        />
      </Snackbar>
    </>
  );
};
