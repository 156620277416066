import { Logistics, Patient } from '@quromedical/models';
import {
  CollectionCard,
  GetDistance,
  SubmissionData,
  BookingTable,
  QuoteTable,
} from 'core/booking';
import { Alert, Section, Skeleton, SkeletonProvider, Snackbar, Visible } from 'design-system';
import { logger } from 'helpers';
import { useFiniteState } from 'hooks/useFiniteState';
import { useRevalidation } from 'hooks/useRevalidation';
import { LogisticsApi } from 'integration/aggregate';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';
import useSWR from 'swr';

interface PatientLogisticTabProps {
  patientId: string;
  patient: Patient.GetPatientResponse;
}

type Quotations = Logistics.Quote[];
const logisticsApi = new LogisticsApi();

const errorStates = ['quotationError', 'bookingError', 'loadingError'] as const;
type ErrorState = typeof errorStates[number];
type State = 'search' | 'loading' | 'selecting' | ErrorState;

const errorMessages: Record<ErrorState, string> = {
  quotationError: strings.LogisticsQuotationError,
  bookingError: strings.LogisticsQuoteSubmittingError,
  loadingError: strings.LogisticsBookingsLoadingError,
};

const isErrorState = (state: State): state is ErrorState =>
  errorStates.includes(state as ErrorState);

export const PatientLogisticTab: React.FC<PatientLogisticTabProps> = ({ patient, patientId }) => {
  const state = useFiniteState<State>('search');
  const bookingFetcher = () =>
    logisticsApi.getBookings({
      patientId,
      providerName: 'droppa',
    });

  const bookingSwrKey = logisticsApi.getPatientLogisticsSWRKey(patientId);

  const bookingSwr = useSWR(bookingSwrKey, bookingFetcher);

  const isValidating = bookingSwr.isValidating;
  const bookingData = bookingSwr.data;

  const revalidateBooking = useRevalidation(bookingSwrKey);

  const [formData, setFormData] = useState<Partial<SubmissionData>>({});
  const [quotations, setQuotations] = useState<Quotations>([]);

  const getQuotation = useCallback(
    async (submissionData: SubmissionData) => {
      const quoteRequest: Logistics.QuoteRequest = {
        organizationId: submissionData.organizationId,
        patientId,
        packages: submissionData.collectionKit,
      };
      try {
        state.set('loading');
        const result = await logisticsApi.getQuote(quoteRequest);
        setFormData(submissionData);
        setQuotations(result.quotes);
        state.set('selecting');
      } catch (exception) {
        logger.error(exception);
        state.set('quotationError');
      }
    },
    [state, patientId]
  );

  const bookQuotation = useCallback(
    async (selectedQuote: Logistics.Quote) => {
      if (!formData.organizationId || !formData.collectionKit) {
        state.set('bookingError');

        return;
      }
      const bookingRequest: Logistics.BookingRequest = {
        organizationId: formData.organizationId,
        packages: formData.collectionKit,
        quote: selectedQuote,
        patientId,
      };

      try {
        state.set('loading');
        await logisticsApi.createBooking(bookingRequest);
        revalidateBooking();
        setQuotations([]);
        state.set('search');
      } catch (exception) {
        logger.error(exception);
        state.set('bookingError');
      }
    },
    [formData.collectionKit, formData.organizationId, patientId, revalidateBooking, state]
  );

  const getDistance = useCallback<GetDistance>(async (patient: string, organization: string) => {
    const res = await logisticsApi.getDistance({
      patient,
      organization,
    });

    return {
      distance: res.value,
    };
  }, []);

  const isLoading = state.is('loading') || isValidating;

  if (bookingSwr.error) {
    state.set('loadingError');
  }

  const isError = isErrorState(state.value);

  return (
    <SkeletonProvider loading={isLoading}>
      <Section unsetZIndex>
        <Skeleton>
          <CollectionCard
            patient={patient}
            patientId={patientId}
            onSubmit={getQuotation}
            getDistance={getDistance}
          />
        </Skeleton>
      </Section>

      <Visible if={quotations.length > 0}>
        <Section unsetZIndex>
          <Skeleton>
            <QuoteTable quotes={quotations} onSubmit={bookQuotation} />
          </Skeleton>
        </Section>
      </Visible>

      <Section unsetZIndex>
        <Skeleton>
          {bookingData ? (
            <BookingTable bookings={bookingData} />
          ) : (
            <Alert
              actionIcon="close"
              backgroundColor="status-critical"
              onAction={revalidateBooking}
              title={strings.ErrorCardTitle}
              body={strings.AlertPatientLoadingError}
            />
          )}
        </Skeleton>
      </Section>

      {isErrorState(state.value) ? (
        <Snackbar isOpen={isError} onClose={state.next('search')}>
          <Alert
            actionIcon="close"
            backgroundColor="status-critical"
            onAction={state.next('search')}
            title={strings.ErrorCardTitle}
            body={errorMessages[state.value]}
          />
        </Snackbar>
      ) : null}
    </SkeletonProvider>
  );
};
