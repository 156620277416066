import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Row, Col } from 'components/base';
import { Card, Divider, Visible, Button, IconName, Text } from 'design-system/base';
import { Grid } from 'design-system/layout';
import { BORDER_RADIUS, BASE_SIZE, MARGIN } from 'design-system/theme';
import { shortMonthDateFormatter, timeFormatter } from 'helpers';
import { useIsSmallerThan } from 'hooks/useResponsive';
import React from 'react';

import { FileCard } from '../file-card';
import { Tag } from '../tag';

export interface Measurement {
  name?: string;
  value: string | number;
  unit?: string;
}

export interface Attachment {
  name: string;
  onDownloadPress: () => Promise<void>;
}

export interface Note {
  title?: string;
  body?: string | JSX.Element;
  measurements?: Measurement[];
}

export interface TagConfig {
  text: string;
  color: ColorName;
  textColor?: ColorName;
}

export interface NoteAction {
  text?: string;
  icon?: IconName;
  color?: ColorName;
  onPress: () => void;
}

interface NoteCardProps {
  date?: Date;
  tags?: TagConfig[];
  notes: Note[];
  attachments?: Attachment[];
  createdBy?: string;
  action?: NoteAction;
}

interface SizeProps {
  isSmall: boolean;
}

const LeftSection = styled(Col)<SizeProps>(({ isSmall }) => ({
  width: isSmall ? undefined : BASE_SIZE[148],
}));

const TagWrapper = styled.View<SizeProps>(({ isSmall }) => ({
  marginTop: MARGIN['2xs'],
  marginRight: isSmall ? MARGIN['2xs'] : undefined,
  flexDirection: 'row',
  flexWrap: 'wrap',
}));

const NoteWrapper = styled.View({
  marginBottom: MARGIN['2xs'],
});

const Dot = styled.View(({ theme }) => ({
  height: BASE_SIZE[4],
  width: BASE_SIZE[4],
  marginHorizontal: MARGIN['2xs'],
  backgroundColor: theme.color['text-default'],
  borderRadius: BORDER_RADIUS.full,
}));

export const NoteCard: React.FC<NoteCardProps> = ({
  date,
  notes = [],
  attachments = [],
  tags = [],
  createdBy = '',
  action,
}) => {
  const dateDisplay = shortMonthDateFormatter(date) || '';
  const timeDisplay = timeFormatter(date) || '';
  const isSmall = useIsSmallerThan('sm');

  const DirectionalView = isSmall ? Col : Row;
  const TagGroup = isSmall ? Row : Col;

  const dividerDirection = isSmall ? 'horizontal' : 'vertical';
  const alignMeasurements = isSmall ? undefined : 'center';

  return (
    <Card>
      <DirectionalView>
        <LeftSection isSmall={isSmall}>
          <Text numberOfLines={1} ellipsizeMode="head" variant="body-strong">
            {createdBy}
          </Text>
          <Text variant="body">{dateDisplay}</Text>
          <Text variant="caption">{timeDisplay}</Text>
          <TagGroup>
            {tags.map((tag) => (
              <TagWrapper isSmall={isSmall} key={tag.text}>
                <Tag text={tag.text} color={tag.color} textColor={tag.textColor} />
              </TagWrapper>
            ))}
          </TagGroup>
        </LeftSection>
        <Divider orientation={dividerDirection} spacing="m" />
        <Col flex={1}>
          {action ? (
            <Row justifyContent="flex-end">
              <Button
                size="large"
                variant="text"
                iconPosition="right"
                textColor={action.color}
                text={action.text}
                icon={action.icon}
                onPress={action.onPress}
              />
            </Row>
          ) : null}
          {notes.map((note, noteIndex) => (
            <NoteWrapper key={noteIndex}>
              <Text selectable variant="body-strong">
                {note.title || ''}
              </Text>
              <Visible if={note.body}>
                {typeof note.body === 'string' ? (
                  <Text selectable variant="body">
                    {note.body || ''}
                  </Text>
                ) : (
                  note.body
                )}
              </Visible>
              <DirectionalView alignItems={alignMeasurements} flexWrap="wrap">
                {note.measurements?.map((measurement, measureIndex, arr) => {
                  const isLast = arr.length - 1 === measureIndex;
                  const showDot = !isLast && !isSmall;

                  const text = `${measurement.name || ''}: ${measurement.value || ''}${
                    measurement.unit || ''
                  }`;

                  return (
                    <React.Fragment key={measureIndex}>
                      <Row>
                        <Text selectable variant="body">
                          {text}
                        </Text>
                      </Row>
                      <Visible if={showDot}>
                        <Dot />
                      </Visible>
                    </React.Fragment>
                  );
                })}
              </DirectionalView>
            </NoteWrapper>
          ))}
          <Grid sm={1} md={2} lg={3} xl={4} gap="xs">
            {attachments?.map((attachment, attachmentIndex) => (
              <FileCard
                key={attachmentIndex}
                name={attachment.name}
                onPress={attachment.onDownloadPress}
              />
            ))}
          </Grid>
        </Col>
      </DirectionalView>
    </Card>
  );
};
