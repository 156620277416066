import styled from '@emotion/native';
import { Col, Row } from 'components/base';
import React from 'react';
import { ScrollView } from 'react-native';

import { SectionProps } from './types';

const ContentWrapper = styled(Row)(({ theme }) => ({
  backgroundColor: theme.color['base-grey'],
}));

export const Aside: React.FC<SectionProps> = ({ flex = 1, hidden = false, children }) => {
  if (hidden) {
    return null;
  }

  return (
    <ContentWrapper flex={flex}>
      <Col flex={1}>
        <ScrollView>{children}</ScrollView>
      </Col>
    </ContentWrapper>
  );
};
