import { getDisplayName } from 'core/person';
import { OrganizationApi } from 'integration';
import { DeviceApi, RelatedPersonApi, PatientApi, PractitionerApi } from 'integration/aggregate';
import { LinkTo } from 'navigation';

const practitionerApi = new PractitionerApi();
const deviceApi = new DeviceApi();
const patientApi = new PatientApi();
const organizationApi = new OrganizationApi();
const relatedPersonApi = new RelatedPersonApi();

export type Fetcher = (id: string) => Promise<string | undefined>;

export type SupportedResource =
  | 'Practitioner'
  | 'Patient'
  | 'Device'
  | 'Organization'
  | 'RelatedPerson';

export const fetchers: Record<SupportedResource, Fetcher> = {
  Practitioner: (id: string) =>
    practitionerApi.getPractitioner(id).then((value) => getDisplayName(value.general)),
  Device: (id: string) => deviceApi.getDevice(id).then((value) => value.name),
  Patient: (id: string) => patientApi.getPatient(id).then((value) => getDisplayName(value.general)),
  Organization: (id: string) => organizationApi.getOrganization(id).then((org) => org.name),
  RelatedPerson: (id: string) =>
    relatedPersonApi.getRelatedPerson(id).then((value) => getDisplayName(value.general)),
};

export const linkMap: Record<SupportedResource, (id: string) => LinkTo> = {
  Patient: (id) => ({
    screen: 'Patient',
    params: {
      screen: 'View',
      params: {
        id,
        screen: 'Profile',
      },
    },
  }),
  Device: (id) => ({
    screen: 'Admin',
    params: {
      screen: 'Device',
      params: {
        screen: 'View',
        params: {
          id,
        },
      },
    },
  }),
  Practitioner: (id) => ({
    screen: 'Admin',
    params: {
      screen: 'Practitioner',
      params: {
        screen: 'View',
        params: {
          id,
        },
      },
    },
  }),
  Organization: (id) => ({
    screen: 'Admin',
    params: {
      screen: 'Organization',
      params: {
        screen: 'View',
        params: {
          id,
        },
      },
    },
  }),
  RelatedPerson: (id) => ({
    screen: 'Admin',
    params: {
      screen: 'RelatedPerson',
      params: {
        screen: 'View',
        params: {
          id,
        },
      },
    },
  }),
};
