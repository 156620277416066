import { DeviceCommand } from '@quromedical/models';
import { Util } from '@quromedical/utils';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Field } from 'components/types';
import { mapDisplaysToOptionsWithStart } from 'core/forms';
import { Button, Dialog, ToggleCard } from 'design-system';
import { useBoolean } from 'hooks/useBoolean';
import { useFiniteState } from 'hooks/useFiniteState';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

export interface ConfigurePatchFormData {
  temperature?: number;
  posture?: DeviceCommand.PatchPostureCalibrateValue;
}

interface PatchConfigureFormProps {
  inline?: boolean;
  onSubmit: Util.SyncOrAsyncVoidFn<ConfigurePatchFormData>;
  revalidate?: Util.SyncOrAsync<() => void>;
}

interface FormProps extends Omit<PatchConfigureFormProps, 'inline'> {
  showSecondaryButton: boolean;
  onClose: () => void;
}

const postureDisplays: Record<DeviceCommand.PatchPostureCalibrateValue, string> = {
  [DeviceCommand.PatchPostureCalibrateValue.Upright]: strings.PostureCalibrationUpright,
  [DeviceCommand.PatchPostureCalibrateValue.LayingSupine]: strings.PostureCalibrationLayingSupine,
};

const postureValues = Object.values(DeviceCommand.PatchPostureCalibrateValue);

const postureOptions = mapDisplaysToOptionsWithStart(
  {
    value: undefined,
    display: strings.PostureCalibrationUnknown,
  },
  postureDisplays
);

const fields: Field<ConfigurePatchFormData>[] = [
  {
    subfields: [
      {
        key: 'temperature',
        type: 'text-box',
        label: strings.PatchCalibrateTitleTemperature,
      },
      {
        key: 'posture',
        type: 'combobox-single',
        label: strings.PatchCalibrateTitlePosture,
        fetcher: postureOptions,
      },
    ],
  },
];

const schema = yup.object<ObjectType<ConfigurePatchFormData>>({
  posture: yup.string().equals(postureValues).notRequired(),
  temperature: yup.number().notRequired(),
});

type State = 'editing' | 'loading' | 'error';

const Form: React.FC<FormProps> = ({ onSubmit, showSecondaryButton, revalidate, onClose }) => {
  const state = useFiniteState<State>('editing');

  const [submitted, setSubmitted] = useState<number | undefined>(undefined);

  const handleSubmit = useCallback<SubmissionHandler<ConfigurePatchFormData>>(
    async (data: ConfigurePatchFormData) => {
      state.set('loading');
      try {
        await onSubmit({
          posture: data.posture,
          temperature: data.temperature ? +data.temperature : undefined,
        });
        await revalidate?.();
        onClose();
        state.set('editing');
        setSubmitted(Date.now());
        return {};
      } catch (error) {
        state.set('error');
        return { error };
      }
    },
    [onSubmit, revalidate, state, onClose]
  );

  const error = state.is('error') ? strings.GenericErrorMessage : undefined;

  return (
    <CrudForm
      key={submitted}
      hasCard={false}
      initialValues={{}}
      validationSchema={schema}
      fields={fields}
      onSubmit={handleSubmit}
      disabled={state.is('loading')}
      onChange={state.next('editing')}
      onChangeDebounceTime={0}
      error={error}
      showSecondarySubmitButton={showSecondaryButton}
      onSecondarySubmit={onClose}
      secondaryButtonText={strings.ButtonTextCancel}
    />
  );
};

export const PatchConfigureForm: React.FC<PatchConfigureFormProps> = ({
  onSubmit,
  revalidate,
  inline = false,
}) => {
  const expanded = useBoolean(false);

  if (inline) {
    return (
      <ToggleCard icon="patch" title={strings.PatchCalibrateFormTitle} initialExpanded={false}>
        <Form
          onSubmit={onSubmit}
          revalidate={revalidate}
          onClose={expanded.setFalse}
          showSecondaryButton={false}
        />
      </ToggleCard>
    );
  }

  return (
    <>
      <Button text={strings.PatchCalibrateFormTitleShort} onPress={expanded.setTrue} />
      <Dialog
        title={strings.PatchCalibrateFormTitle}
        onRequestClose={expanded.setFalse}
        isOpen={expanded.value}
      >
        <Form
          onSubmit={onSubmit}
          revalidate={revalidate}
          onClose={expanded.setFalse}
          showSecondaryButton={!inline}
        />
      </Dialog>
    </>
  );
};
