import { Medication } from '@quromedical/models';
import { Col, Row } from 'components/base';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Field, SelectOption } from 'components/types';
import { AsyncFetcher, Dialog } from 'design-system';
import { Button } from 'design-system/base';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

type PickerState = {
  isOpen: boolean;
};

/**
 * Define the recipient as a union and not just a string to enable us to more strictly check that
 * is is a valid data entry
 */
type RecipientValue = 'Patient' | `Organization/${string}`;
interface CustomForm {
  recipient: RecipientValue;
}

const customFormSchema = yup.object<ObjectType<CustomForm>>({
  recipient: yup.string().required(),
});

interface RecipientPickerProps {
  /**
   * This will handle the error internally if thrown
   */
  onSubmit: (result: Medication.Recipient) => Promise<void> | void;
  organizationFetcher: (query?: string) => Promise<SelectOption[]>;
}

export const RecipientPicker: React.FC<RecipientPickerProps> = ({
  onSubmit,
  organizationFetcher,
}) => {
  const [state, setState] = useState<PickerState>({
    isOpen: false,
  });

  const handleDialogClose = useCallback(() => {
    setState({ ...state, isOpen: false });
  }, [state]);

  const handleSubmit = useCallback<SubmissionHandler<CustomForm>>(
    async (data: CustomForm) => {
      try {
        if (data.recipient === 'Patient') {
          onSubmit({ type: 'patient' });
        } else {
          const orgId = data.recipient.split('/')[1];
          onSubmit({ type: 'organization', id: orgId });
        }
        return {};
      } catch (error) {
        return { error };
      }
    },
    [onSubmit]
  );

  const openDialog = useCallback(() => {
    setState({
      ...state,
      isOpen: true,
    });
  }, [state]);

  const getOrganizations = useCallback<AsyncFetcher<RecipientValue>>(async () => {
    const organizations = await organizationFetcher();

    const orgOptions = organizations.map<SelectOption<undefined, RecipientValue>>((org) => ({
      value: `Organization/${org.value}`,
      display: org.display,
    }));

    return [
      {
        display: strings.TitlePatient,
        value: 'Patient',
      },
      ...orgOptions,
    ];
  }, [organizationFetcher]);

  const fields: Field<CustomForm>[] = [
    {
      subfields: [
        {
          key: 'recipient',
          type: 'combobox-single',
          fetcher: getOrganizations,
          searchable: true,
          label: strings.LabelRecipient,
        },
      ],
    },
  ];

  return (
    <>
      <Col flex={1} unsetZIndex>
        <Row alignItems="flex-end" justifyContent="flex-end">
          <Button variant="contained" text={strings.ButtonTextSubmit} onPress={openDialog} />
        </Row>
      </Col>
      <Dialog
        onRequestClose={handleDialogClose}
        isOpen={state.isOpen}
        title={strings.LabelRecipientPicker}
      >
        <CrudForm
          isNotResponsive
          hasCard={false}
          fields={fields}
          onSubmit={handleSubmit}
          buttonText={strings.ButtonTextSubmit}
          onSecondarySubmit={handleDialogClose}
          secondaryButtonText={strings.ButtonTextCancel}
          showSecondarySubmitButton
          validationSchema={customFormSchema}
          initialValues={{}}
        />
      </Dialog>
    </>
  );
};
