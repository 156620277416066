import { GroupSecurity } from '@quromedical/fhir-common';
import { Person } from '@quromedical/models';
import { Alert, Section, Skeleton, SkeletonProvider, Snackbar, Visible } from 'design-system';
import { onMapsPress } from 'helpers/platform-links';
import { useUserSession } from 'hooks';
import { useRevalidation } from 'hooks/useRevalidation';
import { getAddressPartialFetcher, googlePlaceFetcher } from 'integration';
import { PatientApi, PatientEndpoint, RelatedPersonApi } from 'integration/aggregate';
import React, { useCallback, useState } from 'react';
import { PersonContactForm, PersonGeneralForm } from 'screens/person';
import { strings } from 'strings';
import useSWR from 'swr';

interface PatientNextOfKinTabProps {
  patientId: string;
}

const api = new PatientApi();

export const PatientNextOfKinTab: React.FC<PatientNextOfKinTabProps> = ({ patientId }) => {
  const session = useUserSession();

  const [showAlert, setAlert] = useState<boolean>(true);
  const closeAlert = useCallback(() => {
    setAlert(false);
  }, []);

  const fetcher = () => api.getNextOfKin(patientId);
  const swrKey = api.getPatientSWRKey(patientId, PatientEndpoint.NextOfKin);
  const { data, isValidating, error } = useSWR(swrKey, fetcher);

  const revalidate = useRevalidation(patientId);
  const canEdit = session.hasAny([GroupSecurity.PatientUpdate, GroupSecurity.RelatedPersonUpdate]);

  const relatedPersonId = data?.nextOfKin.id as string;
  const general = data?.nextOfKin.general;
  const contact = data?.nextOfKin.contact;

  const onGeneralSubmit = useCallback(
    async (formData: Person.General) => {
      const relaterPersonApi = new RelatedPersonApi();
      await relaterPersonApi.updateGeneralRelatedPerson(relatedPersonId, patientId, formData);
    },
    [patientId, relatedPersonId]
  );

  const onContactSubmit = useCallback(
    async (formData: Person.Contact) => {
      const relaterPersonApi = new RelatedPersonApi();
      await relaterPersonApi.updateContactRelatedPerson(relatedPersonId, patientId, formData);
    },
    [patientId, relatedPersonId]
  );
  const isButtonDisabled = !data?.nextOfKin.contact?.addressCoordinates;

  const onMapsPressed = useCallback(() => {
    onMapsPress(data?.nextOfKin.contact);
  }, [data?.nextOfKin.contact]);

  return (
    <SkeletonProvider loading={isValidating}>
      <Visible if={!error}>
        <Section>
          <Skeleton>
            <PersonGeneralForm
              canEdit={canEdit}
              data={general}
              onSubmit={onGeneralSubmit}
              revalidate={revalidate}
            />
          </Skeleton>
        </Section>

        <Section hasBottomMargin>
          <Skeleton>
            <PersonContactForm
              canEdit={canEdit}
              data={contact}
              onSubmit={onContactSubmit}
              revalidate={revalidate}
              hasEmergencyAccess={false}
              addressFetcher={googlePlaceFetcher}
              getAddressPartial={getAddressPartialFetcher}
              onMapsPress={onMapsPressed}
              isButtonDisabled={isButtonDisabled}
            />
          </Skeleton>
        </Section>
      </Visible>

      <Visible if={error}>
        <Snackbar isOpen={showAlert} onClose={closeAlert}>
          <Alert
            actionIcon="close"
            backgroundColor="status-critical"
            onAction={closeAlert}
            title={strings.AlertError}
            body={strings.NextOfKinError}
          />
        </Snackbar>
      </Visible>
    </SkeletonProvider>
  );
};
