import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Text } from 'design-system/base';
import { BASE_SIZE, BORDER_RADIUS, BORDER_WIDTH } from 'design-system/theme';
import { View } from 'react-native';

interface NotificationDotProps {
  color?: ColorName;
  count?: number;
  border?: boolean;
}

interface DotProps {
  color: ColorName;
  border?: boolean;
  hasCount?: boolean;
}

const CountWrapper = styled.View({
  // minimal padding just to help center text and compensate for the line-height of the font
  paddingTop: BASE_SIZE[1],
});

const Dot = styled(View)<DotProps>(({ color, theme, hasCount, border = false }) => {
  const size = hasCount ? BASE_SIZE[20] : BASE_SIZE[12];

  const resolvedColor = theme.color[color];

  return {
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: border ? BORDER_WIDTH[2] : 0,
    borderColor: border ? resolvedColor : undefined,
    backgroundColor: border ? undefined : resolvedColor,
    borderRadius: BORDER_RADIUS.full,
    height: size,
    width: size,
  };
});

/**
 * Display a notification as either a count or just a dot - note that if using a count the max
 * supported value is 99, will fallback to `++` otherwise there will be too many digits
 */
export const NotificationDot: React.FC<NotificationDotProps> = ({
  count,
  color = 'text-default',
  border = false,
}) => {
  const textColor = border ? color : 'text-on-color';

  if (!count) {
    return <Dot border={border} color={color} />;
  }

  const resolvedCount = count > 99 ? '++' : count;

  return (
    <Dot border={border} color={color} hasCount={!!count}>
      <CountWrapper>
        <Text color={textColor} variant="caption-small">
          {resolvedCount}
        </Text>
      </CountWrapper>
    </Dot>
  );
};
