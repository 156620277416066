import { FileResult } from 'components/types';
import { Button } from 'design-system/base';
import { getDocumentAsync } from 'expo-document-picker';
import { readFiles } from 'helpers/file';
import React, { useCallback } from 'react';
import { strings } from 'strings';

interface UploadButtonProps {
  onSelect: (files: FileResult[]) => void;
}

export const UploadButton: React.FC<UploadButtonProps> = ({ onSelect }) => {
  const onPress = useCallback(async () => {
    const document = await getDocumentAsync({
      multiple: true,
    });

    if (document.type === 'success') {
      const files = await readFiles(document);

      onSelect(files);
    }
  }, [onSelect]);

  return <Button onPress={onPress} variant="outlined" text={strings.ChooseFile} />;
};
