import { EncounterService, getReferenceId } from '@quromedical/fhir-common';
import { Admission } from '@quromedical/models';
import { useLinkTo } from '@react-navigation/native';
import { CrudForm } from 'components/form';
import { ColumnType, SortConfig, useTableState } from 'components/table';
import { Field } from 'components/types';
import { admissionServiceDisplay, admissionStatusDisplay, getDisplayDate } from 'core/display';
import { mapDisplaysToOptionsWithStart } from 'core/forms';
import { PaginatedTable } from 'core/table';
import { Section, Sidebar } from 'design-system/layout';
import { getField } from 'hooks/usePaginatedData';
import { AdmissionApi } from 'integration/aggregate';
import omitBy from 'lodash.omitby';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';

import { Header } from './Header';

const api = new AdmissionApi();

const columns: ColumnType<Admission.GetResponse>[] = [
  {
    accessor: 'patient',
    header: strings.LabelNameAndSurname,
    Cell: ({ value }) => value?.display || '',
  } as ColumnType<Admission.GetResponse, 'patient'>,
  {
    accessor: 'status',
    header: strings.AdmissionStatusLabel,
    disableSortBy: false,
    Cell: ({ value = 'unknown' }) => admissionStatusDisplay[value],
  } as ColumnType<Admission.GetResponse, 'status'>,
  {
    accessor: 'period',
    header: strings.AdmissionStartLabel,
    disableSortBy: false,
    Cell: ({ value }) => getDisplayDate(value?.start) || '',
  } as ColumnType<Admission.GetResponse, 'period'>,
  {
    accessor: 'service',
    header: strings.AdmissionServiceLabel,
    disableSortBy: false,
    Cell: ({ value }) => admissionServiceDisplay[value as EncounterService] || '',
  } as ColumnType<Admission.GetResponse, 'service'>,
];

type Filter = Pick<Admission.GetParams, 'status' | 'patientName' | 'serviceType'>;

const all = '__all__';

const allOption = {
  value: all,
  display: strings.SortFilterAll,
};

const inUseStatuses: Pick<typeof admissionStatusDisplay, 'in-progress' | 'finished'> = {
  finished: admissionStatusDisplay.finished,
  'in-progress': admissionStatusDisplay['in-progress'],
};

const filterFields: Field<Filter>[] = [
  {
    subfields: [
      {
        key: 'serviceType',
        type: 'combobox-single',
        label: strings.AdmissionServiceLabel,
        fetcher: mapDisplaysToOptionsWithStart(allOption, admissionServiceDisplay),
      },
    ],
  },
  {
    subfields: [
      {
        key: 'status',
        type: 'combobox-single',
        label: strings.AdmissionStatusLabel,
        fetcher: mapDisplaysToOptionsWithStart(allOption, inUseStatuses),
      },
    ],
  },
];

const shouldOmitOption = (value?: string): boolean => {
  if (!value?.length) {
    return true;
  }

  return value === all;
};

const sanitizeFilter = (data: Filter) => omitBy(data, shouldOmitOption);

const sortConfig: SortConfig<Admission.GetResponse> = {
  status: {
    asc: 'status',
    desc: '-status',
  },
  period: {
    asc: 'date',
    desc: '-date',
  },
  service: {
    asc: 'service-type',
    desc: '-service-type',
  },
};

export const AdmissionListScreen: React.FC = () => {
  const sidebarProps = Sidebar.useResponsiveSidebarProps();
  const navigate = useLinkTo();
  const [filter, setFilter] = useState<Filter>({});

  const setPatient = useCallback(
    (patientName?: string) => setFilter({ ...filter, patientName }),
    [filter]
  );

  const tableState = useTableState<Admission.GetResponse>({ sort: sortConfig });

  const cacheKey = [api.getAdmissionSWRKey(), filter, tableState.sort];

  const fetcher = useCallback(
    (pageToken?: string) =>
      api.getAdmissions({
        ...sanitizeFilter(filter),
        pageToken,
        count: 20,
        sort: tableState.sort,
        includeAdminAssignments: true,
      }),
    [filter, tableState.sort]
  );

  const onRowPress = useCallback(
    (row: Admission.GetResponse) => {
      const id = getReferenceId(row.patient);

      if (!id) {
        // this shouldn't happen in practice
        return;
      }

      navigate({
        screen: 'Patient',
        params: {
          screen: 'View',
          params: {
            id,
            screen: 'Profile',
          },
        },
      });
    },
    [navigate]
  );

  return (
    <Sidebar.Container>
      <Sidebar.Main {...sidebarProps.main}>
        <Section>
          <Header onSearchChange={setPatient} screen="admissions" />
        </Section>
        <Section hasBottomMargin>
          <PaginatedTable
            cacheKey={cacheKey}
            fetcher={fetcher}
            getDataFromResponse={getField('admissions')}
            onRowPress={onRowPress}
            onTableStateChanged={tableState.onChange}
            columns={columns}
          />
        </Section>
      </Sidebar.Main>
      <Sidebar.Aside {...sidebarProps.aside}>
        <CrudForm<Filter>
          onChangeDebounceTime={0}
          showSubmitButton={false}
          onChange={setFilter}
          initialValues={{}}
          title={strings.FormTitleSearch}
          fields={filterFields}
        />
      </Sidebar.Aside>
    </Sidebar.Container>
  );
};
