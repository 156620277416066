import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Col, Row } from 'components/base';
import { Text, Icon, IconName, Skeleton } from 'design-system/base';
import { BASE_SIZE, MARGIN } from 'design-system/theme';
import React from 'react';

interface ItemProps {
  icon: IconName;
  /**
   * Optional to facilitate loading
   */
  count?: number;
  label?: string;
  iconColor?: ColorName;
}

const TextWrapper = styled.View({
  marginLeft: MARGIN['2xs'],
});

const CountText = styled(Text)({
  minWidth: BASE_SIZE[64],
});

export const Item: React.FC<ItemProps> = ({ label = '', count = '&nbsp;', icon, iconColor }) => (
  <Col flex={1}>
    <Row>
      <Skeleton fullWidth={false}>
        <CountText variant="page-heading">{count}</CountText>
      </Skeleton>
    </Row>
    <Row alignItems="center">
      <Icon name={icon} color={iconColor} size={BASE_SIZE[12]} />
      <TextWrapper>
        <Text variant="subheading">{label}</Text>
      </TextWrapper>
    </Row>
  </Col>
);
