import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Col, Row } from 'components/base';
import { Spacer, Text, IconName, Button, Card as BaseCard } from 'design-system/base';
import { fadeColor, MARGIN, SCREEN_SIZE, Size } from 'design-system/theme';
import { noOp } from 'helpers';
import { useIsSmallerThan } from 'hooks/useResponsive';
import React from 'react';
import { Modal, StyleSheet, useWindowDimensions } from 'react-native';

export interface Action {
  text?: string;
  icon?: IconName;
  onPress: () => void;
  disabled?: boolean;
}

export interface DialogProps {
  title: string;
  secondary?: Action;
  primary?: Action;
  isOpen?: boolean;
  color?: ColorName;
  borderColor?: ColorName;
  maxSize?: Size;
  onRequestClose?: () => void;
}

const BackgroundWrapper = styled(Col)(({ theme }) => ({
  ...StyleSheet.absoluteFillObject,
  backgroundColor: fadeColor(theme.color['base-grey'], 0.8),
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const Body = styled(Col)({
  marginVertical: MARGIN.xs,
});

interface CardProps {
  maxSize?: Size;
}

const Card = styled(BaseCard)<CardProps>(({ maxSize = 'md' }) => {
  const { width } = useWindowDimensions();
  const isSmall = useIsSmallerThan(maxSize);

  const responsiveWidth = isSmall ? width : SCREEN_SIZE[maxSize];
  const resolvedWidth = responsiveWidth * 0.8;

  return {
    width: resolvedWidth,
  };
});

export const Dialog: React.FC<DialogProps> = ({
  isOpen = false,
  children,
  primary,
  secondary,
  borderColor,
  color = 'base',
  title = '',
  maxSize,
  // required for android
  onRequestClose = noOp,
}) => (
  <Modal transparent visible={isOpen} onRequestClose={onRequestClose} animationType="fade">
    <BackgroundWrapper>
      <Card maxSize={maxSize} color={color} borderColor={borderColor} border hasThickBorder>
        <Text variant="heading">{title}</Text>
        <Body>{children}</Body>
        <Row alignItems="center" justifyContent="flex-end">
          {secondary ? (
            <Button
              variant="flat"
              text={secondary.text}
              onPress={secondary.onPress}
              icon={secondary.icon}
              disabled={secondary.disabled}
            />
          ) : null}
          {primary ? (
            <>
              <Spacer width={MARGIN.s} />
              <Button
                variant="contained"
                text={primary.text}
                onPress={primary.onPress}
                icon={primary.icon}
                disabled={primary.disabled}
              />
            </>
          ) : null}
        </Row>
      </Card>
    </BackgroundWrapper>
  </Modal>
);
