import compact from 'lodash.compact';

import { Node, Group, Comment, CommentWithPath } from './types';

export const getSelectedTree = (root?: Node, keepEmptyGroups = false): Node | undefined => {
  if (!root?.selected) {
    return undefined;
  }

  if (root.type === 'comment') {
    return root;
  }

  const selectedChildren = compact(
    root.children.map((child) => getSelectedTree(child, keepEmptyGroups))
  );

  const subtree: Group = {
    ...root,
    children: selectedChildren,
  };

  if (keepEmptyGroups) {
    return subtree;
  }

  return selectedChildren.length ? subtree : undefined;
};

/**
 * Recursively get selected comments from a root node
 */
export const getSelectedComments = (root: Node): CommentWithPath[] => {
  // if not selected we actually don't need to look through children
  if (!root.selected) {
    return [];
  }

  // recursion escape condition
  if (root.type === 'comment') {
    return [{ ...root, path: root.id }];
  }

  // recurse section
  return root.children
    .map((node) =>
      getSelectedComments(node).map((comment) => ({
        ...comment,
        path: `${root.id}>${comment.id}`,
      }))
    )
    .flat();
};

export const isGroup = (node?: Node): node is Group => node?.type === 'group';
export const isComment = (node?: Node): node is Comment => node?.type === 'comment';
