import { ColorName } from '@emotion/react';
import { Portal } from '@gorhom/portal';
import { Row } from 'components/base';
import { useLayoutDimensions } from 'hooks/useLayoutDimensions';
import React from 'react';
import { View } from 'react-native';

import { FullWindowOverlay, PositionProps } from './FullWindowOverlay';

type AbsoluteMode = 'hosted' | 'full-screen' | 'contained';

interface AbsoluteProps {
  /**
   * Should match the `name` given to the `AbsoluteHost` to identify where the component should be
   * rendered
   */
  name: string;
  /**
   * Customize how the Absolute content should be rendered
   *
   * - `full-screen` - will place it above all native views and take over the screen
   * - `hosted` - will not specify the `AbsoluteHost`, and you can specify this to manually place
   *   content
   * - `contained` - will place above all native views but leave underlying content interactive
   *
   */
  mode: AbsoluteMode;
  /**
   * The background color to use in the case of a `full-screen` overlay
   */
  backgroundColor?: ColorName;
  position?: PositionProps;
}

/**
 * A portal component for enabling absolute view components to be displayed. This will render the
 * component in the position of the `AbsoluteHost`
 *
 * Implements @gorhom/portal with some basic additional functionality
 */
export const Absolute: React.FC<AbsoluteProps> = ({
  name,
  mode,
  backgroundColor,
  position,
  children,
}) => {
  const { layout, onLayout } = useLayoutDimensions({});

  if (mode === 'hosted') {
    return <Portal hostName={name}>{children}</Portal>;
  }

  if (mode === 'full-screen') {
    return (
      <Portal>
        <FullWindowOverlay position={position} backgroundColor={backgroundColor}>
          {children}
        </FullWindowOverlay>
      </Portal>
    );
  }

  return (
    <Portal>
      <FullWindowOverlay
        position={position}
        maxHeight={layout.height}
        maxWidth={layout.width}
        backgroundColor={backgroundColor}
      >
        <Row>
          <View onLayout={onLayout}>{children}</View>
        </Row>
      </FullWindowOverlay>
    </Portal>
  );
};
