import styled from '@emotion/native';
import { MeasurementName, WithRequired } from '@quromedical/models';
import { OxygenSaturationScale } from '@quromedical/news';
import { useNavigation } from '@react-navigation/native';
import { Col } from 'components/base';
import { ColumnType, DataTable, OnTableStateChangedHandler } from 'components/table';
import {
  codes,
  getNEWS2Score,
  getNewsStatusCardMeasurement,
  getPlanStatusCardMeasurement,
  getStatusPrimaryPropMapper,
  StatusMeasurementWithValueIndicator,
} from 'core/vitals';
import { MARGIN, Section, Skeleton, SkeletonProvider, StatusCardMeasure } from 'design-system';
import { useIsSmallerThan } from 'hooks/useResponsive';
import first from 'lodash.first';
import orderBy from 'lodash.orderby';
import React, { useCallback } from 'react';
import { ScrollView } from 'react-native';
import { strings } from 'strings';

import { Layout, Search } from './cards';
import { DashboardGridData } from './DashboardGrid';

type DashboardTableData = WithRequired<DashboardGridData, 'patientId'>;
interface DashboardTableProps {
  data: DashboardTableData[];
  updated: number;
  onLayoutChange: (layout: Layout) => void;
  loading: boolean;
}

const Wrapper = styled(Col)(() => ({
  marginBottom: MARGIN.xs,
}));

interface MeasurementFields {
  ews?: StatusMeasurementWithValueIndicator;
  service?: StatusMeasurementWithValueIndicator;
  heartRate?: StatusMeasurementWithValueIndicator;
  respiratoryRate?: StatusMeasurementWithValueIndicator;
  oxygenSaturation?: StatusMeasurementWithValueIndicator;
  coreTemperature?: StatusMeasurementWithValueIndicator;
  bloodPressure?: StatusMeasurementWithValueIndicator;
}

interface TableData extends MeasurementFields {
  id: string;
  patient?: string;
}

const headers: Partial<Record<keyof MeasurementFields, string>> = {
  ews: strings.EWSTitle,
  service: strings.AdmissionServiceFormLabel,
  respiratoryRate: codes[MeasurementName.RespiratoryRate],
  coreTemperature: codes[MeasurementName.CoreTemperature],
  heartRate: codes[MeasurementName.HeartRate],
  oxygenSaturation: codes[MeasurementName.OxygenSaturation],
  bloodPressure: codes[MeasurementName.CombinedArterialBloodPressure],
};

const column = (key: keyof MeasurementFields): ColumnType<MeasurementFields, typeof key> => ({
  accessor: key,
  // we should never run into a case where we don't have a header for a display metric
  header: headers[key] || '',
  Cell: ({ value }) =>
    value?.hasValue ? <StatusCardMeasure {...value} icon={undefined} name={undefined} /> : null,
});

const measurementColumns: Readonly<Array<keyof MeasurementFields>> = [
  'ews',
  'heartRate',
  'respiratoryRate',
  'coreTemperature',
  'oxygenSaturation',
  'bloodPressure',
  'service',
];

const columns: ColumnType<TableData>[] = [
  {
    accessor: 'patient',
    header: strings.TitlePatient,
  } as ColumnType<TableData, 'patient'>,
  ...measurementColumns.map(column),
];

const mapDashboardPropsToTableData = (data: DashboardTableData): TableData => {
  const latest = data.liveData?.latest || {};

  const getStatusProps = getStatusPrimaryPropMapper(latest, data.oxygenSaturationScale);
  const newsScore = getNEWS2Score(
    latest,
    data.oxygenSaturationScale || OxygenSaturationScale.Scale1
  );

  return {
    id: data.patientId,
    patient: data.title || strings.DisplayNotFound,
    ews: getNewsStatusCardMeasurement(newsScore),
    service: getPlanStatusCardMeasurement(data.admission),
    heartRate: getStatusProps(MeasurementName.HeartRate),
    oxygenSaturation: getStatusProps(MeasurementName.OxygenSaturation),
    respiratoryRate: getStatusProps(MeasurementName.RespiratoryRate),
    coreTemperature: getStatusProps(MeasurementName.CoreTemperature),
    bloodPressure: getStatusProps(MeasurementName.CombinedArterialBloodPressure),
  };
};

type SortDirection = 'asc' | 'desc';

type Sort = [keyof TableData, SortDirection];

const getSortValue = (col: keyof TableData) => (d: TableData) => {
  const base = d[col];

  if (typeof base === 'string') {
    return base;
  }

  if (base?.hasValue) {
    return base.value;
  }

  return undefined;
};

const moveExistingValuesToTop = (
  columnSortedData: TableData[],
  getSortCol: (d: TableData) => string | number | undefined
): TableData[] => {
  const withValue = columnSortedData.filter(getSortCol);
  const withoutValue = columnSortedData.filter((d) => !getSortCol(d));

  const existenceSortedData = [...withValue, ...withoutValue];

  return existenceSortedData;
};

export const DashboardTable: React.FC<DashboardTableProps> = ({
  data,

  loading,
}) => {
  const [sort, setSort] = React.useState<Sort>(['ews', 'desc']);
  const isSmall = useIsSmallerThan('lg');
  const tableData = data.map<TableData>(mapDashboardPropsToTableData);

  const [sortCol, sortDir] = sort;

  const getSortCol = getSortValue(sortCol);
  const columnSortedData = orderBy(tableData, getSortCol, sortDir);
  const existenceSortedData = moveExistingValuesToTop(columnSortedData, getSortCol);

  const onTableStateChanged = useCallback<OnTableStateChangedHandler<TableData>>((tableState) => {
    const sortConfig = first(tableState?.sortBy);
    const col = (sortConfig?.id || 'ews') as keyof TableData;
    const dir = sortConfig?.desc ? 'desc' : 'asc';

    setSort([col, dir]);
  }, []);

  const navigation = useNavigation();

  const onPress = useCallback(
    (d: TableData) => {
      navigation.navigate('Patient', {
        screen: 'View',
        params: {
          id: d.id,
          screen: 'Profile',
        },
      });
    },
    [navigation]
  );

  return (
    <SkeletonProvider loading={loading}>
      <ScrollView>
        <Section unsetZIndex>
          <Wrapper unsetZIndex>
            <Search layout="table" />
          </Wrapper>
          <Skeleton>
            <DataTable
              onRowPress={onPress}
              data={existenceSortedData}
              columns={columns}
              onTableStateChanged={onTableStateChanged}
              debounceTime={0}
              useCards={isSmall}
            />
          </Skeleton>
        </Section>
      </ScrollView>
    </SkeletonProvider>
  );
};
