import { Bundle, getResourcesFromBundle } from '@quromedical/fhir-common';
import { AsyncDownloadButton } from 'components/input';
import { ColumnType } from 'components/table';
import { getDisplayDate } from 'core/display';
import { Attachment } from 'fhir/r4';
import { DiagnosticReportApi } from 'integration';
import first from 'lodash.first';
import React from 'react';
import { strings } from 'strings';

interface PathologyReportTable {
  orderNumber: string;
  reportStatus: string;
  reportDate: string;
  report: Attachment[];
  id: string;
}

const api = new DiagnosticReportApi();

const fetchUrl = (reportId: string) => async (): Promise<string | undefined> => {
  if (!reportId) {
    return undefined;
  }

  const presentedForm = await api.getPresentedForm(reportId);

  const pdf = presentedForm.find((p) => p.contentType === 'application/zip');

  return pdf?.url;
};

export const pathologyReportColumns: ColumnType<PathologyReportTable>[] = [
  {
    header: strings.PathologyOrderNumberSubheading,
    accessor: 'orderNumber',
  } as ColumnType<PathologyReportTable, 'orderNumber'>,
  {
    header: strings.PathologyReportDateSubheading,
    accessor: 'reportDate',
  } as ColumnType<PathologyReportTable, 'reportDate'>,
  {
    header: strings.PathologyReportStatusSubheading,
    accessor: 'reportStatus',
  } as ColumnType<PathologyReportTable, 'reportStatus'>,
  {
    header: strings.MediaDownloadButtonText,
    Cell: ({ row }) => (
      <AsyncDownloadButton
        fetchUrl={fetchUrl(row.original.id)}
        title={strings.PathologyDownloadButtonTitle}
      />
    ),
    accessor: 'report',
  } as ColumnType<PathologyReportTable, 'report'>,
];

export const getDataFromResponse = (
  res: Bundle<fhir4.DiagnosticReport>
): Partial<PathologyReportTable>[] => {
  const data = getResourcesFromBundle(res);
  return data.map((report) => ({
    id: report.id,
    orderNumber: first(report.identifier)?.value,
    reportStatus: report.status,
    reportDate: getDisplayDate(report.effectiveDateTime),
    report: report.presentedForm,
  }));
};
