import { Practitioner } from '@quromedical/models';
import { ColumnType } from 'components/table';
import { strings } from 'strings';

interface Practitioner {
  id: string;
  givenName?: string;
  familyName?: string;
  email?: string;
  phone?: string;
  registrationNumber?: string;
}

export type PractitionerTable = Practitioner;

export const columns: ColumnType<PractitionerTable>[] = [
  {
    header: strings.LabelFamilyName,
    accessor: 'familyName',
  } as ColumnType<PractitionerTable, 'familyName'>,
  {
    header: strings.LabelFamilyName,
    accessor: 'givenName',
  } as ColumnType<PractitionerTable, 'givenName'>,
  {
    header: strings.Email,
    accessor: 'email',
  } as ColumnType<PractitionerTable, 'email'>,
  {
    header: strings.LabelPhone,
    accessor: strings.LabelPhone,
  } as ColumnType<PractitionerTable, 'phone'>,
];
