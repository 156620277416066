import { FONT_FAMILY } from 'design-system/theme';
import * as React from 'react';
import Svg, { Text as BaseText } from 'react-native-svg';

import { CustomIconProps } from './internal';

interface TextProps extends CustomIconProps {
  text: string;
}

export const TextIcon: React.FC<TextProps> = ({ color = '#000000', size = 24, text = '' }) => (
  <Svg width={size} height={size} fill="none">
    <BaseText
      fontSize={size * 0.8}
      fontFamily={FONT_FAMILY.semiBold}
      fill={color}
      x="50%"
      y="50%"
      alignmentBaseline="middle"
      textAnchor="middle"
    >
      {text.toUpperCase()}
    </BaseText>
  </Svg>
);
