import { Util } from '@quromedical/utils';
import { handleDownload } from 'core/media';
import { Button } from 'design-system/base';
import { logger } from 'helpers';
import { useCallback } from 'react';
import { strings } from 'strings';

interface DownloadButtonProps {
  getUrl: Util.SyncOrAsync<() => string>;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({ getUrl }) => {
  const onPress = useCallback(async () => {
    try {
      const url = await getUrl();
      await handleDownload(url);
    } catch (err) {
      logger.error(err);
    }
  }, [getUrl]);

  return <Button onPress={onPress} icon="file-download" text={strings.MediaDownloadButtonText} />;
};
