export const FONT_FAMILY = {
  regular: 'Poppins-Regular',
  medium: 'Poppins-Medium',
  semiBold: 'Poppins-SemiBold',
};

export const FONT_SIZE = {
  pageHeading: 20,
  heading: 18,
  subheading: 12,
  body: 14,
  button: 14,
  caption: 12,
  captionSmall: 10,
};
