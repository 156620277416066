import { Practitioner, Validations } from '@quromedical/models';
import { CrudForm, SubmissionResult } from 'components/form';
import { mapCodingToSelectOption } from 'core/display';
import first from 'lodash.first';
import React from 'react';
import { strings } from 'strings';

import { createQualificationFields } from './PractitionerQualificationForm';

const schema = Validations.practitionerCreateSchema;

interface QualificationFormProps {
  data?: Partial<Practitioner.QualificationFields>;
  handleSubmit: (
    data: Practitioner.CreateQualificationFields
  ) => Promise<SubmissionResult<unknown>>;
  handleCancel: () => void;
}

const getCodingFromQualificationCode = (
  qualificationCode?: Practitioner.RequiredPractitionerQualification
) => first(qualificationCode?.code.coding);

export const QualificationForm: React.FC<QualificationFormProps> = ({
  data = {},
  handleCancel,
  handleSubmit,
}) => {
  const { qualificationCode } = data;
  const coding = getCodingFromQualificationCode(qualificationCode);
  const selectedQualificationOption = mapCodingToSelectOption('display | code', coding);

  const initialData: Partial<Practitioner.CreateQualificationFields> = {
    registrationNumber: data.registrationNumber,
    qualificationCode: coding?.code,
  };

  const fields = createQualificationFields(selectedQualificationOption);

  return (
    <CrudForm
      title={strings.FormLabelPractitionerQualificationDetails}
      validationSchema={schema.fields.qualification}
      fields={fields}
      initialValues={initialData}
      onSubmit={handleSubmit}
      onSecondarySubmit={handleCancel}
      showSecondarySubmitButton
      showSubmitButton
    />
  );
};
