import { Col } from 'components/base';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Field } from 'components/types';
import { SkeletonProvider } from 'design-system/base';
import { dateTimeWithoutYearFormatter, logger } from 'helpers';
import { useBoolean } from 'hooks/useBoolean';
import { useFiniteState } from 'hooks/useFiniteState';
import React, { useCallback } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

import { Item } from '../action-list';
import { Dialog } from '../dialog';

export interface AlertCommentFormProps {
  alertTitle: string;
  alertTs: number;
  canAddComment: boolean;
  onSubmit: (comment: AlertFormData) => Promise<void>;
}

export interface AlertFormData {
  quality: string;
  classification: string;
  details: string;
}

const alertOptions = [
  strings.AlertQualityHigh,
  strings.AlertQualityMedium,
  strings.AlertQualityLow,
].map((title) => ({ value: title, display: title }));

interface FormDataWithMeta extends AlertFormData {
  alertTitle?: string;
  alertTime?: string;
}

const createFields = (alertTitle: string, alertTime: string): Field<FormDataWithMeta>[] => [
  {
    subfields: [
      {
        key: 'alertTitle',
        type: 'text-display',
        label: strings.AlertTypeLabel,
        text: alertTitle,
      },
      {
        key: 'alertTime',
        type: 'text-display',
        label: strings.AlertTypeLabel,
        text: alertTime,
      },
    ],
  },
  {
    subfields: [
      {
        key: 'quality',
        label: strings.AlertQualityLabel,
        type: 'combobox-single',
        fetcher: alertOptions,
      },
    ],
  },
  {
    subfields: [
      {
        key: 'classification',
        label: strings.AlertClassificationLabel,
        type: 'text-box',
      },
    ],
  },
  {
    subfields: [
      {
        key: 'details',
        label: strings.AlertDetailsLabel,
        type: 'text-box',
        multiline: true,
      },
    ],
  },
];

const formSchema = yup.object<ObjectType<AlertFormData>>({
  classification: yup.string().required(),
  details: yup.string().required(),
  quality: yup.string().required(),
});

type FormState = 'active' | 'error' | 'loading';

export const AlertCommentForm: React.FC<AlertCommentFormProps> = ({
  onSubmit,
  alertTitle,
  alertTs,
  canAddComment,
}) => {
  const open = useBoolean(false);
  const state = useFiniteState<FormState>('active');

  const alertTimeDisplay = dateTimeWithoutYearFormatter(alertTs);
  const fields = createFields(alertTitle, alertTimeDisplay);

  const handleSubmit = useCallback<SubmissionHandler<AlertFormData>>(
    async (data: AlertFormData) => {
      state.set('loading');
      try {
        await onSubmit(data);
        open.setFalse();
        state.set('active');
        return {};
      } catch (error) {
        logger.error(error);
        state.set('error');
        return { error };
      }
    },
    [onSubmit, open, state]
  );

  const errorMessage = state.is('error') ? strings.GenericErrorMessage : undefined;

  // it should only be possible to click on the item if the user has the add comment permission
  const onItemPress = canAddComment ? open.setTrue : undefined;
  const actionIcon = canAddComment ? 'add-comment' : undefined;

  return (
    <SkeletonProvider loading={state.is('loading')}>
      <Col>
        <Item
          id={`${alertTitle}-${alertTs}`}
          subtitle={alertTimeDisplay}
          title={alertTitle}
          actionIcon={actionIcon}
          onPress={onItemPress}
          statusIcon="error-outline"
          statusIconColor="status-critical"
        />
      </Col>

      <Dialog
        isOpen={open.value}
        onRequestClose={open.setFalse}
        title={strings.AlertCommentFormTitle}
      >
        <CrudForm
          disabled={state.is('loading')}
          hasCard={false}
          fields={fields}
          initialValues={{}}
          validationSchema={formSchema}
          onSubmit={handleSubmit}
          onChange={state.next('active')}
          onSecondarySubmit={open.setFalse}
          onChangeDebounceTime={0}
          showSecondarySubmitButton
          error={errorMessage}
        />
      </Dialog>
    </SkeletonProvider>
  );
};
