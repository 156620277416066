import {
  ClinicalImpressionAssessmentValueCode,
  ClinicalImpressionPlanValueCode,
} from '@quromedical/fhir-common';
import { MeasurementName } from '@quromedical/models';
import { titles } from 'core/vitals';
import { NestedComment } from 'design-system';

import { Options } from './NestedNoteForm';

const dayTimeOptions: NestedComment.Comment[] = [
  {
    id: 'morning',
    type: 'comment',
    display: 'Morning',
  },
  {
    id: 'afternoon',
    type: 'comment',
    display: 'Afternoon',
  },
  {
    id: 'evening',
    type: 'comment',
    display: 'Evening',
  },
];

const locationOptions: NestedComment.Comment[] = [
  {
    id: 'virtual',
    type: 'comment',
    display: 'Virtual',
  },
  {
    id: 'in-person',
    type: 'comment',
    display: 'In-person',
  },
];

export const baseNoteOptions: Options = {
  subjective: {
    id: 'subjective',
    type: 'group',
    display: 'Subjective',
    icon: 'text-s',
    children: [
      {
        id: 'unwell',
        type: 'comment',
        display: 'Unwell',
        color: 'status-warning',
        textRequired: true,
      },
      {
        id: 'ok',
        type: 'comment',
        display: 'No Issues reported',
        color: 'status-success',
      },
      {
        id: 'other',
        type: 'comment',
        display: 'Other',
        textRequired: true,
      },
    ],
  },
  assessment: {
    id: 'assessment',
    display: 'Assessment',
    type: 'group',
    icon: 'text-a',
    children: [
      {
        type: 'comment',
        display: 'Stable',
        id: ClinicalImpressionAssessmentValueCode.Stable,
      },
      {
        type: 'comment',
        display: 'Improving',
        color: 'status-success',
        id: ClinicalImpressionAssessmentValueCode.Improving,
      },
      {
        type: 'group',
        display: 'Deteriorating',
        color: 'status-warning',
        id: ClinicalImpressionAssessmentValueCode.Deteriorating,
        children: [
          {
            id: 'critical',
            type: 'group',
            display: 'Critical',
            color: 'status-critical',
            children: [
              {
                type: 'comment',
                id: 'unresponsive',
                display: 'Unresponsive',
                color: 'status-critical',
              },
              {
                type: 'comment',
                id: 'heart-stopped',
                display: 'Heart stopped',
                color: 'status-critical',
              },
              {
                type: 'comment',
                id: 'not-breathing',
                display: 'Not Breathing',
                color: 'status-critical',
              },
              {
                type: 'comment',
                id: 'other',
                display: 'Other',
                color: 'status-critical',
                textRequired: true,
              },
            ],
          },
          {
            type: 'comment',
            id: 'other',
            display: 'Other',
            color: 'status-warning',
            textRequired: true,
          },
        ],
      },
    ],
  },
  objective: {
    id: 'objective',
    type: 'group',
    display: 'Objective',
    icon: 'text-o',
    children: [
      ...Object.values(MeasurementName).map(
        (key) =>
          ({
            id: key,
            type: 'comment',
            display: titles[key as MeasurementName] || key,
            textRequired: true,
          } as const)
      ),
      {
        id: 'other',
        type: 'comment',
        display: 'Other',
        textRequired: true,
      },
    ],
  },
  plan: {
    id: 'plan',
    type: 'group',
    display: 'Plan',
    icon: 'text-p',
    children: [
      {
        type: 'comment',
        display: 'Continue',
        id: ClinicalImpressionPlanValueCode.Continue,
      },
      {
        type: 'comment',
        display: 'Amend',
        id: ClinicalImpressionPlanValueCode.Amend,
        textRequired: true,
      },
      {
        type: 'comment',
        display: 'Discharge',
        id: ClinicalImpressionPlanValueCode.Discharge,
        textRequired: true,
      },
      {
        type: 'comment',
        display: 'Escalate',
        id: ClinicalImpressionPlanValueCode.Escalate,
        textRequired: true,
      },
    ],
  },
  clinicalOperations: {
    type: 'group',
    display: 'Clinical Operations',
    id: 'clinical-operations',
    icon: 'text-c',
    children: [
      {
        id: 'handover',
        type: 'comment',
        display: 'Handover',
      },
      {
        id: 'ward-round',
        type: 'comment',
        display: 'Ward Round',
      },
      ...locationOptions,
      ...dayTimeOptions,
      {
        id: 'other',
        type: 'comment',
        display: 'Other',
        textRequired: true,
      },
    ],
  },
};
