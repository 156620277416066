import { Person } from '@quromedical/models';
import { Util } from '@quromedical/utils';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Fetcher } from 'design-system';
import { FormikErrors } from 'formik';
import { useAddressAutoFill } from 'hooks/useAddressAutoFill';
import compact from 'lodash.compact';
import React, { useCallback, useEffect, useState } from 'react';
import {
  initialNextOfKin,
  JointFields,
  nextOfKinFormSchema,
  resolveFormNextOfKinFields,
} from 'screens/patient';
import { strings } from 'strings';

type ChangeHandler = (
  data: Partial<JointFields>,
  errors: FormikErrors<Partial<JointFields>>
) => void;

interface NextOfKinFormProps {
  showErrors?: boolean;
  initial?: Partial<JointFields>;
  onChange?: ChangeHandler;
  onSubmit?: Util.SyncOrAsyncVoidFn<Partial<JointFields>>;
  onCancel?: () => void;
  addressFetcher?: Fetcher<string>;
  getAddressPartial?: (
    placeId: string
  ) => Promise<Partial<Person.ContactWithAddressEmergencyAccess>>;
}

export const NextOfKinForm: React.FC<NextOfKinFormProps> = ({
  onChange,
  showErrors,
  onCancel,
  onSubmit,
  addressFetcher,
  getAddressPartial,
}) => {
  const [formState, setFormState] = useState(initialNextOfKin);

  const [initialState, setInitialState] = useState(initialNextOfKin);

  const { onContactChange, placeId } = useAddressAutoFill(setFormState, getAddressPartial);
  useEffect(() => {
    if (placeId !== initialState.addressPlaceId) {
      setInitialState({ ...formState, addressPlaceId: placeId });
    }
  }, [placeId, initialState, formState]);

  const handleChange = useCallback<ChangeHandler>(
    (data, errors) => {
      const resolvedData = {
        ...data,
        addressLines: compact(data.addressLines),
        telecomPhoneNumbers: compact(data.telecomPhoneNumbers),
      };

      onContactChange(resolvedData);
      if (onChange) {
        onChange(resolvedData, errors);
      }
    },
    [onChange, onContactChange]
  );

  const handleSubmit = useCallback<SubmissionHandler<JointFields>>(
    async (data) => {
      try {
        await onSubmit?.(data);
        return {};
      } catch (error) {
        return { error };
      }
    },
    [onSubmit]
  );

  const nextOfKinFields = resolveFormNextOfKinFields(formState, addressFetcher);
  return (
    <CrudForm
      key={initialState.addressPlaceId}
      title={strings.FormLabelNextOfKin}
      fields={nextOfKinFields}
      showSubmitButton={!!onSubmit}
      onSubmit={handleSubmit}
      showSecondarySubmitButton={!!onCancel}
      onSecondarySubmit={onCancel}
      initialValues={initialState}
      includeIcons={true}
      validationSchema={nextOfKinFormSchema}
      onChange={handleChange}
      onChangeDebounceTime={0}
      showErrors={showErrors}
      validateOnMount
    />
  );
};
