import { Alert, Snackbar } from 'design-system';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';

interface DashboardLoadingProps {
  error: boolean;
}

export const DashboardLoading: React.FC<DashboardLoadingProps> = ({ error }) => {
  const [showError, setError] = useState(true);

  const hideError = useCallback(() => {
    setError(false);
  }, []);

  if (error) {
    const open = showError && error;
    return (
      <Snackbar isOpen={open} onClose={hideError}>
        <Alert
          icon="error-outline"
          backgroundColor="status-critical"
          textColor="white"
          title={strings.AlertError}
          body={strings.AlertPatientLoadingError}
          actionIcon="close"
          onAction={hideError}
        />
      </Snackbar>
    );
  }

  return null;
};
