import { CreateReference, getCreateReferenceId } from '@quromedical/fhir-common';
import { SelectOption, ValueType } from 'components/types';
import { Fetcher } from 'design-system';
import uniqBy from 'lodash.uniqby';
import { strings } from 'strings';

/**
 * Create a set of select options for each value in a display map.
 * Convenience function since this is a task that leads to frequent duplication.
 *
 * If there are any display values missing they will be defaulted to the key
 */
export const mapDisplaysToOptions = <TEnum extends string>(
  displayMap: Record<TEnum, string>
): SelectOption<undefined, TEnum>[] => {
  const keys = Object.keys(displayMap) as TEnum[];

  return keys.map((key) => ({
    value: key,
    display: displayMap[key] || key,
  }));
};

const uniqOptions = <TValue extends ValueType, TData = undefined>(
  options: SelectOption<TData, TValue>[]
): SelectOption<TData, TValue>[] => uniqBy(options, (option) => option.value);

/**
 * Same as `mapDisplaysToOptions` but allows the addition of another first element.
 * Useful since in the case of filters we sometimes want to include an `all` option
 */
export const mapDisplaysToOptionsWithStart = <TEnum extends string, TFirstOption extends ValueType>(
  firstOption: SelectOption<any, TFirstOption>,
  displayMap: Record<TEnum, string>
): SelectOption<undefined, TEnum | TFirstOption>[] => {
  const generated = mapDisplaysToOptions(displayMap);

  return uniqOptions<TEnum | TFirstOption>([firstOption, ...generated]);
};

/**
 * Create a fetcher which appends some static options to an existing fetcher.
 * Useful when trying to pre-populate values in a fetcher and ensure that they are retained or to
 * retain a user selection if a change in the fetcher will make it go away
 */
export const createMixedFetcher = <TOptionValue extends ValueType, TData = undefined>(
  staticOptions: SelectOption<TData, TOptionValue>[],
  fetcher: Fetcher<TOptionValue, TData>
): Fetcher<TOptionValue, TData> => {
  if (typeof fetcher !== 'function') {
    return uniqOptions([...staticOptions, ...fetcher]);
  }

  return async (query?: string) => {
    const result = await fetcher(query);

    return uniqOptions([...staticOptions, ...result]);
  };
};

/**
 * Convert a FHIR reference to a SelectOption. Will show a fallback if the reference
 * does not have a display
 */
export const mapReferenceToSelectOption = (
  ref?: CreateReference
): SelectOption<undefined, string> | undefined => {
  if (!ref) {
    return undefined;
  }

  return {
    display: ref.display || strings.DisplayNotFound,
    value: getCreateReferenceId(ref),
  };
};
