import { User } from '@quromedical/models';
import { logger } from 'helpers';
import compact from 'lodash.compact';

import { BaseApi } from './BaseApi';

enum UserPreferenceEndpoint {
  Base = '',
  Preference = 'preference',
}

export class UserPreferenceApi {
  private readonly userBasePath = 'api/user';
  private readonly aggregateBasePath = 'api/aggregate/me';

  private readonly client: BaseApi;

  constructor() {
    this.client = new BaseApi();
  }

  private createPath(endpoint: UserPreferenceEndpoint) {
    return compact([this.aggregateBasePath, endpoint]).join('/');
  }

  public registerPushToken = async (pushTokenId: string): Promise<void> => {
    const path = `${this.userBasePath}/notification/add-pushtoken`;

    try {
      await this.client.put(path, {
        pushTokenId,
      });
    } catch (err) {
      logger.error(err);
    }
  };

  public unregisterPushToken = async (pushTokenId: string): Promise<void> => {
    const path = `${this.userBasePath}/notification/remove-pushtoken`;

    try {
      await this.client.put(path, {
        pushTokenId,
      });
    } catch (err) {
      logger.error(err);
    }
  };

  public getMe = async (): Promise<User.MeResponse> => {
    const path = this.createPath(UserPreferenceEndpoint.Base);

    const result = await this.client.get<User.MeResponse>(path);

    return result.data;
  };

  public updatePreference = async (
    body: User.UpdatePreferenceRequest
  ): Promise<User.UpdatePreferenceResponse> => {
    const path = this.createPath(UserPreferenceEndpoint.Preference);

    const result = await this.client.put<User.UpdatePreferenceRequest>(path, body);

    return result.data;
  };
}
