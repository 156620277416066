import { EncounterService, Period } from '@quromedical/fhir-common';
import { Vitals, MeasurementName, Websocket, Admission } from '@quromedical/models';
import { OxygenSaturationScale } from '@quromedical/news';
import { useNavigation } from '@react-navigation/native';
import { getRelayStatusIssues, useRelayStatus } from 'core/device';
import {
  colors,
  titles,
  getECGConfig,
  getMeasureProps,
  getNoteDisplay,
  getPatchBatteryProps,
  getIssueProps,
  getServiceProps,
  getNEWS2StatusBarProps,
  dashboardSecondaryMeasureNames,
  dashboardPrimaryMeasureNames,
  getPrimaryMeasurePropMapper,
  getEcgTags,
  icons,
} from 'core/vitals';
import { VitalSummaryCard } from 'design-system/components';
import compact from 'lodash.compact';
import maxBy from 'lodash.maxby';
import React, { useCallback } from 'react';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { strings } from 'strings';

export interface DashboardCardProps {
  admission?: Admission.GetResponse;
  liveData?: Vitals.LiveDataMeasures;
  title?: string;
  serviceType?: EncounterService;
  servicePeriod?: Period;
  patientId?: string;
  oxygenSaturationScale?: OxygenSaturationScale;
  canViewIssues?: boolean;
  canViewAlerts?: boolean;
  alerts?: Websocket.Alert[];
}

const getSecondaryMeasurePropMapper =
  (latest: Partial<Record<Vitals.MeasurementName, Vitals.LatestResult>>) =>
  (name: Vitals.MeasurementName) => {
    const props = getMeasureProps(latest, name);
    return {
      ...props,
      // remove colors from secondary measures to keep things a bit cleaner
      color: undefined,
    };
  };

export const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  patientId,
  canViewIssues = false,
  serviceType,
  oxygenSaturationScale = OxygenSaturationScale.Scale1,
  // default liveData so that we can still display the card even without data for a patient
  liveData = {
    ecg: [],
    latest: {},
  },
  alerts = [],
  canViewAlerts = false,
  servicePeriod,
}) => {
  const relayStatus = useRelayStatus(patientId);
  const relayIssues = getRelayStatusIssues(relayStatus.data);

  const navigation = useNavigation();

  const { latest, ecg } = liveData;

  const ecgData = ecg.map((reading) => ({ x: reading.ts, y: reading.value }));

  const graphSummary = getMeasureProps(latest, MeasurementName.HeartRate, true);

  const primaryMeasures = dashboardPrimaryMeasureNames.map(
    getPrimaryMeasurePropMapper(latest, oxygenSaturationScale)
  );

  const secondaryMeasures = dashboardSecondaryMeasureNames.map(
    getSecondaryMeasurePropMapper(latest)
  );

  const patchProps = getPatchBatteryProps(latest);
  const issueProps = getIssueProps(canViewIssues, relayIssues?.relay, relayIssues?.mbs);
  const serviceProps = getServiceProps(serviceType, servicePeriod);

  const metrics = compact([patchProps, ...issueProps, serviceProps]);

  const graphProps = getECGConfig({
    aspectRatio: 6 / 1,
    baseData: ecg,
  });

  const latestEcg = maxBy(ecg, (d) => d.ts);
  const ecgNote = getNoteDisplay(latestEcg?.ts);

  const onCardPress = useCallback(() => {
    if (!patientId) {
      return;
    }
    navigation.navigate('Patient', {
      screen: 'View',
      params: {
        id: patientId,
        screen: 'Profile',
      },
    });
  }, [navigation, patientId]);

  const statusBarProps = getNEWS2StatusBarProps(latest, oxygenSaturationScale);

  const ecgTags = canViewAlerts ? getEcgTags(alerts) : undefined;

  return (
    <TouchableWithoutFeedback onPress={onCardPress}>
      <VitalSummaryCard
        header={{
          title,
          icon: 'person',
          metrics,
        }}
        statusBar={{
          ...statusBarProps,
          title: strings.EWSTitle,
        }}
        graph={{
          title: titles[MeasurementName.ECG],
          icon: icons[MeasurementName.ECG],
          color: colors[MeasurementName.ECG],
          note: ecgNote,
          aspectRatio: graphProps.aspectRatio,
          data: ecgData,
          xDomain: graphProps.xDomain,
          yDomain: graphProps.yDomain,
          xSubTicks: graphProps.xSubTicks,
          ySubTicks: graphProps.ySubTicks,
          xTicks: graphProps.xTicks,
          yTicks: graphProps.yTicks,
          summary: {
            ...graphSummary,
            tags: ecgTags,
          },
        }}
        primaryMeasures={primaryMeasures}
        secondaryMeasures={secondaryMeasures}
      />
    </TouchableWithoutFeedback>
  );
};
