import React, { useCallback } from 'react';
import { Circle as Base } from 'react-native-svg';

import { usePlotDataContext } from './PlotDataProvider';
import { Datum, ExternalComponentProps } from './types';

interface PointProps {
  x: number;
  y: number;
  radius?: number;
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  onPress?: (point: Datum) => void;
}

type ExternalProps = ExternalComponentProps & PointProps;

export const Point: React.FC<ExternalProps> = (props) => {
  const {
    xScale,
    yScale,
    fill,
    stroke,
    strokeWidth,
    radius = 1,
    x,
    y,
    onPress,
  } = usePlotDataContext(props);

  const handlePress = useCallback(() => {
    onPress?.({ x, y });
  }, [onPress, x, y]);

  const cx = xScale(x);
  const cy = yScale(y);

  return (
    <Base
      cx={cx}
      cy={cy}
      r={radius}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      onPress={handlePress}
    />
  );
};
