import { PatientStatusCode, getNameText } from '@quromedical/fhir-common';
import { Person } from '@quromedical/models';
import { getAgeInYears } from 'helpers';
import { strings } from 'strings';

export const patientStatusDisplay: Record<PatientStatusCode, string> = {
  [PatientStatusCode.Admitted]: strings.AdmissionStatusAdmitted,
  [PatientStatusCode.Discharged]: strings.AdmissionStatusDischarged,
  [PatientStatusCode.Onboarded]: strings.AdmissionStatusOnboarded,
};

const genderRecordMapping: Record<Person.Gender, string> = {
  female: 'F',
  male: 'M',
  other: 'O',
  unknown: 'U',
};

const genderMapping = (gender: Person.Gender = 'unknown'): string =>
  (gender && genderRecordMapping[gender]) || 'U';

export const getNameAgeAndGender = (general: Partial<Person.General> | undefined): string => {
  if (!general) {
    return '';
  }

  const gender = genderMapping(general.gender);
  const age = getAgeInYears(general.birthDate);
  const name = getNameText(general.givenName, general.familyName);

  return `${name} (${gender} / ${age})`;
};
