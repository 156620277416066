import { Pathology } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import { compact } from 'lodash';

export enum PathologyEndpoint {
  Ampath = 'ampath',
}

interface PathParams {
  patientId: string;
  endpoint?: PathologyEndpoint;
}
export class PathologyApi {
  private readonly client: BaseApi;
  private readonly patientRoute = 'api/aggregate/patient';
  private readonly pathologyRoute = 'pathology';

  constructor() {
    this.client = new BaseApi();
  }

  private createPath({ patientId, endpoint }: PathParams) {
    const base = [this.patientRoute, patientId, this.pathologyRoute].join('/');

    return compact([base, endpoint]).join('/');
  }

  public async createAmpathRequest(
    patientId: string,
    req: Pathology.CreatePathologyOrderRequest
  ): Promise<Pathology.CreatePathologyOrderResponse> {
    const path = this.createPath({ patientId, endpoint: PathologyEndpoint.Ampath });
    const result = await this.client.post<Pathology.CreatePathologyOrderResponse>(path, req);
    return result.data;
  }
}
