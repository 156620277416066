import { interpolateRgb } from 'd3-interpolate';
import { ScaleLinear, scaleLinear } from 'd3-scale';

import { StopProps } from './LinearGradient';

export const resolveColor = (stroke?: string, gradientId?: string): string | undefined => {
  if (gradientId) {
    return `url(#${gradientId})`;
  }

  return stroke;
};

type GradientScale = (scaledValue: number) => string;

export const createGradientScale = (
  direction: 'vertical' | 'horizontal',
  yScale: ScaleLinear<number, number>,
  xScale: ScaleLinear<number, number>,
  stops: StopProps[]
): GradientScale => {
  const gradientColorScale = direction === 'vertical' ? yScale : xScale;
  const gradientDomain = stops.map((stop) => gradientColorScale(stop.offsetVal));

  const gradientRange = stops.map((stop) => stop.color);

  const gradientScale = scaleLinear<string, string>()
    .domain(gradientDomain)
    .interpolate(interpolateRgb)
    .range(gradientRange);

  return gradientScale;
};
