import { Grid } from 'design-system/layout';
import { useResponsive } from 'hooks/useResponsive';
import React from 'react';

import { CallWrapper } from '../call-wrapper';

interface InCallProps {
  count: number;
  title?: string;
  minimize: () => void;
}

export const InCall: React.FC<InCallProps> = ({ children, count = 1, title = '', minimize }) => {
  const baseCols = useResponsive({ sm: 1, md: 2, lg: 3, xl: 4 }, 1);
  const cols = baseCols > count ? count : baseCols;
  return (
    <CallWrapper onButtonPress={minimize} title={title} buttonIcon="collapse">
      <Grid gap="m" sm={cols}>
        {children}
      </Grid>
    </CallWrapper>
  );
};
