import { BaseTimeSeriesRecord, MeasurementName } from '@quromedical/models';
import { ascending } from 'd3-array';
import { VitalsApi } from 'integration/aggregate';
import useSWRImmutable from 'swr/immutable';

const api = new VitalsApi();

type Timestamp = number;

interface SingleMeasureDataResult {
  data?: BaseTimeSeriesRecord[];
  isLoading: boolean;
}

/**
 * Use measures for a given patient and time-frame using a hard cache to prevent refetching
 *
 * @param patientId
 * @param measurementName
 * @param start
 * @param durationMs
 * @returns the measure data
 */
export const useSingleMeasureData = (
  patientId: string,
  measurementName: MeasurementName,
  referenceTime: Timestamp,
  durationMs = 5000,
  shouldFetch = true
): SingleMeasureDataResult => {
  const start = referenceTime - durationMs;
  const end = referenceTime + durationMs;

  const currentFetcher = () => {
    if (!shouldFetch) {
      return undefined;
    }

    return api.getVitalsByMeasurement(patientId, measurementName, {
      start,
      end,
    });
  };

  const suffix = shouldFetch ? 'fetched' : 'not-fetched';

  const key = `${patientId}/vitals/secondary/${measurementName}/${start}/${end}/${suffix}`;

  const current = useSWRImmutable(key, currentFetcher);

  return {
    data: current.data?.records.sort((a, b) => ascending(a.ts, b.ts)),
    isLoading: current.isValidating,
  };
};

interface CachedMeasureResult {
  data?: BaseTimeSeriesRecord[];
  next: Timestamp;
  prev: Timestamp;
  current: Timestamp;
  isLoading?: boolean;
}

/**
 * Will get and cache the measure data for the provided time-frame as well as the time before and
 * after the selected measurement
 *
 * Calling this using the specified time-frames returned will ensure that the data is retrieved from
 * the cache and not re-fetched
 *
 * @param patientId
 * @param measurementName
 * @param current
 * @param durationMs
 * @param offset set an offset less than the duration to allow for an overlap when stepping
 * @returns data for the provided timeframe along with the `next` and `previous` timeframe
 */
export const useCachedMeasureData = (
  patientId: string,
  measurementName: MeasurementName,
  current: Timestamp,
  durationMs: number,
  offset = durationMs,
  shouldFetch = true,
  shouldPage = false
): CachedMeasureResult => {
  const { data, isLoading } = useSingleMeasureData(
    patientId,
    measurementName,
    current,
    durationMs,
    shouldFetch
  );

  // the before and after measurement should be fetched and cached, but we don't need to use it
  const prev = current - offset;
  useSingleMeasureData(patientId, measurementName, prev, durationMs, shouldPage);

  const next = current + offset;
  useSingleMeasureData(patientId, measurementName, next, durationMs, shouldPage);

  return {
    data,
    isLoading,
    current,
    next,
    prev,
  };
};
