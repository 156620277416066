import { Media } from '@quromedical/models';
import { getDataFromResponse, mediaColumns, MediaForm } from 'core/media';
import { PatientHeaderSection } from 'core/patient';
import { PaginatedTable } from 'core/table';
import { Section, Skeleton, SkeletonProvider } from 'design-system';
import { logger } from 'helpers';
import { useFiniteState } from 'hooks/useFiniteState';
import { useResponsive } from 'hooks/useResponsive';
import { useRevalidation } from 'hooks/useRevalidation';
import { MediaApi, MediaEndpoint } from 'integration/aggregate';
import { PatientDrawerScreenProps } from 'navigation';
import { usePatientId } from 'providers/PatientIdContext';
import React, { useCallback } from 'react';
import { ScrollView } from 'react-native';
import useSWR from 'swr';

const api = new MediaApi();

type FormStep = 'form' | 'editing' | 'submitting' | 'error';

export const MediaListScreen: React.FC<PatientDrawerScreenProps<'CreateMedia'>> = () => {
  const showHeader = useResponsive({ md: true }, false);
  const patientId = usePatientId();
  const columns = mediaColumns(patientId);

  const state = useFiniteState<FormStep>('form');

  const fetcher = (pageToken?: string) =>
    api.getMedia(patientId, {
      pageToken,
    });

  const swrKey = api.getMediaSWRKey(patientId, MediaEndpoint.Base);
  const revalidate = useRevalidation(patientId);
  const { isValidating } = useSWR(swrKey, fetcher);

  const onSubmit = useCallback(
    async (formData: Media.CreateRequest) => {
      state.set('submitting');
      try {
        await api.createMedia(patientId, formData);
        await revalidate();
        state.set('editing');
      } catch (err) {
        logger.error(err);
        state.set('error');
      }
    },
    [state, patientId, revalidate]
  );

  return (
    <ScrollView>
      <PatientHeaderSection id={patientId} />

      <Section>
        <SkeletonProvider loading={state.is('submitting')}>
          <Skeleton>
            <MediaForm patientId={patientId} onSubmit={onSubmit} />
          </Skeleton>
        </SkeletonProvider>
      </Section>

      <Section hasBottomMargin>
        <SkeletonProvider loading={isValidating}>
          <PaginatedTable
            cacheKey={`Media${patientId}`}
            fetcher={fetcher}
            columns={columns}
            getDataFromResponse={getDataFromResponse}
            showHeader={showHeader}
          />
        </SkeletonProvider>
      </Section>
    </ScrollView>
  );
};
