import styled from '@emotion/native';
import {
  AuthEvent,
  BaseAuthUser,
  PasswordlessAuthClient,
  PromiseOfOptional,
} from '@quromedical/auth';
import { GroupSecurity } from '@quromedical/fhir-common';
import { User } from '@quromedical/models';
import { registerForPushNotifications } from 'config/notifications';
import { Authentication } from 'core/auth';
import { Spinner, Text } from 'design-system';
import { ExpoPushToken } from 'expo-notifications';
import { logger } from 'helpers';
import { analytics } from 'helpers/analytics';
import { Sentry } from 'helpers/sentry';
import { UserPreferenceApi } from 'integration';
import { Session } from 'integration/AuthApi';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

const userApi = new UserPreferenceApi();

interface UserProviderProps {
  authClient: AuthClient;
}

export interface UserSession {
  hasAny: (groups: GroupSecurity[]) => boolean;
  getProfile: () => User.MeResponse | undefined;
  getPermissions: () => GroupSecurity[];
  getUser: () => BaseAuthUser | undefined;
  getSession: () => PromiseOfOptional<Session>;
  getAuthToken: () => PromiseOfOptional<string>;
  logout: () => Promise<void>;
  authClient?: AuthClient;
}

export const UserContext = createContext<UserSession>({
  hasAny: () => false,
  getUser: () => undefined,
  getProfile: () => undefined,
  getPermissions: () => [],
  logout: () => Promise.resolve(undefined),
  getSession: () => Promise.resolve(undefined),
  getAuthToken: () => Promise.resolve(undefined),
  authClient: undefined,
});

const LoadingWrapper = styled.View({
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
});

const Background = styled.View(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.color['base-grey'],
}));

export const UserProvider: React.FC<UserProviderProps> = ({ children, authClient }) => {
  const [me, setMe] = useState<User.MeResponse>();
  const [user, setUser] = useState<BaseAuthUser>();
  const [isLoading, setLoading] = useState(true);
  const [pushToken, setPushToken] = useState<ExpoPushToken>();
  const [isLoggingOut, setLoggingOut] = useState(false);
  const [authSession, setAuthSession] = useState<Session>();

  const permissions = useMemo(() => me?.permissions || [], [me]);

  const getPermissions = useCallback(() => permissions, [permissions]);
  const getProfile = useCallback(() => me, [me]);

  const getUser = useCallback(() => user, [user]);

  const getGroupsAsync = useCallback(async () => {
    try {
      const userGroups = await userApi.getMe();
      setMe(userGroups);
    } catch (error) {
      logger.error(error);
    }
  }, []);

  const getSession = useCallback(async () => {
    setLoading(true);
    const currentSession = await authClient.getSession();
    if (currentSession) {
      setAuthSession(currentSession);
      await getGroupsAsync();
      const currentUser = currentSession.getUser();
      const registeredPushToken = await registerForPushNotifications();
      setPushToken(registeredPushToken);
      setUser(currentUser);

      if (registeredPushToken) {
        userApi.registerPushToken(registeredPushToken.data).catch(logger.error);
      }
    }
    setLoading(false);

    return currentSession;
  }, [authClient, getGroupsAsync]);

  const hasAny = useCallback(
    (query: GroupSecurity[]) => {
      const foundGroup = query.find((g) => permissions.includes(g));
      return !!foundGroup;
    },
    [permissions]
  );

  const logout = useCallback(async () => {
    try {
      setLoggingOut(true);
      await authClient.signOut();
    } catch (error) {
      logger.error(error);
    } finally {
      Sentry.configureScope((scope) => scope.setUser(null));
      analytics.setUser(undefined);

      if (pushToken) {
        userApi.unregisterPushToken(pushToken.data).catch(logger.error);
      }
      setAuthSession(undefined);
      setLoggingOut(false);
    }
  }, [pushToken, authClient]);

  const getAuthToken = useCallback(async () => {
    if (!authSession) {
      return undefined;
    }

    return authSession.getIdToken();
  }, [authSession]);

  const value = useMemo<UserSession>(
    () => ({
      getProfile,
      getPermissions,
      hasAny,
      logout,
      getSession,
      getUser,
      getAuthToken,
      authClient,
    }),
    [getPermissions, getProfile, hasAny, logout, getSession, getUser, authClient, getAuthToken]
  );

  useEffect(() => {
    const subscription = authClient.addEventListener(AuthEvent.SignOut, () =>
      setAuthSession(undefined)
    );
    return () => {
      subscription.remove();
    };
  }, [authClient]);

  useEffect(() => {
    getSession().catch(logger.error);
  }, [getSession]);

  useEffect(() => {
    if (!user) {
      return;
    }
    Sentry.configureScope((scope) => scope.setUser(user));
    analytics.setUser(user.email);
  }, [user]);
  const child = useMemo(
    () => (authSession ? children : <Authentication />),
    [authSession, children]
  );

  if (isLoading) {
    return (
      <Background>
        <LoadingWrapper>
          <Spinner />
          <Text>Logging in</Text>
        </LoadingWrapper>
      </Background>
    );
  }

  if (isLoggingOut) {
    return (
      <Background>
        <LoadingWrapper>
          <Spinner />
          <Text>Logging out</Text>
        </LoadingWrapper>
      </Background>
    );
  }

  return (
    <UserContext.Provider value={value}>
      <Background>{child}</Background>
    </UserContext.Provider>
  );
};

type AuthClient = PasswordlessAuthClient<BaseAuthUser>;
