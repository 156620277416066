import { logger } from 'helpers';
import { useCallback } from 'react';

import { useUserSession } from './useUserSession';

type LogoutHandler = () => Promise<void>;

/**
 * Abstracts logout logic. Uses the user session and platform specific reloading
 *
 * @returns a `callback` that can be used for logging out a user
 */
export const useLogout = (): LogoutHandler => {
  const session = useUserSession();
  const logout = useCallback(async () => {
    await session.logout().catch(logger.error);
  }, [session]);

  return logout;
};
