import { getReferenceId, GroupSecurity } from '@quromedical/fhir-common';
import { Admission } from '@quromedical/models';
import type { Util } from '@quromedical/utils';
import { useLinkTo } from '@react-navigation/native';
import { admissionServiceDisplay } from 'core/display';
import { strings } from 'strings';

import { Action } from './types';

interface GetPatientActionParams {
  parentId: string;
  patientId: string;
  patientName: string;
  navigate: ReturnType<typeof useLinkTo>;
  /**
   * The default KBar priority is 1
   */
  priority?: number;
}

export const getAdmissionAction = (admission: Admission.GetResponse): Action | undefined => {
  const reference = getReferenceId(admission.patient);

  if (!reference) {
    return undefined;
  }

  return {
    parent: 'patient',
    id: reference,
    permission: GroupSecurity.PatientRead,
    name: admission.patient?.display || '',
    hidden: !admission.patient?.display,
    subtitle: admission.service ? admissionServiceDisplay[admission.service] : undefined,
    iconName: 'person-outline',
  };
};

export const getPatientActions: Util.Fn<GetPatientActionParams, Action[]> = ({
  navigate,
  parentId,
  patientId,
  patientName,
}) => {
  const common = {
    parent: parentId,
    subtitle: patientName,
  };

  return [
    {
      ...common,
      id: `${patientId}-profile`,
      name: strings.KBarActiveAdmissionActionProfileTitle,
      iconName: 'person-outline',
      permission: GroupSecurity.PatientRead,
      shortcut: ['p', 'p'],
      perform: () =>
        navigate({
          screen: 'Patient',
          params: {
            screen: 'View',
            params: {
              screen: 'Profile',
              id: patientId,
            },
          },
        }),
    },
    {
      ...common,
      id: `${patientId}-vitals`,
      name: strings.KBarActiveAdmissionActionVitalsTitle,
      iconName: 'multiline-chart',
      permission: GroupSecurity.ClinicalImpressionRead,
      shortcut: ['p', 'v'],
      perform: () =>
        navigate({
          screen: 'Patient',
          params: {
            screen: 'View',
            params: {
              screen: 'Vitals',
              id: patientId,
            },
          },
        }),
    },
    {
      ...common,
      id: `${patientId}-clinical`,
      name: strings.KBarActiveAdmissionActionClinicalTitle,
      iconName: 'pages',
      permission: GroupSecurity.ClinicalImpressionRead,
      shortcut: ['p', 'c'],
      perform: () =>
        navigate({
          screen: 'Patient',
          params: {
            screen: 'View',
            params: {
              screen: 'Clinical',
              id: patientId,
            },
          },
        }),
    },
    {
      ...common,
      id: `${patientId}-notes`,
      name: strings.KBarActiveAdmissionActionNotesTitle,
      iconName: 'notes',
      permission: GroupSecurity.ClinicalImpressionRead,
      shortcut: ['p', 'n'],
      perform: () =>
        navigate({
          screen: 'Patient',
          params: {
            screen: 'View',
            params: {
              screen: 'Notes',
              id: patientId,
            },
          },
        }),
    },
    {
      ...common,
      id: `${patientId}-pathology`,
      name: strings.KBarActiveAdmissionActionPathologyTitle,
      iconName: 'science',
      permission: GroupSecurity.ServiceRequestRead,
      shortcut: ['p', 't'],
      perform: () =>
        navigate({
          screen: 'Patient',
          params: {
            screen: 'View',
            params: {
              screen: 'ServiceRequests',
              id: patientId,
            },
          },
        }),
    },
    {
      ...common,
      id: `${patientId}-media`,
      name: strings.KBarActiveAdmissionActionMediaTitle,
      iconName: 'attach-file',
      permission: GroupSecurity.MediaRead,
      shortcut: ['p', 'm'],
      perform: () =>
        navigate({
          screen: 'Patient',
          params: {
            screen: 'View',
            params: {
              screen: 'Medias',
              id: patientId,
            },
          },
        }),
    },
    {
      ...common,
      id: `${patientId}-video-consult`,
      name: strings.KBarActiveAdmissionActionVideoConsultTitle,
      iconName: 'video-call',
      permission: GroupSecurity.VideoConsultCreate,
      shortcut: ['p', 'v', 'c'],
      perform: () =>
        navigate({
          screen: 'Patient',
          params: {
            screen: 'View',
            params: {
              screen: 'VideoConsult',
              id: patientId,
            },
          },
        }),
    },
  ];
};
