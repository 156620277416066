import { Person, Validations } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { minimalPersonGeneralFields, personGeneralFields } from 'core/forms';
import { FormikErrors } from 'formik';
import React, { useCallback } from 'react';
import { strings } from 'strings';

interface PersonGeneralFormCardProps {
  onValidChange: (data?: Person.General) => void;
  showErrors: boolean;
  minimalData?: boolean;
}

export const PersonGeneralFormCard: React.FC<PersonGeneralFormCardProps> = ({
  onValidChange,
  showErrors,
  minimalData = false,
}) => {
  const onChange = useCallback(
    (data: Partial<Person.General>, _: FormikErrors<Person.General>, hasErrors: boolean) => {
      if (hasErrors) {
        onValidChange(undefined);
      } else {
        const result = data as Person.General; // casting since data is validated

        onValidChange(result);
      }
    },
    [onValidChange]
  );

  const fields = minimalData ? minimalPersonGeneralFields : personGeneralFields;
  const schema = minimalData
    ? Validations.personMinimalGeneralFieldSchema
    : Validations.personGeneralSchema;

  return (
    <CrudForm
      title={strings.FormLabelPersonDetails}
      fields={fields}
      showSubmitButton={false}
      initialValues={{}}
      validationSchema={schema}
      onChange={onChange}
      showErrors={showErrors}
      validateOnMount
      hasCard={false}
    />
  );
};
