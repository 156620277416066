import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { CreateDeviceForm, DeviceListScreen, DeviceScreen } from 'screens/device';

import { DeviceStackParamList } from './types';

const Stack = createNativeStackNavigator<DeviceStackParamList>();

export const DeviceNavigator: React.FC = () => (
  <Stack.Navigator initialRouteName="List">
    <Stack.Screen
      name="List"
      component={DeviceListScreen}
      options={{
        title: 'Devices',
        headerShown: false,
      }}
    />
    <Stack.Screen name="View" component={DeviceScreen} options={{ title: 'Device' }} />
    <Stack.Screen name="Create" component={CreateDeviceForm} options={{ title: 'Create Device' }} />
  </Stack.Navigator>
);
