import { GroupSecurity } from '@quromedical/fhir-common';
import { Person } from '@quromedical/models';
import { Section, Skeleton, SkeletonProvider } from 'design-system';
import { onMapsPress } from 'helpers/platform-links';
import { useUserSession } from 'hooks';
import { useRevalidation } from 'hooks/useRevalidation';
import { getAddressPartialFetcher, googlePlaceFetcher } from 'integration';
import { PatientApi, PatientEndpoint } from 'integration/aggregate';
import React, { useCallback } from 'react';
import { CareTeamCard } from 'screens/patient';
import { PersonContactForm, PersonGeneralForm } from 'screens/person';
import useSWR from 'swr';

interface PatientProfileTabProps {
  patientId: string;
}

const api = new PatientApi();

export const PatientProfileTab: React.FC<PatientProfileTabProps> = ({ patientId }) => {
  const session = useUserSession();

  const fetcher = () => api.getPatient(patientId);
  const swrKey = api.getPatientSWRKey(patientId, PatientEndpoint.Base);
  const { data, isValidating } = useSWR(swrKey, fetcher);

  const revalidate = useRevalidation(patientId);

  const canEdit = session.hasAny([GroupSecurity.PatientUpdate]);

  // if we have a medical aid then the general details are non-editable
  const canEditGeneral = canEdit && !data?.coverage;

  const onGeneralSubmit = useCallback(
    async (formData: Person.General) => {
      await api.updateGeneralPatient(patientId, formData);
    },
    [patientId]
  );

  const onContactSubmit = useCallback(
    async (results: Person.Contact) => {
      await api.updateContactPatient(patientId, results);
    },
    [patientId]
  );

  const isButtonDisabled = !data?.contact?.addressCoordinates;

  const onMapsPressed = useCallback(() => {
    onMapsPress(data?.contact);
  }, [data?.contact]);

  return (
    <SkeletonProvider loading={isValidating}>
      <Section>
        <Skeleton>
          <PersonGeneralForm
            canEdit={canEditGeneral}
            revalidate={revalidate}
            data={data?.general}
            onSubmit={onGeneralSubmit}
          />
        </Skeleton>
      </Section>

      <Section>
        <Skeleton>
          <PersonContactForm
            canEdit={canEdit}
            data={data?.contact}
            onSubmit={onContactSubmit}
            revalidate={revalidate}
            hasEmergencyAccess
            addressFetcher={googlePlaceFetcher}
            getAddressPartial={getAddressPartialFetcher}
            onMapsPress={onMapsPressed}
            isButtonDisabled={isButtonDisabled}
          />
        </Skeleton>
      </Section>

      <Section hasBottomMargin>
        <Skeleton>
          <CareTeamCard
            revalidate={revalidate}
            canEdit={canEdit}
            patientId={patientId}
            generalPractitioner={data?.generalPractitioner}
          />
        </Skeleton>
      </Section>
    </SkeletonProvider>
  );
};
