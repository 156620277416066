import { Person, Validations } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { ContactWithOutCoordinates, resolveFormContactFields } from 'core/forms';
import { Fetcher } from 'design-system';
import { FormikErrors } from 'formik';
import { useAddressAutoFill } from 'hooks/useAddressAutoFill';
import compact from 'lodash.compact';
import React, { useCallback, useEffect, useState } from 'react';
import { initialContact } from 'screens/person';
import { strings } from 'strings';

type OnChange = (
  data: Partial<Person.Contact>,
  errors: FormikErrors<Partial<ContactWithOutCoordinates>>
) => void;

interface ContactFormProps {
  onChange: OnChange;
  showErrors: boolean;
  hasEmergencyAccess?: boolean;
  addressFetcher?: Fetcher<string>;
  getAddressPartial?: (placeId: string) => Promise<Partial<ContactWithOutCoordinates>>;
}

export const ContactForm: React.FC<ContactFormProps> = ({
  onChange,
  showErrors,
  addressFetcher,
  getAddressPartial,
  hasEmergencyAccess,
}) => {
  const [formState, setFormState] = useState(initialContact);

  const [initialState, setInitialState] = useState(initialContact);

  const { onContactChange, placeId } = useAddressAutoFill(setFormState, getAddressPartial);

  useEffect(() => {
    if (placeId !== initialState.addressPlaceId) {
      setInitialState({ ...formState, addressPlaceId: placeId });
    }
  }, [placeId, initialState, formState]);

  const handleChange = useCallback<OnChange>(
    (data, errors) => {
      const resolvedData = {
        ...data,
        addressLines: compact(data.addressLines),
        telecomPhoneNumbers: compact(data.telecomPhoneNumbers),
      };

      onContactChange(resolvedData);

      onChange(resolvedData, errors);
    },
    [onChange, onContactChange]
  );

  const contactFields = resolveFormContactFields(formState, hasEmergencyAccess, addressFetcher);

  return (
    <CrudForm<ContactWithOutCoordinates>
      key={initialState.addressPlaceId}
      title={strings.FormLabelPatientContactDetails}
      fields={contactFields}
      showSubmitButton={false}
      initialValues={initialState}
      includeIcons={true}
      validationSchema={Validations.personContactSchema}
      onChange={handleChange}
      showErrors={showErrors}
      onChangeDebounceTime={0}
      validateOnMount
    />
  );
};
