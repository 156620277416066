export const getInitials = (name?: string): string => {
  if (!name) {
    return '';
  }

  const split = name.split(' ');

  const first = split[0][0] || '';

  if (split.length === 1) {
    return first;
  }

  const last = split[split.length - 1][0] || '';

  return first + last;
};
