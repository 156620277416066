import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Col, Row } from 'components/base';
import { Icon, IconName, Skeleton, Text, Visible } from 'design-system/base';
import { PADDING, MARGIN, BORDER_WIDTH, BASE_SIZE } from 'design-system/theme';
import { dateTimeWithoutYearFormatter, noOp } from 'helpers';
import React, { useCallback } from 'react';
import { Pressable } from 'react-native';

interface ItemProps {
  id: string;
  title: string;
  subtitle: string;
  time?: number | Date;
  actionIcon?: IconName;
  statusIcon?: IconName;
  statusIconColor?: ColorName;
  small?: boolean;
  onPress?: (id: string) => void;
}

const Wrapper = styled(Row)({
  marginTop: MARGIN['2xs'],
  flex: 1,
});

interface ContentWrapperProps {
  small?: boolean;
}

const ContentWrapper = styled(Row)<ContentWrapperProps>(({ theme, small }) => ({
  backgroundColor: theme.color['base-grey'],
  paddingRight: PADDING.xs,
  alignItems: 'center',
  height: small ? BASE_SIZE[32] : BASE_SIZE[48],
  flex: 1,
}));

const SubtitleWrapper = styled(Col)(({ theme }) => ({
  borderStyle: 'solid',
  borderRightColor: theme.color.divider,
  borderRightWidth: BORDER_WIDTH[1],
  alignItems: 'center',
  justifyContent: 'center',
  width: BASE_SIZE[96],
  height: BASE_SIZE[48],
}));

const TitleWrapper = styled.View({
  marginLeft: MARGIN.s,
  flex: 1,
});

const ActionWrapper = styled.View({
  marginLeft: MARGIN.s,
});

export const Item: React.FC<ItemProps> = ({
  id,
  title,
  subtitle,
  time,
  actionIcon = 'chevron-right',
  statusIcon,
  statusIconColor = 'text-default',
  onPress = noOp,
  small = false,
}) => {
  const handlePress = useCallback(() => onPress(id), [onPress, id]);

  return (
    <Pressable onPress={handlePress}>
      <Wrapper>
        <Skeleton>
          <ContentWrapper small={small}>
            <SubtitleWrapper isVisible={!!time}>
              <Text variant="caption-small">{dateTimeWithoutYearFormatter(time)}</Text>
            </SubtitleWrapper>
            <SubtitleWrapper>
              <Text variant="caption-small">{subtitle}</Text>
            </SubtitleWrapper>
            <TitleWrapper>
              <Text variant="body" numberOfLines={1}>
                {title}
              </Text>
            </TitleWrapper>

            <Visible if={statusIcon}>
              <Icon name={statusIcon as IconName} color={statusIconColor} size={BASE_SIZE[20]} />
            </Visible>
            <ActionWrapper>
              <Icon name={actionIcon} size={BASE_SIZE[20]} />
            </ActionWrapper>
          </ContentWrapper>
        </Skeleton>
      </Wrapper>
    </Pressable>
  );
};

interface ItemPlaceholderProps {
  small?: boolean;
}

export const ItemPlaceholder: React.FC<ItemPlaceholderProps> = ({ small }) => (
  <Item small={small} id="_" title="_" subtitle="_" />
);
