import { Person } from '@quromedical/models';
import { Linking, Platform } from 'react-native';

import { logger } from './logger';

const mapPlatform = Platform.select({
  ios: 'maps:0,0?q=',
  android: 'geo:0,0?q=',
  web: 'https://www.google.com/maps/dir/?api=1&origin=&destination=',
}) as string;

const getPlatformURL = (latLng: string, label?: string) => {
  if (Platform.OS === 'web') {
    return `${mapPlatform}${latLng}`;
  }

  if (!label) {
    return undefined;
  }

  return Platform.select({
    ios: `${mapPlatform}${label}@${latLng}`,
    android: `${mapPlatform}${latLng}(${label})`,
  });
};

export const onMapsPress = (contact?: Partial<Person.Contact>) => {
  if (!contact?.addressCoordinates) {
    return;
  }
  const { latitude, longitude } = contact.addressCoordinates;
  const latLng = `${latitude},${longitude}`;
  const addressLabel = contact?.addressLines;
  const label = addressLabel?.toString();

  const platformUrl = getPlatformURL(latLng, label);

  if (!platformUrl) {
    return;
  }

  Linking.openURL(platformUrl).catch(logger.error);
};
