import styled from '@emotion/native';
import { createFhirDateTime, GroupSecurity } from '@quromedical/fhir-common';
import { Notes } from '@quromedical/models';
import { useIsFocused } from '@react-navigation/native';
import { Col, Row } from 'components/base';
import { WithGroup } from 'core/auth';
import { PatientHeaderSection, PatientVitalsSummaryCard } from 'core/patient';
import { hoursToMilliseconds } from 'date-fns/esm';
import {
  Button,
  DurationPicker,
  DurationResult,
  MARGIN,
  Section,
  Spacer,
  ThemeProvider,
} from 'design-system';
import { useBottomNavigatorHidden } from 'design-system/navigation';
import { printAsync } from 'expo-print';
import { logger } from 'helpers';
import { flattenVitalResult, NotesApi, VitalsApi } from 'integration/aggregate';
import { PatientDrawerScreenProps } from 'navigation';
import { useDrawerActions } from 'providers/DrawerActionsContext';
import { usePatientId } from 'providers/PatientIdContext';
import { BoundaryTimes, getBoundaryTimes, Times } from 'providers/PatientVitalsProvider';
import React, { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';

import { NotesSection, VitalsSection } from './sections';

const notesApi = new NotesApi();
const vitalsApi = new VitalsApi();

const Report = styled(Col)(({ theme }) => ({
  // always white since we show a light theme and don't want the background to run off the page
  backgroundColor: theme.color.white,
}));

const ReportPage = styled.View(({ theme }) => ({
  // configured to get something that looks somewhat okay
  width: 1100,
  backgroundColor: theme.color.base,
}));

const ActionWrapper = styled(Section)({
  marginLeft: 0,
  marginBottom: 0,
});

const getNoteQueryDates = (times: BoundaryTimes): Notes.QueryParams => {
  const dateFrom = createFhirDateTime(new Date(times.start));
  const dateTo = createFhirDateTime(new Date(times.end));

  return {
    dateFrom,
    dateTo,
  };
};

export const PrintWrapper: React.FC = ({ children }) => {
  useBottomNavigatorHidden();
  const drawerActions = useDrawerActions();

  const focused = useIsFocused();

  useEffect(() => {
    // hide drawer only during print mode
    if (focused) {
      drawerActions.hideDrawer();
    } else {
      drawerActions.showDrawer();
    }

    return drawerActions.showDrawer;
  }, [drawerActions, focused]);

  return <>{children}</>;
};

const initialDuration = hoursToMilliseconds(24);

export const PatientSummaryScreen: React.FC<PatientDrawerScreenProps<'PrintSummary'>> = () => {
  const patientId = usePatientId();

  const [times, setTimes] = useState<Times>({
    duration: initialDuration,
  });

  const resolvedTimes = getBoundaryTimes(times);
  const dateQuery = getNoteQueryDates(resolvedTimes);

  const handleDurationChanged = useCallback(
    (state: DurationResult) => {
      setTimes({
        duration: state.duration,
        start: state.from,
      });
    },
    [setTimes]
  );

  const notesFetcher = useCallback(
    () =>
      notesApi.getNotes(patientId, {
        ...dateQuery,
        count: 100,
      }),
    [dateQuery, patientId]
  );

  const vitalsFetcher = useCallback(
    () => vitalsApi.getVitals(patientId, resolvedTimes),
    [patientId, resolvedTimes]
  );

  const key = `${patientId}/${times.start?.getTime() || 'now'}-${times.duration}`;

  // fetch data at the parent level to prevent rerenders when printing
  const { isValidating: validatingNotes, data: noteBundle } = useSWR(
    `summary/notes/${key}`,
    notesFetcher
  );
  const { isValidating: validatingVitals, data: vitalsData } = useSWR(
    `summary/vitals/${key}`,
    vitalsFetcher
  );

  const vitals = vitalsData ? flattenVitalResult(vitalsData) : {};

  const print = useCallback(async () => {
    try {
      await printAsync({});
    } catch (err) {
      logger.error(err);
    }
  }, []);

  const isLoading = validatingVitals || validatingNotes;

  return (
    <ThemeProvider theme="light">
      <Report justifyContent="flex-start" alignItems="flex-start" flex={1}>
        <ReportPage>
          <PrintWrapper>
            <ActionWrapper>
              <Row alignItems="center" justifyContent="space-between">
                <Col>
                  <PatientHeaderSection id={patientId} />
                </Col>

                <Row>
                  <DurationPicker onChange={handleDurationChanged} initial="24h" />
                  <Spacer width={MARGIN.s} />
                  <Button variant="contained" onPress={print} icon="print" />
                </Row>
              </Row>
            </ActionWrapper>

            <WithGroup groups={[GroupSecurity.PatientRead]}>
              <Section>
                <PatientVitalsSummaryCard id={patientId} />
              </Section>

              <WithGroup groups={[GroupSecurity.ClinicalImpressionRead]}>
                <Section>
                  <NotesSection loading={isLoading} notes={noteBundle?.notes} />
                </Section>
              </WithGroup>

              <Section hasBottomMargin>
                <VitalsSection loading={isLoading} data={vitals} />
              </Section>
            </WithGroup>
          </PrintWrapper>
        </ReportPage>
      </Report>
    </ThemeProvider>
  );
};
