import { useIsSmallerThan, useResponsive } from 'hooks/useResponsive';

import { SectionProps } from './types';

interface ResponsiveSidebarProps {
  main: SectionProps;
  aside: SectionProps;
}

export const useResponsiveSidebarProps = (): ResponsiveSidebarProps => {
  const cols = 12;

  const isSmall = useIsSmallerThan('sm');

  const asideFlex = useResponsive({ sm: 0, md: 0, lg: 4, xl: 3 }, 0);
  const mainFlex = cols - asideFlex;

  return {
    main: {
      flex: mainFlex,
      hidden: false,
    },
    aside: {
      flex: asideFlex,
      hidden: isSmall,
    },
  };
};
