import styled from '@emotion/native';
import { Col, Row } from 'components/base';
import { Text, Icon } from 'design-system/base';
import { BASE_SIZE, MARGIN, PADDING } from 'design-system/theme';
import React from 'react';

import { BackgroundWrapper } from './BackgroundWrapper';
import { MeasureTags } from './MeasureTags';
import { TitleWrapper } from './TitleWrapper';
import { Measure } from './types';

type MeasureValueVariant = 'dashboard-value-small' | 'dashboard-value-large';

const IconWrapper = styled(Col)({
  marginRight: MARGIN['3xs'],
});

interface ValueWrapperProps {
  hasBottomPadding?: boolean;
}

const ValueWrapper = styled(Col)<ValueWrapperProps>(({ hasBottomPadding = false }) => ({
  // we include the padding at the component level so that we can align components in different
  // space contexts
  padding: PADDING.xs,
  paddingBottom: hasBottomPadding ? undefined : 0,
}));

interface MeasureBlockProps extends Measure {
  valueVariant: MeasureValueVariant;
}

// can use expo-linear-gradient for gradients, install currently failing
export const MeasureBlock: React.FC<MeasureBlockProps> = ({
  icon,
  value = '--',
  title = ' ',
  note = ' ',
  unit = ' ',
  valueVariant = 'dashboard-value-small',
  color = 'text-default',
  backgroundColor = 'transparent',
  tags,
}) => (
  <BackgroundWrapper backgroundColor={backgroundColor}>
    <TitleWrapper>
      <Row alignItems="center" justifyContent="space-between">
        <Row alignItems="center">
          <IconWrapper isVisible={!!icon}>
            <Icon name={icon} size={BASE_SIZE[12]} color="text-default" />
          </IconWrapper>
          <Col>
            <Text numberOfLines={1} variant="dashboard-title">
              {title}
            </Text>
          </Col>
        </Row>
        <Col>
          <Text numberOfLines={1} color={color} variant="dashboard-subtitle">
            {unit}
          </Text>
        </Col>
      </Row>
    </TitleWrapper>
    <ValueWrapper hasBottomPadding={!tags}>
      <Row>
        <Text numberOfLines={1} variant={valueVariant} color={color}>
          {value}
        </Text>
      </Row>
      <Row>
        <Text numberOfLines={1} variant="dashboard-subtitle">
          {note}
        </Text>
      </Row>
    </ValueWrapper>
    <MeasureTags items={tags} />
  </BackgroundWrapper>
);
