import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { BORDER_RADIUS, BORDER_WIDTH, MARGIN, PADDING } from 'design-system/theme';

import { getFadedColor } from './internal';

interface ItemPillWrapperProps {
  color?: ColorName;
  borderColor?: ColorName;
  selected?: boolean;
}
export const ItemPillWrapper = styled.View<ItemPillWrapperProps>(
  ({ theme, color = 'transparent', borderColor = color, selected = false }) => {
    const fadedColor = getFadedColor(theme, color);
    const fadedBorderColor = getFadedColor(theme, borderColor);

    return {
      backgroundColor: selected ? fadedColor : undefined,
      borderColor: selected ? theme.color.transparent : fadedBorderColor,

      marginTop: MARGIN['3xs'],
      marginBottom: MARGIN['2xs'],
      marginRight: MARGIN.xs,
      paddingHorizontal: PADDING['2xs'],
      paddingVertical: PADDING['3xs'],
      borderStyle: 'solid',
      borderWidth: BORDER_WIDTH[1],
      borderRadius: BORDER_RADIUS[4],
    };
  }
);
