import { useTheme } from '@emotion/react';
import { BASE_SIZE } from 'design-system/theme';
import React from 'react';
import { ActivityIndicator } from 'react-native';

export const Spinner: React.FC = () => {
  const theme = useTheme();

  return <ActivityIndicator size={BASE_SIZE[24]} color={theme.color.primary} />;
};
