import { ReactNativeStyle } from '@emotion/native';
import { Theme } from '@emotion/react';

/**
 * A map of variant names and variant styles
 */
type VariantMap<TVariant extends string, TStyle> = Record<TVariant, TStyle>;

/**
 * A function that takes a function and returns map of variant names and styles that make use of the
 * theme object
 */
type VariantBuilder<TVariant extends string, TStyle, TProps extends {} = {}, TTheme = Theme> = (
  theme: TTheme,
  props: TProps
) => VariantMap<TVariant, TStyle>;

/**
 * A variant is defined as either an object which lists static variant values or a function which
 * uses the `theme` to resolve dynamic Variant Values
 */
export type VariantConfig<
  TVariant extends string,
  TStyle,
  TProps extends {} = {},
  TTheme = Theme
> = VariantMap<TVariant, TStyle> | VariantBuilder<TVariant, TStyle, TProps, TTheme>;

/**
 * Builds variants within the context of a styled-component using a list of variants along with the
 * resolved `variant` and `theme`
 *
 * @param variants variant map or a function using the `theme` in a variant function
 * @param variant
 * @param theme
 * @returns
 *
 * @example
 * type StyleVariant = 'on' | 'off';
 * type SizeVariant = 'yes' | 'no';
 *
 * interface ComponentProps {
 *   variant: StyleVariant;
 *   sizeVariant: SizeVariant;
 * }
 *
 * const variants: Record<'on' | 'off', ViewStyle> = {
 *   on: {some styles},
 *   off: {some styles},
 * };
 *
 * export const MyComponent = styled.View<ComponentProps>(
 *   (props) => pickVariant(variants, props.variant, props),
 *   (props) => pickVariant(sizeVariants, props.sizeVariant, props)
 * );
 */
export const pickVariant = <
  TVariant extends string,
  TStyle extends ReactNativeStyle,
  TTheme = Theme,
  TProps extends {
    theme: TTheme;
  } = {
    theme: TTheme;
  },
  // at the very least we can ensure we always return an object, this should be a valid style
  TResult extends {} = {}
>(
  variants: VariantConfig<TVariant, TStyle, TProps, TTheme>,
  variant: TVariant,
  props: TProps
): TResult => {
  let result: TStyle;

  if (typeof variants === 'function') {
    result = variants(props.theme, props)[variant];
  } else {
    result = variants[variant];
  }

  // cast as unknown so we can allow the caller to infer the resulting type
  return (result || {}) as unknown as TResult;
};
