import { Notes } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';

enum NotesEndpoint {
  pin = 'pin',
}

interface DetailedEndpoint {
  noteId: string;
  endpoint: NotesEndpoint;
}

export class NotesApi {
  private readonly client: BaseApi;
  private readonly patientRoot = 'api/aggregate/patient';

  constructor() {
    this.client = new BaseApi();
  }

  private createPath(patientId: string, detail?: DetailedEndpoint): string {
    const base = `${this.patientRoot}/${patientId}/notes`;

    if (!detail) {
      return base;
    }

    return `${base}/${detail.noteId}/${detail.endpoint}`;
  }

  public async getNotes(patientId: string, params: Notes.QueryParams): Promise<Notes.NoteBundle> {
    const path = this.createPath(patientId);
    const result = await this.client.get<Notes.NoteBundle>(path, {
      params,
    });

    return result.data;
  }

  public async createNote(patientId: string, body: Notes.CreateNote): Promise<Notes.NoteBundle> {
    const path = this.createPath(patientId);
    const result = await this.client.post<Notes.NoteBundle>(path, body);

    return result.data;
  }

  public async pinNote(
    patientId: string,
    noteId: string,
    pinned: boolean
  ): Promise<Notes.PinNoteResponse> {
    const path = this.createPath(patientId, {
      noteId,
      endpoint: NotesEndpoint.pin,
    });

    const request: Notes.PinNoteRequest = {
      pinned,
    };

    const result = await this.client.put<Notes.PinNoteResponse>(path, request);

    return result.data;
  }
}
