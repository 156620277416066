import { A } from '@expo/html-elements';
import React from 'react';

import { AppleAppStoreBadge } from './AppleAppStoreBadge';

const appleAppStoreUrl = 'https://apps.apple.com/us/app/quro-medical/id1594819603';

export const AppleAppStoreLink: React.FC = () => (
  <A href={appleAppStoreUrl}>
    <AppleAppStoreBadge />
  </A>
);
