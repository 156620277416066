import { ChargeItemDefinition } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import compact from 'lodash.compact';

export class ChargeItemApi {
  private readonly client: BaseApi;
  private readonly root = 'api/aggregate/medical-aid/charge-item';

  constructor() {
    this.client = new BaseApi();
  }

  private createPath(id?: string) {
    return compact([this.root, id]).join('/');
  }

  public async getChargeItems(
    params: ChargeItemDefinition.GetAllParams
  ): Promise<ChargeItemDefinition.GetAllResponse> {
    const path = this.createPath();
    const result = await this.client.get<ChargeItemDefinition.GetAllResponse>(path, {
      params,
    });

    return result.data;
  }

  public getSWRKey(id?: string) {
    return this.createPath(id);
  }

  public async getChargeItem(id: string): Promise<ChargeItemDefinition.GetResponse> {
    const path = this.createPath(id);
    const result = await this.client.get<ChargeItemDefinition.GetResponse>(path);

    return result.data;
  }

  public async createChargeItem(
    body: ChargeItemDefinition.CreateChargeItemDefinitionRequest
  ): Promise<ChargeItemDefinition.CreateChargeItemDefinitionResponse> {
    const path = this.createPath();
    const result = await this.client.post<ChargeItemDefinition.CreateChargeItemDefinitionResponse>(
      path,
      body
    );

    return result.data;
  }

  public async updateChargeItem(
    id: string,
    body: ChargeItemDefinition.UpdateChargeItemDefinitionRequest
  ): Promise<ChargeItemDefinition.UpdateChargeItemDefinitionResponse> {
    const path = this.createPath(id);
    const result = await this.client.put<ChargeItemDefinition.UpdateChargeItemDefinitionResponse>(
      path,
      body
    );

    return result.data;
  }
}
