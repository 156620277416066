import AsyncStorage from '@react-native-async-storage/async-storage';
import { useCallback } from 'react';

interface AsyncStorageHook {
  getItem: () => Promise<string | null>;
  setItem: (value: string) => Promise<void>;
  removeItem: () => Promise<void>;
}

/**
 * Re-implementation since the react-native-community one seems to be incorrectly implemented and
 * leads to a render loop
 */
export const useAsyncStorage = (key: string): AsyncStorageHook => {
  const getItem = useCallback(async () => AsyncStorage.getItem(key), [key]);
  const setItem = useCallback(async (value: string) => AsyncStorage.setItem(key, value), [key]);
  const removeItem = useCallback(async () => AsyncStorage.removeItem(key), [key]);

  return {
    getItem,
    setItem,
    removeItem,
  };
};
