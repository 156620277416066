import { MedicalAid } from '@quromedical/models';
import { Col } from 'components/base';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Field } from 'components/types';
import { Alert, Fetcher, Snackbar } from 'design-system';
import { logger } from 'helpers';
import React, { useCallback, useState } from 'react';
import { IdType, Search, searchSchema } from 'screens/patient';
import { strings } from 'strings';

const createFields = (
  schemeFetcher: Fetcher<string, any>,
  idType: IdType,
  canSearchOnIDNumber: boolean
): Field<Search>[] => [
  {
    subfields: [
      {
        type: 'combobox-single',
        key: 'schemeDetails',
        label: strings.FormLabelScheme,
        returnFullOption: true,
        searchable: true,
        fetcher: schemeFetcher,
      },
      {
        type: 'radio-group',
        key: 'idType',
        label: strings.FormLabelIDType,
        options: [
          { display: strings.FormLabelMedicalAidNumber, value: 'medicalAid' },
          { display: strings.LabelIDNumber, value: 'idNumber', disabled: !canSearchOnIDNumber },
        ],
      },
      {
        type: 'text-box',
        key: 'id',
        label: idType === 'medicalAid' ? strings.FormLabelMedicalAidNumber : strings.LabelIDNumber,
      },
    ],
  },
];

const createMedicalAidValidationRequest = (
  search: Search
): MedicalAid.MedicalAidValidationRequest => {
  if (search.idType === 'idNumber') {
    return {
      idNumber: search.id,
      schemeCode: search.schemeDetails.value,
    };
  }

  return {
    membershipNumber: search.id,
    schemeCode: search.schemeDetails.value,
  };
};

export interface SearchResult {
  search: Search;
  result: MedicalAid.MedicalAidValidationResponse;
}

export type OnSearch = (
  request: MedicalAid.MedicalAidValidationRequest
) => Promise<MedicalAid.MedicalAidValidationResponse>;

interface SchemeSearchFormProps {
  schemeFetcher: Fetcher<string, any>;
  onSearch: OnSearch;
  onResult: (result: SearchResult) => void;
  onStart: () => void;
}

type State = 'initial' | 'loading' | 'error';

export const SchemeSearchForm: React.FC<SchemeSearchFormProps> = ({
  onResult,
  onStart,
  onSearch,
  schemeFetcher,
}) => {
  const [search, setForm] = useState<Partial<Search>>({});
  const [state, setState] = useState<State>('initial');

  const isFormDisabled = state === 'loading';
  const showError = state === 'error';

  const canSearchOnIDNumber = search?.schemeDetails?.meta?.canSearchOnIDNumber || false;

  const initialValues: Partial<Search> = {
    ...search,
    idType: !canSearchOnIDNumber ? 'medicalAid' : search?.idType,
    schemeDetails: search?.schemeDetails,
  };

  const idSearchAvailable = String(canSearchOnIDNumber);
  const idType = search?.idType || 'medicalAid';

  const fields = createFields(schemeFetcher, idType, canSearchOnIDNumber || false);

  const onSubmit = useCallback<SubmissionHandler<Partial<Search>>>(
    async (formState: Partial<Search>) => {
      setState('loading');
      onStart();
      const medicalAidValidationRequest = createMedicalAidValidationRequest(formState as Search);

      try {
        const result = await onSearch(medicalAidValidationRequest);
        onResult({ search: formState as Search, result });
        setState('initial');
        return {};
      } catch (error) {
        logger.error(error);
        setState('error');
        return { error };
      }
    },
    [onResult, onStart, onSearch]
  );

  const onAlertClose = useCallback(() => {
    setState('initial');
  }, []);

  return (
    <>
      <Col>
        <CrudForm
          key={idSearchAvailable}
          fields={fields}
          initialValues={initialValues}
          onChange={setForm}
          onChangeDebounceTime={0}
          validationSchema={searchSchema}
          disabled={isFormDisabled}
          onSubmit={onSubmit}
        />
      </Col>
      <Snackbar isOpen={showError} onClose={onAlertClose}>
        <Alert
          actionIcon="close"
          backgroundColor="status-critical"
          onAction={onAlertClose}
          title={strings.AlertError}
          body={strings.MedicalAidSearchError}
        />
      </Snackbar>
    </>
  );
};
