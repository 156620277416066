import { reverse } from 'd3-array';
import { scaleLinear } from 'd3-scale';
import React, { useCallback, useState, PropsWithChildren } from 'react';
import { Animated, LayoutChangeEvent, LayoutRectangle } from 'react-native';
import { Svg } from 'react-native-svg';

import { PlotDataProvider } from './PlotDataProvider';
import { ExternalPlotProps, BaseComponentProps, Margin, BasePlotProps, Datum } from './types';
import { usePlatformHoverHandler } from './usePlatformHoverHandler';
import { usePlatformPressHandler } from './usePlatformPressHandler';

export const defaultMargin: Margin = {
  top: 16,
  right: 14,
  bottom: 28,
  left: 36,
};

/**
 * Used as the root component when plotting data using `scaleLinear` and `number,number` data
 */
export const LinearPlot: React.FC<ExternalPlotProps> = (props) => {
  const {
    baseWidth = 1200,
    aspectRatio = 3 / 2,
    margin = defaultMargin,
    baseHeight = baseWidth / aspectRatio,
    data = [],
    xDomain = [0, 1],
    yDomain = [0, 1],
    useNiceScaleX = false,
    useNiceScaleY = false,
    children,
    flex,
    overflow = 'hidden',
    onSelectStart,
    onSelectEnd,
    onHover,
    onHoverDebounce,
  } = props as PropsWithChildren<BasePlotProps>;

  const [layout, setLayout] = useState<LayoutRectangle>();

  const viewBox = `0 0 ${baseWidth} ${baseHeight}`;

  const [endTouch, setEndTouch] = useState<{ x: number; y: number }>();
  const [startTouch, setStartTouch] = useState<{ x: number; y: number }>();

  const xScaleBase = scaleLinear()
    .domain(xDomain)
    .range([margin.left, baseWidth - margin.right]);

  const xScale = useNiceScaleX ? xScaleBase.nice() : xScaleBase;

  const yScaleBase = scaleLinear()
    .domain(reverse(yDomain))
    .range([margin.top, baseHeight - margin.bottom]);

  const yScale = useNiceScaleY ? yScaleBase.nice() : yScaleBase;

  const xScreenScale = scaleLinear()
    .domain([0, layout?.width || baseWidth])
    .range([0, baseWidth]);

  const yScreenScale = scaleLinear()
    .domain([0, layout?.height || baseHeight])
    .range([0, baseHeight]);

  const pressHandlers = usePlatformPressHandler(
    (pos) => {
      if (!onSelectStart) {
        return;
      }

      setStartTouch(pos);
      onSelectStart({
        x: xScale.invert(pos.x),
        y: yScale.invert(pos.y),
      });
    },
    (pos) => {
      if (!onSelectEnd) {
        return;
      }

      setEndTouch(pos);
      onSelectEnd({
        x: xScale.invert(pos.x),
        y: yScale.invert(pos.y),
      });
    },
    xScreenScale,
    yScreenScale
  );

  const hoverCallback = useCallback(
    (pos: Datum) => {
      onHover?.({
        x: xScale.invert(pos.x),
        y: yScale.invert(pos.y),
      });
    },
    [onHover, xScale, yScale]
  );

  const hoverHandlers = usePlatformHoverHandler(
    xScreenScale,
    yScreenScale,
    // we don't want to trigger hover callbacks unnecessarily if the consumer isn't using them
    onHover && hoverCallback,
    onHoverDebounce
  );

  const onLayout = useCallback((e: LayoutChangeEvent) => {
    setLayout(e.nativeEvent.layout);
  }, []);

  const baseComponentProps: BaseComponentProps = {
    baseWidth,
    baseHeight,
    aspectRatio,
    margin,
    xDomain,
    yDomain,
    xScale,
    yScale,
    data,
    layout,
    startTouch,
    endTouch,
    flex,
    xScreenScale,
    yScreenScale,
    useNiceScaleX,
    useNiceScaleY,
  };

  return (
    <PlotDataProvider value={baseComponentProps}>
      <Animated.View
        style={{ aspectRatio, flex }}
        onLayout={onLayout}
        {...pressHandlers}
        {...hoverHandlers}
      >
        <Svg viewBox={viewBox} width="100%" height="100%" style={{ overflow }}>
          {children}
        </Svg>
      </Animated.View>
    </PlotDataProvider>
  );
};
