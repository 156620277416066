import {
  Bundle,
  DeviceUseStatementCode,
  getReferenceId,
  getResourcesFromBundle,
} from '@quromedical/fhir-common';
import { minutesToMilliseconds } from 'date-fns';
import { DeviceUseStatement } from 'fhir/r4';
import { useFhirData } from 'hooks/useFhirData';
import { DeviceUseStatementApi } from 'integration';
import { RelayStatus, RelayStatusApi, RelayStatusField } from 'integration/RelayStatusApi';
import { useCallback } from 'react';
import useSWR from 'swr';

const deviceUseStatementApi = new DeviceUseStatementApi();
const relayStatusApi = new RelayStatusApi();

const relayDeviceUseStatementFetcher = async (
  subject?: string
): Promise<Bundle<DeviceUseStatement> | undefined> => {
  if (!subject) {
    return undefined;
  }
  const result = await deviceUseStatementApi.getDeviceUseStatements({
    subject,
    code: DeviceUseStatementCode.relay,
    status: 'active',
    count: 10,
  });

  return result;
};

interface UseRelayStatusResult {
  data?: RelayStatus;
  hasError: boolean;
}

export const useRelayStatus = (patientId?: string): UseRelayStatusResult => {
  const { data: relayDeviceUseStatements, error: deviceUseStatementError } = useFhirData(
    ['DeviceUseStatement', 'registered-relays'],
    patientId,
    relayDeviceUseStatementFetcher,
    {
      dedupingInterval: minutesToMilliseconds(10),
    }
  );

  const deviceUseStatement = getResourcesFromBundle(relayDeviceUseStatements).find((d) =>
    getReferenceId(d.device)
  );

  const deviceId = getReferenceId(deviceUseStatement?.device);

  const relayStatusFetcher = useCallback(async (): Promise<RelayStatus | undefined> => {
    if (!deviceId) {
      return undefined;
    }

    return relayStatusApi.getStatus(deviceId);
  }, [deviceId]);

  const { data, error: statusError } = useSWR(
    `relay-status/${deviceId || 'no-device'}`,
    relayStatusFetcher,
    {
      dedupingInterval: minutesToMilliseconds(5),
    }
  );

  const hasError = !!statusError || deviceUseStatementError;

  return {
    data,
    hasError: !!hasError,
  };
};

const networkThreshold = 3;
const memoryThreshold = 8000;
const batteryThreshold = 15;

const getValue = (field?: RelayStatusField<number>): number => field?.value || 0;

interface RelayStatusIssues {
  hasAny: boolean;
  mbs: boolean;
  relay: boolean;
  noBluetooth: boolean;
  lowBattery: boolean;
  lowStorage: boolean;
  poorNetwork: boolean;
}

export const getRelayStatusIssues = (status?: RelayStatus): RelayStatusIssues | undefined => {
  if (!status) {
    return undefined;
  }

  const {
    bluetoothGattConnectedDevices,
    availableStorageMB,
    batteryIsCharging,
    batteryPercent,
    cellularNetworkLevel,
    mbsAppStatus,
  } = status;

  const poorNetwork = getValue(cellularNetworkLevel) < networkThreshold;
  const lowStorage = getValue(availableStorageMB) < memoryThreshold;
  const lowBattery =
    batteryIsCharging?.value === false && getValue(batteryPercent) < batteryThreshold;
  const noBluetooth = bluetoothGattConnectedDevices?.value?.length === 0;

  const relay = poorNetwork || lowBattery || lowStorage || noBluetooth;

  const mbs = !mbsAppStatus?.value?.toLowerCase().includes('monitoring');

  const hasAny = relay || mbs;

  return {
    hasAny,
    mbs,
    relay,
    noBluetooth,
    lowBattery,
    lowStorage,
    poorNetwork,
  };
};
