import { CSSProperties } from 'react';

import { Renderer } from './types';

const imageStyles: CSSProperties = {
  height: 'auto',
  maxHeight: '60vh',
  minHeight: 200,
  width: '100%',
  borderWidth: 0,
  objectFit: 'contain',
};

export const Image: Renderer = ({ url }) => <img src={url} style={imageStyles} />;
