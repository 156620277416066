import styled from '@emotion/native';
import { BORDER_WIDTH, PADDING } from 'design-system/theme';
import React from 'react';

import { StatusBarProps, StatusBar } from '../status-bar';

export type StatusHeaderProps = Omit<StatusBarProps, 'titleVariant'>;

const StatusBarWrapper = styled.View(({ theme }) => ({
  paddingVertical: PADDING['2xs'],
  paddingHorizontal: PADDING['2xs'],
  borderWidth: 0,
  borderStyle: 'solid',
  borderColor: theme.color.divider,
  borderBottomWidth: BORDER_WIDTH[1],
}));

export const StatusHeader: React.FC<StatusHeaderProps> = (props) => (
  <StatusBarWrapper>
    <StatusBar {...props} />
  </StatusBarWrapper>
);
