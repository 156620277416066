import {
  CreateReference,
  DeviceMetricCategory,
  DeviceMetricTypeCode,
} from '@quromedical/fhir-common';
import { DeviceCommand, Observation } from '@quromedical/models';
import { Col, Row } from 'components/base';
import { DisplayView } from 'components/fhir';
import { ColumnType } from 'components/table';
import { PaginatedTable } from 'core/table';
import { optionalValueFormatters, titles, units } from 'core/vitals';
import { Text } from 'design-system';
import { dateTimeWithoutYearFormatter } from 'helpers';
import { DeviceApi } from 'integration/aggregate';
import compact from 'lodash.compact';
import React, { useCallback } from 'react';

interface DeviceMetricsListProps {
  deviceId: string;
  category?: DeviceMetricCategory;
  type?: DeviceMetricTypeCode;
}

const api = new DeviceApi();

interface TableDeviceMetric {
  type?: DeviceMetricTypeCode;
  time?: string;
  command?: string;
  status?: string;
  measurements?: Partial<Observation.ObservationVitalMeasurement>[];
  performers?: CreateReference[];
}

const strings = {
  DeviceMetricTypeDisplayPatchCalibration: 'Patch Calibration',
  DeviceMetricPerformer: 'User',
  DeviceMetricType: 'Type',
  DeviceMetricTime: 'Calibration Time',
  DeviceMetricStatus: 'Status',
  DeviceMetricMeasurements: 'Measurements',
};

const deviceMetricTypeDisplays: Record<DeviceMetricTypeCode, string> = {
  [DeviceMetricTypeCode.patchCalibration]: strings.DeviceMetricTypeDisplayPatchCalibration,
};

const columns: ColumnType<TableDeviceMetric>[] = [
  {
    accessor: 'type',
    header: 'Type',
    Cell: ({ value }) => (value ? <Text>{deviceMetricTypeDisplays[value]}</Text> : null),
  } as ColumnType<TableDeviceMetric, 'type'>,
  {
    accessor: 'time',
    header: 'Time',
    Cell: ({ value }) =>
      value ? <Text>{dateTimeWithoutYearFormatter(new Date(value))}</Text> : undefined,
  } as ColumnType<TableDeviceMetric, 'time'>,
  {
    accessor: 'status',
    header: 'Status',
  } as ColumnType<TableDeviceMetric, 'status'>,
  {
    accessor: 'measurements',
    header: 'Measurements',
    Cell: ({ value: measures = [] }) => (
      <Col>
        {measures.map(({ measurementName, value }) => {
          if (!measurementName) {
            return (
              <Row>
                <Text>{value}</Text>
              </Row>
            );
          }

          const unit = units[measurementName] || '';
          const title = titles[measurementName] || measurementName;

          const valueDisplay = optionalValueFormatters[measurementName](value) || value;

          const display = `${title}: ${valueDisplay} ${unit}`;

          return (
            <Row>
              <Text>{display}</Text>
            </Row>
          );
        })}
      </Col>
    ),
  } as ColumnType<TableDeviceMetric, 'measurements'>,
  {
    accessor: 'performers',
    header: 'Performer',
    Cell: ({ value }) => (value ? value?.map((val) => <DisplayView reference={val} />) : null),
  } as ColumnType<TableDeviceMetric, 'performers'>,
];

const mapResponseToTable = (res: DeviceCommand.DeviceMetricGetResponse): TableDeviceMetric => ({
  time: res.time,
  type: res.type,
  command: res.command?.command,
  status: res.command?.state,
  performers: compact(res.measurement?.performers),
  measurements: compact(res.measurement?.measures).map((measure) => ({
    measurementName: measure?.measurementName,
    sensorId: measure?.sensorId,
    ts: measure?.ts,
    value: measure?.value,
  })),
});

export const DeviceMetricsList: React.FC<DeviceMetricsListProps> = ({
  deviceId,
  category,
  type,
}) => {
  const fetcher = useCallback(
    (pageToken?: string, count?: number) =>
      api.getDeviceMetrics(deviceId, {
        category,
        type,
        pageToken,
        count,
      }),
    [deviceId, category, type]
  );

  const cacheKey = `${deviceId}/device-metrics/${category}/${type}`;

  return (
    <PaginatedTable
      cacheKey={cacheKey}
      columns={columns}
      fetcher={fetcher}
      getDataFromResponse={(res) => res.deviceMetrics.map(mapResponseToTable)}
      canChangeCount
      initialCount={20}
    />
  );
};
