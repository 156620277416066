import { DeviceCode } from '@quromedical/fhir-common';
import { ConfigurePatchFormData, PatchConfigureForm } from 'core/device';
import { DeviceApi } from 'integration/aggregate';
import React, { useCallback } from 'react';

interface DeviceConfigureProps {
  deviceId: string;
  deviceCode?: DeviceCode;
  revalidate?: () => Promise<void>;
  inline?: boolean;
}

const api = new DeviceApi();

export const DeviceConfigure: React.FC<DeviceConfigureProps> = ({
  deviceId: id,
  deviceCode,
  inline = false,
  revalidate,
}) => {
  const configurePatch = useCallback(
    async (data: ConfigurePatchFormData) => {
      // don't try-catch this since the states are used by the internal form
      await api.calibratePatch(id, {
        posture: data.posture,
        temperature: data.temperature,
      });
    },
    [id]
  );

  const isPatch = deviceCode === DeviceCode.patch;

  if (!isPatch) {
    return null;
  }

  return <PatchConfigureForm revalidate={revalidate} onSubmit={configurePatch} inline={inline} />;
};
