import styled from '@emotion/native';
import { Col, FlexProps } from 'components/base';
import { fadeColor, FONT_FAMILY, FONT_SIZE, MARGIN, PADDING } from 'design-system/theme';
import React from 'react';
import { Text as BaseText } from 'react-native';
import { ForeignObject, Rect } from 'react-native-svg';

import { mean } from './distance';
import { usePlotDataContext } from './PlotDataProvider';
import { Domain, ExternalComponentProps } from './types';

interface TextProps {
  fontFamily?: string;
  fontSize?: number;
  color?: string;
  backgroundColor?: string;
  paddingHorizontal?: number;
  paddingVertical?: number;
}

interface TooltipProps extends TextProps {
  x: number;
  y: number;
  /**
   * A pixel value offset - will only be applied if the `x` value is a number
   */
  xOffset?: number;
  /**
   * A pixel value offset - will only be applied if the `y` value is a number
   */
  yOffset?: number;
  text?: string;
}

type ExternalProps = ExternalComponentProps & TooltipProps;

type VerticalPosition = 'top' | 'bottom';
type HorizontalPosition = 'left' | 'right';

type WrapperProps = Pick<FlexProps, 'justifyContent' | 'alignItems'>;

const flexProps: Record<VerticalPosition, Record<HorizontalPosition, WrapperProps>> = {
  top: {
    left: {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    right: {
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
    },
  },
  bottom: {
    left: {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
    right: {
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
  },
};

const Text = styled(BaseText)<TextProps>((props) => props);

const getHorizontalPosition = (x: number, xDomain: Domain): HorizontalPosition =>
  x > mean(xDomain) ? 'right' : 'left';

const getVerticalPosition = (y: number, yDomain: Domain): VerticalPosition =>
  y > mean(yDomain) ? 'top' : 'bottom';

const resolveYOffset = (verticalPosition: string, height: number, yOffset: number): number =>
  verticalPosition === 'bottom' ? -(height + yOffset) : yOffset;

const resolveXOffset = (horizontalPosition: string, width: number, xOffset: number): number =>
  horizontalPosition === 'right' ? -(width + xOffset) : xOffset;

interface InteractionBlockerProps {
  x: number;
  y: number;
  height: number;
  width: number;
}

/**
 * Required to cover the foreign object so that it does not interfere with SVG hover and on press
 * handlers
 */
const InteractionBlocker: React.FC<InteractionBlockerProps> = ({ x, y, height, width }) => {
  // increase the size of each measure slightly to handle cases where the foreign object can still
  // be interacted with along its edge
  const resolvedX = x + 1;
  const resolvedY = y + 1;
  const resolvedHeight = height + 2;
  const resolvedWidth = width + 2;

  return (
    <Rect
      x={resolvedX}
      y={resolvedY}
      height={resolvedHeight}
      width={resolvedWidth}
      fill="transparent"
    />
  );
};

export const Tooltip: React.FC<ExternalProps> = (props) => {
  const {
    text = '',
    color = 'lightgrey',
    fontSize = FONT_SIZE.caption,
    fontFamily = FONT_FAMILY.regular,
    x,
    y,
    xOffset = MARGIN.xs,
    yOffset = MARGIN['2xs'],
    xDomain,
    yDomain,
    xScale,
    yScale,
    backgroundColor = 'transparent',
    paddingHorizontal = PADDING['2xs'],
    paddingVertical = PADDING['3xs'],
    baseHeight,
    baseWidth,
  } = usePlotDataContext(props);

  // use a fraction of base height and width just to avoid running into the edges of the graph
  const height = baseHeight * 0.3;
  const width = baseWidth * 0.3;

  const verticalPosition: VerticalPosition = getVerticalPosition(y, yDomain);
  const horizontalPosition: HorizontalPosition = getHorizontalPosition(x, xDomain);

  const resolvedXOffset = resolveXOffset(horizontalPosition, width, xOffset);
  const resolvedYOffset = resolveYOffset(verticalPosition, height, yOffset);

  // the offset we use depends on how we choose to render the text
  const resolvedX = xScale(x) + resolvedXOffset;
  const resolvedY = yScale(y) + resolvedYOffset;

  const { alignItems, justifyContent } = flexProps[verticalPosition][horizontalPosition];

  const resolvedBackgroundColor = fadeColor(backgroundColor, 0.85);

  return (
    <>
      <ForeignObject x={resolvedX} y={resolvedY} height={height} width={width}>
        <Col fullHeight fullWidth alignItems={alignItems} justifyContent={justifyContent}>
          <Text
            fontFamily={fontFamily}
            fontSize={fontSize}
            color={color}
            backgroundColor={resolvedBackgroundColor}
            paddingHorizontal={paddingHorizontal}
            paddingVertical={paddingVertical}
            numberOfLines={2}
          >
            {text}
          </Text>
        </Col>
      </ForeignObject>
      <InteractionBlocker x={resolvedX} y={resolvedY} height={height} width={width} />
    </>
  );
};
