import { Alert, Skeleton, SkeletonProvider, Snackbar } from 'design-system';
import { logger } from 'helpers';
import { useRevalidation } from 'hooks/useFhirData';
import { useFiniteState } from 'hooks/useFiniteState';
import { GroupApi } from 'integration/aggregate';
import React, { useCallback } from 'react';
import { strings } from 'strings';

import { AddMemberForm } from '../forms';

interface AddMemberCardProps {
  id: string;
}

type FormState = 'editing' | 'submitting' | 'error';
const api = new GroupApi();

export const AddMemberCard: React.FC<AddMemberCardProps> = ({ id }) => {
  const state = useFiniteState<FormState>('editing');

  // list data is still using the old FHIR endpoint
  const revalidate = useRevalidation('Group');

  const onSubmit = useCallback(
    async (members: string[]) => {
      state.set('submitting');
      try {
        await api.updateGroupMembers(id, {
          active: true,
          members,
        });
        state.set('editing');
        revalidate();
      } catch (err) {
        state.set('error');
        logger.error(err);
      }
    },
    [id, revalidate, state]
  );

  return (
    <>
      <SkeletonProvider loading={state.is('submitting')}>
        <Skeleton>
          <AddMemberForm onSubmit={onSubmit} />
        </Skeleton>
      </SkeletonProvider>

      <Snackbar onClose={state.next('editing')} isOpen={state.is('error')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={state.next('editing')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
        />
      </Snackbar>
    </>
  );
};
