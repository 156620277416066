import * as Graph from 'components/graph';
import { FONT_FAMILY, FONT_SIZE } from 'design-system/theme';
import React from 'react';
import { strings } from 'strings';

export const NoDataFallback: React.FC = () => (
  <Graph.Text
    x="50%"
    y="50%"
    text={strings.NoDataFallbackText}
    fontSize={FONT_SIZE.heading}
    fontFamily={FONT_FAMILY.semiBold}
  />
);
