import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Col } from 'components/base';
import { fadeColor } from 'design-system/theme';

interface BackgroundProps {
  backgroundColor?: ColorName;
}

export const BackgroundWrapper = styled(Col)<BackgroundProps>(
  ({ backgroundColor = 'transparent', theme }) => {
    const isTransparent = backgroundColor === 'transparent';

    if (isTransparent) {
      return {
        backgroundColor: theme.color.transparent,
      };
    }

    const faded = fadeColor(theme.color[backgroundColor], 0.15);

    return {
      backgroundColor: faded,
    };
  }
);
