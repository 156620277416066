import { createFhirDateTime } from '@quromedical/fhir-common';
import { MeasurementName, Observation } from '@quromedical/models';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Field } from 'components/types';
import { titles, units, measureParts } from 'core/vitals';
import { Dialog } from 'design-system/components';
import { logger } from 'helpers/logger';
import compact from 'lodash.compact';
import React, { useCallback, useMemo } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

type FormValue = Record<MeasurementName, number>;

export interface VitalObservationCreateFormData extends Partial<FormValue> {
  /**
   * Not editable on the form, preset on the form, display only
   */
  unit?: string;
  /**
   * The date/time in the format as provided by masked datetime input
   */
  time: string;
}

interface VitalObservationCreateFormProps {
  isOpen: boolean;
  onClose: () => void;
  /**
   * The default measurement
   */
  measure: MeasurementName;

  onSubmit: (value: Observation.ObservationVitalMeasurement[]) => Promise<void>;
}

const createSchema = (measures: MeasurementName[]) => {
  const dynamicParts = measures.reduce<Partial<Record<MeasurementName, yup.AnySchema>>>(
    (prev, curr) => ({
      ...prev,
      [curr]: yup.number().positive().required(),
    }),
    {}
  );

  return yup.object<ObjectType<VitalObservationCreateFormData>>({
    ...dynamicParts,
    time: yup.date().required(),
  });
};

const createFields = (
  measureOptions: MeasurementName[]
): Field<VitalObservationCreateFormData>[] => [
  ...measureOptions.map<Field<VitalObservationCreateFormData>>((measurement) => ({
    subfields: [
      {
        key: measurement,
        type: 'number-box',
        keyboardType: 'decimal-pad',
        label: `${titles[measurement] || measurement}`,
      },
      {
        key: 'unit',
        type: 'text-display',
        label: strings.VitalObservationFormLabelUnit,
        text: units[measurement],
        backgroundColor: 'base-grey',
      },
    ],
  })),
  {
    subfields: [
      {
        key: 'time',
        type: 'masked-text-box',
        maskType: 'datetime',
        label: strings.VitalObservationFormLabelDateTime,
      },
    ],
  },
];

const createTitle = (measure: MeasurementName): string =>
  `${strings.VitalObservationFormTitlePrefix} ${titles[measure] || ''} ${
    strings.VitalObservationFormTitleSuffix
  }`;

const getMeasuresFromForm = (
  measurementNames: MeasurementName[],
  formData: VitalObservationCreateFormData
): Observation.ObservationVitalMeasurement[] =>
  compact(
    measurementNames.map<Observation.ObservationVitalMeasurement | undefined>((measure) => {
      const foundValue = formData[measure];
      if (!foundValue) {
        return undefined;
      }

      return {
        measurementName: measure,
        ts: new Date(formData.time).getTime(),
        value: foundValue,
      };
    })
  );

export const VitalObservationCreateForm: React.FC<VitalObservationCreateFormProps> = ({
  measure,
  onSubmit,
  isOpen,
  onClose,
}) => {
  const initialValues: Partial<VitalObservationCreateFormData> = {
    time: createFhirDateTime(new Date()),
  };

  const measures = useMemo(() => measureParts[measure] || [measure], [measure]);
  const handleSubmit = useCallback<SubmissionHandler<VitalObservationCreateFormData>>(
    async (data: VitalObservationCreateFormData) => {
      try {
        const results = getMeasuresFromForm(measures, data);
        await onSubmit(results);
        return {};
      } catch (error) {
        logger.error(error);
        return { error };
      }
    },
    [measures, onSubmit]
  );

  const title = createTitle(measure);

  const schema = useMemo(() => createSchema(measures), [measures]);
  const fields = useMemo(() => createFields(measures), [measures]);

  return (
    <Dialog isOpen={isOpen} onRequestClose={onClose} title={title}>
      <CrudForm
        isNotResponsive
        hasCard={false}
        fields={fields}
        onSubmit={handleSubmit}
        buttonText={strings.ButtonTextSubmit}
        onSecondarySubmit={onClose}
        secondaryButtonText={strings.ButtonTextCancel}
        showSecondarySubmitButton
        validationSchema={schema}
        initialValues={initialValues}
        onChangeDebounceTime={0}
      />
    </Dialog>
  );
};
