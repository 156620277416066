import { useTheme } from '@emotion/react';
import { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { FONT_FAMILY, FONT_SIZE } from 'design-system';

export const useCommonStackProps = (): NativeStackNavigationOptions => {
  const theme = useTheme();

  return {
    headerShown: false,
    headerTintColor: theme.color['text-default'],
    headerStyle: {
      backgroundColor: theme.color['base-grey'],
    },
    headerTitleStyle: {
      fontSize: FONT_SIZE.pageHeading,
      fontFamily: FONT_FAMILY.regular,
    },
  };
};
