import styled from '@emotion/native';
import { Flex } from 'components/base';
import { IconName, Icon, Text } from 'design-system/base';
import { BASE_SIZE, BORDER_WIDTH, MARGIN, PADDING } from 'design-system/theme';
import React from 'react';

interface TabProps {
  text: string;
  isActive: boolean;
  icon?: IconName;
  onPress: () => void;
}

interface PressableProps {
  isActive: boolean;
}

const Pressable = styled.Pressable<PressableProps>(({ theme, isActive }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: BASE_SIZE[48],
  marginLeft: MARGIN['2xs'],
  paddingHorizontal: PADDING.xs,
  borderStyle: 'solid',
  borderColor: isActive ? theme.color.primary : 'transparent',
  borderWidth: 0,
  borderBottomWidth: BORDER_WIDTH[4],
}));

const IconWrapper = styled(Flex)({
  marginRight: MARGIN['2xs'],
});

export const Tab: React.FC<TabProps> = ({ isActive, onPress, text, icon }) => (
  <Pressable onPress={onPress} isActive={isActive}>
    <IconWrapper isVisible={!!icon}>
      <Icon name={icon as IconName} size={BASE_SIZE[16]} color="text-default" />
    </IconWrapper>
    <Text variant="button" selectable={false}>
      {text}
    </Text>
  </Pressable>
);
