import styled from '@emotion/native';
import { Row } from 'components/base';
import { SelectOption } from 'components/types';
import { Button, Card, MARGIN } from 'design-system';
import { useList } from 'hooks/useList';
import React, { useState } from 'react';

import { MedicationForm } from './MedicationForm';
import { MedicationUsageFormData } from './types';

interface FormProps {
  handleSubmit: (data: MedicationUsageFormData[]) => Promise<void>;
  medicationFetcher: (query?: string) => Promise<SelectOption[]>;
  title?: string;
}

type MedicationFormState = {
  data: Partial<MedicationUsageFormData>;
  hasErrors: boolean;
  hasSubmitted: boolean;
};

const ButtonWrapper = styled(Row)({
  marginTop: MARGIN['2xs'],
  width: '100%',
  justifyContent: 'flex-end',
});

export const MedicationListForm = ({ handleSubmit, medicationFetcher, title }: FormProps) => {
  const list = useList<number, MedicationFormState>([
    {
      key: Math.random(),
      value: {
        data: {},
        hasErrors: true,
        hasSubmitted: false,
      },
    },
  ]);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  return (
    <Card unsetZIndex>
      {list.items.map((p, index) => {
        const isSubmittedItem = p.value.hasSubmitted || hasSubmitted;

        return (
          <MedicationForm
            key={p.key}
            title={title}
            onAdd={() => {
              list.update(p.key, { ...p.value, hasErrors: false, hasSubmitted: true });
              list.add(Math.random(), {
                data: {},
                hasErrors: true,
                hasSubmitted: false,
              });
            }}
            onChange={(data, hasErrors) =>
              list.update(p.key, {
                hasSubmitted: p.value.hasSubmitted,
                data,
                hasErrors,
              })
            }
            onRemove={() => {
              list.remove(p.key);
            }}
            canAdd={index === list.items.length - 1}
            canRemove={list.items.length > 1}
            showErrors={isSubmittedItem && p.value.hasErrors}
            initialValues={p.value.data}
            medicationFetcher={medicationFetcher}
          />
        );
      })}
      <ButtonWrapper>
        <Button
          variant="contained"
          onPress={() => {
            setHasSubmitted(true);
            const allValid = list.items.every((item) => !item.value.hasErrors);
            if (!allValid) {
              return;
            }

            // casting as the data is known to be valid
            const result = list.items.map((p) => p.value.data as MedicationUsageFormData);
            handleSubmit(result);
          }}
          text={'Submit'}
        />
      </ButtonWrapper>
    </Card>
  );
};
