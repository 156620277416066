import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { ClaimListScreen, ClaimScreen } from 'screens/claim';
import { strings } from 'strings';

import { GroupStackParamList } from './types';

const Stack = createNativeStackNavigator<GroupStackParamList>();

export const ClaimNavigator: React.FC = () => (
  <Stack.Navigator initialRouteName="List">
    <Stack.Screen
      name="List"
      component={ClaimListScreen}
      options={{
        title: strings.ClaimListTitle,
        headerShown: false,
      }}
    />
    <Stack.Screen name="View" component={ClaimScreen} options={{ title: strings.ClaimViewTitle }} />
  </Stack.Navigator>
);
