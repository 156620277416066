import { useFhirData } from 'hooks/useFhirData';

import { fetchers, SupportedResource } from './internal';

export const useFhirDisplay = (resourceType: SupportedResource, id: string): string | undefined => {
  const fetcher = fetchers[resourceType];

  const { data } = useFhirData([resourceType, 'display'], id, fetcher);

  return data;
};
