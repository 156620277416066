import { AuthenticationDetails, DeliveryMethod } from '@quromedical/auth';
import { AxiosError } from 'axios';
import get from 'lodash.get';
import { useCallback, useState, useMemo } from 'react';

import { useUserSession } from './useUserSession';

enum StateName {
  Loading = 'loading',
  Error = 'error',
}

const getResponseErrorMessage = (error: AxiosError) => {
  const errorMessage = get(error, 'response.data.message') as string;
  return errorMessage || 'An error occurred';
};

export const useAuthentication: UseAuthentication = () => {
  const { authClient, getSession } = useUserSession();
  const [state, setState] = useState<AuthState>();

  const startAuthentication = useCallback(
    async (input: StartAuthenticationInput) => {
      const { data, onComplete } = input;
      const { mode, username } = data;
      if (!authClient) {
        return;
      }
      try {
        setState({ name: StateName.Loading });
        const details = await authClient.startAuthentication(mode, username);
        onComplete(details);
        setState(undefined);
      } catch (error) {
        const message = getResponseErrorMessage(error as AxiosError);

        setState({ name: StateName.Error, message });
      }
    },
    [authClient]
  );

  const completeAuthentication = useCallback(
    async (input: CompleteAuthenticationInput) => {
      const { data, onError } = input;
      const { code, mode, username } = data;
      if (!authClient) {
        return;
      }
      setState({ name: StateName.Loading });
      try {
        await authClient.completeAuthentication(mode, username, code);
        await getSession();
        setState(undefined);
      } catch (error) {
        if (onError) {
          onError();
        }
        const message = getResponseErrorMessage(error as AxiosError);

        setState({ name: StateName.Error, message });
      }
    },
    [authClient, getSession]
  );

  const isLoading = useMemo(() => state?.name === StateName.Loading, [state]);
  const error = useMemo(() => {
    const isError = state?.name === StateName.Error;

    return isError ? state.message : undefined;
  }, [state]);

  return { startAuthentication, completeAuthentication, state, isLoading, error };
};

interface AuthInput {
  mode: DeliveryMethod;
  username: string;
}

interface AuthCompleteInput extends AuthInput {
  code: string;
}

interface StartAuthenticationInput {
  onComplete: (details: AuthenticationDetails) => void;
  data: AuthInput;
}

interface CompleteAuthenticationInput {
  onComplete?: () => void;
  onError?: () => void;
  data: AuthCompleteInput;
}

interface UseAuthenticationResponse {
  startAuthentication: (input: StartAuthenticationInput) => void;
  completeAuthentication: (input: CompleteAuthenticationInput) => void;
  state?: AuthState;
  isLoading: boolean;
  error?: string;
}

interface LoadingState {
  name: StateName.Loading;
}
interface ErrorState {
  name: StateName.Error;
  message: string;
}

type AuthState = LoadingState | ErrorState;
type UseAuthentication = () => UseAuthenticationResponse;
