import { handleDownload } from 'core/media';
import { Button } from 'design-system';
import { noOp } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';

interface AsyncProps {
  fetchUrl: () => Promise<string | undefined>;
  title?: string;
}

interface ButtonProps {
  onPress: () => void;
  title?: string;
}

type Step = 'initial' | 'loading' | 'error' | 'success';

const LoadingButton: React.FC = () => (
  <Button onPress={noOp} disabled icon="refresh" text="Loading.." />
);

const SuccessButton: React.FC<ButtonProps> = ({ onPress }) => (
  <Button variant="outlined" onPress={onPress} text="Download Complete" />
);

const ErrorButton: React.FC<ButtonProps> = ({ onPress }) => (
  <Button text="Error, please try again" onPress={onPress} />
);

const InitialButton: React.FC<ButtonProps> = ({ onPress, title = 'Download file' }) => (
  <Button text={title} onPress={onPress} />
);

const resetDurationMs = 5000;

export const AsyncDownloadButton: React.FC<AsyncProps> = ({ fetchUrl, title }) => {
  const [step, setStep] = useState<Step>('initial');

  useEffect(() => {
    if (step === 'error' || step === 'success') {
      const timer = setTimeout(() => setStep('initial'), resetDurationMs);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [step]);

  const onPress = useCallback(async () => {
    setStep('loading');

    try {
      const url = await fetchUrl();
      if (url) {
        await handleDownload(url);
        setStep('success');
      } else {
        setStep('error');
      }
    } catch {
      setStep('error');
    }
  }, [fetchUrl]);

  switch (step) {
    case 'initial':
      return <InitialButton onPress={onPress} title={title} />;

    case 'loading':
      return <LoadingButton />;

    case 'error':
      return <ErrorButton onPress={onPress} />;

    case 'success':
      return <SuccessButton onPress={onPress} />;
  }
};
