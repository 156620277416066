import { CrudForm } from 'components/form';
import React, { useCallback, useState } from 'react';

import { initialSearch, Search, searchFields } from './fields';

interface DeviceUseStatementSearchFormProps {
  onSearchChange: (search: Search) => void;
}

export const DeviceUseStatementSearchForm: React.FC<DeviceUseStatementSearchFormProps> = ({
  onSearchChange,
}) => {
  const [search, setSearch] = useState<Search>(initialSearch);

  const onChange = useCallback(
    (value: Search) => {
      onSearchChange(value);
      setSearch(value);
    },
    [onSearchChange]
  );

  const fields = searchFields(search.code);

  return (
    <CrudForm<Search>
      initialValues={initialSearch}
      onChange={onChange}
      fields={fields}
      showSubmitButton={false}
      // we can have an instant select since we only have dropdowns for searching
      onChangeDebounceTime={0}
    />
  );
};
