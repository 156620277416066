import React from 'react';
import { Rect } from 'react-native-svg';

import { usePlotDataContext } from './PlotDataProvider';
import { ExternalComponentProps } from './types';

interface BorderProps {
  strokeWidth?: number;
  stroke?: string;
  opacity?: number;
}

type ExternalProps = ExternalComponentProps & BorderProps;

export const Border: React.FC<ExternalProps> = (props) => {
  const {
    margin,
    baseWidth,
    baseHeight,
    stroke = 'white',
    strokeWidth = 1,
    opacity = 1,
  } = usePlotDataContext(props);

  const height = baseHeight - margin.bottom - margin.top;
  const width = baseWidth - margin.right - margin.left;

  return (
    <Rect
      x={margin.left}
      y={margin.top}
      width={width}
      height={height}
      fill="none"
      stroke={stroke}
      strokeWidth={strokeWidth}
      opacity={opacity}
    />
  );
};
