import { Practitioner, Validations } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { Field } from 'components/types';
import { FormikErrors } from 'formik';
import { clinicalGroupFetcher } from 'integration';
import React, { useCallback } from 'react';
import { strings } from 'strings';

interface PractitionerCreateInitialGroupFormProps {
  onValidChange: (data?: Practitioner.CreateInitialGroup) => void;
  showErrors: boolean;
}

const fields: Field<Practitioner.CreateInitialGroup>[] = [
  {
    subfields: [
      {
        type: 'combobox-single',
        searchable: true,
        label: 'Group',
        key: 'group',
        fetcher: clinicalGroupFetcher,
      },
    ],
  },
];

export const PractitionerCreateInitialGroupForm: React.FC<
  PractitionerCreateInitialGroupFormProps
> = ({ onValidChange, showErrors }) => {
  const onChange = useCallback(
    (
      data: Partial<Practitioner.CreateInitialGroup>,
      _: FormikErrors<Practitioner.CreateInitialGroup>,
      hasErrors: boolean
    ) => {
      if (hasErrors) {
        onValidChange(undefined);
      } else {
        // casting since data is validated
        const result = data as Practitioner.CreateInitialGroup;

        onValidChange(result);
      }
    },
    [onValidChange]
  );

  return (
    <CrudForm
      title={strings.FormTitlePractitionerInitialGroup}
      fields={fields}
      initialValues={{}}
      validationSchema={Validations.practitionerCreateSchema.fields.initialGroup}
      onChange={onChange}
      showErrors={showErrors}
      validateOnMount
      showSubmitButton={false}
      hasCard={false}
    />
  );
};
