import React from 'react';

interface VisibleProps {
  /**
   * Condition to evaluate. If truthy will return the component
   */
  if?: any;
}

/**
 * Render a component if the `if` prop evaluates to true, ensures we render `null` in cases where
 * the result is undefined to prevent crashing on native
 */
export const Visible: React.FC<VisibleProps> = ({ if: cond, children }) => {
  if (!cond) {
    return null;
  }

  return <>{children}</>;
};
