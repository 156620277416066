import { DeviceUseStatementCode } from '@quromedical/fhir-common';

import { Bundle } from './Bundle';
import { FhirBaseApi } from './FhirBaseApi';

export interface Params {
  device?: string;
  subject?: string;
  count?: number;
  sort?: '-date';
  pageToken?: string;
  code?: DeviceUseStatementCode;
  status?: fhir4.DeviceUseStatement['status'];
}

export class DeviceUseStatementApi extends FhirBaseApi<fhir4.DeviceUseStatement, Params> {
  constructor() {
    super('api/fhir/device-use-statement');
  }

  createDeviceUseStatement = async (
    deviceUseStatement: fhir4.DeviceUseStatement
  ): Promise<fhir4.DeviceUseStatement> => this.post(deviceUseStatement);

  getDeviceUseStatements = async (params: Params): Promise<Bundle<fhir4.DeviceUseStatement>> =>
    this.getAll({
      count: 20,
      ...params,
    });

  terminateDeviceUseStatement = async (id: string): Promise<void> => {
    const path = this.getPath(id, 'terminate');
    await this.client.put(path, {});
  };

  cancelDeviceUseStatement = async (id: string): Promise<void> => {
    const path = this.getPath(id, 'cancel');
    await this.client.put(path, {});
  };
}
