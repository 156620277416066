import { Gender } from '@quromedical/fhir-common';
import { Patient } from '@quromedical/models';
import { useLinkTo } from '@react-navigation/native';
import { CrudForm } from 'components/form';
import { ColumnType, SortConfig, useTableState } from 'components/table';
import { Field } from 'components/types';
import { patientStatusDisplay } from 'core/display';
import { mapDisplaysToOptionsWithStart, genderDisplay } from 'core/forms';
import { PaginatedTable } from 'core/table';
import { Section, Sidebar } from 'design-system/layout';
import { PatientApi } from 'integration/aggregate';
import omitBy from 'lodash.omitby';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';

import { Header } from './Header';

const api = new PatientApi();

const columns: ColumnType<TablePatient>[] = [
  {
    accessor: 'givenName',
    header: strings.GivenName,
    disableSortBy: false,
  },
  {
    accessor: 'familyName',
    header: strings.FamilyName,
    disableSortBy: false,
  },
  {
    accessor: 'gender',
    header: strings.Gender,
    Cell: ({ value = 'unknown' }) => genderDisplay[value] || '',
  } as ColumnType<TablePatient, 'gender'>,
];

type Filter = Pick<Patient.GetAllParams, 'status' | 'name'>;

const all = '__all__';

const allOption = {
  value: all,
  display: strings.SortFilterAll,
};

const filterFields: Field<Filter>[] = [
  {
    subfields: [
      {
        key: 'status',
        type: 'combobox-single',
        label: strings.LabelStatus,
        fetcher: mapDisplaysToOptionsWithStart(allOption, patientStatusDisplay),
      },
    ],
  },
];

const shouldOmitOption = (value?: string): boolean => {
  if (!value?.length) {
    return true;
  }

  return value === all;
};

const sanitizeFilter = (data: Filter) => omitBy(data, shouldOmitOption);

type TablePatient = {
  id: string;
  givenName?: string;
  familyName?: string;
  gender?: Gender;
};

const sortConfig: SortConfig<TablePatient> = {
  givenName: {
    asc: 'given',
    desc: '-given',
  },
  familyName: {
    asc: 'family',
    desc: '-family',
  },
};

const getDataFromResponse = (res: Patient.GetAllResponse): TablePatient[] =>
  res.patients.map((p) => ({
    id: p.id,
    givenName: p.general.givenName,
    familyName: p.general.familyName,
    gender: p.general.gender,
  }));

export const PatientListScreen: React.FC<{}> = () => {
  const sidebarProps = Sidebar.useResponsiveSidebarProps();
  const navigate = useLinkTo();
  const [filter, setFilter] = useState<Filter>({});

  const setSearch = useCallback((name?: string) => setFilter({ ...filter, name }), [filter]);

  const tableState = useTableState<TablePatient>({ sort: sortConfig });

  const cacheKey = [api.getPatientSWRKey('list'), filter, tableState.sort];

  const fetcher = useCallback(
    (pageToken?: string) =>
      api.getPatients({
        ...sanitizeFilter(filter),
        pageToken,
        count: 20,
        sort: tableState.sort,
      }),
    [filter, tableState.sort]
  );

  const onRowPress = useCallback(
    (row: TablePatient) =>
      navigate({
        screen: 'Patient',
        params: {
          screen: 'View',
          params: {
            screen: 'Profile',
            id: row.id,
          },
        },
      }),
    [navigate]
  );

  return (
    <Sidebar.Container>
      <Sidebar.Main {...sidebarProps.main}>
        <Section>
          <Header onSearchChange={setSearch} screen="patients" />
        </Section>
        <Section hasBottomMargin>
          <PaginatedTable
            cacheKey={cacheKey}
            fetcher={fetcher}
            getDataFromResponse={getDataFromResponse}
            onRowPress={onRowPress}
            onTableStateChanged={tableState.onChange}
            columns={columns}
          />
        </Section>
      </Sidebar.Main>
      <Sidebar.Aside {...sidebarProps.aside}>
        <CrudForm<Filter>
          onChangeDebounceTime={0}
          showSubmitButton={false}
          onChange={setFilter}
          initialValues={{}}
          title={strings.FormTitleSearch}
          fields={filterFields}
        />
      </Sidebar.Aside>
    </Sidebar.Container>
  );
};
