import { GroupSecurity } from '@quromedical/fhir-common';
import { Medication } from '@quromedical/models';
import { ConfirmationButton } from 'components/input';
import { ColumnType } from 'components/table';
import { WithGroup } from 'core/auth';
import { RowConfig } from 'design-system';
import { FormCard, Text } from 'design-system';
import { logger } from 'helpers';
import { useRevalidation } from 'hooks/useRevalidation';
import { MedicationApi } from 'integration/aggregate';
import { compact } from 'lodash';
import { useCallback } from 'react';
import { strings } from 'strings';

export interface MedicationTableData {
  name: string;
  nappiCode: string;
  strength: string;
  volume: string;
  route: string;
  repeat: string;
  id: string;
}

export type MedicationRequestTableData = Medication.GroupedRequest;

const api = new MedicationApi();

interface UpdateMedicationStatusButtonProps {
  patientId: string;
  admissionId: string;
  medicationId: string;
}

const UpdateMedicationStatusButton: React.FC<UpdateMedicationStatusButtonProps> = ({
  admissionId,
  medicationId,
  patientId,
}) => {
  const revalidate = useRevalidation('Medication');

  const onSubmit = useCallback(async () => {
    await api.updateMedicationStatus({
      patientId,
      admissionId,
      medicationId,
      body: { status: 'entered-in-error' },
    });
    revalidate().catch(logger.error);
  }, [patientId, admissionId, medicationId, revalidate]);

  return (
    <ConfirmationButton
      status="status-critical"
      title={strings.MedicationConfirmationButtonTitle}
      message={strings.MedicationConfirmationButtonMessage}
      onSubmit={onSubmit}
      buttonText={strings.MedicationConfirmationButtonText}
    />
  );
};

export const baseMedicationColumns: ColumnType<MedicationTableData>[] = [
  {
    header: strings.MedicationNameSubheading,
    accessor: 'name',
  } as ColumnType<MedicationTableData, 'name'>,
  {
    header: strings.MedicationNappiCodeSubheading,
    accessor: 'nappiCode',
  } as ColumnType<MedicationTableData, 'nappiCode'>,
  {
    header: strings.MedicationRepeatSubheading,
    accessor: 'repeat',
  } as ColumnType<MedicationTableData, 'repeat'>,
  {
    header: strings.MedicationStrengthSubheading,
    accessor: 'strength',
  } as ColumnType<MedicationTableData, 'strength'>,
  {
    header: strings.MedicationVolumeSubheading,
    accessor: 'volume',
  } as ColumnType<MedicationTableData, 'volume'>,
  {
    header: strings.MedicationRouteSubheading,
    accessor: 'route',
  } as ColumnType<MedicationTableData, 'route'>,
];

export const medicationStatementColumns = (
  patientId: string,
  admissionId: string
): ColumnType<MedicationTableData>[] => [
  ...baseMedicationColumns,
  {
    accessor: 'id',
    header: strings.MedicationActionsSubheading,
    Cell: (value) => (
      <WithGroup groups={[GroupSecurity.MedicationStatementUpdate]}>
        <UpdateMedicationStatusButton
          patientId={patientId}
          admissionId={admissionId}
          medicationId={value.row.original.id}
        />
      </WithGroup>
    ),
  } as ColumnType<MedicationTableData, 'id'>,
];

export const medicationRequestColumns: ColumnType<MedicationRequestTableData>[] = [
  {
    header: strings.MedicationRequestHeading,
    Cell: ({ value = [], row }) => <FormCard rows={getMedicationRows(value, row.original.date)} />,
    accessor: 'request',
  } as ColumnType<MedicationRequestTableData, 'request'>,
];

const getMeasureDisplay = (measure?: Medication.Measure) => {
  if (!(measure?.value && measure?.unit)) {
    return strings.ValueNotAvailable;
  }
  return `${measure.value} ${measure.unit}`;
};

const getRepeatDisplay = (repeat?: Medication.Repeat) => {
  if (!repeat) {
    return strings.ValueNotAvailable;
  }
  const { frequency, period, periodUnit } = repeat;
  return `${frequency} / ${period} ${periodUnit}`;
};

const getRouteDescription = (route?: Medication.RouteCode) => {
  if (!route) {
    return strings.ValueNotAvailable;
  }

  return Medication.RouteCodeDescription[route];
};

export const getDataFromResponse = (
  res: Medication.GetAllResponse
): Partial<MedicationTableData>[] =>
  res.medicationUsages.map((p) => ({
    id: p.id,
    name: p.medication.name,
    nappiCode: p.medication.nappiCode,
    repeat: getRepeatDisplay(p.dosage.timingRepeat),
    strength: getMeasureDisplay(p.medication.strength),
    volume: getMeasureDisplay(p.medication.volume),
    route: getRouteDescription(p.dosage.route),
    status: p.status,
  }));

export const getRequestDataFromResponse = (
  res: Medication.GetAllRequestsResponse
): Partial<MedicationRequestTableData>[] => {
  return res.groupedRequests;
};

export const getMedicationRows = (
  groupedRequest: Medication.GetRequestResponse[],
  date?: string
): RowConfig[] => {
  const fields: RowConfig[] = groupedRequest.map((req) => ({
    fields: [
      {
        type: 'custom',
        Display: () => <Text variant="body-strong">{req?.medication?.name}</Text>,
      },
      {
        type: 'custom',
        Display: () => (
          <Text variant="body-strong">{getMeasureDisplay(req?.medication?.strength)}</Text>
        ),
      },
      {
        type: 'custom',
        Display: () => (
          <Text variant="body-strong">{getRepeatDisplay(req?.dosage?.timingRepeat)}</Text>
        ),
      },
      {
        type: 'custom',
        Display: () => <Text variant="body-strong">{getRouteDescription(req?.dosage?.route)}</Text>,
      },
      {
        type: 'custom',
        Display: () => <Text variant="body-strong">{req?.medication?.schedule?.toString()}</Text>,
      },
    ],
  }));

  return compact([
    {
      fields: [
        {
          type: 'text',
          label: strings.DateLabel,
          display: date,
        },
      ],
    },
    {
      fields: [
        {
          type: 'text',
          label: strings.LabelName,
        },
        {
          type: 'text',
          label: strings.MedicationStrengthSubheading,
        },
        {
          type: 'text',
          label: strings.MedicationDosageSubheading,
        },
        {
          type: 'text',
          label: strings.MedicationRouteSubheading,
        },
        {
          type: 'text',
          label: strings.MedicationScheduleSubheading,
        },
      ],
    },
    ...fields,
  ]);
};
