import { useLinkTo } from '@react-navigation/native';
import { Col, Flex, Row } from 'components/base';
import { TextBox, ToggleButton } from 'design-system';
import { useIsSmallerThan } from 'hooks/useResponsive';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';

type Screen = 'admissions' | 'patients';

interface HeaderProps {
  onSearchChange: (query?: string) => void;
  screen: Screen;
}

export const Header: React.FC<HeaderProps> = ({ onSearchChange, screen }) => {
  const [search, setSearch] = useState<string>('');
  const navigate = useLinkTo();

  const onChange = (value: string) => {
    setSearch(value);

    const trimmed = value.trim();
    if (!trimmed) {
      onSearchChange(undefined);
    } else {
      onSearchChange(trimmed);
    }
  };

  const handleChange = useCallback(
    (value: Screen) => {
      if (value === screen) {
        return;
      }

      const toScreen = value === 'patients' ? 'PatientList' : 'AdmissionList';

      navigate({
        screen: 'Patient',
        params: {
          screen: toScreen,
        },
      });
    },
    [navigate, screen]
  );

  const flexDirection = useIsSmallerThan('sm') ? 'column' : 'row';

  return (
    <Flex flexDirection={flexDirection} alignItems="center" justifyContent="space-between" flex={1}>
      <Col flex={1}>
        <ToggleButton<Screen>
          value={screen}
          onChange={handleChange}
          options={[
            {
              value: 'admissions',
              display: strings.ToggleDisplayAdmissions,
            },
            {
              value: 'patients',
              display: strings.ToggleDisplayPatients,
            },
          ]}
        />
      </Col>

      <Col flex={1} fullWidth>
        <Row>
          <TextBox
            onChange={onChange}
            value={search}
            placeholder={strings.TextBoxPlaceholderSearch}
          />
        </Row>
      </Col>
    </Flex>
  );
};
