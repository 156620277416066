import styled from '@emotion/native';
import { createFhirDateTime } from '@quromedical/fhir-common';
import { Col, Row } from 'components/base';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Visible, Button } from 'design-system/base';
import { BASE_SIZE, MARGIN } from 'design-system/theme';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { strings } from 'strings';

import { Dialog } from '../dialog';
import { ToggleButton } from '../input';
import {
  Duration,
  CustomForm,
  LatestForm,
  modeOptions,
  getCustomFields,
  customFormSchema,
  getLatestFields,
  latestFormSchema,
  Mode,
} from './fields';
import {
  getBoundaryTimes,
  getStartFromDuration,
  getDurationDisplay,
  durationToMilliseconds,
} from './time';

const ValueWrapper = styled(Col)({
  marginRight: MARGIN.s,
});

const FieldRow = styled(Row)({
  height: BASE_SIZE[48],
});

type BaseState = {
  isOpen: boolean;
};

type LatestState = BaseState & {
  mode: 'latest';
  duration: Duration;
  from: undefined;
};

type CustomState = BaseState & {
  mode: 'custom';
  duration: Duration;
  from: Date;
};

type PickerState = LatestState | CustomState;

export interface DurationResult {
  from?: Date;
  duration: number;
}

interface DurationPickerProps {
  initial?: Duration;
  from?: Date;
  mode?: Mode;
  options?: Duration[];
  isCondensed?: boolean;
  onChange: (result: DurationResult) => void;
}

export const DurationPicker: React.FC<DurationPickerProps> = ({
  initial = '5m',
  isCondensed = false,
  onChange,
  options,
  from,
}) => {
  const [state, setState] = useState<PickerState>(
    from
      ? {
          mode: 'custom',
          isOpen: false,
          duration: initial,
          from,
        }
      : {
          mode: 'latest',
          isOpen: false,
          duration: initial,
          from: undefined,
        }
  );

  const [start, end] = getBoundaryTimes(state.duration, new Date(), state.from);
  const durationDisplay = getDurationDisplay(start, end, state.duration, isCondensed);

  const customText = state.from ? durationDisplay : strings.DurationLabelCustom;

  const handleModeChange = useCallback(
    (mode: Mode) => {
      if (mode === 'custom') {
        setState({
          mode: 'custom',
          isOpen: true,
          duration: state.duration,
          from: getStartFromDuration(state.duration, new Date()),
        });
      } else {
        setState({
          mode: 'latest',
          isOpen: true,
          duration: state.duration,
          from: undefined,
        });
      }
    },
    [state.duration]
  );

  const handleDialogClose = useCallback(() => {
    setState({ ...state, isOpen: false });
  }, [state]);

  const handleCustomSubmit = useCallback<SubmissionHandler<CustomForm>>((data: CustomForm) => {
    setState({
      mode: 'custom',
      isOpen: false,
      duration: data.duration,
      // we can assume that a parsable data was found since the date was submitted
      from: new Date(data.from),
    });
    return {};
  }, []);

  const handleLatestSubmit = useCallback<SubmissionHandler<LatestForm>>((data: LatestForm) => {
    setState({
      mode: 'latest',
      isOpen: false,
      duration: data.duration,
      from: undefined,
    });
    return {};
  }, []);

  const openDialog = useCallback(() => {
    setState({
      ...state,
      isOpen: true,
    });
  }, [state]);

  useEffect(() => {
    // don't call change if the dialog is open since selection is still in progress
    if (state.isOpen) {
      return;
    }

    onChange({
      duration: durationToMilliseconds[state.duration],
      from: state.from,
    });
  }, [onChange, state]);

  const customFields = useMemo(() => getCustomFields(options), [options]);
  const latestFields = useMemo(() => getLatestFields(options), [options]);

  return (
    <>
      <Col flex={1} unsetZIndex>
        <FieldRow alignItems="flex-start" justifyContent="flex-start" unsetZIndex>
          <ValueWrapper alignItems="flex-start" justifyContent="flex-start">
            <Visible if={state.mode === 'latest'}>
              <Button variant="flat" text={state.duration} onPress={openDialog} icon="timer" />
            </Visible>
            <Visible if={state.mode === 'custom'}>
              <Button variant="flat" text={customText} onPress={openDialog} icon="calendar-today" />
            </Visible>
          </ValueWrapper>
          <Row alignItems="flex-start" justifyContent="flex-start">
            <ToggleButton<Mode>
              options={modeOptions}
              onChange={handleModeChange}
              value={state.mode}
            />
          </Row>
        </FieldRow>
      </Col>
      <Dialog
        onRequestClose={handleDialogClose}
        isOpen={state.isOpen && state.mode === 'custom'}
        title={strings.DurationCustomSelectTimeFrame}
      >
        <CrudForm<CustomForm>
          isNotResponsive
          hasCard={false}
          fields={customFields}
          onSubmit={handleCustomSubmit}
          buttonText={strings.ButtonTextSubmit}
          onSecondarySubmit={handleDialogClose}
          secondaryButtonText={strings.ButtonTextCancel}
          showSecondarySubmitButton
          validationSchema={customFormSchema}
          initialValues={{
            duration: state.duration,
            from: createFhirDateTime(state.from || new Date()),
          }}
        />
      </Dialog>
      <Dialog
        onRequestClose={handleDialogClose}
        isOpen={state.isOpen && state.mode === 'latest'}
        title={strings.DurationCustomSelectTimeFrame}
      >
        <CrudForm<LatestForm>
          isNotResponsive
          hasCard={false}
          fields={latestFields}
          onSubmit={handleLatestSubmit}
          buttonText={strings.ButtonTextSubmit}
          onSecondarySubmit={handleDialogClose}
          secondaryButtonText={strings.ButtonTextCancel}
          showSecondarySubmitButton
          validationSchema={latestFormSchema}
          initialValues={{
            duration: state.duration,
          }}
        />
      </Dialog>
    </>
  );
};
