import { Bundle } from '@quromedical/fhir-common';

import { FhirBaseApi } from './FhirBaseApi';

export interface Params {
  count?: number;
  pageToken?: string;
  subject?: string;
}

interface PathologyTestSearchParams {
  query?: string;
  specialOrder?: boolean;
  commonOnly?: boolean;
  limit?: number;
}

export interface PathologyTestSearchResult {
  description: string;
  code: string;
  department: string;
  /**
   * If the test needs to be on a separate order
   */
  specialOrder: boolean;
  common: boolean;
}

export class ServiceRequestApi extends FhirBaseApi<fhir4.ServiceRequest> {
  constructor() {
    super('api/fhir/service-request');
  }

  getServiceRequests = async (params: Params): Promise<Bundle<fhir4.ServiceRequest>> =>
    this.getAll(params);

  createAmpathRequest = async (req: fhir4.ServiceRequest): Promise<fhir4.ServiceRequest> => {
    const path = this.getPath('pathology/ampath');
    const result = await this.client.post<fhir4.ServiceRequest>(path, req);
    return result.data;
  };

  searchAmpathTests = async (
    params: PathologyTestSearchParams
  ): Promise<PathologyTestSearchResult[]> => {
    const path = this.getPath('pathology/ampath/search-tests');
    const result = await this.client.get<PathologyTestSearchResult[]>(path, {
      params,
    });

    return result.data;
  };
}
