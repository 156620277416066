import { Grid } from 'design-system/layout';
import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Measure, Measurement } from './Measure';

interface StatusGridProps<T extends string> {
  measurements: Measurement<T>[];
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  onSelect?: (key: T) => void;
}

export const StatusGrid = <T extends string>({
  measurements,
  sm = 3,
  md = 4,
  lg = 6,
  xl = 8,
  onSelect,
}: StatusGridProps<T>) => {
  return (
    <Grid sm={sm} md={md} lg={lg} xl={xl}>
      {measurements.map((val) => (
        <TouchableOpacity key={val.name} onPress={() => onSelect?.(val.key)}>
          <Measure {...val} />
        </TouchableOpacity>
      ))}
    </Grid>
  );
};
