// converted using SVGR with React-Native and Typescript Options
// https://react-svgr.com/playground/?native=true&typescript=true
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { Icon } from './internal';

export const Heart: Icon = ({ color = '#000000', size = 24 }) => (
  <Svg width={size} height={size} fill="none" viewBox="0 0 24 24">
    <Path
      d="M19.055 3.417a5.5 5.5 0 0 1 2.978 7.188 5.5 5.5 0 0 1-1.193 1.785l-1.06 1.06L12 21.23l-7.78-7.78-1.06-1.06a5.501 5.501 0 0 1 7.78-7.78L12 5.67l1.06-1.06a5.5 5.5 0 0 1 5.995-1.193Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
