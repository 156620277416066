import { Medication } from '@quromedical/models';
import _ from 'lodash';
import { strings } from 'strings';

export type MedicationUsageFormData = Medication.CreateMedicationUsage;
export type MedicationRequestFormData = Medication.CreateMedicationRequestData;

export enum MedicationRepeat {
  OncePerDay = 'once-per-day',
  TwicePerDay = 'twice-per-day',
  ThreeTimesPerDay = 'three-times-per-day',
  EveryFourHours = 'every-four-hours',
  EverySixHours = 'every-six-hours',
  AsNeeded = 'as-needed',
}

export enum MedicationDuration {
  SevenDays = '7-days',
  FourteenDays = '14-days',
  OneMonth = '28-days',
}

export const RepeatDescription: Record<MedicationRepeat, Medication.Repeat> = {
  [MedicationRepeat.OncePerDay]: { frequency: 1, period: 1, periodUnit: 'd' },
  [MedicationRepeat.TwicePerDay]: { frequency: 2, period: 1, periodUnit: 'd' },
  [MedicationRepeat.ThreeTimesPerDay]: { frequency: 3, period: 1, periodUnit: 'd' },
  [MedicationRepeat.EveryFourHours]: { frequency: 1, period: 4, periodUnit: 'h' },
  [MedicationRepeat.EverySixHours]: { frequency: 1, period: 6, periodUnit: 'h' },
  [MedicationRepeat.AsNeeded]: { frequency: 0, period: 0, periodUnit: undefined },
};

export const medicationFrequencyDisplay: Record<MedicationRepeat, string> = {
  [MedicationRepeat.OncePerDay]: strings.MedicationFrequencyDaily,
  [MedicationRepeat.TwicePerDay]: strings.MedicationFrequencyBiDaily,
  [MedicationRepeat.ThreeTimesPerDay]: strings.MedicationFrequencyThreeTimesADay,
  [MedicationRepeat.EveryFourHours]: strings.MedicationFrequency4Hourly,
  [MedicationRepeat.EverySixHours]: strings.MedicationFrequency6Hourly,
  [MedicationRepeat.AsNeeded]: strings.MedicationFrequencyAsNeeded,
};
export const DurationDescription: Record<MedicationDuration, Medication.Duration> = {
  [MedicationDuration.SevenDays]: { value: 7, unit: 'd' },
  [MedicationDuration.FourteenDays]: { value: 14, unit: 'd' },
  [MedicationDuration.OneMonth]: { value: 28, unit: 'd' },
};

export const medicationDurationDisplay: Record<MedicationDuration, string> = {
  [MedicationDuration.SevenDays]: strings.MedicationPrescriptionLength7Days,
  [MedicationDuration.FourteenDays]: strings.MedicationPrescriptionLength14Days,
  [MedicationDuration.OneMonth]: strings.MedicationPrescriptionLength1Month,
};

const allRouteCodes = Object.keys(Medication.RouteCodeDescription) as Medication.RouteCode[];

const routeCodesToExclude: Medication.RouteCode[] = [
  'UNI',
  'CPS',
  'VAG',
  'PAI',
  'SCR',
  'WAF',
  'SWB',
  'IMP',
  'KIT',
  'AMP',
  'PAT',
  'BDL',
  'TUL',
  'PLA',
  'BAN',
  'GAU',
  'VRI',
  'VIA',
  'MET',
  'TES',
  'CLS',
  'SNG',
  'COT',
  'BRA',
  'AQS',
  'DEV',
  'NDL',
  'SUT',
  'SWS',
  'PVC',
  'NSS',
  'BAS',
  'PAP',
  'SLW',
  'SPC',
  'SCT',
  'NIC',
  'PSD',
  'SCL',
  'TPN',
  'MC2',
  'DRE',
  'MC3',
  'GAS',
  'MC1',
  'PTD',
  'CAT',
  'CES',
  'DES',
  'DEB',
  'BIS',
  'CPC',
  'GRE',
  'GUS',
  'JES',
  'OIR',
  'SLL',
  'STI',
  'OGS',
  'JUT',
  'REN',
  'HEA',
  'ODF',
  'ZZZ',
  'PLS',
  'CSM',
  'FLS',
];

export const includedRouteCodes = _.difference(allRouteCodes, routeCodesToExclude);
