import { PrimitiveValueType, SelectOption, ValueType } from 'components/types';
import { ItemType as DropdownItemType } from 'react-native-dropdown-picker';

export type SyncFetcher<TOptionValue extends ValueType, TData = undefined> = (
  query?: string
) => SelectOption<TData, TOptionValue>[];

export type AsyncFetcher<TOptionValue extends ValueType, TData = undefined> = (
  query?: string
) => Promise<SelectOption<TData, TOptionValue>[]>;

export type ObjectFetcher<TOptionValue extends ValueType, TData = undefined> = SelectOption<
  TData,
  TOptionValue
>[];

export type Fetcher<TOptionValue extends ValueType, TData = undefined> =
  | ObjectFetcher<TOptionValue, TData>
  | SyncFetcher<TOptionValue, TData>
  | AsyncFetcher<TOptionValue, TData>;

export const mapOptionsToItems = <TValue extends PrimitiveValueType>(
  options: SelectOption<unknown, TValue>[]
): DropdownItemType<TValue>[] =>
  options.map<DropdownItemType<TValue>>((option) => ({
    label: option.display,
    value: option.value,
    disabled: option.isDisabled,
  }));
