import { Person } from '@quromedical/models';
import { useCallback, useState } from 'react';

type PartialContact = Partial<Person.ContactWithAddressEmergencyAccess>;
type onContactChange = (contact: PartialContact) => void;

interface AddressAutoFillResult {
  placeId?: string;
  onContactChange: onContactChange;
}

export const useAddressAutoFill = (
  onResolvedChange: onContactChange,
  getAddressPartial?: (
    placeId: string
  ) => Promise<Partial<Person.ContactWithAddressEmergencyAccess>>
): AddressAutoFillResult => {
  const [placeId, setPlaceId] = useState<string>();
  const onContactChange = useCallback(
    async (updatedData: PartialContact) => {
      const { addressPlaceId } = updatedData;

      const isSame = addressPlaceId === placeId;
      const isUnset = !addressPlaceId;
      const noChange = isSame || isUnset;
      const cantFetchAddress = !getAddressPartial;

      if (noChange || cantFetchAddress) {
        onResolvedChange(updatedData);
      } else {
        const addressParts = await getAddressPartial(addressPlaceId);

        const contact: PartialContact = {
          ...updatedData,
          ...addressParts,
        };

        onResolvedChange(contact);
      }

      setPlaceId(addressPlaceId);
    },
    [placeId, getAddressPartial, onResolvedChange]
  );

  return { onContactChange, placeId };
};
