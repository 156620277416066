import { MeasurementName } from '@quromedical/models';
import { mergeObjects } from 'helpers';

interface WithTS {
  ts: number;
}

/**
 * Merge latest data, preferring measures with the latest timestamp
 */
export const mergeLatestByTime =
  <TKey extends string>(keys: TKey[]) =>
  <TData extends WithTS>(
    latestA: Partial<Record<TKey, TData>> = {},
    latestB: Partial<Record<TKey, TData>> = {}
  ): Partial<Record<TKey, TData>> => {
    const parts = keys.map((key) => {
      const measureA: TData | undefined = latestA[key];
      const measureB: TData | undefined = latestB[key];

      const hasBoth = measureA && measureB;

      if (!hasBoth) {
        return {
          // if we don't have both then settle for whichever one we do have
          [key]: measureA || measureB,
        };
      }

      const isAOlder = measureA.ts < measureB.ts;

      const result = isAOlder ? measureB : measureA;

      return {
        [key]: result,
      };
    });

    // for some reason typescript doesn't seem to be inferring parts correctly (can't infer key)
    return mergeObjects<Partial<Record<TKey, TData>>>(parts as Partial<Record<TKey, TData>>[], {});
  };

const measureNames = Object.values(MeasurementName);

export const mergeLatestMeasuresByTime = mergeLatestByTime(measureNames);
