import styled from '@emotion/native';
import { PADDING } from 'design-system/theme';
import React, { ElementType } from 'react';

import { Wrapper } from '../internal';

export interface CustomProps {
  Display: ElementType;
  label?: string;
  background?: boolean;
  paddingHorizontal?: boolean;
  paddingVertical?: boolean;
}

interface PaddedProps {
  paddingHorizontal?: boolean;
  paddingVertical?: boolean;
}

const Padded = styled.View<PaddedProps>(({ paddingHorizontal, paddingVertical }) => ({
  paddingHorizontal: paddingHorizontal ? PADDING.xs : 0,
  paddingVertical: paddingVertical ? PADDING['2xs'] : 0,
  width: '100%',
}));

export const Custom: React.FC<CustomProps> = ({
  Display,
  label,
  background = true,
  paddingHorizontal = true,
  paddingVertical = true,
}) => (
  <Wrapper background={background} label={label} border={false}>
    <Padded paddingHorizontal={paddingHorizontal} paddingVertical={paddingVertical}>
      <Display />
    </Padded>
  </Wrapper>
);
