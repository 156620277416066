import { Medication } from '@quromedical/models';
import {
  getRequestDataFromResponse,
  medicationRequestColumns,
  MedicationRequestTableData,
} from 'core/medication';
import { PaginatedTable } from 'core/table';
import { MedicationRequestApi } from 'integration/aggregate';
import React, { useCallback } from 'react';

interface MedicationRequestTableProps {
  patientId: string;
  admissionId: string;
}

const api = new MedicationRequestApi();

export const MedicationRequestTable: React.FC<MedicationRequestTableProps> = ({ patientId }) => {
  const fetcher = useCallback(
    (pageToken?: string, count?: number) =>
      api.getMedicationRequests(patientId, {
        count,
        pageToken,
      }),
    [patientId]
  );

  const swrKey = api.getMedicationRequestSWRKey(patientId);

  return (
    <PaginatedTable<Partial<MedicationRequestTableData>, Medication.GetAllRequestsResponse>
      cacheKey={swrKey}
      fetcher={fetcher}
      columns={medicationRequestColumns}
      showHeader={true}
      getDataFromResponse={getRequestDataFromResponse}
      canChangeCount
    />
  );
};
