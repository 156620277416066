import { CreateReference, getCreateReferenceId } from '@quromedical/fhir-common';
import { Admission, Vitals } from '@quromedical/models';
import { NEWS2ClinicalRisk, QuroScoreResult } from '@quromedical/news';
import { getPatientNEWS2Score, requiresUrgentResponse } from 'core/vitals';
import { useActiveAssignedAdmissions } from 'hooks/useActiveAssignedAdmissions';
import { useLiveData } from 'integration/live-vitals';

export type AdmissionWithPatient = Admission.GetResponse & {
  patient: CreateReference;
};

export interface AdmissionRisk {
  admission: AdmissionWithPatient;
  news?: QuroScoreResult;
  vitals?: Vitals.LiveDataMeasures;
}

const requiresResponse = (
  admissionRisk: AdmissionRisk
): admissionRisk is Required<AdmissionRisk> => {
  const { news } = admissionRisk;

  if (!news) {
    return false;
  }

  return requiresUrgentResponse(news.risk);
};

const filterAlerts =
  (highOnly: boolean) =>
  (admissionRisk: AdmissionRisk): admissionRisk is Required<AdmissionRisk> => {
    const response = requiresResponse(admissionRisk);

    if (!response) {
      return false;
    }

    if (!highOnly) {
      return true;
    }

    return admissionRisk.news.risk === NEWS2ClinicalRisk.High;
  };

const hasPatient = (admission: Admission.GetResponse): admission is AdmissionWithPatient =>
  !!admission.patient;

export const useUrgentReviewList = (highOnly: boolean) => {
  const { vitals: liveData = {}, updated } = useLiveData();
  const { data: admissions = [], isLoading: loadingAdmissions } = useActiveAssignedAdmissions();

  const admissionRisks = admissions
    .filter(hasPatient)
    .map<AdmissionRisk>((admission) => {
      const patientId = getCreateReferenceId(admission.patient);
      const patientVitals = liveData[patientId];

      return {
        admission,
        news: getPatientNEWS2Score(patientVitals, admission.oxygen),
        vitals: patientVitals,
      };
    })
    .filter(filterAlerts(highOnly));

  return { admissionRisks, updated, loadingAdmissions };
};
