/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { logger } from 'helpers';
import React from 'react';
import { Text } from 'react-native';

export class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error: unknown) {
    logger.error(error);

    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo?: unknown) {
    logger.error(error, errorInfo as Record<string, unknown>);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Text>Something went wrong.</Text>;
    }

    return this.props.children;
  }
}
