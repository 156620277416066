import { isRelay } from 'core/device';
import {
  FormCard,
  Skeleton,
  SkeletonProvider,
  Spacer,
  MARGIN,
  MessageCard,
  Visible,
} from 'design-system';
import { DeviceApi } from 'integration/aggregate';
import { RelayStatus as Status, RelayStatusApi } from 'integration/RelayStatusApi';
import React, { useCallback, useEffect, useState } from 'react';
import { strings } from 'strings';
import useSWR from 'swr';

import { createNotificationRows, createIssueRows, createRelayStatusRows } from './createRows';

const api = new RelayStatusApi();

interface RelayStatusProps {
  deviceId: string;
}

const deviceApi = new DeviceApi();

export const RelayStatus: React.FC<RelayStatusProps> = ({ deviceId }) => {
  const swrKey = deviceApi.getDeviceSWRKey(deviceId);

  const fetcher = useCallback(() => deviceApi.getDevice(deviceId), [deviceId]);
  const { data: device, error: deviceError } = useSWR(swrKey, fetcher);
  const [data, setData] = useState<Status>();
  const [statusError, setStatusError] = useState<boolean>();

  const isDeviceARelay = device && isRelay(device);

  const updateData = useCallback(() => {
    if (isDeviceARelay) {
      setData(undefined);
      api
        .getStatus(deviceId)
        .then(setData)
        .catch(() => setStatusError(true));
    }
  }, [deviceId, isDeviceARelay]);

  useEffect(updateData, [updateData]);

  if (!isDeviceARelay) {
    return null;
  }

  const hasError = statusError || deviceError;
  if (hasError) {
    return <MessageCard message={strings.RelayLoadingErrorText} title={strings.ErrorCardTitle} />;
  }

  const notifications = createNotificationRows(data);
  const relayStatus = createRelayStatusRows(data);
  const issues = createIssueRows(data);

  return (
    <SkeletonProvider loading={!data}>
      <Skeleton>
        <FormCard
          cardColor="status-warning"
          showIcons={true}
          title={strings.RelayIssuesTitle}
          rows={issues || []}
        />
      </Skeleton>
      <Visible if={issues}>
        <Spacer height={MARGIN.m} />
        <Skeleton>
          <FormCard
            onButtonPress={updateData}
            buttonVariant="flat"
            buttonIcon="refresh"
            buttonShape="round"
            title={strings.LabelRelayStatus}
            rows={relayStatus}
          />
        </Skeleton>
      </Visible>
      <Spacer height={MARGIN.m} />
      <Skeleton>
        <FormCard title={strings.LabelNotifications} rows={notifications} />
      </Skeleton>
    </SkeletonProvider>
  );
};
