import styled from '@emotion/native';
import { Row } from 'components/base';
import { FileResult } from 'components/types';
import { Text, Spacer, Icon, Visible } from 'design-system/base';
import { BORDER_WIDTH, MARGIN, PADDING, BASE_SIZE } from 'design-system/theme';
import React from 'react';
import { Platform, View } from 'react-native';
import { strings } from 'strings';

import { DropWrapper } from '../drop-wrapper';
import { UploadButton } from '../upload-button';

interface DragDropFileProps {
  isPlacedVertically: boolean;
  onSelect: (files: FileResult[]) => void;
}

const ContentWrapper = styled(View)(({ theme }) => ({
  backgroundColor: theme.color['base-grey'],
  justifyContent: 'center',
  alignItems: 'center',
  aspectRatio: 1,
  padding: PADDING.s,
}));

const LineDivider = styled(Row)(({ theme }) => ({
  borderColor: theme.color.divider,
  borderWidth: 0,
  borderTopWidth: BORDER_WIDTH[1],
  marginHorizontal: MARGIN['3xs'],
  flex: 1,
}));

export const FileUploadControl: React.FC<DragDropFileProps> = ({ onSelect }) => {
  const showDragToUploadText = Platform.OS === 'web';

  return (
    <DropWrapper onSelect={onSelect}>
      <ContentWrapper>
        <Icon size={BASE_SIZE[32]} name="file-upload" color="divider" />
        <Spacer height={MARGIN['3xs']} />

        <Visible if={showDragToUploadText}>
          <Text variant="caption">{strings.DragFileToUpload}</Text>
          <Spacer height={MARGIN.xs} />
          <Row fullWidth alignItems="center">
            <LineDivider />
            <Text variant="caption">{strings.OrText}</Text>
            <LineDivider />
          </Row>
        </Visible>

        <Spacer height={MARGIN.xs} />
        <UploadButton onSelect={onSelect} />
      </ContentWrapper>
    </DropWrapper>
  );
};
