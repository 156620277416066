import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Row } from 'components/base';
import { IconName, Icon, Visible, Skeleton, Text, Spacer } from 'design-system/base';
import { BORDER_WIDTH, MARGIN, BASE_SIZE } from 'design-system/theme';
import React from 'react';

export interface Measurement<T> {
  name?: string;
  value?: string | number | undefined;
  unit?: string;
  statusColor?: ColorName;
  icon?: IconName;
  iconColor?: ColorName;
  key: T;
}

interface MeasurementCardProps {
  statusColor?: ColorName;
}

const MeasureWrapper = styled.View<MeasurementCardProps>(({ statusColor = 'divider', theme }) => ({
  borderStyle: 'solid',
  borderLeftColor: theme.color[statusColor],
  borderLeftWidth: BORDER_WIDTH[2],
  paddingLeft: BASE_SIZE[8],
}));

export const Measure = <T extends string>({
  value = '',
  name = '',
  unit = '',
  statusColor,
  iconColor = 'text-default',
  icon,
}: Measurement<T>) => (
  <MeasureWrapper statusColor={statusColor || 'divider'}>
    <Row alignItems="center" isVisible={!!(icon || name)}>
      <Visible if={icon}>
        <Icon size={BASE_SIZE[12]} color={iconColor} name={icon} />
        <Spacer width={MARGIN['3xs']} />
      </Visible>
      <Visible if={name}>
        <Text variant="caption">{name}</Text>
      </Visible>
    </Row>
    <Skeleton>
      <Row alignItems="flex-end">
        <Text color={statusColor} variant="body">
          {value}
        </Text>
        <Spacer width={MARGIN['3xs']} />
        <Text color={statusColor} variant="caption">
          {unit}
        </Text>
      </Row>
    </Skeleton>
  </MeasureWrapper>
);
