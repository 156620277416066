import { CommentItemPill, CommentItemPillProps } from './CommentItemPill';
import { GroupItemPill, GroupItemPillProps } from './GroupItemPill';

export type ItemPillProps = CommentItemPillProps | GroupItemPillProps;

export const ItemPill: React.FC<ItemPillProps> = ({ node, ...rest }) => {
  if (node.type === 'group') {
    return <GroupItemPill {...rest} node={node} />;
  }

  return <CommentItemPill {...rest} node={node} />;
};
