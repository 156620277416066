import styled from '@emotion/native';
import { useTheme } from '@emotion/react';
import { Col, Row } from 'components/base';
import { FONT_FAMILY, PADDING } from 'design-system/theme';
import React, { useCallback, useMemo, useState } from 'react';
import { TextInputMask } from 'react-native-masked-text';

import { Wrapper } from '../internal';
import { inputReshapers, maskedInputParser, maskFormats, outputReshapers } from './formatters';
import { Placeholder } from './Placeholder';
import { MaskType } from './types';

export interface MaskedTextBoxProps {
  /**
   * The Mask type for dates will use the same ISO format FHI uses as the input and output value
   */
  maskType: MaskType;
  label?: string;
  value?: string;
  errors?: string;
  isDisabled?: boolean;
  onChange: (value?: string) => void;
}

const Input = styled(TextInputMask)(({ theme }) => ({
  paddingVertical: PADDING['2xs'],
  paddingHorizontal: PADDING.xs,
  color: theme.color['text-default'],
  width: '100%',
  fontFamily: FONT_FAMILY.regular,
  fontSize: 14,
}));

/**
 * Note that input will return the datetime in the requested format as a string and not a `Date`.
 *
 * Based on this component's structure it should be trivial to add additional types by adding to the
 * `MaskType` list and making some minor adjustments to the data passed to the `Input`
 *
 * This component also exports it's parsers so that the resulting values can be used outside of the
 * component
 */
export const MaskedTextBox: React.FC<MaskedTextBoxProps> = ({
  label = '',
  value = '',
  errors,
  isDisabled,
  onChange,
  maskType,
}) => {
  const theme = useTheme();

  const inputReshaper = inputReshapers[maskType];
  const outputReshaper = outputReshapers[maskType];

  const input = useMemo(() => inputReshaper(value), [inputReshaper, value]);

  const [innerValue, setInnerValue] = useState(input || '');
  const [focused, setFocus] = useState(false);

  const handleFocus = useCallback(() => setFocus(true), [setFocus]);
  const handleBlur = useCallback(() => setFocus(false), [setFocus]);

  const editable = !isDisabled;

  const maskFormat = maskFormats[maskType];
  const parser = maskedInputParser[maskType];

  const handleChange = useCallback(
    (text: string) => {
      setInnerValue(text);
      const parsed = parser(text);

      if (parsed) {
        const reshaped = outputReshaper(text);

        onChange(reshaped);
      } else {
        onChange(undefined);
      }
    },
    [onChange, outputReshaper, parser]
  );

  return (
    <Wrapper label={label} error={errors} focused={focused}>
      <Col flex={1}>
        <Placeholder placeholder={maskFormat} value={innerValue} />
        <Row>
          <Input
            type="datetime"
            keyboardType="numeric"
            options={{ format: maskFormat, mask: maskFormat }}
            value={innerValue}
            editable={editable}
            placeholderTextColor={theme.color['text-disabled']}
            numberOfLines={1}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChangeText={handleChange}
          />
        </Row>
      </Col>
    </Wrapper>
  );
};
