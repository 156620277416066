import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { PractitionerListScreen, PractitionerScreen } from 'screens/practitioner';

import { PractitionerStackParamList } from './types';

const Stack = createNativeStackNavigator<PractitionerStackParamList>();

export const PractitionerNavigator: React.FC = () => (
  <Stack.Navigator initialRouteName="List">
    <Stack.Screen
      name="List"
      component={PractitionerListScreen}
      options={{
        title: 'Practitioners',
        headerShown: false,
      }}
    />
    <Stack.Screen name="View" component={PractitionerScreen} options={{ title: 'Practitioner' }} />
  </Stack.Navigator>
);
