import styled from '@emotion/native';
import { Vitals, BaseTimeSeriesRecord } from '@quromedical/models';
import { Col } from 'components/base';
import {
  BORDER_WIDTH,
  Button,
  Grid,
  PADDING,
  Skeleton,
  SkeletonProvider,
  Text,
} from 'design-system';
import { integerFormatter } from 'helpers';
import humanizeDuration from 'humanize-duration';
import React from 'react';
import { strings } from 'strings';

import { codes, units } from './records';

interface VitalsRowProps {
  latest: Vitals.LatestResultMeasures;
  loading?: boolean;
  hasAddButton?: boolean;
  onAddPress?: () => void;
}

const ValueWrapper = styled(Col)(({ theme }) => ({
  borderWidth: 0,
  paddingLeft: PADDING['2xs'],
  borderLeftWidth: BORDER_WIDTH[4],
  borderLeftColor: theme.color.divider,
}));

interface VitalContentProps {
  label: string;
  main: string;
  caption: string;
}

const VitalContent: React.FC<VitalContentProps> = ({ label, main, caption }) => (
  <ValueWrapper>
    <Text variant="body">{label}</Text>
    <Text variant="heading">{main}</Text>
    <Text variant="caption-subdued">{caption}</Text>
  </ValueWrapper>
);

interface VitalProps {
  datum: BaseTimeSeriesRecord;
}

const Vital: React.FC<VitalProps> = ({ datum }) => {
  const { measurementName, ts, value } = datum;
  const duration = humanizeDuration(Date.now() - ts, {
    round: true,
    largest: 1,
  });

  const age = `${duration} ${strings.DurationSuffixAgo}`;
  const unit = units[measurementName] || '';
  const code = codes[measurementName] || measurementName;
  const display = `${integerFormatter(value)} ${unit}`;

  return <VitalContent label={code} main={display} caption={age} />;
};

const placeholder = '--';

interface VitalPlaceholderProps {
  loading: boolean;
}

const VitalPlaceholder: React.FC<VitalPlaceholderProps> = ({ loading }) => {
  if (!loading) {
    return null;
  }

  return <VitalContent label={placeholder} main={placeholder} caption={placeholder} />;
};

interface WrapperProps {
  hasButton?: boolean;
}

const Wrapper = styled.View<WrapperProps>(({ hasButton = false }) => ({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingVertical: PADDING['2xs'],
  paddingLeft: PADDING.s,
  paddingRight: hasButton ? 0 : PADDING.s,
}));

export const VitalsRow: React.FC<VitalsRowProps> = ({
  latest,
  loading = false,
  onAddPress,
  hasAddButton,
}) => {
  const data = Object.values(latest);

  if (loading) {
    // we wrap the component in a skeleton so the entire row loads and not just the inner content
    return (
      <SkeletonProvider loading={loading}>
        <Skeleton>
          <Wrapper>
            <VitalPlaceholder loading={loading} />
          </Wrapper>
        </Skeleton>
      </SkeletonProvider>
    );
  }

  const hasButton = hasAddButton && onAddPress;

  return (
    <Wrapper hasButton={!!hasButton}>
      <Grid xl={6} md={4} gap="s" sm={1}>
        {data.map((datum) => (
          <Vital key={datum.measurementName} datum={datum} />
        ))}
      </Grid>

      {hasAddButton && onAddPress ? (
        <Col>
          <Button variant="text" icon="add" onPress={onAddPress} />
        </Col>
      ) : null}
    </Wrapper>
  );
};
