import { ClaimTemplate, WithId } from '@quromedical/models';
import { LinkButton } from 'components/input';
import { ColumnType, DataTable } from 'components/table';
import { ClaimTemplate as FormData, ClaimTemplateForm } from 'core/claim';
import { claimTypeDisplay } from 'core/display';
import { Section, ToggleCard, Text } from 'design-system';
import { useRevalidation } from 'hooks/useRevalidation';
import { chargeItemFetcher } from 'integration';
import { ClaimTemplateApi } from 'integration/aggregate';
import { GroupStackScreenProps } from 'navigation';
import React, { useCallback } from 'react';
import { ScrollView } from 'react-native';
import { strings } from 'strings';
import useSWR from 'swr';

const api = new ClaimTemplateApi();

const columns: ColumnType<WithId<ClaimTemplate.ClaimTemplate>>[] = [
  {
    accessor: 'type',
    header: strings.ClaimTemplateFormLabelService,
    Cell: ({ value }) => <Text>{claimTypeDisplay[value] || value}</Text>,
  } as ColumnType<WithId<ClaimTemplate.ClaimTemplate>, 'type'>,
  {
    accessor: 'id',
    header: '',
    Cell: ({ value }) => (
      <LinkButton
        text={strings.ClaimTemplateViewTitle}
        to={{
          screen: 'Admin',
          params: {
            screen: 'ClaimTemplate',
            params: {
              screen: 'View',
              params: {
                id: value,
              },
            },
          },
        }}
      />
    ),
  } as ColumnType<ClaimTemplate.GetResponse, 'id'>,
];

export const ClaimTemplateListScreen: React.FC<GroupStackScreenProps<'List'>> = () => {
  const baseSWRKey = 'claim-template-list';
  const { isValidating, data } = useSWR(baseSWRKey, () => api.getClaimTemplates());

  const revalidate = useRevalidation(baseSWRKey);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      await api.createClaimTemplate({
        type: data.type,
        chargeLines: data.claimLines.map((line) => ({
          chargeLine: line.chargeItemIdentifier,
          quantity: line.quantity,
        })),
      });

      await revalidate();
    },
    [revalidate]
  );

  return (
    <ScrollView>
      <Section unsetZIndex>
        <ToggleCard
          title={strings.ClaimTemplateFormCreateTitle}
          icon="attach-money"
          textExpand={strings.LabelExpand}
          textCollapse={strings.LabelCollapse}
        >
          <ClaimTemplateForm
            title={strings.ClaimTemplateFormCreateTitle}
            hideCard
            chargeItemFetcher={chargeItemFetcher}
            onSubmit={handleSubmit}
          />
        </ToggleCard>
      </Section>
      <Section>
        <DataTable showLoading={isValidating} data={data?.claimTemplates || []} columns={columns} />
      </Section>
    </ScrollView>
  );
};
