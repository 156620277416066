import { CountryCode } from '@quromedical/fhir-common';
import { Person } from '@quromedical/models';

export const initialGeneral: Partial<Person.General> = {
  identifierCode: Person.IdentifierCode.saIdNumber,
  gender: undefined,
};

export const initialContact: Partial<Person.Contact> = {
  telecomPhoneNumbers: ['', ''],
  addressLines: ['', ''],
  addressCountry: CountryCode.SouthAfrica,
};
