import { Admission } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';

export class AdmissionApi {
  private readonly client: BaseApi;
  private readonly aggregateRoot = 'api/aggregate/admission';
  constructor() {
    this.client = new BaseApi();
  }

  public getAdmissionSWRKey = (): string => this.aggregateRoot;

  public getAdmissions = async (params: Admission.GetParams): Promise<Admission.GetAllResponse> => {
    const response = await this.client.get<Admission.GetAllResponse>(this.aggregateRoot, {
      params,
    });

    return response.data;
  };
}
