import { Currency } from '@quromedical/fhir-common';
import { ChargeItemDefinition } from '@quromedical/models';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Field, SelectOption } from 'components/types';
import { mapDisplaysToOptions } from 'core/forms';
import { Fetcher } from 'design-system';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

type ChargeLineType = 'scheme' | 'administrator';

const chargeLineTypeOptions: Record<ChargeLineType, string> = {
  administrator: strings.ChargeLineAppliesOptionAdministrator,
  scheme: strings.ChargeLineAppliesOptionScheme,
};

export interface ChargeLine {
  type: ChargeLineType;
  currency: Currency;
  chargeCode: string;
  /**
   * Identifier of the scheme/administrator
   */
  identifierCode: string;
  identifierDisplay: string;
  /**
   * Amount in cents
   */
  amount: number;
  amountFactor: 0.01;
}

export type AdministratorFetcher = Fetcher<string, unknown>;
export type SchemeFetcher = Fetcher<string, unknown>;

interface ChargeLineFormProps {
  showErrors?: boolean;
  value: Partial<ChargeLine>;
  schemeFetcher: SchemeFetcher;
  administratorFetcher: AdministratorFetcher;
  onChange: (data: Partial<ChargeLine>, isValid: boolean) => void;
  onRemove?: () => void;
  onAdd?: () => void;
}

interface FormChargeLine extends Omit<ChargeLine, 'identifierCode' | 'identifierDisplay'> {
  identifier: SelectOption<never, string>;
}

const createFields = (
  type: ChargeLineType,
  identifierFetcher: Fetcher<string, unknown>,
  initialIdentifier?: SelectOption<never, string>
): Field<FormChargeLine>[] => {
  const identifierSelection = initialIdentifier ? [initialIdentifier] : [];

  return [
    {
      subfields: [
        {
          key: 'type',
          type: 'combobox-single',
          label: strings.ChargeLineAppliesOptionLabel,
          fetcher: mapDisplaysToOptions(chargeLineTypeOptions),
        },
        {
          key: 'identifier',
          type: 'combobox-single',
          label: chargeLineTypeOptions[type],
          searchable: true,
          fetcher: identifierFetcher,
          initialSelection: identifierSelection,
          returnFullOption: true,
        },
        {
          key: 'chargeCode',
          type: 'text-box',
          label: strings.ChargeLineItemCodeLabel,
        },
        {
          key: 'amount',
          type: 'text-box',
          keyboardType: 'decimal-pad',
          label: strings.ChargeLineItemAmountLabel,
        },
      ],
    },
  ];
};

const schema = yup.object<ObjectType<FormChargeLine>>({
  amount: yup.number().required().integer().positive(),
  identifier: yup.object().required(),
  type: yup.string().required().equals(Object.keys(chargeLineTypeOptions)),
  chargeCode: yup.string().required(),
});

export const ChargeLineForm: React.FC<ChargeLineFormProps> = ({
  value,
  schemeFetcher,
  administratorFetcher,
  showErrors = false,
  onChange,
  onRemove,
  onAdd,
}) => {
  const [identifierDisplay, setIdentifierDisplay] = useState<string | undefined>(
    value.identifierDisplay
  );
  const handleChange = useCallback(
    (data: Partial<FormChargeLine>) => {
      const identifier = data.type === value.type ? data.identifier : undefined;
      const amount = data.amount !== undefined ? +data.amount : undefined;
      const type = data.type || 'administrator';

      const newState: Partial<ChargeLine & FormChargeLine> = {
        identifier: data.identifier,
        amountFactor: ChargeItemDefinition.MONEY_FACTOR,
        currency: Currency.ZAR,
        type,
        amount,
        chargeCode: data.chargeCode,
        identifierCode: identifier?.value,
        identifierDisplay: identifier?.display,
      };

      setIdentifierDisplay(identifier?.display);

      const isValid = schema.isValidSync(newState);

      // manually re-checking the schema since on initial load formik does not send the error
      onChange(newState, isValid);
    },
    [onChange, value]
  );

  const type = value.type || 'administrator';
  const identifierFetcher = type === 'administrator' ? administratorFetcher : schemeFetcher;

  const selectedItem: SelectOption<never, string> | undefined = value.identifierCode
    ? {
        value: value.identifierCode,
        display: value.identifierDisplay || value.identifierCode,
      }
    : undefined;

  const title = identifierDisplay || strings.ChargeLineFormTitle;
  const fields = createFields(type, identifierFetcher, selectedItem);

  const formValue: Partial<FormChargeLine> = {
    ...value,
    identifier: selectedItem,
  };
  const handleSubmit = useCallback<SubmissionHandler<FormChargeLine>>(() => {
    try {
      onAdd?.();
      return {};
    } catch (error) {
      return { error };
    }
  }, [onAdd]);

  return (
    <CrudForm<FormChargeLine>
      key={type}
      title={title}
      hasCard={false}
      onChange={handleChange}
      onChangeDebounceTime={0}
      buttonVariant="outlined"
      fields={fields}
      showErrors={showErrors}
      onSubmit={handleSubmit}
      showSubmitButton={!!onAdd}
      buttonText={strings.FormListAddItem}
      onSecondarySubmit={onRemove}
      showSecondarySubmitButton={!!onRemove}
      secondaryButtonText={strings.FormListRemoveItem}
      initialValues={formValue}
      validationSchema={schema}
    />
  );
};
