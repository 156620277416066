import { ColorName, useTheme } from '@emotion/react';
import * as Graph from 'components/graph';
import { extent } from 'd3-array';
import { integerFormatter } from 'helpers';
import React from 'react';

import { Tooltip } from './Tooltip';
import { GradientPoint } from './types';
import { getTooltipData } from './useTooltipProps';

export interface SecondaryData {
  title: string;
  code: string;
  data: Graph.Datum[];
  color: ColorName;
  gradient?: GradientPoint[];
  unit?: string;
  showTooltip?: boolean;
  simpleTooltip?: boolean;
}

interface SecondaryDisplayProps extends SecondaryData {
  valueFormatter?: (value?: number) => string;
  hover?: Graph.Datum;
}

export const SecondaryDisplay: React.FC<SecondaryDisplayProps> = ({
  gradient = [],
  code,
  color,
  data,
  hover,
  unit,
  showTooltip = false,
  simpleTooltip = false,
  valueFormatter = integerFormatter,
}) => {
  const theme = useTheme();

  const { closest, tooltipText } = getTooltipData({
    hover,
    unit,
    valueFormatter,
    data,
    simple: simpleTooltip,
  });

  const secondaryLinearGradientId = `secondary-gradient-${code}-${Math.random()}`;

  // if there is no gradient this needs to be undefined
  const secondaryLineGradientId = gradient.length ? secondaryLinearGradientId : undefined;

  const secondaryDomain = Graph.getFullDomain(extent(data, (datum) => datum?.y));

  const resolvedColor = theme.color[color];

  const stops = gradient.map<Graph.StopProps>((stop) => ({
    offsetVal: stop.value,
    color: stop.color ? theme.color[stop.color] : resolvedColor,
  }));

  const hasTooltip = closest && showTooltip;

  return (
    <>
      {gradient.length ? (
        <Graph.LinearGradient
          relativeDomain={secondaryDomain}
          id={secondaryLinearGradientId}
          direction="vertical"
          stops={stops}
        />
      ) : null}
      <Graph.PlotLine
        data={data}
        stroke={resolvedColor}
        strokeWidth={2}
        gradientId={secondaryLineGradientId}
        stops={stops}
      />
      {hasTooltip ? (
        <Tooltip
          baseColor={resolvedColor}
          closest={closest}
          stops={stops}
          tooltipText={tooltipText}
        />
      ) : null}
    </>
  );
};
