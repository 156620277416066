import {
  CountryCode,
  OrganizationCode,
  OrganizationCodeDescription,
} from '@quromedical/fhir-common';
import { Organization, Person } from '@quromedical/models';
import { Field, SelectOption } from 'components/types';
import { Fetcher } from 'design-system';
import { strings } from 'strings';

export const getDescription = (code?: OrganizationCode): string | undefined => {
  if (!code) {
    return undefined;
  }

  return OrganizationCodeDescription[code];
};

export const initialData: Partial<Organization.ContactUpdateRequest> = {
  addressCountry: CountryCode.SouthAfrica,
};
const typeCodes = Object.values(OrganizationCode);
const typeOptions = typeCodes.map<SelectOption>((code) => ({
  display: getDescription(code) || code,
  value: code,
}));

export type OrganizationWithOutCoordinates = Organization.General &
  Omit<Person.Contact, 'addressCoordinates'>;

export const initialSearch: Partial<Organization.GetAllParams> = {
  name: '',
  address: '',
};

export const searchFields: Field<Organization.GetAllParams>[] = [
  {
    subfields: [
      {
        type: 'text-box',
        key: 'name',
        label: strings.LabelName,
        placeholder: 'Enter Name',
      },
      {
        type: 'text-box',
        key: 'address',
        label: strings.LabelAddress,
        placeholder: 'Enter Address',
      },
      {
        type: 'combobox-single',
        key: 'type',
        label: strings.LabelOrganizationType,
        fetcher: [
          {
            display: 'All',
            value: undefined,
          },
          ...typeOptions,
        ],
      },
    ],
  },
];

export const createOrganizationGeneralRows = (
  schemeFetcher: Fetcher<string, unknown>,
  type?: OrganizationCode
): Field<Organization.GeneralBase>[] | Field<Organization.GeneralInsurance>[] => {
  const baseFields: Field<Organization.General> = {
    subfields: [
      {
        type: 'text-box',
        key: 'name',
        label: strings.LabelName,
        placeholder: 'Enter Name',
      },
      {
        type: 'combobox-single',
        key: 'type',
        label: strings.LabelOrganizationType,
        fetcher: typeOptions,
      },
    ],
  };

  if (type !== OrganizationCode.InsuranceCompany) {
    return [baseFields];
  }

  return [
    baseFields,
    {
      subfields: [
        {
          type: 'combobox-single',
          key: 'schemeCode',
          searchable: true,
          label: strings.FormLabelScheme,
          fetcher: schemeFetcher,
        },
      ],
    },
  ];
};
