import { GroupSecurity } from '@quromedical/fhir-common';
import { PatientHeaderSection } from 'core/patient';
import { Alert, SkeletonProvider, Snackbar, Tabs } from 'design-system';
import { useRevalidation } from 'hooks/useRevalidation';
import { useUserSession } from 'hooks/useUserSession';
import { PatientApi, PatientEndpoint } from 'integration/aggregate';
import { PatientDrawerScreenProps } from 'navigation';
import { usePatientId } from 'providers/PatientIdContext';
import React from 'react';
import { ScrollView } from 'react-native';
import { strings } from 'strings';
import useSWR from 'swr';

import { PatientMedicationsTab, PatientAdmissionsTab, PatientMedicationRequestTab } from './tabs';

const api = new PatientApi();

export const PatientClinicalScreen: React.FC<PatientDrawerScreenProps<'Clinical'>> = () => {
  const id = usePatientId();
  const session = useUserSession();

  const fetcher = () => api.getPatient(id);
  const key = api.getPatientSWRKey(id, PatientEndpoint.Base);
  const revalidate = useRevalidation(key);

  const { data, isValidating, error } = useSWR(key, fetcher);

  const hasActiveAdmission = !!data?.inProgressAdmission;
  const hasCoverage = !!data?.coverage;
  const admissionId = data?.inProgressAdmission?.id;

  const hasMedicationPermissions = session.hasAny([GroupSecurity.PatientRead]);
  const hasAdmissionPermissions = session.hasAny([GroupSecurity.EncounterRead]);

  if (error) {
    return (
      <Snackbar onClose={revalidate} isOpen={!!error}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={revalidate}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
        />
      </Snackbar>
    );
  }

  return (
    <ScrollView>
      <PatientHeaderSection id={id} />

      <SkeletonProvider loading={isValidating}>
        <Tabs.Group>
          <Tabs.Screen isHidden={!hasAdmissionPermissions} title={strings.AdmissionCardTitle}>
            <PatientAdmissionsTab
              patientId={id}
              hasCoverage={hasCoverage}
              hasActiveAdmission={hasActiveAdmission}
            />
          </Tabs.Screen>

          <Tabs.Screen isHidden={!hasMedicationPermissions} title={strings.MedicationsCardTitle}>
            <PatientMedicationsTab patientId={id} admissionId={admissionId} />
          </Tabs.Screen>

          <Tabs.Screen
            isHidden={!hasMedicationPermissions}
            title={strings.MedicationsRequestCardTitle}
          >
            <PatientMedicationRequestTab patientId={id} admissionId={admissionId} />
          </Tabs.Screen>
        </Tabs.Group>
      </SkeletonProvider>
    </ScrollView>
  );
};
