import useSWR from 'swr';

interface UseDownloadUrlData {
  loading: boolean;
  url?: string;
}

const noDownload = () => undefined;

export const useDownloadUrl = (
  key: string,
  getUrl: () => Promise<string> | string,
  isOpen: boolean
): UseDownloadUrlData => {
  const swrKey = `use-download-url/${key}/${isOpen ? 'open' : 'closed'}`;

  const { data: url, isValidating: loading } = useSWR(swrKey, isOpen ? getUrl : noDownload);

  return { loading, url };
};
