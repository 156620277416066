import { OrganizationCode } from '@quromedical/fhir-common';
import { Organization } from '@quromedical/models';
import { FormCard, RowConfig } from 'design-system';
import React from 'react';
import { strings } from 'strings';

import { getDescription, OrganizationWithOutCoordinates } from './fields';

interface CardProps {
  data: Partial<OrganizationWithOutCoordinates>;
  canEdit: boolean;
  onEditPress: () => void;
}

const getOrganizationRows = (
  organization: Partial<OrganizationWithOutCoordinates>
): RowConfig[] => [
  {
    icon: 'person-outline',
    fields: [
      {
        type: 'text',
        label: strings.LabelName,
        display: organization.name,
      },
      {
        type: 'text',
        label: strings.LabelOrganizationType,
        display: getDescription(organization.type),
      },
    ],
  },
  {
    icon: 'email-outline',
    fields: [
      {
        type: 'text',
        label: strings.Email,
        display: organization.telecomEmail,
      },
      {
        type: 'list',
        label: strings.LabelPhone,
        display: organization.telecomPhoneNumbers,
      },
    ],
  },
  {
    icon: 'pin-drop',
    fields: [
      {
        type: 'list',
        label: strings.LabelAddress,
        display: organization.addressLines,
      },
    ],
  },
  {
    fields: [
      {
        type: 'text',
        label: strings.LabelCity,
        display: organization.addressCity,
      },
      {
        type: 'text',
        label: strings.LabelPostalCode,
        display: organization.addressPostalCode,
      },
    ],
  },
  {
    fields: [
      {
        type: 'text',
        label: strings.LabelCountry,
        display: organization.addressCountry,
      },
    ],
  },
];

export const Card: React.FC<CardProps> = ({ data, canEdit, onEditPress }) => (
  <FormCard
    isButtonVisible={canEdit}
    onButtonPress={onEditPress}
    buttonText={strings.ButtonTextEdit}
    buttonIcon="edit"
    showIcons
    title={strings.CardTitleOrganizationDetails}
    rows={getOrganizationRows(data)}
  />
);

const isInsuranceOrg = (
  data: Partial<Organization.General>
): data is Partial<Organization.GeneralInsurance> =>
  data.type === OrganizationCode.InsuranceCompany;

const getGeneralOrganizationRows = (
  organization: Partial<Organization.General> = {}
): RowConfig[] => {
  const commonRows: RowConfig[] = [
    {
      icon: 'person-outline',
      fields: [
        {
          type: 'text',
          label: strings.LabelName,
          display: organization.name,
        },
        {
          type: 'text',
          label: strings.LabelOrganizationType,
          display: getDescription(organization.type),
        },
      ],
    },
  ];

  if (!isInsuranceOrg(organization)) {
    return commonRows;
  }

  return [
    ...commonRows,
    {
      fields: [
        {
          type: 'text',
          label: strings.LabelOrganizationSchemeCode,
          display: organization.schemeCode,
        },
      ],
    },
  ];
};
interface GeneralCardProps {
  data?: Partial<Organization.General>;
  canEdit: boolean;
  onEditPress: () => void;
}

export const GeneralCard: React.FC<GeneralCardProps> = ({ data, canEdit, onEditPress }) => {
  const rows = getGeneralOrganizationRows(data);

  return (
    <FormCard
      isButtonVisible={canEdit}
      onButtonPress={onEditPress}
      buttonText={strings.ButtonTextEdit}
      buttonIcon="edit"
      showIcons
      title={strings.FormLabelPersonDetails}
      rows={rows}
    />
  );
};
