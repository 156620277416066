import { MouseEvent, useCallback } from 'react';
import { GestureResponderEvent, Platform } from 'react-native';

interface PlatformHandler {
  // web handlers
  onClick?: (event: MouseEvent) => void;
  onMouseUp?: (event: MouseEvent) => void;
  onMouseDown?: (event: MouseEvent) => void;
  // native handlers
  onPress?: (event: GestureResponderEvent) => void;
  onTouchStart?: (event: GestureResponderEvent) => void;
  onTouchEnd?: (event: GestureResponderEvent) => void;
  onTouchMove?: (event: GestureResponderEvent) => void;
}

type Scaler = (v: number) => number;

export const usePlatformPressHandler = (
  startHandler: (position: { x: number; y: number }) => void,
  endHandler: (position: { x: number; y: number }) => void,
  xScaler: Scaler,
  yScaler: Scaler
): PlatformHandler => {
  const webStartCallback = useCallback(
    (e: MouseEvent) => {
      const { offsetX, offsetY } = e.nativeEvent;
      startHandler({ x: xScaler(offsetX), y: yScaler(offsetY) });
    },
    [startHandler, xScaler, yScaler]
  );

  const webEndCallback = useCallback(
    (e: MouseEvent) => {
      const { offsetX, offsetY } = e.nativeEvent;
      endHandler({ x: xScaler(offsetX), y: yScaler(offsetY) });
    },
    [endHandler, xScaler, yScaler]
  );

  const nativeStartCallback = useCallback(
    (e: GestureResponderEvent) => {
      const { locationY, locationX } = e.nativeEvent;
      startHandler({ x: xScaler(locationX), y: yScaler(locationY) });
    },
    [startHandler, xScaler, yScaler]
  );

  const nativeEndCallback = useCallback(
    (e: GestureResponderEvent) => {
      const { locationY, locationX } = e.nativeEvent;
      endHandler({ x: xScaler(locationX), y: yScaler(locationY) });
    },
    [endHandler, xScaler, yScaler]
  );

  if (Platform.OS === 'web') {
    return {
      onClick: webStartCallback,
      onMouseDown: webStartCallback,
      onMouseUp: webEndCallback,
    };
  }

  return {
    onPress: nativeStartCallback,
    onTouchStart: nativeStartCallback,
    onTouchEnd: nativeEndCallback,
    onTouchMove: nativeEndCallback,
  };
};
