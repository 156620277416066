import { GroupSecurity } from '@quromedical/fhir-common';
import { PatientHeaderSection } from 'core/patient';
import { Tabs } from 'design-system';
import { useUserSession } from 'hooks';
import { PatientDrawerScreenProps } from 'navigation';
import { usePatientId } from 'providers/PatientIdContext';
import React from 'react';
import { ScrollView } from 'react-native';
import { NotFoundScreen } from 'screens/NotFoundScreen';
import { strings } from 'strings';

import { DiagnosticReportTab, ServiceRequestTab } from './tabs';

export const ServiceRequestsScreen: React.FC<PatientDrawerScreenProps<'ServiceRequests'>> = () => {
  const id = usePatientId();
  const session = useUserSession();

  if (!id) {
    return <NotFoundScreen />;
  }

  const hasServiceRequestPermissions = session.hasAny([GroupSecurity.ServiceRequestRead]);
  const hasDiagnosticReportsPermissions = session.hasAny([GroupSecurity.DiagnosticReportRead]);

  return (
    <ScrollView>
      <PatientHeaderSection id={id} />

      <Tabs.Group>
        <Tabs.Screen
          isHidden={!hasServiceRequestPermissions}
          title={strings.PathologyRequestsTabTitle}
        >
          <ServiceRequestTab patientId={id} />
        </Tabs.Screen>
        <Tabs.Screen
          isHidden={!hasDiagnosticReportsPermissions}
          title={strings.PathologyReportsTabTitle}
        >
          <DiagnosticReportTab patientId={id} />
        </Tabs.Screen>
      </Tabs.Group>
    </ScrollView>
  );
};
