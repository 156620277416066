import styled from '@emotion/native';
import { GroupSecurity } from '@quromedical/fhir-common';
import type { Util } from '@quromedical/utils';
import { useLinkTo } from '@react-navigation/native';
import { BORDER_RADIUS, fadeColor, MARGIN, useTheme } from 'design-system';
import { logger } from 'helpers';
import { useUserSession } from 'hooks';
import { useActiveAssignedAdmissions } from 'hooks/useActiveAssignedAdmissions';
import {
  KBarProvider,
  KBarPortal,
  KBarAnimator,
  KBarPositioner,
  KBarSearch,
  KBarResults,
  useMatches,
  useRegisterActions,
  useKBar,
} from 'kbar';
import compact from 'lodash.compact';
import React, { useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import { strings } from 'strings';

import { SearchItem, getSearchStyles } from './components';
import { getAdmissionAction, getPatientActions } from './patient';
import type { Action, UseKBarPatientActionsHook, UseKBarToggleHook } from './types';

const isNotHidden: Util.ArrayFilter<Action> = (action) => !action.hidden;

const createPermissionActionFilter =
  (permissions: GroupSecurity[]): Util.ArrayFilter<Action> =>
  (action) => {
    if (!action.permission) {
      return false;
    }

    return permissions.includes(action.permission);
  };

const Provider: React.FC = ({ children }) => {
  const navigate = useLinkTo();
  const session = useUserSession();
  const permissions = session.getPermissions();

  const { data: admissions = [] } = useActiveAssignedAdmissions();

  const admissionActionOptions = admissions.map<Action | undefined>(getAdmissionAction);
  const admissionActions = compact(admissionActionOptions).filter(isNotHidden);

  const patientActions = admissionActions
    .map((admissionAction) =>
      getPatientActions({
        navigate,
        parentId: admissionAction.id,
        patientId: admissionAction.id,
        patientName: admissionAction.name,
      })
    )
    .flat();

  const actions: Action[] = [
    {
      id: 'patient',
      permission: GroupSecurity.PatientRead,
      name: strings.KBarActiveAdmissionsTitle,
      subtitle: strings.KBarActiveAdmissionsSubtitle,
      iconName: 'person-outline',
    },
    {
      id: 'home',
      permission: GroupSecurity.FeaturePreviewNavigator,
      name: strings.KBarHomeTitle,
      shortcut: ['g', 'h'],
      subtitle: strings.KBarHomeSubtitle,
      iconName: 'home',
      perform: () =>
        navigate({
          screen: 'Home',
        }),
    },
    {
      id: 'dashboard',
      permission: GroupSecurity.PatientRead,
      name: strings.KBarDashboardTitle,
      shortcut: ['g', 'd'],
      subtitle: strings.KBarDashboardSubtitle,
      iconName: 'dashboard',
      perform: () =>
        navigate({
          screen: 'Dashboard',
        }),
    },
    {
      id: 'patients',
      permission: GroupSecurity.PatientRead,
      name: strings.KBarPatientTitle,
      shortcut: ['g', 'p'],
      subtitle: strings.KBarPatientSubtitle,
      iconName: 'people-outline',
      perform: () =>
        navigate({
          screen: 'Patient',
          params: {
            screen: 'PatientList',
          },
        }),
    },
    {
      id: 'admissions',
      permission: GroupSecurity.EncounterRead,
      name: strings.KBarAdmissionTitle,
      shortcut: ['g', 'a'],
      subtitle: strings.KBarAdmissionSubtitle,
      iconName: 'schedule',
      perform: () =>
        navigate({
          screen: 'Patient',
          params: {
            screen: 'AdmissionList',
          },
        }),
    },
    {
      id: 'admin-onboard-medical-aid',
      permission: GroupSecurity.PatientCreate,
      name: strings.KBarAdminOnboardMedicalAidTitle,
      subtitle: strings.KBarAdminOnboardMedicalAidSubtitle,
      shortcut: ['o', 'm'],
      iconName: 'person-add-alt',
      perform: () =>
        navigate({
          screen: 'Admin',
          params: {
            screen: 'Onboard',
            params: {
              screen: 'MedicalAid',
            },
          },
        }),
    },
    {
      id: 'admin-onboard-private',
      permission: GroupSecurity.PatientCreate,
      name: strings.KBarAdminOnboardPrivateTitle,
      subtitle: strings.KBarAdminOnboardPrivateSubtitle,
      shortcut: ['o', 'p'],
      iconName: 'person-add-alt',
      perform: () =>
        navigate({
          screen: 'Admin',
          params: {
            screen: 'Onboard',
            params: {
              screen: 'Private',
            },
          },
        }),
    },
    ...admissionActions,
    ...patientActions,
  ];

  const hasPermissions = createPermissionActionFilter(permissions);
  const filteredActions = actions.filter(hasPermissions).filter(isNotHidden);

  return (
    <KBarProvider actions={filteredActions} key={filteredActions.length}>
      {children}
    </KBarProvider>
  );
};

const MainWrapper = styled.View(({ theme }) => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    logger.track({
      type: 'gamification',
      name: 'kbar-search-render',
    });
  }, []);

  return {
    backgroundColor: fadeColor(theme.color['base-grey'], 0.95),
    borderRadius: BORDER_RADIUS[4],
    width: width / 2,
    maxWidth: 800,
    shadowColor: theme.color.black,
    shadowOpacity: 0.2,
    shadowRadius: 10,
  };
});

const SearchInputWrapper = styled.View({
  marginVertical: MARGIN.xs,
  marginHorizontal: MARGIN.s,
});

export const Content: React.FC = () => {
  const matches = useMatches();
  const { height } = useWindowDimensions();
  const maxHeight = height / 2;

  const theme = useTheme();
  const searchStyles = getSearchStyles(theme.color['text-default']);

  return (
    <KBarPortal>
      <KBarPositioner>
        <KBarAnimator>
          <MainWrapper>
            <SearchInputWrapper>
              <KBarSearch style={searchStyles} />
            </SearchInputWrapper>
            <KBarResults
              items={matches.results}
              maxHeight={maxHeight}
              onRender={({ item, active }) => <SearchItem item={item} active={active} />}
            />
          </MainWrapper>
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
};

export const KBar: React.FC = ({ children }) => (
  <Provider>
    <Content />

    {children}
  </Provider>
);

/**
 * Will add actions for the current patient to the actions list and will remove on dismount
 */
export const useKBarPatientActions: UseKBarPatientActionsHook = (patientId, patientName) => {
  const navigate = useLinkTo();
  const { query } = useKBar();

  const currentPatientAction: Action = {
    id: 'current-patient',
    name: patientName,
    subtitle: strings.KBarCurrentPatientActionTitleSubtitle,
    iconName: 'account-circle',
    priority: 2,
  };

  const patientActions = getPatientActions({
    navigate,
    parentId: currentPatientAction.id,
    patientId,
    patientName,
    priority: 2,
  });

  const actions = [currentPatientAction, ...patientActions];

  // make the actions dependant query as well since the query may not be defined on initial mount
  useRegisterActions(query ? actions : [], [patientId, patientName, query]);
};

export const useToggle: UseKBarToggleHook = () => {
  const { query } = useKBar();

  // To handle the case where the KBar is not initially mounted
  if (!query?.toggle) {
    return () => undefined;
  }

  return query.toggle;
};
