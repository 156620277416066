import { quantile } from 'd3-array';
import { FONT_FAMILY, FONT_SIZE, Visible } from 'design-system';
import { integerFormatter, noOp } from 'helpers';
import React, { useState } from 'react';
import { PanGestureHandler } from 'react-native-gesture-handler';
import Animated, {
  useAnimatedGestureHandler,
  useAnimatedProps,
  useSharedValue,
} from 'react-native-reanimated';
import Svg, { Circle, G, Line, LineProps, Rect, SvgProps, Text, TextProps } from 'react-native-svg';

import { usePlotDataContext } from './PlotDataProvider';

const AnimatedSvg = Animated.createAnimatedComponent(Svg);
const AnimatedLine = Animated.createAnimatedComponent(Line);
const AnimatedText = Animated.createAnimatedComponent(Text);

interface SliderProps {
  initialValue: number;
  radius?: number;
  backgroundColor?: string;
  foregroundColor?: string;
  trackColor?: string;
  hidden?: boolean;
  isRendered?: boolean;
  hasTrack?: boolean;
  hasMarker?: boolean;
  markerColor?: string;
  hasText?: boolean;
  textColor?: string;
  textSize?: number;
  textFont?: string;
  valueFormatter?: (value?: number) => string;
  onChange: (value: number) => void;
}

export const Slider: React.FC<SliderProps> = (props) => {
  const {
    onChange,
    xScreenScale,
    yScreenScale,
    xScale,
    layout,
    baseWidth,
    baseHeight,
    xDomain,
    initialValue,
    radius = 12,
    trackColor = 'lightgrey',
    backgroundColor = 'white',
    foregroundColor = 'black',
    hidden = false,
    isRendered = true,
    hasTrack,
    hasMarker,
    markerColor = 'white',
    hasText = false,
    textColor = 'white',
    textFont = FONT_FAMILY.regular,
    textSize = FONT_SIZE.caption,
    valueFormatter = integerFormatter,
  } = usePlotDataContext(props);

  const iconSize = radius * 2;
  const yOffset = radius * 1.2;

  const min = xDomain[0];
  const max = xDomain[1];

  const startX = xScale(min);
  const endX = xScale(max);
  const svgDomain = [startX, endX];
  const midX = quantile(svgDomain, 0.5) as number;
  const nearStartX = quantile(svgDomain, 0.2) as number;
  const nearEndX = quantile(svgDomain, 0.8) as number;

  const [valueDisplay, setValueDisplay] = useState(valueFormatter(initialValue));

  const sharedSetValueDisplay = useSharedValue(setValueDisplay);
  const sharedChange = useSharedValue(onChange);
  const sharedDataValue = useSharedValue(initialValue);

  const gestureHandler = useAnimatedGestureHandler(
    {
      onActive: (e) => {
        const svgValue = xScreenScale(e.x);
        const dataValue = xScale.invert(svgValue);

        if (dataValue >= min && dataValue <= max) {
          sharedDataValue.value = dataValue;
          sharedChange.value(dataValue);
          sharedSetValueDisplay.value(valueFormatter(dataValue));
        }
      },
    },
    [sharedChange, sharedSetValueDisplay]
  );

  const rectWidth = xScreenScale(layout?.width || baseWidth);
  const rectHeight = yScreenScale(layout?.height || baseHeight);

  const trackWidth = radius / 2;
  const trackOffset = yOffset + radius;

  const textOffset = baseHeight - FONT_SIZE.caption / 2;

  const svgAnimatedProps = useAnimatedProps<SvgProps>(
    () => ({
      x: xScale(sharedDataValue.value) - radius,
    }),
    [xScale, sharedDataValue, radius]
  );

  const markerAnimatedProps = useAnimatedProps<LineProps>(() => {
    const x = xScale(sharedDataValue.value);
    return {
      x1: x,
      x2: x,
    };
  }, [xScale, sharedDataValue, radius]);

  const textAnimatedProps = useAnimatedProps<TextProps>(() => {
    const xBase = xScale(sharedDataValue.value);

    // if there is a marker we always want to pick a side so as not to overlap the line
    if (hasMarker) {
      const isPastMidpoint = xBase >= midX;
      const fallbackAnchor = isPastMidpoint ? 'end' : 'start';
      const offset = isPastMidpoint ? -4 : 4;

      return {
        x: xBase + offset,
        textAnchor: fallbackAnchor,
      };
    }

    // if the text is near the end then we want to anchor it on the right and shift
    if (xBase > nearEndX) {
      return {
        x: xBase + radius,
        textAnchor: 'end',
      };
    }

    // if the text is near the start then we want to anchor it on the left and shift
    if (xBase < nearStartX) {
      return {
        x: xBase - radius,
        textAnchor: 'start',
      };
    }

    // return the text center aligned if not an edge case
    return {
      x: xBase,
      textAnchor: 'middle',
    };
  });

  if (!isRendered) {
    return null;
  }

  return (
    <G>
      <Visible if={hasTrack}>
        <Line
          stroke={trackColor}
          strokeWidth={trackWidth}
          x1={startX}
          x2={endX}
          y1={trackOffset}
          y2={trackOffset}
          strokeLinecap="round"
        />
      </Visible>

      <Visible if={hasMarker}>
        <AnimatedLine
          y1={0}
          y2={baseHeight}
          strokeWidth={2}
          stroke={markerColor}
          animatedProps={markerAnimatedProps}
          // Animated SVG Elements need an `onPress` due to a bug with react-native-svg
          // (see: https://github.com/software-mansion/react-native-reanimated/issues/3321)
          onPress={noOp}
        />
      </Visible>

      <Visible if={!hidden}>
        <Visible if={hasText}>
          <AnimatedText
            fontFamily={textFont}
            fontSize={textSize}
            fill={textColor}
            y={textOffset}
            textAnchor="middle"
            pointerEvents="none"
            alignmentBaseline="central"
            animatedProps={textAnimatedProps}
            onPress={noOp}
          >
            {valueDisplay}
          </AnimatedText>
        </Visible>

        <AnimatedSvg
          viewBox="0 0 24 24"
          y={yOffset}
          height={iconSize}
          width={iconSize}
          animatedProps={svgAnimatedProps}
          onPress={noOp}
        >
          <Circle cx="50%" cy="50%" r="11" fill={backgroundColor} />
          <Rect y="5" x="8.5" width="2" height="14" fill={foregroundColor} />
          <Rect y="5" x="13.5" width="2" height="14" fill={foregroundColor} />
        </AnimatedSvg>
      </Visible>

      <PanGestureHandler onGestureEvent={gestureHandler}>
        <Rect x={0} y={0} width={rectWidth} height={rectHeight} fill="transparent" opacity={0.5} />
      </PanGestureHandler>
    </G>
  );
};
