import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { GroupListScreen, GroupScreen } from 'screens/group';

import { GroupStackParamList } from './types';

const Stack = createNativeStackNavigator<GroupStackParamList>();

export const GroupNavigator: React.FC = () => (
  <Stack.Navigator initialRouteName="List">
    <Stack.Screen
      name="List"
      component={GroupListScreen}
      options={{
        title: 'Groups',
        headerShown: false,
      }}
    />
    <Stack.Screen name="View" component={GroupScreen} options={{ title: 'Group' }} />
  </Stack.Navigator>
);
