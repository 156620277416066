import { ColorName } from '@emotion/react';
import { MeasurementName, Vitals } from '@quromedical/models';
import {
  calculateQuroScore,
  Consciousness,
  NEWS2ClinicalRisk,
  OxygenSaturationScale,
  QuroScoreResult,
} from '@quromedical/news';
import { Domain } from 'components/graph';
import { StatusBarBound, StatusBarProps } from 'design-system/components';
import { logger } from 'helpers';
import { strings } from 'strings';

export const news2RiskColorMap: Record<NEWS2ClinicalRisk, ColorName> = {
  [NEWS2ClinicalRisk.Low]: 'status-success',
  [NEWS2ClinicalRisk.LowMedium]: 'status-warning',
  [NEWS2ClinicalRisk.Medium]: 'status-warning',
  [NEWS2ClinicalRisk.High]: 'status-critical',
};

const news2RiskDisplayMap: Record<NEWS2ClinicalRisk, string> = {
  [NEWS2ClinicalRisk.Low]: strings.NEWS2ScoreLabelLowRisk,
  [NEWS2ClinicalRisk.LowMedium]: strings.NEWS2ScoreLabelLowMediumRisk,
  [NEWS2ClinicalRisk.Medium]: strings.NEWS2ScoreLabelMediumRisk,
  [NEWS2ClinicalRisk.High]: strings.NEWS2ScoreLabelHighRisk,
};

export const news2ShortDisplayMap: Record<NEWS2ClinicalRisk, string> = {
  [NEWS2ClinicalRisk.Low]: strings.NEWS2ScoreLabelShortLowRisk,
  [NEWS2ClinicalRisk.LowMedium]: strings.NEWS2ScoreLabelShortLowMediumRisk,
  [NEWS2ClinicalRisk.Medium]: strings.NEWS2ScoreLabelShortMediumRisk,
  [NEWS2ClinicalRisk.High]: strings.NEWS2ScoreLabelShortHighRisk,
};

const oxygenScaleDisplayMap: Record<OxygenSaturationScale, string> = {
  [OxygenSaturationScale.Scale1]: strings.NEWS2OxygenSaturationLabelScale1,
  [OxygenSaturationScale.Scale2Oxygen]: strings.NEWS2OxygenSaturationLabelScale2Oxygen,
  [OxygenSaturationScale.Scale2Air]: strings.NEWS2OxygenSaturationLabelScale2Air,
};

/**
 * need to consider what happens if the risk is low-medium since that isn't associated with a
 * specific score but is rather a result of a specific metric
 */
const news2StatusBounds: StatusBarBound[] = [
  // start bound from -1 to 0 to represent unknown scores
  {
    start: -1,
    end: 0,
    color: 'divider',
  },
  // bounds adjusted and not derived since the NEWS scoring bounds are non-continuos using the .5
  // also allows us to ensure that if we're at an edge value the user still sees a clear answer
  {
    color: news2RiskColorMap.low,
    start: 0,
    end: 4.5,
  },
  {
    color: news2RiskColorMap.medium,
    start: 4.5,
    end: 6.5,
  },
  {
    color: news2RiskColorMap.high,
    start: 6.5,
    end: 21,
  },
];

/**
 * Assumes defaults for the oxygen scale and consciousness since we don't have that currently
 */
export const getNEWS2Score = (
  latest: Partial<Record<Vitals.MeasurementName, Vitals.LatestResult>>,
  oxygenSaturationScale: OxygenSaturationScale,
  consciousness = Consciousness.Alert
): QuroScoreResult | undefined => {
  const oxygenSaturation = latest[MeasurementName.OxygenSaturation]?.value;
  const pulseRate = latest[MeasurementName.HeartRate]?.value;
  const respirationRate = latest[MeasurementName.RespiratoryRate]?.value;
  const systolicBloodPressure = latest[MeasurementName.SystolicArterialBloodPressure]?.value;
  const temperature = latest[MeasurementName.CoreTemperature]?.value;

  const hasAllRequired = pulseRate && respirationRate && temperature;

  if (!hasAllRequired) {
    return undefined;
  }

  try {
    return calculateQuroScore({
      oxygenSaturationScale,
      consciousness,

      oxygenSaturation,

      pulseRate,
      respirationRate,
      systolicBloodPressure,
      temperature,
    });
  } catch (err) {
    logger.error(err);
    return undefined;
  }
};

const news2ScoreDomain: Domain = [-1, 21];

export const getNEWS2Display = (score?: QuroScoreResult, shortDisplay = false): string => {
  const displayMap = shortDisplay ? news2ShortDisplayMap : news2RiskDisplayMap;

  const statusDisplay = score ? displayMap[score.risk] : strings.NEWS2ScoreLabelUnknownRisk;

  const valueText = score ? `${statusDisplay} (${score.score})` : statusDisplay;
  return valueText;
};

export const getNEWS2StatusBarProps = (
  latest: Partial<Record<Vitals.MeasurementName, Vitals.LatestResult>>,
  oxygenSaturationScale: OxygenSaturationScale
): Omit<StatusBarProps, 'title'> => {
  const score = getNEWS2Score(latest, oxygenSaturationScale);
  const valueText = getNEWS2Display(score);

  const subtitle = oxygenScaleDisplayMap[oxygenSaturationScale];

  // if there is no score we move it back so that it appears in the starting segment
  const value = score?.score || -0.5;
  const boundPreference = 'end';

  return {
    value,
    valueText,
    boundPreference,
    domain: news2ScoreDomain,
    bounds: news2StatusBounds,
    subtitle,
  };
};

export const getPatientNEWS2Score = (
  vitals?: Vitals.LiveDataMeasures,
  oxygen?: fhir4.Quantity
): QuroScoreResult | undefined => {
  const latest = vitals?.latest;

  if (!latest) {
    return undefined;
  }

  // assumes that a patient who is not on oxygen is on scale 1, we currently don't differentiate for
  // scale2 air
  const oxygenSaturationScale = oxygen
    ? OxygenSaturationScale.Scale2Oxygen
    : OxygenSaturationScale.Scale1;

  // assumes patient is conscious
  const newsScore = getNEWS2Score(latest, oxygenSaturationScale);

  return newsScore;
};

/**
 * Any patient with a NEWS2 score that is not low risk requires an urgent response
 */
export const requiresUrgentResponse = (risk: NEWS2ClinicalRisk): boolean =>
  risk !== NEWS2ClinicalRisk.Low;

export const getOxygenSaturationScale = (oxygen?: fhir4.Quantity) =>
  oxygen ? OxygenSaturationScale.Scale2Oxygen : OxygenSaturationScale.Scale1;
