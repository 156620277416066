import { Practitioner, Validations } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { Field, SelectOption } from 'components/types';
import { PractitionerQualification } from 'fhir/r4';
import { FormikErrors } from 'formik';
import { qualificationFetcher } from 'integration';
import React, { useCallback } from 'react';
import { strings } from 'strings';

interface PractitionerQualificationFormProps {
  onValidChange: (data?: Practitioner.CreateQualificationFields) => void;
  showErrors: boolean;
}

export const createQualificationFields = (
  initialQualification?: SelectOption<PractitionerQualification>
): Field<Practitioner.CreateQualificationFields>[] => {
  const initialSelection = initialQualification && [initialQualification];

  return [
    {
      subfields: [
        {
          type: 'combobox-single',
          searchable: true,
          label: strings.Specialty,
          key: 'qualificationCode',
          initialSelection,
          fetcher: qualificationFetcher,
        },
        {
          key: 'registrationNumber',
          label: strings.RegistrationNumber,
          type: 'text-box',
        },
      ],
    },
  ];
};

export const PractitionerQualificationForm: React.FC<PractitionerQualificationFormProps> = ({
  onValidChange,
  showErrors,
}) => {
  const fields = createQualificationFields();

  const onChange = useCallback(
    (
      data: Partial<Practitioner.CreateQualificationFields>,
      _: FormikErrors<Practitioner.CreateQualificationFields>,
      hasErrors: boolean
    ) => {
      if (hasErrors) {
        onValidChange(undefined);
      } else {
        // casting since data is validated
        const result = data as Practitioner.CreateQualificationFields;

        onValidChange(result);
      }
    },
    [onValidChange]
  );

  return (
    <CrudForm
      title={strings.FormLabelPractitionerQualificationDetails}
      fields={fields}
      initialValues={{}}
      validationSchema={Validations.practitionerCreateSchema.fields.qualification}
      onChange={onChange}
      showErrors={showErrors}
      validateOnMount
      showSubmitButton={false}
      hasCard={false}
    />
  );
};
