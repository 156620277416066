import styled from '@emotion/native';
import { Util } from '@quromedical/utils';
import { Col, Row } from 'components/base';
import { Field, FieldData, FieldType } from 'components/types';
import {
  Icon,
  Button,
  Card,
  CardProps,
  Visible,
  Spacer,
  Text,
  ButtonTypeVariant,
} from 'design-system/base';
import { MARGIN } from 'design-system/theme';
import { FormikErrors, useFormik } from 'formik';
import { noOp } from 'helpers';
import { parseError } from 'helpers/error';
import get from 'lodash.get';
import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useWindowDimensions } from 'react-native';
import { useAsyncDebounce } from 'react-table';
import { strings } from 'strings';
import { SCREEN_SIZE } from 'styles';
import { AnySchema } from 'yup';

import { SelectiveControl } from './internal';

const CardlessWrapper: React.FC = ({ children }) => <Col unsetZIndex>{children}</Col>;

const ChildrenWrapper = styled(Col)({
  marginTop: MARGIN['2xs'],
});

const ControlWrapper = styled(Row)(() => ({
  flex: 1,
  marginTop: MARGIN['2xs'],
  marginHorizontal: MARGIN['3xs'],
}));

const ButtonWrapper = styled(Row)({
  marginTop: MARGIN['2xs'],
  width: '100%',
  justifyContent: 'flex-end',
});

const IconWrapper = styled.View({
  marginRight: MARGIN.s,
  height: 24,
  width: 24,
});

const IconCol = styled(Col)({
  alignSelf: 'center',
  justifyContent: 'center',
});

interface FieldWrapperProps {
  isNotResponsive: boolean;
  useColLayout: boolean;
}

const FieldWrapper: React.FC<FieldWrapperProps> = ({ isNotResponsive, useColLayout, children }) => {
  if (isNotResponsive) {
    return (
      <Row alignItems="flex-start" justifyContent="flex-start" fullWidth unsetZIndex>
        {children}
      </Row>
    );
  }

  const Wrapper = useColLayout ? Col : Row;

  return (
    <Wrapper alignItems="flex-start" justifyContent="flex-start" fullWidth unsetZIndex>
      {children}
    </Wrapper>
  );
};

/**
 * Used to resolve the error message in the presence of a overriding field message.
 *
 * If `fieldMessage` is false then never display an error, otherwise fallback to the appropriate
 * message
 */
const resolveErrorMessage = (
  baseMessage?: string,
  fieldMessage?: false | string
): string | undefined => {
  if (fieldMessage === false) {
    return undefined;
  }

  if (baseMessage && fieldMessage) {
    return fieldMessage;
  }

  return baseMessage;
};

export type SubmissionResult<TError = unknown> = { error?: TError };

export type SubmissionHandler<TData> = Util.SyncOrAsync<(data: TData) => SubmissionResult>;

type FormState =
  | { type: 'editing' }
  | { type: 'submitting' }
  | { type: 'submission-success' }
  | { type: 'submission-error'; error: string };

interface CrudFormProps<TData extends FieldData<TData>> extends PropsWithChildren<{}> {
  /**
   * Title for the form
   */
  title?: string;
  /**
   * Initial data for populating form fields
   */
  initialValues: Partial<TData>;
  /**
   * Form layout configuration
   */
  fields: Field<TData>[];
  validationSchema?: AnySchema;
  buttonText?: string;
  secondaryButtonText?: string;
  validateOnChange?: boolean;
  validateOnMount?: boolean;
  /**
   * If the submit button should be show, defaults to `true`
   */
  showSubmitButton?: boolean;
  showErrors?: boolean;
  onSubmit?: SubmissionHandler<TData>;
  showSecondarySubmitButton?: boolean;
  onSecondarySubmit?: () => void;
  /**
   * Will be called whenever the `data` value changes, ensure that you're not setting the data of
   * the component via this handler or the component may end up in an infinite render state
   */
  onChange?: (data: TData, errors: FormikErrors<TData>, hasErrors: boolean) => void;
  /**
   * Will send back data if it is valid, otherwise will pass undefined
   */
  onValidChange?: (data?: TData) => void;
  /**
   * Duration for debouncing on-change even propagation to parent component
   */
  onChangeDebounceTime?: number;
  /**
   * Will disable all components in the form if true
   */
  disabled?: boolean;
  includeIcons?: boolean;
  hasCard?: boolean;
  cardProps?: CardProps;
  /**
   * Remove responsive layout and use it as per the exact form configuration. This allows the parent
   * to modify and define the row/col relationships. This is sometimes needed in order to override
   * the default form layout for mobile in complex layout conditions where the default
   * responsiveness causes fields to be laid out in a way that isn't fully compatible with the react
   * native layout functionality
   */
  isNotResponsive?: boolean;
  /**
   * Submission or other error message to display on the form if necessary
   */
  error?: string;
  buttonVariant?: ButtonTypeVariant;

  /**
   * Any additional content to be rendered before the submission section of the form
   */
  children?: ReactNode;
}

const fallbackSubmit = () => ({});

/**
 * Generic CRUD form which can be configured to work with any flattened data structure. The layout
 * can also be changed by updating the `fields` prop with a combination of the `useDimension` hook.
 * Form state wil be persisted between renders
 * Currently supports:
 * - Text input
 * - Text area
 * - Date selector
 * - Dropdown
 * - Multiselect
 * - Toggle
 */
export const CrudForm = <TData extends FieldData<TData> = {}>({
  title,
  buttonText = 'Submit',
  secondaryButtonText = 'Cancel',
  initialValues,
  fields,
  validationSchema,
  validateOnChange,
  showErrors = false,
  showSubmitButton = true,
  buttonVariant = 'contained',
  showSecondarySubmitButton = false,
  includeIcons = false,
  onSubmit = fallbackSubmit,
  onChange = noOp,
  onValidChange,
  onChangeDebounceTime = 1000,
  hasCard = true,
  cardProps = {},
  disabled = false,
  validateOnMount = false,
  isNotResponsive = false,
  onSecondarySubmit = noOp,
  error: formError,
  children,
}: CrudFormProps<TData>): ReactElement => {
  const [formState, setFormState] = useState<FormState>({ type: 'editing' });

  const onSubmitWithError = useCallback(
    async (data: TData) => {
      setFormState({ type: 'submitting' });
      const result = await onSubmit(data);

      if (result.error) {
        const errorMessage = parseError(result.error) || strings.GenericErrorMessage;
        setFormState({ type: 'submission-error', error: errorMessage });
      } else {
        setFormState({ type: 'submission-success' });
      }
    },
    [onSubmit]
  );

  const { values, setFieldValue, handleSubmit, errors, submitCount } = useFormik<TData>({
    // pretend to be TData here, validation should ensure the data is complete
    initialValues: initialValues as TData,
    onSubmit: onSubmitWithError,
    validationSchema,
    validateOnChange,
    validateOnMount,
  });

  const { width } = useWindowDimensions();
  const isSmallScreen = width < SCREEN_SIZE.md;

  const submissionError = formState.type === 'submission-error' ? formState.error : undefined;
  const errorsVisible = showErrors || submitCount > 0;
  const displayError = formError || submissionError;

  const isSubmitting = formState.type === 'submitting';
  const isDisabled = disabled || isSubmitting;

  const handleChange = useCallback(
    (data: TData, err: FormikErrors<TData>, hasErrors: boolean) => {
      onValidChange?.(hasErrors ? undefined : data);
      onChange(data, err, hasErrors);
    },
    [onChange, onValidChange]
  );

  const onChangeDebounced = useAsyncDebounce(handleChange, onChangeDebounceTime);

  useEffect(() => {
    const hasErrors = Object.keys(errors).length > 0;
    onChangeDebounced(values, errors, hasErrors);
  }, [values, errors, onChangeDebounced]);

  const FormWrapper = hasCard ? Card : CardlessWrapper;

  return (
    <FormWrapper {...cardProps} unsetZIndex>
      {title ? <Text variant="heading">{title}</Text> : null}
      <Col alignItems="center" justifyContent="center" fullWidth unsetZIndex>
        {fields.map((row, key) => {
          const useColLayout = isSmallScreen;
          const isResponsive = !isNotResponsive;

          const showFieldIcon = includeIcons && useColLayout && isResponsive;

          // show the row icon if we want to see icons but aren't already showing the field icon
          const showRowIcon = includeIcons && !showFieldIcon;

          return (
            <FieldWrapper isNotResponsive={isNotResponsive} useColLayout={useColLayout} key={key}>
              <IconCol isVisible={showRowIcon}>
                <IconWrapper>{row.icon ? <Icon name={row.icon} /> : null}</IconWrapper>
              </IconCol>
              {row.subfields.map((field, index) => {
                const isFirstInRow = index === 0;

                const value = get(values, field.key);

                const baseError = get(errors, field.key) as string | undefined;

                const error = resolveErrorMessage(baseError, field.errorMessage);

                return (
                  <Row key={field.key.toString()} flex={1} fullWidth unsetZIndex>
                    <IconCol isVisible={showFieldIcon}>
                      <IconWrapper>
                        {isFirstInRow && row.icon ? <Icon name={row.icon} /> : null}
                      </IconWrapper>
                    </IconCol>
                    <ControlWrapper fullWidth unsetZIndex>
                      <SelectiveControl
                        disabled={isDisabled}
                        config={field}
                        value={value as FieldType}
                        onChange={setFieldValue.bind({}, field.key as string)}
                        errors={errorsVisible ? error : undefined}
                      />
                    </ControlWrapper>
                  </Row>
                );
              })}
            </FieldWrapper>
          );
        })}
        {children ? (
          <ChildrenWrapper unsetZIndex fullWidth>
            {children}
          </ChildrenWrapper>
        ) : null}
        <Row isVisible={!!displayError} fullWidth justifyContent="flex-start">
          <Text color="status-critical">{displayError}</Text>
        </Row>
        <ButtonWrapper isVisible={showSecondarySubmitButton || showSubmitButton}>
          <Visible if={showSecondarySubmitButton}>
            <Button
              disabled={isDisabled}
              variant="flat"
              onPress={onSecondarySubmit}
              text={secondaryButtonText}
            />
          </Visible>
          <Spacer width={MARGIN.m} />
          <Visible if={showSubmitButton}>
            <Button
              disabled={isDisabled}
              variant={buttonVariant}
              onPress={handleSubmit}
              text={buttonText}
            />
          </Visible>
        </ButtonWrapper>
      </Col>
    </FormWrapper>
  );
};
