import { useCallback, useState } from 'react';

interface UseFiniteStateHook<TState extends string> {
  value: TState;
  set: (state: TState) => void;
  /**
   * Gets a handler that when called will set the state to `nextState`
   */
  next: (nextState: TState) => () => void;
  /**
   * Check if the `state` is the current state
   */
  is: (state: TState) => boolean;
  /**
   * Check if the `state` is not the current state (opposite of state.is)
   */
  isNot: (state: TState) => boolean;
}

/**
 * @example
 * const state = useFiniteState('initial');
 *
 * // onPress will then set the state to  'loading'
 * <Button onPress={state.getNextHandler('loading')} />
 *
 * // can also be used to check if the state is a specific value
 * <Visible if={state.is('loading)}>
 *  <Loading />
 * </Visible>
 *
 */
export const useFiniteState = <TState extends string>(
  initial: TState
): UseFiniteStateHook<TState> => {
  const [value, set] = useState<TState>(initial);

  const next = (nextState: TState) => () => set(nextState);
  const is = useCallback((check: TState) => value === check, [value]);
  const isNot = useCallback((check: TState) => value !== check, [value]);

  return {
    value,
    set,
    next,
    is,
    isNot,
  };
};
