import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Row, Col } from 'components/base';
import { Text, Spacer } from 'design-system/base';
import { BASE_SIZE, BORDER_RADIUS, MARGIN } from 'design-system/theme';
import React from 'react';

type Display = (value: number, total: number) => string;

const defaultDisplay: Display = (value, total) => `${value}/${total}`;

interface LineLoaderProps {
  value: number;
  total: number;
  status: LineLoaderStatus;
  display?: Display;
}

export type LineLoaderStatus = 'loading' | 'done' | 'error';

interface SliderProps {
  progress?: number;
  status: LineLoaderStatus;
}

interface SliderHolderProps {
  status: LineLoaderStatus;
}

const statusColorMap: Record<LineLoaderStatus, ColorName> = {
  done: 'status-success',
  error: 'status-critical',
  loading: 'accent-blue',
};

const Slider = styled(Col)<SliderProps>(({ theme, progress = 0, status = 'loading' }) => {
  const colorName = statusColorMap[status];
  const backgroundColor = theme.color[colorName];

  return {
    backgroundColor,
    borderRadius: BORDER_RADIUS.full,
    height: BASE_SIZE[8],
    width: `${progress * 100}%`,
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    maxWidth: '100%',
  };
});

const SliderHolder = styled(Col)<SliderHolderProps>(({ theme, status = 'loading' }) => {
  const baseColor = theme.color['base-grey'];
  const errorColor = theme.color['status-critical-20'];

  const hasError = status === 'error';
  const backgroundColor = hasError ? errorColor : baseColor;

  return {
    backgroundColor,
    borderRadius: BORDER_RADIUS.full,
    width: '100%',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
  };
});

export const LineLoader: React.FC<LineLoaderProps> = ({
  value,
  total,
  status,
  display = defaultDisplay,
}) => (
  <Row justifyContent="center">
    <Col justifyContent="center" fullWidth={true} flex={1}>
      <SliderHolder status={status}>
        <Slider progress={value / total} status={status} />
      </SliderHolder>
    </Col>
    <Spacer width={MARGIN.s} />
    <Col justifyContent="flex-end">
      <Text variant="caption">{display(value, total)}</Text>
    </Col>
  </Row>
);
