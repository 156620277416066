import { GroupSecurity } from '@quromedical/fhir-common';
import { MeasurementName, Websocket } from '@quromedical/models';
import { Col } from 'components/base';
import { WithGroup } from 'core/auth';
import { getAlertTitle, RealtimeAlertsList } from 'core/vitals';
import { MARGIN, Spacer } from 'design-system';
import { AlertCommentForm, AlertFormData } from 'design-system/components';
import { Grid } from 'design-system/layout';
import { useUserSession } from 'hooks';
import { useResponsive } from 'hooks/useResponsive';
import { PatientApi } from 'integration/aggregate';
import { usePatientId } from 'providers/PatientIdContext';
import { usePatientVitals } from 'providers/PatientVitalsProvider';
import React, { useCallback, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { VitalsGridItem } from './VitalsGridItem';

interface VitalsActiveGridProps {
  scrollToTop?: () => void;
  measurements: MeasurementName[];
}

const alertVitalsFocus: Record<Websocket.AlertType, MeasurementName> = {
  'ecg-sinus-rhythm': MeasurementName.HeartRate,
};

const api = new PatientApi();

export const VitalsActiveGrid: React.FC<VitalsActiveGridProps> = ({
  scrollToTop,
  measurements,
}) => {
  const session = useUserSession();

  // only users who are allowed to manage device use statements should care about filters
  const canViewFilter = session.hasAny([
    GroupSecurity.DeviceUseStatementCreate,
    GroupSecurity.DeviceUseStatementAdmin,
  ]);

  const canAddComment = session.hasAny([GroupSecurity.AlertCommentCreate]);

  const { width } = useWindowDimensions();
  const [expanded, setExpanded] = useState<MeasurementName | undefined>();

  const toggleExpanded = useCallback(
    (measureName: MeasurementName) => {
      if (measureName === expanded) {
        setExpanded(undefined);
      } else {
        setExpanded(measureName);
      }

      if (scrollToTop) {
        scrollToTop();
      }
    },
    [expanded, scrollToTop]
  );

  const columns = useResponsive({ md: 2 }, 1);
  const initialWidth = width / columns;

  const patientId = usePatientId();
  const { timeFrame, vitals, latest, onRefresh, setTimes, times } = usePatientVitals();

  const filteredMeasures = measurements.filter((key) => key !== expanded);

  const [activeAlert, setActiveAlert] = useState<Websocket.Alert>();

  const onAlertPress = useCallback(
    (alert: Websocket.Alert) => {
      setActiveAlert(alert);

      if (!times.duration) {
        return;
      }

      const centerPoint = alert.ts;
      const offset = times.duration / 2;
      const start = centerPoint - offset;

      setTimes({
        duration: times.duration,
        start: new Date(start),
      });

      setExpanded(alertVitalsFocus[alert.type]);
      scrollToTop?.();
    },
    [times.duration, setTimes, scrollToTop]
  );

  const onSubmit = useCallback(
    async (formData: AlertFormData) => {
      if (!activeAlert) {
        return;
      }

      await api.addAlertComment(patientId, {
        ...formData,
        alertTs: activeAlert.ts,
      });
    },

    [activeAlert, patientId]
  );

  const alertKey = activeAlert ? `${activeAlert?.type}-${activeAlert.ts}` : '';

  return (
    <>
      <WithGroup groups={[GroupSecurity.AlertEWS]}>
        {activeAlert ? (
          <Col>
            <Grid sm={1} md={2} gap="m">
              <AlertCommentForm
                canAddComment={canAddComment}
                key={alertKey}
                alertTitle={getAlertTitle(activeAlert)}
                alertTs={activeAlert.ts}
                onSubmit={onSubmit}
              />
            </Grid>
            <Spacer height={MARGIN.m} />
          </Col>
        ) : null}
      </WithGroup>
      {expanded ? (
        <>
          <VitalsGridItem
            key={alertKey}
            revalidate={onRefresh}
            patientId={patientId}
            measureName={expanded}
            primaryGraphMeasureName={expanded}
            vitals={vitals[expanded]}
            latest={latest[expanded]}
            initialWidth={width}
            timeFrame={timeFrame}
            isExpanded={true}
            onExpandToggled={toggleExpanded}
            canViewFilter={canViewFilter}
          />
          <Spacer height={MARGIN.m} />
        </>
      ) : null}

      <Grid sm={1} md={2} gap="m">
        {filteredMeasures.map((key) => (
          <VitalsGridItem
            revalidate={onRefresh}
            patientId={patientId}
            key={key}
            measureName={key}
            primaryGraphMeasureName={key}
            vitals={vitals[key]}
            latest={latest[key]}
            initialWidth={initialWidth}
            timeFrame={timeFrame}
            isExpanded={false}
            onExpandToggled={toggleExpanded}
            canViewFilter={canViewFilter}
          />
        ))}
        <WithGroup groups={[GroupSecurity.AlertEWS]}>
          <Col>
            <RealtimeAlertsList patientId={patientId} onPress={onAlertPress} />
          </Col>
        </WithGroup>
      </Grid>
    </>
  );
};
