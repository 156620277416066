import {
  DeviceCode,
  getResourcesFromBundle,
  GroupCode,
  OrganizationCode,
} from '@quromedical/fhir-common';
import { Medication, Organization, Person } from '@quromedical/models';
import { SelectOption } from 'components/types';
import { getDisplayName } from 'core/person';
import { AsyncFetcher, Fetcher } from 'design-system';
import { PractitionerQualification } from 'fhir/r4';
import first from 'lodash.first';
import { getAddressParts } from 'screens/person';
import { strings } from 'strings';

import {
  ChargeItemApi,
  DeviceApi,
  GooglePlaceApi,
  GroupApi,
  MedicalAidApi,
  MedicationApi,
  PatientApi,
  PractitionerApi,
  PractitionerQualificationApi,
} from './aggregate';
import { OrganizationApi as AggregateOrganizationApi } from './aggregate';
import { OrganizationApi } from './OrganizationApi';
import { PathologyTestSearchResult, ServiceRequestApi } from './ServiceRequestApi';
import { ValueSetApi } from './ValueSetApi';

const patientApi = new PatientApi();
const deviceApi = new DeviceApi();
const organizationApi = new OrganizationApi();
const practitionerApi = new PractitionerApi();
const practitionerQualificationApi = new PractitionerQualificationApi();
const groupApi = new GroupApi();
const serviceRequestApi = new ServiceRequestApi();
const valueSetApi = new ValueSetApi();
const medicationApi = new MedicationApi();
const googlePlaceApi = new GooglePlaceApi();

const hasValue = (value?: string): value is string => !!value;

export const patientFetcher = async (name?: string) => {
  const { patients } = await patientApi.getPatients({ name });

  const options = patients
    .map((p) => ({
      value: p.id,
      display: getDisplayName(p.general) || strings.DisplayNotFound,
    }))
    .filter((option) => hasValue(option.display) && hasValue(option.value));

  return options;
};

export const activeDeviceFetcher =
  (type?: DeviceCode): AsyncFetcher<string, undefined> =>
  async (query?: string) => {
    const result = await deviceApi.getDevices({ status: 'active', type, 'device-name': query });

    return result.devices.map((device) => ({
      value: device.id,
      display: device.name || strings.DisplayNotFound,
    }));
  };

export const relayDeviceFetcher = async (query?: string): Promise<SelectOption[]> => {
  const result = await deviceApi.getDevices({
    identifier: DeviceCode.relay,
    'device-name': query,
    status: 'active',
  });
  return result.devices.map((device) => ({
    display: device.name || '',
    value: device.id,
  }));
};

export const patchDeviceFetcher = async (query?: string): Promise<SelectOption[]> => {
  const result = await deviceApi.getDevices({
    identifier: DeviceCode.patch,
    'device-name': query,
    status: 'active',
  });
  return result.devices.map((device) => ({
    display: device.name || '',
    value: device.id,
  }));
};

export const deviceByTypeFetcher =
  (type?: DeviceCode) =>
  async (query?: string): Promise<SelectOption[]> => {
    const result = await deviceApi.getDevices({
      'device-name': query,
      type,
    });

    return result.devices.map((device) => ({
      display: device.name || strings.DisplayNotFound,
      value: device.id,
    }));
  };

export const organizationFetcher = async (query?: string): Promise<SelectOption[]> => {
  const result = await organizationApi.getOrganizations({
    name: query,
  });
  const results = getResourcesFromBundle(result).map((organization) => ({
    display: organization.name || '',
    value: organization.id as string,
  }));
  return results;
};

export const teamOrDepartmentOrganizationFetcher = async (
  query?: string
): Promise<SelectOption[]> => {
  const type = [OrganizationCode.OrganizationalTeam, OrganizationCode.HospitalDepartment].join(',');

  const result = await organizationApi.getOrganizations({
    name: query,
    type,
  });
  const results = getResourcesFromBundle(result).map((organization) => ({
    display: organization.name || '',
    value: organization.id as string,
  }));
  return results;
};

export const payorOrganizationFetcher = async (query?: string): Promise<SelectOption[]> => {
  const result = await organizationApi.getOrganizations({
    name: query,
    type: OrganizationCode.Payer,
  });
  const results = getResourcesFromBundle(result).map((organization) => ({
    display: organization.name || '',
    value: organization.id as string,
  }));
  return results;
};

export const practitionerFetcher = async (query?: string): Promise<SelectOption[]> => {
  const result = await practitionerApi.getPractitioners({ name: query });
  return result.practitioners.map((p) => ({
    display: getDisplayName(p.general) || strings.DisplayNotFound,
    value: p.id,
  }));
};

export const icd10CodeFetcher = async (query?: string): Promise<SelectOption[]> => {
  const valueSet = await valueSetApi.getIcd10Codes(query);

  const result = valueSet.compose?.include?.[0].concept?.map((v) => ({
    display: `${v.code}: ${v.display || ''}` || '',
    value: v.code,
  }));

  return result || [];
};

const getMeasureDisplay = (measure?: Medication.Measure) => {
  if (!(measure?.value && measure?.unit)) {
    return '';
  }
  return `${measure.value} ${measure.unit}`;
};

const getMedicationDisplay = (medication: Medication.Medication) => {
  const strength = getMeasureDisplay(medication.strength);
  const routeDescription = Medication.RouteCodeDescription[medication.route];
  return `${medication.name} ${strength} (${routeDescription})`;
};

/** *
 * PresentationCodes are used as filters, if none are provided the result will be unfiltered
 */
export const medicationFetcher =
  (presentationCodes?: Medication.RouteCode[]) =>
  async (query?: string): Promise<SelectOption[]> => {
    const result = await medicationApi.searchMedication({ query, presentationCodes });

    return result.map((p) => ({
      display: getMedicationDisplay(p),
      value: p.code,
    }));
  };

export const clinicalGroupFetcher = async (): Promise<SelectOption[]> => {
  const result = await groupApi.getGroups({
    code: GroupCode.PractitionerClinical,
  });

  return result.groups.map((p) => ({
    display: p.name || '',
    value: p.id as string,
  }));
};

interface AmpathFetcherOptions {
  commonOnly?: boolean;
  specialOrder?: boolean;
  limit?: number;
}

export const ampathTestFetcher =
  (options: AmpathFetcherOptions = {}) =>
  async (query?: string): Promise<SelectOption<PathologyTestSearchResult>[]> => {
    const { commonOnly = false, specialOrder = false, limit = 100 } = options;

    const result = await serviceRequestApi.searchAmpathTests({
      commonOnly,
      specialOrder,
      query,
      limit,
    });

    return result.map((res) => {
      const display = `${res.code} - ${res.description} (${res.department})`;
      return {
        display,
        value: res.code,
        meta: res,
      };
    });
  };

const isValidPractitionerQualification = (
  option: Partial<SelectOption<PractitionerQualification, string>>
): option is SelectOption<PractitionerQualification, string> =>
  hasValue(option.display) && hasValue(option.value) && !!option.meta;

const mapPractitionerQualificationToSelectOption = (
  p: PractitionerQualification
): Partial<SelectOption<PractitionerQualification, string>> => {
  const value = first(p.code.coding)?.code;

  return {
    display: p.code.text || value,
    value,
    meta: p,
  };
};

export const qualificationFetcher: AsyncFetcher<string, PractitionerQualification> = async () => {
  const result = await practitionerQualificationApi.getPractitionerQualifications();
  const options = result
    .map(mapPractitionerQualificationToSelectOption)
    .filter(isValidPractitionerQualification);

  return options;
};

export const googlePlaceFetcher: AsyncFetcher<string> = async (query) => {
  const result = await googlePlaceApi.getPlaces(query);

  return result.predictions.map((p) => ({
    display: p.description || '',
    value: p.place_id,
  }));
};

export const getAddressPartialFetcher = async (
  placeId: string
): Promise<Partial<Person.ContactWithAddressEmergencyAccess>> => {
  const addressDetails = await googlePlaceApi.getAddressDetails(placeId);

  const addressComponents = addressDetails.result.address_components;

  const contact = getAddressParts(addressComponents);

  return contact;
};

const aggregateOrganizationApi = new AggregateOrganizationApi();

export const organizationSchemeCodeFetcher: Fetcher<
  string,
  Organization.InsuranceOrganization
> = async (query?: string) => {
  const orgs = await aggregateOrganizationApi.getInsuranceOrganizations({
    name: query,
  });

  const result = orgs.organizations.map<SelectOption<Organization.InsuranceOrganization, string>>(
    (org) => ({
      display: org.schemeName,
      value: org.schemeCode,
      meta: org,
    })
  );

  return result;
};

const medicalAidApi = new MedicalAidApi();

export const administratorCodeFetcher: Fetcher<string> = async () => {
  const administratorResponse = await medicalAidApi.getAdministrators();

  return administratorResponse.administrators.map((admin) => ({
    display: admin.administratorName,
    value: admin.administratorCode,
  }));
};

export const schemeCodeFetcher: Fetcher<string> = async () => {
  const schemeResponse = await medicalAidApi.getSchemes();

  return schemeResponse.schemes.map((scheme) => ({
    display: scheme.schemeName,
    value: scheme.schemeCode,
  }));
};

const chargeItemApi = new ChargeItemApi();

export const chargeItemFetcher: Fetcher<string> = async (title?: string) => {
  const result = await chargeItemApi.getChargeItems({
    title,
  });

  return result.chargeItems.map((item) => ({
    display: item.title || strings.DisplayNotFound,
    value: item.id,
  }));
};
