import { useVideo } from '@100mslive/react-sdk';
import { Col } from 'components/base';
import { Peer as PeerType } from 'providers/video';
import React from 'react';

interface PeerProps {
  peer: PeerType;
}
const videoStyles: React.CSSProperties = {
  objectFit: 'cover',
  height: '100%',
  width: '100%',
};

export const Peer: React.FC<PeerProps> = ({ peer }) => {
  const { videoRef } = useVideo({
    trackId: peer.trackId,
  });

  return (
    <Col flex={1}>
      <video style={videoStyles} ref={videoRef} autoPlay muted playsInline />
    </Col>
  );
};
