import { getCreateReferenceId, isValidReference } from '@quromedical/fhir-common';
import { Admission, Vitals, Websocket } from '@quromedical/models';
import { useNavigation } from '@react-navigation/native';
import { getDurationInDays, getNameAgeAndGender } from 'core/display';
import {
  removeStaleMeasurements,
  getOxygenSaturationScale,
  getMonitoringConfiguration,
} from 'core/vitals';
import { useActiveAssignedAdmissions } from 'hooks/useActiveAssignedAdmissions';
import { useLiveData } from 'integration/live-vitals';
import orderBy from 'lodash.orderby';
import { useCallback, useState, createContext, useContext } from 'react';

import { SearchData, Layout, DashboardGridData } from './sections';

type PatientAdmission = Admission.GetResponse & Required<Pick<Admission.GetResponse, 'patient'>>;

const isValidAdmission = (admission: Admission.GetResponse): admission is PatientAdmission =>
  isValidReference(admission.patient);

const getDashboardCardProps = (
  liveData: Vitals.LiveDataPayload,
  alerts: Record<string, Websocket.Alert[]>,
  admission: PatientAdmission
): DashboardGridData => {
  const patientId = getCreateReferenceId(admission.patient);
  const patientData = liveData[patientId];

  // if the user is on oxygen then we use Scale 2 Oxygen - otherwise we default to Scale 1
  // we currently don't account for Scale 2 Air which is meant to be used under special instruction
  // cases only
  const oxygenSaturationScale = getOxygenSaturationScale(admission.oxygen);

  const title = getNameAgeAndGender(admission.subject?.general);

  const baseProps: DashboardGridData = {
    title,
    patientId,
    admission,
    oxygenSaturationScale,
    alerts: alerts[patientId],
    serviceType: admission.service,
    servicePeriod: admission.period,
  };

  if (!patientData) {
    return baseProps;
  }

  const { ecg, latest } = patientData;

  const filteredLatest = removeStaleMeasurements(latest, Date.now());

  const hasLatest = !!Object.keys(filteredLatest).length;
  const hasEcg = !!ecg.length;
  const hasData = hasLatest || hasEcg;

  if (!hasData) {
    return baseProps;
  }

  return {
    ...baseProps,
    liveData: {
      ecg,
      latest: filteredLatest,
    },
  };
};

const orderByDataPoints = (filteredPropList: DashboardGridData[]) =>
  orderBy(filteredPropList, (props) => Object.keys(props.liveData?.latest || {}).length, 'desc');

const searchPatients = (
  search: SearchData,
  admissions: PatientAdmission[] = []
): PatientAdmission[] => {
  const cleanSearch = (search.search || '').trim().toLocaleLowerCase();

  const filteredAdmissions = admissions
    .filter((admission) => {
      if (!search.filter) {
        return true;
      }

      return admission.service === search.filter;
    })
    .filter((admission) => {
      if (!search.monitoringConfiguration) {
        return true;
      }

      const monitoringLevel =
        admission.service &&
        getMonitoringConfiguration(admission.service, getDurationInDays(admission.period?.start));

      if (!monitoringLevel) {
        return true;
      }

      return monitoringLevel === search.monitoringConfiguration;
    });

  if (!cleanSearch) {
    return filteredAdmissions;
  }

  return filteredAdmissions.filter((admission) =>
    admission.patient?.display?.toLowerCase()?.includes(cleanSearch)
  );
};

interface DashboardData {
  search: SearchData;
  setSearch: (search: SearchData) => void;
  onLayoutChange: (layout: Layout) => void;
  liveData: Vitals.LiveDataPayload;
  updated: number;

  patients: Admission.GetResponseWithVitals[];
  error: boolean;
  isLoading: boolean;
  data: DashboardGridData[];
}

const DashboardContext = createContext<DashboardData>({
  search: {},
  setSearch: () => undefined,
  onLayoutChange: () => undefined,
  liveData: {},
  updated: 0,
  patients: [],
  error: false,
  isLoading: false,
  data: [],
});

export const DashboardContextProvider: React.FC = ({ children }) => {
  const [search, setSearch] = useState<SearchData>({});

  const navigation = useNavigation();

  const onLayoutChange = useCallback(
    (value: Layout) => {
      const screen = value === 'table' ? 'List' : 'Grid';
      navigation.navigate('Dashboard', {
        screen,
      });
    },
    [navigation]
  );

  const { vitals: liveData, updated, alerts } = useLiveData();

  const { data: patients = [], error, isLoading } = useActiveAssignedAdmissions();

  const getProps = getDashboardCardProps.bind(undefined, liveData, alerts);

  // view dashboard cards for all active patients, even if we don't have fresh data for them
  const uniquePatients = patients.filter(isValidAdmission);
  const searchResults = searchPatients(search, uniquePatients);
  const filteredPropList = searchResults.map(getProps);

  const data = orderByDataPoints(filteredPropList);
  const value: DashboardData = {
    search,
    setSearch,
    onLayoutChange,
    liveData,
    updated,
    patients,
    error: !!error,
    isLoading,
    data,
  };

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};

export const useDashboardContext = () => useContext(DashboardContext);
