import { Claim } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import compact from 'lodash.compact';

enum SingleClaimEndpoint {
  Submit = 'submit',
}

export class ClaimApi {
  private readonly client: BaseApi;
  private readonly root = 'api/aggregate/medical-aid/claim';

  constructor() {
    this.client = new BaseApi();
  }

  private createPath(id?: string, endpoint?: SingleClaimEndpoint) {
    return compact([this.root, id, endpoint]).join('/');
  }

  public getSWRKey(id?: string) {
    return this.createPath(id);
  }

  public async getAll(params: Claim.GetAllParams = {}): Promise<Claim.GetAllResponse> {
    const path = this.createPath();

    const result = await this.client.get<Claim.GetAllResponse>(path, { params });

    return result.data;
  }

  public async getOne(id: string): Promise<Claim.GetResponse> {
    const path = this.createPath(id);

    const result = await this.client.get<Claim.GetResponse>(path);

    return result.data;
  }

  public async update(id: string, body: Claim.UpdateClaimRequest): Promise<Claim.GetResponse> {
    const path = this.createPath(id);

    const result = await this.client.put<Claim.GetResponse>(path, body);

    return result.data;
  }

  public async submit(
    id: string,
    body: Claim.SubmitClaimRequest
  ): Promise<Claim.SubmitClaimResponse> {
    const path = this.createPath(id, SingleClaimEndpoint.Submit);

    const result = await this.client.put<Claim.SubmitClaimResponse>(path, body);

    return result.data;
  }
}
