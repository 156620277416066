import { GooglePlaces } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';

export class GooglePlaceApi {
  private readonly client: BaseApi;
  private readonly aggregateRoot = 'api/aggregate/google-places';
  constructor() {
    this.client = new BaseApi();
  }

  public getPlaces = async (search?: string): Promise<GooglePlaces.PlacePrediction> => {
    const params: GooglePlaces.SearchAddressQueryParams = { search };

    const result = await this.client.get<GooglePlaces.PlacePrediction>(
      `${this.aggregateRoot}/address`,
      {
        params,
      }
    );

    return result.data;
  };

  public getAddressDetails = async (
    placeId: string
  ): Promise<GooglePlaces.AddressDetailsResponse> => {
    const result = await this.client.get<GooglePlaces.AddressDetailsResponse>(
      `${this.aggregateRoot}/place/${placeId}`
    );

    return result.data;
  };
}
