import styled from '@emotion/native';
import { Col, Row } from 'components/base';
import { Text } from 'design-system/base';
import { BORDER_RADIUS, BORDER_WIDTH, MARGIN, BASE_SIZE } from 'design-system/theme';
import { getInitials } from 'helpers/name';
import { useIsSmallerThan } from 'hooks/useResponsive';
import React from 'react';

interface ProfileProps {
  name?: string;
  initials?: string;
  onProfilePress?: () => void;
}

const Pressable = styled.Pressable({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const InitialWrapper = styled(Col)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: BASE_SIZE[36],
  width: BASE_SIZE[36],
  backgroundColor: theme.color.base,
  borderColor: theme.color.divider,
  borderStyle: 'solid',
  borderRadius: BORDER_RADIUS.full,
  borderWidth: BORDER_WIDTH[2],
}));

const TextWrapper = styled(Col)({
  marginRight: MARGIN.xs,
});

/**
 * Note that due to the way overflows and absolute positioning work on native it may be necessary to
 * create a native version of this component that uses relative positioning instead
 */
export const Profile: React.FC<ProfileProps> = ({
  name = '',
  initials = getInitials(name),
  onProfilePress,
}) => {
  const isSmall = useIsSmallerThan('sm');

  return (
    <Row unsetZIndex>
      <Pressable onPress={onProfilePress}>
        <TextWrapper isVisible={!isSmall}>
          <Text selectable={false}>{name}</Text>
        </TextWrapper>
        <InitialWrapper isVisible={!!initials}>
          <Text selectable={false}>{initials}</Text>
        </InitialWrapper>
      </Pressable>
    </Row>
  );
};
