import { Text } from 'design-system';
import React from 'react';

import { BaseWrapper } from './Layout';

interface TitleProps {
  title?: string;
  subtitle?: string;
}

export const Title: React.FC<TitleProps> = ({ title, subtitle }) => (
  <BaseWrapper>
    {title ? <Text variant="subheading">{title}</Text> : null}
    {subtitle ? <Text variant="subheading">{subtitle}</Text> : null}
  </BaseWrapper>
);
