import { GroupSecurity } from '@quromedical/fhir-common';
import { VideoConsult } from '@quromedical/models';
import { WithGroup } from 'core/auth';
import { PatientHeaderSection } from 'core/patient';
import { CreateMeetingCard } from 'core/video-consult';
import { Section, SkeletonProvider } from 'design-system';
import { logger } from 'helpers';
import { VideoConsultApi } from 'integration/aggregate';
import { PatientDrawerScreenProps } from 'navigation';
import { usePatientId } from 'providers/PatientIdContext';
import { useMeeting } from 'providers/video';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';

const videoApi = new VideoConsultApi();

type State = 'initial' | 'loading' | 'error' | VideoConsult.CreateRoomResponse;

export const CreateMeetingScreen: React.FC<PatientDrawerScreenProps<'VideoConsult'>> = () => {
  const patientId = usePatientId();
  const meeting = useMeeting();

  const [state, setState] = useState<State>('initial');

  const room = typeof state !== 'string' ? state : undefined;

  useEffect(() => {
    const exec = async () => {
      setState('loading');
      try {
        const result = await videoApi.createRoom(patientId);
        setState(result);
      } catch (err) {
        setState('error');
        logger.error(err);
      }
    };
    exec().catch(logger.error);
  }, [patientId]);

  const handleJoinWaitingRoom = useCallback(() => {
    if (!room) {
      return;
    }

    meeting.joinWaitingRoom(room.token);
  }, [room, meeting]);

  return (
    <SkeletonProvider loading={!room}>
      <ScrollView>
        <PatientHeaderSection id={patientId} />

        <WithGroup groups={[GroupSecurity.VideoConsultCreate]}>
          <Section>
            <CreateMeetingCard
              patientId={patientId}
              room={room}
              handleJoinWaitingRoom={handleJoinWaitingRoom}
            />
          </Section>
        </WithGroup>
      </ScrollView>
    </SkeletonProvider>
  );
};
