import { getReferenceId, getReferenceType } from '@quromedical/fhir-common';
import { Link } from '@react-navigation/native';
import { Col } from 'components/base';
import { Text, TextVariant } from 'design-system';
import { useFhirData } from 'hooks/useFhirData';
import React from 'react';
import { strings } from 'strings';

import { Fetcher, fetchers, linkMap, SupportedResource } from './internal';

interface DisplayViewProps {
  reference?: fhir4.Reference;
  variant?: TextVariant;
}

interface FetchedViewProps {
  variant?: TextVariant;
  resourceType: SupportedResource;
  id: string;
  fetcher: Fetcher;
}

const FetchedDisplay: React.FC<FetchedViewProps> = ({ resourceType, id, fetcher, variant }) => {
  const { data, error } = useFhirData([resourceType, 'display'], id, fetcher);

  if (error) {
    return <Text variant={variant}>{strings.DisplayError}</Text>;
  }

  const linkTo = linkMap[resourceType](id);

  return (
    <Link to={linkTo}>
      <Text variant={variant}>{data || strings.DisplayLoading}</Text>
    </Link>
  );
};

interface StaticViewProps {
  variant?: TextVariant;
  resourceType: SupportedResource;
  id: string;
  display: string;
}

const StaticDisplay: React.FC<StaticViewProps> = ({ resourceType, id, display, variant }) => {
  const linkTo = linkMap[resourceType](id);

  return (
    <Link to={linkTo}>
      <Text variant={variant}>{display}</Text>
    </Link>
  );
};

/**
 * Used for rendering the `display` property of a `Reference` if it exists, fetches the entity to
 * render a display if one does not exist
 */
export const DisplayView: React.FC<DisplayViewProps> = ({ reference, variant }) => {
  const display = reference?.display;
  const id = getReferenceId(reference);
  const type = getReferenceType(reference);
  const fetcher = type && fetchers[type as SupportedResource];

  // check if we can render something inline, required due to TS inability to infer on current
  // version
  if (!(id && type && fetcher)) {
    return null;
  }

  if (display) {
    return (
      <StaticDisplay
        variant={variant}
        resourceType={type as SupportedResource}
        id={id}
        display={display}
      />
    );
  }

  return (
    <FetchedDisplay
      variant={variant}
      resourceType={type as SupportedResource}
      id={id}
      fetcher={fetcher}
    />
  );
};

interface ListProps {
  references?: fhir4.Reference[];
  variant?: TextVariant;
}

/**
 * Renders a list of `DisplayView` in a column
 */
export const DisplayList: React.FC<ListProps> = ({ references, variant }) => {
  if (!references) {
    return null;
  }

  return (
    <Col>
      {references.map((r, i) => (
        <DisplayView variant={variant} key={i} reference={r} />
      ))}
    </Col>
  );
};
