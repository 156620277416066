import { Person, Validations } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { personGeneralFields } from 'core/forms';
import { FormikErrors } from 'formik';
import React from 'react';
import { initialGeneral } from 'screens/person';
import { strings } from 'strings';

// TODO: where to organizes this files

type HandleChange = (
  data: Partial<Person.General>,
  errors: FormikErrors<Partial<Person.General>>
) => void;

interface GeneralFormProps {
  onChange: HandleChange;
  showErrors: boolean;
}

export const GeneralForm: React.FC<GeneralFormProps> = ({ onChange, showErrors }) => (
  <CrudForm
    title={strings.FormLabelPatientDetails}
    fields={personGeneralFields}
    showSubmitButton={false}
    initialValues={initialGeneral}
    includeIcons={true}
    validationSchema={Validations.personGeneralSchema}
    onChange={onChange}
    showErrors={showErrors}
    validateOnMount
    onChangeDebounceTime={0}
  />
);
