import { Admission, Validations } from '@quromedical/models';
import { Row } from 'components/base';
import { CrudForm } from 'components/form';
import { Field, SelectOption } from 'components/types';
import { Card, Text } from 'design-system';
import { getDefinedState, useObjectState } from 'hooks/useObjectState';
import React, { useMemo } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

import { createOxygenFields, getOxygenValue } from './internal';

export type AdmissionClinicalData = Pick<Admission.CreateRequest, 'icd10Codes' | 'oxygen'>;

type ICD10Fetcher = (query?: string) => Promise<SelectOption[]>;

interface AdmissionClinicalFormProps {
  showErrors?: boolean;
  /**
   * Returns undefined if the data is incomplete or invalid. This is to simplify error checking and
   * validation at a parent level - no data means no valid data
   */
  onValidChange: (data?: AdmissionClinicalData) => void;
  icd10CodeFetcher: ICD10Fetcher;
  icd10Codes?: string[];
}

const icd10Schema = yup
  .object<ObjectType<ICD10Fields>>({
    icd10Codes: Validations.admissionRequestSchemaFields.icd10Codes,
  })
  .required();

const oxygenSchema = yup
  .object<ObjectType<OxygenFields>>({
    oxygen: Validations.admissionRequestSchemaFields.oxygen,
  })
  .required();

const createFields = (icd10CodeFetcher: ICD10Fetcher): Field<ICD10Fields>[] => [
  {
    subfields: [
      {
        key: 'icd10Codes',
        type: 'combobox-multiple',
        label: strings.FormLabelICD10Codes,
        fetcher: icd10CodeFetcher,
        searchable: true,
      },
    ],
  },
];

interface ICD10Fields {
  icd10Codes: string[];
}

interface OxygenFields {
  oxygen?: number;
  hasOxygen: boolean;
}

interface ClinicalSection {
  icd10?: ICD10Fields;
  oxygen?: OxygenFields;
}

export const AdmissionClinicalForm: React.FC<AdmissionClinicalFormProps> = ({
  icd10Codes = [],
  showErrors = false,
  onValidChange,
  icd10CodeFetcher,
}) => {
  const state = useObjectState<ClinicalSection>(
    {
      oxygen: { hasOxygen: false },
    },
    (change) => {
      const defined = getDefinedState(change, ['icd10']);

      if (!defined) {
        onValidChange(undefined);
      } else {
        onValidChange({
          icd10Codes: defined.icd10.icd10Codes,
          oxygen: getOxygenValue(change.oxygen?.hasOxygen, change.oxygen?.oxygen),
        });
      }
    }
  );

  const icd10Fields = createFields(icd10CodeFetcher);
  const oxygenFields = useMemo(
    () => createOxygenFields(state.value.oxygen?.hasOxygen),
    [state.value]
  );

  return (
    <Card unsetZIndex>
      <Row>
        <Text variant="heading">{strings.AdmissionClinicalFormTitle}</Text>
      </Row>

      <CrudForm<ICD10Fields>
        cardProps={{ unsetZIndex: true }}
        validateOnMount
        validateOnChange
        initialValues={{ icd10Codes }}
        fields={icd10Fields}
        validationSchema={icd10Schema}
        showErrors={showErrors}
        onValidChange={state.set('icd10')}
        onChangeDebounceTime={0}
        showSubmitButton={false}
        hasCard={false}
      />

      <CrudForm<OxygenFields>
        cardProps={{ unsetZIndex: true }}
        validateOnMount
        validateOnChange
        initialValues={{ hasOxygen: false }}
        fields={oxygenFields}
        validationSchema={oxygenSchema}
        showErrors={showErrors}
        onChange={state.set('oxygen')}
        onChangeDebounceTime={0}
        showSubmitButton={false}
        hasCard={false}
      />
    </Card>
  );
};
