import { CreateReference, getCreateReferenceId, getReferenceId } from '@quromedical/fhir-common';
import { Admission, Websocket } from '@quromedical/models';
import { limit } from '@quromedical/utils';
import { useLinkTo } from '@react-navigation/native';
import { getLatestECGSinusAlert } from 'core/vitals';
import { secondsToMilliseconds } from 'date-fns';
import { ActionList, SkeletonProvider, Visible } from 'design-system';
import { dateTimeWithoutYearFormatter } from 'helpers';
import { useActiveAssignedAdmissions } from 'hooks/useActiveAssignedAdmissions';
import { useLiveData } from 'integration/live-vitals';
import { sortBy } from 'lodash';
import compact from 'lodash.compact';
import React, { useCallback } from 'react';
import { strings } from 'strings';

type AdmissionWithPatient = Admission.GetResponseWithVitals & {
  patient: CreateReference;
};

interface AdmissionAlert {
  admission: AdmissionWithPatient;
  alerts?: Websocket.Alert[];
}

const hasPatient = (admission: Admission.GetResponse): admission is AdmissionWithPatient =>
  !!admission.patient;

interface ListProps {
  isLoading: boolean;
  items: AdmissionAlert[];
  patientId?: string;
  onPatientPress: (alert: Websocket.Alert) => void;
}

interface Updated {
  updated: number;
}

const alertDisplays: Record<Websocket.Alert['type'], string> = {
  'ecg-sinus-rhythm': strings.AlertTitleECGNonSinusRhythm,
};

export const getAlertTitle = (alert: Websocket.Alert, patientName?: string): string => {
  const alertDisplay = alertDisplays[alert.type];
  if (!patientName) {
    return alertDisplay;
  }

  const title = `${patientName} - ${alertDisplay}`;

  return title;
};

const List: React.FC<ListProps> = ({ isLoading, items, onPatientPress, patientId }) => {
  const optionalAlerts = items.map(({ admission, alerts = [] }) => {
    const admissionAlerts = admission.alerts || [];

    const sortedAlerts = sortBy([...alerts, ...admissionAlerts], (alert) => -alert.ts);
    const alert = getLatestECGSinusAlert(sortedAlerts);

    if (!alert) {
      return undefined;
    }

    return {
      // if we're only viewing a single patient then show the 5 most recent, otherwise just one
      alert: patientId ? sortedAlerts.filter(limit(5)) : [alert],
      admission,
    };
  });
  const alerts = compact(optionalAlerts);
  const filteredAlerts = patientId
    ? alerts.filter((a) => getReferenceId(a.admission.patient) === patientId)
    : alerts;

  // flatten alerts and retain the relevant admission data
  const mergedAlerts = filteredAlerts.map((a) => a.alert.map((alert) => ({ ...a, alert }))).flat();

  return (
    <SkeletonProvider loading={isLoading}>
      <ActionList.List
        title={strings.TitleRealtimeAlert}
        icon="insights"
        blockColor="status-critical"
      >
        <Visible if={!patientId}>
          <ActionList.SubHeading
            subtitle={strings.AlertRowTitleTime}
            title={strings.TitlePatient}
          />
        </Visible>
        {mergedAlerts.map((item) => {
          const { admission, alert } = item;
          const id = getCreateReferenceId(admission.patient);

          // if we're filtering by a patient then don't show their name in the display
          const title = getAlertTitle(alert, patientId ? undefined : admission.patient.display);
          const time = dateTimeWithoutYearFormatter(alert.ts);

          return (
            <ActionList.Item
              id={id}
              subtitle={time}
              title={title}
              onPress={() => onPatientPress(alert)}
              statusIcon="error-outline"
              statusIconColor="status-critical"
            />
          );
        })}
      </ActionList.List>
    </SkeletonProvider>
  );
};

/**
 * Since there could be many patients' data being updated at the same time, we use a memo to reduce
 * frequent renders
 */
const ListMemo = React.memo<ListProps & Updated>(List, (prev) => {
  const age = Date.now() - prev.updated;
  // using a relatively short memo time since the component isn't very heavy to render
  const stale = age > secondsToMilliseconds(3);
  return !stale;
});

interface RealtimeAlertsListProps {
  patientId?: string;
  onPress?: (alert: Websocket.Alert) => void;
}

export const RealtimeAlertsList: React.FC<RealtimeAlertsListProps> = ({ onPress, patientId }) => {
  const { updated, alerts } = useLiveData();
  const { data: admissions = [], isLoading: loadingAdmissions } = useActiveAssignedAdmissions();

  const navigate = useLinkTo();

  const onPatientPress = useCallback(
    (alert: Websocket.Alert) =>
      navigate({
        screen: 'Patient',
        params: {
          screen: 'View',
          params: {
            screen: 'Vitals',
            id: alert.patientId,
          },
        },
      }),
    [navigate]
  );

  const items = admissions.filter(hasPatient).map<AdmissionAlert>((admission) => {
    const admissionPatientId = getCreateReferenceId(admission.patient);

    const result = {
      admission,
      alerts: alerts[admissionPatientId],
    };

    return result;
  });

  // we only want new loads and not existing data being revalidated
  const isLoading = loadingAdmissions && admissions.length === 0;

  return (
    <ListMemo
      patientId={patientId}
      isLoading={isLoading}
      updated={updated}
      items={items}
      onPatientPress={onPress || onPatientPress}
    />
  );
};
