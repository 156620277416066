import { VideoTile } from 'design-system';
import { useMeeting, usePeer } from 'providers/video';
import React from 'react';

import { Peer } from './Peer';

interface ControlledPeerProps {
  peerId?: string;
  hideName?: boolean;
  showMinimizedToggle?: boolean;
  isMinimized: boolean;
  showLeaveButton?: boolean;
}
export const ControlledPeer: React.FC<ControlledPeerProps> = ({
  peerId,
  hideName,
  isMinimized,
  showMinimizedToggle,
  showLeaveButton,
}) => {
  const {
    isMicEnabled,
    isVideoEnabled,
    minimize,
    expand,
    toggleMicEnabled,
    toggleVideoEnabled,
    leaveMeeting,
  } = useMeeting();

  const peer = usePeer(peerId);

  if (!peer) {
    return null;
  }
  const displayName = !hideName ? peer.name : undefined;
  const handleMinimizeToggle = isMinimized ? expand : minimize;
  const toggleMinimize = showMinimizedToggle ? handleMinimizeToggle : undefined;

  return (
    <VideoTile
      isMicEnabled={peer.isMicEnabled}
      isVideoEnabled={peer.isVideoEnabled}
      isMinimized={isMinimized}
      toggleMicEnabled={toggleMicEnabled}
      toggleVideoEnabled={toggleVideoEnabled}
      toggleMinimized={toggleMinimize}
      leaveMeeting={leaveMeeting}
      displayName={displayName}
      showLeaveButton={showLeaveButton}
      signalLevel={peer.signalLevel}
      volume={peer.volume}
      localAudioControl={isMicEnabled}
      localVideoControl={isVideoEnabled}
    >
      <Peer peer={peer} />
    </VideoTile>
  );
};
