import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Col, Row } from 'components/base';
import { Spacer, Visible, Text, Icon, IconName, Button } from 'design-system/base';
import { BORDER_RADIUS, MARGIN, PADDING } from 'design-system/theme';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { SCREEN_SIZE } from 'styles';

export interface AlertProps {
  backgroundColor?: ColorName;
  textColor?: ColorName;

  icon?: IconName;
  title?: string;
  body?: string;

  onAction?: () => void;
  actionIcon?: IconName;
  actionText?: string;
}

interface WrapperProps {
  backgroundColor: ColorName;
}

const Wrapper = styled(Row)<WrapperProps>(({ backgroundColor, theme }) => {
  const { width } = useWindowDimensions();

  const overallMaxWidth = SCREEN_SIZE.md;

  const baseWidth = width > overallMaxWidth ? overallMaxWidth : width;

  const maxWidth = 0.9 * baseWidth;

  return {
    maxWidth,
    paddingVertical: PADDING.xs,
    paddingHorizontal: PADDING.s,
    backgroundColor: theme.color[backgroundColor],
    borderRadius: BORDER_RADIUS[4],
  };
});

export const Alert: React.FC<AlertProps> = ({
  backgroundColor = 'base',
  textColor = 'text-default',

  icon,
  title = '',
  body = '',

  onAction,
  actionIcon,
  actionText = '',
}) => {
  const showTextSpacer = title && body;
  const buttonShape = actionText ? 'default' : 'round';

  return (
    <Wrapper backgroundColor={backgroundColor} alignItems="center">
      <Row isVisible={!!icon}>
        <Col>
          <Icon name={icon} color={textColor} />
        </Col>
        <Spacer width={MARGIN.xs} />
      </Row>
      <Col flex={1}>
        <Visible if={title}>
          <Text variant="body-strong" color={textColor}>
            {title}
          </Text>
        </Visible>
        <Visible if={showTextSpacer}>
          <Spacer height={MARGIN['2xs']} />
        </Visible>
        <Visible if={body}>
          <Text variant="body" color={textColor}>
            {body}
          </Text>
        </Visible>
      </Col>
      {onAction ? (
        <Row justifyContent="flex-end">
          <Spacer width={MARGIN.xs} />
          <Button
            variant="text"
            shape={buttonShape}
            textColor={textColor}
            onPress={onAction}
            text={actionText}
            icon={actionIcon}
            iconPosition="right"
          />
        </Row>
      ) : null}
    </Wrapper>
  );
};
