import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Col } from 'components/base';
import { Spinner, Button, Text, Dialog, Visible, MARGIN } from 'design-system';
import React, { useState, useCallback } from 'react';
import { strings } from 'strings';

interface ConfirmationButtonProps {
  title: string;
  message: string;
  buttonText: string;
  status: ColorName;
  errorMessage?: string;
  onSubmit: () => Promise<void>;
}

type Step = 'initial' | 'loading' | 'error';

const SpinnerWrapper = styled(Col)({
  marginTop: MARGIN.m,
});

export const ConfirmationButton: React.FC<ConfirmationButtonProps> = ({
  onSubmit,
  title,
  message,
  buttonText,
  status,
  errorMessage = 'An error has occurred, please try again',
}) => {
  const [isModalVisible, setVisible] = React.useState(false);
  const [formStep, setFormStep] = useState<Step>('initial');

  const reset = useCallback(() => {
    setVisible(false);
    setFormStep('initial');
  }, []);

  const handleSubmit = useCallback(async () => {
    setFormStep('loading');
    try {
      await onSubmit();
      reset();
    } catch (error) {
      setFormStep('error');
    }
  }, [onSubmit, reset]);

  const showModal = useCallback(() => setVisible(true), [setVisible]);

  const isLoading = formStep === 'loading';
  const isError = formStep === 'error';

  const notLoading = !isLoading;

  const primary = notLoading
    ? {
        onPress: handleSubmit,
        text: buttonText,
      }
    : undefined;

  const secondary = notLoading
    ? {
        onPress: reset,
        text: strings.ButtonTextCancel,
      }
    : undefined;

  return (
    <>
      <Button onPress={showModal} text={buttonText} />

      <Dialog
        isOpen={isModalVisible}
        onRequestClose={reset}
        title={title}
        primary={primary}
        secondary={secondary}
        borderColor={status}
      >
        <Text>{message}</Text>

        <Visible if={isLoading}>
          <SpinnerWrapper flex={1} alignItems="center" justifyContent="center">
            <Spinner />
          </SpinnerWrapper>
        </Visible>

        <Visible if={isError}>
          <Text color="status-critical">{errorMessage}</Text>
        </Visible>
      </Dialog>
    </>
  );
};
