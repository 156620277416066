import styled from '@emotion/native';
import { SPACING, Spacing } from 'design-system/theme';

interface SpacerProps {
  height?: number | Spacing;
  width?: number | Spacing;
}

const resolveSpacing = (spacing?: number | Spacing): number | undefined => {
  if (!spacing) {
    return undefined;
  }

  if (typeof spacing === 'number') {
    return spacing;
  }

  return SPACING[spacing];
};

export const Spacer = styled.View<SpacerProps>(({ height, width }) => ({
  height: resolveSpacing(height),
  width: resolveSpacing(width),
}));
