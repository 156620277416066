import { A } from '@expo/html-elements';
import React from 'react';

import { GooglePlayStoreBadge } from './GooglePlayStoreBadge';

const googlePlayStoreUrl =
  'https://play.google.com/store/apps/details?id=co.za.quromedical.insight.prod';

export const GooglePlayStoreLink: React.FC = () => (
  <A href={googlePlayStoreUrl}>
    <GooglePlayStoreBadge />
  </A>
);
