import { ColorName } from '@emotion/react';
import { MaterialIcons } from '@expo/vector-icons';
import { useTheme } from 'design-system/theme';
import React from 'react';

import {
  IconName,
  getCustomIcon,
  MaterialIconNames,
  getTextIconName,
  TextIcon,
} from './custom-icons';

interface IconProps {
  /**
   * If the name is not provided the component will render nothing
   */
  name?: IconName;
  color?: ColorName;
  size?: number;
}

export const Icon: React.FC<IconProps> = ({ color = 'text-default', name, size = 24 }) => {
  const theme = useTheme();
  const resolvedColor = theme.color[color];

  if (!name) {
    return null;
  }

  const CustomIcon = getCustomIcon(name);

  if (CustomIcon) {
    return <CustomIcon color={resolvedColor} size={size} />;
  }

  const textIcon = getTextIconName(name);

  if (textIcon) {
    return <TextIcon color={resolvedColor} size={size} text={textIcon} />;
  }

  return (
    <MaterialIcons
      selectable={false}
      name={name as MaterialIconNames}
      color={resolvedColor}
      size={size}
    />
  );
};
