import styled from '@emotion/native';
import {
  createNavigatorFactory,
  DefaultNavigatorOptions,
  ParamListBase,
  CommonActions,
  TabActionHelpers,
  TabNavigationState,
  TabRouter,
  TabRouterOptions,
  useNavigationBuilder,
} from '@react-navigation/native';
import { Row } from 'components/base';
import { IconName, Visible } from 'design-system/base';
import { BASE_SIZE, PADDING } from 'design-system/theme';
import { LinkTo } from 'navigation';
import * as React from 'react';
import { useWindowDimensions } from 'react-native';

import { Profile, Logo } from '../shared';
import { SearchButton } from './SearchButton';
import { Tab } from './Tab';

interface TabAction {
  icon: IconName;
  title: string;
  isHidden?: boolean;
  onPress: () => void;
}

// Props accepted by the view
type TabNavigationConfig = {
  name?: string;
  initials?: string;
  actions?: TabAction[];
};

// Supported screen options
type TabNavigationOptions = {
  title?: string;
  icon?: IconName;
  link?: LinkTo;
  isHidden?: boolean;
};

// Map of event name and the type of data (in event.data)
//
// canPreventDefault: true adds the defaultPrevented property to the
// emitted events.
type TabNavigationEventMap = {
  tabPress: {
    data: { isAlreadyFocused: boolean };
    canPreventDefault: true;
  };
};

// The props accepted by the component is a combination of 3 things
type Props = DefaultNavigatorOptions<
  ParamListBase,
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap
> &
  TabRouterOptions &
  TabNavigationConfig;

const Screen = styled.View(({ theme }) => {
  const { height } = useWindowDimensions();

  return {
    height,
    backgroundColor: theme.color.base,
  };
});

const TopBar = styled(Row)(({ theme }) => ({
  backgroundColor: theme.color['base-grey'],
  justifyContent: 'space-between',
  paddingHorizontal: PADDING.m,
  height: BASE_SIZE[48],
  maxWidth: '100%',
}));

const Page = styled.View({
  flex: 1,
});

/**
 * Web-style Top Navigator implemented as per the
 * [React-Navigation Documentation Sample](https://reactnavigation.org/docs/custom-navigators/)
 */
export const TopNavigator = ({
  initialRouteName,
  children,
  screenOptions,
  name,
  initials,
  actions = [],
}: Props): JSX.Element => {
  const { state, navigation, descriptors, NavigationContent } = useNavigationBuilder<
    TabNavigationState<ParamListBase>,
    TabRouterOptions,
    TabActionHelpers<ParamListBase>,
    TabNavigationOptions,
    TabNavigationEventMap
  >(TabRouter, {
    children,
    screenOptions,
    initialRouteName,
  });

  const navigateToProfile = React.useCallback(() => {
    navigation.navigate('Profile');
  }, [navigation]);

  // note that in the case of small devices the header will overflow and not scroll. this is
  // necessary to ensure the Profile dropdown works correctly. in such cases be sure to show the
  // a different mobile navigation or reduce the number of options available here
  return (
    <NavigationContent>
      <Screen>
        <TopBar unsetZIndex>
          <Row unsetZIndex>
            <Logo />
            {state.routes.map((route, index) => {
              const { title, icon, link, isHidden } = descriptors[route.key].options;

              if (isHidden) {
                return null;
              }

              const isActive = state.index === index;
              const text = title || route.name;

              const isAlreadyFocused = route.key === state.routes[state.index].key;

              const onPress = () => {
                if (link && typeof link !== 'string') {
                  navigation.navigate(link.screen, link.params);
                  return;
                }

                const event = navigation.emit({
                  type: 'tabPress',
                  target: route.key,
                  canPreventDefault: true,
                  data: {
                    isAlreadyFocused,
                  },
                });

                if (!event.defaultPrevented) {
                  navigation.dispatch({
                    ...CommonActions.navigate({ name: route.name, merge: true }),
                    target: state.key,
                  });
                }
              };

              return (
                <Tab
                  key={route.key}
                  onPress={onPress}
                  text={text}
                  icon={icon}
                  isActive={!!isActive}
                />
              );
            })}

            {actions.map((action) => (
              <Visible key={action.title} if={!action.isHidden}>
                <Tab
                  onPress={action.onPress}
                  icon={action.icon}
                  text={action.title}
                  isActive={false}
                />
              </Visible>
            ))}

            <SearchButton />
          </Row>
          <Row unsetZIndex>
            <Profile name={name} initials={initials} onProfilePress={navigateToProfile} />
          </Row>
        </TopBar>

        <Page>
          {state.routes.map((route, index) => {
            const isVisible = index === state.index;

            if (!isVisible) {
              return null;
            }

            return descriptors[route.key].render();
          })}
        </Page>
      </Screen>
    </NavigationContent>
  );
};

export const createTopNavigator = createNavigatorFactory<
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap,
  typeof TopNavigator
>(TopNavigator);
