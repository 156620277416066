import { GroupSecurity } from '@quromedical/fhir-common';
import { NavigationContainer, Theme } from '@react-navigation/native';
import {
  createBottomNavigator,
  createTopNavigator,
  BottomNavigatorProvider,
} from 'design-system/navigation';
import { logger } from 'helpers';
import { useIsSmallerThan } from 'hooks/useResponsive';
import { useUserSession } from 'hooks/useUserSession';
import { KBar } from 'providers';
import React from 'react';
import { Linking, Platform } from 'react-native';
import { HomeScreen } from 'screens/home';
import { UserProfileScreen } from 'screens/profile';
import { strings } from 'strings';

import { AdminDrawerNavigator, adminVisiblePermissions } from './AdminDrawerNavigator';
import { DashboardStackNavigator } from './DashboardStackNavigator';
import { createLinkingConfiguration } from './LinkingConfiguration';
import { PatientStackNavigator } from './PatientStackNavigator';
import { AppRootParamList } from './types';

const TopNav = createTopNavigator<AppRootParamList>();
const BottomNav = createBottomNavigator<AppRootParamList>();

interface ResponsiveNavigatorProps {
  theme: Theme;
}

const TopNavigator: React.FC = () => {
  const session = useUserSession();

  // we might want to add the FHIR user here to show their name instead of email
  const user = session.getUser();
  const isAdmin = session.hasAny(adminVisiblePermissions);
  const hasHomePreview = session.hasAny([GroupSecurity.FeaturePreviewNavigator]);
  const initialRouteName = hasHomePreview ? 'Home' : 'Dashboard';

  const patientReferralUrl = 'https://quromedical.co.za/patient-form/';

  const openURL = async (url: string) => {
    if (Platform.OS === 'web') {
      window.open(url);
    } else {
      try {
        await Linking.openURL(url);
      } catch (e) {
        logger.error(e);
      }
    }
  };

  return (
    <KBar>
      <TopNav.Navigator
        name={user?.email}
        initialRouteName={initialRouteName}
        actions={[
          {
            icon: 'person-add',
            title: strings.ReferPatient,
            isHidden: isAdmin,
            onPress() {
              openURL(patientReferralUrl).catch(logger.error);
            },
          },
        ]}
      >
        <TopNav.Screen
          name="Home"
          component={HomeScreen}
          options={{
            title: 'Home',
            icon: 'home',
            isHidden: !hasHomePreview,
          }}
        />
        <TopNav.Screen
          name="Dashboard"
          component={DashboardStackNavigator}
          options={{
            title: 'Dashboard',
            icon: 'dashboard',
          }}
        />
        <TopNav.Screen
          name="Patient"
          component={PatientStackNavigator}
          options={{
            title: 'Patients',
            icon: 'people-outline',
            link: {
              screen: 'Patient',
              params: {
                screen: 'AdmissionList',
              },
            },
          }}
        />

        <TopNav.Screen
          name="Admin"
          component={AdminDrawerNavigator}
          options={{
            title: 'Admin',
            icon: 'admin-panel-settings',
            isHidden: !isAdmin,
          }}
        />

        <TopNav.Screen
          name="Profile"
          component={UserProfileScreen}
          options={{
            icon: 'person-outline',
            isHidden: true,
          }}
        />
      </TopNav.Navigator>
    </KBar>
  );
};

const BottomNavigator: React.FC = () => {
  const session = useUserSession();

  // we might want to add the FHIR user here to show their name instead of email
  const user = session.getUser();
  const isAdmin = session.hasAny(adminVisiblePermissions);
  const hasHomePreview = session.hasAny([GroupSecurity.FeaturePreviewNavigator]);

  const initialRouteName = hasHomePreview ? 'Home' : 'Dashboard';

  return (
    <BottomNav.Navigator name={user?.email} initialRouteName={initialRouteName}>
      <BottomNav.Screen
        name="Home"
        component={HomeScreen}
        options={{
          icon: 'home',
          isHidden: !hasHomePreview,
        }}
      />
      <BottomNav.Screen
        name="Dashboard"
        component={DashboardStackNavigator}
        options={{
          icon: 'dashboard',
        }}
      />
      <BottomNav.Screen
        name="Patient"
        component={PatientStackNavigator}
        options={{
          icon: 'people-outline',
          link: {
            screen: 'Patient',
            params: {
              screen: 'AdmissionList',
            },
          },
        }}
      />

      <BottomNav.Screen
        name="Admin"
        component={AdminDrawerNavigator}
        options={{
          icon: 'menu',
          isHidden: !isAdmin,
        }}
      />

      <BottomNav.Screen
        name="Profile"
        component={UserProfileScreen}
        options={{
          icon: 'person-outline',
          isHidden: true,
        }}
      />
    </BottomNav.Navigator>
  );
};

export const ResponsiveNavigator: React.FC<ResponsiveNavigatorProps> = ({ theme }) => {
  const session = useUserSession();
  const groups = session.getPermissions();

  const linking = createLinkingConfiguration(groups);

  const isSmall = useIsSmallerThan('md');
  const isNative = Platform.OS !== 'web';

  const Navigator = isSmall || isNative ? <BottomNavigator /> : <TopNavigator />;

  return (
    <BottomNavigatorProvider>
      <NavigationContainer theme={theme} linking={linking}>
        {Navigator}
      </NavigationContainer>
    </BottomNavigatorProvider>
  );
};
