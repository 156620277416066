import { getReferenceId } from '@quromedical/fhir-common';
import { Patient } from '@quromedical/models';
import { DisplayList } from 'components/fhir';
import { SelectOption } from 'components/types';
import { CareTeamForm } from 'core/patient';
import { Alert, FormCard, Skeleton, SkeletonProvider, Snackbar, Visible } from 'design-system';
import { useFiniteState } from 'hooks/useFiniteState';
import { practitionerFetcher, teamOrDepartmentOrganizationFetcher } from 'integration';
import { PatientApi } from 'integration/aggregate';
import compact from 'lodash.compact';
import React, { useCallback } from 'react';
import { strings } from 'strings';

interface CareTeamCardProps {
  patientId: string;
  generalPractitioner?: Patient.GeneralPractitioners;
  canEdit: boolean;
  revalidate: () => void;
}

const api = new PatientApi();

/**
 * get the initial form values for editing the practitioners assigned to a patient
 *
 * note that the initial selections ensure that we can view values that may not yet be in the option
 * list since the options need to be fetched from the backend
 */
const getInitialFormValues = (
  generalPractitioner: Patient.GeneralPractitioners | undefined
): InitialFormValues => {
  const initialOrganizations: SelectOption[] | undefined = generalPractitioner?.organizations.map(
    (ref) => ({
      value: getReferenceId(ref),
      display: ref.display || strings.DisplayError,
    })
  );

  const initialPractitioners: SelectOption[] | undefined = generalPractitioner?.practitioners.map(
    (ref) => ({
      value: getReferenceId(ref),
      display: ref.display || strings.DisplayError,
    })
  );

  const initialValues: Patient.GeneralPractitionerUpdateRequest = {
    organizations: compact(generalPractitioner?.organizations.map(getReferenceId)),
    practitioners: compact(generalPractitioner?.practitioners.map(getReferenceId)),
  };

  return { initialPractitioners, initialOrganizations, initialValues };
};

type FormState = 'initial' | 'editing' | 'submitting' | 'error';

export const CareTeamCard: React.FC<CareTeamCardProps> = ({
  patientId,
  generalPractitioner,
  revalidate,
  canEdit,
}) => {
  const state = useFiniteState<FormState>('initial');

  const handleSubmit = useCallback(
    async (data: Patient.GeneralPractitionerUpdateRequest) => {
      state.set('submitting');
      try {
        await api.updateGeneralPractitioners(patientId, data);
        state.set('initial');
        revalidate();
        return {};
      } catch (error) {
        state.set('error');
        return { error };
      }
    },
    [patientId, revalidate, state]
  );

  const { initialPractitioners, initialOrganizations, initialValues } =
    getInitialFormValues(generalPractitioner);

  return (
    <>
      <SkeletonProvider loading={state.is('submitting')}>
        <Skeleton>
          <Visible if={state.isNot('editing')}>
            <FormCard
              isButtonVisible={canEdit}
              onButtonPress={state.next('editing')}
              buttonText={strings.ButtonTextEdit}
              buttonIcon="edit"
              showIcons
              title={strings.CardTitleCareTeam}
              rows={[
                {
                  icon: 'people-outline',
                  fields: [
                    {
                      type: 'custom',
                      label: strings.GeneralPractitioners,
                      Display: () => (
                        <DisplayList
                          variant="body-strong"
                          references={generalPractitioner?.practitioners}
                        />
                      ),
                    },
                    {
                      type: 'custom',
                      label: strings.FormLabelAssignedOrgOrTeams,
                      Display: () => (
                        <DisplayList
                          variant="body-strong"
                          references={generalPractitioner?.organizations}
                        />
                      ),
                    },
                  ],
                },
              ]}
            />
          </Visible>

          <Visible if={state.is('editing')}>
            <CareTeamForm
              initialOrganizations={initialOrganizations}
              initialPractitioners={initialPractitioners}
              onSubmit={handleSubmit}
              initialValues={initialValues}
              teamOrDepartmentOrganizationFetcher={teamOrDepartmentOrganizationFetcher}
              practitionerFetcher={practitionerFetcher}
              onCancel={state.next('initial')}
            />
          </Visible>
        </Skeleton>
      </SkeletonProvider>

      <Snackbar onClose={state.next('editing')} isOpen={state.is('error')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={state.next('editing')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
        />
      </Snackbar>
    </>
  );
};

interface InitialFormValues {
  initialValues: Patient.GeneralPractitionerUpdateRequest;
  initialPractitioners?: SelectOption[];
  initialOrganizations?: SelectOption[];
}
