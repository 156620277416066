import { MedicalAid } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import compact from 'lodash.compact';

enum Endpoint {
  administrator = 'administrator',
  scheme = 'scheme',
}

export class MedicalAidApi {
  private readonly client: BaseApi;
  private readonly root = 'api/aggregate/medical-aid';

  constructor() {
    this.client = new BaseApi();
  }

  private createPath(endpoint?: Endpoint) {
    return compact([this.root, endpoint]).join('/');
  }

  public async getAdministrators(): Promise<MedicalAid.GetAdministratorsResponse> {
    const path = this.createPath(Endpoint.administrator);
    const result = await this.client.get<MedicalAid.GetAdministratorsResponse>(path);

    return result.data;
  }

  public async getSchemes(): Promise<MedicalAid.GetSchemesResponse> {
    const path = this.createPath(Endpoint.scheme);
    const result = await this.client.get<MedicalAid.GetSchemesResponse>(path);

    return result.data;
  }
}
