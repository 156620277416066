import styled from '@emotion/native';
import { Row } from 'components/base';
import { Text, Card as BaseCard } from 'design-system/base';
import { MARGIN } from 'design-system/theme';
import React from 'react';

interface CardProps {
  title: string;
}

const ContentWrapper = styled(Row)({
  marginTop: MARGIN.s,
});

export const Card: React.FC<CardProps> = ({ title, children }) => (
  <BaseCard border>
    <Row>
      <Text variant="heading">{title}</Text>
    </Row>
    <ContentWrapper>{children}</ContentWrapper>
  </BaseCard>
);
