import {
  ClinicalImpressionCode,
  ClinicalImpressionLocationCode,
  ClinicalImpressionAssessmentValueCode,
  ClinicalImpressionPlanValueCode,
} from '@quromedical/fhir-common';
import { LocaleStringKey } from 'strings';

export const codeDisplayMap: Record<ClinicalImpressionCode, LocaleStringKey> = {
  [ClinicalImpressionCode.ClinicalUpdate]: 'SOAPNoteCodeClinicalUpdate',
  [ClinicalImpressionCode.Routine]: 'SOAPNoteCodeRoutine',
  [ClinicalImpressionCode.Onboarding]: 'SOAPNoteCodeOnboarding',
  [ClinicalImpressionCode.ClinicalCallout]: 'SOAPNoteCodeClinicalCallout',
  [ClinicalImpressionCode.Discharge]: 'SOAPNoteCodeDischarge',
  [ClinicalImpressionCode.BloodResults]: 'SOAPNoteCodeBloodResults',
  [ClinicalImpressionCode.Physiotherapy]: 'SOAPNoteCodePhysiotherapy',
};

export const locationDisplayMap: Record<ClinicalImpressionLocationCode, LocaleStringKey> = {
  [ClinicalImpressionLocationCode.Virtual]: 'SOAPNoteLocationCodeVirtual',
  [ClinicalImpressionLocationCode.InPerson]: 'SOAPNoteLocationCodeInPerson',
};

export const assessmentDisplayMap: Record<ClinicalImpressionAssessmentValueCode, LocaleStringKey> =
  {
    [ClinicalImpressionAssessmentValueCode.Stable]: 'SOAPNoteAssessmentStable',
    [ClinicalImpressionAssessmentValueCode.Improving]: 'SOAPNoteAssessmentImproving',
    [ClinicalImpressionAssessmentValueCode.Deteriorating]: 'SOAPNoteAssessmentDeteriorating',
  };

export const planDisplayMap: Record<ClinicalImpressionPlanValueCode, LocaleStringKey> = {
  [ClinicalImpressionPlanValueCode.Continue]: 'SOAPNotePlanContinue',
  [ClinicalImpressionPlanValueCode.Amend]: 'SOAPNotePlanAmend',
  [ClinicalImpressionPlanValueCode.Escalate]: 'SOAPNotePlanEscalate',
  [ClinicalImpressionPlanValueCode.Discharge]: 'SOAPNotePlanDischarge',
};
