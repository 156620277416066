import styled from '@emotion/native';
import { Col } from 'components/base';
import { Spacer, Visible } from 'design-system/base';
import { MARGIN, PADDING } from 'design-system/theme';
import React from 'react';

import { FilterSection, FilterSectionProps } from './FilterSection';
import { LegendSection, LegendSectionProps } from './LegendSection';

interface Visibility {
  visible?: boolean;
}

interface FooterProps<TLegendIdentifier extends string> {
  legend: LegendSectionProps<TLegendIdentifier> & Visibility;
  filter: FilterSectionProps<string> & Visibility;
}

const FooterWrapper = styled(Col)({
  marginTop: MARGIN.m,
  paddingHorizontal: PADDING.m,
  paddingBottom: PADDING.s,
});

export const Footer = <TLegendIdentifier extends string>({
  filter,
  legend,
}: FooterProps<TLegendIdentifier>) => (
  <FooterWrapper>
    <Visible if={legend.visible}>
      <LegendSection {...legend} />
    </Visible>
    <Visible if={filter.visible && legend.visible}>
      <Spacer height="3xs" />
    </Visible>
    <Visible if={filter.visible}>
      <FilterSection {...filter} />
    </Visible>
  </FooterWrapper>
);
