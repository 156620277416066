import { Patient, Validations } from '@quromedical/models';
import { CrudForm, SubmissionResult } from 'components/form';
import { Field, SelectOption } from 'components/types';
import { FormikErrors } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { strings } from 'strings';

interface CareTeamFormProps {
  initialPractitioners?: SelectOption[];
  initialOrganizations?: SelectOption[];
  initialValues?: Patient.GeneralPractitionerUpdateRequest;
  showErrors?: boolean;
  onSubmit?: (data: Patient.GeneralPractitionerUpdateRequest) => Promise<SubmissionResult<unknown>>;
  onValidChange?: (data?: Patient.GeneralPractitionerUpdateRequest) => void;
  onChange?: (
    data: Partial<Patient.GeneralPractitionerUpdateRequest>,
    errors: FormikErrors<Patient.GeneralPractitionerUpdateRequest>,
    hasErrors: boolean
  ) => void;
  onCancel?: () => void;
  practitionerFetcher: (query?: string) => Promise<SelectOption[]>;
  teamOrDepartmentOrganizationFetcher: (query?: string) => Promise<SelectOption[]>;
}

const createFields = (
  practitionerFetcher: (query?: string) => Promise<SelectOption[]>,
  teamOrDepartmentOrganizationFetcher: (query?: string) => Promise<SelectOption[]>,
  initialPractitioners?: SelectOption[],
  initialOrganizations?: SelectOption[]
): Field<Patient.GeneralPractitionerUpdateRequest>[] => [
  {
    subfields: [
      {
        key: 'practitioners',
        label: strings.GeneralPractitioners,
        type: 'combobox-multiple',
        searchable: true,
        fetcher: practitionerFetcher,
        initialSelection: initialPractitioners,
      },
      {
        key: 'organizations',
        label: strings.FormLabelAssignedOrgOrTeams,
        type: 'combobox-multiple',
        searchable: true,
        fetcher: teamOrDepartmentOrganizationFetcher,
        initialSelection: initialOrganizations,
      },
    ],
  },
];

export const CareTeamForm: React.FC<CareTeamFormProps> = ({
  initialOrganizations,
  initialPractitioners,
  initialValues = {},
  showErrors,
  onValidChange,
  onSubmit,
  onChange,
  onCancel,
  practitionerFetcher,
  teamOrDepartmentOrganizationFetcher,
}) => {
  const fields = useMemo(
    () =>
      createFields(
        practitionerFetcher,
        teamOrDepartmentOrganizationFetcher,
        initialPractitioners,
        initialOrganizations
      ),
    [
      practitionerFetcher,
      teamOrDepartmentOrganizationFetcher,
      initialPractitioners,
      initialOrganizations,
    ]
  );

  const handleChange = useCallback(
    (
      data: Partial<Patient.GeneralPractitionerUpdateRequest>,
      errors: FormikErrors<Patient.GeneralPractitionerUpdateRequest>,
      hasErrors: boolean
    ) => {
      if (onChange) {
        onChange(data, errors, hasErrors);
      }

      if (onValidChange) {
        if (hasErrors) {
          onValidChange(undefined);
        } else {
          onValidChange(data as Patient.GeneralPractitionerUpdateRequest);
        }
      }
    },
    [onChange, onValidChange]
  );
  return (
    <CrudForm<Patient.GeneralPractitionerUpdateRequest>
      title={strings.CardTitleCareTeam}
      validationSchema={Validations.generalPractitionerUpdateRequestSchema}
      fields={fields}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onChange={handleChange}
      onChangeDebounceTime={onChange ? 0 : undefined}
      onSecondarySubmit={onCancel}
      showSecondarySubmitButton={!!onCancel}
      showSubmitButton={!!onSubmit}
      showErrors={showErrors}
    />
  );
};
