import { WithId } from '@quromedical/fhir-common';

import { BaseApi } from './BaseApi';
import { Bundle } from './Bundle';

export class FhirBaseApi<TResource extends fhir4.FhirResource, TGet = {}> {
  protected readonly basePath: string;

  protected client: BaseApi;

  constructor(basePath = 'api/fhir') {
    this.client = new BaseApi();
    this.basePath = basePath;
  }

  protected getPath(path: string, after?: string): string {
    if (path && after) {
      return [this.basePath, path, after].join('/');
    }

    if (path) {
      return [this.basePath, path].join('/');
    }

    return this.basePath;
  }

  protected get = async (path: string, params?: TGet): Promise<WithId<TResource>> => {
    const result = await this.client.get<WithId<TResource>>(this.getPath(path), {
      params,
    });
    return result.data;
  };

  protected getAll = async (params?: TGet): Promise<Bundle<WithId<TResource>>> => {
    const result = await this.client.get<Bundle<WithId<TResource>>>(this.basePath, {
      params,
    });
    return result.data;
  };

  protected post = async (params: TResource): Promise<TResource> => {
    const result = await this.client.post<TResource>(this.basePath, params);
    return result.data;
  };

  protected put = async (params: TResource): Promise<TResource> => {
    const result = await this.client.put<TResource>(this.basePath, params);
    return result.data;
  };
}
