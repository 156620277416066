import { Medication } from '@quromedical/models';
import { SelectOption } from 'components/types';
import { MedicationUsageFormData, MedicationListForm } from 'core/medication';
import { Alert, Skeleton, SkeletonProvider, Snackbar } from 'design-system';
import { useFiniteState } from 'hooks/useFiniteState';
import { useRevalidation } from 'hooks/useRevalidation';
import React, { useCallback } from 'react';
import { strings } from 'strings';

type FormState = 'editing' | 'submitting' | 'error';

export interface MedicationCreateProps {
  onSubmit: (medicationUsage: Medication.CreateMedicationUsage[]) => Promise<void>;
  medicationFetcher: (query?: string) => Promise<SelectOption[]>;
  title: string;
}

export const CreateMedicationCard: React.FC<MedicationCreateProps> = ({
  onSubmit,
  medicationFetcher,
  title,
}) => {
  const state = useFiniteState<FormState>('editing');
  const revalidate = useRevalidation('Medication');

  const handleSubmit = useCallback(
    async (data: MedicationUsageFormData[]) => {
      state.set('submitting');

      try {
        await onSubmit(data);
        state.set('editing');
        revalidate();
      } catch (err) {
        state.set('error');
      }
    },
    [revalidate, onSubmit, state]
  );

  return (
    <>
      <SkeletonProvider loading={state.is('submitting')}>
        <Skeleton>
          <MedicationListForm
            handleSubmit={handleSubmit}
            medicationFetcher={medicationFetcher}
            title={title}
          />
        </Skeleton>
      </SkeletonProvider>

      <Snackbar onClose={state.next('editing')} isOpen={state.is('error')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={state.next('editing')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
        />
      </Snackbar>
    </>
  );
};
