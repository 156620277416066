import { GroupSecurity } from '@quromedical/fhir-common';
import { Practitioner } from '@quromedical/models';
import { useLinkTo } from '@react-navigation/native';
import { CrudForm } from 'components/form';
import { WithGroup } from 'core/auth';
import { columns, initialSearch, PractitionerTable, searchFields } from 'core/practitioner';
import { PaginatedTable } from 'core/table';
import { Section, ToggleCard } from 'design-system';
import { useResponsive } from 'hooks/useResponsive';
import { PractitionerApi } from 'integration/aggregate';
import { PractitionerStackScreenProps } from 'navigation';
import React, { useCallback, useState } from 'react';
import { ScrollView } from 'react-native';
import { strings } from 'strings';

import { PractitionerCreateForm } from './card';

const api = new PractitionerApi();

const getDataFromResponse = (res: Practitioner.GetAllResponse): PractitionerTable[] =>
  res.practitioners.map((p) => ({
    id: p.id,
    givenName: p.general.givenName,
    familyName: p.general.familyName,
    email: p.contact.telecomEmail,
    phone: p.contact.telecomPhoneNumbers?.[0],
    secondaryPhone: p.contact.telecomPhoneNumbers?.[1],
    registrationNumber: p.qualification.registrationNumber,
  }));

export const PractitionerListScreen: React.FC<PractitionerStackScreenProps<'List'>> = () => {
  const isStriped = useResponsive({ md: true }, false);
  const navigate = useLinkTo();

  const [search, setSearch] = useState<Practitioner.GetAllParams>(initialSearch);

  const fetcher = useCallback(
    (pageToken?: string) =>
      api.getPractitioners({
        name: search.name || undefined,
        email: search.email || undefined,
        sort: search.sort,
        pageToken,
      }),
    [search]
  );

  const onRowPress = useCallback(
    (row: PractitionerTable) =>
      navigate({
        screen: 'Admin',
        params: {
          screen: 'Practitioner',
          params: { screen: 'View', params: { id: row.id } },
        },
      }),
    [navigate]
  );

  return (
    <ScrollView>
      <WithGroup groups={[GroupSecurity.PractitionerCreate]}>
        <Section unsetZIndex>
          <ToggleCard
            icon="person-outline"
            title={strings.PractitionerToggleCardTitle}
            textExpand={strings.PractitionerToggleCardExpandButtonText}
            textCollapse={strings.PractitionerToggleCardCollapseButtonText}
          >
            <PractitionerCreateForm />
          </ToggleCard>
        </Section>
      </WithGroup>
      <Section>
        <CrudForm
          cardProps={{ unsetZIndex: true }}
          fields={searchFields}
          initialValues={initialSearch}
          showSubmitButton={false}
          onChange={setSearch}
        />
      </Section>
      <Section>
        <PaginatedTable
          cacheKey={`Practitioner${JSON.stringify(search)}`}
          fetcher={fetcher}
          columns={columns}
          showHeader={true}
          isStriped={isStriped}
          getDataFromResponse={getDataFromResponse}
          onRowPress={onRowPress}
        />
      </Section>
    </ScrollView>
  );
};
