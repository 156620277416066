import { extent } from 'd3-array';

import { Datum, Domain, PartialDomain } from './types';

export const getFullDomain = (partial?: PartialDomain): Domain | undefined => {
  const start = partial?.[0];
  const end = partial?.[1];

  if (start !== undefined && end !== undefined) {
    return [start, end];
  }

  return undefined;
};

interface DomainResult {
  x?: Domain;
  y?: Domain;
}

export const getDomain = (data: Datum[]): DomainResult => {
  const xExtent = extent(data, (d) => d?.x) as PartialDomain;
  const yExtent = extent(data, (d) => d?.y) as PartialDomain;

  const x = getFullDomain(xExtent);
  const y = getFullDomain(yExtent);

  return { x, y };
};
