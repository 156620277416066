import { Organization } from '@quromedical/models';
import { Alert, Fetcher, Skeleton, SkeletonProvider, Snackbar, Visible } from 'design-system';
import { logger } from 'helpers';
import { useFiniteState } from 'hooks/useFiniteState';
import React, { useCallback } from 'react';
import { strings } from 'strings';

import { GeneralCard } from './card';
import { GeneralForm } from './form';

interface OrganizationGeneralFormProps {
  canEdit: boolean;
  data?: Partial<Organization.General>;
  revalidate?: () => void;
  onSubmit?: (Person: Organization.General) => Promise<void>;
  schemeFetcher: Fetcher<string, unknown>;
}

type FormState = 'initial' | 'editing' | 'submitting' | 'error';

export const OrganizationGeneralForm: React.FC<OrganizationGeneralFormProps> = ({
  canEdit,
  data,
  revalidate,
  onSubmit,
  schemeFetcher,
}) => {
  const state = useFiniteState<FormState>('initial');

  const handleSubmit = useCallback(
    async (result: Organization.General) => {
      state.set('submitting');
      try {
        await onSubmit?.(result);
        state.set('initial');
        revalidate?.();
      } catch (error) {
        state.set('error');
        logger.error(error);
      }
    },
    [revalidate, onSubmit, state]
  );

  return (
    <>
      <SkeletonProvider loading={state.is('submitting')}>
        <Skeleton>
          <Visible if={state.isNot('editing')}>
            <GeneralCard data={data} canEdit={canEdit} onEditPress={state.next('editing')} />
          </Visible>

          <Visible if={state.is('editing')}>
            <GeneralForm
              data={data}
              handleCancel={state.next('initial')}
              handleSubmit={handleSubmit}
              schemeFetcher={schemeFetcher}
            />
          </Visible>
        </Skeleton>
      </SkeletonProvider>

      <Snackbar onClose={state.next('editing')} isOpen={state.is('error')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={state.next('editing')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
        />
      </Snackbar>
    </>
  );
};
