import styled from '@emotion/native';
import { Medication } from '@quromedical/models';
import { Row } from 'components/base';
import { SelectOption } from 'components/types';
import { Card, MARGIN } from 'design-system';
import { useList } from 'hooks/useList';
import React, { useCallback, useState } from 'react';

import { MedicationRequestForm } from './MedicationRequestForm';
import { RecipientPicker } from './RecipientPicker';
import { MedicationRequestFormData } from './types';

interface FormProps {
  handleSubmit: (data: Medication.CreateMedicationRequest) => Promise<void>;
  medicationFetcher: (query?: string) => Promise<SelectOption[]>;
  organizationFetcher: (query?: string) => Promise<SelectOption[]>;
  title?: string;
}

type MedicationFormState = {
  data: Partial<MedicationRequestFormData>;
  hasErrors: boolean;
  hasSubmitted: boolean;
};

const ButtonWrapper = styled(Row)({
  marginTop: MARGIN['2xs'],
  width: '100%',
  justifyContent: 'flex-end',
});

export const MedicationRequestListForm = ({
  handleSubmit,
  medicationFetcher,
  organizationFetcher,
  title,
}: FormProps) => {
  const list = useList<number, MedicationFormState>([
    {
      key: Math.random(),
      value: {
        data: {},
        hasErrors: true,
        hasSubmitted: false,
      },
    },
  ]);

  const submit = useCallback(
    async (recipient: Medication.Recipient) => {
      setHasSubmitted(true);
      const allValid = list.items.every((item) => !item.value.hasErrors);
      if (!allValid) {
        return;
      }

      // casting as the data is known to be valid
      const result = list.items.map((p) => p.value.data as MedicationRequestFormData);
      await handleSubmit({ medicationRequest: result, recipient });
    },
    [handleSubmit, list.items]
  );

  const [hasSubmitted, setHasSubmitted] = useState(false);

  return (
    <Card unsetZIndex>
      {list.items.map((p, index) => {
        const isSubmittedItem = p.value.hasSubmitted || hasSubmitted;

        return (
          <MedicationRequestForm
            key={p.key}
            title={title}
            onAdd={() => {
              list.update(p.key, { ...p.value, hasErrors: false, hasSubmitted: true });
              list.add(Math.random(), {
                data: {},
                hasErrors: true,
                hasSubmitted: false,
              });
            }}
            onChange={(data, hasErrors) =>
              list.update(p.key, {
                hasSubmitted: p.value.hasSubmitted,
                data,
                hasErrors,
              })
            }
            onRemove={() => {
              list.remove(p.key);
            }}
            canAdd={index === list.items.length - 1}
            canRemove={list.items.length > 1}
            showErrors={isSubmittedItem && p.value.hasErrors}
            initialValues={p.value.data}
            medicationFetcher={medicationFetcher}
          />
        );
      })}
      <ButtonWrapper>
        <RecipientPicker onSubmit={submit} organizationFetcher={organizationFetcher} />
      </ButtonWrapper>
    </Card>
  );
};
