import { VideoConsult } from '@quromedical/models';
import { AlertButton, IconName } from 'design-system';
import { VideoConsultApi } from 'integration/aggregate';
import React from 'react';
import { strings } from 'strings';

interface InviteButtonProps {
  roomId: string;
  patientId: string;
  type: VideoConsult.InvitationType;
  icon: IconName;
  buttonText: string;
}

const videoApi = new VideoConsultApi();

export const InviteButton: React.FC<InviteButtonProps> = ({
  roomId,
  patientId,
  type,
  icon,
  buttonText,
}) => {
  const onPress = async () => {
    await videoApi.sendInvite(patientId, { roomId, type });
  };

  return (
    <AlertButton
      variant="flat-alt"
      icon={icon}
      text={buttonText}
      success={{
        title: strings.AlertSuccess,
        body: strings.AlertSendInviteSuccessful,
      }}
      error={{
        title: strings.AlertError,
        body: strings.AlertSendInviteFailed,
      }}
      onPress={onPress}
    />
  );
};
