import { Row } from 'components/base';
import { Visible } from 'design-system/base';

import { isGroup } from './helpers';
import { IndentWrapper } from './IndentWrapper';
import { ItemPill } from './ItemPill';
import { Group } from './types';

interface NestedCommentFormProps {
  initial: Group;
  parent?: Group;
  onChange: (data: Group) => void;
}

export const NestedCommentForm: React.FC<NestedCommentFormProps> = ({ initial, onChange }) => {
  const groupChildren = initial.children.filter((d) => d.selected).filter(isGroup);

  return (
    <>
      <Visible if={initial.selected && initial.children.length}>
        <Row flexGrow={1} flexWrap="wrap">
          {initial.children.map((child) => (
            <ItemPill
              key={child.id}
              node={child}
              onTextChange={(text) => {
                if (child.type === 'comment') {
                  onChange({
                    ...initial,
                    children: initial.children.map((c) =>
                      c.id === child.id
                        ? {
                            ...child,
                            text,
                          }
                        : c
                    ),
                  });
                }
              }}
              onUnSelect={() =>
                onChange({
                  ...initial,
                  children: initial.children.map((c) =>
                    c.id === child.id ? { ...child, selected: false } : c
                  ),
                })
              }
              onSelect={() =>
                onChange({
                  ...initial,
                  children: initial.children.map((c) =>
                    c.id === child.id ? { ...child, selected: true } : c
                  ),
                })
              }
            />
          ))}
        </Row>

        <Visible if={!!groupChildren.length}>
          {groupChildren.map((child) => (
            <IndentWrapper
              marginLeft="3xs"
              key={child.id}
              color={child.type === 'group' ? child.color : undefined}
              icon={child.icon}
            >
              <NestedCommentForm
                parent={initial}
                initial={child}
                onChange={(data) =>
                  onChange({
                    ...initial,
                    children: initial.children.map((c) => (c.id === data.id ? data : c)),
                  })
                }
              />
            </IndentWrapper>
          ))}
        </Visible>
      </Visible>
    </>
  );
};
