import { Person } from '@quromedical/models';
import { Breadcrumb } from 'components/fhir';
import { getNameAgeAndGender } from 'core/display';
import { useIsSmallerThan } from 'hooks/useResponsive';
import { LinkTo } from 'navigation';
import React from 'react';
import { strings } from 'strings';

interface PatientBreadcrumbProps {
  id: string;
  patient?: Partial<Person.General>;
}

const patientListTo: LinkTo = {
  screen: 'Patient',
  params: {
    screen: 'PatientList',
  },
};

/**
 * Will display a `Breadcrumb` that links to the patient list. Only visible on small devices
 */
export const PatientBreadcrumb: React.FC<PatientBreadcrumbProps> = ({ id, patient }) => {
  const isSmall = useIsSmallerThan('lg');
  const patientDisplay = getNameAgeAndGender(patient);

  if (isSmall) {
    return null;
  }

  const patientProfileTo: LinkTo = {
    screen: 'Patient',
    params: {
      screen: 'View',
      params: {
        id,
        screen: 'Profile',
      },
    },
  };

  return (
    <Breadcrumb
      links={[
        { text: strings.MyPatients, to: patientListTo },
        { text: patientDisplay, to: patientProfileTo },
      ]}
    />
  );
};
