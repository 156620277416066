import { GroupSecurity } from '@quromedical/fhir-common';
import { Notes } from '@quromedical/models';
import { Util } from '@quromedical/utils';
import { List, Section } from 'design-system';
import { useUserSession } from 'hooks';
import { usePatientId } from 'providers/PatientIdContext';
import React, { createContext, useContext, useState } from 'react';

import { Footer, Item, NoteListHeader, useAllNotes, NoteItemData } from './list';

interface NoteListContextData {
  canLoadMore: boolean;
  isLoading: boolean;
  data: Notes.Note[];
  loadMore: () => void;
  search: string;
  error?: unknown;
  setSearch: (value: string) => void;
  revalidate: () => void;
}

const NoteListContext = createContext<NoteListContextData>({
  canLoadMore: false,
  isLoading: false,
  data: [],
  loadMore: () => undefined,
  search: '',
  setSearch: () => undefined,
  revalidate: () => undefined,
});

const useNoteList = () => useContext(NoteListContext);

const ListHeaderComponent: React.FC = () => {
  const { search, setSearch, data, isLoading, revalidate } = useNoteList();
  const id = usePatientId();

  return (
    <NoteListHeader
      loading={isLoading}
      notes={data}
      search={search}
      setSearch={setSearch}
      revalidate={revalidate}
      id={id}
    />
  );
};

const ListFooterComponent: React.FC = () => {
  const { canLoadMore, loadMore, error } = useNoteList();
  return (
    <Section hasBottomMargin>
      <Footer onPress={loadMore} canLoadMore={canLoadMore} hasError={!!error} />
    </Section>
  );
};

const createNotesToDataMap =
  (patientId: string, canEdit: boolean, revalidate: () => void) =>
  (fromPins: boolean): Util.ArrayMap<Notes.Note, NoteItemData> =>
  (note) => ({
    ...note,
    revalidate,
    canEdit,
    patientId,
    fromPins,
  });

export const PatientNotesScreen: React.FC = () => {
  const patientId = usePatientId();
  const session = useUserSession();

  const canEdit = session.hasAny([GroupSecurity.ClinicalImpressionUpdate]);

  const [search, setSearch] = useState('');

  const {
    canLoadMore,
    isLoading,
    loadMore,
    data = [],
    pins = [],
    revalidate,
    error,
  } = useAllNotes(patientId, search);

  const mapNotesToData = createNotesToDataMap(patientId, canEdit, revalidate);

  const notesData = data.map<NoteItemData>(mapNotesToData(false));
  const pinsData = pins.map<NoteItemData>(mapNotesToData(true));

  const allData = [...pinsData, ...notesData];

  return (
    <NoteListContext.Provider
      value={{
        canLoadMore,
        isLoading,
        data,
        loadMore,
        search,
        setSearch,
        revalidate,
        error,
      }}
    >
      <List
        ListHeaderComponent={ListHeaderComponent}
        loading={isLoading}
        spacing="2xs"
        data={allData}
        keyExtractor={(item) =>
          // in order to ensure that items are updated appropriately the key needs to consider the
          // pinned state as well as the data source
          `${item.id}_${item.pinned ? 'pinned' : ''}_${item.fromPins ? 'pin' : ''}`
        }
        Item={Item}
        ListFooterComponent={ListFooterComponent}
      />
    </NoteListContext.Provider>
  );
};
