import { FormCard, SkeletonProvider, RowConfig, Snackbar, Alert } from 'design-system';
import { logger } from 'helpers';
import { useFiniteState } from 'hooks/useFiniteState';
import { useRevalidation } from 'hooks/useRevalidation';
import { DeviceApi } from 'integration/aggregate';
import chunk from 'lodash.chunk';
import React, { useCallback } from 'react';
import { strings } from 'strings';
import useSWR from 'swr';

import { mapStatusItemToField } from './helpers';
import { MDMError } from './MDMError';

type RebootStatus = 'initial' | 'loading' | 'error';

interface StatusCardProps {
  deviceId: string;
}

const deviceApi = new DeviceApi();

export const StatusCard: React.FC<StatusCardProps> = ({ deviceId }) => {
  const rebootStatus = useFiniteState<RebootStatus>('initial');

  const statusFetcher = () => deviceApi.getMDMStatus(deviceId);
  const baseSwrKey = deviceApi.getDeviceSWRKey(deviceId);
  const swrKey = `${baseSwrKey}/tinymdm-status`;
  const status = useSWR(swrKey, statusFetcher);

  const revalidateStatus = useRevalidation(swrKey);
  const rebootDevice = useCallback(async () => {
    rebootStatus.set('loading');
    try {
      await deviceApi.rebootDevice(deviceId);
      await revalidateStatus();
      rebootStatus.set('initial');
    } catch (error) {
      logger.error(error);
      rebootStatus.set('error');
    }
  }, [deviceId, rebootStatus, revalidateStatus]);

  const loading = status.isValidating || rebootStatus.is('loading');
  const error = !!status.error;

  if (error) {
    return <MDMError />;
  }

  const field = Object.entries(status.data || {}).map(([name, statusItem]) =>
    mapStatusItemToField(name, statusItem)
  );

  const chunks = chunk(field, 3);
  const rows: RowConfig[] = chunks.map((fields) => ({ fields }));

  const showRebootAlert = rebootStatus.is('error');

  return (
    <>
      <SkeletonProvider loading={loading}>
        <FormCard
          rows={rows}
          title={strings.TinyMDMStatusTitle}
          onButtonPress={rebootDevice}
          buttonVariant="contained"
          buttonText={strings.TinyMDMRebootButton}
          buttonIcon="phone-android"
          buttonDisabled={loading}
        />
      </SkeletonProvider>

      <Snackbar onClose={rebootStatus.next('initial')} isOpen={showRebootAlert}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={rebootStatus.next('initial')}
          actionIcon="close"
          title={strings.TinyMDMRebootErrorTitle}
          body={strings.TinyMDMRebootErrorText}
        />
      </Snackbar>
    </>
  );
};
