import { MeasurementName, Vitals } from '@quromedical/models';
import { getDomain } from 'components/graph';
import { codes, colors, domains, gradientStops, titles, units, valueFormatters } from 'core/vitals';
import { sort } from 'd3-array';
import { getExpandedDomain, SkeletonProvider, VitalCard, Text } from 'design-system';
import { Grid } from 'design-system/layout';
import { useLayoutDimensions } from 'hooks/useLayoutDimensions';
import React from 'react';
import { View } from 'react-native';
import { strings } from 'strings';

interface VitalsSectionProps {
  data?: Vitals.VitalsResultMeasures;
  loading?: boolean;
}

const placeholderMeasures = [
  MeasurementName.Battery,
  MeasurementName.Battery,
  MeasurementName.Battery,
];

const Loading: React.FC = () => (
  <SkeletonProvider loading>
    <Grid>
      {placeholderMeasures.map((_, i) => (
        <VitalCard
          identifier=""
          key={i}
          initialWidth={0}
          title=""
          code=""
          color="text-disabled"
          hideSummary
        />
      ))}
    </Grid>
  </SkeletonProvider>
);

export const VitalsSection: React.FC<VitalsSectionProps> = ({ data = {}, loading = false }) => {
  const { onLayout, layout } = useLayoutDimensions({});

  const availableMeasures = Object.values(MeasurementName).filter(
    (measureName) => data[measureName]?.length
  );

  if (loading) {
    return <Loading />;
  }

  if (!availableMeasures?.length) {
    return <Text variant="page-heading">{strings.PatientPrintTitleNoVitals}</Text>;
  }

  return (
    <View onLayout={onLayout}>
      <Grid>
        {availableMeasures.map((measureName) => {
          const vitals = data[measureName]?.map((d) => ({
            x: d.ts,
            y: d.value,
          }));

          const graphData = sort(vitals || [], (d) => d.x);

          const measureDomain = domains[measureName];
          const dataDomain = getDomain(graphData);

          const yDomain =
            dataDomain.y &&
            getExpandedDomain(
              measureDomain || dataDomain.y,
              graphData.map((d) => d.y)
            );

          return (
            <VitalCard
              identifier={measureName}
              initialWidth={layout.width}
              yDomain={yDomain}
              title={titles[measureName] || measureName}
              code={codes[measureName] || '  '}
              data={graphData}
              gradient={gradientStops[measureName]}
              valueFormatter={valueFormatters[measureName]}
              unit={units[measureName]}
              color={colors[measureName] || 'text-disabled'}
              hideSummary
            />
          );
        })}
      </Grid>
    </View>
  );
};
