import { IdentifierCode } from '@quromedical/fhir-common';

export const IdentifierCodeDisplay: Record<IdentifierCode, string> = {
  [IdentifierCode.coverageSchemeCode]: 'Medical Aid Scheme Code',
  [IdentifierCode.coverageSubscriberId]: 'Medical Aid Number',
  [IdentifierCode.coverageDependentCode]: 'Medical Aid Dependant Code',
  [IdentifierCode.deviceType]: 'Device Type',
  [IdentifierCode.medicalAidAuthorizationCode]: 'Medical Aid Authorization Code',
  [IdentifierCode.passportNumber]: 'Passport Number',
  [IdentifierCode.registrationNumber]: 'Registration Number',
  [IdentifierCode.serviceRequestPerformer]: 'Request Performer',
  [IdentifierCode.southAfricanIdNumber]: 'South African ID Number',
  [IdentifierCode.namibianIdNumber]: 'Namibian ID Number',
  [IdentifierCode.encounterHospitalizationDependentCode]: 'Medical Aid Dependant Code',
  [IdentifierCode.encounterHospitalizationSchemeCode]: 'Medical Aid Scheme Code',
  [IdentifierCode.encounterHospitalizationSubscriberId]: 'Medical Aid Number',
  [IdentifierCode.encounterMedicalAidAuthorizationCode]: 'Medical Aid Authorization Code',
  [IdentifierCode.encounterMedicalAidAuthorizationDate]: 'Medical Aid Authorization Date',
  [IdentifierCode.logisticsTrackingNumber]: 'Tracking Number',
  [IdentifierCode.organizationSchemeCode]: 'Medical Aid Scheme Code',
  [IdentifierCode.claimInvoiceNumber]: 'Invoice number',
  [IdentifierCode.medicationStatementGroupIdentifier]: 'Prescription',
  [IdentifierCode.medicationRequestGroupIdentifier]: 'Prescription',
};
