import { ObjectType } from '@quromedical/fhir-common';
import { Medication } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { Field, SelectOption } from 'components/types';
import { mapDisplaysToOptions } from 'core/forms';
import { useCallback, useState } from 'react';
import { strings } from 'strings';
import * as yup from 'yup';

import { mapMedicationRepeatToRepeatName } from './internal';
import {
  medicationFrequencyDisplay,
  MedicationRequestFormData,
  medicationDurationDisplay,
  MedicationDuration,
  MedicationRepeat,
  DurationDescription,
  RepeatDescription,
} from './types';

interface FormProps {
  title?: string;
  medicationFetcher: (query?: string) => Promise<SelectOption[]>;
  onAdd: () => void;
  onChange: (data: Partial<MedicationRequestFormData>, hasErrors: boolean) => void;
  onRemove: () => void;
  canAdd: boolean;
  canRemove: boolean;
  showErrors: boolean;
  initialValues: Partial<MedicationRequestFormData>;
}

const MedicationDurationCodes = Object.values(MedicationDuration);
const MedicationRepeatCodes = Object.values(MedicationRepeat);

export type FormData = {
  medication: SelectOption<any, string>;
  duration: MedicationDuration;
  substitution: boolean;
  repeat: MedicationRepeat;
  noteText?: string;
  refill?: number;
};

const schema = yup
  .object<ObjectType<FormData>>({
    medication: yup.object().required(),
    repeat: yup.string().oneOf(MedicationRepeatCodes).required(),
    noteText: yup.string().notRequired(),
    substitution: yup.string().required(),
    duration: yup.string().oneOf(MedicationDurationCodes).required(),
    refill: yup.number().required(),
  })
  .required();

const createFields = (
  medicationFetcher: (query?: string) => Promise<SelectOption[]>
): Field<FormData>[] => [
  {
    subfields: [
      {
        key: 'medication',
        label: strings.LabelMedication,
        initialSelection: [],

        searchable: true,
        type: 'combobox-single',
        fetcher: medicationFetcher,
        returnFullOption: true,
      },
      {
        type: 'text-box',
        key: 'noteText',
        label: strings.LabelMedicationNoteText,
      },
    ],
  },
  {
    subfields: [
      {
        key: 'duration',
        label: strings.LabelMedicationDuration,
        type: 'radio-group',
        options: mapDisplaysToOptions(medicationDurationDisplay),
      },
      {
        type: 'radio-group',
        key: 'refill',
        label: strings.LabelMedicationRefill,
        options: [
          {
            value: 1,
            display: strings.MedicationRequestRefillOnce,
          },
          {
            value: 2,
            display: strings.MedicationRequestRefillTwice,
          },
          {
            value: 3,
            display: strings.MedicationRequestRefillThreeTimes,
          },
        ],
      },
    ],
  },
  {
    subfields: [
      {
        key: 'repeat',
        label: strings.LabelMedicationFrequency,
        type: 'radio-group',
        options: mapDisplaysToOptions(medicationFrequencyDisplay),
      },
      {
        type: 'radio-group',
        key: 'substitution',
        label: strings.LabelMedicationGenerics,
        options: [
          {
            value: true,
            display: 'Yes',
          },
          {
            value: false,
            display: 'No',
          },
        ],
      },
    ],
  },
];

const mapMedicationDurationToDurationName = (
  duration?: Medication.Duration
): MedicationDuration | undefined => {
  if (!duration) {
    return undefined;
  }
  const descriptions = Object.keys(DurationDescription) as MedicationDuration[];

  const foundDescription = descriptions.find((key) => {
    const value = DurationDescription[key];
    const isSame = value.unit === duration.unit && value.value === duration.value;

    return isSame;
  });

  return foundDescription;
};

const mapInitialValuesToFormData = (
  initialValues: Partial<MedicationRequestFormData>
): Partial<FormData> => ({
  ...initialValues,
  duration: mapMedicationDurationToDurationName(initialValues.duration),
  repeat: mapMedicationRepeatToRepeatName({
    frequency: initialValues.frequency,
    period: initialValues.period,
    periodUnit: initialValues.periodUnit,
  }),
});

export const MedicationRequestForm = ({
  title,
  medicationFetcher,
  onAdd,
  onChange,
  onRemove,
  canAdd,
  canRemove,
  showErrors,
  initialValues,
}: FormProps) => {
  const [medicationDisplay, setMedicationDisplay] = useState(title);

  const handleChange = useCallback(
    (data: Partial<FormData>) => {
      const medication = data.medication;

      // manually check schema since the internal form does not validate if not touched
      const isValid = schema.isValidSync(data);
      setMedicationDisplay(medication?.display || title);
      const duration = data.duration && DurationDescription[data.duration];
      const repeat = data.repeat ? RepeatDescription[data.repeat] : {};

      onChange(
        {
          medicationCode: medication?.value,
          noteText: data.noteText,
          refill: data.refill,
          substitution: data.substitution,
          duration,
          ...repeat,
        },
        !isValid
      );
    },
    [onChange, title]
  );

  const initialData = mapInitialValuesToFormData(initialValues);

  const onSubmit = useCallback(() => {
    onAdd();
    return {};
  }, [onAdd]);

  const fields = createFields(medicationFetcher);

  return (
    <CrudForm<FormData>
      title={medicationDisplay}
      validationSchema={schema}
      fields={fields}
      initialValues={initialData}
      hasCard={false}
      buttonText={strings.FormListAddItem}
      onSecondarySubmit={onRemove}
      secondaryButtonText={strings.FormListRemoveItem}
      showSecondarySubmitButton={canRemove}
      onChange={handleChange}
      onSubmit={onSubmit}
      validateOnChange
      validateOnMount={showErrors}
      onChangeDebounceTime={0}
      showSubmitButton={canAdd}
      buttonVariant="outlined"
    />
  );
};
