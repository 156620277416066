import styled from '@emotion/native';
import { Col, Row } from 'components/base';
import { Icon, Spacer, Text } from 'design-system';
import { MARGIN } from 'design-system/theme';
import React from 'react';

import type { Action } from '../types';
import { Shortcut } from './Shortcut';
import { PaddedWrapper } from './Wrappers';

interface SearchItemProps {
  item: string | Action;
  active: boolean;
}

interface ActiveProps {
  active: boolean;
}

const SearchItemWrapper = styled(Col)<ActiveProps>(({ active, theme }) => ({
  backgroundColor: active ? theme.color.base : undefined,
}));

export const SearchItem: React.FC<SearchItemProps> = ({ item, active }) => {
  //  this means it's a section
  if (typeof item === 'string') {
    return <Text variant="caption-subdued">{item}</Text>;
  }

  return (
    <SearchItemWrapper active={active}>
      <PaddedWrapper>
        <Row alignItems="center">
          <Icon color={active ? 'primary' : 'text-default'} name={item.iconName} />
          <Spacer width={MARGIN.s} />

          <Col flex={1}>
            <Row>
              <Text variant="body-strong">{item.name}</Text>
            </Row>
            <Row alignItems="center" justifyContent="space-between">
              <Text variant="caption-subdued">{item.subtitle}</Text>
              <Shortcut shortcut={item.shortcut} />
            </Row>
          </Col>
        </Row>
      </PaddedWrapper>
    </SearchItemWrapper>
  );
};
