import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { resolveZIndex } from 'components/base';
import { BORDER_WIDTH, PADDING, useTheme } from 'design-system/theme';
import { PropsWithChildren } from 'react';

export interface CardProps {
  border?: boolean;
  color?: ColorName;
  paddingVertical?: number;
  paddingHorizontal?: number;
  borderColor?: ColorName;
  hasThickBorder?: Boolean;
  unsetZIndex?: boolean;
  overflow?: 'hidden' | 'visible';
}

export const Card = styled.View<PropsWithChildren<CardProps>>(
  ({
    border = false,
    color = 'base-grey',
    borderColor = 'divider',
    hasThickBorder = false,
    paddingHorizontal = PADDING.m,
    paddingVertical = PADDING.s,
    unsetZIndex = false,
    overflow,
  }) => {
    const theme = useTheme();
    const backgroundColor = theme.color[color];
    const borderTopColor = theme.color[borderColor];
    const borderWidth = hasThickBorder ? BORDER_WIDTH[2] : BORDER_WIDTH[1];
    const borderTopWidth = border ? borderWidth : 0;
    const zIndex = resolveZIndex(unsetZIndex);

    return {
      width: '100%',
      paddingHorizontal,
      paddingVertical,
      backgroundColor,
      borderTopColor,
      borderStyle: 'solid',
      borderTopWidth,
      zIndex,
      overflow,
    };
  }
);
