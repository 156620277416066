import {
  getCreateReferenceId,
  getNameText,
  getReferenceId,
  isValidReference,
} from '@quromedical/fhir-common';
import { Patient, Logistics } from '@quromedical/models';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Field, SelectOption } from 'components/types';
import { mapDisplaysToOptions } from 'core/forms';
import { Alert, Skeleton, SkeletonProvider, Snackbar } from 'design-system';
import { Reference } from 'fhir/r4';
import { useFiniteState } from 'hooks/useFiniteState';
import { compact, first } from 'lodash';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';
import useSWR from 'swr';
import { ObjectType } from 'validation';
import * as yup from 'yup';

interface CollectionCardProps {
  patient: Patient.GetPatientResponse;
  patientId: string;
  onSubmit: (data: SubmissionData) => Promise<void>;
  getDistance: GetDistance;
}

export type SubmissionData = {
  organizationId: string;
  collectionKit: Logistics.Package[];
};

type CollectionCardFormData = {
  organizationId: string;
  collectionKit: Logistics.Package[];
  // For display purposes
  patientName?: undefined;
  patientAddress?: undefined;
  selectedOrganizationName?: string | undefined;
  distance?: undefined;
};

const packages = Object.values(Logistics.Package) as string[];
const packageSchema = yup.array(yup.string().required().oneOf(packages)).min(1);

const schema = yup.object<ObjectType<CollectionCardFormData>>({
  organizationId: yup.string().required(),
  collectionKit: packageSchema,
});

type DisplayPackages = Record<Logistics.Package, string>;

const displayPackages: DisplayPackages = {
  [Logistics.Package.BPMonitoringKit]: strings.LogisticsBPMonitoringKitLabel,
  [Logistics.Package.Concentrator]: strings.LogisticsConcentratorLabel,
  [Logistics.Package.Cylinder10L]: strings.LogisticsCylinder10LLabel,
  [Logistics.Package.Cylinder3L]: strings.LogisticsCylinder3LLabel,
  [Logistics.Package.MonitoringKit]: strings.LogisticsMonitoringKitLabel,
};

const organizationFromReferenceFetcher = (organizations: Reference[]): SelectOption[] =>
  organizations.filter(isValidReference).map((org) => ({
    display: org.display || strings.DisplayNotFound,
    value: getCreateReferenceId(org),
  }));

type KilometerDisplay = `${number} km`;

const getDistanceKilometerDisplay = (meters: number | undefined): KilometerDisplay | undefined => {
  if (meters === undefined) {
    return undefined;
  }

  const kilometers = meters / 1000;

  return `${kilometers} km`;
};

const fields = (
  patient: Patient.GetPatientResponse,
  organizations: Reference[],
  selectedOrganization?: Reference,
  distance?: number
): Field<CollectionCardFormData>[] => [
  {
    subfields: [
      {
        key: 'patientName',
        type: 'text-display',
        label: strings.LogisticsPatientNameLabel,
        text: getNameText(patient.general.givenName, patient.general.familyName),
      },
      {
        key: 'patientAddress',
        type: 'text-display',
        label: strings.LogisticsFromLabel,
        text: compact([
          ...(patient.contact.addressLines || []),
          patient.contact.addressCity,
          patient.contact.addressPostalCode,
          patient.contact.addressCountry,
        ]).join(', '),
      },
    ],
  },
  {
    subfields: [
      {
        key: 'selectedOrganizationName',
        type: 'text-display',
        label: strings.LogisticsOrganizationNameLabel,
        text: selectedOrganization?.display,
      },
      {
        key: 'organizationId',
        type: 'combobox-single',
        label: strings.LogisticsToLabel,
        fetcher: organizationFromReferenceFetcher(organizations),
        searchable: false,
      },
    ],
  },
  {
    subfields: [
      {
        key: 'distance',
        type: 'text-display',
        label: strings.LogisticsDistanceLabel,
        text: getDistanceKilometerDisplay(distance),
      },
      {
        key: 'collectionKit',
        type: 'combobox-multiple',
        fetcher: mapDisplaysToOptions(displayPackages),
        label: strings.LogisticsCollectionKitLabel,
        searchable: false,
      },
    ],
  },
];

interface DistanceResult {
  distance: number;
}

export type GetDistance = (patientId: string, orgId: string) => Promise<DistanceResult>;

const useDistance = (getDistance: GetDistance, patientId?: string, orgId?: string) => {
  const result = useSWR<DistanceResult | undefined>(`distance-${patientId}-${orgId}`, () => {
    if (!patientId || !orgId) {
      return undefined;
    }

    return getDistance(patientId, orgId);
  });

  return result;
};

type FormState = 'editing' | 'loading' | 'submissionError' | 'calculatingDistance';

export const CollectionCard: React.FC<CollectionCardProps> = ({
  patient,
  patientId,
  getDistance,
  onSubmit,
}) => {
  const state = useFiniteState<FormState>('editing');

  const patientOrganizations = patient.generalPractitioner.organizations;
  const firstOrgRef = first(patientOrganizations);
  const orgId = getReferenceId(firstOrgRef);

  const [formState, setFormState] = useState<Partial<CollectionCardFormData>>({
    collectionKit: [],
    organizationId: orgId,
  });

  const distance = useDistance(getDistance, patientId, formState.organizationId);
  const distanceValue = distance.data?.distance;

  const hasError = distance.error || state.is('submissionError');
  const errorMessage = distance.error
    ? strings.LogisticsDistanceCalculationError
    : strings.LogisticsDistanceSubmittingError;

  const selectedOrganization = patientOrganizations.find(
    (org) => getReferenceId(org) === formState.organizationId
  );

  const cardFields = fields(patient, patientOrganizations, selectedOrganization, distanceValue);

  const handleSubmit = useCallback<SubmissionHandler<CollectionCardFormData>>(
    async (data: CollectionCardFormData) => {
      state.set('loading');

      try {
        await onSubmit({ organizationId: data.organizationId, collectionKit: data.collectionKit });
        state.set('editing');
        return {};
      } catch (error) {
        state.set('submissionError');
        return { error };
      }
    },
    [onSubmit, state]
  );

  return (
    <>
      <SkeletonProvider loading={state.is('loading')}>
        <Skeleton>
          <CrudForm<CollectionCardFormData>
            cardProps={{ unsetZIndex: true }}
            key={patientId}
            title={strings.LogisticsCollectionCardTitle}
            fields={cardFields}
            initialValues={formState}
            onSubmit={handleSubmit}
            disabled={state.is('loading') || distance.isValidating}
            showSubmitButton
            buttonText={strings.LogisticsGetQuoteButton}
            onChange={setFormState}
            validationSchema={schema}
          />
        </Skeleton>
      </SkeletonProvider>

      <Snackbar onClose={state.next('editing')} isOpen={hasError}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={state.next('editing')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={errorMessage}
        />
      </Snackbar>
    </>
  );
};
