import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { PrimitiveValueType } from 'components/types';
import { IconName, Button, BorderRadiusProps } from 'design-system/base';
import { MARGIN } from 'design-system/theme';
import React, { ReactElement } from 'react';

import { Wrapper } from '../internal';

export interface ToggleOption<TValue> {
  value: TValue;
  display?: string;
  icon?: IconName;
}

export interface ToggleButtonProps<TValue extends PrimitiveValueType> {
  options: ToggleOption<TValue>[];
  value: TValue;
  label?: string;
  error?: string;
  isDisabled?: boolean;
  hideError?: boolean;
  rightAlign?: boolean;
  onChange: (value: TValue) => void;
}

const getBorderRadiusProps = (index: number, count: number): BorderRadiusProps => {
  const isFirst = index === 0;

  const lastIndex = count - 1;
  const isLast = index === lastIndex;

  const borderRadiusProps: BorderRadiusProps = {
    unsetBottomLeftRadius: !isFirst,
    unsetTopLeftRadius: !isFirst,
    unsetBottomRightRadius: !isLast,
    unsetTopRightRadius: !isLast,
  };

  return borderRadiusProps;
};

interface ButtonWrapperProps {
  hasTopMargin: boolean;
}

const ButtonWrapper = styled.View<ButtonWrapperProps>(({ hasTopMargin }) => ({
  flexDirection: 'row',
  marginTop: hasTopMargin ? MARGIN['2xs'] : 0,
}));

export const ToggleButton = <TValue extends PrimitiveValueType>({
  options,
  value,
  onChange,
  label,
  error,
  isDisabled = false,
  hideError = false,
  rightAlign = false,
}: ToggleButtonProps<TValue>): ReactElement => (
  <Wrapper
    hideErrorSection={hideError}
    label={label}
    error={error}
    background={false}
    border={false}
    insetLabel={false}
    rightAlign={rightAlign}
  >
    <ButtonWrapper hasTopMargin={!!label}>
      {options.map((option, index, arr) => {
        const selected = option.value === value;
        const textColor: ColorName = selected ? 'white' : 'text-default';
        const showContained = selected && !isDisabled;
        const variant = showContained ? 'contained' : 'outlined';
        const borderRadiusProps = getBorderRadiusProps(index, arr.length);
        const handlePress = () => onChange(option.value);
        const key = option.value?.toString() || '__undefined__';

        return (
          <Button
            key={key}
            {...borderRadiusProps}
            disabled={isDisabled}
            iconPosition="left"
            variant={variant}
            text={option.display}
            icon={option.icon}
            textColor={textColor}
            onPress={handlePress}
          />
        );
      })}
    </ButtonWrapper>
  </Wrapper>
);
