import { Healthbridge, MedicalAid } from '@quromedical/models';
import { Col } from 'components/base';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Field, SelectOption } from 'components/types';
import { Fetcher } from 'design-system';
import { logger } from 'helpers';
import compact from 'lodash.compact';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

interface Search {
  scheme?: SelectOption<Healthbridge.Administrator, string>;
  idNumber: string;
  memberNumber?: string;
}

const idNumberSearchSchema = yup.object<ObjectType<Search>>({
  scheme: yup.object().required(),
});

const memberNumberSearchSchema = yup.object<ObjectType<Search>>({
  scheme: yup.object().required(),
  memberNumber: yup.string().required(),
});

const createFields = (
  schemeFetcher: Fetcher<string, any>,
  canSearchOnIDNumber: boolean,
  idNumber: string
): Field<Search>[] =>
  compact([
    {
      subfields: [
        {
          key: 'idNumber',
          type: 'text-display',
          label: strings.LabelIDNumber,
          text: idNumber,
        },
        {
          key: 'scheme',
          type: 'combobox-single',
          label: strings.FormLabelScheme,
          fetcher: schemeFetcher,
          searchable: true,
          returnFullOption: true,
        },
      ],
    },
    canSearchOnIDNumber
      ? undefined
      : {
          subfields: [
            {
              key: 'memberNumber',
              type: 'text-box',
              label: strings.FormLabelMedicalAidNumber,
            },
          ],
        },
  ]);

export interface SearchResult {
  search: Search;
  result: MedicalAid.MedicalAidValidationResponse;
}

export type OnSearch = (
  request: MedicalAid.MedicalAidValidationRequest
) => Promise<MedicalAid.MedicalAidValidationResponse>;

interface MedicalAidUpdateFormProps {
  idNumber: string;
  schemeFetcher: Fetcher<string, any>;
  onSearch: OnSearch;
  onResult: (result: SearchResult) => void;
  onError: () => void;
}

type State = 'initial' | 'loading';

export const MedicalAidUpdateForm: React.FC<MedicalAidUpdateFormProps> = ({
  onResult,
  onSearch,
  onError,
  schemeFetcher,
  idNumber,
}) => {
  const [search, setForm] = useState<Partial<Search>>({});
  const [state, setState] = useState<State>('initial');

  const isFormDisabled = state === 'loading';

  // default to true if undefined
  const canSearchOnIDNumber = search?.scheme?.meta?.canSearchOnIDNumber ?? true;

  const schema = canSearchOnIDNumber ? idNumberSearchSchema : memberNumberSearchSchema;

  const fields = createFields(schemeFetcher, canSearchOnIDNumber, idNumber);

  const onSubmit = useCallback<SubmissionHandler<Partial<Search>>>(
    async (formState: Partial<Search>) => {
      setState('loading');
      try {
        const membershipNumber = canSearchOnIDNumber ? undefined : search.memberNumber;

        // casting since the data is validated
        const result = await onSearch({
          idNumber,
          membershipNumber,
          schemeCode: search.scheme?.value as string,
        });
        onResult({ search: formState as Search, result });
        setState('initial');
        return {};
      } catch (error) {
        logger.error(error);
        onError();
        setState('initial');
        return { error };
      }
    },
    [
      canSearchOnIDNumber,
      search.memberNumber,
      search.scheme?.value,
      onSearch,
      idNumber,
      onResult,
      onError,
    ]
  );

  return (
    <Col unsetZIndex>
      <CrudForm
        title={strings.MedicalAidPatientUpdateSearchFormTitle}
        fields={fields}
        initialValues={{}}
        onChange={setForm}
        onChangeDebounceTime={0}
        validationSchema={schema}
        disabled={isFormDisabled}
        onSubmit={onSubmit}
        buttonText={strings.ButtonTextSearch}
      />
    </Col>
  );
};
