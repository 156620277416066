import { DeliveryMethod } from '@quromedical/auth';
import { useUserSession } from 'hooks';
import React, { useCallback, useMemo, useState } from 'react';

import { AuthDeliveryMethod } from './AuthDeliveryMethod';
import { AuthPin } from './AuthPin';
import { AuthUsername } from './AuthUsername';
import { AuthenticationStepProps, AuthState } from './types';

enum AuthStep {
  Username = 'username',
  Method = 'method',
  Code = 'code',
}

const authSteps: Record<AuthStep, React.FC<AuthenticationStepProps>> = {
  [AuthStep.Username]: AuthUsername,
  [AuthStep.Code]: AuthPin,
  [AuthStep.Method]: AuthDeliveryMethod,
};

const nextStepMappings: Record<AuthStep, AuthStep | undefined> = {
  [AuthStep.Username]: AuthStep.Method,
  [AuthStep.Method]: AuthStep.Code,
  [AuthStep.Code]: undefined,
};

export const Authentication: React.FC = () => {
  const { getSession } = useUserSession();
  const [step, setStep] = useState<AuthStep | undefined>(AuthStep.Username);
  const [authState, setAuthState] = useState<AuthState>({
    code: '',
    mode: DeliveryMethod.SMS,
    username: '',
    display: '',
    field: '',
  });

  const onNext = useCallback(
    (data: Partial<AuthState> = {}) => {
      const nextStep = step ? nextStepMappings[step] : undefined;
      if (!nextStep) {
        getSession();
        return;
      }
      setStep(nextStep);
      setAuthState({
        ...authState,
        ...data,
      });
    },
    [authState, step, getSession]
  );
  const Component = useMemo(() => {
    if (!step) {
      return () => null;
    }
    const component = authSteps[step];

    return component;
  }, [step]);

  return <Component onNext={onNext} authState={authState} />;
};
