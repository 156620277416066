import styled from '@emotion/native';
import { Validations, Device } from '@quromedical/models';
import { useNavigation } from '@react-navigation/native';
import { Col, Row } from 'components/base';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Alert, MARGIN, Skeleton, SkeletonProvider, Snackbar } from 'design-system';
import { logger } from 'helpers';
import { useRevalidation } from 'hooks/useRevalidation';
import { DeviceApi } from 'integration/aggregate';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';

import { deviceFormFields } from './internal';

const FormWrapper = styled(Col)({ margin: MARGIN.s });

const initialDevice = {};

export const CreateDeviceForm: React.FC = () => {
  const navigation = useNavigation();
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const revalidate = useRevalidation('Device');

  const onSubmit = useCallback<SubmissionHandler<Device.CreateRequest>>(
    async (device: Device.CreateRequest) => {
      setLoading(true);
      try {
        const api = new DeviceApi();
        const result = await api.createDevice(device);

        if (result) {
          navigation.navigate('Admin', {
            screen: 'Device',
            params: {
              screen: 'View',
              params: {
                id: result.id,
              },
            },
          });
        }
        await revalidate();
        setLoading(false);
        return {};
      } catch (error) {
        logger.error(error);
        setHasError(true);
        return { error };
      }
    },
    [navigation, revalidate]
  );

  const onAlertClose = useCallback(() => {
    setHasError(false);
  }, []);

  return (
    <SkeletonProvider loading={loading}>
      <Row alignItems="center" justifyContent="space-between">
        <FormWrapper flex={1}>
          <Skeleton>
            <CrudForm
              title={strings.CreateDevice}
              fields={deviceFormFields}
              showSubmitButton={true}
              initialValues={initialDevice}
              validationSchema={Validations.deviceSchema}
              onSubmit={onSubmit}
            />
          </Skeleton>
        </FormWrapper>
        <Snackbar isOpen={hasError} onClose={onAlertClose}>
          <Alert
            actionIcon="close"
            backgroundColor="status-critical"
            title={strings.AlertError}
            onAction={onAlertClose}
            body={strings.DeviceCreateError}
          />
        </Snackbar>
      </Row>
    </SkeletonProvider>
  );
};
