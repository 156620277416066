// converted using SVGR with React-Native and Typescript Options
// https://react-svgr.com/playground/?native=true&typescript=true
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { Icon } from './internal';

export const Patch: Icon = ({ color = '#000000', size = 24 }) => (
  <Svg width={size} height={size} fill="none" viewBox="0 0 24 24">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.649 8.05a3.3 3.3 0 1 1 3.3 3.301v1.5a4.8 4.8 0 1 0-4.8-4.8h1.5Zm.202 7.9a3.098 3.098 0 0 1 3.098-3.099v-1.5a4.598 4.598 0 0 0-4.598 4.598 3.3 3.3 0 1 1-3.3-3.3v-1.5a4.8 4.8 0 1 0 4.8 4.8Zm-4.8-4.801a3.098 3.098 0 0 0 3.098-3.098h1.5a4.598 4.598 0 0 1-4.598 4.598v-1.5Z"
      fill={color}
    />
  </Svg>
);
