import { Patient } from '@quromedical/models';
import { useNavigation } from '@react-navigation/native';
import { PatientOnboardApi } from 'integration/aggregate';
import { OnboardStackScreenProps } from 'navigation';
import React, { useCallback } from 'react';
import { ScrollView } from 'react-native';

import { PrivateOnboardForm } from './forms';
import { NavigationToggle } from './navigation';

const api = new PatientOnboardApi();

export const OnboardPrivateScreen: React.FC<OnboardStackScreenProps<'Private'>> = () => {
  const navigation = useNavigation();
  const handlePrivatePatient = useCallback(
    async (patient: Patient.PrivateOnboardRequest) => {
      const result = await api.onboardPrivate(patient);

      // navigate to the page for the created patient on completion
      navigation.navigate('Patient', {
        screen: 'View',
        params: {
          screen: 'Profile',
          id: result.patientId,
        },
      });
    },
    [navigation]
  );

  return (
    <ScrollView>
      <NavigationToggle active="Private" />

      <PrivateOnboardForm onSubmit={handlePrivatePatient} />
    </ScrollView>
  );
};
