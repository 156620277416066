import { DeviceCode, DeviceStatus } from '@quromedical/fhir-common';
import { Device } from '@quromedical/models';
import { Field, SelectOption } from 'components/types';
import { deviceCodeOptions, getDeviceTypeDisplayName } from 'core/device';
import { RowConfig } from 'design-system';
import { strings } from 'strings';

const deviceStatusFetcher: SelectOption<undefined, DeviceStatus>[] = [
  {
    display: strings.DisplayActive,
    value: 'active',
  },
  {
    display: strings.DisplayInactive,
    value: 'inactive',
  },
  {
    display: strings.DisplayEnteredInError,
    value: 'entered-in-error',
  },
];

export const deviceFormFields: Field<Partial<Device.Device>>[] = [
  {
    icon: 'devices',
    subfields: [
      {
        type: 'text-box',
        key: 'name',
        label: strings.LabelName,
      },
    ],
  },
  {
    subfields: [
      {
        type: 'combobox-single',
        key: 'type',
        label: strings.LabelDeviceType,
        fetcher: deviceCodeOptions,
        placeholder: strings.PlaceHolderDeviceType,
      },
      {
        type: 'combobox-single',
        key: 'status',
        label: strings.LabelDeviceStatus,
        fetcher: deviceStatusFetcher,
        placeholder: strings.PlaceHolderDeviceStatus,
      },
      {
        type: 'text-box',
        key: 'serialNumber',
        label: strings.LabelDeviceSerialNumber,
      },
    ],
  },
];

export const deviceCardRows = (device: Partial<Device.Device> = {}): RowConfig[] => {
  const deviceSerial: RowConfig =
    device.type === DeviceCode.relay
      ? {
          fields: [
            {
              type: 'text',
              label: strings.LabelDeviceSerialNumber,
              display: device.serialNumber,
            },
          ],
        }
      : {
          fields: [],
        };

  return [
    {
      icon: 'devices',
      fields: [
        {
          type: 'text',
          label: strings.DeviceName,
          display: device.name,
        },
      ],
    },
    {
      fields: [
        {
          type: 'text',
          label: strings.LabelDeviceType,
          display: getDeviceTypeDisplayName(device.type),
        },
        {
          type: 'text',
          label: strings.LabelDeviceType,
          display: device.status,
        },
      ],
    },
    deviceSerial,
  ];
};
