import { ColorName, useTheme } from '@emotion/react';
import * as Graph from 'components/graph';
import { fadeColor, FONT_FAMILY, FONT_SIZE } from 'design-system/theme';
import { noOp } from 'helpers';
import React from 'react';

export interface SliderProps {
  initialWidth: number;
  xDomain?: Graph.Domain;
  hasMarker?: boolean;
  hasTrack?: boolean;
  hasText?: boolean;
  markerColor?: ColorName;
  initialValue?: number;
  margin?: Graph.Margin;
  isVisible?: boolean;
  valueFormatter?: (value?: number) => string;
  onChange?: (value: number) => void;
}

export const Slider: React.FC<SliderProps> = ({
  margin,
  initialValue = 0,
  initialWidth,
  xDomain = [0, 1],
  hasTrack = false,
  hasMarker = false,
  hasText = false,
  isVisible = true,
  markerColor = 'text-default',
  onChange = noOp,
  valueFormatter,
}) => {
  const theme = useTheme();

  const baseHeight = 50;
  const aspectRatio = initialWidth / baseHeight;

  const textColor = theme.color['text-default'];
  const foregroundColor = theme.color.primary;
  const backgroundColor = theme.color['text-default'];
  const resolvedMarkerColor = theme.color[markerColor];
  const trackColor = fadeColor(backgroundColor, 0.25);

  return (
    <Graph.LinearPlot
      flex={1}
      baseWidth={initialWidth}
      baseHeight={baseHeight}
      aspectRatio={aspectRatio}
      margin={margin}
      xDomain={xDomain}
      yDomain={[0, baseHeight]}
      overflow="visible"
    >
      <Graph.Slider
        hasText={hasText}
        isRendered={isVisible}
        hasMarker={hasMarker}
        markerColor={resolvedMarkerColor}
        hasTrack={hasTrack}
        trackColor={trackColor}
        initialValue={initialValue}
        onChange={onChange}
        valueFormatter={valueFormatter}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        textColor={textColor}
        textFont={FONT_FAMILY.regular}
        textSize={FONT_SIZE.caption}
      />
    </Graph.LinearPlot>
  );
};
