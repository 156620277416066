import {
  ClinicalImpressionAssessmentValueCode,
  ClinicalImpressionPlanValueCode,
  GroupSecurity,
} from '@quromedical/fhir-common';
import { Notes } from '@quromedical/models';
import { useLinkTo } from '@react-navigation/native';
import { Col } from 'components/base';
import { NoteSummaryGraph } from 'core/notes';
import { PatientHeaderSection } from 'core/patient';
import {
  getSelectedComments,
  Grid,
  isComment,
  NestedComment,
  Section,
  SkeletonProvider,
  TextBox,
} from 'design-system';
import { useMergedLatestVitals } from 'hooks/useMergedLatestVitals';
import { useIsBiggerThan } from 'hooks/useResponsive';
import { useUserSession } from 'hooks/useUserSession';
import { NotesApi } from 'integration/aggregate';
import compact from 'lodash.compact';
import { usePatientVitals } from 'providers/PatientVitalsProvider';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';

import { NoteForm, NestedNoteForm, SubmitData, baseNoteOptions } from '../form';

// import { baseNoteOptions } from '../form/config';

interface NoteListHeaderProps {
  id: string;
  loading?: boolean;
  notes: Notes.Note[];
  search: string;
  setSearch: (query: string) => void;
  revalidate: () => void;
}

const api = new NotesApi();

const getCommentString = (node: NestedComment.Node) => {
  const comments = getSelectedComments(node);
  return comments.map((comment) => comment.display).join('. ');
};

const mapCommentToStructuredData = (node: NestedComment.Node): Notes.StructuredNoteNode => {
  if (isComment(node)) {
    return {
      type: 'comment',
      id: node.id,
      display: node.display,
      color: node.color,
      selected: node.selected,
      text: node.text,
    };
  }

  return {
    type: 'group',
    id: node.id,
    display: node.display,
    color: node.color,
    selected: node.selected,
    children: node.children.map(mapCommentToStructuredData),
  };
};

export const NoteListHeader: React.FC<NoteListHeaderProps> = ({
  id,
  loading = false,
  notes,
  search,
  setSearch,
  revalidate,
}) => {
  const latest = useMergedLatestVitals(id);
  const { patient, isValidatingVitals } = usePatientVitals();

  const isBig = useIsBiggerThan('md');
  const navigate = useLinkTo();

  const practitioners = patient?.generalPractitioner.practitioners;

  const [lastRefreshed, setLastRefreshed] = useState(Date.now());
  const onRefresh = useCallback(() => {
    setLastRefreshed(Date.now());
    revalidate();
  }, [revalidate]);

  const onPrint = useCallback(() => {
    navigate({
      screen: 'Patient',
      params: {
        screen: 'View',
        params: {
          id,
          screen: 'PrintSummary',
        },
      },
    });
  }, [navigate, id]);

  const session = useUserSession();

  const viewPreviewNotes = session.hasAny([GroupSecurity.FeaturePreviewNotes]);

  const handleSubmit = useCallback(
    async (data: SubmitData) => {
      await api.createNote(id, {
        code: data.code,
        locationCode: data.locationCode,
        media: data.media,
        vitals: latest,
        practitionersToNotify:
          practitioners?.filter((prac) => data.practitionersToNotify.includes(prac.id || '')) || [],
        note: {
          planCode: data.plan.children[0]?.id as ClinicalImpressionPlanValueCode,
          assessmentCode: data.assessment.children[0]?.id as ClinicalImpressionAssessmentValueCode,
          subjective: getCommentString(data.subjective),
          assessment: getCommentString(data.assessment),
          objective: getCommentString(data.objective),
          plan: getCommentString(data.plan),
        },
        structuredContent: {
          type: 'nested-comment-nodes',
          data: compact([
            data.subjective,
            data.objective,
            data.assessment,
            data.plan,
            data.clinicalOperations,
          ]).map(mapCommentToStructuredData),
        },
      });

      revalidate();
    },
    [id, latest, practitioners, revalidate]
  );

  return (
    <SkeletonProvider loading={loading}>
      <Col key={lastRefreshed}>
        <PatientHeaderSection id={id} action={{ icon: 'print', onPress: onPrint }} />

        <Section isVisible={viewPreviewNotes}>
          <NestedNoteForm
            id={id}
            onSubmit={handleSubmit}
            options={baseNoteOptions}
            generalPractitioners={practitioners || []}
          />
        </Section>

        <Section isVisible={!viewPreviewNotes}>
          <NoteForm
            onRefresh={onRefresh}
            vitalsLoading={isValidatingVitals}
            latest={latest}
            generalPractitioners={practitioners}
            id={id}
          />
        </Section>

        <Section>
          <Grid sm={1} xl={2}>
            <TextBox
              onChange={setSearch}
              value={search}
              placeholder={strings.TextBoxPlaceholderSearch}
            />
          </Grid>
        </Section>
        <Section isVisible={isBig} hasBottomMargin>
          <NoteSummaryGraph notes={notes} />
        </Section>
      </Col>
    </SkeletonProvider>
  );
};
