import { GroupSecurity } from '@quromedical/fhir-common';
import { Organization, Person } from '@quromedical/models';
import { OrganizationGeneralForm } from 'core/organization';
import { Alert, Section, Skeleton, SkeletonProvider, Snackbar } from 'design-system';
import { onMapsPress } from 'helpers/platform-links';
import { useUserSession } from 'hooks';
import { useRevalidation } from 'hooks/useRevalidation';
import { getAddressPartialFetcher, googlePlaceFetcher, schemeCodeFetcher } from 'integration';
import { OrganizationApi, OrganizationEndpoint } from 'integration/aggregate';
import { OrganizationStackScreenProps } from 'navigation';
import React, { useCallback } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { PersonContactForm } from 'screens/person';
import { strings } from 'strings';
import useSWR from 'swr';

const api = new OrganizationApi();

export const OrganizationScreen: React.FC<OrganizationStackScreenProps<'View'>> = ({ route }) => {
  const session = useUserSession();

  const canEdit = session.hasAny([GroupSecurity.OrganizationUpdate]);

  const { id } = route.params;
  const fetcher = useCallback(() => api.getOrganization(id), [id]);
  const swrKey = api.getOrganizationSWRKey(id, OrganizationEndpoint.Base);
  const { data, isValidating, error } = useSWR(swrKey, fetcher);

  const revalidate = useRevalidation(swrKey);

  const onGeneralSubmit = useCallback(
    async (formData: Organization.General) => {
      await api.updateOrganizationGeneral(id, formData);
    },
    [id]
  );

  const onContactSubmit = useCallback(
    async (results: Person.Contact) => {
      await api.updateOrganizationContact(id, results);
    },
    [id]
  );

  const onMapsPressed = useCallback(() => {
    onMapsPress(data?.contact);
  }, [data?.contact]);

  if (error) {
    return (
      <Snackbar onClose={revalidate} isOpen={!!error}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={revalidate}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
        />
      </Snackbar>
    );
  }

  const loading = isValidating || !data;

  const isButtonDisabled = !data?.contact?.addressCoordinates;

  return (
    <ScrollView>
      <SkeletonProvider loading={loading}>
        <Section unsetZIndex>
          <Skeleton>
            <OrganizationGeneralForm
              schemeFetcher={schemeCodeFetcher}
              revalidate={revalidate}
              canEdit={canEdit}
              data={data?.general}
              onSubmit={onGeneralSubmit}
            />
          </Skeleton>
        </Section>
        <Section>
          <Skeleton>
            <PersonContactForm
              canEdit={canEdit}
              data={data?.contact}
              onSubmit={onContactSubmit}
              revalidate={revalidate}
              hasEmergencyAccess={false}
              addressFetcher={googlePlaceFetcher}
              getAddressPartial={getAddressPartialFetcher}
              onMapsPress={onMapsPressed}
              isButtonDisabled={isButtonDisabled}
            />
          </Skeleton>
        </Section>
      </SkeletonProvider>
    </ScrollView>
  );
};
