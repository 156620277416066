import {
  Admission,
  Alert,
  DeviceAssociation as Association,
  Patient,
  Person,
} from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import compact from 'lodash.compact';

export enum PatientEndpoint {
  Base = '',
  Contact = 'contact',
  General = 'general',
  NextOfKin = 'next-of-kin',
  Coverage = 'coverage',
  GeneralPractitioner = 'general-practitioner',
  ICD10 = 'icd-10',
  Admit = 'admission',
  DeviceAssociation = 'device-association',
  Discharge = 'discharge',
  AlertComment = 'alert/comment',
}

export class PatientApi {
  private readonly client: BaseApi;
  private readonly listRoot = 'api/aggregate/patient';
  private readonly patientRoot = 'api/aggregate/patient/';
  constructor() {
    this.client = new BaseApi();
  }

  private createPath(patientId: string, endpoint: string, resourceId?: string) {
    const basePath = `${this.patientRoot}${patientId}`;

    return compact([basePath, endpoint, resourceId]).join('/');
  }

  /**
   * Get an SWR Key associated with the `getPatient` function.
   */
  public getPatientSWRKey = (patientId: string, ...endpoint: PatientEndpoint[]): string =>
    this.createPath(patientId, endpoint.join('_'));

  public async getPatient(patientId: string): Promise<Patient.GetPatientResponse> {
    const path = this.createPath(patientId, PatientEndpoint.Base);

    const result = await this.client.get<Patient.GetPatientResponse>(path);

    return result.data;
  }

  public async updateContactPatient(
    patientId: string,
    body: Person.Contact
  ): Promise<Patient.UpdateContactPatient> {
    const path = this.createPath(patientId, PatientEndpoint.Contact);

    const result = await this.client.put<Patient.UpdateContactPatient>(path, body);
    return result.data;
  }

  public async updateGeneralPatient(
    patientId: string,
    body: Partial<Person.General>
  ): Promise<Patient.UpdateGeneralPatient> {
    const path = this.createPath(patientId, PatientEndpoint.General);

    const result = await this.client.put<Patient.UpdateGeneralPatient>(path, body);

    return result.data;
  }

  public async getNextOfKin(patientId: string): Promise<Patient.NextOfKinResponse> {
    const path = this.createPath(patientId, PatientEndpoint.NextOfKin);

    const result = await this.client.get<Patient.NextOfKinResponse>(path);

    return result.data;
  }

  public async getCoverage(patientId: string): Promise<Patient.PatientCoverageResponse> {
    const path = this.createPath(patientId, PatientEndpoint.Coverage);

    const result = await this.client.get<Patient.PatientCoverageResponse>(path);

    return result.data;
  }

  public async updateGeneralPractitioners(
    patientId: string,
    body: Patient.GeneralPractitionerUpdateRequest
  ): Promise<Patient.GeneralPractitioners> {
    const path = this.createPath(patientId, PatientEndpoint.GeneralPractitioner);

    const result = await this.client.put<Patient.GeneralPractitioners>(path, body);
    return result.data;
  }

  public async updateICD10Codes(
    patientId: string,
    body: Patient.AdmissionICD10CodeUpdateRequest
  ): Promise<Patient.AdmissionICD10CodeUpdateResponse> {
    const path = this.createPath(patientId, PatientEndpoint.ICD10);

    const result = await this.client.put<Patient.AdmissionICD10CodeUpdateResponse>(path, body);
    return result.data;
  }

  public async createAdmission(
    patientId: string,
    body: Admission.CreateRequest
  ): Promise<Admission.CreateResponse> {
    const path = this.createPath(patientId, PatientEndpoint.Admit);

    const result = await this.client.post<Admission.CreateResponse>(path, body);

    return result.data;
  }

  public async createDeviceAssociation(
    patientId: string,
    body: Association.PatientDeviceAssociationCreateRequest
  ): Promise<Association.PatientDeviceAssociationCreateResponse> {
    const path = this.createPath(patientId, PatientEndpoint.DeviceAssociation);

    const result = await this.client.post<Association.PatientDeviceAssociationCreateResponse>(
      path,
      body
    );

    return result.data;
  }

  public async getAdmissions(
    patientId: string,
    params?: Admission.GetParams
  ): Promise<Admission.GetAllResponseWithMedia> {
    const path = this.createPath(patientId, PatientEndpoint.Admit);

    const result = await this.client.get<Admission.GetAllResponseWithMedia>(path, {
      params,
    });

    return result.data;
  }

  public async updateAdmission(
    patientId: string,
    admissionId: string,
    body: Admission.UpdateRequest
  ): Promise<Admission.UpdateResponse> {
    const path = this.createPath(patientId, PatientEndpoint.Admit, admissionId);

    const result = await this.client.put<Admission.UpdateResponse>(path, body);

    return result.data;
  }

  public async getPatients(params: Patient.GetAllParams): Promise<Patient.GetAllResponse> {
    const result = await this.client.get<Patient.GetAllResponse>(this.listRoot, { params });

    return result.data;
  }

  public async dischargePatient(patientId: string): Promise<void> {
    const path = this.createPath(patientId, PatientEndpoint.Discharge);
    await this.client.put(path);
  }

  public async addAlertComment(
    patientId: string,
    alert: Alert.EWSAlertCommentCreateRequest
  ): Promise<void> {
    const path = this.createPath(patientId, PatientEndpoint.AlertComment);
    await this.client.post(path, alert);
  }

  public async updateCoverage(patientId: string, request: Patient.MedicalAidUpdateRequest) {
    const path = this.createPath(patientId, PatientEndpoint.Coverage);
    await this.client.post(path, request);
  }
}
