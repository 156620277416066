import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { StyleSheet } from 'react-native';
import { FullWindowOverlay as FullWindowOverlayBase } from 'react-native-screens';

export interface PositionProps {
  top: number | 'unset';
  bottom: number | 'unset';
  left: number | 'unset';
  right: number | 'unset';
}

interface FullWindowOverlayProps {
  backgroundColor?: ColorName;
  maxHeight?: number;
  maxWidth?: number;
  position?: PositionProps;
}

export const FullWindowOverlay = styled(FullWindowOverlayBase)<FullWindowOverlayProps>(
  ({ theme, backgroundColor, maxHeight, maxWidth, position }) => {
    const positionProps = position
      ? { ...StyleSheet.absoluteFillObject, ...position }
      : StyleSheet.absoluteFillObject;

    return {
      ...positionProps,
      maxHeight,
      maxWidth,
      backgroundColor: backgroundColor && theme.color[backgroundColor],
    };
  }
);
