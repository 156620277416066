// eslint-disable-next-line no-restricted-imports
import '@babel/runtime/regenerator';
import { sentryConfig } from 'config/sentry';
import { Root } from 'layout/Root';
import React from 'react';
import 'react-native-gesture-handler';
import * as Sentry from 'sentry-expo';

Sentry.init(sentryConfig);

const App: React.FC = () => {
  return <Root />;
};

export default App;
