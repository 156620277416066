import { useTheme as useEmotionTheme, Color, Theme, ColorName } from '@emotion/react';
import { DarkTheme, DefaultTheme, Theme as NavigationTheme } from '@react-navigation/native';
import { color } from 'd3-color';

const COLOR_COMMON = {
  // basic
  white: '#FFFFFF',
  black: '#121212',
  transparent: 'transparent',

  // accent
  'accent-blue': '#8AB4F8',
  'accent-blue-20': '#E8F0FE',
  'accent-blue-10': '#F3F8FE',
  'accent-purple': '#9F69D2',
  'accent-purple-20': '#ECE1F6',
  'accent-purple-10': '#F5F0FB',
  'accent-green': '#93C29A',
  'accent-green-20': '#E9F3EB',
  'accent-green-10': '#F4F9F5',
  'accent-pink': '#E16C9D',
  'accent-pink-20': '#F9E1EB',
  'accent-pink-10': '#FCF0F5',
  'accent-orange': '#FFA336',
  'accent-orange-20': '#FFECD7',
  'accent-orange-10': '#FFF6EB',
  'accent-yellow': '#FFD336',

  // measures
  'measure-heart-rate': '#93C29A',
  'measure-respiratory-rate': '#FFD336',
  'measure-oxygen-saturation': '#9F69D2',
  'measure-core-temperature': '#E16C9D',
  'measure-blood-pressure': '#8AB4F8',
  'measure-blood-pressure-map': '#8AB4F8',
  'measure-blood-pressure-systolic': '#E16C9D',
  'measure-blood-pressure-diastolic': '#93C29A',
  'measure-glucose': '#FFA336',
  'measure-ecg': '#93C29A',
  'measure-posture': '#A0A0A0',
  'measure-weight': '#EF8AF8',
};

const COLOR_HIGH_CONTRAST_OVERRIDE = {
  // basic
  black: '#000000',

  // base
  base: '#000000',
  'base-grey': '#1B1B1B',
  divider: '#474747',

  // text
  'text-default': '#FFFFFF',
  'text-on-color': '#000000',

  // accent
  'accent-blue': '#8AFFFF',
  'accent-purple': '#9523FF',
  'accent-green': '#00E899',
  'accent-pink': '#FC2FDF',
  'accent-orange': '#FFBB00',
  'accent-yellow': '#FBFF35',

  // measures
  'measure-heart-rate': '#00E899',
  'measure-respiratory-rate': '#FBFF35',
  'measure-oxygen-saturation': '#9523FF',
  'measure-core-temperature': '#FC2FDF',
  'measure-blood-pressure': '#8AFFFF',
  'measure-glucose': '#FFBB00',
  'measure-ecg': '#00E899',
  'measure-posture': '#A0A0A0',
  'measure-weight': '#EF8ADF',

  // status
  'status-critical': '#FF3002',
  'status-warning': '#FFBB00',
  'status-success': '#00E899',

  // risk
  'risk-high': '#FF3002',
  'risk-medium': '#FFBB00',
  'risk-low': '#00E899',
};

export const COLOR_DARK: Color = {
  ...COLOR_COMMON,

  // base
  base: '#121212',
  'base-grey': '#1E2127',
  divider: '#474E59',

  // text
  'text-default': '#FFFFFF',
  'text-subdued': '#E8EAED',
  'text-disabled': '#A0A0A0',
  'text-on-color': '#000000',

  // brand
  primary: '#FF595A',
  'primary-20': '#412020',
  'primary-10': '#2A1919',
  'primary-hover': '#CC4748',

  // status
  'status-critical': '#FF3132',
  'status-critical-20': '#411818',
  'status-critical-10': '#2A1515',

  'status-warning': '#FFA336',

  'status-success': '#93C29A',
  'status-success-20': '#E9F3EB',
  'status-success-10': '#F4F9F5',

  // risk
  'risk-high': '#FF3132',
  'risk-medium': '#FFA336',
  'risk-low': '#93C29A',
};

export const COLOR_LIGHT: Color = {
  ...COLOR_COMMON,

  // base
  base: '#F7F6FB',
  'base-grey': '#FFFFFF',
  divider: '#E3E5EE',

  // text
  'text-default': '#121212',
  'text-subdued': '#6D7175',
  'text-disabled': '#A0A0A0',
  'text-on-color': '#FFFFFF',

  // brand
  primary: '#FF595A',
  'primary-20': '#FFDEDE',
  'primary-10': '#FFEEEE',
  'primary-hover': '#CC4748',

  // status
  'status-critical': '#FF3132',
  'status-critical-20': '#FFD6D6',
  'status-critical-10': '#FFEAEA',

  'status-warning': '#FFA336',

  'status-success': '#68A972',
  'status-success-20': '#E1EEE3',
  'status-success-10': '#F0F6F1',

  // risk
  'risk-high': '#FF3132',
  'risk-medium': '#FFA336',
  'risk-low': '#68A972',
};

export const COLOR_DARK_HIGH_CONTRAST: Color = {
  ...COLOR_DARK,
  ...COLOR_HIGH_CONTRAST_OVERRIDE,
};

export const navigatorDarkTheme: NavigationTheme = {
  dark: true,
  colors: {
    ...DarkTheme.colors,
    primary: COLOR_DARK.primary,
    background: COLOR_DARK.base,
    text: COLOR_DARK['text-default'],
    card: COLOR_DARK.base,
    border: COLOR_DARK.divider,
  },
};

export const navigatorLightTheme: NavigationTheme = {
  dark: false,
  colors: {
    ...DefaultTheme.colors,
    primary: COLOR_LIGHT.primary,
    background: COLOR_LIGHT.base,
    text: COLOR_LIGHT['text-default'],
    card: COLOR_LIGHT.base,
    border: COLOR_LIGHT.divider,
  },
};

export const darkTheme: Theme = {
  color: COLOR_DARK,
  name: 'dark',
  navigatorTheme: navigatorDarkTheme,
};

export const lightTheme: Theme = {
  color: COLOR_LIGHT,
  name: 'light',
  navigatorTheme: navigatorLightTheme,
};

export const darkThemeHighContrast: Theme = {
  color: COLOR_DARK_HIGH_CONTRAST,
  name: 'dark',
  navigatorTheme: navigatorDarkTheme,
};

export const colorNames = Object.keys(COLOR_DARK) as ColorName[];

export const useTheme = (): Theme => useEmotionTheme();

/**
 * Will apply the `opacity` to the `baseColor`. If the color can't be parsed will return `baseColor`
 */
export const fadeColor = (baseColor: string, opacity: number): string => {
  const parsedColor = color(baseColor);

  if (!parsedColor) {
    return baseColor;
  }

  parsedColor.opacity = opacity;

  return parsedColor.formatRgb();
};

/**
 * Will lighten the `baseColor` by the `amount`. If the color can't be parsed will return
 * `baseColor`
 */
export const lightenColor = (baseColor: string, amount: number): string => {
  const parsedColor = color(baseColor);

  if (!parsedColor) {
    return baseColor;
  }

  return parsedColor.brighter(amount).formatRgb();
};
