import { FONT_FAMILY, FONT_SIZE } from 'design-system/theme';
import React from 'react';

const baseStyles: React.CSSProperties = {
  border: 'none',
  backgroundColor: 'transparent',
  outline: 'none',
  fontFamily: FONT_FAMILY.regular,
  fontSize: FONT_SIZE.body,
};

export const getSearchStyles = (textColor: string): React.CSSProperties => ({
  ...baseStyles,
  color: textColor,
});
