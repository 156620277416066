// importing from here temporarily, when we migrate from the old components we should move this here
import { PrimitiveValueType, SelectOption } from 'components/types';
import { useTheme } from 'design-system/theme';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import DropDownPicker, {
  ItemType as DropdownItemType,
  ValueType as DropdownValueType,
} from 'react-native-dropdown-picker';
import { useAsyncDebounce } from 'react-table';

import { Wrapper } from '../internal';
import { Fetcher, mapOptionsToItems } from './fetcher';
import {
  ArrowDownIconComponent,
  ArrowUpIconComponent,
  getListEmptyComponent,
  getStatusItems,
  Step,
  TickIconComponent,
} from './placeholders';
import { baseStyle, useDropdownTheme } from './styles';
import { toValueType } from './value-type';

type ValueType<
  TValue extends PrimitiveValueType,
  TData = any,
  TReturnFull extends boolean = false
> = TReturnFull extends true ? SelectOption<TData, TValue> : TValue;

export interface ComboBoxSingleProps<
  TOptionValue extends PrimitiveValueType = PrimitiveValueType,
  TReturnFull extends boolean = false,
  TData = TReturnFull extends true ? any : undefined,
  TValue = ValueType<TOptionValue, TData, TReturnFull>
> {
  label?: string;
  placeholder?: string;
  isDisabled?: boolean;
  errors?: string;
  initialSelection?: SelectOption<TData, TOptionValue>[];
  value?: TValue;
  /**
   * If the full `SelectOption` object should be returned, will otherwise return the `ValueType`
   */
  returnFullOption?: TReturnFull;
  searchPlaceholder?: string;
  /**
   * Note that if you're using searchable along with a static `fetcher` the results will not be
   * re-evaluated
   */
  searchable?: boolean;
  onChange: (value: TValue) => void;
  fetcher: Fetcher<TOptionValue, TData>;
  onFetchDebounce?: number;
}

export const ComboBoxSingle = <
  TValue extends PrimitiveValueType = PrimitiveValueType,
  TReturnFull extends boolean = false,
  TData = any
>({
  label,
  errors,
  value,
  isDisabled,
  placeholder,
  returnFullOption,
  searchPlaceholder,
  onChange,
  fetcher,
  searchable,
  initialSelection = [],
  onFetchDebounce = 1000,
}: ComboBoxSingleProps<TValue, TReturnFull, TData>): ReactElement => {
  const isFunctionFetcher = typeof fetcher === 'function';
  const theme = useTheme();
  const { themeName, themeStyle } = useDropdownTheme();

  const primitive = toValueType<PrimitiveValueType>(value as TValue);

  const [innerValue, setInnerValue] = useState<DropdownValueType | null>(
    // convert undefined to null
    primitive === undefined ? null : primitive
  );

  const [options, setOptions] = useState<SelectOption[]>(
    isFunctionFetcher ? initialSelection : fetcher
  );
  const [items, setItems] = useState<DropdownItemType<TValue>[]>(
    mapOptionsToItems(initialSelection)
  );

  const [open, setOpen] = useState(false);
  const [step, setStep] = useState<Step>('data');
  const [query, setQuery] = useState<string>('');

  const search = useCallback(
    async (text: string) => {
      if (!isFunctionFetcher) {
        const mappedItems = mapOptionsToItems(fetcher);
        setItems(mappedItems);
        return;
      }

      const trimmedQuery = text.trim() || undefined;

      const searchResult = await fetcher(trimmedQuery);
      setOptions(searchResult);
      const mappedItems = mapOptionsToItems(searchResult);
      setItems(mappedItems);
    },
    [fetcher, isFunctionFetcher]
  );

  const debounceTime = isFunctionFetcher ? onFetchDebounce : 0;
  const debouncedSearch = useAsyncDebounce(search, debounceTime);

  useEffect(() => {
    setStep('loading');
    debouncedSearch(query)
      .then(() => setStep('data'))
      .catch(() => setStep('error'));
  }, [debouncedSearch, query]);

  const handleChange = useCallback(
    (changeValue: DropdownItemType<DropdownValueType> | null) => {
      if (changeValue === null) {
        return;
      }

      if (returnFullOption === true) {
        const fullValue = options.find((item) => changeValue.value === item.value);
        onChange(fullValue as ValueType<TValue, TData, TReturnFull>);
      } else {
        onChange(
          (changeValue === null ? undefined : changeValue.value) as ValueType<
            TValue,
            TData,
            TReturnFull
          >
        );
      }
    },
    [options, onChange, returnFullOption]
  );

  const loading = step === 'loading';

  const itemsWithStatus = [...getStatusItems(step), ...items];

  const ListEmptyComponent = getListEmptyComponent(step);

  return (
    <Wrapper label={label} error={errors} open={open} focused={open}>
      <DropDownPicker
        disabled={isDisabled}
        multiple={false}
        loading={loading}
        searchable={searchable}
        searchPlaceholder={searchPlaceholder}
        placeholder={placeholder}
        // state management
        value={innerValue as DropdownValueType}
        items={itemsWithStatus as DropdownItemType<DropdownValueType>[]}
        setOpen={setOpen}
        open={open}
        setValue={setInnerValue}
        onChangeSearchText={setQuery}
        onSelectItem={handleChange}
        // we can modify the function used here in order to add fuzzy searching at a later stage
        setItems={setItems}
        // styles and usage
        theme={themeName}
        closeOnBackPressed={true}
        showBadgeDot={true}
        listMode="SCROLLVIEW"
        mode="BADGE"
        dropDownContainerStyle={[
          baseStyle.dropDownContainerStyle,
          themeStyle.dropDownContainerStyle,
        ]}
        style={[baseStyle.style, themeStyle.style]}
        textStyle={[baseStyle.textStyle, themeStyle.textStyle]}
        badgeStyle={[baseStyle.badgeStyle, themeStyle.badgeStyle]}
        badgeDotStyle={baseStyle.badgeDotStyle}
        badgeTextStyle={baseStyle.badgeTextStyle}
        disabledItemLabelStyle={themeStyle.disabledItemLabelStyle}
        listItemContainerStyle={[
          baseStyle.listItemContainerStyle,
          themeStyle.listItemContainerStyle,
        ]}
        listItemLabelStyle={[baseStyle.listItemLabelStyle, themeStyle.listItemLabelStyle]}
        searchContainerStyle={[baseStyle.searchContainerStyle, themeStyle.searchContainerStyle]}
        searchTextInputStyle={[baseStyle.searchTextInputStyle, themeStyle.searchTextInputStyle]}
        badgeDotColors={[theme.color['status-success']]}
        badgeColors={[theme.color['base-grey']]}
        searchPlaceholderTextColor={theme.color['text-subdued']}
        ArrowDownIconComponent={ArrowDownIconComponent}
        ArrowUpIconComponent={ArrowUpIconComponent}
        TickIconComponent={TickIconComponent}
        ListEmptyComponent={ListEmptyComponent}
      />
    </Wrapper>
  );
};
