import { withVisibility } from 'components/base';
import React from 'react';
import { G as SvgG, Line as SvgLine } from 'react-native-svg';

import { usePlotDataContext } from './PlotDataProvider';
import { ExternalComponentProps } from './types';

interface CrosshairProps {
  startX?: boolean;
  endX?: boolean;
  startY?: boolean;
  endY?: boolean;
  opacity?: number;
  stroke?: string;
  strokeWidth?: number;
}

const G = withVisibility(SvgG);
const Line = withVisibility(SvgLine);

type ExternalProps = ExternalComponentProps & CrosshairProps;

export const Crosshair: React.FC<ExternalProps> = (props) => {
  const {
    baseHeight,
    startTouch,
    endTouch,
    baseWidth,
    startX = false,
    startY = false,
    endX = false,
    endY = false,
    stroke = 'lightgrey',
    opacity = 1,
    strokeWidth = 1,
    margin,
  } = usePlotDataContext(props);

  const commonProps = {
    stroke,
    strokeWidth,
    opacity,
  };

  return (
    <G>
      <G isVisible={!!startTouch}>
        <Line
          isVisible={startX}
          x1={startTouch?.x}
          x2={startTouch?.x}
          y1={margin.top}
          y2={baseHeight - margin.bottom}
          {...commonProps}
        />
        <Line
          isVisible={startY}
          y1={startTouch?.y}
          y2={startTouch?.y}
          x1={margin.left}
          x2={baseWidth - margin.right}
          {...commonProps}
        />
      </G>
      <G isVisible={!!endTouch}>
        <Line
          isVisible={endX}
          x1={endTouch?.x}
          x2={endTouch?.x}
          y1={margin.top}
          y2={baseHeight - margin.bottom}
          {...commonProps}
        />
        <Line
          isVisible={endY}
          y1={endTouch?.y}
          y2={endTouch?.y}
          x1={margin.left}
          x2={baseWidth - margin.right}
          {...commonProps}
        />
      </G>
    </G>
  );
};
