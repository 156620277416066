import { PromiseOfOptional } from '@quromedical/auth';
import { AxiosRequestConfig } from 'axios';
import { logger } from 'helpers';
import set from 'lodash.set';

import { AuthApi } from './AuthApi';

const getAccessToken = async (): PromiseOfOptional<string> => {
  // This is meant fot auth zero session
  const authSession = await AuthApi.currentSession();
  return authSession?.getIdToken();
};

export const authInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  try {
    const token = await getAccessToken();
    set(config, 'headers.Authorization', token);
    return config;
  } catch (err) {
    logger.error(new Error('Error setting Auth Token'));
    throw err;
  }
};
