import { Bundle, getResourcesFromBundle } from '@quromedical/fhir-common';
import { Row } from 'components/base';
import { ColumnType } from 'components/table';
import { getDisplayDate } from 'core/display';
import { Text } from 'design-system';
import { compact, first } from 'lodash';
import React from 'react';
import { strings } from 'strings';

interface ServiceRequestTable {
  orderNumber: string;
  requestStatus: string;
  requestDate: string;
  tests: string[];
  note: string;
  id: string;
}

export const serviceRequestColumns: ColumnType<ServiceRequestTable>[] = [
  {
    header: strings.PathologyOrderNumberSubheading,
    accessor: 'orderNumber',
  } as ColumnType<ServiceRequestTable, 'orderNumber'>,
  {
    header: strings.PathologyRequestDateSubheading,
    accessor: 'requestDate',
  } as ColumnType<ServiceRequestTable, 'requestDate'>,
  {
    header: strings.PathologyRequestStatusSubheading,
    accessor: 'requestStatus',
  } as ColumnType<ServiceRequestTable, 'requestStatus'>,
  {
    header: strings.PathologyTestsSubheading,
    Cell: ({ value = [] }) =>
      value.map((test, index) => (
        <Row key={index}>
          <Text>{test || ''}</Text>
        </Row>
      )),
    accessor: 'tests',
  } as ColumnType<ServiceRequestTable, 'tests'>,
  {
    header: strings.PathologyNotesSubheading,
    accessor: 'note',
  } as ColumnType<ServiceRequestTable, 'note'>,
];

export const getDataFromResponse = (
  res: Bundle<fhir4.ServiceRequest>
): Partial<ServiceRequestTable>[] => {
  const data = getResourcesFromBundle(res);
  return data.map((req) => ({
    id: req.id,
    orderNumber: first(req.identifier)?.value,
    requestStatus: req.status,
    requestDate: getDisplayDate(req.occurrenceDateTime),
    tests: compact(req.orderDetail?.map((test) => test.text)),
    note: first(req.note)?.text,
  }));
};
