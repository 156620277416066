import { Col, Row } from 'components/base';
import { Card } from 'design-system/base';
import React from 'react';

import { BaseWrapper } from './Layout';

export const Wrapper: React.FC = ({ children }) => (
  <BaseWrapper>
    <Card>
      <Row>
        <Col flex={1}>{children}</Col>
      </Row>
    </Card>
  </BaseWrapper>
);
