import { Bundle, getIdQueryString } from '@quromedical/fhir-common';

import { FhirBaseApi } from './FhirBaseApi';

export interface Params {
  count?: number;
  pageToken?: string;
  name?: string;
  address?: string;
  type?: string;
}

export class OrganizationApi extends FhirBaseApi<fhir4.Organization> {
  constructor() {
    super('api/fhir/organization');
  }

  getOrganizations = async (params: Params): Promise<Bundle<fhir4.Organization>> =>
    this.getAll(params);

  getOrganization = async (id: string): Promise<fhir4.Organization> => this.get(id);

  updateOrganization = async (res: fhir4.Organization): Promise<fhir4.Organization> =>
    this.put(res);

  createOrganization = async (res: fhir4.Organization): Promise<fhir4.Organization> =>
    this.post(res);

  getOrganizationsByIds = async (
    ids?: string[]
  ): Promise<Bundle<fhir4.Organization> | undefined> => {
    if (!ids?.length) {
      return undefined;
    }

    const id = getIdQueryString(ids);

    return this.getAll({
      id,
    });
  };
}
