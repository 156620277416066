import styled from '@emotion/native';
import { Col as C, Row as R } from 'components/base';
import { Text } from 'design-system';
import { BORDER_WIDTH, PADDING } from 'design-system/theme';

export const Table = styled(C)({});

export const Header = styled(C)({});

export const Body = styled(C)({});

export const Row = styled(R)(({ flex }) => ({
  flex,
  alignSelf: 'stretch',
}));

export const HeaderRow = styled(R)(({ flex, theme }) => {
  const backgroundColor = theme.color.base;

  return {
    flex,
    alignSelf: 'stretch',
    backgroundColor,
    paddingVertical: PADDING.xs,
    paddingHorizontal: PADDING.s,
  };
});

export const FullPaddedRowWithBackgroundRow = styled(R)(({ flex, theme }) => ({
  flex,
  alignSelf: 'stretch',
  paddingHorizontal: PADDING.s,
  paddingVertical: PADDING.xs,
  borderColor: theme.color.transparent,
  borderBottomColor: theme.color.base,
  borderWidth: BORDER_WIDTH[2],
  backgroundColor: theme.color['base-grey'],
}));

export const VerticalPaddedRow = styled(R)(({ flex }) => ({
  flex,
  alignSelf: 'stretch',
  paddingVertical: PADDING.xs,
}));

export const CellWrapper = styled(C)(({ flex = 1 }) => ({
  flex,
  alignSelf: 'stretch',
  justifyContent: 'center',
}));

export const HeaderCell: React.FC = ({ children, ...props }) => (
  <CellWrapper {...props}>
    <Text variant="subheading">{children || ''}</Text>
  </CellWrapper>
);

export const Cell: React.FC = ({ children, ...props }) => (
  <CellWrapper {...props}>
    <Text>{children || ''}</Text>
  </CellWrapper>
);
