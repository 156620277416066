import { MedicalAid } from '@quromedical/models';
import { getDisplayName } from 'core/person';
import { Field, FormCard, RowConfig } from 'design-system';
import { compact } from 'lodash';
import React from 'react';
import { strings } from 'strings';

interface PatientSelectionCardProps {
  inactiveMembers: MedicalAid.MedicalAidValidationResponse;
}

const createInactiveMembersRows = (
  inactiveMembers: MedicalAid.MedicalAidValidationResponse
): RowConfig[] => {
  const names = inactiveMembers.map((i) => getDisplayName(i.general));

  const reasons = inactiveMembers.map((i) => i.medicalAidMembership?.status);

  const nameFields: Field = {
    type: 'list',
    label: strings.CardLabelInactiveMembers,
    display: compact(names),
  };

  const reasonFields: Field = {
    type: 'list',
    label: strings.CardLabelInactiveMedicalAidReason,
    display: compact(reasons),
  };

  const inactiveMemberFields: RowConfig[] = [
    {
      icon: 'warning',
      fields: [nameFields, reasonFields],
    },
  ];

  return inactiveMemberFields;
};

export const InactiveMembersCard: React.FC<PatientSelectionCardProps> = ({ inactiveMembers }) => {
  const rows = createInactiveMembersRows(inactiveMembers);

  return (
    <FormCard
      cardColor="status-warning"
      showIcons={true}
      title={strings.CardTitleInactiveMedicalAidMembers}
      rows={rows}
    />
  );
};
