import styled from '@emotion/native';
import { Col, Row } from 'components/base';
import { IconName, Text, Button, ButtonTypeVariant } from 'design-system/base';
import { Logo } from 'design-system/brand';
import { BASE_SIZE, PADDING } from 'design-system/theme';
import React from 'react';

interface HeaderProps {
  onPress: () => void;
  buttonIcon: IconName;
  title: string;
  buttonVariant?: ButtonTypeVariant;
}

export const HeaderRow = styled(Row)({
  paddingHorizontal: PADDING.m,
  paddingVertical: PADDING.s,
});

export const Header: React.FC<HeaderProps> = ({ title, onPress, buttonIcon, buttonVariant }) => (
  <HeaderRow alignItems="center" justifyContent="space-between">
    <Col flex={1}>
      <Logo size={BASE_SIZE[48]} />
    </Col>
    <Col flex={1} alignItems="center">
      <Text>{title}</Text>
    </Col>
    <Col flex={1} alignItems="flex-end">
      <Row>
        <Button
          onPress={onPress}
          icon={buttonIcon}
          shape="round"
          iconPosition="right"
          size="large"
          variant={buttonVariant}
        />
      </Row>
    </Col>
  </HeaderRow>
);
