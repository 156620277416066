import React from 'react';
import { G, Line } from 'react-native-svg';

import { usePlotDataContext } from './PlotDataProvider';
import { ExternalComponentProps } from './types';

interface XGridProps {
  ticks?: number | number[];
  stroke?: string;
  opacity?: number;
  strokeDasharray?: number | number[];
  strokeWidth?: number;
}

type ExternalProps = ExternalComponentProps & XGridProps;

export const XGrid: React.FC<ExternalProps> = (props) => {
  const {
    ticks = 10,
    xScale,
    baseHeight,
    margin,
    stroke = 'lightgrey',
    opacity = 0.5,
    strokeDasharray,
    strokeWidth,
  } = usePlotDataContext(props);

  const tickMarks = typeof ticks === 'number' ? xScale.ticks(ticks) : ticks;

  return (
    <G>
      {tickMarks.map((t) => (
        <G key={t.toString()}>
          <Line
            stroke={stroke}
            x1={xScale(t)}
            y1={margin.top}
            x2={xScale(t)}
            y2={baseHeight - margin.bottom}
            opacity={opacity}
            strokeDasharray={strokeDasharray}
            strokeWidth={strokeWidth}
          />
        </G>
      ))}
    </G>
  );
};
