import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Text, TextVariant } from 'design-system/base';
import { BORDER_RADIUS, PADDING } from 'design-system/theme';
import React from 'react';

import { Wrapper } from '../internal';

export interface TextDisplayProps {
  label?: string;
  value?: string;
  placeholder?: string;
  errors?: string;
  variant?: TextVariant;
  text?: string;
  background?: boolean;
  backgroundColor?: ColorName;
  border?: boolean;
}

interface TextWrapperProps {
  backgroundColor?: ColorName;
}

const TextWrapper = styled.View<TextWrapperProps>(({ theme, backgroundColor = 'transparent' }) => ({
  flex: 1,
  paddingHorizontal: PADDING.xs,
  // this is to help the text match the height of the input box but still allow text variants
  height: 37,
  justifyContent: 'center',
  backgroundColor: theme.color[backgroundColor],
  borderRadius: BORDER_RADIUS[4],
}));

export const TextDisplay: React.FC<TextDisplayProps> = ({
  label,
  errors,
  variant,
  text = '',
  background = false,
  backgroundColor,
  border = false,
}) => (
  <Wrapper background={background} label={label} error={errors} border={border} focused={false}>
    <TextWrapper backgroundColor={backgroundColor}>
      <Text numberOfLines={1} variant={variant}>
        {text}
      </Text>
    </TextWrapper>
  </Wrapper>
);
