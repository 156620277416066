import { Skeleton, SkeletonProvider } from 'design-system/base';
import React from 'react';

import { VideoTile } from '../video-tile';
import { CallWrapper } from './CallWrapper';

export interface CallLoaderProps {
  title: string;
  leaveMeeting: () => void;
}

export const CallLoader: React.FC<CallLoaderProps> = ({ title, leaveMeeting }) => (
  <CallWrapper title={title} onButtonPress={leaveMeeting} buttonIcon="call-end">
    <SkeletonProvider loading>
      <Skeleton>
        <VideoTile isMicEnabled isMinimized isVideoEnabled />
      </Skeleton>
    </SkeletonProvider>
  </CallWrapper>
);
