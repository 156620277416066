import { CrudForm, SubmissionHandler } from 'components/form';
import { Card, ToggleCard } from 'design-system';
import { useRevalidation } from 'hooks/useRevalidation';
import { PathologyApi } from 'integration/aggregate/';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';

import { createFields } from './fields';
import { schema } from './schema';
import { FormData, TestType } from './types';

const api = new PathologyApi();

interface Props {
  patientId: string;
}

export const ServiceRequestForm: React.FC<Props> = ({ patientId }) => {
  const revalidate = useRevalidation('ServiceRequest');
  const [testType, setTestType] = useState<TestType>('normal');
  const [toggleKey, setToggleKey] = useState(Date.now());

  const onSubmit = useCallback<SubmissionHandler<FormData>>(
    async (formData: FormData) => {
      try {
        await api.createAmpathRequest(patientId, formData);
        setToggleKey(Date.now());
        revalidate();
        return {};
      } catch (error) {
        return { error };
      }
    },
    [revalidate, patientId]
  );

  const fields = createFields(testType);

  const onChange = useCallback((formData: FormData) => {
    setTestType(formData.testType);
  }, []);

  return (
    <Card paddingHorizontal={0} paddingVertical={0}>
      <ToggleCard
        title={strings.PathologyCreateCardTitle}
        icon="biotech"
        blockColor="accent-green"
        textCollapse={strings.PathologyButtonTextHide}
        textExpand={strings.PathologyButtonTextCreateRequest}
        contentWrapperProps={{ paddingHorizontal: 0, paddingVertical: 0 }}
        key={toggleKey}
      >
        <CrudForm
          key={testType}
          fields={fields}
          initialValues={{ testType }}
          validationSchema={schema}
          buttonText="Submit"
          onSubmit={onSubmit}
          onChange={onChange}
          onChangeDebounceTime={0}
        />
      </ToggleCard>
    </Card>
  );
};
