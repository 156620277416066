import { ColorName } from '@emotion/react';
import {
  VitalParameterScore,
  VitalParameterScale,
  VitalParameterType,
  VitalParameterBounds,
} from '@quromedical/news';
import { GradientPoint } from 'design-system';
import { logger } from 'helpers';
import round from 'lodash.round';

/**
 * Creates a wrapper around an early warning scale with defaulting and error handling/logging needed
 */
export type SafeScale = (value?: number) => VitalParameterScore | undefined;

/**
 * Creates a wrapper around an early warning scale with defaulting and error handling/logging needed
 *
 * @param scale the early warning scale to use
 * @param keepValueDecimals decimals to keep - most scales require an int value, but some require a
 * a specific number of decimal places in the input data
 * @returns will return the scale result, if it throws, we will assume a high warning score
 */
export const createSafeScale = (scale: VitalParameterScale, keepValueDecimals = 0): SafeScale => {
  const safeScale: SafeScale = (value) => {
    if (typeof value === 'undefined') {
      // if we don't have a value then we just return 0
      return undefined;
    }

    try {
      const rounded = round(value, keepValueDecimals);
      const result = scale(rounded);

      return result.value;
    } catch (err) {
      logger.error(err, {
        value,
        message: 'Failed to parse value using the provided base scale',
      });

      // if we can't parse the value then assume a high warning score
      return 3;
    }
  };

  return safeScale;
};

type ScoreColor = Partial<Record<VitalParameterScore, ColorName>>;
type ScaleColor = Partial<Record<VitalParameterType, ScoreColor>>;

const scaleColors: ScaleColor = {
  high: {
    2: 'status-warning',
    3: 'status-critical',
  },
  low: {
    2: 'status-warning',
    3: 'status-critical',
  },
};

export const createGradient = (bounds: VitalParameterBounds): GradientPoint[] => {
  const points = bounds.map<GradientPoint[]>((bound) => {
    const { lower, score, upper } = bound;
    const color = scaleColors[score.type]?.[score.value];

    // for high values we want the start of the bound (which is lower)
    if (score.type === 'high') {
      return [
        {
          color,
          value: lower,
        },
      ];
    }

    // for low values we want the start of the bound (which is upper)
    if (score.type === 'low') {
      return [
        {
          color,
          value: upper,
        },
      ];
    }

    // for base values we want the start and end of the base
    return [
      {
        color,
        value: upper,
      },
      {
        color,
        value: lower,
      },
    ];
  });

  return points.flat();
};
