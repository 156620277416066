import { GroupSecurity } from '@quromedical/fhir-common';
import { Vitals, MeasurementName, Observation } from '@quromedical/models';
import { Util } from '@quromedical/utils';
import { Datum, Domain } from 'components/graph';
import { VitalObservationCreateForm } from 'core/observation';
import {
  codes,
  colors,
  domains,
  gradientStops,
  retainDefaultYDomain,
  secondaryGraphMeasure,
  splinePropGetters,
  titles,
  units,
  valueFormatters,
  mapVitalsToGraphData,
  DatumWithDevice,
  SecondaryData,
  baseSplineProps,
  icons,
} from 'core/vitals';
import { mean } from 'd3-array';
import {
  FilterItem,
  FooterLegendProps,
  getExpandedDomain,
  SummaryProps,
  Visible,
  VitalCard,
  VitalCardSliderProps,
  VitalCardSplineProps,
} from 'design-system';
import { useUserSession } from 'hooks';
import { useBoolean } from 'hooks/useBoolean';
import { useIsBiggerThan } from 'hooks/useResponsive';
import { ObservationApi } from 'integration/aggregate';
import uniqBy from 'lodash.uniqby';
import React, { useCallback, useMemo, useState } from 'react';
import { strings } from 'strings';

import { useCachedMeasureData } from './hooks';

const observationApi = new ObservationApi();

interface VitalsGridItemProps {
  patientId: string;
  measureName: MeasurementName;
  primaryGraphMeasureName: MeasurementName;
  vitals?: Vitals.VitalsResult;
  latest?: Vitals.LatestResult;
  initialWidth: number;
  timeFrame: Domain;
  isExpanded?: boolean;
  showSecondaryData?: boolean;
  /**
   * Defined as an input since it may depend on data that is not necessarily part of the component
   */
  summary?: SummaryProps[];
  /**
   * Defined as an input since it may depend on data that is not necessarily part of the component
   */
  secondaryData?: SecondaryData[];
  legend?: FooterLegendProps<MeasurementName>[];
  onExpandToggled: (measureName: MeasurementName) => void;
  canViewFilter?: boolean;
  revalidate?: () => void;
}

const getDeviceNames = (
  graphData: DatumWithDevice[],
  secondaryData: SecondaryData[] = []
): string[] => {
  const primaryDeviceNames = graphData.map((datum) => datum.deviceName);
  const secondaryDeviceNames = secondaryData?.flatMap((props) =>
    props.data.map((datum) => datum.deviceName)
  );

  const deviceNames = [...primaryDeviceNames, ...secondaryDeviceNames];
  return uniqBy(deviceNames, (v) => v);
};

export const VitalsGridItem: React.FC<VitalsGridItemProps> = ({
  patientId,
  measureName,
  vitals = [],
  latest,
  initialWidth,
  timeFrame,
  isExpanded = false,
  showSecondaryData = isExpanded,
  summary,
  secondaryData,
  onExpandToggled,
  legend,
  primaryGraphMeasureName,
  canViewFilter = false,
  revalidate,
}) => {
  const [deviceFilter, setDeviceFilter] = useState<string>();

  const [playing, setPlaying] = useState(false);

  const isLarge = useIsBiggerThan('md');
  const onExpandPress = useCallback(() => {
    onExpandToggled(measureName);
  }, [measureName, onExpandToggled]);

  const initialValue = mean(timeFrame) as number;

  const secondaryMeasureName = secondaryGraphMeasure[measureName];
  const baseSpline = secondaryMeasureName && baseSplineProps[secondaryMeasureName];
  const showSpline = !!secondaryMeasureName;

  const [sliderValue, setSliderValue] = useState<number>(initialValue);
  const dialogOpen = useBoolean(false);

  const timeRange = timeFrame[1] - timeFrame[0];

  const largeDuration = baseSpline?.durationLarge || timeRange;
  const smallDuration = baseSpline?.durationSmall || timeRange;

  const duration = isLarge ? largeDuration : smallDuration;
  const offset = (duration * 3) / 5;
  const aspectRatio = isLarge ? 5 : 2;

  const splineData = useCachedMeasureData(
    patientId,
    secondaryMeasureName || measureName,
    sliderValue,
    duration,
    offset,
    !!baseSpline,
    baseSpline?.showActions
  );

  const splinePropGetter = splinePropGetters[secondaryMeasureName as MeasurementName];

  const measureSplineProps = splinePropGetter
    ? splinePropGetter({
        aspectRatio,
        baseData: splineData.data || [],
        endTs: sliderValue + duration,
        showYValues: true,
        baseTimeFrame: timeFrame,
        deviceFilter,
      })
    : {};

  const onNextPress = useCallback(() => {
    setSliderValue(splineData.next);
  }, [splineData.next]);

  const onPreviousPress = useCallback(() => {
    setSliderValue(splineData.prev);
  }, [splineData.prev]);

  const nextIcon = isLarge ? 'forward-5' : 'fast-forward';
  const previousIcon = isLarge ? 'replay-5' : 'fast-rewind';

  const mergedSplineProps: VitalCardSplineProps | undefined = showSpline
    ? {
        ...measureSplineProps,
        title: titles[secondaryMeasureName],
        gridColor: 'text-default',
        color: colors[secondaryMeasureName],
        showXValues: true,
        showYValues: true,
        isLoading: splineData.isLoading,
        next: baseSpline?.showActions
          ? {
              icon: nextIcon,
              onPress: onNextPress,
            }
          : undefined,
        previous: baseSpline?.showActions
          ? {
              icon: previousIcon,
              onPress: onPreviousPress,
            }
          : undefined,
        onPlay: baseSpline?.showActions
          ? () => {
              setPlaying(true);
            }
          : undefined,
        onStop: baseSpline?.showActions
          ? () => {
              setPlaying(false);
            }
          : undefined,
        onTrackerAnimationEnd: onNextPress,
        isPlaying: playing,
        trackerDuration: duration,
        trackerColor: colors[secondaryMeasureName],
        trackerValue: sliderValue,
      }
    : undefined;

  const sliderProps: VitalCardSliderProps | undefined = baseSpline?.showSlider
    ? {
        initialValue,
        onChange: setSliderValue,
      }
    : undefined;

  const handleExpand = onExpandPress;

  const graphData = useMemo<DatumWithDevice[]>(
    () => mapVitalsToGraphData(measureName, vitals),
    [measureName, vitals]
  );

  const domain = useMemo<Domain | undefined>(() => {
    const baseDomain = domains[measureName];

    if (!baseDomain) {
      return undefined;
    }

    if (retainDefaultYDomain[measureName]) {
      return baseDomain;
    }

    return getExpandedDomain(
      baseDomain,
      graphData.map((d) => d.y)
    );
  }, [graphData, measureName]);

  const latestValue = useMemo<Datum | undefined>(() => {
    if (!latest) {
      return undefined;
    }

    return {
      x: latest.ts,
      y: latest.value,
    };
  }, [latest]);

  const datumFilter: Util.ArrayFilter<DatumWithDevice> = useCallback(
    (device) => {
      if (!deviceFilter) {
        return true;
      }

      return device.deviceName === deviceFilter;
    },
    [deviceFilter]
  );

  const filteredGraphData = useMemo<DatumWithDevice[]>(
    () => graphData.filter(datumFilter),
    [datumFilter, graphData]
  );

  const filteredSecondaryData = useMemo<SecondaryData[] | undefined>(
    () =>
      secondaryData?.map((d) => ({
        ...d,
        data: d.data.filter(datumFilter),
      })),
    [datumFilter, secondaryData]
  );

  const filters = useMemo<FilterItem<string>[]>(() => {
    const deviceNames = getDeviceNames(graphData, secondaryData);

    return deviceNames.map<FilterItem<string>>((name) => {
      if (name === patientId) {
        // for cases where the data is manually created we use the patient ID as the sensor name
        return {
          identifier: name,
          title: strings.VitalObservationDeviceName,
        };
      }

      return {
        identifier: name,
        title: name,
      };
    });
  }, [graphData, patientId, secondaryData]);

  const hasFilters = filters.length > 0;
  const showFilter = canViewFilter && isExpanded && hasFilters;

  const session = useUserSession();
  const canCreateObservations = session.hasAny([GroupSecurity.ObservationCreate]);

  const onSubmit = async (measures: Observation.ObservationVitalMeasurement[]) => {
    await observationApi.createObservationVital(patientId, { measures });
    revalidate?.();
  };

  return (
    <>
      <VitalCard<MeasurementName>
        key={measureName}
        identifier={primaryGraphMeasureName}
        initialWidth={initialWidth}
        data={filteredGraphData}
        latestValue={latestValue}
        gradient={gradientStops[measureName]}
        yDomain={domain}
        xDomain={timeFrame}
        valueFormatter={valueFormatters[measureName]}
        unit={units[measureName]}
        code={codes[measureName] || measureName}
        icon={icons[measureName]}
        title={titles[measureName] || measureName}
        color={colors[measureName] || 'accent-blue'}
        isExpanded={isExpanded}
        onExpandPress={handleExpand}
        slider={sliderProps}
        spline={mergedSplineProps}
        summary={summary}
        secondaryData={filteredSecondaryData}
        showSecondaryData={showSecondaryData}
        legend={legend}
        onFilterChange={setDeviceFilter}
        showFilter={showFilter}
        filters={filters}
        showAddButton={canCreateObservations}
        onAddPress={dialogOpen.setTrue}
        showTooltip
      />

      <Visible if={canCreateObservations}>
        <VitalObservationCreateForm
          measure={measureName}
          isOpen={dialogOpen.value}
          onSubmit={onSubmit}
          onClose={dialogOpen.setFalse}
        />
      </Visible>
    </>
  );
};
