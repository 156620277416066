import { ColorName, Theme } from '@emotion/react';
import { lightenColor } from 'design-system/theme';

import { Node } from './types';

export const getFadedColor = (theme: Theme, color: ColorName): string => {
  const resolvedColor = theme.color[color];

  return lightenColor(resolvedColor, 0.5);
};

export const getNodeTextColor = (node: Node): ColorName => {
  const textColor = node.selected ? 'text-on-color' : 'text-default';

  return textColor;
};

export const getNodeColor = (node: Node): ColorName => node.color || 'text-subdued';

export const sortByType = (child: Node) => (child.type === 'comment' ? 1 : -1);
