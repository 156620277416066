import { GroupSecurity } from '@quromedical/fhir-common';
import { Person, Practitioner } from '@quromedical/models';
import { PractitionerActiveToggleButton } from 'core/practitioner';
import { Alert, Section, Skeleton, SkeletonProvider, Snackbar } from 'design-system';
import { onMapsPress } from 'helpers/platform-links';
import { useRevalidation } from 'hooks/useRevalidation';
import { useUserSession } from 'hooks/useUserSession';
import { getAddressPartialFetcher, googlePlaceFetcher } from 'integration';
import { PractitionerApi, PractitionerEndpoint } from 'integration/aggregate';
import { PractitionerStackScreenProps } from 'navigation';
import React, { useCallback } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { PersonContactForm, PersonGeneralForm } from 'screens/person';
import { strings } from 'strings';
import useSWR from 'swr';

import { PractitionerGroupMembershipCard, PractitionerQualificationCard } from './card';

const api = new PractitionerApi();

export const PractitionerScreen: React.FC<PractitionerStackScreenProps<'View'>> = ({ route }) => {
  const session = useUserSession();

  const canEdit = session.hasAny([GroupSecurity.PractitionerUpdate]);

  const { id } = route.params;
  const fetcher = useCallback(() => api.getPractitioner(id), [id]);
  const swrKey = api.getPractitionerSWRKey(id, PractitionerEndpoint.Base);
  const { data, isValidating, error } = useSWR(swrKey, fetcher);
  const revalidate = useRevalidation(swrKey);
  const onGeneralSubmit = useCallback(
    async (formData: Practitioner.GeneralUpdateRequest) => {
      await api.updatePractitionerGeneral(id, formData);
    },
    [id]
  );

  const onContactSubmit = useCallback(
    async (results: Person.Contact) => {
      await api.updatePractitionerContact(id, results);
    },
    [id]
  );

  const onQualificationSubmit = useCallback(
    async (results: Practitioner.QualificationUpdateRequest) => {
      await api.updatePractitionerQualification(id, results);
    },
    [id]
  );

  const onMapsPressed = useCallback(() => {
    onMapsPress(data?.contact);
  }, [data?.contact]);

  if (error) {
    return (
      <Snackbar onClose={revalidate} isOpen={!!error}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={revalidate}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
        />
      </Snackbar>
    );
  }

  const loading = isValidating || !data;

  const isActive = !!data?.active;

  const isButtonDisabled = !data?.contact?.addressCoordinates;

  return (
    <ScrollView>
      <SkeletonProvider loading={loading}>
        <Section>
          <Skeleton>
            <PersonGeneralForm
              canEdit={canEdit}
              revalidate={revalidate}
              data={data?.general}
              onSubmit={onGeneralSubmit}
              minimalData={true}
            />
          </Skeleton>
        </Section>

        <Section>
          <Skeleton>
            <PersonContactForm
              canEdit={canEdit}
              data={data?.contact}
              onSubmit={onContactSubmit}
              revalidate={revalidate}
              hasEmergencyAccess={false}
              addressFetcher={googlePlaceFetcher}
              getAddressPartial={getAddressPartialFetcher}
              onMapsPress={onMapsPressed}
              isButtonDisabled={isButtonDisabled}
            />
          </Skeleton>
        </Section>
        <Section>
          <Skeleton>
            <PractitionerQualificationCard
              onSubmit={onQualificationSubmit}
              data={data?.qualification}
              canEdit={canEdit}
              revalidate={revalidate}
            />
          </Skeleton>
        </Section>
        <Section>
          <Skeleton>
            <PractitionerGroupMembershipCard data={data?.groups} />
          </Skeleton>
        </Section>
        <Section alignItems="flex-end" hasBottomMargin>
          <Skeleton>
            <PractitionerActiveToggleButton id={id} isActive={isActive} canEdit={canEdit} />
          </Skeleton>
        </Section>
      </SkeletonProvider>
    </ScrollView>
  );
};
