import { GroupSecurity } from '@quromedical/fhir-common';
import { PatientHeaderSection } from 'core/patient';
import { Tabs } from 'design-system';
import { useUserSession } from 'hooks';
import { usePatientId } from 'providers/PatientIdContext';
import React, { useCallback, useRef } from 'react';
import { ScrollView } from 'react-native';
import { strings } from 'strings';

import { PatientVitalsActiveTab } from './PatientVitalsActiveTab';
import { PatientVitalsTrackingTab } from './PatientVitalsTrackingTab';

export const PatientVitalsScreen: React.FC = () => {
  const session = useUserSession();

  const id = usePatientId();
  const scrollViewRef = useRef<ScrollView | null>(null);

  const scrollToTop = useCallback(() => {
    scrollViewRef.current?.scrollTo(0);
  }, []);

  const hasTrackingPermission = session.hasAny([GroupSecurity.FeaturePreviewTracking]);

  return (
    <ScrollView ref={scrollViewRef}>
      <PatientHeaderSection id={id} />

      <Tabs.Group>
        <Tabs.Screen title={strings.VitalsActiveTabTitle}>
          <PatientVitalsActiveTab id={id} scrollToTop={scrollToTop} />
        </Tabs.Screen>
        <Tabs.Screen title={strings.VitalsTrackingTabTitle} isHidden={!hasTrackingPermission}>
          <PatientVitalsTrackingTab id={id} scrollToTop={scrollToTop} />
        </Tabs.Screen>
      </Tabs.Group>
    </ScrollView>
  );
};
