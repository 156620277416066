import { MDM } from '@quromedical/models';
import { Field } from 'design-system';
import { timeWithSecondsFormatter } from 'helpers';
import compact from 'lodash.compact';
import startCase from 'lodash.startcase';
import { strings } from 'strings';

export const isGeolocationPositionArray = (
  statusItem: MDM.StatusItem
): statusItem is MDM.GeolocationPositions[] => {
  const isArray = Array.isArray(statusItem);
  if (!isArray) {
    return false;
  }

  const first = statusItem[0];
  if (!first) {
    return false;
  }

  return !!(first.date && first.latitude && first.longitude);
};

export const renderGeolocation = ({
  address,
  latitude,
  longitude,
  timestamp,
}: MDM.GeolocationPositions): Field | undefined => ({
  type: 'list',
  label: strings.TinyMDMGeoPositionLabel,
  display: compact([
    address?.toString(),
    latitude?.toString(),
    longitude?.toString(),
    timeWithSecondsFormatter(timestamp),
  ]),
});

const toNormalCasing = (name: string): string => {
  const withSpaces = name.replace('_', ' ');

  return startCase(withSpaces);
};

const typeMappers: Record<string, (name: string, statusItem: MDM.StatusItem) => Field | undefined> =
  {
    string: (name, statusItem) => ({
      type: 'text',
      label: toNormalCasing(name),
      display: statusItem as string,
    }),
    number: (name, statusItem) => ({
      type: 'text',
      label: toNormalCasing(name),
      display: (statusItem as number).toString(),
    }),
    boolean: (name, statusItem) => ({
      type: 'text',
      label: toNormalCasing(name),
      display: (statusItem as boolean).toString(),
    }),
    object: (name, statusItem) => {
      const isGeolocation = isGeolocationPositionArray(statusItem);
      if (isGeolocation) {
        const first = statusItem[0];
        return renderGeolocation(first);
      }

      return undefined;
    },
  };

const notAvailable = (name: string): Field => ({
  type: 'text',
  label: toNormalCasing(name),
  display: strings.ValueNotAvailable,
});

export const mapStatusItemToField = (
  name: string,
  statusItem: MDM.StatusItem | undefined
): Field => {
  if (!statusItem) {
    return notAvailable(name);
  }

  const typeOfStatusItem = typeof statusItem;

  const resolved = typeMappers[typeOfStatusItem];
  const result = resolved?.(name, statusItem);

  if (!result) {
    return notAvailable(name);
  }

  return result;
};
