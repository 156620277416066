import styled from '@emotion/native';
import { Col, Row } from 'components/base';
import { Visible, Text } from 'design-system/base';
import { MARGIN } from 'design-system/theme';
import { useLayoutDimensions } from 'hooks/useLayoutDimensions';
import React from 'react';
import { View } from 'react-native';

import { StatusPlot, StatusPlotProps } from './StatusPlot';

export interface StatusBarProps extends Omit<StatusPlotProps, 'layoutWidth'> {
  title?: string;
  subtitle?: string;
}

const TextWrapper = styled(Col)({
  marginRight: MARGIN.s,
});

export const StatusBar: React.FC<StatusBarProps> = ({ title = '', subtitle, ...rest }) => {
  const { layout, onLayout } = useLayoutDimensions({});

  return (
    <Row flex={1} alignItems="flex-start">
      <Visible if={title}>
        <TextWrapper>
          <Row>
            <Text variant="caption">{title}</Text>
          </Row>
          <Row isVisible={!!subtitle}>
            <Text variant="caption-small">{subtitle}</Text>
          </Row>
        </TextWrapper>
      </Visible>

      <Col flex={1}>
        <View onLayout={onLayout}>
          <StatusPlot {...rest} layoutWidth={layout.width} />
        </View>
      </Col>
    </Row>
  );
};
