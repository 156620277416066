import { ObjectType } from 'validation';
import * as yup from 'yup';

import { FormMedia } from './MediaForm';

export const schema = yup
  .object<ObjectType<FormMedia>>({
    media: yup.array().required().min(1).max(5),
  })
  .defined();
