import { logger } from 'helpers';
import { useCallback, useEffect, useState } from 'react';
import useWebsocketBase, { ReadyState } from 'react-use-websocket';

export type SendMessage<TOpenMessage> = (message: TOpenMessage) => void;
type OnMessageHandler<TReceived> = (message: TReceived) => void;
type OnOpenHandler<TOpenMessage> = (send: SendMessage<TOpenMessage>) => void;

/**
 * Use a subscription to a websocket connection with data event handling and JSON parsing. Based on
 * react-use-websocket
 */
export const useWebsocket = <TReceivedMessage, TOpenMessage>(
  wssUrl: string,
  onOpen: OnOpenHandler<TOpenMessage>,
  onMessage: OnMessageHandler<TReceivedMessage>
) => {
  const [openHandled, setOpenHandled] = useState(false);

  const handleRawMessage = useCallback(
    (ev: MessageEvent) => {
      if (typeof ev.data !== 'string') {
        return;
      }

      try {
        const parsed = JSON.parse(ev.data) as TReceivedMessage;
        onMessage(parsed);
      } catch (err) {
        logger.error(err);
      }
    },
    [onMessage]
  );

  const websocket = useWebsocketBase(wssUrl, {
    onError: logger.error,
    onMessage: handleRawMessage,
  });

  const { getWebSocket, sendJsonMessage, readyState } = websocket;

  useEffect(() => {
    if (openHandled) {
      return;
    }

    const isReady = readyState === ReadyState.OPEN;
    if (!isReady) {
      return;
    }

    onOpen(sendJsonMessage);
    setOpenHandled(true);
  }, [getWebSocket, onOpen, openHandled, sendJsonMessage, readyState]);
};
