// importing from here temporarily, when we migrate from the old components we should move this here
import { PrimitiveValueType, SelectOption } from 'components/types';
import { useTheme } from 'design-system/theme';
import uniqBy from 'lodash.uniqby';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import DropDownPicker, {
  ItemType as DropdownItemType,
  ValueType as DropdownValueType,
} from 'react-native-dropdown-picker';
import { useAsyncDebounce } from 'react-table';

import { Wrapper } from '../internal';
import { Fetcher, mapOptionsToItems } from './fetcher';
import {
  ArrowDownIconComponent,
  ArrowUpIconComponent,
  getListEmptyComponent,
  getStatusItems,
  Step,
  TickIconComponent,
} from './placeholders';
import { baseStyle, useDropdownTheme } from './styles';
import { toValueTypeArray } from './value-type';

type ValueType<
  TValue extends PrimitiveValueType,
  TData = any,
  TReturnFull extends boolean = false
> = TReturnFull extends true ? SelectOption<TData, TValue>[] : TValue[];

export interface ComboBoxMultipleProps<
  TOptionValue extends PrimitiveValueType = PrimitiveValueType,
  TReturnFull extends boolean = false,
  TData = TReturnFull extends true ? any : undefined,
  TValue = ValueType<TOptionValue, TData, TReturnFull>
> {
  label: string;
  placeholder?: string;
  isDisabled?: boolean;
  errors?: string;
  initialSelection?: SelectOption<TData, TOptionValue>[];
  value?: TValue;
  /**
   * @deprecated will not work with multiple values
   * If the full `SelectOption` object should be returned, will otherwise return the `ValueType`
   */
  returnFullOption?: TReturnFull;
  searchPlaceholder?: string;
  /**
   * Note that if you're using searchable along with a static `fetcher` the results will not be
   * re-evaluated
   */
  searchable?: boolean;
  onChange: (value: TValue) => void;
  fetcher: Fetcher<TOptionValue, TData>;
  expand?: boolean;
}

export const ComboBoxMultiple = <
  TValue extends PrimitiveValueType = PrimitiveValueType,
  TReturnFull extends boolean = false,
  TData = any
>({
  label,
  errors,
  value = [],
  isDisabled,
  placeholder,
  returnFullOption,
  searchPlaceholder,
  onChange,
  fetcher,
  searchable,
  expand = true,
  initialSelection = [],
}: ComboBoxMultipleProps<TValue, TReturnFull, TData>): ReactElement => {
  const theme = useTheme();
  const { themeName, themeStyle } = useDropdownTheme();

  const primitive = toValueTypeArray(value as TValue[]);

  // the library shouldn't pass us back null at any point, but this is something we may need to
  // investigate if we have issues around null and undefined values misbehaving at some point
  const [innerValue, setInnerValue] = useState<DropdownValueType[] | null>(
    primitive as DropdownValueType[]
  );

  const initialOptions = typeof fetcher !== 'function' ? fetcher : initialSelection;

  const [options, setOptions] = useState<SelectOption<TData, TValue>[]>(initialOptions);

  const initialItems = mapOptionsToItems(initialSelection);
  const [searchItems, setSearchItems] = useState<DropdownItemType<TValue>[]>(initialItems);

  // ensure that we always have a reference to items that are no longer in the search options
  const [cache, setCache] = useState<DropdownItemType<TValue>[]>([
    ...initialItems,
    ...mapOptionsToItems(initialOptions),
  ]);

  const updateCache = useCallback((newItems: DropdownItemType<TValue>[]) => {
    setCache((current) => {
      const merged = [...current, ...newItems];
      return uniqBy(merged, (val) => val.value);
    });
  }, []);

  const [open, setOpen] = useState(false);
  const [step, setStep] = useState<Step>('data');
  const [query, setQuery] = useState<string>('');

  const search = useCallback(
    async (text: string) => {
      if (typeof fetcher !== 'function') {
        const mappedItems = mapOptionsToItems(fetcher);
        setSearchItems(mappedItems);
        return;
      }

      const trimmedQuery = text.trim() || undefined;

      const searchResult = await fetcher(trimmedQuery);
      setOptions(searchResult);
      const mappedItems = mapOptionsToItems(searchResult);
      setSearchItems(mappedItems);
      updateCache(mappedItems);
    },
    [fetcher, updateCache]
  );

  const debouncedSearch = useAsyncDebounce(search, 1000);

  useEffect(() => {
    setStep('loading');
    debouncedSearch(query)
      .then(() => setStep('data'))
      .catch(() => setStep('error'));
  }, [debouncedSearch, query]);

  const handleSelectItem = useCallback(
    (changeValue: DropdownItemType<TValue>[]) => {
      if (changeValue === null) {
        return;
      }

      const shouldCallEmptyChange = changeValue?.length === 0 && value.length > 0;

      if (shouldCallEmptyChange) {
        onChange([]);
        return;
      }

      if (returnFullOption === true) {
        const changeValues = changeValue.map((v) => v.value);
        const fullValue = options.filter((item) => changeValues?.includes(item.value));

        onChange(fullValue as ValueType<TValue, TData, TReturnFull>);
      } else {
        const values = changeValue.map((v) => v.value);
        onChange(values as ValueType<TValue, TData, TReturnFull>);
      }
    },
    [onChange, returnFullOption, options, value]
  );

  const loading = step === 'loading';

  const itemsWithStatus = useMemo(
    () => [...getStatusItems(step), ...searchItems],
    [searchItems, step]
  );

  const handleChangeValue = useCallback(
    // on change needs to be manually handled since it's not otherwise called when items are removed
    (changeItems: DropdownValueType[] | null) => {
      if (!changeItems) {
        return;
      }

      const updatedItems = cache.filter((item) => {
        if (item.value === undefined) {
          return false;
        }

        return changeItems.includes(item.value);
      });

      handleSelectItem(updatedItems);
    },
    [handleSelectItem, cache]
  );

  const ListEmptyComponent = getListEmptyComponent(step);

  return (
    <Wrapper label={label} error={errors} open={open}>
      <DropDownPicker
        disabled={isDisabled}
        multiple={true}
        loading={loading}
        searchable={searchable}
        searchPlaceholder={searchPlaceholder}
        placeholder={placeholder}
        // state management
        value={innerValue}
        items={itemsWithStatus as DropdownItemType<DropdownValueType>[]}
        setOpen={setOpen}
        open={open}
        setValue={setInnerValue}
        onChangeSearchText={setQuery}
        onChangeValue={handleChangeValue}
        // we can modify the function used here in order to add fuzzy searching at a later stage
        setItems={setSearchItems}
        // styles and usage
        theme={themeName}
        closeOnBackPressed={true}
        showBadgeDot={true}
        extendableBadgeContainer={expand}
        listMode="SCROLLVIEW"
        mode="BADGE"
        dropDownContainerStyle={[
          baseStyle.dropDownContainerStyle,
          themeStyle.dropDownContainerStyle,
        ]}
        style={[baseStyle.style, themeStyle.style]}
        textStyle={[baseStyle.textStyle, themeStyle.textStyle]}
        badgeStyle={[baseStyle.badgeStyle, themeStyle.badgeStyle]}
        badgeDotStyle={baseStyle.badgeDotStyle}
        badgeTextStyle={baseStyle.badgeTextStyle}
        disabledItemLabelStyle={themeStyle.disabledItemLabelStyle}
        listItemContainerStyle={[
          baseStyle.listItemContainerStyle,
          themeStyle.listItemContainerStyle,
        ]}
        listItemLabelStyle={[baseStyle.listItemLabelStyle, themeStyle.listItemLabelStyle]}
        searchContainerStyle={[baseStyle.searchContainerStyle, themeStyle.searchContainerStyle]}
        searchTextInputStyle={[baseStyle.searchTextInputStyle, themeStyle.searchTextInputStyle]}
        badgeDotColors={[theme.color['status-success']]}
        badgeColors={[theme.color['base-grey']]}
        searchPlaceholderTextColor={theme.color['text-subdued']}
        ArrowDownIconComponent={ArrowDownIconComponent}
        ArrowUpIconComponent={ArrowUpIconComponent}
        TickIconComponent={TickIconComponent}
        ListEmptyComponent={ListEmptyComponent}
      />
    </Wrapper>
  );
};
