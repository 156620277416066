import { ColumnType, DataTable } from 'components/table';
import { FormCard, RowConfig, Skeleton, SectionSpacer, Text } from 'design-system';
import { getMoneyDisplay } from 'helpers/money';
import { useFiniteState } from 'hooks/useFiniteState';
import { useCallback } from 'react';
import { strings } from 'strings';

import {
  ChargeItemDefinitionForm,
  ChargeItemDefinitionFormProps,
} from './ChargeItemDefinitionForm';
import { ChargeLine } from './ChargeLineForm';

interface ChargeItemDefinitionCardProps extends ChargeItemDefinitionFormProps {
  canEdit: boolean;
}

type CardState = 'initial' | 'form';

interface Row {
  description?: string;
}

const createCardRows = (row?: Row): RowConfig[] => [
  {
    fields: [
      {
        type: 'text',
        label: strings.ChargeItemDefinitionFormLabelDescription,
        display: row?.description,
      },
    ],
  },
];

const tableColumns: ColumnType<ChargeLine>[] = [
  {
    accessor: 'identifierCode',
    header: strings.ChargeLineAppliesOptionLabel,
  } as ColumnType<ChargeLine, 'identifierCode'>,
  {
    accessor: 'amount',
    header: strings.ChargeLineUnitPrice,
    Cell: ({ row }) => (
      <Text>
        {row.original.amount
          ? getMoneyDisplay(
              {
                currency: row.original.currency,
                factor: row.original.amountFactor,
                value: row.original.amount,
              },
              1
            )
          : ''}
      </Text>
    ),
  } as ColumnType<ChargeLine, 'amount'>,
  {
    accessor: 'type',
    header: strings.ChargeLineAppliesSchemeOrAdministrator,
  } as ColumnType<ChargeLine, 'type'>,
  {
    accessor: 'chargeCode',
    header: strings.ChargeLineItemCodeLabel,
  } as ColumnType<ChargeLine, 'chargeCode'>,
];

export const ChargeItemDefinitionCard: React.FC<ChargeItemDefinitionCardProps> = ({
  canEdit,
  initial,
  onSubmit,
  ...rest
}) => {
  const state = useFiniteState<CardState>('initial');

  const handleSubmit = useCallback<ChargeItemDefinitionCardProps['onSubmit']>(
    async (data) => {
      await onSubmit(data);
      state.set('initial');
    },
    [onSubmit, state]
  );

  if (state.is('form')) {
    return (
      <ChargeItemDefinitionForm
        {...rest}
        onSubmit={handleSubmit}
        initial={initial}
        onCancel={state.next('initial')}
      />
    );
  }

  const cardRows = createCardRows(initial);

  return (
    <>
      <Skeleton>
        <FormCard
          title={initial?.title || strings.ChargeItemDefinitionFormEditTitle}
          rows={cardRows}
          onButtonPress={state.next('form')}
          buttonText={strings.ButtonTextEdit}
          buttonIcon="edit"
          isButtonVisible={canEdit}
        />
      </Skeleton>

      <SectionSpacer />

      <Skeleton>
        <DataTable columns={tableColumns} data={initial?.chargeLines || []} />
      </Skeleton>
    </>
  );
};
