import {
  useHMSActions,
  selectPeers,
  useHMSStore,
  selectIsConnectedToRoom,
  selectIsLocalAudioEnabled,
  selectIsLocalVideoEnabled,
  HMSRoomProvider,
  selectPeerByID,
  selectConnectionQualityByPeerID,
  selectIsPeerAudioEnabled,
  selectIsPeerVideoEnabled,
  selectIsInPreview,
  selectDominantSpeaker,
  selectPeerAudioByID,
} from '@100mslive/react-sdk';
import { getDisplayName } from 'core/person';
import { logger } from 'helpers';
import { useFhirData } from 'hooks/useFhirData';
import { useUserSession } from 'hooks/useUserSession';
import { PractitionerApi } from 'integration/aggregate';
import { first } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { MeetingRoomContextValue, UsePeerHook, MeetingState } from './types';

const MeetingRoomDataContext = React.createContext<Partial<MeetingRoomContextValue>>({});

const practitionerApi = new PractitionerApi();

const fetcher = (email?: string) =>
  practitionerApi.getPractitioners({
    email,
  });

const MeetingRoomDataProvider: React.FC = ({ children }) => {
  const user = useUserSession().getUser();

  const { data: results } = useFhirData(['Practitioner', 'current-user'], user?.email, fetcher);

  const practitioner = first(results?.practitioners);

  const [meetingState, setMeetingState] = useState<MeetingState>({
    type: 'out-of-call',
    roomToken: undefined,
  });

  const isAudioEnabled = useHMSStore(selectIsLocalAudioEnabled);
  const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
  const isMicEnabled = useHMSStore(selectIsLocalAudioEnabled);
  const isVideoEnabled = useHMSStore(selectIsLocalVideoEnabled);
  const inWaitingRoom = useHMSStore(selectIsInPreview);

  const speaker = useHMSStore(selectDominantSpeaker);
  const speakerId = speaker?.id;

  const hmsPeers = useHMSStore(selectPeers);

  const isConnected = !!isConnectedToRoom;

  const hmsActions = useHMSActions();

  useEffect(() => {
    // TODO: might need a state machine lib(xState)
    const isLoading =
      meetingState.type === 'loading-call' || meetingState.type === 'loading-waiting-room';

    // we only want to allow transitions from loading states
    if (!isLoading) {
      return;
    }

    const { roomToken } = meetingState;

    if (isConnected) {
      setMeetingState({ type: 'in-call', roomToken });
    } else if (inWaitingRoom) {
      setMeetingState({ type: 'in-waiting-room', roomToken });
    }
  }, [isConnected, inWaitingRoom, meetingState]);

  const joinWaitingRoom = useCallback(
    async (authToken: string) => {
      if (!user) {
        return;
      }
      const displayName = getDisplayName(practitioner?.general);
      const userName = displayName || user.email;

      const config = {
        userName,
        authToken,
        settings: {
          isAudioMuted: false,
          isVideoMuted: false,
        },
        rememberDeviceSelection: true,
        captureNetworkQualityInPreview: true,
      };
      setMeetingState({ type: 'loading-waiting-room', roomToken: authToken });
      try {
        await hmsActions.preview(config);
      } catch (err) {
        logger.error(err);
      }
    },
    [hmsActions, user, practitioner]
  );

  const joinMeeting = useCallback(() => {
    if (!user) {
      return;
    }

    const displayName = getDisplayName(practitioner?.general);
    const userName = displayName || user.email;

    if (meetingState.roomToken) {
      setMeetingState({ type: 'loading-call', roomToken: meetingState.roomToken });
      try {
        hmsActions.join({
          authToken: meetingState.roomToken,
          userName,
        });
      } catch (err) {
        logger.error(err);
      }
    }
  }, [hmsActions, meetingState, user, practitioner]);

  const setMicEnabled = useCallback(
    async (enabled: boolean) => {
      try {
        await hmsActions.setLocalAudioEnabled(enabled);
      } catch (err) {
        logger.error(err);
      }
    },
    [hmsActions]
  );

  const setVideoEnabled = useCallback(
    async (enabled: boolean) => {
      try {
        await hmsActions.setLocalVideoEnabled(enabled);
      } catch (err) {
        logger.error(err);
      }
    },
    [hmsActions]
  );

  const leaveMeeting = useCallback(async () => {
    try {
      await setVideoEnabled(false);
      setMeetingState({ type: 'out-of-call', roomToken: undefined });
    } catch (err) {
      logger.error(err);
    } finally {
      await hmsActions.leave();
    }
  }, [hmsActions, setVideoEnabled]);

  const toggleMicEnabled = useCallback(
    async () => setMicEnabled(!isMicEnabled),
    [isMicEnabled, setMicEnabled]
  );

  const toggleVideoEnabled = useCallback(
    () => setVideoEnabled(!isVideoEnabled),
    [isVideoEnabled, setVideoEnabled]
  );

  const minimize = useCallback(() => {
    if (meetingState.type !== 'in-call') {
      return;
    }
    setMeetingState({ type: 'in-call-minimized', roomToken: meetingState.roomToken });
  }, [meetingState]);

  const expand = useCallback(() => {
    if (meetingState.type !== 'in-call-minimized') {
      return;
    }
    setMeetingState({ type: 'in-call', roomToken: meetingState.roomToken });
  }, [meetingState]);

  const peerIds = hmsPeers.map((peer) => peer.id);

  const localId = hmsPeers.find((peer) => peer.isLocal)?.id;
  const remoteIds = hmsPeers.filter((peer) => !peer.isLocal).map((peer) => peer.id);

  const value: MeetingRoomContextValue = {
    meetingState,
    localId,
    remoteIds,
    peerIds,
    isAudioEnabled,
    isMicEnabled,
    isVideoEnabled,
    speakerId,
    minimize,
    expand,
    setMicEnabled,
    setVideoEnabled,
    joinWaitingRoom,
    joinMeeting,
    leaveMeeting,
    toggleMicEnabled,
    toggleVideoEnabled,
  };

  return (
    <MeetingRoomDataContext.Provider value={value}>{children}</MeetingRoomDataContext.Provider>
  );
};

export const useMeeting = () => useContext(MeetingRoomDataContext) as MeetingRoomContextValue;

export const usePeer: UsePeerHook = (id) => {
  const base = useHMSStore(selectPeerByID(id));
  // this means that the remote peer mic is enabled
  const isAudioEnabled = useHMSStore(selectIsPeerAudioEnabled(id));
  const isVideoEnabled = useHMSStore(selectIsPeerVideoEnabled(id));
  const volume = useHMSStore(selectPeerAudioByID(id));
  const signalLevel = useHMSStore(selectConnectionQualityByPeerID(id))?.downlinkQuality;

  const hasData = id && base;

  if (!hasData) {
    return undefined;
  }

  return {
    id,
    isLocal: base?.isLocal,
    isMicEnabled: isAudioEnabled,
    isVideoEnabled,
    name: base.name,
    trackId: base.videoTrack,
    volume,
    signalLevel,
  };
};

export const MeetingRoomProvider: React.FC = ({ children }) => (
  <HMSRoomProvider>
    <MeetingRoomDataProvider>{children}</MeetingRoomDataProvider>
  </HMSRoomProvider>
);
