import {
  BORDER_RADIUS,
  BORDER_WIDTH,
  FONT_FAMILY,
  FONT_SIZE,
  MARGIN,
  PADDING,
  COLOR_DARK,
  COLOR_LIGHT,
  useTheme,
} from 'design-system/theme';
import { Platform, StyleSheet, ViewStyle } from 'react-native';
import { ThemeNameType } from 'react-native-dropdown-picker';

const nativeDropdownContainerStyles: ViewStyle =
  Platform.OS === 'web'
    ? {}
    : {
        position: 'relative',
        top: 0,
      };

export const baseStyle = StyleSheet.create({
  dropDownContainerStyle: {
    ...nativeDropdownContainerStyles,
    marginTop: MARGIN['2xs'],
    borderRadius: 0,
    borderWidth: BORDER_WIDTH[1],
  },
  // main wrapper style
  style: {
    paddingHorizontal: PADDING.xs,
    minHeight: 38,
    borderRadius: 0,
    borderWidth: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // applies to all text in component
  textStyle: {
    fontSize: FONT_SIZE.body,
    fontFamily: FONT_FAMILY.regular,
  },
  // the style for badges when using a multi select
  badgeStyle: {
    paddingVertical: PADDING['3xs'],
    paddingHorizontal: PADDING.xs,
    borderRadius: BORDER_RADIUS.full,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // the style of indicator when an item is selected for multi-select
  badgeDotStyle: {
    height: 10,
    width: 10,
    borderRadius: BORDER_RADIUS.full,
    marginRight: MARGIN['2xs'],
  },
  badgeTextStyle: {
    fontSize: FONT_SIZE.caption,
    fontFamily: FONT_FAMILY.regular,
  },
  // style for the option container
  listItemContainerStyle: {
    paddingHorizontal: PADDING.xs,
    paddingVertical: PADDING['2xs'],
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listItemLabelStyle: {
    fontSize: FONT_SIZE.caption,
  },
  searchContainerStyle: {
    borderBottomWidth: BORDER_WIDTH[2],
    borderStyle: 'solid',
    padding: 0,
  },
  searchTextInputStyle: {
    borderRadius: 0,
    borderWidth: 0,
    paddingHorizontal: PADDING.xs,
    paddingVertical: PADDING['2xs'],
  },
});

const darkStyle = StyleSheet.create({
  dropDownContainerStyle: {
    backgroundColor: COLOR_DARK['base-grey'],
    borderColor: COLOR_DARK.divider,
  },
  // main wrapper style
  style: {
    backgroundColor: COLOR_DARK.base,
  },
  // applies to all text in component
  textStyle: {
    color: COLOR_DARK['text-default'],
  },
  // the style for badges when using a multi select
  badgeStyle: {
    backgroundColor: COLOR_DARK['accent-blue'],
  },
  // styles for the disabled items
  disabledItemLabelStyle: {
    color: COLOR_DARK['text-disabled'],
  },
  // style for the option container
  listItemContainerStyle: {
    backgroundColor: COLOR_DARK['base-grey'],
  },
  listItemLabelStyle: {
    color: COLOR_DARK['text-default'],
  },
  searchContainerStyle: {
    backgroundColor: COLOR_DARK['base-grey'],
    borderBottomColor: COLOR_DARK.divider,
  },
  searchTextInputStyle: {
    color: COLOR_DARK['text-default'],
  },
});

const lightStyle = StyleSheet.create({
  dropDownContainerStyle: {
    backgroundColor: COLOR_LIGHT['base-grey'],
    borderColor: COLOR_LIGHT.divider,
  },
  // main wrapper style
  style: {
    backgroundColor: COLOR_LIGHT.base,
  },
  // applies to all text in component
  textStyle: {
    color: COLOR_LIGHT['text-default'],
  },
  // the style for badges when using a multi select
  badgeStyle: {
    backgroundColor: COLOR_LIGHT['accent-blue'],
  },
  // styles for the disabled items
  disabledItemLabelStyle: {
    color: COLOR_LIGHT['text-disabled'],
  },
  // style for the option container
  listItemContainerStyle: {
    backgroundColor: COLOR_LIGHT['base-grey'],
  },
  listItemLabelStyle: {
    color: COLOR_LIGHT['text-default'],
  },
  searchContainerStyle: {
    backgroundColor: COLOR_LIGHT['base-grey'],
    borderBottomColor: COLOR_LIGHT.divider,
  },
  searchTextInputStyle: {
    color: COLOR_LIGHT['text-default'],
  },
});

interface DropdownThemeResult {
  themeName: ThemeNameType;
  themeStyle: typeof darkStyle | typeof lightStyle;
}

export const useDropdownTheme = (): DropdownThemeResult => {
  const theme = useTheme();
  const themeName: ThemeNameType = theme.name === 'light' ? 'LIGHT' : 'DARK';
  const themeStyle = theme.name === 'light' ? lightStyle : darkStyle;

  return { themeName, themeStyle };
};
