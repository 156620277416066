// converted using SVGR with React-Native and Typescript Options
// https://react-svgr.com/playground/?native=true&typescript=true
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { CustomIconProps } from './internal';

export type BarValue = 0 | 1 | 2 | 3;

interface SignalStrengthProps extends CustomIconProps {
  bars?: BarValue;
  missingColor?: string;
}

export const SignalStrength: React.FC<SignalStrengthProps> = ({
  color = '#000000',
  size = 24,
  missingColor = '#ffffff',
  bars = 3,
}) => {
  const topBarColor = bars >= 3 ? color : missingColor;
  const middleBarColor = bars >= 2 ? color : missingColor;
  const bottomBarColor = bars >= 0 ? color : missingColor;

  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 48 48">
      <Path
        fill={topBarColor}
        d="M3.15 21.7 0 18.55q4.65-4.75 10.825-7.65Q17 8 24 8q7 0 13.175 2.9Q43.35 13.8 48 18.55l-3.15 3.15q-4.4-4.2-9.625-6.7T24 12.5q-6 0-11.225 2.5T3.15 21.7Z"
      />

      <Path
        fill={middleBarColor}
        d="M11.6 30.15 8.45 27q3.5-3.5 7.325-5.25T24 20q4.4 0 8.225 1.75Q36.05 23.5 39.55 27l-3.15 3.15q-3.05-3.05-6.15-4.35-3.1-1.3-6.25-1.3t-6.25 1.3q-3.1 1.3-6.15 4.35Z"
      />

      <Path
        fill={bottomBarColor}
        d="M24 42.55l7.4-7.45q-1.45-1.45-3.325-2.275Q26.2 32 24 32t-4.075.825Q18.05 33.65 16.6 35.1Z"
      />
    </Svg>
  );
};
