import { getDataFromResponse, pathologyReportColumns } from 'core/diagnostic-report';
import { PaginatedTable } from 'core/table';
import { Section } from 'design-system';
import { useResponsive } from 'hooks/useResponsive';
import { DiagnosticReportApi } from 'integration';
import React from 'react';

interface DiagnosticReportListProps {
  patientId: string;
}

const api = new DiagnosticReportApi();

export const DiagnosticReportTab: React.FC<DiagnosticReportListProps> = ({ patientId }) => {
  const showHeader = useResponsive({ md: true }, false);
  const isStriped = useResponsive({ md: true }, false);

  const fetcher = (pageToken?: string) =>
    api.getDiagnosticReports({
      subject: patientId,
      pageToken,
    });

  const cacheKey = JSON.stringify({
    subject: patientId,
  });

  return (
    <Section>
      <PaginatedTable
        cacheKey={`DiagnosticReport${cacheKey}`}
        fetcher={fetcher}
        columns={pathologyReportColumns}
        showHeader={showHeader}
        isStriped={isStriped}
        getDataFromResponse={getDataFromResponse}
      />
    </Section>
  );
};
