import { FormCard, Skeleton, SkeletonProvider, Visible } from 'design-system';
import React, { ElementType, PropsWithChildren, ReactElement, useCallback } from 'react';
import { Row as RowType, TableBodyPropGetter, TableBodyProps } from 'react-table';

import { PositionProps } from './PositionProps';
import { PressableWrapper } from './PressableWrapper';

type TableBodyPropGetterFunction<Data extends object> = (
  propGetter?: TableBodyPropGetter<Data> | undefined
) => TableBodyProps;

// ADD: showLoadingSpinner for SkeletonProvider
interface DataTableBodyProps<Data extends object> {
  rows: RowType<Data>[];
  columnConfig: BodyColumnConfigProps<Data>;
  getTableBodyProps: TableBodyPropGetterFunction<Data>;
  prepareRow: (row: RowType<Data>) => void;
  Body: ElementType;
  Row: ElementType<PositionProps>;
  Cell: ElementType<PositionProps>;
  isLoading?: boolean;
  useCards?: boolean;
  onRowPress?: (data: Data) => void;
}

export interface BodyColumnConfig {
  flex?: number;
}

export type BodyColumnConfigProps<Data extends object> = Partial<
  Record<keyof Data, BodyColumnConfig>
>;

// ADD: showLoadingSpinner for SkeletonProvider
export const DataTableBody = <Data extends object>({
  rows,
  columnConfig = {},
  getTableBodyProps,
  prepareRow,
  Body,
  Row,
  Cell,
  isLoading = false,
  onRowPress,
  useCards = false,
}: PropsWithChildren<DataTableBodyProps<Data>>): ReactElement => {
  const onPress = useCallback(
    (data: Data) => {
      if (onRowPress) {
        onRowPress(data);
      }
    },
    [onRowPress]
  );

  interface RowConfig {
    row: RowType<Data>;
  }

  const RowCard: React.FC<RowConfig> = ({ row }) => (
    <FormCard
      rows={row.cells.map((cell, cellIndex) => ({
        fields: [
          {
            type: 'custom',
            label: `${cell.render('header')}`,
            Display: () => (
              <Cell {...cell.getCellProps()} index={cellIndex}>
                {cell.render('Cell')}
              </Cell>
            ),
          },
        ],
      }))}
    />
  );

  return (
    <SkeletonProvider loading={isLoading}>
      <Body {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);

          const onPressHandler = onRowPress && (() => onPress(row.original));
          return (
            <PressableWrapper key={rowIndex} onPress={onPressHandler}>
              <Row {...row.getRowProps()} index={rowIndex}>
                <Visible if={useCards}>
                  <RowCard row={row} />
                </Visible>
                <Visible if={!useCards}>
                  {row.cells.map((cell, cellIndex) => {
                    const key = cell.column.id as keyof Data;
                    const config = columnConfig[key] || {};

                    return (
                      <Skeleton key={cellIndex}>
                        <Cell {...cell.getCellProps()} index={cellIndex} flex={config?.flex}>
                          {cell.render('Cell')}
                        </Cell>
                      </Skeleton>
                    );
                  })}
                </Visible>
              </Row>
            </PressableWrapper>
          );
        })}
      </Body>
    </SkeletonProvider>
  );
};
