import { GroupSecurity } from '@quromedical/fhir-common';
import { Col } from 'components/base';
import { WithGroup } from 'core/auth';
import { UrgentClinicalReviewList, RealtimeAlertsList } from 'core/vitals';
import { Grid, Section } from 'design-system';
import React from 'react';
import { ScrollView } from 'react-native-gesture-handler';

export const HomeScreen: React.FC = () => (
  <ScrollView>
    <Section hasBottomMargin hasTopMargin>
      <WithGroup groups={[GroupSecurity.PatientRead]}>
        <Grid sm={1} lg={2} xl={3} xxxxl={4}>
          <Col>
            <UrgentClinicalReviewList />
          </Col>
          <WithGroup groups={[GroupSecurity.FeaturePreviewAI]}>
            <Col>
              <RealtimeAlertsList />
            </Col>
          </WithGroup>
        </Grid>
      </WithGroup>
    </Section>
  </ScrollView>
);
