import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import { useIsSmallerThan } from 'hooks/useResponsive';
import React from 'react';
import { AdmissionListScreen, PatientListScreen } from 'screens/patient';

import { PatientDrawerNavigator } from './PatientDrawerNavigator';
import { PatientStackParamList, AppRootParamList } from './types';
import { useCommonStackProps } from './useCommonStackProps';

const Stack = createNativeStackNavigator<PatientStackParamList>();

export const PatientStackNavigator: React.FC<
  NativeStackScreenProps<AppRootParamList, 'Patient'>
> = () => {
  const overviewHeaderShown = useIsSmallerThan('lg');
  const screenOptions = useCommonStackProps();

  return (
    <Stack.Navigator initialRouteName="AdmissionList" screenOptions={screenOptions}>
      <Stack.Screen
        name="PatientList"
        component={PatientListScreen}
        options={{
          title: 'Patients',
        }}
      />
      <Stack.Screen
        name="AdmissionList"
        component={AdmissionListScreen}
        options={{
          title: 'Admissions',
        }}
      />
      <Stack.Screen
        name="View"
        component={PatientDrawerNavigator}
        options={{
          title: 'Patient',
          headerShown: overviewHeaderShown,
        }}
      />
    </Stack.Navigator>
  );
};
