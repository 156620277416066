import { Admission, Media, Validations, WithId } from '@quromedical/models';
import { Util } from '@quromedical/utils';
import { CrudForm, SubmissionHandler } from 'components/form';
import { Field, FileResult, SelectOption } from 'components/types';
import {
  admissionClassDisplay,
  admissionServiceDisplay,
  admissionStatusDisplay,
  mapCodingToSelectOption,
} from 'core/display';
import { FileKeyProgressMap, Text } from 'design-system';
import { CreateUploadUrl, useFileUpload } from 'hooks/useFileUpload';
import compact from 'lodash.compact';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

import { createOxygenFields, getOxygenValue, OxygenFields } from './internal';

interface AdmissionUpdateFormProps {
  data: WithId<Partial<Admission.Admission>>;
  hasMedicalAidAuthorization: boolean;
  icd10CodeFetcher: (query?: string) => Promise<SelectOption[]>;
  handleSubmit: (data: Admission.UpdateRequest) => void;
  handleCancel: () => void;
  createUploadUrl: CreateUploadUrl;
}

type DisplayOnlyFields = Pick<Admission.UpdateResponse, 'status' | 'class' | 'service'>;

type FormData = Omit<Admission.UpdateRequest, 'medicalAidAuthorization' | 'attachments'> &
  OxygenFields & {
    medicalAidAuthorizationCode: string;
    medicalAidAuthorizationDate: string;
    media?: FileResult[];
  };

const commonFieldSchemas = {
  periodStart: Validations.admissionRequestSchemaFields.periodStart,
  icd10Codes: Validations.admissionRequestSchemaFields.icd10Codes,
  oxygen: Validations.admissionRequestSchemaFields.oxygen,
};

const withoutMedicalAidAuthSchema = yup.object<ObjectType<FormData>>(commonFieldSchemas).required();

const withMedicalAidAuthSchema = yup
  .object<ObjectType<FormData>>({
    ...commonFieldSchemas,
    medicalAidAuthorizationCode: Validations.medicalAidAuthorizationCreateSchemaFields.code,
    medicalAidAuthorizationDate: Validations.medicalAidAuthorizationCreateSchemaFields.date,
  })
  .required();

interface CreateFieldsProps {
  data: WithId<Partial<Admission.Admission>>;
  icd10CodeFetcher: (query?: string) => Promise<SelectOption[]>;
  hasMedicalAidAuthorization?: boolean;
  hasOxygen?: boolean;
  uploadInProgress?: boolean;
  progress?: FileKeyProgressMap;
}

const createFields: Util.Fn<CreateFieldsProps, Field<FormData & DisplayOnlyFields>[]> = ({
  data,
  icd10CodeFetcher,
  hasMedicalAidAuthorization = false,
  hasOxygen = false,
  uploadInProgress = false,
  progress = {},
}) => {
  const initialIcd10Codes = compact(
    data.icd10Codes?.map((code) => mapCodingToSelectOption('code: display', code))
  );

  return compact([
    {
      subfields: [
        {
          type: 'custom',
          key: 'status',
          label: strings.AdmissionStatusLabel,
          Display: () => <Text>{admissionStatusDisplay[data.status || 'unknown']}</Text>,
          background: false,
        },
      ],
    },
    {
      subfields: [
        {
          type: 'custom',
          key: 'service',
          label: strings.AdmissionServiceLabel,
          Display: () => (
            <Text>{(data.service && admissionServiceDisplay[data.service]) || ''}</Text>
          ),
          background: false,
        },
        {
          type: 'custom',
          key: 'class',
          label: strings.AdmissionServiceLabel,
          Display: () => <Text>{(data.class && admissionClassDisplay[data.class]) || ''}</Text>,
          background: false,
        },
      ],
    },
    {
      subfields: [
        {
          type: 'masked-text-box',
          maskType: 'date',
          key: 'periodStart',
          label: strings.AdmissionStartLabel,
        },
      ],
    },
    hasMedicalAidAuthorization
      ? {
          subfields: [
            {
              type: 'masked-text-box',
              maskType: 'date',
              key: 'medicalAidAuthorizationDate',
              label: strings.AdmissionAuthorizationDateLabel,
            },
            {
              type: 'text-box',
              key: 'medicalAidAuthorizationCode',
              label: strings.AdmissionAuthorizationCodeLabel,
            },
          ],
        }
      : undefined,
    {
      subfields: [
        {
          type: 'combobox-multiple',
          key: 'icd10Codes',
          label: strings.FormLabelICD10Codes,
          fetcher: icd10CodeFetcher,
          searchable: true,
          initialSelection: initialIcd10Codes,
        },
      ],
    },
    ...createOxygenFields(hasOxygen),
    {
      subfields: [
        {
          key: 'media',
          type: 'file-picker-dialog',
          label: strings.FormLabelAttachments,
          uploadInProgress,
          progress,
        },
      ],
    },
  ]);
};

export const AdmissionUpdateForm: React.FC<AdmissionUpdateFormProps> = ({
  data,
  hasMedicalAidAuthorization,
  handleCancel,
  handleSubmit,
  icd10CodeFetcher,
  createUploadUrl,
}) => {
  const initialData: Partial<FormData> = {
    icd10Codes: data.icd10Codes?.map((code) => code.code),
    medicalAidAuthorizationCode: data.medicalAidAuthorization?.code,
    medicalAidAuthorizationDate: data.medicalAidAuthorization?.date,
    periodStart: data.period?.start,
    oxygen: data.oxygen?.value,
    hasOxygen: !!data.oxygen?.value,
  };

  const [attachments, setAttachments] = useState<Media.CreateData[]>([]);

  const { onFilesChange, progress, uploadsComplete } = useFileUpload(
    createUploadUrl,
    setAttachments
  );

  const [formState, setFormState] = useState<Partial<FormData>>(initialData);

  const onChange = useCallback(
    (result: Partial<FormData>) => {
      onFilesChange(result);
      setFormState(result);
    },
    [onFilesChange]
  );

  const onSubmit = useCallback<SubmissionHandler<Partial<FormData>>>(
    (result: Partial<FormData>) => {
      const validated = result as FormData;

      handleSubmit({
        attachments,
        icd10Codes: validated.icd10Codes,
        periodStart: validated.periodStart,
        oxygen: getOxygenValue(validated.hasOxygen, validated.oxygen),
        medicalAidAuthorization: hasMedicalAidAuthorization
          ? {
              code: validated.medicalAidAuthorizationCode,
              date: validated.medicalAidAuthorizationDate,
            }
          : undefined,
      });
      return {};
    },
    [handleSubmit, attachments, hasMedicalAidAuthorization]
  );

  const schema = hasMedicalAidAuthorization
    ? withMedicalAidAuthSchema
    : withoutMedicalAidAuthSchema;

  const fields: Field<FormData & DisplayOnlyFields>[] = createFields({
    data,
    icd10CodeFetcher,
    hasMedicalAidAuthorization,
    hasOxygen: formState.hasOxygen,
    uploadInProgress: !uploadsComplete,
    progress,
  });

  return (
    <CrudForm
      title={strings.CardTitleAdmissionDetails}
      validationSchema={schema}
      onChangeDebounceTime={0}
      fields={fields}
      initialValues={initialData}
      onChange={onChange}
      onSubmit={onSubmit}
      onSecondarySubmit={handleCancel}
      showSecondarySubmitButton
      showSubmitButton
    />
  );
};
