import { PrimitiveValueType, SelectOption, ValueType } from 'components/types';

/**
 * Converts a `ValueType[] | SelectOption[]` to a `ValueType[]`
 */
export const toValueTypeArray = <TValueType extends ValueType = PrimitiveValueType, TData = any>(
  value: TValueType[] | SelectOption<TData, ValueType>[]
): ValueType[] => {
  const hasSelectOptionValue = !!(value[0] as SelectOption)?.value;

  if (hasSelectOptionValue) {
    return (value as SelectOption[]).map((option) => option.value);
  }

  return value as ValueType[];
};

export const toValueType = <TValueType extends ValueType = PrimitiveValueType, TData = any>(
  value: TValueType | SelectOption<TData, TValueType>
): TValueType => (value as SelectOption<TData, TValueType>)?.value || (value as TValueType);
