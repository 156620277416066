import styled from '@emotion/native';
import { Col, Flex } from 'components/base';
import { Spacing, MARGIN } from 'design-system/theme';
import { useResponsive } from 'hooks/useResponsive';
import assign from 'lodash.assign';
import chunk from 'lodash.chunk';
import fill from 'lodash.fill';
import React from 'react';
import { View } from 'react-native';

interface GridProps {
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  xxxl?: number;
  xxxxl?: number;
  gap?: Spacing;
  /**
   * Use a right-to-left population strategy
   */
  rtl?: boolean;
  unsetZIndex?: boolean;
}

interface GapProps {
  gap: Spacing;
}

const Cell = styled(Col)<GapProps>(({ gap }) => ({
  flex: 1,
  margin: MARGIN[gap] / 2,
}));

const Wrapper = styled(Col)<GapProps>(({ gap }) => ({
  flex: 1,
  margin: -1 * (MARGIN[gap] / 2),
}));

export const Grid: React.FC<React.PropsWithChildren<GridProps>> = ({
  // using `sm = 1` ensures that if no props are provided we render a single column
  sm = 1,
  md,
  lg,
  xl,
  xxl,
  xxxl,
  xxxxl,
  gap = 'm',
  rtl = false,
  children,
  unsetZIndex,
}) => {
  const cols = useResponsive(
    { sm, md, lg, xl, xxl, '3xl': xxxl, '4xl': xxxxl },
    // for the purpose of mobile responsiveness we always want to fallback to the `sm` case
    sm
  );

  const nodes = React.Children.toArray(children);

  const rows = chunk(nodes, cols);

  const wrapperDirection = rtl ? 'row-reverse' : 'row';

  return (
    <Wrapper gap={gap} unsetZIndex={unsetZIndex}>
      {rows.map((cells, rowIndex) => {
        // fill cells to prevent incomplete rows from expanding
        const filledCells = assign(fill(new Array(cols), null), cells);
        return (
          <Flex flexDirection={wrapperDirection} key={rowIndex} unsetZIndex={unsetZIndex}>
            {filledCells.map((cell, cellIndex) => (
              <Cell key={cellIndex} gap={gap} unsetZIndex={unsetZIndex}>
                {cell || <View />}
              </Cell>
            ))}
          </Flex>
        );
      })}
    </Wrapper>
  );
};
