import { Datum } from 'components/graph';
import { maxIndex, mean, minIndex } from 'd3-array';
import React from 'react';

import { Summary } from './Summary';
import { SummaryWrapper } from './SummaryWrapper';

interface SummarySectionProps {
  latestValue?: Datum;
  average?: number;
  xFormatter: (value?: number) => string;
  data: Datum[];
  unit?: string;
}

export const SummarySection: React.FC<SummarySectionProps> = ({
  xFormatter,
  latestValue,
  unit,
  data,
}) => {
  const average = mean(data, (d) => d?.y);
  const max = data[maxIndex(data, (d) => d?.y)] as Datum | undefined;
  const min = data[minIndex(data, (d) => d?.y)] as Datum | undefined;

  return (
    <SummaryWrapper>
      <Summary
        title="Latest"
        time={latestValue?.x}
        value={xFormatter(latestValue?.y)}
        unit={unit}
        isLarge
        showTime
      />

      <Summary title="Average" value={xFormatter(average)} unit={unit} />
      <Summary title="Min" value={xFormatter(min?.y)} unit={unit} />
      <Summary title="Max" value={xFormatter(max?.y)} unit={unit} />
    </SummaryWrapper>
  );
};
