/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable global-require */
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { logger } from 'helpers';
import * as React from 'react';
import { Image, Platform } from 'react-native';

const cacheImages = (images: Array<string | number>) =>
  images.map((image) => {
    if (typeof image === 'string') {
      return Image.prefetch(image);
    }
    return Asset.fromModule(image).downloadAsync();
  });

type ImageType = string | number;
type ImageCache = Record<string, ImageType>;
type LoadAllAssetsResponse = void | Asset | boolean;

export const cache: ImageCache =
  Platform.OS === 'web'
    ? {
        logo: require('assets/images/logo.svg'),
      }
    : {
        logo: require('assets/images/logo.png'),
      };

const fonts = {
  'Poppins-Medium': require('../assets/fonts/Poppins-Medium.ttf'),
  'Poppins-Regular': require('../assets/fonts/Poppins-Regular.ttf'),
  'Poppins-SemiBold': require('../assets/fonts/Poppins-SemiBold.ttf'),
};

export const loadAllAssets = (): Promise<LoadAllAssetsResponse>[] => [
  ...cacheImages([cache.logo]),
  Font.loadAsync(fonts),
];

export const useCachedResources = (): boolean => {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync().catch(logger.error);

        await Promise.all(loadAllAssets());
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        logger.error(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync().catch(logger.error);
      }
    }

    loadResourcesAndDataAsync().catch(logger.error);
  }, []);

  return isLoadingComplete;
};
