import { EncounterService } from '@quromedical/fhir-common';
import { Col, Row } from 'components/base';
import { admissionServiceDisplay } from 'core/display';
import { mapDisplaysToOptionsWithStart } from 'core/forms';
import { MonitoringConfiguration, monitoringConfigurationDisplay } from 'core/vitals';
import { Spacer } from 'design-system/base';
import { ComboBoxSingle, TextBox, ToggleButton } from 'design-system/components';
import { Grid } from 'design-system/layout';
import { MARGIN } from 'design-system/theme';
import React from 'react';
import { useDashboardContext } from 'screens/dashboard';
import { strings } from 'strings';

export type Layout = 'grid' | 'table';

export interface SearchData {
  search?: string;
  filter?: EncounterService;
  monitoringConfiguration?: MonitoringConfiguration;
}

interface SearchProps {
  layout: Layout;
}

enum FilterOption {
  ALL = '__all__',
}

export const Search: React.FC<SearchProps> = ({ layout }) => {
  const { setSearch, onLayoutChange, search } = useDashboardContext();

  const onSearchChange = (val: string) => {
    setSearch({ ...search, search: val });
  };

  const onFilterChange = (val: EncounterService | FilterOption) => {
    const resolved = val === FilterOption.ALL ? undefined : val;
    setSearch({ filter: resolved, ...search });
  };

  const onMonitoringChange = (val: MonitoringConfiguration | FilterOption) => {
    const resolved = val === FilterOption.ALL ? undefined : val;
    setSearch({ monitoringConfiguration: resolved, ...search });
  };

  return (
    // grid layout is aligned to the dashboard grid so that the controls line up with the cards
    <Grid sm={1} lg={3} xl={3} xxxxl={4} unsetZIndex>
      <Col>
        <TextBox
          value={search.search}
          onChange={onSearchChange}
          placeholder={strings.TextBoxPlaceholderSearch}
        />
      </Col>
      <Col unsetZIndex>
        <ComboBoxSingle
          value={search.filter}
          onChange={onFilterChange}
          placeholder={strings.AdmissionServiceOptionAll}
          fetcher={mapDisplaysToOptionsWithStart(
            {
              display: strings.AdmissionServiceOptionAll,
              value: FilterOption.ALL,
            },
            admissionServiceDisplay
          )}
        />
      </Col>
      <Row unsetZIndex alignItems="center">
        <Col flex={1} unsetZIndex>
          <ComboBoxSingle
            value={search.monitoringConfiguration}
            placeholder={strings.SortFilterAll}
            fetcher={mapDisplaysToOptionsWithStart(
              {
                display: strings.SortFilterAll,
                value: FilterOption.ALL,
              },
              monitoringConfigurationDisplay
            )}
            onChange={onMonitoringChange}
          />
        </Col>
        <Spacer width={MARGIN.xs} />
        <Col flexShrink={0}>
          <ToggleButton
            rightAlign
            value={layout}
            onChange={onLayoutChange}
            options={[
              {
                value: 'grid',
                icon: 'grid-on',
              },
              {
                value: 'table',
                icon: 'list',
              },
            ]}
          />
        </Col>
      </Row>
    </Grid>
  );
};
