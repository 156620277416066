import Constants from 'expo-constants';
import get from 'lodash.get';

const Env = get(Constants, 'manifest.extra.Env') as EnvironmentVariables;

export const env = {
  apiUrl: Env.FHIR_GATEWAY_URL,
  stage: Env.STAGE,
  sentryDSN: Env.SENTRY_DSN,
  releaseId: Env.CODEBUILD_BUILD_ID,
  vitalsWebsocketUrl: Env.VITALS_WEBSOCKET_URL,
  auth0Domain: `https://${Env.AUTH0_DOMAIN}`,
  auth0ClientId: Env.AUTH0_APP_CLIENT_ID,
  segmentWriteKey: Env.SEGMENT_WRITE_KEY,
  appURL: Env.APP_URL,
};

interface EnvironmentVariables {
  FHIR_GATEWAY_URL: string;
  STAGE: string;
  SENTRY_DSN: string;
  CODEBUILD_BUILD_ID: string;
  VITALS_WEBSOCKET_URL: string;
  AUTH0_DOMAIN: string;
  AUTH0_APP_CLIENT_ID: string;
  SEGMENT_WRITE_KEY: string;
  APP_URL: string;
}
