import styled from '@emotion/native';
import { Col, Row } from 'components/base';
import { BASE_SIZE, MARGIN, PADDING, Spacer, Text, Visible } from 'design-system';
import { Logo } from 'design-system/brand';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const Wrapper = styled.View(({ theme }) => {
  const backgroundColor = theme.color['base-grey'];
  const { top } = useSafeAreaInsets();

  return {
    paddingHorizontal: PADDING['3xl'],
    paddingBottom: PADDING.xs,
    backgroundColor,
    marginTop: top,
  };
});

const StyledCol = styled(Col)({
  maxWidth: BASE_SIZE[576],
});

const ErrorRow = styled(Row)({
  marginTop: MARGIN['2xs'],
  padding: PADDING['2xs'],
});

interface AuthWrapperProps {
  heading: string;
  subheading: string;
  error?: string;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = (props) => {
  const { heading, subheading, children, error = '' } = props;

  return (
    <Row justifyContent="center" alignItems="center">
      <StyledCol flex={1}>
        <Wrapper>
          <Row justifyContent="center" alignItems="center">
            <Logo size={BASE_SIZE[96]} />
          </Row>
          <Spacer height={MARGIN.l} />
          <Col justifyContent="center" alignItems="center">
            <Text variant="page-heading">{heading}</Text>
            <Spacer height={MARGIN['3xs']} />
            <Text variant="caption-subdued">{subheading}</Text>
            <Spacer height={MARGIN.l} />
          </Col>
          <Visible if={!!error}>
            <ErrorRow>
              <Text variant="caption" color="status-critical">
                {error}
              </Text>
            </ErrorRow>
          </Visible>
          <Col>{children}</Col>
        </Wrapper>
      </StyledCol>
    </Row>
  );
};
