import { Notes } from '@quromedical/models';
import { handleDownload } from 'core/media';
import { NoteCard } from 'core/notes';
import { ItemComponent, Section } from 'design-system';
import { logger } from 'helpers';
import { MediaApi } from 'integration';
import { NotesApi } from 'integration/aggregate';
import React, { useCallback } from 'react';

const mediaApi = new MediaApi();
const notesApi = new NotesApi();

export const downloadAttachment = async (id: string) => {
  try {
    const media = await mediaApi.getMedia(id);
    const { url } = media.content;
    if (url) {
      await handleDownload(url);
    }
  } catch (err) {
    logger.error(err);
  }
};

interface CommonProps {
  patientId: string;
  fromPins: boolean;
  canEdit: boolean;
  revalidate?: () => void;
}

export type NoteItemData = Notes.Note & CommonProps;

const StatefulItem: React.FC<NoteItemData> = (item) => {
  const [pinned, setPinned] = React.useState(item.pinned);

  const onPin = useCallback(async () => {
    try {
      logger.track({
        type: 'gamification',
        name: 'note-pin-press',
      });

      // for immediate user feedback
      setPinned(!pinned);
      const nextValue = !item.pinned;
      await notesApi.pinNote(item.patientId, item.id, nextValue);
      item.revalidate?.();
    } catch (err) {
      logger.error(err);
    }
  }, [item, pinned]);

  return (
    <NoteCard
      note={{
        ...item,
        pinned,
      }}
      onAttachmentPress={downloadAttachment}
      canPin={item.canEdit}
      onPin={onPin}
    />
  );
};

export const Item: ItemComponent<NoteItemData> = ({ item }) => (
  // note that for some reason using hooks in a component that's rendered by a list view causes
  // the render to crash, hence the separation of the stateful and stateless components
  <Section hasBottomMargin={false} hasTopMargin={false}>
    <StatefulItem {...item} />
  </Section>
);
