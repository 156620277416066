import { ContentType, isTruthy } from '@quromedical/fhir-common';
import { Media } from '@quromedical/models';
import axios from 'axios';
import { FileResult } from 'components/types';
import { FileKeyProgressMap, LineLoaderStatus, Progress } from 'design-system/components';
import { logger } from 'helpers';
import { useCallback, useRef, useState } from 'react';

interface FormState {
  media: FileResult[];
}

type OnComplete = (media: Media.CreateData[]) => void;
type OnFilesChange<TFormState> = (data: Partial<TFormState>) => void;

interface UseFileUpload<TFormState extends FormState> {
  media: Media.CreateData[];
  progress: FileKeyProgressMap;
  uploadsComplete: boolean;
  onFilesChange: OnFilesChange<TFormState>;
}

export type CreateUploadUrl = (
  body: Media.CreateUploadUrlRequest
) => Promise<Media.CreateUploadUrlResponse>;

// from https://stackoverflow.com/questions/4250364/how-to-trim-a-file-extension-from-a-string-in-javascript
const removeExtension = (name: string) => name.replace(/\.[^/.]+$/, '');

const getUploadUrl = async (file: FileResult, createUploadUrl: CreateUploadUrl) => {
  const fileTitle = removeExtension(file.name);

  const uploadResult = await createUploadUrl({
    // this may be invalid, we need to prevent users from picking these types on the client
    contentType: file.type as ContentType,
    title: fileTitle,
  });

  return uploadResult;
};

const mapMediaToCreateData = (
  files: FileResult[],
  uploadData: Record<string, Media.CreateUploadUrlResponse>
): Media.CreateData[] =>
  files
    .map<Media.CreateData | undefined>((media) => {
      const fileData = uploadData[media.key];

      if (!fileData) {
        return undefined;
      }

      const { key } = fileData;

      return {
        key,
        contentType: media.type as ContentType,
        title: media.name,
      };
    })
    .filter(isTruthy);

const createFormData = async (
  uploadResult: Media.CreateUploadUrlResponse,
  file: FileResult
): Promise<FormData> => {
  const formData = new FormData();

  const keys = Object.keys(uploadResult.formData);

  keys.forEach((key) => formData.append(key, uploadResult.formData[key]));

  // the URI here is a blob-uri, we need this to transform the file into a blob
  const fileData = await fetch(file.uri);
  const blob = await fileData.blob();

  formData.append('file', blob);

  return formData;
};

/**
 * Since we can't guarantee that the progress event will be the same across different platforms
 */
const getProgress = (progressEvent: Partial<ProgressEvent> = {}): Progress | undefined => {
  if (!progressEvent.total) {
    return undefined;
  }

  return {
    total: progressEvent.total,
    value: progressEvent.loaded || 0,
    status: 'loading',
  };
};

/**
 * This method is safe - no need to try/catch it
 */
const uploadToS3 = async (
  uploadResult: Media.CreateUploadUrlResponse,
  file: FileResult,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
  onComplete: () => void,
  onError: () => void
): Promise<void> => {
  try {
    const formData = await createFormData(uploadResult, file);

    await axios.post(uploadResult.url, formData, {
      headers: {
        'Content-Type': ['multipart/formdata', file.type],
      },
      onUploadProgress,
    });

    onComplete();
  } catch (err) {
    logger.error(err);
    onError();
  }
};

/**
 * Checks that `progress.status === 'done'` for each file. If a file has no associated progress then
 * it is regarded as not complete
 */
const isComplete = (files: FileResult[], progress: FileKeyProgressMap): boolean => {
  const keys = files.map((f) => f.key);

  return keys.every((key) => {
    const current = progress[key];

    const isDone = current?.status === 'done';

    return isDone;
  });
};

const useProgressMap = () => {
  const [progress, setProgress] = useState<FileKeyProgressMap>({});

  const setProgressStatus = useCallback((key: string, status: LineLoaderStatus) => {
    setProgress((current) => {
      const file = current[key];

      if (!file) {
        return current;
      }

      return {
        ...current,
        [key]: {
          ...file,
          status,
        },
      };
    });
  }, []);

  const initialize = useCallback(
    (key: string) => () =>
      setProgress({
        ...progress,
        [key]: {
          total: 1,
          value: 0,
          status: 'loading',
        },
      }),
    [progress]
  );
  const complete = useCallback(
    (key: string) => () => setProgressStatus(key, 'done'),
    [setProgressStatus]
  );

  const error = useCallback(
    (key: string) => () => setProgressStatus(key, 'error'),
    [setProgressStatus]
  );

  const load = useCallback(
    (key: string) => (progressEvent: ProgressEvent) => {
      setProgress((current) => ({
        ...current,
        [key]: getProgress(progressEvent),
      }));
    },
    []
  );

  const handler = useCallback(
    (key: string) => ({
      onLoad: load(key),
      onInitialize: initialize(key),
      onComplete: complete(key),
      onError: error(key),
    }),
    [complete, error, initialize, load]
  );

  const exists = useCallback((key: string) => !!progress[key], [progress]);

  return {
    progress,
    exists,
    handler,
  };
};

/**
 * Used for uploading files to S3. Requires a function that can provide the required upload data for
 * the file so that the necessary parameters for uploading are known
 *
 * The `onFilesChanged` can be called as frequently as needed and will ensure that the resulting
 * `media` list is always up-to-date
 *
 * Data should only be uploaded if `uploadsComplete` is true
 */
export const useFileUpload = <TFormState extends FormState>(
  createUploadUrl: CreateUploadUrl,
  onComplete?: OnComplete
): UseFileUpload<TFormState> => {
  const progress = useProgressMap();
  const [uploadData, setUploadData] = useState<Record<string, Media.CreateUploadUrlResponse>>({});
  // files should be a ref since this may be required in scopes that run in async contexts
  const files = useRef<FileResult[]>([]);

  const onFilesChange = useCallback<OnFilesChange<TFormState>>(
    async (data: Partial<TFormState>) => {
      files.current = data.media || [];
      // we need to also call the change if a file is removed which is not accounted below
      onComplete?.(mapMediaToCreateData(files.current, uploadData));

      const uploadTasks = data.media?.map(async (file) => {
        if (!progress.exists(file.key)) {
          const fileHandler = progress.handler(file.key);

          try {
            fileHandler.onInitialize();

            const uploadResult = await getUploadUrl(file, createUploadUrl);

            setUploadData((upload) => {
              const result = {
                ...upload,
                [file.key]: uploadResult,
              };

              // call onComplete here so that we always have the latest state data for uploads
              onComplete?.(mapMediaToCreateData(files.current, result));

              return result;
            });

            await uploadToS3(uploadResult, file, fileHandler.onLoad, fileHandler.onComplete, () =>
              fileHandler.onError()
            );
          } catch (err) {
            fileHandler.onError();
            logger.error(err);
          }
        }
      });

      await Promise.all(uploadTasks || []);
    },
    [createUploadUrl, onComplete, progress, uploadData]
  );

  const media = mapMediaToCreateData(files.current, uploadData);

  const uploadsComplete = isComplete(files.current, progress.progress);

  return {
    media,
    onFilesChange,
    uploadsComplete,
    progress: progress.progress,
  };
};
