// From HealthIcons: https://healthicons.org/icon/outline/symbols/heart_cardiogram
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { Icon } from './internal';

export const HeartCardiogram: Icon = ({ color = '#000000', size = 24 }) => (
  <Svg width={size} height={size} viewBox="0 0 48 48" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3446 11.9881C8.88462 13.846 8 16.3844 8 19.0345C8 20.5722 8.26446 22.0435 8.73002 23.4444H17.4152L20.8032 17.4212L22.9203 24.8308L25.5109 21.5H32V23.5H26.4891L22.0797 29.1692L20.1968 22.5788L18.5848 25.4444H4V23.4444H6.63587C6.23033 22.0457 6 20.5732 6 19.0345C6 15.9592 7.02123 12.9804 8.77202 10.7524C10.5276 8.51823 13.0561 7 16.0312 7C19.5348 7 22.1991 8.96337 24 11.7338C25.8008 8.9635 28.4651 7 31.9688 7C34.9442 7 37.4727 8.5185 39.2282 10.7527C40.9789 12.9809 42 15.9597 42 19.0345C42 25.617 37.4563 31.1139 33.1576 34.866C30.9836 36.7635 28.8144 38.2655 27.1908 39.2925C26.378 39.8067 25.6993 40.2035 25.2217 40.4728C24.9828 40.6075 24.794 40.7105 24.6636 40.7804C24.5985 40.8154 24.5479 40.8421 24.5129 40.8604L24.4724 40.8815L24.4611 40.8873L24.4578 40.8891L24.4567 40.8896C24.4567 40.8896 24.456 40.89 24 40C23.5662 40.901 23.5659 40.9009 23.5659 40.9009L23.5599 40.898L23.546 40.8912L23.4955 40.8664C23.4521 40.8449 23.3892 40.8134 23.3084 40.7723C23.1468 40.6899 22.9133 40.5684 22.6196 40.4091C22.0324 40.0905 21.2032 39.6199 20.2258 39.0068C18.2746 37.7828 15.7151 35.9803 13.3082 33.6741C12.0243 32.444 10.7773 31.0644 9.68759 29.5461C9.36556 29.0974 9.46824 28.4726 9.91692 28.1506C10.3656 27.8286 10.9904 27.9313 11.3124 28.38C12.3169 29.7795 13.4782 31.0672 14.6918 32.23C16.9708 34.4135 19.4113 36.1349 21.2886 37.3125C22.2255 37.9002 23.0177 38.3497 23.5733 38.6511C23.731 38.7367 23.8696 38.8103 23.9868 38.8716C24.0623 38.83 24.1466 38.783 24.2393 38.7307C24.6913 38.4758 25.3407 38.0963 26.1217 37.6023C27.6856 36.613 29.7664 35.1713 31.8424 33.3592C36.0437 29.6922 40 24.7063 40 19.0345C40 16.3848 39.1155 13.8464 37.6556 11.9884C36.2005 10.1365 34.2134 9 31.9688 9C28.866 9 26.4594 11.0208 24.8976 14.2018L24 16.0301L23.1024 14.2018C21.5405 11.0206 19.1339 9 16.0312 9C13.7868 9 11.7997 10.1363 10.3446 11.9881ZM24 40L23.5659 40.9009L24.0139 41.1165L24.4567 40.8896L24 40Z"
      fill={color}
    />
  </Svg>
);
