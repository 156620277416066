import { GroupSecurity } from '@quromedical/fhir-common';
import { Device } from '@quromedical/models';
import { useLinkTo } from '@react-navigation/native';
import { CrudForm } from 'components/form';
import { WithGroup } from 'core/auth';
import {
  columns,
  DeviceTable,
  getDataFromResponse,
  initialSearch,
  searchFields,
} from 'core/device';
import { PaginatedTable } from 'core/table';
import { Section, ToggleCard } from 'design-system';
import { useResponsive } from 'hooks/useResponsive';
import { DeviceApi } from 'integration/aggregate';
import { DeviceStackScreenProps } from 'navigation';
import React, { useCallback, useState } from 'react';
import { ScrollView } from 'react-native';
import { strings } from 'strings';

import { CreateDeviceForm } from './DeviceCreateScreen';

const api = new DeviceApi();

export const DeviceListScreen: React.FC<DeviceStackScreenProps<'List'>> = () => {
  const isStriped = useResponsive({ md: true }, false);

  const [search, setSearch] = useState<Device.GetAllParams>(initialSearch);
  const navigate = useLinkTo();

  const fetcher = (pageToken?: string) =>
    api.getDevices({
      'device-name': search['device-name'] || undefined,
      type: search.type,
      identifier: search.type,
      pageToken,
    });

  const onRowPress = useCallback(
    (row: Partial<DeviceTable>) =>
      navigate({
        screen: 'Admin',
        params: {
          screen: 'Device',
          params: { screen: 'View', params: { id: row.id as string } },
        },
      }),
    [navigate]
  );

  return (
    <ScrollView>
      <WithGroup groups={[GroupSecurity.DeviceCreate]}>
        <Section>
          <ToggleCard
            icon="phone-iphone"
            title={strings.DeviceToggleCardTitle}
            textExpand={strings.DeviceToggleCardExpandButtonText}
            textCollapse={strings.DeviceToggleCardCollapseButtonText}
            contentWrapperProps={{ paddingHorizontal: 0, paddingVertical: 0 }}
          >
            <CreateDeviceForm />
          </ToggleCard>
        </Section>
      </WithGroup>
      <Section unsetZIndex>
        <CrudForm
          fields={searchFields}
          initialValues={initialSearch}
          showSubmitButton={false}
          onChange={setSearch}
        />
      </Section>
      <Section>
        <PaginatedTable
          cacheKey={`Device${JSON.stringify(search)}`}
          fetcher={fetcher}
          columns={columns}
          showHeader={true}
          isStriped={isStriped}
          getDataFromResponse={getDataFromResponse}
          onRowPress={onRowPress}
        />
      </Section>
    </ScrollView>
  );
};
