import { Admission } from '@quromedical/models';
import { PatientApi } from 'integration/aggregate';
import first from 'lodash.first';
import { useCallback } from 'react';
import useSWR from 'swr';

const api = new PatientApi();

export const usePatientActiveAdmission = (
  patientId: string
): Admission.GetResponseWithMedia | undefined => {
  const fetcher = useCallback(
    () =>
      api.getAdmissions(patientId, {
        status: 'in-progress',
        count: 1,
      }),
    [patientId]
  );
  const { data } = useSWR(`patient-active-admission/${patientId}`, fetcher);

  return first(data?.admissions);
};
