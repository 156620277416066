import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Col, Row } from 'components/base';
import { Text, Skeleton } from 'design-system/base';
import { PADDING, MARGIN, BORDER_WIDTH, BASE_SIZE } from 'design-system/theme';
import React from 'react';
import { strings } from 'strings';

interface ItemProps {
  title?: string;
  subtitle?: string;
  time?: boolean;
  backgroundColor?: ColorName;
  textColor?: ColorName;
}

const Wrapper = styled(Row)({
  marginTop: MARGIN['2xs'],
  flex: 1,
});

interface ContentWrapperProps {
  backgroundColor?: ColorName;
}

const ContentWrapper = styled(Row)<ContentWrapperProps>(
  ({ theme, backgroundColor = 'divider' }) => ({
    backgroundColor: theme.color[backgroundColor],
    paddingRight: PADDING.xs,
    alignItems: 'center',
    flex: 1,
  })
);

const SubtitleWrapper = styled(Col)(({ theme }) => ({
  paddingVertical: PADDING['3xs'],
  borderStyle: 'solid',
  borderRightColor: theme.color['base-grey'],
  borderRightWidth: BORDER_WIDTH[1],
  alignItems: 'center',
  justifyContent: 'center',
  width: BASE_SIZE[96],
}));

const TitleWrapper = styled.View({
  paddingVertical: PADDING['3xs'],
  marginLeft: MARGIN.s,
  flex: 1,
});

export const SubHeading: React.FC<ItemProps> = ({
  title = '',
  subtitle = '',
  time = false,
  backgroundColor,
  textColor,
}) => (
  <Wrapper>
    <Skeleton>
      <ContentWrapper backgroundColor={backgroundColor}>
        <SubtitleWrapper isVisible={time}>
          <Text variant="caption-small" color={textColor}>
            {strings.ActionListSubtitleTime}
          </Text>
        </SubtitleWrapper>
        <SubtitleWrapper>
          <Text variant="caption-small" color={textColor}>
            {subtitle}
          </Text>
        </SubtitleWrapper>
        <TitleWrapper>
          <Text variant="caption-small" color={textColor}>
            {title}
          </Text>
        </TitleWrapper>
      </ContentWrapper>
    </Skeleton>
  </Wrapper>
);

export const SubHeadingPlaceholder: React.FC = () => <SubHeading title="_" subtitle="_" />;
