import minBy from 'lodash.minby';

import { Datum } from './types';

const getPythagoreanDistance = (a: Datum, b: Datum) => {
  const xDiff = b.x - a.x;
  const yDiff = b.y - a.y;

  return Math.sqrt(xDiff ** 2 + yDiff ** 2);
};

export const getClosestDatum =
  <TDatum extends Datum>(data: TDatum[]) =>
  (reference: Datum): TDatum | undefined =>
    minBy(data, (datum) => getPythagoreanDistance(reference, datum));

export const mean = (arr: number[]) => {
  if (!arr.length) {
    return 0;
  }

  const sum = arr.reduce((prev, current) => prev + current, 0);

  return sum / arr.length;
};
