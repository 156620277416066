import { ObjectType } from '@quromedical/fhir-common';
import { Group } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { Field } from 'components/types';
import { practitionerFetcher } from 'integration';
import React, { useCallback } from 'react';
import { strings } from 'strings';
import * as yup from 'yup';

interface AddMemberFormProps {
  onSubmit: (data: string[]) => void;
}

interface FormData {
  members: string[];
}

export const initialMembers: Partial<FormData> = {
  members: [],
};

export const createFields = (): Field<FormData>[] => [
  {
    subfields: [
      {
        key: 'members',
        label: strings.FormLabelMembers,
        type: 'combobox-multiple',
        searchable: true,
        initialSelection: [],
        fetcher: practitionerFetcher,
      },
    ],
  },
];

const schema = yup.object<ObjectType<FormData>>({
  members: yup.array(yup.string().required()).required().min(1),
});

export const AddMemberForm: React.FC<AddMemberFormProps> = ({ onSubmit }) => {
  const handleSubmit = useCallback(
    (data: Group.AddMembersRequest) => {
      try {
        onSubmit(data.members || []);
        return {};
      } catch (error) {
        return { error };
      }
    },
    [onSubmit]
  );

  const fields = createFields();

  return (
    <CrudForm
      title={strings.CardTitleAddGroupMembers}
      onSubmit={handleSubmit}
      fields={fields}
      initialValues={initialMembers}
      buttonText="Submit"
      validationSchema={schema}
    />
  );
};
