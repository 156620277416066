import { BaseApi } from './BaseApi';

export class RelayStatusApi {
  private readonly basePath = 'api/relays';
  private readonly client: BaseApi;

  constructor() {
    this.client = new BaseApi();
  }

  public async getStatus(deviceId: string): Promise<RelayStatus> {
    const path = [this.basePath, deviceId, 'status'].join('/');
    const res = await this.client.get<RelayStatus>(path);
    return res.data;
  }
}

export interface RelayStatusField<T> {
  timestamp: number;
  value: T;
}

export interface Notification {
  id: number;
  timestamp: number;
  channel: string;
  title: string;
  content: string;
}

export interface RelayStatus {
  timestamp?: number;

  // PhoneStatusStream
  batteryIsCharging?: RelayStatusField<boolean>;
  batteryPercent?: RelayStatusField<number>;
  bluetoothGattConnectedDevices?: RelayStatusField<string[]>;
  bluetoothIsOn?: RelayStatusField<boolean>;
  ringerIsEnabled?: RelayStatusField<boolean>;
  phoneNumber?: RelayStatusField<string>;
  mbsAppVersion?: RelayStatusField<string>;
  relayId?: RelayStatusField<string>;
  relayAppVersion?: RelayStatusField<string>;
  availableStorageMB?: RelayStatusField<number>;
  androidSoftwareVersion?: RelayStatusField<string>;
  uptimeMillis?: RelayStatusField<number>;

  // MBSAppStatusStream
  mbsAppStatus?: RelayStatusField<string>;

  // NetworkStatusStream
  cellularNetworkType?: RelayStatusField<string>;
  cellularNetworkLevel?: RelayStatusField<number>;
  activeNotifications?: RelayStatusField<Notification[]>;
}
