import { getFullPersonalDetailsRows } from 'core/forms';
import {
  Alert,
  FormCard,
  Section,
  Skeleton,
  SkeletonProvider,
  Snackbar,
  Visible,
} from 'design-system';
import { PatientApi, PatientEndpoint } from 'integration/aggregate';
import React, { useCallback, useState } from 'react';
import { MedicalAidMembershipCard } from 'screens/patient';
import { strings } from 'strings';
import useSWR from 'swr';

interface PatientMedicalAidTabProps {
  patientId: string;
}

const api = new PatientApi();

export const PatientMedicalAidTab: React.FC<PatientMedicalAidTabProps> = ({ patientId }) => {
  const fetcher = () => api.getCoverage(patientId);
  const key = api.getPatientSWRKey(patientId, PatientEndpoint.Coverage);

  const result = useSWR(key, fetcher);

  const [showAlert, setAlert] = useState<boolean>(true);
  const closeAlert = useCallback(() => {
    setAlert(false);
  }, []);

  const { coverage, mainMember } = result.data || {};

  const generalRows = getFullPersonalDetailsRows(mainMember?.general);

  return (
    <SkeletonProvider loading={result.isValidating}>
      <Visible if={!result.error}>
        <Section>
          <Skeleton>
            <MedicalAidMembershipCard {...(coverage?.medicalAid || {})} />
          </Skeleton>
        </Section>

        <Section hasBottomMargin>
          <Skeleton>
            <FormCard title={strings.CardLabelMainMemberDetails} rows={generalRows} />
          </Skeleton>
        </Section>
      </Visible>

      <Visible if={!!result.error}>
        <Snackbar isOpen={showAlert} onClose={closeAlert}>
          <Alert
            actionIcon="close"
            backgroundColor="status-critical"
            onAction={closeAlert}
            title={strings.AlertError}
            body={strings.MedicalAidError}
          />
        </Snackbar>
      </Visible>
    </SkeletonProvider>
  );
};
