import { Media } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import compact from 'lodash.compact';

export enum MediaEndpoint {
  Base = '',
  CreateUploadUrl = 'create-upload-url',
  CreateDownloadUrl = 'download-url',
}

export class MediaApi {
  private readonly client: BaseApi;
  private readonly aggregateRoot = 'api/aggregate/patient';
  constructor() {
    this.client = new BaseApi();
  }

  private createPath(patientId: string, endpoint: string, resourceId?: string) {
    return compact([this.aggregateRoot, patientId, 'media', resourceId, endpoint]).join('/');
  }

  /**
   * Get an SWR Key associated with the `getMedia` function.
   */
  public getMediaSWRKey = (patientId: string, ...endpoint: MediaEndpoint[]): string =>
    this.createPath(patientId, endpoint.join('_'));

  public createUploadUrl = async (
    patientId: string,
    body: Media.CreateUploadUrlRequest
  ): Promise<Media.CreateUploadUrlResponse> => {
    const path = this.createPath(patientId, MediaEndpoint.CreateUploadUrl);

    const result = await this.client.post<Media.CreateUploadUrlResponse>(path, body);

    return result.data;
  };

  public createDownloadUrl = async (
    patientId: string,
    mediaId: string
  ): Promise<Media.CreateDownloadUrlResponse> => {
    const path = this.createPath(patientId, MediaEndpoint.CreateDownloadUrl, mediaId);

    const result = await this.client.get<Media.CreateDownloadUrlResponse>(path);

    return result.data;
  };

  public createMedia = async (
    patientId: string,
    body: Media.CreateRequest
  ): Promise<Media.CreateResponse> => {
    const path = this.createPath(patientId, MediaEndpoint.Base);

    const result = await this.client.post<Media.CreateResponse>(path, body);

    return result.data;
  };

  public getMedia = async (
    patientId: string,
    params: Media.GetAllParams
  ): Promise<Media.GetAllResponse> => {
    const path = this.createPath(patientId, MediaEndpoint.Base);

    const result = await this.client.get<Media.GetAllResponse>(path, { params });

    return result.data;
  };
}
