import { ColorName, useTheme } from '@emotion/react';
import * as Graph from 'components/graph';
import { Margin } from 'components/graph';
import { Visible, Skeleton } from 'design-system/base';
import { dateTimeWithoutYearFormatter, dateWithoutYearFormatter, timeFormatter } from 'helpers';
import { useLayoutDimensions } from 'hooks/useLayoutDimensions';
import React from 'react';
import { View } from 'react-native';

import { ToggleCard, ToggleCardProps } from '../toggle-card';
import { useTooltipProps } from './useTooltipProps';

export interface TimelinePoint extends Graph.Datum {
  text: string;
}

type Formatter = (value: number) => string;

type TimeFormat = 'time' | 'date' | 'date-time';

const timeFormatters: Record<TimeFormat, Formatter> = {
  'date-time': dateTimeWithoutYearFormatter,
  time: timeFormatter,
  date: dateWithoutYearFormatter,
};

interface TimelineCardProps extends ToggleCardProps {
  points: TimelinePoint[];
  xTicks?: number[] | number;
  yTicks?: number[] | number;
  yDomain?: Graph.Domain;
  stops?: Graph.StopProps[];
  color?: ColorName;
  timeFormat?: TimeFormat;
  initialWidth?: number;
  aspectRatio?: number;
  hasGradient?: boolean;
  showScatter?: boolean;
  margin?: Margin;
  valueFormatter: Formatter;
}

export const TimelineCard: React.FC<TimelineCardProps> = ({
  points,
  stops = [],
  valueFormatter,
  initialWidth,
  color = 'text-default',
  timeFormat = 'time',
  aspectRatio = 1,
  xTicks = 5,
  yTicks = 5,
  showScatter = false,
  margin,
  yDomain,
  ...toggleCardProps
}) => {
  const theme = useTheme();
  const { layout, onLayout } = useLayoutDimensions({ initialWidth });
  const { closest, onHover, tooltipText } = useTooltipProps(points);

  const hasGradient = stops.length > 0;

  const resolvedColor = theme.color[color];

  const gradientId = `path-gradient-${resolvedColor}-${Math.random()}`;

  const xFormatter = timeFormatters[timeFormat];

  const domain = Graph.getDomain(points);

  return (
    <ToggleCard {...toggleCardProps}>
      <Skeleton>
        <View onLayout={onLayout}>
          <Graph.LinearPlot
            baseWidth={layout.width}
            aspectRatio={aspectRatio}
            margin={margin}
            useNiceScaleY
            onHoverDebounce={0}
            onHover={onHover}
            // use selecting as a replacement for hovering on native
            onSelectEnd={onHover}
            data={points}
            xDomain={domain.x}
            yDomain={yDomain || domain.y}
            overflow="visible"
          >
            <Visible if={stops}>
              <Graph.LinearGradient
                id={gradientId}
                relativeDomain={domain.y}
                direction="vertical"
                stops={stops}
              />
            </Visible>

            <Graph.Grid
              color={theme.color['text-default']}
              stroke={theme.color.divider}
              yFormatter={valueFormatter}
              xFormatter={xFormatter}
              xTicks={xTicks}
              yTicks={yTicks}
              yAxis
              xAxis
              yGrid
            />

            <Graph.PlotLine
              strokeWidth={2}
              stroke={resolvedColor}
              gradientId={hasGradient ? gradientId : undefined}
              stops={stops}
            />

            <Visible if={showScatter}>
              <Graph.PlotScatter data={points} fill={resolvedColor} radius={3} stops={stops} />
            </Visible>

            {closest ? (
              <>
                <Graph.Point
                  x={closest.x}
                  y={closest.y}
                  fill={theme.color['text-default']}
                  radius={5}
                />
                <Graph.PlotScatter data={[closest]} fill={resolvedColor} radius={3} stops={stops} />
                <Graph.Tooltip
                  x={closest.x}
                  y={closest.y}
                  yOffset={8}
                  xOffset={8}
                  color={theme.color.base}
                  text={tooltipText}
                  backgroundColor={theme.color['text-default']}
                />
              </>
            ) : null}
          </Graph.LinearPlot>
        </View>
      </Skeleton>
    </ToggleCard>
  );
};
