import { Text, Skeleton } from 'design-system/base';
import React from 'react';

export interface SummaryProps {
  total?: number;
  count?: number;
}

export const Summary: React.FC<SummaryProps> = ({ total = 0, count = 0 }) => (
  <Skeleton fullWidth={false}>
    <Text variant="body-strong">
      Showing {total}/{count}
    </Text>
  </Skeleton>
);
