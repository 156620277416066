import { Person, Validations } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { ContactWithOutCoordinates, resolveFormContactFields } from 'core/forms';
import { Fetcher } from 'design-system';
import { useAddressAutoFill } from 'hooks/useAddressAutoFill';
import compact from 'lodash.compact';
import React, { useCallback, useEffect, useState } from 'react';
import { initialContact } from 'screens/person';
import { strings } from 'strings';

interface PersonContactFormCardProps {
  onValidChange: (data?: Person.Contact) => void;
  showErrors: boolean;
  addressFetcher?: Fetcher<string>;
  getAddressPartial?: (
    placeId: string
  ) => Promise<Partial<Person.ContactWithAddressEmergencyAccess>>;
}

export const PersonContactFormCard: React.FC<PersonContactFormCardProps> = ({
  onValidChange,
  showErrors,
  addressFetcher,
  getAddressPartial,
}) => {
  const [formState, setFormState] = useState(initialContact);

  const [initialState, setInitialState] = useState(initialContact);

  const { onContactChange, placeId } = useAddressAutoFill(setFormState, getAddressPartial);
  useEffect(() => {
    if (placeId !== initialState.addressPlaceId) {
      setInitialState({ ...formState, addressPlaceId: placeId });
    }
  }, [placeId, initialState, formState]);

  const onChange = useCallback(
    (data: Partial<Person.Contact>) => {
      const contact = {
        // casting since data is validated
        ...(data as Person.Contact),
        addressLines: compact(data.addressLines),
        telecomPhoneNumbers: compact(data.telecomPhoneNumbers),
      };

      onContactChange(contact);
      if (onValidChange) {
        onValidChange(contact);
      }
    },
    [onValidChange, onContactChange]
  );

  const contactFields = resolveFormContactFields(formState, false, addressFetcher);

  return (
    <CrudForm<ContactWithOutCoordinates>
      key={initialState.addressPlaceId}
      title={strings.FormLabelPersonContactDetails}
      fields={contactFields}
      showSubmitButton={false}
      initialValues={initialState}
      validationSchema={Validations.personContactSchema}
      onChange={onChange}
      validateOnChange
      showErrors={showErrors}
      onChangeDebounceTime={0}
      validateOnMount
      hasCard={false}
    />
  );
};
