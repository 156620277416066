import styled from '@emotion/native';
import { GroupSecurity } from '@quromedical/fhir-common';
import { WithRequired } from '@quromedical/models';
import { FlashList } from '@shopify/flash-list';
import { Col } from 'components/base';
import { UrgentClinicalReviewList } from 'core/vitals';
import { secondsToMilliseconds } from 'date-fns';
import { MARGIN, Skeleton, SkeletonProvider, useResponsiveSectionMargin } from 'design-system';
import { useUserSession } from 'hooks';
import { useResponsive } from 'hooks/useResponsive';
import compact from 'lodash.compact';
import React from 'react';

import { DashboardCard, DashboardCardProps, Layout, Search } from './cards';

const gridItemGap = MARGIN.xs;

const Wrapper = styled(Col)(() => ({
  flex: 1,
  margin: gridItemGap,
}));

// set default itemSize to smallest item item on mobile
const gridItemExpectedBaseSize = 450;

export type DashboardGridData = WithRequired<DashboardCardProps, 'patientId'>;

interface DashboardGridProps {
  data: DashboardGridData[];
  updated: number;
  onLayoutChange: (layout: Layout) => void;
  loading: boolean;
}

interface Updated {
  updated: number;
}

const DashboardCardMemo = React.memo<DashboardCardProps & Updated>(DashboardCard, (prev, next) => {
  const hasPrevData = prev.liveData;
  const hasNextData = next.liveData;

  const onlyNextHasData = hasNextData && !hasPrevData;

  if (onlyNextHasData) {
    return false;
  }

  const age = Date.now() - prev.updated;
  const stale = age > secondsToMilliseconds(10);

  return !stale;
});
interface ListItemProps extends DashboardCardProps {
  listId: string;
}

const loadingData: ListItemProps[] = [
  { listId: 'placeholder_0' },
  { listId: 'placeholder_1' },
  { listId: 'placeholder_2' },
];

export const DashboardGrid: React.FC<DashboardGridProps> = ({
  data,

  updated,
  loading,
}) => {
  const session = useUserSession();
  const numColumns = useResponsive({ sm: 1, md: 2, lg: 2, xl: 3, '4xl': 4 }, 1);

  const canViewDeviceIssues = session.hasAny([GroupSecurity.DeviceUseStatementAdmin]);
  const canViewAIAlerts = session.hasAny([GroupSecurity.FeaturePreviewAI]);
  const canViewEWSAlerts = session.hasAny([GroupSecurity.AlertEWS]);

  const alertItem = canViewEWSAlerts ? 'alert' : undefined;
  const dataWithListId = data.map<ListItemProps>((item) => ({ ...item, listId: item.patientId }));
  const displayData = compact([alertItem, ...dataWithListId]);

  const screenPadding = useResponsiveSectionMargin();
  // subtract the padding from the wrapper so it's not doubled
  const flashListPadding = screenPadding - gridItemGap;

  const listData: (ListItemProps | 'alert')[] = loading ? loadingData : displayData;

  return (
    <SkeletonProvider loading={loading}>
      <FlashList<ListItemProps | 'alert'>
        data={listData}
        renderItem={({ item }) => {
          if (item === 'alert') {
            return (
              <Wrapper>
                <UrgentClinicalReviewList highOnly />
              </Wrapper>
            );
          }

          return (
            <Wrapper>
              <Skeleton>
                <DashboardCardMemo
                  {...item}
                  updated={updated}
                  key={item.patientId}
                  canViewIssues={canViewDeviceIssues}
                  canViewAlerts={canViewAIAlerts}
                />
              </Skeleton>
            </Wrapper>
          );
        }}
        contentContainerStyle={{
          padding: flashListPadding,
        }}
        estimatedItemSize={gridItemExpectedBaseSize}
        horizontal={false}
        numColumns={numColumns}
        ListHeaderComponent={
          <Wrapper>
            <Search layout="grid" />
          </Wrapper>
        }
        ListHeaderComponentStyle={{
          zIndex: 1,
        }}
      />
    </SkeletonProvider>
  );
};
