import { Patient } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';

export class OnboardApi {
  private readonly client: BaseApi;

  private readonly root = 'api/aggregate/patient/onboard';

  private readonly private = `${this.root}/private`;
  private readonly medicalAid = `${this.root}/medical-aid`;

  constructor() {
    this.client = new BaseApi();
  }

  public onboardPrivate = async (
    request: Patient.PrivateOnboardRequest
  ): Promise<Patient.OnboardResponse> => {
    const result = await this.client.post<Patient.OnboardResponse>(this.private, request);

    return result.data;
  };

  public onboardMedicalAid = async (
    request: Patient.MedicalAidOnboardRequest
  ): Promise<Patient.OnboardResponse> => {
    const result = await this.client.post<Patient.OnboardResponse>(this.medicalAid, request);

    return result.data;
  };
}
