import { Row } from 'components/base';
import { IconName, Button } from 'design-system/base';
import React from 'react';

interface SplineAction {
  onPress: () => void;
  icon: IconName;
}

export interface SplineActionProps {
  next?: SplineAction;
  previous?: SplineAction;
  isPlaying?: boolean;
  onPlay?: () => void;
  onStop?: () => void;
}

export const SplineActions: React.FC<SplineActionProps> = ({
  next,
  previous,
  isPlaying = false,
  onPlay,
  onStop,
}) => {
  const showPlayButton = onPlay && !isPlaying;
  const showStopButton = onStop && isPlaying;

  return (
    <Row justifyContent="flex-end" alignItems="center">
      {previous ? (
        <Button
          variant="flat"
          shape="round"
          size="large"
          iconSize="extra-large"
          onPress={previous.onPress}
          icon={previous.icon}
        />
      ) : null}

      {showPlayButton ? (
        <Button
          variant="flat"
          shape="round"
          size="large"
          iconSize="extra-large"
          onPress={onPlay}
          icon="play-arrow"
        />
      ) : null}

      {showStopButton ? (
        <Button
          variant="flat"
          shape="round"
          size="large"
          iconSize="extra-large"
          onPress={onStop}
          icon="stop"
        />
      ) : null}

      {next ? (
        <Button
          variant="flat"
          shape="round"
          size="large"
          iconSize="extra-large"
          onPress={next.onPress}
          icon={next.icon}
        />
      ) : null}
    </Row>
  );
};
