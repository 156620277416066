import { useTheme } from '@emotion/react';
import { Col, Row } from 'components/base';
import { Spacer, Visible, Text, Icon } from 'design-system/base';
import { BASE_SIZE, fadeColor, MARGIN } from 'design-system/theme';
import React, { useCallback } from 'react';
import { Pressable } from 'react-native';
import { strings } from 'strings';

import { getNodeColor, getNodeTextColor } from './internal';
import { ItemPillWrapper } from './ItemPillWrapper';
import { PillInput } from './PillInput';
import { BaseItemPillProps, Comment } from './types';

export type CommentItemPillProps = BaseItemPillProps<Comment>;

export const CommentItemPill: React.FC<CommentItemPillProps> = ({
  node,
  onSelect,
  onUnSelect,
  onTextChange,
}) => {
  const color = getNodeColor(node);
  const textColor = getNodeTextColor(node);
  const theme = useTheme();

  const canPressEdit = node.selected && node.text === undefined;
  const canViewForm = node.selected && node.text !== undefined;

  const emitEmptyText = useCallback(() => onTextChange?.(''), [onTextChange]);
  const emitUndefinedText = useCallback(() => {
    if (node.textRequired) {
      return;
    }

    onTextChange?.(undefined);
  }, [onTextChange, node]);

  const viewRequiredForm = node.selected && node.textRequired;

  return (
    // top level Col ensures that non-editing elements don't also expand
    <Col>
      <ItemPillWrapper color={color} selected={node.selected}>
        <Row alignItems="center" justifyContent="space-between">
          <Pressable onPress={node.selected ? onUnSelect : onSelect}>
            <Text selectable={false} color={textColor} variant="caption">
              {node.display}
            </Text>
          </Pressable>
          <Visible if={canPressEdit || canViewForm}>
            <Spacer width={MARGIN['2xs']} />
          </Visible>
          <Visible if={!node.textRequired}>
            <Visible if={canPressEdit}>
              <Pressable onPress={emitEmptyText}>
                <Icon color={textColor} size={BASE_SIZE[16]} name="add" />
              </Pressable>
            </Visible>
            <Visible if={canViewForm}>
              <Pressable onPress={emitUndefinedText}>
                <Icon color={textColor} size={BASE_SIZE[16]} name="remove" />
              </Pressable>
            </Visible>
          </Visible>
        </Row>

        <Row isVisible={!!(canViewForm || viewRequiredForm)}>
          <PillInput
            backgroundColor={color}
            textColor={textColor}
            value={node.text}
            placeholder={node.textRequired ? strings.NestedFormTextRequired : undefined}
            placeholderTextColor={fadeColor(theme.color[textColor], 0.5)}
            onChangeText={onTextChange}
          />
        </Row>
      </ItemPillWrapper>
    </Col>
  );
};
