import { ChargeItemDefinitionCode, createFhirDateTime } from '@quromedical/fhir-common';
import { ChargeItemDefinition } from '@quromedical/models';

import { ChargeItemDefinition as FormData } from './ChargeItemDefinitionForm';

export const mapFormDataToChargeItemRequest = (
  data: FormData
): ChargeItemDefinition.CreateChargeItemDefinition => {
  return {
    code: ChargeItemDefinitionCode.ClaimLine,
    date: createFhirDateTime(new Date()),
    title: data.title,
    description: data.description,
    chargeLines: data.chargeLines.map((line) => ({
      code: line.chargeCode,
      type: 'base',
      amount: {
        currency: line.currency,
        factor: line.amountFactor,
        value: line.amount,
      },
      applicability: {
        administratorCode: line.type === 'administrator' ? line.identifierCode : undefined,
        schemeCode: line.type === 'scheme' ? line.identifierCode : undefined,
      },
    })),
  };
};
