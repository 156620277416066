import { MedicalAid, Person } from '@quromedical/models';
import { Button, Section, Visible } from 'design-system';
import { FormikErrors } from 'formik';
import { getAddressPartialFetcher, googlePlaceFetcher } from 'integration';
import React, { useState, useCallback } from 'react';
import { MedicalAidMembershipCard, Search } from 'screens/patient';
import { strings } from 'strings';

import { ContactForm } from '../forms';
import { InactiveMembersCard } from './InActiveMembersCard';
import { PatientSelectionCard } from './PatientSelectionCard';

type PatientStepData = {
  search: Search;
  results: MedicalAid.MedicalAidValidationResponse;
  onSubmit: (patient: MedicalAid.MedicalAidPerson) => void;
};

const isActiveMember = (medicalAidMember: Partial<MedicalAid.MedicalAidPerson>): boolean => {
  const isActive = medicalAidMember.medicalAidMembership?.isActive;

  return !!isActive;
};

const isInactiveMember = (medicalAidMember: Partial<MedicalAid.MedicalAidPerson>): boolean =>
  !isActiveMember(medicalAidMember);

export const PatientStep: React.FC<PatientStepData> = ({ results, search, onSubmit }) => {
  const [submitted, setSubmitted] = useState(false);
  const [selectionError, setSelectionError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [selected, setSelected] = useState<Partial<MedicalAid.MedicalAidPerson>>();
  const [contact, setContact] = useState<Partial<Person.Contact>>();

  const onSelectionChange = useCallback(
    (data: Partial<MedicalAid.MedicalAidPerson>, err: boolean) => {
      setSelected(data);
      setSelectionError(err);
    },
    []
  );

  const onContactChange = useCallback(
    (data: Partial<Person.Contact>, err: FormikErrors<Partial<Person.Contact>>) => {
      setContact(data);

      const hasErrors = !!Object.keys(err).length;
      setContactError(hasErrors);
    },
    []
  );

  const hasError = contactError || selectionError;
  const showErrors = submitted && hasError;

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (!hasError) {
      // since we have validated we can safely assume the data type
      const result = { ...selected, contact } as MedicalAid.MedicalAidPerson;
      onSubmit(result);
    }
  }, [contact, hasError, onSubmit, selected]);

  // All members should have the same medical aid membership in this context
  const medicalAid = results[0]?.medicalAidMembership?.medicalAid;

  const activeMembers = results.filter(isActiveMember);
  const inactiveMembers = results.filter(isInactiveMember);

  return (
    <>
      <Section>
        <MedicalAidMembershipCard
          membershipNumber={medicalAid?.membershipNumber}
          plan={medicalAid?.plan}
          schemeName={medicalAid?.schemeName}
        />
      </Section>

      <Visible if={inactiveMembers.length > 0}>
        <Section>
          <InactiveMembersCard inactiveMembers={inactiveMembers} />
        </Section>
      </Visible>

      <Section>
        <PatientSelectionCard
          value={selected}
          results={activeMembers}
          initialIdNumber={search.id}
          onChange={onSelectionChange}
          showErrors={showErrors}
        />
      </Section>
      <Section>
        <ContactForm
          onChange={onContactChange}
          showErrors={showErrors}
          addressFetcher={googlePlaceFetcher}
          getAddressPartial={getAddressPartialFetcher}
        />
      </Section>
      <Section alignItems="flex-end" hasBottomMargin>
        <Button onPress={handleSubmit} text={strings.ButtonTextNext} />
      </Section>
    </>
  );
};
