import styled from '@emotion/native';
import { useTheme } from '@emotion/react';
import { FONT_FAMILY, PADDING } from 'design-system/theme';
import React, { useCallback, useState } from 'react';
import { KeyboardType } from 'react-native';

import { Wrapper } from '../internal';

export interface NumberBoxProps {
  label?: string;
  value?: number;
  placeholder?: string;
  errors?: string;
  isDisabled?: boolean;
  onChange: (value?: number) => void;
  keyboardType?: KeyboardType;
  onSubmitEditing?: () => void;
}

const Input = styled.TextInput(({ theme }) => ({
  paddingVertical: PADDING['2xs'],
  paddingHorizontal: PADDING.xs,
  color: theme.color['text-default'],
  width: '100%',
  fontFamily: FONT_FAMILY.regular,
  fontSize: 14,
}));

export const NumberBox: React.FC<NumberBoxProps> = ({
  label = '',
  value = '',
  placeholder,
  errors,
  isDisabled,
  keyboardType = 'numeric',
  onChange,
  onSubmitEditing,
}) => {
  const theme = useTheme();
  const [focused, setFocus] = useState(false);

  // store a separate text value so that decimals aren't automatically removed
  const [text, setText] = useState(value.toString());

  const handleFocus = useCallback(() => setFocus(true), [setFocus]);
  const handleBlur = useCallback(() => setFocus(false), [setFocus]);

  const handleChange = useCallback(
    (value: string) => {
      // not using parseInt since this will trim away invalid values and pretend value is valid
      const parsed = Number(value);
      if (isNaN(parsed)) {
        setText('');
        onChange(undefined);
      } else {
        setText(value);
        onChange(parsed);
      }
    },
    [onChange]
  );

  const editable = !isDisabled;

  return (
    <Wrapper label={label} error={errors} focused={focused}>
      <Input
        keyboardType={keyboardType}
        onFocus={handleFocus}
        onSubmitEditing={onSubmitEditing}
        onBlur={handleBlur}
        value={text}
        placeholder={placeholder}
        onChangeText={handleChange}
        placeholderTextColor={theme.color['text-disabled']}
        editable={editable}
      />
    </Wrapper>
  );
};
