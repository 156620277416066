import { Admission, Validations } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { Field } from 'components/types';
import { Text } from 'design-system/base';
import { FormikErrors } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { strings } from 'strings';

export type AdmissionAuthorizationData = Pick<Admission.CreateRequest, 'medicalAidAuthorization'>;

interface MedicalAidDetails {
  schemeName?: string;
  planName?: string;
}

type AdmissionAuthorizationFormData = Admission.CreateMedicalAidAuthorization & MedicalAidDetails;

interface AdmissionAuthorizationFormProps extends MedicalAidDetails {
  showErrors?: boolean;
  /**
   * Returns undefined if the data is incomplete or invalid. This is to simplify error checking and
   * validation at a parent level - no data means no valid data
   */
  onValidChange: (data?: AdmissionAuthorizationData) => void;
}

const createFields = (schemeName = '', planName = ''): Field<AdmissionAuthorizationFormData>[] => [
  {
    subfields: [
      {
        key: 'schemeName',
        type: 'custom',
        label: strings.FormLabelScheme,
        Display: () => <Text variant="body-strong">{schemeName}</Text>,
        background: false,
      },
      {
        key: 'planName',
        type: 'custom',
        label: strings.CardLabelPlanAndOption,
        Display: () => <Text variant="body-strong">{planName}</Text>,
        background: false,
      },
    ],
  },
  {
    subfields: [
      {
        key: 'date',
        type: 'masked-text-box',
        label: strings.AdmissionAuthorizationDateLabel,
        maskType: 'date',
      },
      {
        key: 'code',
        type: 'text-box',
        label: strings.AdmissionAuthorizationCodeLabel,
      },
    ],
  },
];

const initialValues: Partial<AdmissionAuthorizationFormData> = {};

export const AdmissionAuthorizationForm: React.FC<AdmissionAuthorizationFormProps> = ({
  onValidChange,
  showErrors,
  planName,
  schemeName,
}) => {
  const fields = useMemo(() => createFields(schemeName, planName), [planName, schemeName]);

  const onChange = useCallback(
    (
      data: Partial<AdmissionAuthorizationFormData>,
      _: FormikErrors<AdmissionAuthorizationFormData>,
      hasErrors: boolean
    ) => {
      if (hasErrors) {
        onValidChange(undefined);
      } else {
        const validated = data as AdmissionAuthorizationFormData;
        onValidChange({
          medicalAidAuthorization: {
            code: validated.code,
            date: validated.date,
          },
        });
      }
    },
    [onValidChange]
  );

  return (
    <CrudForm<AdmissionAuthorizationFormData>
      title={strings.AdmissionAuthorizationFormTitle}
      cardProps={{ unsetZIndex: true }}
      validateOnMount
      initialValues={initialValues}
      fields={fields}
      validationSchema={Validations.admissionRequestSchemaFields.medicalAidAuthorization}
      showErrors={showErrors}
      onChange={onChange}
      onChangeDebounceTime={0}
      showSubmitButton={false}
    />
  );
};
