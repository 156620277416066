import styled from '@emotion/native';
import { Col, Row } from 'components/base';
import { Visible, useSkeletonContext, Button } from 'design-system/base';
import { MARGIN } from 'design-system/theme';
import { useIsSmallerThan } from 'hooks/useResponsive';
import React from 'react';

import { Header, HeaderProps } from '../header';
import { ItemPlaceholder } from './Item';
import { SubHeadingPlaceholder } from './SubHeading';
import { Summary, SummaryProps } from './Summary';

interface ListProps extends HeaderProps, SummaryProps {
  header?: boolean;
}

interface PlaceholderProps {
  header?: boolean;
}

const Placeholder: React.FC<PlaceholderProps> = ({ header }) => (
  <>
    <SubHeadingPlaceholder />
    <ItemPlaceholder small={!header} />
    <ItemPlaceholder small={!header} />
    <ItemPlaceholder small={!header} />
  </>
);

const SummaryWrapper = styled(Row)({
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: MARGIN.s,
});

export const List: React.FC<ListProps> = ({
  blockColor,
  icon,
  iconColor,
  title,
  primaryButtonText: buttonText,
  onPrimaryButtonPress: onPress,
  total,
  count,
  children,
  showPrimaryButton = true,
  header = true,
}) => {
  const { loading } = useSkeletonContext();
  const isSmall = useIsSmallerThan('sm');

  const showSecondaryButton = showPrimaryButton && isSmall;
  const showHeaderButton = showPrimaryButton && !isSmall;

  return (
    <Col flex={1}>
      <Visible if={header}>
        <Header
          primaryButtonText={buttonText}
          title={title}
          onPrimaryButtonPress={onPress}
          icon={icon}
          iconColor={iconColor}
          blockColor={blockColor}
          showPrimaryButton={showHeaderButton}
        />
      </Visible>
      <Col>{loading ? <Placeholder header={header} /> : children}</Col>
      <SummaryWrapper>
        <Visible if={total}>
          <Summary total={total} count={count} />
        </Visible>
        <Visible if={showSecondaryButton}>
          {onPress ? (
            <Button
              variant="flat"
              onPress={onPress}
              iconPosition="right"
              icon="arrow-forward"
              text={buttonText}
            />
          ) : null}
        </Visible>
      </SummaryWrapper>
    </Col>
  );
};
