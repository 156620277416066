import { useCallback, useState } from 'react';

type ToggleFunction = () => void;
type ToggleHook = [boolean, ToggleFunction];

export const useToggle = (initial: boolean): ToggleHook => {
  const [value, setValue] = useState(initial);

  const toggle = useCallback(() => setValue((state) => !state), []);

  return [value, toggle];
};
