import styled from '@emotion/native';
import { Col, Row } from 'components/base';
import { FileResult } from 'components/types';
import { Button } from 'design-system/base';
import { MARGIN } from 'design-system/theme';
import { getDocumentAsync } from 'expo-document-picker';
import { mapItemToFileResult } from 'helpers/file';
import React, { useCallback } from 'react';

import { Wrapper } from '../internal';
import { FileRow } from './FileRow';

export interface MultiFileProps {
  value: FileResult[];
  label: string;
  errors?: string;
  isDisabled?: boolean;
  buttonText?: string;
  onChange: (value: FileResult[]) => void;
}

const RowWrapper = styled(Row)({
  marginTop: MARGIN.s,
});

export const MultiFile: React.FC<MultiFileProps> = ({
  label,
  errors,
  isDisabled,
  buttonText = '',
  onChange,
  value = [],
}) => {
  const handleAdd = useCallback(async () => {
    const document = await getDocumentAsync();

    if (document.type === 'success') {
      const { output } = document;

      if (!output) {
        return;
      }

      const file = mapItemToFileResult(document);

      if (file) {
        onChange([...value, file]);
      }
    }
  }, [onChange, value]);

  const handleDelete = useCallback(
    (index: number) => {
      const filteredValue = value.filter((_, i) => i !== index);

      onChange(filteredValue);
    },
    [value, onChange]
  );

  const handleTitleChange = useCallback(
    (index: number, title: string) => {
      const currentFile = value[index];
      const updatedFile = {
        ...currentFile,
        name: title,
      };

      value[index] = updatedFile;

      onChange(value);
    },
    [onChange, value]
  );

  return (
    <Wrapper label={label} error={errors} background={false} border={false} insetLabel={false}>
      <Col flex={1}>
        {value.map((file, index) => (
          <RowWrapper key={index} fullWidth flex={1}>
            <FileRow
              title={file.name}
              onDelete={() => handleDelete(index)}
              onTitleChange={(title) => handleTitleChange(index, title)}
            />
          </RowWrapper>
        ))}
        <RowWrapper>
          <Button
            onPress={handleAdd}
            disabled={isDisabled}
            text={buttonText}
            variant="outlined"
            icon="attach-file"
          />
        </RowWrapper>
      </Col>
    </Wrapper>
  );
};
