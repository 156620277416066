import { GroupSecurity } from '@quromedical/fhir-common';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useUserSession } from 'hooks/useUserSession';
import { DeviceNavigator } from 'navigation/DeviceNavigator';
import React from 'react';
import { DeviceAssociationScreen } from 'screens/device-association';
import { NotFoundScreen } from 'screens/NotFoundScreen';
import { strings } from 'strings';

import { ChargeItemNavigator } from './ChargeItemNavigator';
import { ClaimNavigator } from './ClaimNavigator';
import { ClaimTemplateNavigator } from './ClaimTemplateNavigator';
import { DrawerNavigatorContent } from './DrawerNavigatorContent';
import { useCommonDrawerProps, hiddenDrawerItemStyle } from './drawerStyles';
import { GroupNavigator } from './GroupNavigator';
import { OrganizationNavigator } from './OrganizationNavigator';
import { PatientOnboardStackNavigator } from './PatientOnboardStackNavigator';
import { PractitionerNavigator } from './PractitionerNavigator';
import { AdminDrawerParamList } from './types';

const Drawer = createDrawerNavigator<AdminDrawerParamList>();

export const adminVisiblePermissions = [
  GroupSecurity.DeviceAdmin,
  GroupSecurity.DeviceCreate,
  GroupSecurity.DeviceUpdate,
  GroupSecurity.PractitionerRead,
  GroupSecurity.GroupRead,
  GroupSecurity.OrganizationRead,
  GroupSecurity.CoverageRead,
  GroupSecurity.OrganizationRead,
  GroupSecurity.PatientCreate,
  GroupSecurity.ChargeItemDefinitionCreate,
];

export const AdminDrawerNavigator: React.FC = () => {
  const drawerProps = useCommonDrawerProps();
  const session = useUserSession();

  /** Will set `hiddenDrawerItemStyle` if the user does not have the group required to view it */
  const visibleIfHas = (group: GroupSecurity) => {
    const isVisible = session.hasAny([group]);

    return isVisible ? undefined : hiddenDrawerItemStyle;
  };

  const deviceItemStyle = visibleIfHas(GroupSecurity.DeviceRead);
  const deviceAssociationItemStyle = visibleIfHas(GroupSecurity.DeviceUseStatementRead);
  const onboardPatientItemStyle = visibleIfHas(GroupSecurity.PatientCreate);
  const practitionerItemStyle = visibleIfHas(GroupSecurity.PractitionerRead);
  const groupItemStyle = visibleIfHas(GroupSecurity.GroupRead);
  const organizationItemStyle = visibleIfHas(GroupSecurity.OrganizationRead);
  const chargeItemDefinitionStyle = visibleIfHas(GroupSecurity.ChargeItemDefinitionRead);
  const claimTemplateStyle = visibleIfHas(GroupSecurity.ClaimTemplateRead);
  const claimStyle = visibleIfHas(GroupSecurity.ClaimRead);

  const hasGroups = session.hasAny(adminVisiblePermissions);

  if (!hasGroups) {
    return <NotFoundScreen />;
  }
  return (
    <Drawer.Navigator
      backBehavior="history"
      screenOptions={drawerProps}
      drawerContent={DrawerNavigatorContent}
    >
      <Drawer.Group>
        <Drawer.Screen
          name="Onboard"
          component={PatientOnboardStackNavigator}
          options={{
            title: 'Onboard patient',
            drawerItemStyle: onboardPatientItemStyle,
          }}
        />
        <Drawer.Screen
          name="Device"
          component={DeviceNavigator}
          options={{
            title: 'Devices',
            drawerItemStyle: deviceItemStyle,
          }}
        />
        <Drawer.Screen
          name="DeviceAssociation"
          component={DeviceAssociationScreen}
          options={{
            title: 'Device Associations',
            drawerItemStyle: deviceAssociationItemStyle,
          }}
        />
        <Drawer.Screen
          name="Practitioner"
          component={PractitionerNavigator}
          options={{
            title: 'Practitioners',
            drawerItemStyle: practitionerItemStyle,
          }}
        />
        <Drawer.Screen
          name="Group"
          component={GroupNavigator}
          options={{
            title: 'Groups',
            drawerItemStyle: groupItemStyle,
          }}
        />
        <Drawer.Screen
          name="Organization"
          component={OrganizationNavigator}
          options={{
            title: 'Organizations',
            drawerItemStyle: organizationItemStyle,
          }}
        />
        <Drawer.Screen
          name="ChargeItem"
          component={ChargeItemNavigator}
          options={{
            title: strings.ChargeItemDefinitionListTitle,
            drawerItemStyle: chargeItemDefinitionStyle,
          }}
        />
        <Drawer.Screen
          name="ClaimTemplate"
          component={ClaimTemplateNavigator}
          options={{
            title: strings.ClaimTemplateListTitle,
            drawerItemStyle: claimTemplateStyle,
          }}
        />
        <Drawer.Screen
          name="Claim"
          component={ClaimNavigator}
          options={{
            title: strings.ClaimListTitle,
            drawerItemStyle: claimStyle,
          }}
        />
      </Drawer.Group>
    </Drawer.Navigator>
  );
};
