import { ColorName } from '@emotion/react';
import { Visible, IconName, Card, CardProps } from 'design-system/base';
import { useIsSmallerThan } from 'hooks/useResponsive';
import { useToggle } from 'hooks/useToggle';
import React from 'react';

import { Header } from '../header';

export interface ToggleCardProps {
  title: string;
  textExpand?: string;
  textCollapse?: string;
  initialExpanded?: boolean;
  blockColor?: ColorName;
  iconColor?: ColorName;
  icon?: IconName;
  iconText?: string;
  /**
   * Any overrides to be added to the internal card. Currently configured as default in order to
   * have a good default content layout
   */
  contentWrapperProps?: CardProps;
  alwaysExpanded?: boolean;
}

export const ToggleCard: React.FC<ToggleCardProps> = ({
  textCollapse,
  textExpand,
  title,
  initialExpanded = false,
  blockColor,
  iconColor = 'text-on-color',
  icon,
  iconText,
  children,
  contentWrapperProps = { unsetZIndex: true },
  alwaysExpanded = false,
}) => {
  const [expanded, toggle] = useToggle(initialExpanded || alwaysExpanded);
  const isSmall = useIsSmallerThan('lg');

  const buttonText = expanded ? textCollapse : textExpand;
  const buttonIcon: IconName = expanded ? 'arrow-drop-up' : 'arrow-drop-down';
  const hideButtonText = !isSmall ? buttonText : undefined;

  return (
    <Card paddingHorizontal={0} paddingVertical={0} unsetZIndex>
      <Header
        title={title}
        primaryButtonText={hideButtonText}
        primaryButtonIcon={buttonIcon}
        blockColor={blockColor}
        iconColor={iconColor}
        icon={icon}
        iconText={iconText}
        onPrimaryButtonPress={toggle}
        showPrimaryButton={!alwaysExpanded}
      />
      <Visible if={expanded}>
        <Card {...contentWrapperProps} unsetZIndex>
          {children}
        </Card>
      </Visible>
    </Card>
  );
};
