import { Logistics } from '@quromedical/models';
import { ColumnType, DataTable } from 'components/table';
import { Alert, Skeleton, SkeletonProvider, Snackbar } from 'design-system';
import { useFiniteState } from 'hooks/useFiniteState';
import { useIsSmallerThan } from 'hooks/useResponsive';
import React from 'react';
import { strings } from 'strings';

interface TableData {
  vehicleType: string;
  price: number;
  serviceType: string;
  transportMode: string;
  trackingNumber: string;
  status: string;
  distance: number;
  providerName: string;
  index: number;
}

const columns: ColumnType<TableData>[] = [
  {
    header: strings.LogisticsVehicleTypeLabel,
    accessor: 'vehicleType',
  } as ColumnType<TableData, 'vehicleType'>,
  {
    header: strings.LogisticsTransportModeLabel,
    accessor: 'transportMode',
  } as ColumnType<TableData, 'transportMode'>,
  {
    header: strings.LogisticsBookingStatus,
    accessor: 'status',
  } as ColumnType<TableData, 'status'>,
  {
    header: strings.LogisticsDistanceLabel,
    accessor: 'distance',
  } as ColumnType<TableData, 'distance'>,
  {
    header: strings.LogisticsPriceLabel,
    accessor: 'price',
  } as ColumnType<TableData, 'price'>,
  {
    header: strings.LogisticsProviderName,
    accessor: 'providerName',
  } as ColumnType<TableData, 'providerName'>,
];

interface BookingTableProps {
  bookings: Logistics.Booking[];
}

type FormState = 'displaying' | 'loading' | 'submissionError';

const mapBookingsToTableData = (bookings: Logistics.Booking[]): TableData[] =>
  bookings.map<TableData>((booking, index) => ({
    distance: booking.distance,
    price: booking.priceRands,
    providerName: booking.providerName,
    status: booking.status,
    trackingNumber: booking.trackingNumber,
    transportMode: booking.transportMode,
    vehicleType: booking.vehicleType,
    serviceType: booking.serviceType,
    index,
  }));

export const BookingTable: React.FC<BookingTableProps> = ({ bookings }) => {
  const state = useFiniteState<FormState>('displaying');
  const isSmall = useIsSmallerThan('sm');

  const tableData = mapBookingsToTableData(bookings);

  const hasError = state.is('submissionError');
  const isLoading = state.is('loading');

  return (
    <>
      <SkeletonProvider loading={isLoading}>
        <Skeleton>
          <DataTable data={tableData} columns={columns} debounceTime={0} useCards={isSmall} />
        </Skeleton>
      </SkeletonProvider>

      <Snackbar onClose={state.next('displaying')} isOpen={hasError}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={state.next('displaying')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.LogisticsQuoteSubmittingError}
        />
      </Snackbar>
    </>
  );
};
