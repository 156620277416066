import React from 'react';

import { TabScreenProps } from './types';

export const Screen: React.FC<TabScreenProps> = ({ isHidden, children }) => {
  if (isHidden) {
    return null;
  }

  return <>{children}</>;
};
