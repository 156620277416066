import { User, UserPreference, Validations } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { Field } from 'components/types';
import { mapDisplaysToOptions } from 'core/forms';
import { Alert, Snackbar, useThemeStateContext } from 'design-system';
import { FormikErrors } from 'formik';
import { logger } from 'helpers';
import { useUserSession } from 'hooks';
import { useFiniteState } from 'hooks/useFiniteState';
import { UserPreferenceApi } from 'integration';
import React, { useCallback } from 'react';
import { strings } from 'strings';

type PreferenceState = 'form' | 'error';

const api = new UserPreferenceApi();

const themeOptions: Record<UserPreference.Theme, string> = {
  dark: strings.FormOptionThemeDark,
  light: strings.FormOptionThemeLight,
  system: strings.FormOptionThemeSystem,
  'contrast-dark': strings.FormOptionThemeContrastDark,
};

const preferenceFields: Field<User.Preference>[] = [
  {
    subfields: [
      {
        key: 'theme',
        type: 'radio-group',
        label: strings.FormLabelTheme,
        options: mapDisplaysToOptions(themeOptions),
      },
    ],
  },
];

export const PreferenceCard: React.FC = () => {
  const theme = useThemeStateContext();

  const session = useUserSession();
  const profile = session.getProfile();

  const preferenceState = useFiniteState<PreferenceState>('form');

  const onPreferenceChange = useCallback(
    async (
      data: User.Preference,
      _: FormikErrors<Partial<User.Preference>>,
      hasErrors: boolean
    ) => {
      const isSame = data.theme === theme.theme;
      if (hasErrors || isSame) {
        return;
      }

      const resolvedTheme = data.theme || theme.theme;

      theme.changeTheme(resolvedTheme);

      try {
        await api.updatePreference({
          theme: resolvedTheme,
        });
        preferenceState.set('form');
      } catch (err) {
        logger.error(err);
        preferenceState.set('error');
      }
    },
    [preferenceState, theme]
  );

  const initialPreferences: User.Preference = {
    theme: profile?.preference.theme || theme.theme,
  };

  return (
    <>
      <Snackbar onClose={preferenceState.next('form')} isOpen={preferenceState.is('error')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={preferenceState.next('form')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.PreferenceUpdateErrorMessage}
        />
      </Snackbar>

      <CrudForm<User.Preference>
        title={strings.FormLabelPreferences}
        validationSchema={Validations.preferenceUpdateSchema}
        onChange={onPreferenceChange}
        onChangeDebounceTime={0}
        showSubmitButton={false}
        initialValues={initialPreferences}
        fields={preferenceFields}
      />
    </>
  );
};
