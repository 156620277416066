import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { FONT_FAMILY } from 'design-system/theme';
import { Animated, TextStyle } from 'react-native';

import { pickVariant, VariantConfig } from '../generic';

export type TextVariant =
  | 'page-heading'
  | 'heading'
  | 'subheading'
  | 'button'
  | 'body'
  | 'body-strong'
  | 'caption'
  | 'caption-subdued'
  | 'caption-small'
  | 'dashboard-value-small'
  | 'dashboard-value-large'
  // these seem to be rather randomly defined in the design, can we use existing values?
  | 'dashboard-title'
  | 'dashboard-subtitle';

interface TextProps {
  variant?: TextVariant;
  /**
   * Key from the color object, will be resolved internally
   */
  color?: ColorName;
}

const variants: VariantConfig<TextVariant, TextStyle> = ({ color }) => ({
  body: {
    fontFamily: FONT_FAMILY.regular,
    fontSize: 14,
  },
  'body-strong': {
    fontFamily: FONT_FAMILY.medium,
    fontSize: 14,
  },
  'caption-subdued': {
    color: color['text-subdued'],
    fontFamily: FONT_FAMILY.regular,
    fontSize: 12,
  },
  'page-heading': {
    fontFamily: FONT_FAMILY.semiBold,
    fontSize: 20,
  },
  button: {
    fontFamily: FONT_FAMILY.medium,
    fontSize: 14,
  },
  caption: {
    fontFamily: FONT_FAMILY.regular,
    fontSize: 12,
  },
  heading: {
    fontFamily: FONT_FAMILY.semiBold,
    fontSize: 18,
  },
  subheading: {
    fontFamily: FONT_FAMILY.medium,
    fontSize: 16,
  },
  'caption-small': {
    fontFamily: FONT_FAMILY.regular,
    fontSize: 10,
  },
  'dashboard-value-small': {
    fontFamily: FONT_FAMILY.semiBold,
    fontSize: 16,
  },
  'dashboard-value-large': {
    fontFamily: FONT_FAMILY.semiBold,
    fontSize: 28,
  },
  'dashboard-title': {
    fontFamily: FONT_FAMILY.medium,
    fontSize: 12,
  },
  'dashboard-subtitle': {
    fontFamily: FONT_FAMILY.regular,
    fontSize: 10,
  },
});

export const Text = styled.Text<TextProps>(
  ({ theme }) => ({
    color: theme.color['text-default'],
  }),
  (props) => pickVariant(variants, props.variant || 'body', props),
  // if color is defined then overwrite the existing color, prefer a color name over a color value
  ({ color, theme }) => (color ? { color: theme.color[color] || color } : {})
);

export const AnimatedText = styled(Animated.Text)<TextProps>(
  ({ theme }) => ({
    color: theme.color['text-default'],
  }),
  (props) => pickVariant(variants, props.variant || 'body', props),
  // if color is defined then overwrite the existing color, prefer a color name over a color value
  ({ color, theme }) => (color ? { color: theme.color[color] || color } : {})
);
