import { Person } from '@quromedical/models';
import { Alert, Fetcher, Skeleton, SkeletonProvider, Snackbar, Visible } from 'design-system';
import { logger } from 'helpers';
import { useFiniteState } from 'hooks/useFiniteState';
import compact from 'lodash.compact';
import React, { useCallback } from 'react';
import { strings } from 'strings';

import { AddressCard, ContactCard } from './card';
import { ContactForm } from './form';

interface PersonContactFormProps {
  canEdit: boolean;
  data?: Partial<Person.ContactWithAddressEmergencyAccess>;
  revalidate?: () => void;
  onSubmit?: (Person: Person.ContactWithAddressEmergencyAccess) => Promise<void>;
  hasEmergencyAccess: boolean;
  addressFetcher?: Fetcher<string>;
  getAddressPartial?: (
    placeId: string
  ) => Promise<Partial<Person.ContactWithAddressEmergencyAccess>>;
  isButtonDisabled?: boolean;
  onMapsPress?: () => void;
}

type FormState = 'initial' | 'editing' | 'submitting' | 'error';

export const PersonContactForm: React.FC<PersonContactFormProps> = ({
  canEdit,
  data,
  revalidate,
  onSubmit,
  hasEmergencyAccess = false,
  addressFetcher,
  getAddressPartial,
  isButtonDisabled = false,
  onMapsPress,
}) => {
  const state = useFiniteState<FormState>('initial');

  const handleSubmit = useCallback(
    async (result: Person.ContactWithAddressEmergencyAccess) => {
      state.set('submitting');
      try {
        const resolvedData = {
          ...result,
          addressLines: compact(result.addressLines),
          telecomPhoneNumbers: compact(result.telecomPhoneNumbers),
        };

        await onSubmit?.(resolvedData);
        state.set('initial');
        revalidate?.();
        return {};
      } catch (error) {
        state.set('error');
        logger.error(error);
        return { error };
      }
    },
    [revalidate, onSubmit, state]
  );

  return (
    <>
      <SkeletonProvider loading={state.is('submitting')}>
        <Skeleton>
          <Visible if={state.isNot('editing')}>
            <ContactCard
              data={data}
              canEdit={canEdit}
              onEditPress={state.next('editing')}
              hasEmergencyAccess={hasEmergencyAccess}
            />
          </Visible>

          <Visible if={state.is('editing')}>
            <ContactForm
              data={data}
              handleCancel={state.next('initial')}
              handleSubmit={handleSubmit}
              hasEmergencyAccess={hasEmergencyAccess}
              addressFetcher={addressFetcher}
              getAddressPartial={getAddressPartial}
            />
          </Visible>
        </Skeleton>

        <Visible if={state.isNot('editing')}>
          <AddressCard data={data} isButtonDisabled={isButtonDisabled} onMapsPress={onMapsPress} />
        </Visible>
      </SkeletonProvider>

      <Snackbar onClose={state.next('editing')} isOpen={state.is('error')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={state.next('editing')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
        />
      </Snackbar>
    </>
  );
};
