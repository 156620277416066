import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import {
  DashboardContextProvider,
  DashboardGridScreen,
  DashboardListScreen,
} from 'screens/dashboard';

import { AppRootParamList, DashboardStackParamList } from './types';

const Stack = createNativeStackNavigator<DashboardStackParamList>();

export const DashboardStackNavigator: React.FC<
  NativeStackScreenProps<AppRootParamList, 'Dashboard'>
> = () => (
  <DashboardContextProvider>
    <Stack.Navigator initialRouteName="Grid" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Grid" component={DashboardGridScreen} />
      <Stack.Screen name="List" component={DashboardListScreen} />
    </Stack.Navigator>
  </DashboardContextProvider>
);
