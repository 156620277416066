import styled from '@emotion/native';
import { Col, Row, withVisibility } from 'components/base';
import { MARGIN, Text } from 'design-system';
import React, { ReactElement } from 'react';

type Content = string | ReactElement;

interface BaseProps {
  title?: Content;
  subtitle?: Content;
  content?: Content;
}

const Wrapper = styled.View({
  marginTop: MARGIN.s,
});

const ContentWrapper = styled(Row)({
  marginTop: MARGIN['2xs'],
});

const Base: React.FC<BaseProps> = ({ title, content, subtitle }) => (
  <Wrapper>
    <Row justifyContent="space-between">
      <Col isVisible={!!title}>
        <Text variant="caption">{title}</Text>
      </Col>
      <Col isVisible={!!title}>
        <Text variant="caption-small">{subtitle}</Text>
      </Col>
    </Row>
    <ContentWrapper isVisible={!!content}>
      {typeof content === 'string' ? <Text variant="body-strong">{content}</Text> : content}
    </ContentWrapper>
  </Wrapper>
);

export const Section = withVisibility<BaseProps>(Base);
