import styled from '@emotion/native';
import { getCreateReferenceId } from '@quromedical/fhir-common';
import { Vitals } from '@quromedical/models';
import { useLinkTo } from '@react-navigation/native';
import { Col, Row } from 'components/base';
import {
  BASE_SIZE,
  BORDER_WIDTH,
  Icon,
  MARGIN,
  PADDING,
  Spacer,
  Text,
  Notification,
} from 'design-system';
import minBy from 'lodash.minby';
import orderBy from 'lodash.orderby';
import React, { useCallback } from 'react';
import { strings } from 'strings';

import { useUrgentReviewList } from './clinical-review';
import { getNEWS2Display, news2RiskColorMap } from './news';

const Wrapper = styled.View({
  marginVertical: MARGIN['2xs'],
  marginHorizontal: MARGIN.xs,
});

const Title = styled(Row)(({ theme }) => ({
  borderColor: theme.color.divider,
  borderStyle: 'solid',
  borderWidth: 0,
  borderTopWidth: BORDER_WIDTH[1],
  paddingTop: PADDING.xs,
  paddingHorizontal: PADDING.xs,
  marginBottom: MARGIN['2xs'],
  marginHorizontal: MARGIN.xs,
}));

export const SidebarAlerts: React.FC = () => {
  const navigate = useLinkTo();

  const onPatientPress = useCallback(
    (id: string) =>
      navigate({
        screen: 'Patient',
        params: {
          screen: 'View',
          params: {
            screen: 'Notes',
            id,
          },
        },
      }),
    [navigate]
  );

  const { admissionRisks } = useUrgentReviewList(true);

  const sortedItems = orderBy(admissionRisks, (item) => item.news.score, 'desc');

  return (
    <Col>
      <Title alignItems="center" justifyContent="flex-start">
        <Icon name="alarm" size={BASE_SIZE[16]} color="text-subdued" />
        <Spacer width={MARGIN.xs} />
        <Text color="text-subdued" variant="caption">
          {strings.TitleUrgentClinicalReviewCritical}
        </Text>
      </Title>

      {sortedItems.map((item) => {
        const { admission, news, vitals } = item;
        const { patient } = admission;

        const id = getCreateReferenceId(patient);
        const display = patient.display || strings.DisplayNotFound;

        const backgroundColor = news2RiskColorMap[news.risk];
        const title = getNEWS2Display(news, true);

        const latestMeasures = Object.values<Vitals.BaseTimeSeriesRecord | undefined>(
          vitals.latest || {}
        );

        // use the oldest time to indicate when the measurement was taken
        const time = minBy(latestMeasures, (v) => v?.ts)?.ts;

        return (
          <Wrapper>
            <Notification
              key={id}
              icon="circle"
              title={title}
              body={display}
              color={backgroundColor}
              time={time}
              onPress={() => onPatientPress(id)}
            />
          </Wrapper>
        );
      })}
    </Col>
  );
};
