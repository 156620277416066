import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Row } from 'components/base';
import { Spacer, Text, Icon, IconName } from 'design-system/base';
import { useGlow } from 'design-system/hooks';
import { BASE_SIZE, BORDER_RADIUS, BORDER_WIDTH, MARGIN, PADDING } from 'design-system/theme';
import React from 'react';
import { Animated } from 'react-native';

export interface MeasureTagsProps {
  items?: MeasureTagProps[];
}

export interface MeasureTagProps {
  title: string;
  icon?: IconName;
  color?: ColorName;
  glow?: boolean;
}

const ItemWrapper = styled(Animated.View)({
  flexDirection: 'row',
  alignItems: 'center',
  borderStyle: 'solid',
  borderWidth: BORDER_WIDTH[1],
  borderRadius: BORDER_RADIUS.full,
  paddingHorizontal: PADDING['2xs'],
  marginTop: MARGIN['3xs'],
  marginRight: MARGIN['3xs'],
  flexGrow: 0,
  maxWidth: '100%',
});

const MeasureTag: React.FC<MeasureTagProps> = ({
  title = '',
  icon,
  color = 'text-default',
  glow = false,
}) => {
  const animatedColor = useGlow({ color, disabled: !glow, minOpacity: 0.5, duration: 750 });

  return (
    <ItemWrapper style={{ borderColor: animatedColor }}>
      <Icon name={icon} color={color} size={BASE_SIZE[12]} />
      <Spacer width={MARGIN['3xs']} />
      <Text color={color} variant="dashboard-subtitle" ellipsizeMode="clip" numberOfLines={1}>
        {title}
      </Text>
    </ItemWrapper>
  );
};

const MainWrapper = styled(Row)({
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  paddingHorizontal: PADDING.xs,
  marginBottom: MARGIN.xs,
});

export const MeasureTags: React.FC<MeasureTagsProps> = ({ items = [] }) => (
  <MainWrapper>
    {items.map((item) => (
      <MeasureTag key={item.title} {...item} />
    ))}
  </MainWrapper>
);
