import { DeviceUseStatementCode, DeviceUseStatementStatus } from '@quromedical/fhir-common';
import { useTableState } from 'components/table';
import { PaginatedTable } from 'core/table';
import { MonitoringConfiguration } from 'core/vitals';
import { DeviceAssociationApi } from 'integration/aggregate';
import React, { useCallback } from 'react';

import { deviceAssociationColumns, getTableDataFromResponse, TableAssociation } from './columns';
import { sortConfig } from './fields';

const api = new DeviceAssociationApi();

interface DeviceUseStatementListProps {
  status?: DeviceUseStatementStatus;
  code?: DeviceUseStatementCode;
  monitoringConfiguration?: MonitoringConfiguration;
  device?: string;
}

export const DeviceUseStatementList: React.FC<DeviceUseStatementListProps> = ({
  status,
  code,
  device,
  monitoringConfiguration,
}) => {
  const swrKey = api.getDeviceAssociationSWRKey();

  const { sort, onChange } = useTableState<TableAssociation>({
    sort: sortConfig,
  });

  const deviceUseStatementFetcher = useCallback(
    (pageToken?: string, count?: number) =>
      api.getDeviceAssociations({
        pageToken,
        count,
        status,
        code,
        // the search should be fully removed if it is empty
        device: device || undefined,
        sort,
      }),
    [status, code, device, sort]
  );

  const cacheKey = [swrKey, status, code, device, sort, monitoringConfiguration];

  return (
    <PaginatedTable
      cacheKey={cacheKey}
      fetcher={deviceUseStatementFetcher}
      columns={deviceAssociationColumns}
      getDataFromResponse={getTableDataFromResponse(monitoringConfiguration)}
      onTableStateChanged={onChange}
      canChangeCount
      // using a high initial count since monitoring configuration is on the client side and this
      // may help avoid some user confusion when compared with an empty table with multiple pages
      initialCount={100}
    />
  );
};
