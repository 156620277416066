import { hoursToMilliseconds, minutesToMilliseconds } from 'date-fns';
import { dateWithoutYearFormatter, dateTimeWithoutYearFormatter, timeFormatter } from 'helpers';

import { Duration } from './fields';

export const durationToMilliseconds: Record<Duration, number> = {
  '5m': minutesToMilliseconds(5),
  '15m': minutesToMilliseconds(15),
  '1h': hoursToMilliseconds(1),
  '6h': hoursToMilliseconds(6),
  '24h': hoursToMilliseconds(24),
  '1w': hoursToMilliseconds(24 * 7),
  '1m': hoursToMilliseconds(24 * 30),
  '3m': hoursToMilliseconds(24 * 30 * 3),
  '6m': hoursToMilliseconds(24 * 30 * 6),
  '1y': hoursToMilliseconds(24 * 30 * 12),
};

export const millisecondsToDuration = Object.fromEntries(
  Object.entries(durationToMilliseconds).map<[number, Duration]>(([key, value]) => [
    value,
    key as Duration,
  ])
);

type Start = Date;
type End = Date;
/**
 * Get a start time given the end and duration
 */
export const getStartFromDuration = (duration: Duration, until: Date): Date => {
  const spread = durationToMilliseconds[duration];

  const now = until.getTime();

  return new Date(now - spread);
};
/**
 * Get start and end times using a given duration and start time. If a `from` time is not provided
 * the time will be calculated with the assumption that the boundary ends at `now`
 */
export const getBoundaryTimes = (duration: Duration, until: Date, from?: Date): [Start, End] => {
  const spread = durationToMilliseconds[duration];
  if (!from) {
    const relative = until.getTime();

    const start = new Date(relative - spread);
    const end = new Date(relative);

    return [start, end];
  }

  const base = from.getTime();
  const end = new Date(base + spread);

  return [from, end];
};

const getLongDurationDisplay = (start: Date, end: Date, duration: Duration): string => {
  if (duration === '24h') {
    const startDisplay = dateWithoutYearFormatter(start);
    const endDisplay = dateWithoutYearFormatter(end);

    return [startDisplay, endDisplay].join('-');
  }

  const startDate = dateTimeWithoutYearFormatter(start);
  const endTime = timeFormatter(end);

  return [startDate, endTime].join('-');
};

const getShortDurationDisplay = (start: Date, end: Date, duration: Duration): string => {
  if (duration === '24h') {
    return dateWithoutYearFormatter(start);
  }

  const startDate = timeFormatter(start);
  const endTime = timeFormatter(end);

  return [startDate, endTime].join('-');
};

export const getDurationDisplay = (
  start: Date,
  end: Date,
  duration: Duration,
  isCondensed: boolean
): string => {
  if (isCondensed) {
    return getShortDurationDisplay(start, end, duration);
  }

  return getLongDurationDisplay(start, end, duration);
};
