import styled from '@emotion/native';
import { useTheme } from '@emotion/react';
import { FONT_FAMILY, PADDING } from 'design-system/theme';
import React, { useCallback, useState } from 'react';
import { KeyboardType } from 'react-native';

import { Wrapper } from '../internal';

export interface TextBoxProps {
  label?: string;
  value?: string;
  placeholder?: string;
  errors?: string;
  isDisabled?: boolean;
  multiline?: boolean;
  onChange: (value: string) => void;
  keyboardType?: KeyboardType;
  onSubmitEditing?: () => void;
}

const Input = styled.TextInput(({ theme }) => ({
  paddingVertical: PADDING['2xs'],
  paddingHorizontal: PADDING.xs,
  color: theme.color['text-default'],
  width: '100%',
  fontFamily: FONT_FAMILY.regular,
  fontSize: 14,
}));

export const TextBox: React.FC<TextBoxProps> = ({
  label = '',
  value = '',
  placeholder,
  errors,
  isDisabled,
  multiline,
  keyboardType = 'default',
  onChange,
  onSubmitEditing,
}) => {
  const theme = useTheme();
  const [focused, setFocus] = useState(false);

  const handleFocus = useCallback(() => setFocus(true), [setFocus]);
  const handleBlur = useCallback(() => setFocus(false), [setFocus]);

  const editable = !isDisabled;

  const numberOfLines = multiline ? 5 : 1;

  return (
    <Wrapper label={label} error={errors} focused={focused}>
      <Input
        keyboardType={keyboardType}
        onFocus={handleFocus}
        onSubmitEditing={onSubmitEditing}
        onBlur={handleBlur}
        value={value}
        placeholder={placeholder}
        onChangeText={onChange}
        placeholderTextColor={theme.color['text-disabled']}
        editable={editable}
        multiline={multiline}
        numberOfLines={numberOfLines}
      />
    </Wrapper>
  );
};
