import { CreateReference } from '@quromedical/fhir-common';
import { Group, Validations } from '@quromedical/models';
import { CrudForm, SubmissionResult } from 'components/form';
import { Field, SelectOption } from 'components/types';
import { mapReferenceToSelectOption } from 'core/forms';
import React from 'react';
import { strings } from 'strings';

import { permissions, typeOptions } from '../config';

interface FormProps {
  data: Partial<Group.CreateRequest>;
  // We're returning a full group because of validation
  handleSubmit: (data: Group.CreateRequest) => Promise<SubmissionResult<unknown>>;
  handleCancel?: () => void;
  organizationFetcher: (query?: string) => Promise<SelectOption[]>;
  title?: string;
  showSecondarySubmitButton?: boolean;
  initialOrganization?: CreateReference;
}

const schema = Validations.groupFormSchema;

export const createFields = (
  organizationFetcher: (query?: string) => Promise<SelectOption[]>,
  initialOrganization?: SelectOption
): Field<Partial<Group.CreateRequest>>[] => [
  {
    subfields: [
      {
        key: 'name',
        label: strings.LabelGroupName,
        placeholder: strings.PlaceHolderGroupName,
        type: 'text-box',
      },
    ],
  },
  {
    subfields: [
      {
        key: 'type',
        label: strings.LabelGroupType,
        initialSelection: [],
        placeholder: strings.PlaceHolderGroupType,
        type: 'combobox-single',
        fetcher: typeOptions,
      },
      {
        key: 'active',
        label: strings.LabelActive,
        placeholder: strings.PlaceHolderSelectOption,
        type: 'combobox-single',
        fetcher: [
          { display: 'True', value: true },
          { display: 'False', value: false },
        ],
      },
    ],
  },
  {
    subfields: [
      {
        type: 'combobox-multiple',
        key: 'permissions',
        label: strings.LabelPermissions,
        placeholder: strings.PlaceHolderAddPermissions,
        fetcher: permissions,
      },
      {
        type: 'combobox-single',
        key: 'organization',
        label: strings.LabelOrganization,
        searchable: true,
        initialSelection: initialOrganization ? [initialOrganization] : [],
        placeholder: strings.PlaceHolderSelectOrganization,
        fetcher: organizationFetcher,
      },
    ],
  },
];

export const Form: React.FC<FormProps> = ({
  data,
  handleCancel,
  handleSubmit,
  organizationFetcher,
  initialOrganization,
  title,
  showSecondarySubmitButton,
}) => {
  const fields = createFields(organizationFetcher, mapReferenceToSelectOption(initialOrganization));
  return (
    <CrudForm
      title={title}
      validationSchema={schema}
      fields={fields}
      initialValues={data}
      onSubmit={handleSubmit}
      onSecondarySubmit={handleCancel}
      showSecondarySubmitButton={showSecondarySubmitButton}
      showSubmitButton
    />
  );
};
