import { DeliveryMethod } from '@quromedical/auth';
import { Col, Row } from 'components/base';
import { Button, IconName, RadioGroup, RadioOption } from 'design-system';
import React, { useEffect, useState } from 'react';
import { strings } from 'strings';

interface DeliveryOptionsProps {
  onSubmit: () => void;
  onChange: (value: DeliveryMethod) => void;
  value?: DeliveryMethod;
  isLoading: boolean;
}

type Icon = IconName | undefined;

const options: RadioOption<DeliveryMethod>[] = [
  {
    display: strings.AuthLabelSMS,
    value: DeliveryMethod.SMS,
  },
  {
    display: strings.AuthLabelEmail,
    value: DeliveryMethod.Email,
  },
];

export const DeliveryOptions: React.FC<DeliveryOptionsProps> = (props) => {
  const { onSubmit, value = DeliveryMethod.SMS, onChange, isLoading } = props;
  const [mode, setMode] = useState<DeliveryMethod>(value);

  useEffect(() => {
    onChange(mode);
  }, [mode, onChange]);

  const buttonText = isLoading ? strings.DisplayLoading : strings.AuthDeliveryMethodButtonText;

  const icon: Icon = isLoading ? undefined : 'arrow-forward';

  return (
    <Col>
      <Row>
        <RadioGroup<DeliveryMethod>
          onChange={setMode}
          options={options}
          value={mode}
          isDisabled={isLoading}
          background={false}
          vertical
          variant="bordered"
        />
      </Row>
      <Row>
        <Button
          onPress={onSubmit}
          disabled={isLoading}
          text={buttonText}
          size="full-width"
          icon={icon}
          iconPosition="right"
        />
      </Row>
    </Col>
  );
};
