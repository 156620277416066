import { Row } from 'components/base';
import { Spinner } from 'design-system';
import React from 'react';

import { Body } from './Body';
import { Wrapper } from './Card';

/**
 * @deprecated new components should not use this and instead use a `Skeleton`
 */
export const LoadingCard: React.FC = () => (
  <Wrapper>
    <Body>
      <Row justifyContent="center">
        <Spinner />
      </Row>
    </Body>
  </Wrapper>
);
