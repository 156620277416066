import { DeviceCode, DeviceStatus } from '@quromedical/fhir-common';
import { Device } from '@quromedical/models';
import { Field, SelectOption } from 'components/types';
import { mapDisplaysToOptions, mapDisplaysToOptionsWithStart } from 'core/forms';
import { partial } from 'lodash';
import { strings } from 'strings';

import { DeviceTable } from './columns';

const deviceStatuses: DeviceStatus[] = ['active', 'entered-in-error', 'inactive', 'unknown'];

const deviceStatusOptions: SelectOption[] = deviceStatuses.map((status) => ({
  display: status as string,
  value: status as string,
}));

const deviceList = async (): Promise<SelectOption[]> => [
  { display: 'Patch', value: DeviceCode.patch },
  { display: 'Relay', value: DeviceCode.relay },
];

const deviceStatusOptionsList = async (): Promise<SelectOption[]> => deviceStatusOptions;

export const fields: Field<DeviceTable>[] = [
  {
    subfields: [
      {
        type: 'text-box',
        key: 'name',
        label: 'Name',
      },
    ],
  },
  {
    subfields: [
      {
        key: 'type',
        label: 'Device Type',
        placeholder: 'Select Type',
        type: 'combobox-single',
        fetcher: deviceList,
      },
      {
        key: 'status',
        label: 'Device Status',
        type: 'combobox-single',
        fetcher: deviceStatusOptionsList,
      },
    ],
  },
];

export const initialSearch: Partial<Device.GetAllParams> = {
  type: undefined,
  'device-name': undefined,
};

export const deviceCodeDisplayMap: Record<DeviceCode, string> = {
  [DeviceCode.patch]: strings.DisplayDeviceTypePatch,
  [DeviceCode.relay]: strings.DisplayDeviceTypeRelay,
  [DeviceCode.glucose]: strings.DisplayDeviceTypeGlucose,
  [DeviceCode.bloodPressure]: strings.DisplayDeviceTypeBloodPressure,
};

export const deviceCodeOptions = mapDisplaysToOptions(deviceCodeDisplayMap);
export const deviceCodeFilterOptions = mapDisplaysToOptionsWithStart(
  {
    display: strings.SortFilterAll,
    value: undefined,
  },
  deviceCodeDisplayMap
);

export const searchFields: Field<Device.GetAllParams>[] = [
  {
    subfields: [
      {
        key: 'device-name',
        label: 'Name',
        placeholder: 'Type to Search',
        type: 'text-box',
      },
      {
        key: 'type',
        label: 'Device Type',
        placeholder: 'Select Type',
        type: 'combobox-single',
        fetcher: deviceCodeFilterOptions,
      },
    ],
  },
];

export const isTypeOfDevice = (type: DeviceCode, device: Device.GetResponse): boolean =>
  device.type === type;

export const isPatch = partial(isTypeOfDevice, DeviceCode.patch);
export const isRelay = partial(isTypeOfDevice, DeviceCode.relay);

export const getDeviceTypeDisplayName = (type?: DeviceCode): string | undefined => {
  if (!type) {
    return undefined;
  }
  return deviceCodeDisplayMap[type];
};
