import { Medication, Medication as Medications } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import { compact } from 'lodash';

export enum MedicationEndpoint {
  Status = 'status',
}

interface updateMedicationStatusParams {
  patientId: string;
  admissionId: string;
  medicationId: string;
  body: Medications.UpdateMedicationRequest;
}

interface PathParams {
  patientId: string;
  admissionId?: string;
  medicationId?: string;
  endpoint?: MedicationEndpoint;
}
export class MedicationApi {
  private readonly client: BaseApi;
  private readonly patientRoute = 'api/aggregate/patient';
  private readonly admissionRoute = 'admission';
  private readonly medicationRoute = 'medication';
  private readonly medicationSearchRoute = 'api/aggregate/medication';

  constructor() {
    this.client = new BaseApi();
  }

  private createPath({ patientId, admissionId }: PathParams) {
    const base = [this.patientRoute, patientId].join('/');
    if (!admissionId) {
      return [base, this.medicationRoute].join('/');
    }
    return [base, this.admissionRoute, admissionId, this.medicationRoute].join('/');
  }

  /**
   * Get an SWR Key associated with the `getMedications` function.
   */
  public getMedicationSWRKey = (
    patientId: string,
    status?: fhir4.MedicationStatement['status']
  ): string => compact([patientId, status]).join('/');

  public searchMedication = async (
    params: Medication.MedicationGetAllParams
  ): Promise<Medications.Medication[]> => {
    const result = await this.client.get<Medications.Medication[]>(this.medicationSearchRoute, {
      params,
    });

    return result.data;
  };

  public async getMedications(
    patientId: string,
    params: Medications.QueryParams
  ): Promise<Medications.GetAllResponse> {
    const path = this.createPath({ patientId });

    const result = await this.client.get<Medications.GetAllResponse>(path, { params });

    return result.data;
  }

  public async createMedication(
    patientId: string,
    admissionId: string,
    body: Medications.CreateRequest
  ): Promise<Medications.CreateResponse> {
    const path = this.createPath({ patientId, admissionId });

    const result = await this.client.post<Medications.CreateResponse>(path, body);

    return result.data;
  }

  public async updateMedicationStatus({
    patientId,
    admissionId,
    medicationId,
    body,
  }: updateMedicationStatusParams): Promise<Medications.UpdateResponse> {
    const path = this.createPath({
      patientId,
      admissionId,
      medicationId,
      endpoint: MedicationEndpoint.Status,
    });

    const result = await this.client.put<Medications.UpdateResponse>(path, body);

    return result.data;
  }
}
