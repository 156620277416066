import styled from '@emotion/native';
import { ErrorBoundary } from 'components/error';
import { env } from 'config/env';
import { AbsoluteProvider } from 'design-system';
import { ThemeState } from 'design-system/theme';
import { useCachedResources } from 'hooks/useCachedResources';
import { AuthApi } from 'integration/AuthApi';
import { LiveDataProvider } from 'integration/live-vitals';
import { RootNavigator } from 'navigation/RootNavigator';
import { UserProvider } from 'providers';
import { MeetingRoomProvider, MeetingRoomDisplayProvider } from 'providers/video';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SafeAreaProvider, useSafeAreaInsets } from 'react-native-safe-area-context';
import { SWRConfig } from 'swr';

const swrConfig = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshWhenOffline: false,
  refreshWhenHidden: false,
  refreshInterval: 0,
};

const authClient = AuthApi.getInstance({
  clientId: env.auth0ClientId,
  domain: env.auth0Domain,
});

const SafeAreaView = styled.View(() => {
  const { height, width } = useWindowDimensions();
  const { top } = useSafeAreaInsets();

  return {
    height,
    width,
    marginTop: top,
  };
});

export const Root: React.FC = () => {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  }

  return (
    <ErrorBoundary>
      <SafeAreaProvider>
        <ThemeState>
          <UserProvider authClient={authClient}>
            <GestureHandlerRootView>
              <LiveDataProvider>
                <MeetingRoomProvider>
                  <AbsoluteProvider>
                    <SafeAreaView>
                      <SWRConfig value={swrConfig}>
                        <MeetingRoomDisplayProvider>
                          <RootNavigator />
                        </MeetingRoomDisplayProvider>
                      </SWRConfig>
                    </SafeAreaView>
                  </AbsoluteProvider>
                </MeetingRoomProvider>
              </LiveDataProvider>
            </GestureHandlerRootView>
          </UserProvider>
        </ThemeState>
      </SafeAreaProvider>
    </ErrorBoundary>
  );
};
