import { Row } from 'components/base';
import { Button, MARGIN, Spacer } from 'design-system';
import { OneTimePinInput } from 'design-system/components';
import { useAuthentication } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { strings } from 'strings';

import { AuthWrapper } from './AuthWrapper';
import { AuthenticationStepProps } from './types';

const optSize = 6;

export const AuthPin: React.FC<AuthenticationStepProps> = (props) => {
  const { onNext, authState } = props;
  const { username } = authState;
  const { mode } = authState;

  const [display, setDisplay] = useState(authState.display);
  const [field, setField] = useState(authState.field);
  const [code, setCode] = useState('');
  const [disabled, setDisabled] = useState(false);
  const { completeAuthentication, startAuthentication, isLoading, error } = useAuthentication();

  const subheading = useMemo(() => {
    const text = strings.AuthOneTimePinSubheading.replace('[field]', field).replace(
      '[username]',
      display
    );

    return text;
  }, [display, field]);

  const buttonText = isLoading ? strings.DisplayLoading : strings.AuthOneTimePinButtonText;

  useEffect(() => {
    setDisplay(authState.display);
    setField(authState.field);
  }, [authState]);

  const reset = useCallback(() => {
    setDisabled(false);
  }, []);
  const onSubmit = useCallback(() => {
    setDisabled(true);
    completeAuthentication({
      data: { code, mode, username },
      onComplete: onNext,
      onError: reset,
    });
  }, [code, onNext, username, mode, completeAuthentication, reset]);

  const onResend = useCallback(() => {
    setDisabled(true);
    startAuthentication({
      data: { mode, username },
      onComplete: (details) => {
        setDisplay(details.username);
        setField(details.field);
        reset();
      },
    });
  }, [username, mode, startAuthentication, reset]);

  useEffect(() => {
    const authenticate = code.length === optSize;
    if (!authenticate) {
      return;
    }
    onSubmit();
  }, [code, onSubmit]);

  return (
    <AuthWrapper heading={strings.AuthOneTimePinHeading} subheading={subheading} error={error}>
      <Row>
        <OneTimePinInput size={optSize} onChange={setCode} disabled={disabled} />
      </Row>
      <Spacer height={MARGIN.m} />
      <Row>
        <Button
          onPress={onResend}
          textColor="primary"
          text={buttonText}
          size="full-width"
          variant="text"
        />
      </Row>
      {/* <Visible if={resent}>
        <DeliveryOptions
          onChange={setMode}
          onSubmit={onResend}
          value={mode}
          isLoading={isLoading}
        />
      </Visible> */}
    </AuthWrapper>
  );
};
