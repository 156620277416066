import { Row } from 'components/base';
import { Text } from 'design-system';
import React from 'react';

import { Body } from './Body';
import { Wrapper } from './Card';

interface Props {
  message: string;
}

/**
 * @deprecated new components should rather use an `Alert` with a `Snackbar`
 */
export const ErrorCard: React.FC<Props> = ({ message }) => (
  <Wrapper>
    <Body>
      <Row justifyContent="center">
        <Text variant="subheading">{message}</Text>
      </Row>
    </Body>
  </Wrapper>
);
