import { useCallback, useRef, useState } from 'react';

type Defined<TState extends {}, TRequiredKey extends keyof TState> = TState &
  Required<Pick<TState, TRequiredKey>>;

const hasRequiredKeys = <
  TState extends {},
  TKey extends keyof TState = keyof TState,
  TRequiredKey extends TKey = TKey
>(
  state: TState,
  requiredKeys: TRequiredKey[]
): state is Defined<TState, TRequiredKey> => {
  const definedValues = requiredKeys.map((key) => state[key]).filter((val) => val !== undefined);

  return definedValues.length === requiredKeys.length;
};

export const getDefinedState = <
  TState extends {},
  TKey extends keyof TState = keyof TState,
  TRequiredKey extends TKey = TKey
>(
  state: TState,
  requiredKeys: TRequiredKey[]
): Defined<TState, TRequiredKey> | undefined => {
  const isValid = hasRequiredKeys<TState, TKey, TRequiredKey>(state, requiredKeys);

  if (!isValid) {
    return undefined;
  }

  return state;
};

type UseObjectStateHook<TState extends {}, TKey extends keyof TState = keyof TState> = {
  value: TState;
  set: <TSelectedKey extends TKey>(key: TSelectedKey) => (value: TState[TSelectedKey]) => void;
  /**
   * If all required key are not defined in the state object will return `undefined` otherwise will
   * return the state value ensuring that all `requiredKeys` are defined
   */
  getDefined: <TRequiredKey extends TKey>(
    requiredKeys: TRequiredKey[]
  ) => Defined<TState, TRequiredKey> | undefined;
};

/**
 * For working with state that may contain separate parts, each with their own setters or getters
 */
export const useObjectState = <TState extends {}, TKey extends keyof TState = keyof TState>(
  initial: TState,
  onChange?: (state: TState) => void
): UseObjectStateHook<TState, TKey> => {
  const [state, setState] = useState(initial);
  const ref = useRef<TState>(initial);

  const set = (key: TKey) => (value: TState[typeof key]) => {
    // need to use a ref to the state since the function will otherwise get bound to a stale state
    // when the `set` method is called - usually this will happen on the first component render
    const newState: TState = {
      ...ref.current,
      [key]: value,
    };

    // need to update the ref as well as the state
    ref.current = newState;
    setState(newState);
    onChange?.(newState);
  };

  const getDefined = useCallback(
    <TRequiredKey extends TKey>(
      requiredKeys: TRequiredKey[]
    ): Defined<TState, TRequiredKey> | undefined => getDefinedState(state, requiredKeys),
    [state]
  );

  return {
    value: state,
    set,
    getDefined,
  };
};
