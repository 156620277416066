import { OrganizationCode } from '@quromedical/fhir-common';
import { Organization } from '@quromedical/models';
import { ColumnType } from 'components/table';
import { Text } from 'design-system';
import React from 'react';
import { strings } from 'strings';

import { getDescription } from '../cards';

export interface Organization {
  id: string;
  name?: string;
  type?: OrganizationCode;
  telecomEmail?: string;
  telecomPhoneNumbers?: string;
  addressLines?: string;
  addressCity?: string;
  addressPostalCode?: string;
}

export type OrganizationTable = Organization;

export const columnsLarge: ColumnType<OrganizationTable>[] = [
  {
    header: strings.DeviceTableNameSubheading,
    Cell: ({ value }) => <Text>{value}</Text>,
    accessor: 'name',
  } as ColumnType<OrganizationTable, 'name'>,
  {
    header: strings.DeviceTableTypeSubheading,
    Cell: ({ value }) => <Text>{getDescription(value) || ''}</Text>,
    accessor: 'type',
  } as ColumnType<OrganizationTable, 'type'>,
  {
    header: strings.Email,
    Cell: ({ value }) => <Text>{value}</Text>,
    accessor: 'telecomEmail',
  } as ColumnType<OrganizationTable, 'telecomEmail'>,
  {
    header: strings.LabelPhone,
    Cell: ({ value }) => <Text>{value}</Text>,
    accessor: 'telecomPhoneNumbers',
  } as ColumnType<OrganizationTable, 'telecomPhoneNumbers'>,
  {
    header: strings.LabelAddress,
    Cell: ({ row }) => (
      <>
        <Text>{row.original.addressLines}</Text>
        <Text>{row.original.addressCity}</Text>
        <Text>{row.original.addressPostalCode}</Text>
      </>
    ),
    accessor: 'addressLines',
  } as ColumnType<OrganizationTable, 'addressLines'>,
];

export const columnsSmall: ColumnType<OrganizationTable>[] = columnsLarge;
