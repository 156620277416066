import styled from '@emotion/native';
import { Col, Flex, Row } from 'components/base';
import { PrimitiveValueType } from 'components/types';
import { Button, IconName } from 'design-system/base';
import { PADDING } from 'design-system/theme';
import React, { ReactElement } from 'react';

import { Wrapper } from '../internal';
import { Item, Variant } from './Item';

export interface RadioOption<TValue> {
  value: TValue;
  display: string;
  disabled?: boolean;
}

type Action = {
  icon: IconName;
  onClick: () => void;
};

export interface RadioGroupProps<TValue extends PrimitiveValueType> {
  options: RadioOption<TValue>[];
  label?: string;
  value?: TValue;
  errors?: string;
  isDisabled?: boolean;
  onChange: (value: TValue) => void;
  action?: Action;
  vertical?: boolean;
  variant?: Variant;
  background?: boolean;
}

const ItemWrapper = styled(Flex)({
  flexWrap: 'wrap',
  maxWidth: '100%',
  width: '100%',
  paddingVertical: PADDING['2xs'],
  paddingHorizontal: PADDING.xs,
});

export const RadioGroup = <TValue extends PrimitiveValueType>({
  options,
  value,
  onChange,
  label,
  errors,
  isDisabled,
  action,
  vertical,
  background = true,
  variant = 'default',
}: RadioGroupProps<TValue>): ReactElement => {
  const itemWrapperDirection = vertical ? 'column' : 'row';
  const itemWrapperAlignment = vertical ? 'flex-start' : 'center';

  return (
    <Wrapper
      label={label}
      error={errors}
      border={false}
      accessibilityRole="radiogroup"
      background={background}
    >
      <Row justifyContent="space-between" alignItems="center" flex={1} fullWidth>
        <ItemWrapper
          flexShrink={1}
          flexDirection={itemWrapperDirection}
          alignItems={itemWrapperAlignment}
        >
          {options.map((option) => {
            const selected = option.value === value;
            return (
              <Item
                vertical={vertical}
                variant={variant}
                key={option.value?.toString()}
                display={option.display}
                selected={selected}
                value={option.value}
                onPress={onChange}
                isDisabled={isDisabled || option.disabled}
              />
            );
          })}
        </ItemWrapper>
        {action ? (
          <Col flexShrink={0}>
            <Button variant="text" icon={action.icon} onPress={action?.onClick} />
          </Col>
        ) : null}
      </Row>
    </Wrapper>
  );
};
