import { Visible } from 'design-system';
import React from 'react';

import { MeetingContent } from './MeetingContent';
import { useMeeting } from './MeetingRoomProvider';

export const MeetingRoomDisplayProvider: React.FC = ({ children }) => {
  const { meetingState } = useMeeting();
  const showMeeting = meetingState.type !== 'out-of-call';

  return (
    <>
      {children}

      <Visible if={showMeeting}>
        <MeetingContent />
      </Visible>
    </>
  );
};
