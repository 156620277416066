import { CrudForm } from 'components/form';
import { Field, SelectOption } from 'components/types';
import { Fetcher } from 'design-system';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

export interface ClaimLine {
  quantity: number;
  chargeItemIdentifier: string;
  chargeItemDisplay: string;
}

export type ChargeItemFetcher = Fetcher<string, unknown>;

interface ClaimLineFormProps {
  showErrors?: boolean;
  value: Partial<ClaimLine>;
  chargeItemFetcher: ChargeItemFetcher;
  onChange: (data: Partial<ClaimLine>, isValid: boolean) => void;
  onRemove?: () => void;
  onAdd?: () => void;
}

interface FormClaimLine extends Omit<ClaimLine, 'chargeItemDisplay' | 'chargeItemIdentifier'> {
  chargeItem: SelectOption<never, string>;
}

const createFields = (
  chargeItemFetcher: Fetcher<string, unknown>,
  selectedChargeItem?: SelectOption<never, string>
): Field<FormClaimLine>[] => {
  return [
    {
      subfields: [
        {
          key: 'quantity',
          type: 'text-box',
          keyboardType: 'decimal-pad',
          label: strings.ClaimLineQuantityLabel,
        },
        {
          key: 'chargeItem',
          type: 'combobox-single',
          label: strings.ClaimLineChargeItemLabel,
          searchable: true,
          fetcher: chargeItemFetcher,
          returnFullOption: true,
          initialSelection: selectedChargeItem ? [selectedChargeItem] : undefined,
        },
      ],
    },
  ];
};

const schema = yup.object<ObjectType<FormClaimLine>>({
  chargeItem: yup.object().required(),
  quantity: yup.number().required().positive().integer(),
});

export const ClaimLineForm: React.FC<ClaimLineFormProps> = ({
  value,
  chargeItemFetcher,
  showErrors = false,
  onChange,
  onRemove,
  onAdd,
}) => {
  const [identifierDisplay, setIdentifierDisplay] = useState<string | undefined>(
    value.chargeItemDisplay
  );
  const handleChange = useCallback(
    (data: Partial<FormClaimLine>) => {
      const newState: Partial<ClaimLine> = {
        // the values passed from the text input do not get converted into a number automatically
        quantity: data.quantity ? +data.quantity : 0,
        chargeItemIdentifier: data.chargeItem?.value,
        chargeItemDisplay: data.chargeItem?.display,
      };

      setIdentifierDisplay(data.chargeItem?.display);

      // revalidating since the derived validation may not be up to date
      const isValid = schema.isValidSync(data);

      onChange(newState, isValid);
    },
    [onChange]
  );

  const selectedItem: SelectOption<never, string> | undefined = value.chargeItemIdentifier
    ? {
        value: value.chargeItemIdentifier,
        display: value.chargeItemDisplay || value.chargeItemIdentifier,
      }
    : undefined;

  const title = identifierDisplay || strings.ClaimLineFormTitle;
  const fields = createFields(chargeItemFetcher, selectedItem);

  const formValue: Partial<FormClaimLine> = {
    ...value,
    chargeItem: selectedItem,
  };

  const onSubmit = useCallback(async () => {
    try {
      onAdd?.();
      return {};
    } catch (error) {
      return { error };
    }
  }, [onAdd]);

  return (
    <CrudForm<FormClaimLine>
      key={formValue.chargeItem?.value}
      title={title}
      hasCard={false}
      onChange={handleChange}
      onChangeDebounceTime={0}
      buttonVariant="outlined"
      fields={fields}
      showErrors={showErrors}
      onSubmit={onSubmit}
      showSubmitButton={!!onAdd}
      buttonText={strings.FormListAddItem}
      onSecondarySubmit={onRemove}
      showSecondarySubmitButton={!!onRemove}
      secondaryButtonText={strings.FormListRemoveItem}
      initialValues={formValue}
      validationSchema={schema}
    />
  );
};
