import { ClaimTemplate } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import compact from 'lodash.compact';

export class ClaimTemplateApi {
  private readonly client: BaseApi;
  private readonly root = 'api/aggregate/medical-aid/claim-template';

  constructor() {
    this.client = new BaseApi();
  }

  private createPath(id?: string) {
    return compact([this.root, id]).join('/');
  }

  public getSWRKey(id?: string) {
    return this.createPath(id);
  }

  public async getClaimTemplates(): Promise<ClaimTemplate.GetAllResponse> {
    const path = this.createPath();
    const result = await this.client.get<ClaimTemplate.GetAllResponse>(path);

    return result.data;
  }

  public async getClaimTemplate(id: string): Promise<ClaimTemplate.GetResponse> {
    const path = this.createPath(id);
    const result = await this.client.get<ClaimTemplate.GetResponse>(path);

    return result.data;
  }

  public async updateClaimTemplate(
    body: ClaimTemplate.UpdateRequest
  ): Promise<ClaimTemplate.UpdateResponse> {
    const path = this.createPath();
    const result = await this.client.put<ClaimTemplate.UpdateResponse>(path, body);

    return result.data;
  }

  public async createClaimTemplate(
    body: ClaimTemplate.CreateRequest
  ): Promise<ClaimTemplate.UpdateResponse> {
    const path = this.createPath();
    const result = await this.client.post<ClaimTemplate.UpdateResponse>(path, body);

    return result.data;
  }
}
