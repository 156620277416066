import { logger } from 'helpers';
import { Linking, Platform } from 'react-native';

export const handleDownload = async (url: string): Promise<void> => {
  if (Platform.OS === 'web') {
    window.open(url);
  } else {
    try {
      await Linking.openURL(url);
    } catch (e) {
      logger.error(e);
    }
  }
};
