import { Patient, Vitals } from '@quromedical/models';
import { removeStaleMeasurements } from 'core/vitals';
import { minutesToMilliseconds } from 'date-fns';
import { useLastRefreshed } from 'hooks/useLastRefreshed';
import {
  PatientApi as PatientAggregateApi,
  PatientEndpoint,
  VitalsApi,
} from 'integration/aggregate';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import useSWR from 'swr';

const patientAggregateApi = new PatientAggregateApi();
const vitalsApi = new VitalsApi();

export interface Times {
  start?: Date;
  duration: number;
}

interface Context {
  id?: string;
  patient?: Patient.GetPatientResponse;
  vitals?: Vitals.VitalsResultMeasures;
  /**
   * The RAW latest vitals data unfiltered by expiration. For filtered data use `latestFiltered`
   */
  latest?: Vitals.LatestResultMeasures;
  /**
   * Vitals data filtered by valid time range/expiry
   */
  latestFiltered?: Vitals.LatestResultMeasures;
  times?: Times;
  resolvedTimes?: BoundaryTimes;
  isValidatingPatient?: boolean;
  isValidatingVitals?: boolean;
  timeFrame?: [number, number];
  lastRefreshed?: Date;
  onRefresh?: () => void;
  setTimes?: (times: Times) => void;
}

type ResolvedContext = Context & Required<Omit<Context, 'patient' | 'aggregate'>>;

const PatientVitalsContext = createContext<Context>({});

interface ProviderProps {
  id: string;
}

export interface BoundaryTimes {
  end: number;
  start: number;
}

export const getBoundaryTimes = (times: Times): BoundaryTimes => {
  const startDate = times.start ? times.start : new Date(Date.now() - times.duration);
  const start = startDate.getTime();

  const end = start + times.duration;

  return { end, start };
};

/**
 * Provider for the overall patient data as well as patient vitals data for the purpose of sharing
 * this data between multiple patient screens and reducing the overall number of times this data
 * needs to be fetched within a context
 */
export const PatientVitalsProvider: React.FC<Required<ProviderProps>> = ({ children, id }) => {
  const key = patientAggregateApi.getPatientSWRKey(id, PatientEndpoint.Base);

  const aggregateFetcher = useCallback(() => patientAggregateApi.getPatient(id), [id]);

  const { data: patient, isValidating: isValidatingPatient } = useSWR(key, aggregateFetcher);

  const initialDuration = minutesToMilliseconds(5);

  const [times, setTimes] = useState<Times>({
    duration: initialDuration,
  });

  const [lastRefreshed, setLastRefreshed] = useState<Date>(new Date());

  useEffect(() => {
    setLastRefreshed(new Date());
    // update the lastRefreshed time every time mins is changed
  }, [times]);

  const onRefresh = useCallback(() => {
    setLastRefreshed(new Date());
    // TODO: somehow make it fetch new data as well
  }, []);

  const { end, start } = getBoundaryTimes(times);

  const fetcher = useCallback(() => {
    if (!times) {
      return undefined;
    }

    return vitalsApi.getVitals(id, {
      end,
      start,
    });
  }, [end, id, start, times]);

  const debouncedFetcher = useAsyncDebounce(fetcher, 1000);

  const { isValidating: isValidatingVitals, data } = useSWR(
    `patient/${id}/vitals/${times.duration}/${
      times.start?.toString() || 'latest'
    }/${lastRefreshed.getTime()}`,
    debouncedFetcher
  );

  const vitals = data?.vitals.type === 'success' ? data.vitals.data : {};
  const latest = data?.latest.type === 'success' ? data.latest.data : {};

  const latestFiltered = removeStaleMeasurements(latest);

  const timeFrame: [number, number] = [start, end];

  const value: ResolvedContext = {
    id,
    patient,
    onRefresh,
    isValidatingPatient,
    isValidatingVitals,
    latest,
    latestFiltered,
    vitals,
    setTimes,
    times,
    timeFrame,
    lastRefreshed,
    resolvedTimes: {
      start,
      end,
    },
  };

  return <PatientVitalsContext.Provider value={value}>{children}</PatientVitalsContext.Provider>;
};

export const usePatientVitals = (): ResolvedContext =>
  useContext(PatientVitalsContext) as ResolvedContext;

/**
 * Subscribe to the age of the vitals data with the provided `refreshRate`
 *
 * > Note that this hook is independent of the `usePatientVitals` hook since using this value will
 * result in a re-render each time the value is updated
 */
export const usePatientVitalsRefreshAge = (refreshRateSeconds?: number): number => {
  const context = usePatientVitals();

  const refreshAge = useLastRefreshed(context.lastRefreshed, refreshRateSeconds);

  return refreshAge;
};
