import { MedicalAid, Patient } from '@quromedical/models';
import { useNavigation } from '@react-navigation/native';
import { CoverageApi, organizationSchemeCodeFetcher } from 'integration';
import { PatientOnboardApi } from 'integration/aggregate';
import { OnboardStackScreenProps } from 'navigation';
import React, { useCallback, useState } from 'react';
import { ScrollView } from 'react-native';

import { JointFields } from '../internal';
import { OnSearch, SchemeSearchForm, SearchResult } from './forms';
import { NavigationToggle } from './navigation';
import { NextOfKinStep, PatientStep } from './steps';

type StepWithData<TKey extends string, TData> = {
  key: TKey;
  data: TData;
};

type StepWithoutData<TKey extends string> = {
  key: TKey;
};

type Step<TKey extends string, TData = undefined> = TData extends undefined
  ? StepWithoutData<TKey>
  : StepWithData<TKey, TData>;

type PatientStepData = SearchResult;

type NextOfKinStepData = {
  search: SearchResult;
  patient: MedicalAid.MedicalAidPerson;
};

type SubmittingStateData = {
  search: SearchResult;
  patient: MedicalAid.MedicalAidPerson;
  nextOfKin: JointFields;
};

// TODO: maybe use XState
type FormStep =
  | Step<'search'>
  | Step<'searching'>
  | Step<'no-search-results'>
  | Step<'patient', PatientStepData>
  | Step<'next-of-kin', NextOfKinStepData>
  | Step<'submitting', SubmittingStateData>
  | Step<'done'>
  | Step<'error', SubmittingStateData>;

const api = new PatientOnboardApi();

// this function will be cleaned up when the state management is aligned to the data needed here
const mapStateToOnboardData = (
  stateData: SubmittingStateData
): Patient.MedicalAidOnboardRequest => ({
  patient: {
    identifierValue: stateData.patient.general.identifierValue as string,
    contact: stateData.patient.contact as Patient.PatientContact,
  },
  coverage: {
    schemeCode: stateData.patient.medicalAidMembership?.medicalAid?.schemeCode as string,
    dependantCode: stateData.patient.medicalAidMembership?.dependantCode as string,
    subscriberId: stateData.patient.medicalAidMembership?.medicalAid?.membershipNumber as string,
  },
  nextOfKin: {
    contact: {
      ...stateData.nextOfKin,
    },
    general: {
      ...stateData.nextOfKin,
    },
  },
});

const coverageApi = new CoverageApi();

export const OnboardMedicalAidScreen: React.FC<OnboardStackScreenProps<'MedicalAid'>> = () => {
  const navigation = useNavigation();
  const [formStep, setFormStep] = useState<FormStep>({
    key: 'search',
  });

  const handleSearchStart = useCallback(() => {
    setFormStep({
      key: 'searching',
    });
  }, []);

  const handleSearchResult = useCallback((result: SearchResult) => {
    setFormStep({
      key: 'patient',
      data: result,
    });
  }, []);

  const handlePatientSubmit = useCallback(
    (patient: MedicalAid.MedicalAidPerson) => {
      if (formStep.key !== 'patient') {
        return;
      }

      setFormStep({
        key: 'next-of-kin',
        data: {
          patient,
          search: formStep.data,
        },
      });
    },
    [formStep]
  );

  const handleNextOfKinSubmit = useCallback(
    async (nextOfKin: JointFields) => {
      if (formStep.key !== 'next-of-kin') {
        return;
      }

      const nextState: FormStep = {
        key: 'submitting',
        data: {
          ...formStep.data,
          nextOfKin,
        },
      };

      setFormStep(nextState);

      // this entire state management here is a but of a mess due to the previous model version,
      // will fix using a better state management solution
      try {
        const onboardData: Patient.MedicalAidOnboardRequest = mapStateToOnboardData(nextState.data);

        // TODO: store the data in the correct types in the sub-steps so we don't have to do this
        const result = await api.onboardMedicalAid(onboardData);

        // navigate to the page for the created patient on completion
        navigation.navigate('Patient', {
          screen: 'View',
          params: {
            screen: 'Profile',
            id: result.patientId,
          },
        });

        setFormStep({
          key: 'done',
        });
      } catch (err) {
        setFormStep({
          key: 'error',
          data: nextState.data,
        });
      }
    },
    [formStep, navigation]
  );

  const hasPatientResults = formStep.key === 'patient' && formStep.data.result.length;

  const onSearch = useCallback<OnSearch>((search) => coverageApi.familyCheck(search), []);

  return (
    <ScrollView>
      <NavigationToggle active="MedicalAid" />

      <SchemeSearchForm
        onResult={handleSearchResult}
        onStart={handleSearchStart}
        onSearch={onSearch}
        schemeFetcher={organizationSchemeCodeFetcher}
      />

      {hasPatientResults ? (
        <PatientStep
          key={formStep.data.search.id + formStep.data.search.schemeDetails.value}
          search={formStep.data.search}
          results={formStep.data.result}
          onSubmit={handlePatientSubmit}
        />
      ) : null}

      {formStep.key === 'next-of-kin' ? (
        <NextOfKinStep
          onSubmit={handleNextOfKinSubmit}
          patient={formStep.data.patient}
          results={formStep.data.search.result}
        />
      ) : null}
    </ScrollView>
  );
};
