import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Flex, Row } from 'components/base';
import { Text } from 'design-system/base';
import { BASE_SIZE, BORDER_WIDTH, MARGIN, PADDING } from 'design-system/theme';
import React from 'react';
import { ScrollView } from 'react-native';

import { NotificationDot } from '../notification-dot';
import { TabWithIndex } from './types';

interface TabsProps {
  tabs: TabWithIndex[];
  activeIndex: number;
  tabWidth?: number;
  onTabPress: (tab: TabWithIndex) => void;
}

interface TabPressableProps {
  isActive: boolean;
  width?: number;
}

const TabPressable = styled.Pressable<TabPressableProps>(({ theme, isActive, width }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  height: BASE_SIZE[48],
  width: width || BASE_SIZE[148],
  paddingHorizontal: PADDING.s,
  borderStyle: 'solid',
  borderColor: isActive ? theme.color.primary : 'transparent',
  borderWidth: 0,
  borderBottomWidth: BORDER_WIDTH[2],
}));

const TabRow = styled(Row)(({ theme }) => ({
  borderBottomColor: theme.color.divider,
  borderBottomWidth: BASE_SIZE[1],
}));

const NotificationWrapper = styled(Flex)({
  marginRight: MARGIN['2xs'],
});

export const Tabs: React.FC<TabsProps> = ({ onTabPress, tabs, activeIndex, tabWidth }) => (
  <TabRow>
    <ScrollView horizontal={true}>
      {tabs
        .filter((option) => !option.isHidden)
        .map((option) => {
          const isSelected = option.index === activeIndex;
          const textColor: ColorName = isSelected ? 'primary' : 'text-default';

          const notificationColor = isSelected ? 'primary' : option.notificationColor;

          const onPress = () => onTabPress(option);
          return (
            <TabPressable
              width={tabWidth}
              key={option.index}
              onPress={onPress}
              isActive={isSelected}
            >
              <NotificationWrapper isVisible={!!option.hasNotification}>
                <NotificationDot color={notificationColor} count={option.notificationCount} />
              </NotificationWrapper>

              <Text numberOfLines={1} color={textColor} variant="button" selectable={false}>
                {option.title}
              </Text>
            </TabPressable>
          );
        })}
    </ScrollView>
  </TabRow>
);
