import { getCreateReferenceId } from '@quromedical/fhir-common';
import { Vitals } from '@quromedical/models';
import { useLinkTo } from '@react-navigation/native';
import { getNEWS2Display, news2RiskColorMap } from 'core/vitals';
import { secondsToMilliseconds } from 'date-fns';
import { ActionList, SkeletonProvider } from 'design-system';
import minBy from 'lodash.minby';
import orderBy from 'lodash.orderby';
import React, { useCallback } from 'react';
import { strings } from 'strings';

import { AdmissionRisk, useUrgentReviewList } from './clinical-review';

interface ListProps {
  isLoading: boolean;
  items: Required<AdmissionRisk>[];
  onPatientPress: (id: string) => void;
  header?: boolean;
  critical?: boolean;
}

interface Updated {
  updated: number;
}

const List: React.FC<ListProps> = ({ isLoading, items, onPatientPress, critical }) => {
  const sortedItems = orderBy(items, (item) => item.news.score, 'desc');

  const blockColor = critical ? 'risk-high' : 'risk-medium';
  const title = critical
    ? strings.TitleUrgentClinicalReviewCritical
    : strings.TitleUrgentClinicalReview;

  return (
    <SkeletonProvider loading={isLoading}>
      <ActionList.List title={title} icon="alarm" blockColor={blockColor}>
        <ActionList.SubHeading subtitle={strings.EWSTitle} title={strings.TitlePatient} />
        {sortedItems.map((item) => {
          const { admission, news, vitals } = item;
          const { patient } = admission;

          const id = getCreateReferenceId(patient);
          const display = patient.display || strings.DisplayNotFound;

          const iconColor = news2RiskColorMap[news.risk];
          const subtitle = getNEWS2Display(news, true);

          const latestMeasures = Object.values<Vitals.BaseTimeSeriesRecord | undefined>(
            vitals.latest || {}
          );

          // use the oldest time to indicate when the measurement was taken
          const time = minBy(latestMeasures, (v) => v?.ts);

          return (
            <ActionList.Item
              key={id}
              id={id}
              time={time?.ts}
              subtitle={subtitle}
              title={display}
              onPress={onPatientPress}
              statusIcon="error-outline"
              statusIconColor={iconColor}
            />
          );
        })}
      </ActionList.List>
    </SkeletonProvider>
  );
};

/**
 * Since there could be many patients' data being updated at the same time, we use a memo to reduce
 * frequent renders
 */
const ListMemo = React.memo<ListProps & Updated>(List, (prev) => {
  const age = Date.now() - prev.updated;
  // using a relatively short memo time since the component isn't very heavy to render
  const stale = age > secondsToMilliseconds(3);
  return !stale;
});

interface UrgentClinicalReviewListProps {
  highOnly?: boolean;
}

export const UrgentClinicalReviewList: React.FC<UrgentClinicalReviewListProps> = ({
  highOnly = false,
}) => {
  const navigate = useLinkTo();

  const onPatientPress = useCallback(
    (id: string) =>
      navigate({
        screen: 'Patient',
        params: {
          screen: 'View',
          params: {
            screen: 'Notes',
            id,
          },
        },
      }),
    [navigate]
  );

  const { admissionRisks, loadingAdmissions, updated } = useUrgentReviewList(highOnly);

  return (
    <ListMemo
      isLoading={loadingAdmissions}
      updated={updated}
      items={admissionRisks}
      onPatientPress={onPatientPress}
      critical={highOnly}
    />
  );
};
