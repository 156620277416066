import { Device, Validations } from '@quromedical/models';
import { Col, Row } from 'components/base';
import { ErrorCard, LoadingCard } from 'components/card';
import * as Card from 'components/card';
import { Button } from 'design-system';
import {
  Notification,
  RelayStatus as Status,
  RelayStatusApi,
  RelayStatusField,
} from 'integration/RelayStatusApi';
import React, { useCallback, useEffect, useState } from 'react';

import { isRelay } from './config';

const api = new RelayStatusApi();

interface RelayStatusProps {
  deviceId: string;
  device?: Device.GetResponse;
}

const networkText = ['Very poor', 'Poor', 'Moderate', 'Good', 'Very good'];

const val = (field?: RelayStatusField<number | string | string[]>) => field?.value.toString();

const isPatchConnected = (field?: RelayStatusField<string[]>) => {
  const patch = field?.value.find((value) => Validations.patchIdRegex.test(value));
  return patch;
};

const getMBSStatus = (field?: RelayStatusField<string>): string | undefined => {
  const mbsStatus = field?.value;
  if (!mbsStatus) {
    return undefined;
  }
  const mbsStatusContainsMonitoring = /monitoring/i.test(mbsStatus);

  return mbsStatusContainsMonitoring
    ? `Patch is not connected, restart device. Last MBS status: ${mbsStatus}`
    : `Patch is not connected, last MBS status: ${mbsStatus}`;
};

const NotificationSection: React.FC<Notification> = ({ title, timestamp, content }) => {
  const subtitle = new Date(timestamp).toLocaleString();

  return <Card.Section title={title} subtitle={subtitle} content={content} />;
};

const sortNotifications = (notificationA: Notification, notificationB: Notification) =>
  notificationB.timestamp - notificationA.timestamp;

export const RelayStatus: React.FC<RelayStatusProps> = ({ deviceId, device }) => {
  const [data, setData] = useState<Status>();
  const [statusError, setStatusError] = useState<boolean>();
  const [expanded, setExpanded] = useState(false);

  const toggleNotifications = useCallback(() => setExpanded(!expanded), [expanded]);
  const isDeviceARelay = device && isRelay(device);

  const updateData = useCallback(() => {
    if (isDeviceARelay) {
      setData(undefined);
      api
        .getStatus(deviceId)
        .then(setData)
        .catch(() => setStatusError(true));
    }
  }, [deviceId, isDeviceARelay]);

  useEffect(updateData, [updateData]);

  if (!isDeviceARelay) {
    return null;
  }

  if (statusError) {
    return <ErrorCard message="Error Loading Relay Status" />;
  }

  if (!data) {
    return <LoadingCard>Loading</LoadingCard>;
  }

  const { timestamp, activeNotifications, uptimeMillis, ...rest } = data;
  const relayId = val(rest.relayId);
  const bluetoothConnections = val(rest.bluetoothGattConnectedDevices) || 'None';

  const mbsAppStatus = isPatchConnected(rest.bluetoothGattConnectedDevices)
    ? val(rest.mbsAppStatus)
    : getMBSStatus(rest.mbsAppStatus);

  const networkQuality = `${networkText[rest.cellularNetworkLevel?.value || 0]} (Level ${
    rest.cellularNetworkLevel?.value
  }, ${rest.cellularNetworkType?.value})`;
  const availableStorage = val(rest.availableStorageMB);
  const relayAppVersion = val(rest.relayAppVersion);
  const phoneNumber = val(rest.phoneNumber);
  const mbsAppVersion = val(rest.mbsAppVersion);

  const hasChargingData = !!rest.batteryIsCharging;
  const isBatteryCharging = rest.batteryIsCharging?.value === true ? 'Charging' : 'Not Charging';
  const chargingMessage = hasChargingData ? isBatteryCharging : 'Unknown';

  const batteryPercentage = `${val(rest.batteryPercent)}% (${chargingMessage})`;

  const updatedDate = timestamp ? new Date(timestamp).toLocaleString() : '';
  const rebootDate =
    timestamp && uptimeMillis?.value
      ? new Date(timestamp - uptimeMillis.value).toLocaleString()
      : '';

  const sortedNotifications = activeNotifications?.value.sort(sortNotifications);
  const toggleButtonText = expanded ? 'Collapse' : 'Expand';

  const notifications = sortedNotifications?.map((n) => (
    <NotificationSection {...n} key={`${n.channel}__${n.id}`} />
  ));

  return (
    <Card.Wrapper>
      <Card.Body>
        <Row flex={1}>
          <Col flex={1}>
            <Card.Section title="Last Updated" content={updatedDate} />
            <Card.Section title="Relay ID" content={relayId} />
            <Card.Section title="MBS App Status" content={mbsAppStatus} />
            <Card.Section title="Network Quality" content={networkQuality} />
          </Col>
          <Col flex={1}>
            <Card.Section title="Phone Battery" content={batteryPercentage} />
            <Card.Section title="Bluetooth Connections" content={bluetoothConnections} />
            <Card.Section title="Relay App Version" content={relayAppVersion} />
            <Card.Section title="Last Reboot" content={rebootDate} />
          </Col>
          <Col flex={1}>
            <Card.Section title="Available Storage (MB)" content={availableStorage} />
            <Card.Section title="Phone Number" content={phoneNumber} />
            <Card.Section title="MBS App Version" content={mbsAppVersion} />
          </Col>
        </Row>
        <Card.Section
          title="Notifications"
          content={<Button text={toggleButtonText} onPress={toggleNotifications} />}
        />
        {expanded && notifications}
      </Card.Body>
      <Card.Footer Right={() => <Button text="Refresh" onPress={updateData} />} />
    </Card.Wrapper>
  );
};
