import { ClaimTypeCode } from '@quromedical/fhir-common';
import { strings } from 'strings';

export const claimTypeDisplay: Record<ClaimTypeCode, string> = {
  [ClaimTypeCode.HospitalAtHome]: strings.AdmissionServiceHospitalAtHome,
  [ClaimTypeCode.LACE]: strings.AdmissionServiceLACE,
  [ClaimTypeCode.RemotePatientMonitoring]: strings.AdmissionServiceRemotePatientMonitoring,
  [ClaimTypeCode.Arthroplasty]: strings.AdmissionServiceArthroplasty,
  [ClaimTypeCode.HealingHands]: strings.AdmissionServiceHealingHands,
  [ClaimTypeCode.InHospitalMonitoring]: strings.AdmissionServiceInHospitalMonitoring,
  [ClaimTypeCode.CareAtHome]: strings.AdmissionServiceCareAtHome,
};
