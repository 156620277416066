import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Text } from 'design-system/base';
import { PADDING, BORDER_RADIUS } from 'design-system/theme';
import React from 'react';

interface TagProps {
  text: string;
  color: ColorName;
  textColor?: ColorName;
}

interface WrapperProps {
  color: ColorName;
}

const Wrapper = styled(Text)<React.PropsWithChildren<WrapperProps>>(({ color, theme }) => ({
  backgroundColor: theme.color[color],
  paddingHorizontal: PADDING['3xs'],
  borderRadius: BORDER_RADIUS[4],
}));

export const Tag: React.FC<TagProps> = ({ color, text = '', textColor = 'text-on-color' }) => (
  <Wrapper color={color}>
    <Text color={textColor} variant="caption-small">
      {text}
    </Text>
  </Wrapper>
);
