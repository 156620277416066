import styled from '@emotion/native';
import { Col } from 'components/base';
import { Absolute } from 'design-system/layout';
import { useLayoutDimensions } from 'hooks/useLayoutDimensions';
import React from 'react';
import { useWindowDimensions } from 'react-native';

const ContentWrapper = styled(Col)(() => {
  const { width, height } = useWindowDimensions();
  const { layout } = useLayoutDimensions({
    initialHeight: height,
    initialWidth: width,
  });

  const safeHeight = layout.height * 0.8;
  const contentWidth = layout.width < safeHeight ? layout.width : safeHeight;

  const screenFactor = 0.5;

  const maxWidth = contentWidth * screenFactor;

  return {
    width: maxWidth,
  };
});

export const InCallMinimized: React.FC = ({ children }) => (
  <Absolute
    mode="contained"
    name="call-wrapper-minimized"
    position={{ top: 'unset', left: 'unset', bottom: 20, right: 30 }}
  >
    <ContentWrapper>{children}</ContentWrapper>
  </Absolute>
);
