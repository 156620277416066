import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { logger } from 'helpers';
import { Platform } from 'react-native';

export const registerForPushNotifications = async (): Promise<
  Notifications.ExpoPushToken | undefined
> => {
  if (Platform.OS === 'web') {
    // push notifications not supported on web
    return undefined;
  }

  // device-specific config may cause this to fail
  try {
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;

      Notifications.setNotificationHandler({
        handleNotification: () =>
          Promise.resolve({
            shouldShowAlert: true,
            shouldPlaySound: true,
            shouldSetBadge: false,
          }),
      });

      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }

      if (finalStatus !== 'granted') {
        logger.error('Failed to get push token for push notification!');
        return undefined;
      }

      const token = await Notifications.getExpoPushTokenAsync();

      if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: '#EE847C',
        }).catch(logger.error);
      }

      return token;
    }
    logger.error('Push notifications not supported');
  } catch (err) {
    logger.error('Error registering for push notifications', err as Record<string, unknown>);
  }

  return undefined;
};
