import { Medication } from '@quromedical/models';
import {
  getDataFromResponse,
  medicationStatementColumns,
  MedicationTableData,
} from 'core/medication';
import { PaginatedTable } from 'core/table';
import { useResponsive } from 'hooks/useResponsive';
import { MedicationApi } from 'integration/aggregate';
import React, { useCallback } from 'react';

const api = new MedicationApi();

interface MedicationsTableProps {
  patientId: string;
  admissionId: string;
}

export const MedicationTable: React.FC<MedicationsTableProps> = ({ patientId, admissionId }) => {
  const isStriped = useResponsive({ md: true }, false);
  const fetcher = useCallback(
    (pageToken?: string, count?: number) => {
      return api.getMedications(patientId, { count, pageToken, status: 'active' });
    },
    [patientId]
  );

  const swrKey = api.getMedicationSWRKey(patientId);

  const columns = medicationStatementColumns(patientId, admissionId);
  return (
    <PaginatedTable<Partial<MedicationTableData>, Medication.GetAllResponse>
      cacheKey={`medication-table-${swrKey}`}
      fetcher={fetcher}
      columns={columns}
      showHeader={true}
      isStriped={isStriped}
      getDataFromResponse={getDataFromResponse}
      canChangeCount
    />
  );
};
