import { Organization, Validations } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { Field } from 'components/types';
import { Fetcher } from 'design-system';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';

import { createOrganizationGeneralRows } from './fields';

interface PersonGeneralFormCardProps {
  onValidChange: (data?: Organization.General) => void;
  showErrors: boolean;
  schemeFetcher: Fetcher<string, unknown>;
}

export const OrganizationGeneralFormCard: React.FC<PersonGeneralFormCardProps> = ({
  onValidChange,
  showErrors,
  schemeFetcher,
}) => {
  const [state, setState] = useState<Partial<Organization.General>>({
    name: '',
    schemeCode: '',
  });

  const schema = Validations.generalOrganizationSchemas;
  const isStateValid = schema.isValidSync(state);

  const onChange = useCallback(
    (data: Partial<Organization.General>) => {
      setState(data);

      // manually checking since the form doesn't seem to deal well with the conditional validation
      // schema and changing fields
      const isValid = schema.isValidSync(data);

      if (!isValid) {
        onValidChange(undefined);
      } else {
        const result = data as unknown as Organization.General; // casting since data is validated

        onValidChange(result);
      }
    },
    [onValidChange, schema]
  );

  const fields = createOrganizationGeneralRows(schemeFetcher, state.type);
  const shouldShowErrors = isStateValid ? false : showErrors;

  return (
    <CrudForm
      key={state.type}
      title={strings.FormLabelPersonDetails}
      // casting to avoid complexity around then handling of the `schemeCode` field
      fields={fields as Field<Organization.General>[]}
      showSubmitButton={false}
      initialValues={state}
      validationSchema={schema}
      onChange={onChange}
      showErrors={shouldShowErrors}
      validateOnChange
      validateOnMount
      hasCard={false}
      onChangeDebounceTime={0}
    />
  );
};
