import { MeasurementName } from '@quromedical/models';
import { Col, Row } from 'components/base';
import { getDurationInDays } from 'core/display';
import { trackingMeasures } from 'core/vitals';
import { hoursToMilliseconds } from 'date-fns/esm';
import { Section, DurationPicker, DurationResult, Grid } from 'design-system';
import { usePatientActiveAdmission } from 'hooks/usePatientActiveAdmission';
import { useIsSmallerThan } from 'hooks/useResponsive';
import { Times } from 'providers/PatientVitalsProvider';
import React, { useCallback, useState } from 'react';
import { NotFoundScreen } from 'screens/NotFoundScreen';

import { VitalsFilter } from './VitalsFilter';
import { VitalsTrackingGrid } from './VitalsTrackingGrid';

interface PatientVitalsTabProps {
  id: string;
  scrollToTop: () => void;
}

export const PatientVitalsTrackingTab: React.FC<PatientVitalsTabProps> = ({ id, scrollToTop }) => {
  const activeAdmission = usePatientActiveAdmission(id);
  const dayOfService = getDurationInDays(activeAdmission?.period?.start);
  const serviceType = activeAdmission?.service;

  const isSmall = useIsSmallerThan('sm');

  const [filter, setFilter] = useState<MeasurementName[]>([]);

  const [times, setTimes] = useState<Required<Times>>({
    start: new Date(),
    duration: hoursToMilliseconds(24),
  });

  const handleDurationChanged = useCallback((result: DurationResult) => {
    // if the user has not selected a start time we need this based on `now - duration`
    const fallbackStart = new Date(Date.now() - result.duration);
    const start = result.from || fallbackStart;
    setTimes({
      duration: result.duration,
      start,
    });
  }, []);

  if (!id) {
    return <NotFoundScreen />;
  }

  const controlRowJustify = isSmall ? 'flex-start' : 'flex-end';

  return (
    <>
      <Section unsetZIndex>
        <Grid unsetZIndex sm={1} lg={2}>
          <Col justifyContent="flex-end" fullHeight unsetZIndex>
            <VitalsFilter
              possibleMeasures={trackingMeasures}
              onChange={setFilter}
              dayOfService={dayOfService}
              serviceType={serviceType}
            />
          </Col>

          <Row justifyContent={controlRowJustify} alignItems="flex-end" fullHeight>
            <DurationPicker
              initial="24h"
              options={['24h', '1w', '1m', '3m', '6m']}
              isCondensed={isSmall}
              onChange={handleDurationChanged}
            />
          </Row>
        </Grid>
      </Section>

      <Section>
        <VitalsTrackingGrid id={id} measurements={filter} times={times} scrollToTop={scrollToTop} />
      </Section>
    </>
  );
};
