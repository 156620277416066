/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */
import { GroupSecurity } from '@quromedical/fhir-common';
import { LinkingOptions, PathConfigMap } from '@react-navigation/native';
import { env } from 'config/env';
import * as Linking from 'expo-linking';

import {
  AdminDrawerParamList,
  AppRootParamList,
  CoverageStackParamList,
  DeviceStackParamList,
  GroupStackParamList,
  OnboardStackParamList,
  OrganizationStackParamList,
  PatientDrawerParamList,
  PractitionerStackParamList,
  RelatedPersonStackParamList,
} from './types';

/**
 * Create a function which only returns `result` if the provided `GroupSecurity` exists in `groups`
 * @param groups
 */
const createInclusiveCheck =
  (groups: GroupSecurity[]) =>
  <T>(group: GroupSecurity, result: T): T | undefined =>
    groups.includes(group) ? result : undefined;

/**
 * Remove values that are undefined from the input object immutably
 * @param data
 */
const clearUndefined = <T>(data: T): T => JSON.parse(JSON.stringify(data)) as T;

/**
 * Create a linking configuration for a user based on their `GroupSecurity` list
 * @param groups
 * @returns
 */
export const createLinkingConfiguration = (
  groups: GroupSecurity[]
): LinkingOptions<AppRootParamList> => {
  const inclusive = createInclusiveCheck(groups);

  const appUrl = env.appURL;

  return {
    prefixes: [appUrl, Linking.createURL('/')],
    config: {
      screens: clearUndefined<PathConfigMap<AppRootParamList>>({
        Home: 'home',
        Dashboard: {
          path: 'dashboard',
          screens: {
            Grid: 'grid',
            List: 'list',
          },
        },
        Profile: 'profile',
        Patient: {
          path: 'patient',
          screens: {
            PatientList: inclusive(GroupSecurity.PatientRead, 'patients'),
            AdmissionList: inclusive(GroupSecurity.EncounterRead, 'admissions'),
            View: {
              path: ':id',
              screens: clearUndefined<PathConfigMap<PatientDrawerParamList>>({
                Vitals: inclusive(GroupSecurity.ObservationRead, 'vitals'),
                Profile: inclusive(GroupSecurity.PatientRead, 'profile'),
                VideoConsult: inclusive(GroupSecurity.VideoConsultCreate, 'video-consult'),
                // other screens that users will have full access to
                Notes: inclusive(GroupSecurity.ClinicalImpressionRead, 'notes'),
                Clinical: inclusive(GroupSecurity.MedicationStatementRead, 'clinical'),
                CreateRelayDeviceUseStatement: inclusive(
                  GroupSecurity.DeviceUseStatementCreate,
                  'device-use-statement/relay-create'
                ),
                CreatePatchDeviceUseStatement: inclusive(
                  GroupSecurity.DeviceUseStatementCreate,
                  'device-use-statement/patch-create'
                ),
                Medias: inclusive(GroupSecurity.MediaRead, 'media'),
                CreateMedia: inclusive(GroupSecurity.MediaCreate, 'media/create'),
                ServiceRequests: inclusive(GroupSecurity.CoverageCreate, 'service-request'),
                CreateAmpathServiceRequest: inclusive(
                  GroupSecurity.ServiceRequestCreate,
                  'service-request/create-ampath-request'
                ),
                Admit: inclusive(GroupSecurity.EncounterCreate, 'admit'),
                PrintSummary: inclusive(GroupSecurity.ClinicalImpressionRead, 'print-summary'),
              }),
            },
          },
        },
        Admin: {
          path: 'admin',
          screens: clearUndefined<PathConfigMap<AdminDrawerParamList>>({
            Onboard: {
              path: 'onboard',
              screens: clearUndefined<PathConfigMap<OnboardStackParamList>>({
                MedicalAid: inclusive(GroupSecurity.PatientCreate, 'medical-aid'),
                Private: inclusive(GroupSecurity.PatientCreate, 'private'),
              }),
            },
            Practitioner: {
              path: 'practitioner',
              screens: clearUndefined<PathConfigMap<PractitionerStackParamList>>({
                List: inclusive(GroupSecurity.PractitionerRead, ''),
                View: inclusive(GroupSecurity.PractitionerRead, ':id'),
              }),
            },
            Device: {
              path: 'device',
              screens: clearUndefined<PathConfigMap<DeviceStackParamList>>({
                List: inclusive(GroupSecurity.DeviceRead, ''),
                View: inclusive(GroupSecurity.DeviceRead, ':id'),
                Create: inclusive(GroupSecurity.DeviceCreate, 'create'),
                Edit: inclusive(GroupSecurity.DeviceUpdate, ':id/edit'),
              }),
            },
            DeviceAssociation: inclusive(GroupSecurity.DeviceRead, 'device-association'),
            Organization: {
              path: 'organization',
              screens: clearUndefined<PathConfigMap<OrganizationStackParamList>>({
                List: inclusive(GroupSecurity.OrganizationRead, ''),
                View: inclusive(GroupSecurity.OrganizationRead, ':id'),
                Create: inclusive(GroupSecurity.OrganizationCreate, 'create'),
                Edit: inclusive(GroupSecurity.OrganizationUpdate, ':id/edit'),
              }),
            },
            Group: {
              path: 'group',
              screens: clearUndefined<PathConfigMap<GroupStackParamList>>({
                List: inclusive(GroupSecurity.GroupRead, ''),
                View: inclusive(GroupSecurity.GroupRead, ':id'),
                Create: inclusive(GroupSecurity.GroupCreate, 'create'),
                Edit: inclusive(GroupSecurity.GroupUpdate, ':id/edit'),
                AddMember: inclusive(GroupSecurity.GroupUpdate, ':id/member/add'),
              }),
            },
            RelatedPerson: {
              path: 'related-person',
              screens: clearUndefined<PathConfigMap<RelatedPersonStackParamList>>({
                List: inclusive(GroupSecurity.RelatedPersonRead, ''),
                View: inclusive(GroupSecurity.RelatedPersonRead, ':id'),
                Create: inclusive(GroupSecurity.RelatedPersonCreate, 'create'),
                Edit: inclusive(GroupSecurity.RelatedPersonUpdate, ':id/edit'),
              }),
            },
            Coverage: {
              path: 'coverage',
              screens: clearUndefined<PathConfigMap<CoverageStackParamList>>({
                List: inclusive(GroupSecurity.CoverageRead, ''),
                View: inclusive(GroupSecurity.CoverageRead, ':id'),
                Create: inclusive(GroupSecurity.CoverageCreate, 'create'),
                Edit: inclusive(GroupSecurity.CoverageUpdate, ':id/edit'),
              }),
            },
            ChargeItem: {
              path: 'charge-item',
              screens: clearUndefined({
                List: inclusive(GroupSecurity.ChargeItemDefinitionRead, ''),
                View: inclusive(GroupSecurity.ChargeItemDefinitionRead, ':id'),
              }),
            },
            ClaimTemplate: {
              path: 'claim-template',
              screens: clearUndefined({
                List: inclusive(GroupSecurity.ClaimTemplateRead, ''),
                View: inclusive(GroupSecurity.ClaimTemplateRead, ':id'),
              }),
            },
            Claim: {
              path: 'claim',
              screens: clearUndefined({
                List: inclusive(GroupSecurity.ClaimRead, ''),
                View: inclusive(GroupSecurity.ClaimRead, ':id'),
              }),
            },
          }),
        },
      }),
    },
    // for some reason TS is unable to correctly infer the types for config during type:check
  } as LinkingOptions<AppRootParamList>;
};
