import { Healthbridge, Validations } from '@quromedical/models';
import { SelectOption } from 'components/types';
import { strings } from 'strings';
import { ObjectType } from 'validation';
import * as yup from 'yup';

import { JointFields } from './forms';

export type IdType = 'medicalAid' | 'idNumber';

export interface Search {
  schemeDetails: SelectOption<Healthbridge.Administrator, string>;
  id: string;
  idType: IdType;
}

export const searchSchema = yup.object<ObjectType<Search>>({
  idType: yup.string().required(),
  schemeDetails: yup.object().required(),
  id: yup.string().required(strings.PatientMedicalAidSearchId),
  // TODO add ID number validation, validation not showing on form
});

const nextOfKinFormSchemaFields = {
  ...Validations.personContactFieldSchemas,
  ...Validations.personGeneralFieldSchemas,
  birthDate: undefined,
  identifierCode: undefined,
  identifierValue: undefined,
};

export const nextOfKinFormSchema = yup
  .object<ObjectType<JointFields>>(nextOfKinFormSchemaFields)
  .required();
