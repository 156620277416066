import { handleDownload } from 'core/media';
import { Button } from 'design-system';
import { logger } from 'helpers';
import { MediaApi } from 'integration/aggregate';
import React, { useCallback } from 'react';
import { strings } from 'strings';

interface DownloadButtonProps {
  id: string;
  patientId: string;
}

const api = new MediaApi();

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  id,
  patientId,
}: DownloadButtonProps) => {
  const onPress = useCallback(async () => {
    if (id) {
      const result = await api.createDownloadUrl(patientId, id);
      handleDownload(result.url).catch(logger.error);
    }
  }, [id, patientId]);

  if (!id) {
    return (
      <Button
        disabled={true}
        variant="outlined"
        text={strings.MediaDownloadButtonText}
        onPress={onPress}
      />
    );
  }

  return <Button variant="outlined" text={strings.MediaDownloadButtonText} onPress={onPress} />;
};
