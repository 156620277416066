import {
  ClinicalImpressionAssessmentValueCode,
  ClinicalImpressionCode,
  ClinicalImpressionLocationCode,
  ClinicalImpressionPlanValueCode,
  createReference,
} from '@quromedical/fhir-common';
import { Media, Notes, Vitals } from '@quromedical/models';
import { SelectOption } from 'components/types';
import { strings } from 'strings';

import {
  assessmentDisplayMap,
  codeDisplayMap,
  locationDisplayMap,
  planDisplayMap,
} from './displays';
import { FormData } from './types';

export const codeOptions: SelectOption<never, ClinicalImpressionCode>[] = Object.values(
  ClinicalImpressionCode
).map((key) => ({
  display: strings[codeDisplayMap[key]],
  value: key,
}));

export const locationOptions: SelectOption<never, ClinicalImpressionLocationCode>[] = Object.values(
  ClinicalImpressionLocationCode
).map((key) => ({
  display: strings[locationDisplayMap[key]],
  value: key,
}));

export const assessmentOptions: SelectOption<never, ClinicalImpressionAssessmentValueCode>[] =
  Object.values(ClinicalImpressionAssessmentValueCode).map((key) => ({
    display: strings[assessmentDisplayMap[key]],
    value: key,
  }));

export const planOptions: SelectOption<never, ClinicalImpressionPlanValueCode>[] = Object.values(
  ClinicalImpressionPlanValueCode
).map((key) => ({
  display: strings[planDisplayMap[key]],
  value: key,
}));

export const locationCodes = Object.values(ClinicalImpressionLocationCode);
export const codes = Object.values(ClinicalImpressionCode);
export const planCodes = Object.values(ClinicalImpressionPlanValueCode);
export const assessmentCodes = Object.values(ClinicalImpressionAssessmentValueCode);

export const mapFormToNote = (
  formData: FormData,
  latest: Vitals.LatestResultMeasures,
  media: Media.CreateData[]
): Notes.CreateNote => {
  const practitionersToNotify = formData.practitionersToNotify.map((practitioner) =>
    createReference('Practitioner', practitioner)
  );

  return {
    media,
    code: formData.code,
    locationCode: formData.locationCode,
    practitionersToNotify,
    vitals: latest,
    note: {
      assessmentCode: formData.assessmentCode,
      planCode: formData.planCode,
      subjective: formData.subjective,
      objective: formData.objective,
      assessment: formData.assessment,
      plan: formData.plan,
    },
  };
};
