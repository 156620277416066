import styled from '@emotion/native';
import { Col, Flex } from 'components/base';
import { Visible, Card } from 'design-system/base';
import { Grid } from 'design-system/layout';
import { BORDER_WIDTH } from 'design-system/theme';
import { useIsSmallerThan } from 'hooks/useResponsive';
import React from 'react';

import { StatusBarProps } from '../status-bar';
import { Graph, GraphProps } from './Graph';
import { Header, HeaderProps } from './Header';
import { MeasureBlock } from './MeasureBlock';
import { StatusHeader } from './StatusHeader';
import { Measure } from './types';

export interface VitalSummaryCardProps {
  header?: HeaderProps;
  graph?: GraphProps;
  statusBar?: StatusBarProps;
  primaryMeasures?: Measure[];
  secondaryMeasures?: Measure[];
}

interface SecondaryWrapperProps {
  hasHorizontalBorder?: boolean;
  hasVerticalBorder?: boolean;
}

const SecondaryWrapper = styled(Col)<SecondaryWrapperProps>(
  ({ theme, hasHorizontalBorder, hasVerticalBorder }) => ({
    borderWidth: 0,
    borderColor: theme.color.divider,
    borderRightWidth: hasHorizontalBorder ? BORDER_WIDTH[1] : 0,
    borderTopWidth: hasVerticalBorder ? BORDER_WIDTH[1] : 0,
  })
);

export const VitalSummaryCard: React.FC<VitalSummaryCardProps> = ({
  header,
  graph,
  statusBar,
  primaryMeasures = [],
  secondaryMeasures = [],
}) => {
  const isSmall = useIsSmallerThan('sm');
  const bodyDirection = isSmall ? 'column' : 'row-reverse';
  const flex = isSmall ? undefined : 1;

  return (
    <Card paddingHorizontal={0} paddingVertical={0}>
      <Col>
        <Header {...header} />
        <Visible if={statusBar}>
          <StatusHeader {...statusBar} />
        </Visible>
        <Graph showGrid {...graph} />
        <Flex flexDirection={bodyDirection}>
          <Col flex={flex}>
            <Grid rtl sm={2} gap="none">
              {primaryMeasures.map((measure) => (
                <MeasureBlock
                  key={`${measure.title}-${measure.value}`}
                  {...measure}
                  valueVariant="dashboard-value-large"
                />
              ))}
            </Grid>
          </Col>
          <SecondaryWrapper hasHorizontalBorder={!isSmall} hasVerticalBorder={isSmall} flex={flex}>
            <Grid rtl sm={2} gap="none">
              {secondaryMeasures.map((measure) => (
                <MeasureBlock
                  key={`${measure.title}-${measure.value}`}
                  {...measure}
                  valueVariant="dashboard-value-small"
                />
              ))}
            </Grid>
          </SecondaryWrapper>
        </Flex>
      </Col>
    </Card>
  );
};
