/* eslint-disable no-console */
import { analytics } from './analytics';
import { Sentry } from './sentry';

interface GamificationEvent {
  type: 'gamification';
  name: 'kbar-search-render' | 'note-pin-press';
}

type TrackEvent = GamificationEvent;

export const logger = {
  track: (data: TrackEvent) => {
    if (__DEV__) {
      console.info('track', data);
    }

    const eventName = `${data.type}.${data.name}`;
    analytics.track(eventName, {
      data,
    });
  },
  error: (err: unknown, data: Record<string, unknown> = {}, tags: Tags = {}): void => {
    if (__DEV__) {
      console.error(err);
    }

    Sentry.withScope((scope) => {
      scope.setTags(tags);
      scope.setContext('data', data);
      Sentry.captureException(err);
    });
  },
};

type Mixed = number | string | boolean;
type Tags = Record<string, Mixed>;
