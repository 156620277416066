import { PaginatedTable } from 'core/table';
import { GroupApi } from 'integration/GroupApi';
import React, { useCallback } from 'react';

import { memberColumns } from './table';

interface GroupMembersProps {
  id: string;
}

const api = new GroupApi();

export const GroupMembers: React.FC<GroupMembersProps> = ({ id }) => {
  const fetcher = useCallback(async () => {
    const data = await api.getGroup(id);
    const members = data.member || [];

    return {
      members,
      link: undefined,
    };
  }, [id]);

  const columns = memberColumns(id);

  const cacheKey = `Group${id}${Date.now()}`;

  return (
    <PaginatedTable
      cacheKey={cacheKey}
      disablePagination
      columns={columns}
      fetcher={fetcher}
      getDataFromResponse={(res) => res.members}
    />
  );
};
