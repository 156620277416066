import { Organization, Validations } from '@quromedical/models';
import { CrudForm } from 'components/form';
import { Field } from 'components/types';
import { Fetcher } from 'design-system';
import React, { useCallback, useState } from 'react';
import { strings } from 'strings';

import { createOrganizationGeneralRows } from './fields';

type General = Organization.General;

interface GeneralFormProps {
  data?: Partial<General>;
  handleSubmit: (data: Organization.General) => Promise<void>;
  handleCancel: () => void;
  schemeFetcher: Fetcher<string, unknown>;
}

export const GeneralForm: React.FC<GeneralFormProps> = ({
  data = {},
  handleCancel,
  handleSubmit,
  schemeFetcher,
}) => {
  const [state, setState] = useState<Partial<General>>(data);

  const fields = createOrganizationGeneralRows(schemeFetcher, state.type);
  const generalSchema = Validations.generalOrganizationSchemas;

  const onSubmit = useCallback(
    async (data: Organization.General) => {
      try {
        await handleSubmit(data);
        return {};
      } catch (error) {
        return { error };
      }
    },
    [handleSubmit]
  );

  return (
    <CrudForm<General>
      key={state.type}
      title={strings.FormLabelPersonDetails}
      validationSchema={generalSchema}
      // casting to prevent complexity around the `schemeCode` field
      fields={fields as Field<Organization.General>[]}
      initialValues={state}
      onSubmit={onSubmit}
      onSecondarySubmit={handleCancel}
      onChange={setState}
      onChangeDebounceTime={0}
      showSecondarySubmitButton
      showSubmitButton
    />
  );
};
