import { Practitioner } from '@quromedical/models';
import { Alert, Skeleton, SkeletonProvider, Snackbar, Visible } from 'design-system';
import { logger } from 'helpers';
import { useFiniteState } from 'hooks/useFiniteState';
import React, { useCallback } from 'react';
import { strings } from 'strings';

import { QualificationCard } from './card';
import { QualificationForm } from './form';

interface PractitionerContactFormProps {
  canEdit: boolean;
  data?: Partial<Practitioner.QualificationFields>;
  revalidate: () => void;
  onSubmit: (practitioner: Practitioner.CreateQualificationFields) => Promise<void>;
}

type FormState = 'initial' | 'editing' | 'submitting' | 'error';

export const PractitionerQualificationCard: React.FC<PractitionerContactFormProps> = ({
  canEdit,
  data,
  revalidate,
  onSubmit,
}) => {
  const state = useFiniteState<FormState>('initial');

  const handleSubmit = useCallback(
    async (result: Practitioner.CreateQualificationFields) => {
      state.set('submitting');
      try {
        await onSubmit(result);
        state.set('initial');
        revalidate();
        return {};
      } catch (error) {
        state.set('error');
        logger.error(error);
        return { error };
      }
    },
    [revalidate, onSubmit, state]
  );

  return (
    <>
      <SkeletonProvider loading={state.is('submitting')}>
        <Skeleton>
          <Visible if={state.isNot('editing')}>
            <QualificationCard data={data} canEdit={canEdit} onEditPress={state.next('editing')} />
          </Visible>

          <Visible if={state.is('editing')}>
            <QualificationForm
              data={data}
              handleCancel={state.next('initial')}
              handleSubmit={handleSubmit}
            />
          </Visible>
        </Skeleton>
      </SkeletonProvider>

      <Snackbar onClose={state.next('editing')} isOpen={state.is('error')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={state.next('editing')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
        />
      </Snackbar>
    </>
  );
};
