import { ContentType } from '@quromedical/fhir-common';
import { Media } from '@quromedical/models';
import { DisplayView } from 'components/fhir';
import { ColumnType } from 'components/table';
import { Text, MediaPreview, FileType } from 'design-system';
import { MediaApi } from 'integration/aggregate';
import React from 'react';
import { strings } from 'strings';

export interface TableMedia {
  id: string;
  url: string;
  operator: fhir4.Reference;
  title: string;
  contentType: ContentType;
}

const mediaTypeMap: Partial<Record<ContentType, FileType>> = {
  [ContentType.pdf]: 'pdf',
  [ContentType.jpeg]: 'image',
  [ContentType.png]: 'image',
};

const api = new MediaApi();

const getMediaUrl = (patientId: string, mediaId: string) => async (): Promise<string> => {
  const media = await api.createDownloadUrl(patientId, mediaId);

  return media.url;
};

export const mediaColumns = (patientId: string): ColumnType<TableMedia>[] => [
  {
    header: strings.MediaFileNameSubheading,
    Cell: ({ value }) => <Text>{value}</Text>,
    accessor: 'title',
  } as ColumnType<TableMedia, 'title'>,
  {
    header: strings.MediaPractitionerSubheading,
    Cell: ({ value }) => <DisplayView reference={value} />,
    accessor: 'operator',
  } as ColumnType<fhir4.Media, 'operator'>,
  {
    accessor: 'id',
    header: strings.MediaDownloadSubheading,
    Cell: ({ row, value }) => {
      const fileType = mediaTypeMap[row.original.contentType];

      return (
        <MediaPreview
          id={value}
          type={fileType}
          getUrl={getMediaUrl(patientId, value)}
          title={row.original.title}
        />
      );
    },
  } as ColumnType<TableMedia, 'id'>,
];

export const getDataFromResponse = (res: Media.GetAllResponse): Partial<TableMedia>[] =>
  res.media.map((p) => ({
    id: p.id,
    operator: p.createdBy,
    title: p.title,
    url: p.id,
    contentType: p.contentType,
  }));
