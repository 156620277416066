import styled from '@emotion/native';
import { Col } from 'components/base';
import { Visible } from 'design-system/base';
import { Absolute } from 'design-system/layout';
import { BASE_SIZE, PADDING } from 'design-system/theme';
import React, { useEffect } from 'react';
import { useWindowDimensions, ViewStyle } from 'react-native';

type Alignment = 'start' | 'end' | 'center';

interface SnackbarProps {
  isOpen: boolean;
  onClose: () => void;
  duration?: number;
  align?: Alignment;
  top?: number;
}

const alignStyles: Record<Alignment, ViewStyle> = {
  start: {
    left: 0,
    alignItems: 'flex-start',
  },
  end: {
    right: 0,
    alignItems: 'flex-end',
  },
  center: {
    alignItems: 'center',
  },
};

interface WrapperProps {
  align: Alignment;
  top?: number;
}

const Wrapper = styled(Col)<WrapperProps>(({ align, top = BASE_SIZE[48] }) => {
  const { width } = useWindowDimensions();
  const styles = alignStyles[align];

  const padding = PADDING.s;

  return {
    width: width - 2 * padding,
    top,
    padding,
    flexDirection: 'column',
    ...styles,
  };
});

export const Snackbar: React.FC<SnackbarProps> = ({
  onClose,
  duration = 10000,
  isOpen,
  children,
  top,
  align = 'center',
}) => {
  const name = `snackbar_${Math.random().toString()}`;

  useEffect(() => {
    const closeTimeout = setTimeout(() => {
      if (isOpen) {
        onClose();
      }
    }, duration);

    return () => {
      clearTimeout(closeTimeout);
    };
  }, [duration, isOpen, onClose]);

  return (
    <Visible if={isOpen}>
      <Absolute name={name} mode="contained">
        <Wrapper top={top} align={align}>
          {children}
        </Wrapper>
      </Absolute>
    </Visible>
  );
};
