import styled from '@emotion/native';
import { Row } from 'components/base';
import { BASE_SIZE, PADDING } from 'design-system/theme';
import React from 'react';

import { Logo, Profile } from '../shared';

interface TopBarProps {
  name?: string;
  initials?: string;
  onProfilePress?: () => void;
}

const Wrapper = styled(Row)(({ theme }) => ({
  flexDirection: 'row',
  backgroundColor: theme.color['base-grey'],
  justifyContent: 'space-between',
  paddingHorizontal: PADDING.s,
  height: BASE_SIZE[48],
  maxWidth: '100%',
}));

export const TopBar: React.FC<TopBarProps> = ({ initials, name, onProfilePress }) => (
  <Wrapper unsetZIndex>
    <Logo />
    <Profile name={name} initials={initials} onProfilePress={onProfilePress} />
  </Wrapper>
);
