/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Bundle, BundleEntry, getResourcesFromBundle } from '@quromedical/fhir-common';
import { FhirResource } from 'fhir/r4';
import { logger } from 'helpers';
import { useCallback } from 'react';
import useSWRInfinite from 'swr/infinite';

type ResourceType = fhir4.FhirResource['resourceType'];

export type KeyType = ResourceType | [ResourceType, string];

interface FhirDataInfiniteResult<TResource extends fhir4.FhirResource> {
  loadMore: () => void;
  canLoadMore: boolean;
  data?: Bundle<TResource>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  isLoading: boolean;
}

const initialToken = '__initial__';

const getNextToken = <TResource extends fhir4.FhirResource>(
  bundle: Bundle<TResource>
): string | undefined => {
  const token = bundle.link?.find((l) => l.relation === 'next');
  return token?.url;
};

const mergeBundleArray = <TResource extends fhir4.FhirResource>(
  bundleArray: Bundle<TResource>[]
): Bundle<TResource> => {
  const bundleEntry = bundleArray
    .filter((item) => !!item.entry)
    .map((item) => item.entry as BundleEntry<TResource>[])
    .reduce((acc, entry) => [...acc, ...entry], [] as BundleEntry<TResource>[]);

  const lastBundle = bundleArray[bundleArray.length - 1];

  return {
    ...lastBundle,
    entry: bundleEntry,
  };
};

const getNext = <TResource extends fhir4.FhirResource>(
  key: KeyType,
  pageIndex: number,
  previousData: Bundle<TResource> | null
) => {
  if (pageIndex === 0) {
    return initialToken + JSON.stringify(key);
  }
  if (previousData) {
    return getNextToken(previousData) || null;
  }
  return null;
};

export const useFhirDataInfinite = <TResource extends fhir4.FhirResource>(
  key: KeyType,
  fetcher: (pageToken?: string) => Promise<Bundle<TResource>>
): FhirDataInfiniteResult<TResource> => {
  const keyedFetcher = (token?: string) => {
    const pageToken = token?.startsWith(initialToken) ? undefined : token;
    return fetcher(pageToken);
  };

  const keyLoader = getNext.bind({}, key);

  const { data, error, setSize, isValidating } = useSWRInfinite<Bundle<TResource>>(
    keyLoader,
    keyedFetcher
  );
  const loadMore = useCallback(() => {
    setSize((size) => size + 1).catch(logger.error);
  }, [setSize]);

  if (data) {
    const bundle = mergeBundleArray(data);
    const canLoadMore = !!getNextToken(bundle);

    return {
      data: bundle,
      isLoading: isValidating,
      canLoadMore,
      error,
      loadMore,
    };
  }

  return {
    canLoadMore: true,
    data: undefined,
    isLoading: isValidating,
    error,
    loadMore,
  };
};

export const noFlatten = <T>(data: T): T => data;

/**
 * Used as a compat mechanism for transforming any flatten functions to align
 * to the structure needed by the `usePaginatedData` hook as per the `PaginatedTable` requirements
 *
 * @deprecated only for compatibility with existing code. New code should not use FHIR data directly
 * in tables
 */
export const getDataFromResponseCompat =
  <TResource extends FhirResource, TFlat extends {}>(flatten: (resource: TResource) => TFlat) =>
  (data: Bundle<TResource>) => {
    const resources = getResourcesFromBundle<TResource>(data);
    return resources.map(flatten);
  };
