import { Logistics } from '@quromedical/models';
import { ConfirmationButton } from 'components/input';
import { ColumnType, DataTable } from 'components/table';
import { Alert, Skeleton, SkeletonProvider, Snackbar } from 'design-system';
import { useFiniteState } from 'hooks/useFiniteState';
import { useIsSmallerThan } from 'hooks/useResponsive';
import React, { useCallback } from 'react';
import { strings } from 'strings';

export interface TableData {
  vehicleType: string;
  serviceType: string;
  serviceDescription: string;
  price: number;
  index: number;
}

export const createQuoteColumns = (
  onSubmit: (index: number) => Promise<void>
): ColumnType<TableData>[] => [
  {
    header: strings.LogisticsVehicleTypeLabel,
    accessor: 'vehicleType',
  } as ColumnType<TableData, 'vehicleType'>,
  {
    header: strings.LogisticsServiceTypeLabel,
    accessor: 'serviceType',
  } as ColumnType<TableData, 'serviceType'>,
  {
    header: strings.LogisticsServiceDescriptionLabel,
    accessor: 'serviceDescription',
  } as ColumnType<TableData, 'serviceDescription'>,
  {
    header: strings.LogisticsPriceLabel,
    accessor: 'price',
  } as ColumnType<TableData, 'price'>,
  {
    header: '',
    accessor: 'index',
    Cell: ({ row, value }) => (
      <ConfirmationButton
        message={`${strings.LogisticsBookingConfirmationMessage} ${row.original.price}`}
        status="status-success"
        buttonText={strings.LogisticsBookCollection}
        title={strings.LogisticsBookCollection}
        onSubmit={() => onSubmit(value)}
      />
    ),
  } as ColumnType<TableData, 'index'>,
];

interface QuoteTableProps {
  quotes: Logistics.Quote[];
  onSubmit: (quote: Logistics.Quote) => Promise<void>;
}

type FormState = 'displaying' | 'loading' | 'submissionError';

export const QuoteTable: React.FC<QuoteTableProps> = ({ quotes, onSubmit }) => {
  const state = useFiniteState<FormState>('displaying');
  const isSmall = useIsSmallerThan('sm');

  const handleSubmit = useCallback(
    async (index: number) => {
      const quote = quotes[index];

      if (!quote) {
        return;
      }

      await onSubmit(quotes[index]);
    },
    [onSubmit, quotes]
  );

  const hasError = state.is('submissionError');

  const tableData = quotes
    .map<TableData>((quote, index) => ({
      vehicleType: quote.vehicleType,
      serviceType: quote.serviceType,
      serviceDescription: quote.serviceDescription,
      price: quote.priceRands,
      index,
    }))
    .sort((a, b) => a.price - b.price);

  const columns = createQuoteColumns(handleSubmit);

  return (
    <>
      <SkeletonProvider loading={state.is('loading')}>
        <Skeleton>
          <DataTable data={tableData} columns={columns} debounceTime={0} useCards={isSmall} />
        </Skeleton>
      </SkeletonProvider>

      <Snackbar onClose={state.next('displaying')} isOpen={hasError}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={state.next('displaying')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.LogisticsQuoteSubmittingError}
        />
      </Snackbar>
    </>
  );
};
