/* eslint-disable max-classes-per-file */
import axios, { AxiosError, AxiosInstance } from 'axios';
import { env } from 'config/env';
import { logger } from 'helpers';

const reportExcludeCodes = [400, 401, 403];

const shouldReport = (error: unknown): AxiosError<{}> | undefined => {
  const isAxiosError = axios.isAxiosError(error);

  if (!isAxiosError) {
    return undefined;
  }

  const axiosError = error;
  // depending on the type of error/cors configuration axios may not get the full error data
  const status = axiosError?.response?.status;

  // should report if we don't have any status, or if the status note one of the below
  const isRelevantError = !status || !reportExcludeCodes.includes(status);

  if (!isRelevantError) {
    return undefined;
  }

  return axiosError;
};

class BaseApiError extends Error {
  axiosError: Partial<AxiosError>;
  url?: string;
  status?: number;
  method?: string;

  constructor(axiosError: Partial<BaseError>) {
    const url = axiosError?.response?.config?.url || '';
    const status = axiosError?.response?.status;
    const method = axiosError?.response?.config?.method;

    const message = `Base API Error ${url}`;

    super(message);

    this.axiosError = axiosError;
    this.url = url;
    this.status = status;
    this.method = method;
  }
}

/**
 * Pass in a configured `BaseApiError` so as to ensure the stack trace logged is based on where the
 * original error was thrown/created
 * @param err
 * @param baseApiError
 */
const logAxiosError = (err: unknown, baseApiError: BaseApiError) => {
  const axiosError = shouldReport(err);
  if (axiosError) {
    const headers = (axiosError.response?.headers || {}) as Record<string, string>;

    const amzTraceId = headers['x-trace-id'];
    const tags = { amzTraceId };
    const error = axiosError as unknown as Record<string, unknown>;

    logger.error(baseApiError, error, tags);
  }
};

export class UnauthenticatedApi {
  protected readonly client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: env.apiUrl,
      headers: {
        Authorization: 'UnauthenticatedApi',
      },
    });
  }

  public get: AxiosInstance['get'] = async (...args) => {
    try {
      return await this.client.get(...args);
    } catch (err) {
      logAxiosError(err, new BaseApiError(err as BaseError));
      throw err;
    }
  };

  public put: AxiosInstance['put'] = async (...args) => {
    try {
      return await this.client.put(...args);
    } catch (err) {
      logAxiosError(err, new BaseApiError(err as BaseError));
      throw err;
    }
  };

  public post: AxiosInstance['post'] = async (...args) => {
    try {
      return await this.client.post(...args);
    } catch (err) {
      logAxiosError(err, new BaseApiError(err as BaseError));
      throw err;
    }
  };
}

type BaseError = AxiosError<{}>;
