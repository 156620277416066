import { useCallback, useState } from 'react';

interface UseBooleanHook {
  value: boolean;
  setValue: (value: boolean) => void;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
}

/**
 * Use a boolean value with additional options for managing the boolean state.
 *
 * Provides utility functions to help reduce boilerplate and do common boolean value operations
 */
export const useBoolean = (initial: boolean): UseBooleanHook => {
  const [value, setValue] = useState(initial);

  const toggle = useCallback(() => setValue(!value), [value]);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);

  return {
    value,
    setValue,
    setFalse,
    setTrue,
    toggle,
  };
};
