import styled from '@emotion/native';
import { useTheme } from '@emotion/react';
import { Col, Row } from 'components/base';
import { Text, Icon } from 'design-system/base';
import { BASE_SIZE, BORDER_RADIUS, BORDER_WIDTH, MARGIN, PADDING } from 'design-system/theme';
import React, { useCallback, useState } from 'react';
import { ActivityIndicator, Pressable } from 'react-native';

interface FileCardProps {
  name: string;
  onPress: () => Promise<void>;
}

const Wrapper = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  paddingVertical: PADDING['2xs'],
  paddingHorizontal: PADDING.s,
  backgroundColor: theme.color.base,
  borderRadius: BORDER_RADIUS[4],
  borderWidth: BORDER_WIDTH[1],
  borderColor: theme.color.divider,
}));

const TextWrapper = styled(Col)({
  marginLeft: MARGIN.xs,
});

const IconWrapper = styled(Col)({
  height: BASE_SIZE[24],
  width: BASE_SIZE[24],
  alignItems: 'center',
  justifyContent: 'center',
});

export const FileCard: React.FC<FileCardProps> = ({ name = '', onPress }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const handleLoading = useCallback(async () => {
    setLoading(true);
    try {
      await onPress();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [onPress]);

  return (
    <Pressable onPress={handleLoading} disabled={loading}>
      <Wrapper>
        <IconWrapper>
          {loading ? (
            <ActivityIndicator color={theme.color['text-default']} />
          ) : (
            <Icon name="file-present" color="text-default" />
          )}
        </IconWrapper>
        <TextWrapper flex={1}>
          <Row>
            <Text numberOfLines={1} ellipsizeMode="head" variant="body">
              {name}
            </Text>
          </Row>
        </TextWrapper>
      </Wrapper>
    </Pressable>
  );
};
