import { GroupSecurity } from '@quromedical/fhir-common';
import { useUserSession } from 'hooks/useUserSession';
import React from 'react';

interface WithGroupProps {
  groups: GroupSecurity[];
}

/**
 * Ensures that the contents of a section are only available to users with the provided `group`
 */
export const WithGroup: React.FC<WithGroupProps> = ({ groups, children }) => {
  const session = useUserSession();
  const hasGroup = session.hasAny(groups);

  if (!hasGroup) {
    return null;
  }

  return <>{children}</>;
};
