import { Theme, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { UserPreference } from '@quromedical/models';
import { logger, noOp } from 'helpers';
import { useAsyncStorageData } from 'hooks/useAsyncStorageData';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useColorScheme } from 'react-native';

import { darkTheme, lightTheme, darkThemeHighContrast } from './color';

interface ThemeProviderProps {
  theme: UserPreference.Theme;
}

interface ThemeStateContextData {
  theme: UserPreference.Theme;
  changeTheme: (theme: UserPreference.Theme) => void;
}

const ThemeStateContext = createContext<ThemeStateContextData>({
  theme: 'dark',
  changeTheme: noOp,
});

export const useThemeStateContext = () => useContext(ThemeStateContext);

export const ThemeProvider: React.FC<React.PropsWithChildren<ThemeProviderProps>> = ({
  children,
  theme,
}) => {
  const colorScheme = useColorScheme() || 'dark';

  const isDark = colorScheme === 'dark';

  const systemTheme = isDark ? darkTheme : lightTheme;

  const themeMap: Record<UserPreference.Theme, Theme> = {
    light: lightTheme,
    dark: darkTheme,
    system: systemTheme,
    'contrast-dark': darkThemeHighContrast,
  };

  const resolvedTheme = themeMap[theme];

  return <EmotionThemeProvider theme={resolvedTheme}>{children}</EmotionThemeProvider>;
};

export const ThemeState: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<UserPreference.Theme>('dark');
  const themeStore = useAsyncStorageData<UserPreference.Theme>('userpreference.theme', 'dark');

  useEffect(() => {
    themeStore.getData().then(setTheme).catch(logger.error);
  }, [themeStore]);

  const changeTheme = (value: UserPreference.Theme = 'dark') => {
    setTheme(value);
    themeStore.setData(value).catch(logger.error);
  };

  return (
    <ThemeStateContext.Provider value={{ theme, changeTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeStateContext.Provider>
  );
};
