import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { IconName, Icon } from 'design-system/base';
import { BASE_SIZE, BORDER_WIDTH, PADDING } from 'design-system/theme';
import React from 'react';

interface TabProps {
  isActive: boolean;
  icon: IconName;
  onPress: () => void;
}

interface PressableProps {
  isActive: boolean;
}

const Pressable = styled.Pressable<PressableProps>(({ theme, isActive }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: BASE_SIZE[56],
  paddingHorizontal: PADDING.xs,
  paddingTop: PADDING['3xs'],
  borderStyle: 'solid',
  borderColor: isActive ? theme.color.primary : 'transparent',
  borderWidth: 0,
  borderBottomWidth: BORDER_WIDTH[2],
  width: 86,
}));

export const Tab: React.FC<TabProps> = ({ isActive, onPress, icon }) => {
  const color: ColorName = isActive ? 'primary' : 'text-default';

  return (
    <Pressable onPress={onPress} isActive={isActive}>
      <Icon color={color} name={icon} size={BASE_SIZE[24]} />
    </Pressable>
  );
};
