import { Practitioner } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';

export class PractitionerQualificationApi {
  private readonly client: BaseApi;
  private readonly aggregateRoot = 'api/aggregate/practitioner-qualification';

  constructor() {
    this.client = new BaseApi();
  }

  getPractitionerQualifications = async (): Promise<Practitioner.GetQualificationResponse> => {
    const response = await this.client.get<Practitioner.GetQualificationResponse>(
      this.aggregateRoot
    );
    return response.data;
  };
}
