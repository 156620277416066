import { Currency } from '@quromedical/fhir-common';
import { ChargeItemDefinition } from '@quromedical/models';

const currencyDisplay: Record<Currency, string> = {
  [Currency.ZAR]: 'R',
};

export const getMoneyDisplay = (amount: ChargeItemDefinition.Money, quantity: number): string => {
  const value = quantity * amount.value * amount.factor;
  const formatted = value.toFixed(2);

  const prefix = currencyDisplay[amount.currency];

  return `${prefix}${formatted}`;
};
