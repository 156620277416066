import { ControlledPeer, RemotePeer } from 'core/video-consult';
import { WaitingRoom, InCall, CallLoader, InCallMinimized } from 'design-system';
import React from 'react';
import { strings } from 'strings';

import { useMeeting } from './MeetingRoomProvider';

export const MeetingContent: React.FC = () => {
  const { meetingState, remoteIds, peerIds, speakerId, localId, leaveMeeting, minimize } =
    useMeeting();
  const state = meetingState.type;

  if (state === 'out-of-call') {
    return null;
  }

  if (state === 'in-waiting-room') {
    return (
      <WaitingRoom leaveMeeting={leaveMeeting} title={strings.WaitingRoomTitle}>
        <ControlledPeer peerId={localId} isMinimized={false} />
      </WaitingRoom>
    );
  }

  if (state === 'in-call') {
    return (
      <InCall minimize={minimize} count={peerIds.length}>
        <ControlledPeer peerId={localId} isMinimized={false} showLeaveButton />

        {remoteIds.map((peerId) => (
          <RemotePeer key={peerId} peerId={peerId} />
        ))}
      </InCall>
    );
  }

  if (state === 'in-call-minimized') {
    const defaultPeerId = remoteIds[0] || localId;
    const peerId = speakerId ?? defaultPeerId;

    return (
      <InCallMinimized>
        <ControlledPeer isMinimized peerId={peerId} showLeaveButton showMinimizedToggle />
      </InCallMinimized>
    );
  }

  return <CallLoader leaveMeeting={leaveMeeting} title={strings.MeetingLoadingTitle} />;
};
