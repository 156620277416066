import { useTheme } from '@emotion/react';
import * as Graph from 'components/graph';

interface TooltipProps {
  closest?: Graph.Datum;
  baseColor: string;
  stops: Graph.StopProps[];
  tooltipText?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ closest, baseColor, stops, tooltipText }) => {
  const theme = useTheme();

  if (!closest) {
    return null;
  }

  return (
    <>
      <Graph.Point x={closest.x} y={closest.y} fill={theme.color['text-default']} radius={5} />
      <Graph.PlotScatter data={[closest]} fill={baseColor} radius={3} stops={stops} />
      <Graph.Tooltip
        x={closest.x}
        y={closest.y}
        yOffset={8}
        xOffset={8}
        color={theme.color.base}
        text={tooltipText}
        backgroundColor={theme.color['text-default']}
      />
    </>
  );
};
