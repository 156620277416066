import styled from '@emotion/native';
import { Flex } from 'components/base';
import { MARGIN, SCREEN_SIZE } from 'design-system/theme';
import { useResponsive } from 'hooks/useResponsive';

interface SectionProps {
  hasBottomMargin?: boolean;
  hasTopMargin?: boolean;
  limitWidth?: boolean;
}

export const useResponsiveSectionMargin = () =>
  useResponsive({ sm: MARGIN.xs, md: MARGIN.s, lg: MARGIN.m }, MARGIN.xs);

export const Section = styled(Flex)<SectionProps>(
  ({ hasBottomMargin = false, hasTopMargin = true, limitWidth = false }) => {
    const margin = useResponsiveSectionMargin();
    const maxWidth = limitWidth ? SCREEN_SIZE.md : undefined;

    return {
      marginHorizontal: margin,
      marginTop: hasTopMargin ? margin : undefined,
      marginBottom: hasBottomMargin ? margin : undefined,
      maxWidth,
    };
  }
);
