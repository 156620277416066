import last from 'lodash.last';
import { useCallback, useState } from 'react';
import useSWR from 'swr';

interface PaginatedDataResult<TData> {
  hasNext: boolean;
  hasPrev: boolean;
  data?: TData[];
  error: unknown;
  isLoading: boolean;
  page: number;
  loadNext: () => void;
  loadPrev: () => void;
  setCount: (count: number) => void;
  resetPage: () => void;
}

export const getNextTokenFromLink = (link: fhir4.BundleLink[] = []): string | undefined => {
  const token = link.find((l) => l.relation === 'next');
  return token?.url;
};

export const getField =
  <TData extends {}, TKey extends keyof TData>(key: TKey) =>
  (data: TData): TData[TKey] =>
    data[key];

const getPaginatedSWRToken = (
  key: string,
  count: number | undefined,
  activeToken: string | undefined
): string => `paginated-key/${key}/token=${activeToken || 'unset'}/count=${count || 'unset'}`;

export const usePaginatedData = <TResponse, TData>(
  key: string,
  fetcher: (pageToken?: string, count?: number) => Promise<TResponse>,
  getDataFromResponse: (data: TResponse) => TData[],
  getNextToken: (prevData: TResponse) => string | undefined,
  initialCount?: number
): PaginatedDataResult<TData> => {
  const [count, setCount] = useState(initialCount);
  const [prevTokens, setPrevTokens] = useState<string[]>([]);
  const [activeToken, setActiveToken] = useState<string>();

  const keyedFetcher = () => fetcher(activeToken, count);

  const swrKey = getPaginatedSWRToken(key, count, activeToken);

  const { data, error, isValidating } = useSWR<TResponse, unknown>(swrKey, keyedFetcher);

  const responseData = data ? getDataFromResponse(data) : undefined;

  const nextToken = data ? getNextToken(data) : undefined;

  const loadNext = useCallback(() => {
    if (nextToken) {
      setActiveToken(nextToken);
      setPrevTokens([...prevTokens, nextToken]);
    }
  }, [nextToken, prevTokens]);

  const prevToken = last(prevTokens);

  const loadPrev = useCallback(() => {
    setActiveToken(prevToken);

    if (prevTokens.length) {
      const remainingTokens = prevTokens.slice(0, -1);
      setPrevTokens(remainingTokens);
    }
  }, [prevToken, prevTokens]);

  const onCountChange = useCallback((value: number) => {
    // this is a bit opinionated but to avoid confusion the page is reset when the count is changed
    // since we can't tell in advance how a given pagination system might work and the position
    // calculation would become very complex if that were to be considered fully
    setActiveToken(undefined);
    setCount(value);
  }, []);

  const resetPage = useCallback(() => {
    setActiveToken(undefined);
    setPrevTokens([]);
  }, []);
  const page = prevTokens.length;

  return {
    error,
    page,
    data: responseData,
    isLoading: isValidating,
    hasNext: !!nextToken,
    hasPrev: !!prevToken,
    loadNext,
    loadPrev,
    setCount: onCountChange,
    resetPage,
  };
};
