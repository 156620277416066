import { MedicalAid } from '@quromedical/models';
import { FormCard, RowConfig } from 'design-system';
import React from 'react';
import { strings } from 'strings';

const createMedicalAidRowConfigs = (medicalAid?: Partial<MedicalAid.MedicalAid>): RowConfig[] => [
  {
    fields: [
      {
        type: 'text',
        label: strings.CardLabelScheme,
        display: medicalAid?.schemeName,
      },
      {
        type: 'text',
        label: strings.CardLabelPlanAndOption,
        display: medicalAid?.plan,
      },
    ],
  },
  {
    fields: [
      {
        type: 'text',
        label: strings.FormLabelMedicalAidNumber,
        display: medicalAid?.membershipNumber,
      },
    ],
  },
];

export const MedicalAidMembershipCard: React.FC<Partial<MedicalAid.MedicalAid>> = (props) => {
  const medicalAidRowConfigs = createMedicalAidRowConfigs(props);

  return (
    <FormCard
      title={strings.CardLabelMedicalAidDetails}
      rows={medicalAidRowConfigs}
      showIcons={false}
    />
  );
};
