// converted using SVGR with React-Native and Typescript Options
// https://react-svgr.com/playground/?native=true&typescript=true
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { Icon } from './internal';

export const PatchAdd: Icon = ({ color = '#000000', size = 24 }) => (
  <Svg width={size} height={size} fill="none" viewBox="0 0 24 24">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.649 8.05a3.3 3.3 0 1 1 3.3 3.301v1.5a4.8 4.8 0 1 0-4.8-4.8h1.5Zm.202 7.9a3.098 3.098 0 0 1 3.098-3.099v-1.5a4.598 4.598 0 0 0-4.598 4.598 3.3 3.3 0 1 1-3.3-3.3v-1.5a4.8 4.8 0 1 0 4.8 4.8Zm-4.8-4.801a3.098 3.098 0 0 0 3.098-3.098h1.5a4.598 4.598 0 0 1-4.598 4.598v-1.5Z"
      fill={color}
    />
    <Path d="M7 10V7h3V5H7V2H5v3H2v2h3v3h2Z" fill={color} />
  </Svg>
);

<Path
  d="M8.596 13.281H6.702A.704.704 0 0 1 6 12.58c0-.386.316-.702.702-.702h1.894c.386 0 .702.316.702.702a.704.704 0 0 1-.702.701ZM6.702 10.58h1.894a.704.704 0 0 0 .702-.701.704.704 0 0 0-.702-.702H6.702A.704.704 0 0 0 6 9.878c0 .386.316.701.702.701Zm0-2.666h1.894a.704.704 0 0 0 .702-.702.704.704 0 0 0-.702-.702H6.702A.704.704 0 0 0 6 7.211c0 .386.316.702.702.702Zm0-2.702h1.894a.704.704 0 0 0 .702-.702.704.704 0 0 0-.702-.702H6.702A.704.704 0 0 0 6 4.51c0 .386.316.702.702.702Zm9.895 12.983c0 2.14-1.72 3.86-3.86 3.86-2.14 0-3.86-1.72-3.86-3.86 0-1.228.597-2.386 1.579-3.123V1.7c0-.385.316-.701.702-.701h3.158c.386 0 .702.316.702.702V15.07a3.823 3.823 0 0 1 1.579 3.123Zm-1.403 0c0-.877-.457-1.684-1.229-2.14-.21-.14-.35-.352-.35-.597V2.404H11.86v13.053c0 .246-.14.491-.35.596a2.465 2.465 0 0 0-1.264 2.14 2.463 2.463 0 0 0 2.456 2.457c1.369 0 2.492-1.123 2.492-2.456Z"
  fill="#000"
/>;
