import { GroupDescription } from '@quromedical/fhir-common';
import { Group } from '@quromedical/models';
import { activeDisplay } from 'core/display';
import { FormCard, RowConfig } from 'design-system';
import React from 'react';
import { strings } from 'strings';

import { getCodeDescription } from '../config';

const getGroupRows = (group: Partial<Group.Group> = {}): RowConfig[] => [
  {
    icon: 'group',
    fields: [
      {
        label: strings.LabelGroupName,
        display: group.name,
        type: 'text',
      },
    ],
  },
  {
    fields: [
      {
        label: strings.LabelGroupType,
        display: getCodeDescription(group.type),
        type: 'text',
      },
      {
        label: strings.LabelActive,
        display: activeDisplay(group.active),
        type: 'text',
      },
    ],
  },
  {
    icon: 'security',
    fields: [
      {
        type: 'list',
        label: strings.LabelPermissions,
        display: group.permissions?.map((c) => GroupDescription[c]),
      },
      {
        type: 'text',
        label: strings.LabelOrganization,
        display: group.organization?.display,
      },
    ],
  },
];

interface CardProps {
  data: Partial<Group.Group>;
  canEdit: boolean;
  onEditPress: () => void;
}

export const Card: React.FC<CardProps> = ({ data, canEdit, onEditPress }) => {
  const rows = getGroupRows(data);

  return (
    <FormCard
      isButtonVisible={canEdit}
      onButtonPress={onEditPress}
      buttonText={strings.ButtonTextEdit}
      buttonIcon="edit"
      showIcons
      title={strings.CardTitleGroupDetails}
      rows={rows}
    />
  );
};
