import { GroupSecurity } from '@quromedical/fhir-common';
import { PatientHeaderSection } from 'core/patient';
import { Tabs } from 'design-system';
import { useRevalidation } from 'hooks/useRevalidation';
import { useUserSession } from 'hooks/useUserSession';
import { PatientApi, PatientEndpoint } from 'integration/aggregate';
import { PatientDrawerScreenProps } from 'navigation';
import { usePatientId } from 'providers/PatientIdContext';
import React from 'react';
import { ScrollView } from 'react-native';
import { NotFoundScreen } from 'screens/NotFoundScreen';
import { strings } from 'strings';
import useSWR from 'swr';

import {
  PatientDevicesTab,
  PatientLogisticTab,
  PatientMedicalAidTab,
  PatientNextOfKinTab,
  PatientProfileTab,
  PatientMedicalAidUpdateTab,
} from './tabs';

const api = new PatientApi();

export const PatientProfileScreen: React.FC<PatientDrawerScreenProps<'Profile'>> = () => {
  const id = usePatientId();
  const session = useUserSession();

  const fetcher = () => (id ? api.getPatient(id) : undefined);
  const key = api.getPatientSWRKey(id, PatientEndpoint.Base);

  const result = useSWR(key, fetcher);

  const revalidate = useRevalidation(key);

  if (!id) {
    return <NotFoundScreen />;
  }

  const patientFilePermissions = [GroupSecurity.PatientRead];
  const hasPatientFilePermissions = session.hasAny(patientFilePermissions);
  const deviceManagePermissions = [
    GroupSecurity.DeviceUseStatementCreate,
    GroupSecurity.DeviceUseStatementUpdate,
  ];
  const hasDeviceManagePermissions = session.hasAny(deviceManagePermissions);
  const hasDeviceUseStatementCreatePermission = session.hasAny([
    GroupSecurity.DeviceUseStatementCreate,
  ]);

  const hasLogisticsPermissions = session.hasAny([GroupSecurity.Logistics]);

  const hasNextOfKinPermissions = session.hasAny([GroupSecurity.RelatedPersonRead]);

  const hasCoverage = !!result.data?.coverage;

  const canViewCoverage = hasCoverage && session.hasAny([GroupSecurity.CoverageRead]);
  const canCreateCoverage = !hasCoverage && session.hasAny([GroupSecurity.CoverageCreate]);

  return (
    <ScrollView>
      <PatientHeaderSection id={id} />

      <Tabs.Group>
        <Tabs.Screen isHidden={!hasPatientFilePermissions} title={strings.LabelPatientFile}>
          <PatientProfileTab patientId={id} />
        </Tabs.Screen>

        <Tabs.Screen isHidden={!hasNextOfKinPermissions} title={strings.LabelNextOfKin}>
          <PatientNextOfKinTab patientId={id} />
        </Tabs.Screen>

        <Tabs.Screen isHidden={!canViewCoverage} title={strings.FormLabelMedicalAid}>
          <PatientMedicalAidTab patientId={id} />
        </Tabs.Screen>

        <Tabs.Screen isHidden={!canCreateCoverage} title={strings.FormLabelMedicalAid}>
          {result.data ? (
            <PatientMedicalAidUpdateTab
              patient={result.data}
              patientId={id}
              revalidate={revalidate}
            />
          ) : null}
        </Tabs.Screen>

        <Tabs.Screen isHidden={!hasDeviceManagePermissions} title={strings.LabelDevices}>
          <PatientDevicesTab
            patientId={id}
            canCreateDeviceUseStatement={hasDeviceUseStatementCreatePermission}
          />
        </Tabs.Screen>
        <Tabs.Screen isHidden={!hasLogisticsPermissions} title={'Logistics'}>
          {result.data ? <PatientLogisticTab patient={result.data} patientId={id} /> : null}
        </Tabs.Screen>
      </Tabs.Group>
    </ScrollView>
  );
};
