import styled from '@emotion/native';
import {
  ClinicalImpressionCode,
  ClinicalImpressionLocationCode,
  getReferenceId,
} from '@quromedical/fhir-common';
import { Media } from '@quromedical/models';
import { Col, Row } from 'components/base';
import { CrudForm } from 'components/form';
import { Field, FileResult, SelectOption } from 'components/types';
import {
  Card,
  Header,
  PADDING,
  Text,
  Visible,
  getSelectedComments,
  Button,
  MARGIN,
  FieldWrapper,
  FilePickerDialog,
  NestedComment,
  isGroup,
  NestedCommentForm,
  getSelectedTree,
  CommentDisplay,
} from 'design-system';
import { logger } from 'helpers';
import { useFileUpload } from 'hooks/useFileUpload';
import { useFiniteState } from 'hooks/useFiniteState';
import { useToggle } from 'hooks/useToggle';
import { MediaApi } from 'integration/aggregate';
import compact from 'lodash.compact';
import React, { useCallback, useState, useMemo } from 'react';
import { strings } from 'strings';

import { codeOptions, locationOptions } from './mapping';

const mediaApi = new MediaApi();

export interface Options {
  subjective: NestedComment.Group;
  objective: NestedComment.Group;
  assessment: NestedComment.Group;
  plan: NestedComment.Group;
  clinicalOperations?: NestedComment.Group;
}

interface NestedNoteFormProps {
  id: string;
  generalPractitioners?: fhir4.Reference[];
  options: Options;
  onSubmit: (formData: SubmitData) => Promise<void> | void;
}

export interface FormData {
  code: ClinicalImpressionCode;
  locationCode: ClinicalImpressionLocationCode;
  practitionersToNotify: string[];
}

export interface MediaData {
  media: Media.CreateData[];
}

export const createFields = (generalPractitioner: fhir4.Reference[] = []): Field<FormData>[] => {
  const practitionerOptions: SelectOption[] = generalPractitioner.map((practitioner) => ({
    display: practitioner.display || strings.PlaceholderPractitionerDisplayMissing,
    value: getReferenceId(practitioner),
  }));

  const fields: (Field<FormData> | undefined)[] = [
    {
      subfields: [
        {
          key: 'locationCode',
          type: 'toggle-button',
          label: strings.SOAPNoteLocationDescription,
          options: locationOptions,
        },
      ],
    },
    {
      subfields: [
        {
          key: 'code',
          type: 'combobox-single',
          label: strings.SOAPNoteCodeDescription,
          fetcher: codeOptions,
        },
        {
          key: 'practitionersToNotify',
          type: 'combobox-multiple',
          label: strings.FormLabelPractitionersToNotify,
          returnFullOption: false,
          fetcher: practitionerOptions,
        },
      ],
    },
  ];

  return compact(fields);
};

const FormWrapper = styled.View({
  paddingHorizontal: PADDING.m,
  paddingBottom: PADDING.s,
});

const FieldContent = styled.View({
  paddingVertical: PADDING['2xs'],
  marginLeft: MARGIN.xs,
});

export type SubmitData = FormData & Options & MediaData;
type FormState = 'interactive' | 'submitting' | 'submission-error';

const initialFormData: FormData = {
  practitionersToNotify: [],
  locationCode: ClinicalImpressionLocationCode.Virtual,
  code: ClinicalImpressionCode.Routine,
};

export const NestedNoteForm: React.FC<NestedNoteFormProps> = ({
  id: patientId,
  onSubmit,
  generalPractitioners,
  options,
}) => {
  const formState = useFiniteState<FormState>('interactive');

  const [expanded, toggleExpand] = useToggle(false);

  // we need to keep track of the last submission as a key to refresh the form after submission
  const [lastSubmitted, setLastSubmitted] = useState(Date.now());
  const [hasSubmitted, setSubmitted] = useState(false);

  const upload = useFileUpload((data) => mediaApi.createUploadUrl(patientId, data));

  const expandButtonIcon = expanded ? 'arrow-drop-up' : 'arrow-drop-down';
  const expandButtonText = expanded
    ? strings.SOAPNoteButtonTextHide
    : strings.SOAPNoteButtonTextCreateNote;

  const initialNote = useMemo<NestedComment.Group>(
    () => ({
      id: 'note',
      display: 'Note',
      type: 'group',
      selected: true,
      children: compact([
        options.subjective,
        options.objective,
        options.assessment,
        options.plan,
        options.clinicalOperations,
      ]),
    }),
    [options]
  );

  const [note, setNote] = useState<NestedComment.Group>(initialNote);

  const subjective = note.children.find((child) => child.id === options.subjective.id);
  const objective = note.children.find((child) => child.id === options.objective.id);
  const assessment = note.children.find((child) => child.id === options.assessment.id);
  const plan = note.children.find((child) => child.id === options.plan.id);
  const clinicalOperations = note.children.find(
    (child) => child.id === options.clinicalOperations?.id
  );

  const selectedSubjective = subjective ? getSelectedComments(subjective) : [];
  const selectedObjective = objective ? getSelectedComments(objective) : [];
  const selectedAssessment = assessment ? getSelectedComments(assessment) : [];
  const selectedPlan = plan ? getSelectedComments(plan) : [];
  const selectedClinicalOperations = clinicalOperations
    ? getSelectedComments(clinicalOperations)
    : [];

  const allTabsSelected =
    selectedSubjective.length &&
    selectedObjective.length &&
    selectedAssessment.length &&
    selectedPlan.length;

  const subjectiveTree = getSelectedTree(subjective);
  const objectiveTree = getSelectedTree(objective);
  const assessmentTree = getSelectedTree(assessment);
  const planTree = getSelectedTree(plan);
  const clinicalOperationsTree = getSelectedTree(clinicalOperations);

  const allDefined =
    isGroup(subjectiveTree) &&
    isGroup(objectiveTree) &&
    isGroup(assessmentTree) &&
    isGroup(planTree);

  const hasComments = getSelectedComments(note).length > 0;

  const showSelectionError = hasSubmitted && !allTabsSelected;

  const [formData, setFormData] = useState<Partial<FormData>>(initialFormData);

  const [uploadFiles, setUploadFiles] = useState<FileResult[]>([]);

  const onFilesChange = useCallback(
    (media: FileResult[]) => {
      upload.onFilesChange({ media });
      setUploadFiles(media);
    },
    [upload]
  );

  const reset = useCallback(() => {
    setFormData(initialFormData);
    setNote(initialNote);
    formState.set('interactive');
    setSubmitted(false);
    setUploadFiles([]);
  }, [initialNote, formState]);

  const handleSubmit = useCallback(async () => {
    setSubmitted(true);

    if (!(allDefined && allTabsSelected)) {
      return;
    }

    try {
      formState.set('submitting');
      await onSubmit({
        // FormData is only a set of toggles, wouldn't be possible for a user to select invalid
        ...(formData as FormData),
        media: upload.media,
        subjective: subjectiveTree,
        objective: objectiveTree,
        plan: planTree,
        assessment: assessmentTree,
        clinicalOperations: isGroup(clinicalOperationsTree) ? clinicalOperationsTree : undefined,
      });
      setLastSubmitted(Date.now());
      reset();
    } catch (err) {
      logger.error(err);
      formState.set('submission-error');
    }
  }, [
    allDefined,
    allTabsSelected,
    formState,
    formData,
    upload.media,
    subjectiveTree,
    objectiveTree,
    planTree,
    assessmentTree,
    clinicalOperationsTree,
    onSubmit,
    reset,
  ]);

  const fields = createFields(generalPractitioners);

  return (
    <Card paddingHorizontal={0} paddingVertical={0}>
      <Header
        title={strings.SOAPNoteCreateTitle}
        iconText="SOAP"
        blockColor="accent-green"
        onPrimaryButtonPress={toggleExpand}
        primaryButtonText={expandButtonText}
        primaryButtonIcon={expandButtonIcon}
        showPrimaryButton
      />
      <Visible if={expanded}>
        <FormWrapper>
          <CrudForm
            key={lastSubmitted}
            disabled={formState.is('submitting')}
            hasCard={false}
            fields={fields}
            initialValues={initialFormData}
            onChangeDebounceTime={0}
            onChange={setFormData}
            showSubmitButton={false}
          />
          <FieldWrapper
            label={strings.SOAPNoteTitle}
            error={showSelectionError ? strings.SOAPNoteAllItemsRequiredErrorMessage : undefined}
          >
            <Col flex={1}>
              <FieldContent>
                <NestedCommentForm onChange={setNote} initial={note} />
              </FieldContent>
              <Visible if={hasComments}>
                <FieldContent>
                  <CommentDisplay
                    comments={[
                      selectedSubjective,
                      selectedObjective,
                      selectedAssessment,
                      selectedPlan,
                      selectedClinicalOperations,
                    ]}
                  />
                </FieldContent>
              </Visible>
            </Col>
          </FieldWrapper>
          <Row alignItems="flex-end">
            <Col flex={1}>
              <FilePickerDialog
                isDisabled={formState.is('submitting')}
                onChange={onFilesChange}
                value={uploadFiles}
                progress={upload.progress}
                uploadInProgress={!upload.uploadsComplete}
              />
            </Col>
            <Col flex={1} alignItems="flex-end" justifyContent="flex-end">
              <Button onPress={handleSubmit} text={strings.ButtonTextSubmit} />
            </Col>
          </Row>
          <Row>
            {formState.is('submission-error') ? (
              <Text color="status-critical">{strings.GenericErrorMessage}</Text>
            ) : null}
          </Row>
        </FormWrapper>
      </Visible>
    </Card>
  );
};
