import { Group } from '@quromedical/models';
import { BaseApi } from 'integration/BaseApi';
import { compact } from 'lodash';

export enum GroupEndpoint {
  Member = 'member',
}

export class GroupApi {
  private readonly client: BaseApi;
  private readonly root = 'api/aggregate/group';

  constructor() {
    this.client = new BaseApi();
  }

  private createPath(groupId: string, endpoint?: GroupEndpoint) {
    const basePath = `${this.root}/${groupId}`;

    return compact([basePath, endpoint]).join('/');
  }

  /**
   * Get an SWR Key associated with the `getGroup` function
   */
  public getGroupSWRKey = (groupId: string): string => this.createPath(groupId);

  public async getGroup(id: string): Promise<Group.GetResponse> {
    const result = await this.client.get<Group.GetResponse>(this.createPath(id));

    return result.data;
  }

  public async getGroups(params: Group.GetAllParams): Promise<Group.GetAllResponse> {
    const result = await this.client.get<Group.GetAllResponse>(this.root, { params });

    return result.data;
  }

  public async createGroup(group: Group.CreateRequest): Promise<Group.CreateResponse> {
    const result = await this.client.post<Group.CreateResponse>(this.root, group);

    return result.data;
  }

  public async updateGroup(id: string, group: Group.UpdateRequest): Promise<Group.UpdateResponse> {
    const result = await this.client.put<Group.UpdateResponse>(this.createPath(id), group);

    return result.data;
  }

  public async updateGroupMembers(
    id: string,
    body: Group.UpdateMemberRequest
  ): Promise<Group.UpdateMemberResponse> {
    const path = this.createPath(id, GroupEndpoint.Member);
    const result = await this.client.put<Group.AddMembersResponse>(path, body);
    return result.data;
  }
}
