import {
  GroupSecurity,
  GroupDescription,
  GroupCode,
  GroupCodeDescription,
} from '@quromedical/fhir-common';
import { Group } from '@quromedical/models';
import { Field, SelectOption } from 'components/types';

export const initialData: Partial<Group.Group> = {
  active: true,
  permissions: [],
  name: '',
  type: undefined,
  organization: undefined,
};

export const initialMembers: Partial<Group.AddMembersRequest> = {
  members: [],
};

export const permissions = Object.values(GroupSecurity).map<SelectOption>((code) => ({
  value: code,
  display: GroupDescription[code as PermissionDescription] || code,
}));

export const typeOptions = Object.values(GroupCode).map<SelectOption>((code) => ({
  value: code,
  display: GroupCodeDescription[code] || code,
}));

export const initialSearch: Group.GetAllParams = {
  code: undefined,
};

export const searchFields: Field<Group.GetAllParams>[] = [
  {
    subfields: [
      {
        type: 'combobox-single',
        key: 'code',
        label: 'Type',
        fetcher: [
          {
            display: 'All',
            value: undefined,
          },
          ...typeOptions,
        ],
      },
    ],
  },
];

export type TableGroup = Group.GetResponse;

export type PermissionDescription = keyof typeof GroupDescription;

export const getCodeDescription = (code?: GroupCode): string | undefined => {
  if (!code) {
    return undefined;
  }

  return GroupCodeDescription[code];
};
