import { Person, Validations } from '@quromedical/models';
import { CrudForm, SubmissionResult } from 'components/form';
import {
  ContactWithOutCoordinates,
  minimalPersonGeneralFields,
  personGeneralFields,
  resolveFormContactFields,
} from 'core/forms';
import { Fetcher } from 'design-system/components';
import { useAddressAutoFill } from 'hooks/useAddressAutoFill';
import React, { useState } from 'react';
import { strings } from 'strings';

interface GeneralFormProps {
  data?: Partial<Person.General>;
  minimalData?: boolean;
  handleSubmit: (data: Person.General) => Promise<SubmissionResult<unknown>>;
  handleCancel: () => void;
}

interface ContactFormProps {
  data?: Partial<Person.ContactWithAddressEmergencyAccess>;
  hasEmergencyAccess?: boolean;
  handleSubmit: (
    data: Person.ContactWithAddressEmergencyAccess
  ) => Promise<SubmissionResult<unknown>>;
  handleCancel: () => void;
  addressFetcher?: Fetcher<string>;
  getAddressPartial?: (
    placeId: string
  ) => Promise<Partial<Person.ContactWithAddressEmergencyAccess>>;
}

export const ContactForm: React.FC<ContactFormProps> = ({
  data = {},
  hasEmergencyAccess = false,
  handleCancel,
  handleSubmit,
  getAddressPartial,
  addressFetcher,
}) => {
  const [formState, setFormState] =
    useState<Partial<Person.ContactWithAddressEmergencyAccess>>(data);
  const contactFields = resolveFormContactFields(formState, hasEmergencyAccess, addressFetcher);

  const { onContactChange, placeId } = useAddressAutoFill(setFormState, getAddressPartial);

  return (
    <CrudForm<ContactWithOutCoordinates>
      key={placeId}
      title={strings.FormLabelPersonContactDetails}
      validationSchema={Validations.personContactSchema}
      fields={contactFields}
      initialValues={formState}
      onSubmit={handleSubmit}
      onSecondarySubmit={handleCancel}
      showSecondarySubmitButton
      showSubmitButton
      onChangeDebounceTime={0}
      onChange={onContactChange}
    />
  );
};

export const GeneralForm: React.FC<GeneralFormProps> = ({
  data = {},
  minimalData = false,
  handleCancel,
  handleSubmit,
}) => {
  const fields = minimalData ? minimalPersonGeneralFields : personGeneralFields;
  const schema = minimalData
    ? Validations.personMinimalGeneralFieldSchema
    : Validations.personGeneralSchema;

  return (
    <CrudForm
      title={strings.FormLabelPersonDetails}
      validationSchema={schema}
      fields={fields}
      initialValues={data}
      onSubmit={handleSubmit}
      onSecondarySubmit={handleCancel}
      showSecondarySubmitButton
      showSubmitButton
    />
  );
};
