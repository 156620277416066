import { CountryCode } from '@quromedical/fhir-common';
import { GooglePlaces, Person } from '@quromedical/models';
import compact from 'lodash.compact';

const findComponent = (addressComponents: GooglePlaces.AddressComponents[], name: string) =>
  addressComponents.find((add) => add.types.includes(name));

const getShortName = (addressComponents: GooglePlaces.AddressComponents[], name: string) => {
  const component = findComponent(addressComponents, name);
  return component?.short_name;
};
const getLongName = (addressComponents: GooglePlaces.AddressComponents[], name: string) => {
  const component = findComponent(addressComponents, name);
  return component?.long_name;
};

export const getAddressParts = (
  addressComponents: GooglePlaces.AddressComponents[]
): Partial<Person.Contact> => {
  const addressCountry = getShortName(addressComponents, 'country') as CountryCode;

  const addressLine1Number = getShortName(addressComponents, 'street_number');

  const addressLine1Street = getLongName(addressComponents, 'route');

  const addressLine1 = compact([addressLine1Number, addressLine1Street]).join(' ');

  const addressLine2 = getLongName(addressComponents, 'sublocality_level_1');

  const addressCity = getLongName(addressComponents, 'administrative_area_level_2');

  const addressPostalCode = getShortName(addressComponents, 'postal_code');

  const addressLines = compact([addressLine1, addressLine2]);
  return { addressCity, addressCountry, addressPostalCode, addressLines };
};
