import styled from '@emotion/native';
import { Col } from 'components/base';
import { CSSProperties } from 'react';
import { useWindowDimensions } from 'react-native';

import { Renderer } from './types';

const iframeStyles: CSSProperties = {
  border: 'none',
  height: '100%',
  width: '100%',
};

const Wrapper = styled(Col)(() => {
  const { height } = useWindowDimensions();

  return {
    height: 0.8 * height,
    width: '100%',
  };
});

export const PDF: Renderer = (props) => (
  <Wrapper>
    <iframe src={props.url} title={props.title} style={iframeStyles} />
  </Wrapper>
);
