import { PaginatedTable } from 'core/table';
import { useResponsive } from 'hooks/useResponsive';
import { ServiceRequestApi } from 'integration';
import React from 'react';

import { getDataFromResponse, serviceRequestColumns } from './columns';

interface ServiceRequestListProps {
  patientId: string;
}

const api = new ServiceRequestApi();

export const ServiceRequestList: React.FC<ServiceRequestListProps> = ({ patientId }) => {
  const showHeader = useResponsive({ md: true }, false);
  const isStriped = useResponsive({ md: true }, false);

  const fetcher = (pageToken?: string) =>
    api.getServiceRequests({
      subject: patientId,
      pageToken,
    });

  const cacheKey = JSON.stringify({
    subject: patientId,
  });

  return (
    <PaginatedTable
      cacheKey={`ServiceRequest${cacheKey}`}
      fetcher={fetcher}
      columns={serviceRequestColumns}
      showHeader={showHeader}
      isStriped={isStriped}
      getDataFromResponse={getDataFromResponse}
    />
  );
};
