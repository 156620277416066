import { Bundle } from '@quromedical/fhir-common';

import { FhirBaseApi } from './FhirBaseApi';

export interface Params {
  sort?: string;
  count?: number;
  pageToken?: string;
  id?: string;
  'based-on'?: string;
  status?: fhir4.DiagnosticReport['status'][];
  identifier?: string;
  subject?: string;
  date?: string;
  code?: string;
}

export class DiagnosticReportApi extends FhirBaseApi<fhir4.DiagnosticReport> {
  constructor() {
    super('api/fhir/diagnostic-report');
  }

  getDiagnosticReports = async (params: Params): Promise<Bundle<fhir4.DiagnosticReport>> => {
    const status = params.status?.length ? params.status?.join(',') : undefined;

    return this.getAll({
      ...params,
      status,
    });
  };

  getDiagnosticReport = async (id: string): Promise<fhir4.DiagnosticReport> => this.get(id);

  getPresentedForm = async (id: string): Promise<fhir4.Attachment[]> => {
    const path = this.getPath(id, 'presented-form');

    const result = await this.client.get<fhir4.Attachment[]>(path);

    return result.data;
  };
}
