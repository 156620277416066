import { getCreateReferenceId, GroupSecurity } from '@quromedical/fhir-common';
import { ClaimTemplate as FormData, ClaimTemplateCard, InitialClaimTemplate } from 'core/claim';
import { claimTypeDisplay } from 'core/display';
import { Section, Skeleton, SkeletonProvider } from 'design-system';
import { useRevalidation } from 'hooks/useRevalidation';
import { useUserSession } from 'hooks/useUserSession';
import { chargeItemFetcher } from 'integration';
import { ClaimTemplateApi } from 'integration/aggregate';
import { ClaimTemplateStackScreenProps } from 'navigation';
import React, { useCallback } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { NotFoundScreen } from 'screens/NotFoundScreen';
import { strings } from 'strings';
import useSWR from 'swr';

const api = new ClaimTemplateApi();

export const ClaimTemplateScreen: React.FC<ClaimTemplateStackScreenProps<'View'>> = ({ route }) => {
  const { id } = route.params;

  const session = useUserSession();
  const canEdit = session.hasAny([GroupSecurity.ClaimTemplateUpdate]);

  const fetcher = useCallback(() => api.getClaimTemplate(id), [id]);
  const swrKey = api.getSWRKey(id);

  const { data, isValidating, error } = useSWR(swrKey, fetcher);

  const revalidate = useRevalidation(api.getSWRKey());

  const handleSubmit = useCallback(
    async (data: FormData) => {
      await api.updateClaimTemplate({
        type: data.type,
        chargeLines: data.claimLines.map((line) => ({
          chargeLine: line.chargeItemIdentifier,
          quantity: line.quantity,
        })),
      });
      await revalidate();
    },
    [revalidate]
  );

  if (error) {
    return <NotFoundScreen />;
  }

  const loading = isValidating || !data;

  const initial: InitialClaimTemplate | undefined = data
    ? {
        type: data.type,
        claimLines: data.chargeLines.map((line) => ({
          chargeItemDisplay: line.reference.display || strings.DisplayNotFound,
          chargeItemIdentifier: getCreateReferenceId(line.reference),
          quantity: line.quantity,
        })),
      }
    : undefined;

  const title = data ? claimTypeDisplay[data.type] : strings.DisplayNotFound;

  return (
    <ScrollView>
      <SkeletonProvider loading={loading}>
        <Section unsetZIndex>
          <Skeleton>
            <ClaimTemplateCard
              title={title}
              chargeItemFetcher={chargeItemFetcher}
              canEdit={canEdit}
              initial={initial}
              onSubmit={handleSubmit}
            />
          </Skeleton>
        </Section>
      </SkeletonProvider>
    </ScrollView>
  );
};
