import { withVisibility } from 'components/base';
import React from 'react';
import { G as SvgG } from 'react-native-svg';

import { Border } from './Border';
import { usePlotDataContext } from './PlotDataProvider';
import { ExternalComponentProps } from './types';
import { XAxis } from './XAxis';
import { XGrid } from './XGrid';
import { YAxis } from './YAxis';
import { YGrid } from './YGrid';

interface GridProps {
  xTicks?: number | number[];
  yTicks?: number | number[];
  grid?: boolean;
  xGrid?: boolean;
  yGrid?: boolean;
  axis?: boolean;
  xAxis?: boolean;
  yAxis?: boolean;
  border?: boolean;
  stroke?: string;
  strokeWidth?: number;
  color?: string;
  strokeDasharray?: number | number[];
  xFormatter?: (n: number) => string;
  yFormatter?: (n: number) => string;
}

const G = withVisibility(SvgG);

type ExternalProps = ExternalComponentProps & GridProps;

export const Grid: React.FC<ExternalProps> = (props) => {
  const {
    grid = false,
    axis = false,
    xGrid = false,
    yGrid = false,
    xAxis = false,
    yAxis = false,
    border = false,
    xTicks = 10,
    yTicks = 10,
    stroke,
    color,
    xFormatter,
    yFormatter,
    strokeWidth,
  } = usePlotDataContext(props);

  return (
    <G>
      <G isVisible={border}>
        <Border {...props} />
      </G>
      <G isVisible={grid || xGrid}>
        <XGrid {...props} ticks={xTicks} stroke={stroke} strokeWidth={strokeWidth} />
      </G>
      <G isVisible={grid || yGrid}>
        <YGrid {...props} ticks={yTicks} stroke={stroke} strokeWidth={strokeWidth} />
      </G>
      <G isVisible={axis || xAxis}>
        <XAxis {...props} ticks={xTicks} formatter={xFormatter} color={color} />
      </G>
      <G isVisible={axis || yAxis}>
        <YAxis {...props} ticks={yTicks} formatter={yFormatter} color={color} />
      </G>
    </G>
  );
};
