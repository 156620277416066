import React, { createContext, useContext } from 'react';

interface Context {
  id?: string;
}

const PatientIdContext = createContext<Context>({ id: undefined });

export const PatientIdProvider: React.FC<Required<Context>> = ({ children, id }) => (
  <PatientIdContext.Provider value={{ id }}>{children}</PatientIdContext.Provider>
);

// should be used in a context where the ID provider has been set prior
export const usePatientId = (): string => useContext(PatientIdContext).id as string;
