import { Group } from '@quromedical/models';
import { useLinkTo } from '@react-navigation/native';
import { Alert, Skeleton, SkeletonProvider, Snackbar } from 'design-system';
import { useFiniteState } from 'hooks/useFiniteState';
import { useRevalidation } from 'hooks/useRevalidation';
import { organizationFetcher } from 'integration';
import { GroupApi } from 'integration/aggregate';
import React, { useCallback } from 'react';
import { strings } from 'strings';

import { Form } from '../forms';

type FormState = 'editing' | 'submitting' | 'error';
const api = new GroupApi();

export const CreateGroupCard: React.FC = () => {
  const navigate = useLinkTo();
  const state = useFiniteState<FormState>('editing');
  const revalidate = useRevalidation('Group');

  const onSubmit = useCallback(
    async (data: Group.CreateRequest) => {
      state.set('submitting');
      try {
        const result = await api.createGroup(data);
        state.set('editing');
        navigate({
          screen: 'Admin',
          params: {
            screen: 'Group',
            params: {
              screen: 'View',
              params: {
                id: result.id,
              },
            },
          },
        });
        revalidate();
        return {};
      } catch (error) {
        state.set('error');
        return { error };
      }
    },
    [navigate, revalidate, state]
  );

  return (
    <>
      <SkeletonProvider loading={state.is('submitting')}>
        <Skeleton>
          <Form data={{}} handleSubmit={onSubmit} organizationFetcher={organizationFetcher} />
        </Skeleton>
      </SkeletonProvider>

      <Snackbar onClose={state.next('editing')} isOpen={state.is('error')}>
        <Alert
          backgroundColor="status-critical"
          textColor="white"
          onAction={state.next('editing')}
          actionIcon="close"
          title={strings.ErrorCardTitle}
          body={strings.GenericErrorMessage}
        />
      </Snackbar>
    </>
  );
};
