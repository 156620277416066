import { mapFileListToArray, readWebFile } from 'helpers/file';
import { compact } from 'lodash';
import React, { DragEventHandler, useCallback } from 'react';

import { DropWrapperProps } from './types';

export const DropWrapper: React.FC<DropWrapperProps> = ({ children, onSelect }) => {
  const preventDefault = useCallback<DragEventHandler<HTMLDivElement>>(
    (ev) => ev.preventDefault(),
    []
  );

  const onDrop = useCallback<DragEventHandler<HTMLDivElement>>(
    async (ev) => {
      const { dataTransfer } = ev;

      ev.preventDefault();
      ev.stopPropagation();

      if (!dataTransfer) {
        return;
      }

      if (dataTransfer.files.length) {
        const fileArray = mapFileListToArray(dataTransfer.files);
        const fileReadPromises = fileArray.map(readWebFile);
        const files = await Promise.all(fileReadPromises);

        onSelect(compact(files));
      }
    },
    [onSelect]
  );

  // in order to handle onDrop events, you need to ensure that onDragOver is default prevented
  return (
    <div onDrop={onDrop} onDragOver={preventDefault}>
      {children}
    </div>
  );
};
