import styled from '@emotion/native';
import { ColorName } from '@emotion/react';
import { Text } from 'design-system/base';
import { BASE_SIZE, PADDING } from 'design-system/theme';
import React from 'react';

interface IconTextProps {
  text: string;
  iconColor?: ColorName;
  blockColor?: ColorName;
}

interface WrapperProps {
  color: ColorName;
}

const Wrapper = styled.View<WrapperProps>(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  backgroundColor: theme.color[color],
  height: BASE_SIZE[48],
  width: BASE_SIZE[42],
  paddingVertical: PADDING['3xs'],
}));

export const IconText: React.FC<IconTextProps> = ({
  iconColor = 'text-default',
  blockColor = 'text-default',
  text = '',
}) => (
  <Wrapper color={blockColor}>
    <Text variant="caption" color={iconColor}>
      {text}
    </Text>
  </Wrapper>
);
